/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import OrgStore from './OrgStore';
import Constants from '../constants';
import {createApiStore} from '../lib/store';

let rules = [];
let count = {};
let filters = JSON.parse(sessionStorage.getItem('rulesearchFilters')) || {};

function setRules(data) {
  data.forEach(fillUserInfo);
  rules = data;
}

function setCount(data) {
  count = data;
}

function setFilters(data) {
  filters = data;
  sessionStorage.setItem('rulesearchFilters', JSON.stringify(data));
}

/**
 * Whether the href is a service account
 * @param href
 * @returns {boolean}
 */
const isServiceAccountHref = href => typeof href === 'string' && href.includes('/service_accounts/');

function fillUserInfo(rule) {
  const updatedByUser = rule.updated_by
    ? isServiceAccountHref(rule.updated_by)
      ? {full_name: rule.updated_by.name}
      : OrgStore.getUserFromHref(rule.updated_by.href)
    : null;
  const createdByUser = rule.created_by
    ? isServiceAccountHref(rule.created_by)
      ? {full_name: rule.created_by.name}
      : OrgStore.getUserFromHref(rule.created_by.href)
    : null;

  Object.assign(rule, {
    updated_by_user: (updatedByUser && updatedByUser.full_name) || '--',
    created_by_user: (createdByUser && createdByUser.full_name) || '--',
  });
}

export default createApiStore('SEC_POLICY_RULE_SEARCH_', {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SEC_POLICY_RULE_SEARCH_CREATE_SUCCESS:
        setRules(action.data);
        setCount(action.count);
        break;
      case Constants.SEC_POLICY_RULE_SEARCH_SET_FILTERS:
        setFilters(action.data);
        break;
      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getCount() {
    return count;
  },

  getRules() {
    return rules;
  },

  getFilters() {
    return filters;
  },
});
