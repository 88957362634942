/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../../actions/actionCreators';
import Constants from '../../constants/Constants';
import {IpListStore, LabelStore, ProvisionStore, SegmentationTemplateStore, ServiceStore} from '../../stores';
import {StoreMixin, RouterMixin, UserMixin} from '../../mixins';
import SegmentationTemplateEula from '../../modals/SegmentationTemplateEula.jsx';
import ConfirmationDialog from '../../components/ConfirmationDialog.jsx';
import {Link} from 'react-router';
import {Button, Dialog, SpinnerOverlay} from '../../components';
import {SegmentationTemplateUtils, GridDataUtils, RestApiUtils} from '../../utils';
import SegmentationTemplateMixin from './SegmentationTemplateMixin.jsx';

function getStateFromStores() {
  return {
    template: SegmentationTemplateStore.getTemplate(this.props.params.id),
    status: SegmentationTemplateStore.getStatus(),
    labelDisplayName: LabelStore.getLabelDisplayName(),
  };
}

export default React.createClass({
  mixins: [
    RouterMixin,
    SegmentationTemplateMixin,
    UserMixin,
    StoreMixin([SegmentationTemplateStore], getStateFromStores),
  ],

  componentDidMount() {
    if (!this.state.template) {
      this.replaceWith('segmentationTemplates.list');
    }
  },

  getWhatsNew() {
    if (this.state.template.change_note && this.state.template.change_note.length) {
      return (
        <div className="SegmentationTemplateDetail-data">
          <div className="SegmentationTemplateDetail-data-title">{intl('SegmentationTemplates.WhatsNew')}</div>
          {this.state.template.change_note}
        </div>
      );
    }

    return null;
  },

  getDependencies() {
    let dependencies;

    if (this.state.template.dependencies.length) {
      dependencies = (
        <div>
          <ul className="Template-list">
            {this.state.template.dependencies.map(dependency => (
              <li className="Template-listelement">{dependency}</li>
            ))}
          </ul>
        </div>
      );
    }

    if (dependencies) {
      return (
        <div className="SegmentationTemplateDetail-data">
          <div className="SegmentationTemplateDetail-data-title">{intl('SegmentationTemplates.Dependencies')}</div>
          {dependencies}
        </div>
      );
    }

    return null;
  },

  getRulesets() {
    if (this.state.template.rule_sets.length) {
      const segmentationTemplateRulesets = SegmentationTemplateStore.getSegmentationTemplateRulesets();
      const rulesets = this.state.template.rule_sets.map(ruleset => {
        const href = segmentationTemplateRulesets[ruleset.key] ? segmentationTemplateRulesets[ruleset.key].href : null;

        if (href) {
          return (
            <tr key={ruleset.key} className="SegmentationTemplateDetail-row">
              <td className="Template-tabledata">
                <Link
                  to="rulesets.item"
                  className="Grid-link"
                  params={{id: href.split('/').pop(), pversion: 'draft', tab: 'intrascope'}}
                >
                  {segmentationTemplateRulesets[ruleset.key].name}
                </Link>
              </td>
              <td className="Template-tabledata">{this.getRulesetScopes(ruleset.scopes)}</td>
            </tr>
          );
        }

        return (
          <tr key={ruleset.key} className="SegmentationTemplateDetail-row">
            <td className="Template-tabledata">{ruleset.name}</td>
            <td className="Template-tabledata">{this.getRulesetScopes(ruleset.scopes)}</td>
          </tr>
        );
      });

      return (
        <div className="SegmentationTemplateDetail-data">
          <div className="SegmentationTemplateDetail-data-title">{intl('Common.Policies')}</div>
          <table className="SegmentationTemplateDetail-data-table" data-tid="templates-detail-rulesets">
            <tbody>
              <tr className="SegmentationTemplateDetail-row">
                <th className="Template-tableHead">{intl('Common.Name')}</th>
                <th className="Template-tableHead">{intl('Common.Scope')}</th>
              </tr>
              {rulesets}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  },

  getServices() {
    if (this.state.template.services.length) {
      const segmentationTemplateServices = SegmentationTemplateStore.getSegmentationTemplateServices();
      const services = this.state.template.services.map(service => {
        let href = segmentationTemplateServices[service.key] ? segmentationTemplateServices[service.key].href : null;
        let pversion = 'draft';

        if (ServiceStore.getSpecifiedDefaultService(service.name)) {
          href = ServiceStore.getSpecifiedDefaultService(service.name).href;
          pversion = 'active';
        }

        if (href) {
          return (
            <tr key={service.name} className="SegmentationTemplateDetail-row">
              <td className="Template-tabledata">
                <Link to="services.item" className="Grid-link" params={{id: href.split('/').pop(), pversion}}>
                  {service.name}
                </Link>
              </td>
              <td className="Template-tabledata">{GridDataUtils.formatServiceOsPorts('value', service)}</td>
            </tr>
          );
        }

        return (
          <tr key={service.name} className="SegmentationTemplateDetail-row">
            <td className="Template-tabledata">{service.name}</td>
            <td className="Template-tabledata">{GridDataUtils.formatServiceOsPorts('value', service)}</td>
          </tr>
        );
      });

      return (
        <div className="SegmentationTemplateDetail-data">
          <div className="SegmentationTemplateDetail-data-title">{intl('Common.Services')}</div>
          <table className="SegmentationTemplateDetail-data-table" data-tid="templates-detail-services">
            <tbody>
              <tr className="SegmentationTemplateDetail-row">
                <th className="Template-tableHead">{intl('Common.Name')}</th>
                <th className="Template-tableHead">{intl('Port.Protocol')}</th>
              </tr>
              {services}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  },

  getLabels() {
    const {labelDisplayName} = this.state;

    if (this.state.template.labels.length) {
      const segmentationTemplateLabels = SegmentationTemplateStore.getSegmentationTemplateLabels();
      const labels = this.state.template.labels.map(label => {
        const href = segmentationTemplateLabels[label.value] ? segmentationTemplateLabels[label.value].href : null;

        if (href) {
          return (
            <tr className="SegmentationTemplateDetail-row" key={label.value}>
              <td className="Template-tabledata">
                <Link to="labels.item" className="Grid-link" params={{id: href.split('/').pop(), pversion: 'draft'}}>
                  {label.value}
                </Link>
              </td>
              <td className="Template-tabledata">{labelDisplayName[label.key]}</td>
            </tr>
          );
        }

        return (
          <tr key={label.value} className="SegmentationTemplateDetail-row">
            <td className="Template-tabledata">{label.value}</td>
            <td className="Template-tabledata">{labelDisplayName[label.key]}</td>
          </tr>
        );
      });

      return (
        <div className="SegmentationTemplateDetail-data">
          <div className="SegmentationTemplateDetail-data-title">{intl('Common.Labels')}</div>
          <table className="SegmentationTemplateDetail-data-table" data-tid="templates-detail-labels">
            <tbody>
              <tr className="SegmentationTemplateDetail-row">
                <th className="Template-tableHead">{intl('Common.Name')}</th>
                <th className="Template-tableHead">{intl('Labels.Type')}</th>
              </tr>
              {labels}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  },

  getIpLists() {
    if (this.state.template.ip_lists.length) {
      const segmentationTemplateIpLists = SegmentationTemplateStore.getSegmentationTemplateIpLists();
      const ipLists = this.state.template.ip_lists.map(ipList => {
        let href = segmentationTemplateIpLists[ipList.name] ? segmentationTemplateIpLists[ipList.name].href : null;
        const ranges = this.formatIpLists(ipList.ip_ranges);
        let pversion = 'draft';

        if (ipList.name === intl('IPLists.Any')) {
          href = IpListStore.getAnyIpList().href;
          pversion = 'active';
        }

        if (href) {
          return (
            <tr key={ipList.name} className="SegmentationTemplateDetail-row">
              <td className="Template-tabledata">
                <Link to="iplists.item" className="Grid-link" params={{id: href.split('/').pop(), pversion}}>
                  {ipList.name}
                </Link>
              </td>
              <td className="Template-tabledata">{ranges}</td>
            </tr>
          );
        }

        return (
          <tr key={ipList.name} className="SegmentationTemplateDetail-row">
            <td className="Template-tabledata">{ipList.name}</td>
            <td className="Template-tabledata">{ranges}</td>
          </tr>
        );
      });

      return (
        <div className="SegmentationTemplateDetail-data">
          <div className="SegmentationTemplateDetail-data-title">{intl('Common.IPLists')}</div>
          <table className="SegmentationTemplateDetail-data-table" data-tid="templates-detail-ip-lists">
            <tbody>
              <tr className="SegmentationTemplateDetail-row">
                <th className="Template-tableHead">{intl('Common.Name')}</th>
                <th className="Template-tableHead">{intl('IPLists.List.Label')}</th>
              </tr>
              {ipLists}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  },

  getLabelGroups() {
    if (this.state.template.label_groups.length) {
      const segmentationTemplateLabelGroups = SegmentationTemplateStore.getSegmentationTemplateLabelGroups();
      const labelGroups = this.state.template.label_groups.map(labelGroup => {
        const href = segmentationTemplateLabelGroups[labelGroup.name]
          ? segmentationTemplateLabelGroups[labelGroup.name].href
          : null;

        if (href) {
          return (
            <tr key={labelGroup.name} className="SegmentationTemplateDetail-row">
              <td className="Template-tabledata">
                <Link
                  to="labelGroupTab"
                  className="Grid-link"
                  params={{id: href.split('/').pop(), pversion: 'draft', tab: 'summary'}}
                >
                  {labelGroup.name}
                </Link>
              </td>
              <td className="Template-tabledata">{this.getLabelGroupLabels(labelGroup.labels)}</td>
            </tr>
          );
        }

        return (
          <tr key={labelGroup.name} className="SegmentationTemplateDetail-row">
            <td className="Template-tabledata">{labelGroup.name}</td>
            <td className="Template-tabledata">{this.getLabelGroupLabels(labelGroup.labels)}</td>
          </tr>
        );
      });

      return (
        <div className="SegmentationTemplateDetail-data">
          <div className="SegmentationTemplateDetail-data-title">{intl('Labels.Groups')}</div>
          <table className="SegmentationTemplateDetail-data-table">
            <tbody>
              <tr className="SegmentationTemplateDetail-row">
                <th className="Template-tableHead">{intl('Common.Name')}</th>
                <th className="Template-tableHead">{intl('Common.Labels')}</th>
              </tr>
              {labelGroups}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  },

  getLabelGroupLabels(labels) {
    const {labelDisplayName} = this.state;

    const groupLabels = labels.map(label => (
      <tr key={label.value}>
        <td className="SegmentationTemplateDetail-labelgroup-labels">
          {`${label.value} - ${labelDisplayName[label.key]}`}
        </td>
      </tr>
    ));

    return (
      <table>
        <tbody>{groupLabels}</tbody>
      </table>
    );
  },

  getDescription() {
    if (this.state.template.description) {
      return (
        <div className="SegmentationTemplateDetail-data">
          <div className="SegmentationTemplateDetail-data-title">{intl('Common.Description')}</div>
          <div data-tid="templates-detail-description">{this.state.template.description}</div>
        </div>
      );
    }

    return null;
  },

  getLastUpdatedAt() {
    const updatedAt = new Date(this.state.template.updated_at);

    return isNaN(updatedAt)
      ? intl('Common.Unknown')
      : intl.date(updatedAt, {month: 'long', day: 'numeric', year: 'numeric'});
  },

  getRulesetScopes(scopes) {
    if (!scopes[0].length) {
      return `${intl('Common.All')} | ${intl('Common.All')} | ${intl('Common.All')}`;
    }

    let scopeNames = [];

    scopes.forEach(scope => {
      if (scope.length) {
        const types = ['app', 'env', 'loc'];
        const name = _.compact(types.map(type => SegmentationTemplateUtils.getLabel(scope, type))).join(' | ');

        scopeNames.push(name);
      } else {
        scopeNames.push(`${intl('Common.All')} | ${intl('Common.All')} | ${intl('Common.All')}`);
      }
    });
    scopeNames = scopeNames.map((name, index) => (
      <tr key={index}>
        <td className="Template-tabledata">{name}</td>
      </tr>
    ));

    return (
      <table data-tid="templates-detail-ruleset-scopes">
        <tbody>{scopeNames}</tbody>
      </table>
    );
  },

  getUninstallButton() {
    const buttonText =
      this.state.template.status === 'uninstalling'
        ? intl('Workloads.Status.Uninstalling')
        : intl('SegmentationTemplates.Uninstall');

    return (
      <Button
        data-tid="templates-detail-button-uninstall"
        text={buttonText}
        onClick={this.handleConfirmUninstall}
        disabled={this.state.template.status === 'invalidVersion' || this.isUserReadOnly()}
      />
    );
  },

  handleConfirmUninstall() {
    actionCreators.openDialog(
      <ConfirmationDialog
        title={intl('SegmentationTemplates.UninstallTemplate')}
        message={intl('SegmentationTemplates.UninstallWarning')}
        onConfirm={this.handleUninstall}
      />,
    );
  },

  handleEula() {
    actionCreators.openDialog(<SegmentationTemplateEula data-tid="templates-eula" onInstall={this.handleInstall} />);
  },

  async handleUninstall() {
    actionCreators.setSegmentationTemplateStatus({key: this.state.template.key, status: 'uninstalling'});

    const templateData = `${this.state.template.name} - Version ${this.state.template.version}`;

    this.handleUninstallRulesets(templateData);
  },

  async handleUninstallRulesets(templateData) {
    const undeletableObjects = [];
    const provisionedObjects = [];
    let ruleSetsToRemove = {remove: [], update: []};

    if (this.state.template.rule_sets.length) {
      await RestApiUtils.ruleSets.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);
      ruleSetsToRemove = SegmentationTemplateUtils.prepareEntitiesForDelete(
        this.state.template.rule_sets,
        SegmentationTemplateStore.getSegmentationTemplateRulesets(),
        templateData,
        'key',
        false,
      );

      const rulesetHrefs = ruleSetsToRemove.remove.map(ruleset => ruleset.href);

      if (rulesetHrefs.length) {
        await RestApiUtils.ruleSets.delete(rulesetHrefs);
        await Promise.all(
          rulesetHrefs.map(href =>
            RestApiUtils.ruleSets
              .getInstance(href.split('/').pop(), 'draft', true)
              .catch(_.noop)
              .then(response => {
                if (response && response.body && response.body.deleted_at) {
                  provisionedObjects.push({
                    type: intl('Explorer.Policy'),
                    name: response.body.name,
                    data: response.body,
                  });
                }
              }),
          ),
        );
      }

      if (ruleSetsToRemove.update.length) {
        await Promise.all(
          ruleSetsToRemove.update.map(ruleset => RestApiUtils.ruleSet.update(ruleset.href, ruleset.data)),
        );
      }
    }

    this.handleUninstallLabelGroups(templateData, undeletableObjects, provisionedObjects);
  },

  async handleUninstallLabelGroups(templateData, undeletableObjects, provisionedObjects) {
    let labelGroupsToRemove = {remove: [], update: []};

    if (this.state.template.label_groups.length) {
      await RestApiUtils.labelGroups.getCollection(
        {external_data_set: 'illumio_segmentation_templates'},
        'draft',
        true,
      );
      labelGroupsToRemove = SegmentationTemplateUtils.prepareEntitiesForDelete(
        this.state.template.label_groups,
        SegmentationTemplateStore.getSegmentationTemplateLabelGroups(),
        templateData,
        'name',
        true,
      );

      if (labelGroupsToRemove.remove.length) {
        await Promise.all(
          labelGroupsToRemove.remove.map(labelGroup =>
            RestApiUtils.labelGroup.delete(labelGroup.id).catch(() => {
              actionCreators.closeDialog();
              undeletableObjects.push({type: intl('Labels.Group'), name: labelGroup.data.name, data: labelGroup.data});
            }),
          ),
        );
        await Promise.all(
          labelGroupsToRemove.remove.map(labelGroup =>
            RestApiUtils.labelGroups
              .getInstance(labelGroup.id, {}, 'draft', true)
              .catch(_.noop)
              .then(response => {
                if (response && response.body && response.body.deleted_at) {
                  provisionedObjects.push({type: intl('Labels.Group'), name: response.body.name, data: response.body});
                }
              }),
          ),
        );
      }

      if (labelGroupsToRemove.update.length) {
        await Promise.all(
          labelGroupsToRemove.update.map(labelGroup => RestApiUtils.labelGroup.update(labelGroup.id, labelGroup.data)),
        );
      }
    }

    this.handleProvisionErrors(templateData, undeletableObjects, provisionedObjects);
  },

  async handleUninstallIpLists(templateData, undeletableObjects, ignoreLabels) {
    let ipListsToRemove = {remove: [], update: []};

    if (this.state.template.ip_lists.length) {
      await RestApiUtils.ipLists.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);

      const installableIpLists = _.reject(this.state.template.ip_lists, {name: intl('IPLists.Any')});

      ipListsToRemove = SegmentationTemplateUtils.prepareEntitiesForDelete(
        installableIpLists,
        SegmentationTemplateStore.getSegmentationTemplateIpLists(),
        templateData,
        'name',
        true,
      );

      if (ipListsToRemove.remove.length) {
        await Promise.all(
          ipListsToRemove.remove.map(ipList =>
            RestApiUtils.ipList.delete(ipList.id).catch(() => {
              actionCreators.closeDialog();
              undeletableObjects.push({type: intl('Common.IPList'), name: ipList.data.name, data: ipList.data});
            }),
          ),
        );
      }

      if (ipListsToRemove.update.length) {
        await Promise.all(ipListsToRemove.update.map(ipList => RestApiUtils.ipList.update(ipList.id, ipList.data)));
      }
    }

    this.handleUninstallServices(templateData, undeletableObjects, ignoreLabels);
  },

  async handleUninstallServices(templateData, undeletableObjects, ignoreLabels) {
    let servicesToRemove = {remove: [], update: []};

    await RestApiUtils.services.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);

    const deletableServices = _.reject(this.state.template.services, {name: 'All Services'});

    servicesToRemove = SegmentationTemplateUtils.prepareEntitiesForDelete(
      deletableServices,
      SegmentationTemplateStore.getSegmentationTemplateServices(),
      templateData,
      'key',
      true,
    );

    await Promise.all(servicesToRemove.update.map(service => RestApiUtils.service.update(service.id, service.data)));
    await Promise.all(
      servicesToRemove.remove.map(service =>
        RestApiUtils.service.delete(service.id).catch(() => {
          actionCreators.closeDialog();
          undeletableObjects.push({type: intl('Common.Service'), name: service.data.name, data: service.data});
        }),
      ),
    );
    this.handleUninstallLabels(templateData, undeletableObjects, ignoreLabels);
  },

  async handleProvisionErrors(templateData, undeletableObjects, provisionedObjects) {
    if (provisionedObjects.length && this.state.template.labels.length) {
      actionCreators.setSegmentationTemplateStatus({key: this.state.template.key});
      actionCreators.openDialog(
        <Dialog
          className="SegmentationTemplateErrorModal"
          type="detail"
          title="Uninstall Template"
          subTitle="Template labels cannot be deleted until changes to other deleted template objects (listed below) have been provisioned.  Do you want to provision these changes now?"
          actions={[
            {
              text: 'Revert',
              tid: 'revert',
              type: 'secondary',
              onClick: () => {
                actionCreators.closeDialog();
                this.handleRevertChanges(templateData, provisionedObjects, undeletableObjects);
              },
            },
            {
              text: 'Provision Now',
              tid: 'provision',
              type: 'primary',
              onClick: () => {
                actionCreators.closeDialog();
                this.handleProvisionChanges(templateData, provisionedObjects, undeletableObjects);
              },
            },
          ]}
        >
          <ul className="Template-list">
            {provisionedObjects.map(object => (
              <li className="Template-listelement">
                {`${object.type}: `}
                <span className="SegmentationTemplateErrorModal-EntityName">{object.name}</span>
              </li>
            ))}
          </ul>
        </Dialog>,
      );
    } else {
      actionCreators.setSegmentationTemplateStatus({key: this.state.template.key, status: 'uninstalling'});
      this.handleUninstallIpLists(templateData, undeletableObjects);
    }
  },

  async handleUninstallLabels(templateData, undeletableObjects, ignoreLabels) {
    let labelsToRemove = {remove: [], update: []};

    if (this.state.template.labels.length && !ignoreLabels) {
      await RestApiUtils.labels.getCollection({external_data_set: 'illumio_segmentation_templates'}, true);
      labelsToRemove = SegmentationTemplateUtils.prepareEntitiesForDelete(
        this.state.template.labels,
        SegmentationTemplateStore.getSegmentationTemplateLabels(),
        templateData,
        'value',
        true,
      );

      if (labelsToRemove.remove.length) {
        await Promise.all(
          labelsToRemove.remove.map(label =>
            RestApiUtils.label.delete(label.id).catch(() => {
              actionCreators.closeDialog();
              undeletableObjects.push({type: intl('Common.Label'), name: label.data.value, data: label.data});
            }),
          ),
        );
      }

      if (labelsToRemove.update.length) {
        await Promise.all(labelsToRemove.update.map(label => RestApiUtils.label.update(label.id, label.data)));
      }
    }

    actionCreators.segmentationTemplatesClearInstalledTemplates();
    await RestApiUtils.labels.getCollection({external_data_set: 'illumio_segmentation_templates'}, true);
    await RestApiUtils.labelGroups.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);
    await RestApiUtils.services.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);
    await RestApiUtils.ipLists.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);
    await RestApiUtils.ruleSets.getCollection(
      {
        representation: 'rule_set_scopes',
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    actionCreators.segmentationTemplatesCompareInstalledTemplates();
    await RestApiUtils.secPolicies.dependencies();

    if (undeletableObjects.length) {
      actionCreators.openDialog(
        <Dialog
          className="SegmentationTemplateErrorModal"
          type="detail"
          title={intl('SegmentationTemplates.UninstallTemplate')}
          subTitle={intl('SegmentationTemplates.UndeletableObjects')}
          actions={[
            {
              text: intl('Common.Close'),
              tid: 'cancel',
              type: 'primary',
              onClick: () => {
                actionCreators.closeDialog();
              },
            },
          ]}
        />,
      );
    }

    actionCreators.setSegmentationTemplateStatus({key: this.state.template.key});
  },

  async handleRevertChanges(templateData, provisionedObjects, undeletableObjects) {
    actionCreators.setSegmentationTemplateStatus({key: this.state.template.key, status: 'uninstalling'});

    const changeSubset = {};

    provisionedObjects.forEach(object => {
      const objectType = object.data.href.split('/').slice(-2)[0];

      if (changeSubset[objectType]) {
        changeSubset[objectType].push({href: object.data.href});
      } else {
        changeSubset[objectType] = [{href: object.data.href}];
      }
    });
    await RestApiUtils.secPolicies.dependencies({operation: 'revert', change_subset: changeSubset});

    const dependencies = ProvisionStore.getDependencies();

    dependencies.forEach(object => {
      const objectType = Object.keys(object.dependency)[0];

      if (changeSubset[objectType]) {
        changeSubset[objectType].push({href: object.dependency[objectType].href});
      } else {
        changeSubset[objectType] = [{href: object.data.href}];
      }
    });

    await RestApiUtils.secPolicies.delete({change_subset: changeSubset});
    this.handleUninstallIpLists(templateData, undeletableObjects, true);
  },

  async handleProvisionChanges(templateData, provisionedObjects, undeletableObjects) {
    actionCreators.setSegmentationTemplateStatus({key: this.state.template.key, status: 'uninstalling'});

    const changeSubset = {};

    provisionedObjects.forEach(object => {
      const objectType = object.data.href.split('/').slice(-2)[0];

      if (changeSubset[objectType]) {
        changeSubset[objectType].push({href: object.data.href});
      } else {
        changeSubset[objectType] = [{href: object.data.href}];
      }
    });
    await RestApiUtils.secPolicies.dependencies({operation: 'commit', change_subset: changeSubset});

    const dependencies = ProvisionStore.getDependencies();

    dependencies.forEach(object => {
      const objectType = Object.keys(object.dependency)[0];

      if (changeSubset[objectType]) {
        changeSubset[objectType].push({href: object.dependency[objectType].href});
      } else {
        changeSubset[objectType] = [{href: object.data.href}];
      }
    });
    await RestApiUtils.secPolicies.create({update_description: '', change_subset: changeSubset});
    this.handleUninstallIpLists(templateData, undeletableObjects);
  },

  formatIpLists(ipRanges) {
    let ranges = [];

    ipRanges.forEach(range => {
      let result = range.from_ip;

      if (range.exclusion) {
        result = `!${result}`;
      }

      if (range.to_ip) {
        result = `${result} - ${range.to_ip}`;
      }

      ranges.push(result);
    });
    ranges = ranges.map((range, index) => (
      <tr key={index}>
        <td className="Template-tabledata">{range}</td>
      </tr>
    ));

    return (
      <table data-tid="templates-detail-ip-list-ranges">
        <tbody>{ranges}</tbody>
      </table>
    );
  },

  handleBack() {
    this.transitionTo('segmentationTemplates.list');
  },

  render() {
    if (SegmentationTemplateStore.getAll().length === 0) {
      this.replaceWith('segmentationTemplates.list');
    }

    const {state} = this;

    if (state.template) {
      const logo = this.getLogo(state.template.icon, 'SegmentationTemplateDetail-logo');

      return (
        <div className="SegmentationTemplateDetail" data-tid="templates-detail">
          {this.state.status.includes(Constants.STATUS_BUSY) || _.includes(state.template.status, 'installing') ? (
            <SpinnerOverlay />
          ) : null}
          <div className="SegmentationTemplateDetail-container">
            {logo}
            <div className="SegmentationTemplateDetail-text">
              <div className="SegmentationTemplateDetail-name" data-tid="templates-name">
                {state.template.name ? state.template.name : null}
              </div>
              <div className="Template-content">{this.getVersion(state.template)}</div>
              <div className="Template-content">
                <div className="Template-key" data-tid="templates-detail-content">
                  {intl('SegmentationTemplates.Content')}
                </div>
                <div className="Template-values">{SegmentationTemplateUtils.getContent(state.template)}</div>
              </div>
              <div className="Template-content">
                <div className="Template-key" data-tid="templates-detail-update">
                  {intl('SegmentationTemplates.LastUpdated')}
                </div>
                <div className="Template-values">{this.getLastUpdatedAt()}</div>
              </div>
              <Button
                data-tid="templates-detail-button-install"
                text={SegmentationTemplateUtils.getInstallText(state.template.status)}
                onClick={this.handleEula}
                disabled={
                  state.template.status === 'installed' ||
                  state.template.status === 'installing' ||
                  state.template.status === 'uninstalling' ||
                  state.template.status === 'invalidVersion' ||
                  this.isUserReadOnly()
                }
              />
              {state.template.status !== 'notInstalled' &&
                state.template.status !== 'installing' &&
                this.getUninstallButton()}
              {state.template.status === 'invalidVersion' && (
                <div className="Template-invalidversion">{intl('SegmentationTemplates.InvalidVersion')}</div>
              )}
              {this.getDescription()}
              {this.getWhatsNew()}
              {this.getDependencies()}
              {this.getRulesets()}
              {this.getServices()}
              {this.getLabels()}
              {this.getLabelGroups()}
              {this.getIpLists()}
            </div>
          </div>
        </div>
      );
    }

    return <div />;
  },
});
