/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import {Icon, RadioGroup, Select, Checkbox, ServiceSelect, WorkloadSelect, ContainerSelect} from '..';
import ComponentUtils from '../../utils/ComponentUtils';

const defaultTid = 'comp-field';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    type: PropTypes.oneOf([
      'text',
      'textarea',
      'email',
      'password',
      'name',
      'org',
      'timezone',
      'select',
      'checkbox',
      'radiogroup',
      'serviceselect',
      'control',
      'workloadselect',
      'containerselect',
    ]),
    state: PropTypes.oneOf(['ok', 'old', 'new', 'invalid', 'error']),
    label: PropTypes.any,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
    control: PropTypes.any,
    hint: PropTypes.func,
    noHint: PropTypes.bool,
    showLabel: PropTypes.bool,
    icon: PropTypes.string,
    errorMessage: PropTypes.string,
    noBorder: PropTypes.bool,
    required: PropTypes.bool,
    subText: PropTypes.string,
  },

  getDefaultProps() {
    return {
      type: 'text',
      state: 'old',
      hint: () => null,
      showLabel: true,
      noHint: false,
      errorMessage: null,
      subText: null,
    };
  },

  render() {
    const classes = cx({
      'Form-field': true,
      'Form-field-text': this.props.type === 'text',
      'Form-field-textarea': this.props.type === 'textarea',
      'Form-field--error': this.props.state === 'error',
      'Form-field--new': this.props.state === 'new',
      'Form-field--removed': this.props.state === 'removed',
      'Form-field--invalid': this.props.state === 'invalid',
      'Form-field--icon': this.props.icon,
      'Form-field-select': this.props.type === 'select',
      'Form-field-radiogroup': this.props.type === 'radiogroup',
      'Form-field-serviceselect': this.props.type === 'serviceselect',
      'Form-field-checkbox': this.props.type === 'checkbox',
      'Form-field-email': this.props.type === 'email',
      'Form-field-password': this.props.type === 'password',
      'Form-field-org': this.props.type === 'org',
      'Form-field-name': this.props.type === 'name',
      'Form-field--noBorder': this.props.noBorder,
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    tids.push(`${defaultTid}-${this.props.type}`);

    const hideLabel = !this.props.showLabel || (!this.props.label && this.props.type === 'checkbox');
    let label = !hideLabel && (
      <label className="Form-field-label" data-tid="comp-field-label">
        {this.props.label}
      </label>
    );

    if (label && this.props.required && !hideLabel) {
      label = (
        <label className="Form-field-label" data-tid="comp-field-label">
          <span className="Form-field--required">* </span>
          {this.props.label}
        </label>
      );
    }

    let controls = null;
    const props = _.omit(this.props, ['tid', 'type', 'label', 'state', 'hint', 'icon']);

    if (this.props.control) {
      controls = this.props.control;
    } else {
      switch (this.props.type) {
        case 'text':
        case 'name':
        case 'org':
          controls = <input type="text" tabIndex="0" data-tid="comp-field-input" {...props} />;
          break;

        case 'email':
          controls = <input type="email" tabIndex="0" data-tid="comp-field-input" {...props} />;
          break;

        case 'password':
          controls = <input type="password" tabIndex="0" data-tid="comp-field-input" {...props} />;
          break;

        case 'textarea':
          controls = <textarea tabIndex="0" data-tid="comp-field-input" {...props} />;
          break;

        case 'checkbox':
          controls = <Checkbox {...props} />;
          break;

        case 'timezone':
          controls = <Select {...props} />;
          break;

        case 'select':
          controls = <Select {...props} />;
          break;

        case 'radiogroup':
          controls = <RadioGroup {...props} />;
          break;

        case 'serviceselect':
          controls = <ServiceSelect {...props} />;
          break;

        case 'workloadselect':
          controls = <WorkloadSelect {...props} />;
          break;

        case 'containerselect':
          controls = <ContainerSelect {...props} />;
          break;
      }
    }

    let hint = null;

    if (!this.props.noHint) {
      hint = (
        <div className="Form-field-hint" data-tid="comp-field-hint">
          {this.props.hint()}
        </div>
      );
    }

    let icon = null;

    if (this.props.icon) {
      icon = <Icon name={this.props.icon} styleClass="Field" />;
    }

    let error = null;

    if (this.props.state === 'error' && this.props.errorMessage) {
      error = (
        <div className="Form-field-error-message" data-tid="comp-field-error-message">
          {this.props.errorMessage}
        </div>
      );
    }

    let subText = null;

    if (this.props.subText) {
      subText = (
        <div className="Form-field-subtext-message" data-tid="comp-field-subtext-message">
          {this.props.subText}
        </div>
      );
    }

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
        {label}
        <div className="Form-field-controls" data-tid="comp-field-controls">
          {controls}
          {subText}
          {icon}
          {error}
        </div>
        {hint}
      </div>
    );
  },
});
