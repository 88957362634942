/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import InternetVisualization from '../../utils/Internet';
import actionCreators from '../../actions/actionCreators';
import {GraphTransformStore} from '../../stores';

export default React.createClass({
  componentDidMount() {
    this.d3Wrapper = d3.select(findDOMNode(this));
    this.d3Wrapper
      .datum(this.props.data)
      .call(_.bind(InternetVisualization.enter, InternetVisualization))
      .call(_.bind(InternetVisualization.update, InternetVisualization));
  },

  componentDidUpdate() {
    this.d3Wrapper.datum(this.props.data).call(_.bind(InternetVisualization.update, InternetVisualization));
  },

  handleHoverInternet(evt) {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.hoverInternet) {
      this.props.hoverInternet(this.props.data);
    }

    if (!this.tooltip) {
      actionCreators.showMapTooltip({
        type: this.props.data.type,
        tooltipInfo: {...this.props.data},
        location: {x: evt.pageX, y: evt.pageY},
      });

      this.tooltip = true;
    }
  },

  handleUnhoverInternet() {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.unhoverInternet && !this.props.data.selected) {
      this.props.unhoverInternet(this.props.data);
    }

    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  handleSelectInternet() {
    // note: internetLinks is to display info on command panel
    // clusterId is to highlight the selected internet/ipList in a cluster
    // internetLinks is also to highlight the selected internet/ipList for unconnected nodes
    const internets = [
      {
        type: this.props.data.type,
        clusterId: this.props.data.cluster ? this.props.data.cluster.clusterId : null,
        clusterHref: this.props.data.cluster ? this.props.data.cluster.href : null,
        nodeHref: this.props.data.node ? this.props.data.node.href : null,
        internetLinks: this.props.data.internets,
        identifier: this.props.data.identifier,
        cluster: this.props.data.cluster,
      },
    ];

    if (this.props.data.selected) {
      actionCreators.unselectComponent(internets);
    } else {
      actionCreators.updateComponentSelection(internets);
    }

    if (this.props.hoverInternet) {
      this.props.hoverInternet(this.props.data);
    }
  },
  handleContextMenu(evt) {
    evt.preventDefault();

    /* Hide the tooltip on right click */
    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  render() {
    let background = null;
    const {type} = this.props.data;

    if (type === 'internet') {
      background = <text className="Icon-bg" />;
    } else if (type === 'ipList') {
      background = <rect className="Icon-bg" />;
    } else if (type === 'fqdn') {
      background = <text className="Icon-bg" />;
    }

    const internetClassname = cx('il-internet', {
      'il-iplist': this.props.data.type === 'ipList',
    });

    return (
      <g
        className={internetClassname}
        data-tid={`internet-node-${type}`}
        onClick={GraphTransformStore.getInteractionType() === 'select' ? this.handleSelectInternet : null}
        onMouseEnter={this.handleHoverInternet}
        onMouseLeave={this.handleUnhoverInternet}
        onContextMenu={this.handleContextMenu}
      >
        {background}
        <text className="Icon-text" />
      </g>
    );
  },
});
