/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {createElement} from 'react';
import {Link, State} from 'react-router';

export default React.createClass({
  mixins: [State],

  render() {
    const {children, className, ...props} = this.props;
    let elementType;
    let elementProps;

    if (props.to) {
      elementType = Link;
      elementProps = {
        query: props.query || this.getQuery(),
        className: cx('Link', className),
        ...props,
      };
    } else {
      elementType = 'a';
      elementProps = {
        className: cx('Link', className),
        ...props,
      };
    }

    return createElement(elementType, elementProps, children);
  },
});
