/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
/**
 * Check that user clicked on element (link, for example) without pressing some keys expecting page opening in new tab
 * (new browsing context in terms of HTML5)
 *
 * If link's target other than '_self' or empty, let browser decide what to do
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target
 *
 * Ctrl/Cmd + Shift + Click --> Open a link in a new tab in foreground
 * Ctrl/Cmd + Click --> Open a link in a new tab in backgroud
 * Shift + Click --> Open a link in a new window
 * Alt + Click --> Save the target on disk (open the Save As dialog)
 * Middle mouse click --> Open a link in a new tab
 *
 * @param {Object} evt            - Click event
 * @param {Object} [target=_self] - Anchor target attribute value
 */
export const isClickInBrowsingContext = (evt, target = '_self') =>
  target === '_self' &&
  (!evt ||
    (!evt.ctrlKey && !evt.metaKey && !evt.shiftKey && !evt.altKey && (evt.type !== 'click' || evt.button === 0)));

/**
 * Emulate click on element.
 * If original event is passed and MouseEvent constructor is supported, attributes are copied from specified event
 *
 * @param {HTMLElement} element
 * @param {Object} [originalNativeEvent]
 */
export const clickElement = (element, originalNativeEvent) => {
  if (typeof MouseEvent === 'function') {
    element.dispatchEvent(
      new MouseEvent(
        'click',
        originalNativeEvent || {
          view: window,
          bubbles: true,
          cancelable: true,
        },
      ),
    );
  } else if (typeof element.click === 'function') {
    element.click();
  } else if (document.createEvent) {
    const eventObj = document.createEvent('MouseEvents');

    eventObj.initEvent('click', true, true);
    element.dispatchEvent(eventObj);
  } else {
    return false;
  }

  return true;
};

/**
 * Open href in new tab/window or download
 * Uses temporary anchor element to avoid 'window.open' blocking and treat open type according to pressed key
 *
 * @param {String} href
 * @param {Object} [evt] Original click event to get pressed keys from it. Will be just opened in new tab if omitted
 * @param {String} [pageName=download] Page name, to create file name, if user want to download page (pressed Alt)
 */
export const openHref = (href, evt, pageName = 'download') => {
  let result = false;

  try {
    const a = document.createElement('a');
    const newTab = !evt || evt.ctrlKey || evt.metaKey || evt.shiftKey;
    const download = evt && evt.altKey;

    a.setAttribute('href', href);

    if (newTab) {
      a.setAttribute('target', '_blank');
    } else if (download) {
      a.setAttribute('download', `${pageName}.html`);
    }

    // For Firefox clicked ahref must be on the page, even invisible
    a.style.display = 'none';
    document.body.append(a);

    result = clickElement(a, (evt && evt.nativeEvent) || evt);

    a.remove();
  } catch (error) {
    console.error(error);
    result = false;
  }

  return result;
};

/**
 * Create form via javascript with parameters and submit it to given target
 *
 * @param {String} target Url to suibmit form
 * @param {Object} [data={}] Object wit key/value that should be sent to target
 * @param {String} [method=post] HTTP method
 */
export const submitFormTo = (target, data = {}, method = 'post') => {
  const form = document.createElement('form');

  form.setAttribute('action', target);
  form.setAttribute('method', method);

  for (const [key, value] of Object.entries(data)) {
    const hiddenField = document.createElement('input');

    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', key);
    hiddenField.setAttribute('value', value);

    form.append(hiddenField);
  }

  document.body.append(form);
  form.submit();
};

/**
 * Prevent default event and stop its propagation
 */
export const preventEvent = evt => {
  try {
    evt.preventDefault();
    evt.stopPropagation();
  } catch (error) {
    console.log('Event failed to be prevented', error);
  }

  return false;
};

/**
 * Get first scroll parent of element
 * https://stackoverflow.com/questions/35939886/find-first-scrollable-parent/42543908#42543908
 *
 * @param {object} [element] Document element
 * @param {boolean} [includeHidden=true] Find scroll parent if there is no overflow yet
 * @returns {object} scroll parent and sticky offset elements
 */
export const getScrollParentAndOffsetElements = (element = {}, includeHidden = true) => {
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
  const excludeStaticParent = getComputedStyle(element).position === 'absolute';
  let parent = element;
  const offsetElements = [];

  while (true) {
    parent = parent.parentElement;

    if (parent === document.body || !parent) {
      return {parent: document.documentElement, offsetElements};
    }

    parent.childNodes.forEach(child => {
      if (child.className?.includes('StickyShadow_sticky')) {
        offsetElements.push(child);
      }
    });

    const style = getComputedStyle(parent);

    if (style.position === 'fixed') {
      return {parent: document.documentElement, offsetElements};
    }

    if (excludeStaticParent && style.position === 'static') {
      continue;
    }

    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return {parent, offsetElements};
    }
  }
};

/**
 * Scroll to top of document element
 *
 * @param {object} [element] Document element
 * @param {Array} [offsetElements] Find element position relative to offsetElements
 * @param {string} [behavior="smooth"] Scrolling behavior
 */
export const scrollToTopOfElement = ({element = {}, offsetElements = [], behavior = 'smooth'} = {}) => {
  const {parent: scroller, offsetElements: stickyElements} = getScrollParentAndOffsetElements(element);
  const {top: scrollerTop, height: scrollerHeight} = scroller.getBoundingClientRect();
  let {top: elementTop, height: elementHeight} = element.getBoundingClientRect();

  if (scroller !== document.documentElement) {
    elementTop -= scrollerTop;
  }

  const bottom = elementTop + elementHeight;
  const offsetHeight = [...offsetElements, ...stickyElements].reduce(
    (acc, element) => acc + element.getBoundingClientRect().height,
    0,
  );

  let moveBy = 0;

  if (elementTop - offsetHeight < 0) {
    // If top border of the element is above the bottom border of the offset, scroll up by the differentce (negative)
    moveBy = elementTop - offsetHeight;
  } else if (elementHeight > scrollerHeight - offsetHeight) {
    // Othersize, if top of the element is below the bottom border of the offset, but its total height is bigger the available space,
    // then just scroll to the top of the element
    moveBy = offsetHeight - elementTop;
  } else if (bottom > scrollerHeight) {
    // Othersize, if top of the element is below the bottom border of the offset, but bottom of the element is below the visible area,
    // and element's height can fit into the available space,
    // then scroll to align bottom of the element with the bottom of the scrollable
    moveBy = bottom - scrollerHeight;
  }

  // Scroll only if absolute value of delta is 1 or greater
  if (Math.floor(Math.abs(moveBy))) {
    scroller.scrollBy({top: moveBy, behavior});
  } else {
    moveBy = 0;
  }

  return {scroller, moveBy};
};

/**
 * Detect when scroll ends, by debouncing the onDone call by the given debounce timeout.
 * Returns a function to unsubscribe from the event.
 *
 * @param callback
 * @param scroller
 * @param [debounce=100]
 * @param [startWaitingImmediately=true] - If scrolling has started already, start a countdown immediately
 * @returns {function(...[*]=)}
 */
export const onScrollEnd = function ({onDone, scroller, debounce = 100, startWaitingImmediately = true}) {
  let scrollingTimeout;

  if (!scroller || scroller === document.documentElement || scroller === document.body) {
    scroller = window;
  }

  function checkScroll() {
    window.clearTimeout(scrollingTimeout);

    scrollingTimeout = setTimeout(() => {
      scroller.removeEventListener('scroll', checkScroll);
      onDone();
    }, debounce);
  }

  if (startWaitingImmediately) {
    checkScroll();
  }

  // Listen to scroll event
  scroller.addEventListener('scroll', checkScroll);

  // Return unsubscribe function
  return function unsubscribeScrollEnd() {
    window.clearTimeout(scrollingTimeout);
    scroller.removeEventListener('scroll', checkScroll);
  };
};

export const scrollToElement = ({element, offsetElements}) => {
  // Scroll to the element if needed
  const {scroller, moveBy} = scrollToTopOfElement({element, offsetElements});

  if (moveBy) {
    const preventMouseOver = evt => preventEvent(evt);

    // If we need to scroll to the focused element,
    // then we need to prevent handleMouseOver on all other elements, to prevent mouse over them in the middle of scrolling.
    // So first, intercept onMouseoverEvent on the document level, and stop propagating it
    document.addEventListener('mouseover', preventMouseOver, {capture: true});

    // Remove handleMouseOver prevention when scrolling is done
    onScrollEnd({
      scroller,
      debounce: 500,
      onDone() {
        document.removeEventListener('mouseover', preventMouseOver, {capture: true});
      },
    });
  }
};
