/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Dialog, RadioGroup} from '..';
import actionCreators from '../../actions/actionCreators';

export default React.createClass({
  getInitialState() {
    const {onClearRoleConfirm, onClearAppConfirm, onClearLocationConfirm} = this.props.data;

    return {
      isTrafficChecked: false,
      radioValue: onClearRoleConfirm ? 'role' : onClearAppConfirm ? 'app' : onClearLocationConfirm ? 'loc' : 'org',
    };
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  handleRadioToggle(evt) {
    this.setState({radioValue: evt.target.value});
  },

  render() {
    const {onClearRoleConfirm, onClearAppConfirm, onClearOrgConfirm, onClearLocationConfirm, labels} = this.props.data;
    const actions = [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          this.handleClose();
        },
      },
      {
        text: intl('Common.OK'),
        tid: 'ok',
        type: 'primary',
        ref: 'confirmButton',
        onClick: () => {
          const {radioValue} = this.state;

          switch (radioValue) {
            case 'role':
              onClearRoleConfirm();
              break;
            case 'app':
              onClearAppConfirm();
              break;
            case 'loc':
              onClearLocationConfirm();
              break;
            case 'org':
              onClearOrgConfirm();
          }

          this.handleClose();
        },
      },
    ];

    const radioData = [];

    if (onClearRoleConfirm) {
      const value = labels?.role ? intl('Map.Traffic.ClearForRole') : intl('Map.Traffic.ClearForMissingRole');

      radioData.push({tid: 'clearRoleTraffic', key: 'role', value});
    }

    if (onClearAppConfirm) {
      const value = intl('Map.Traffic.ClearForApp');

      radioData.push({tid: 'clearAppTraffic', key: 'app', value});
    }

    if (onClearLocationConfirm) {
      const value = intl('Map.Traffic.ClearForLocation');

      radioData.push({tid: 'clearLocationTraffic', key: 'loc', value});
    }

    if (onClearOrgConfirm) {
      radioData.push({tid: 'clearOrgTraffic', key: 'org', value: intl('Map.Traffic.ClearForOrg')});
    }

    return (
      <Dialog className="ClearTrafficModal" title={this.props.data.title} actions={actions}>
        <div className="ClearTrafficMessage">{this.props.data.message}</div>
        <RadioGroup value={this.state.radioValue} onChange={this.handleRadioToggle} data={radioData} />
      </Dialog>
    );
  },
});
