/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {PropTypes} from 'react';
import ComponentUtils from '../../utils/ComponentUtils';

const defaultTid = 'comp-toolbar';

export default React.createClass({
  propTypes: {
    justify: PropTypes.oneOf(['right', 'left']),
    tid: PropTypes.string,
    hasObjectSelector: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      justify: 'left',
    };
  },

  render() {
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    const right = React.Children.count(this.props.children) === 1 && this.props.justify === 'right';
    const classes = cx({
      'ToolBar': true,
      'ToolBar-ObjectSelector': this.props.hasObjectSelector,
      'ToolBar--right': right,
    });

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
        {this.props.children}
      </div>
    );
  },
});
