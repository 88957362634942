/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createApiStore} from '../lib/store';
import StoreUtils from '../utils/StoreUtils';
import Constants from '../constants';

let Dvs = [];
let isLoaded = false;

function setDvs(data) {
  Dvs = data;
}

function getDvs(href) {
  return _.find(Dvs, dvs => dvs.href === href);
}

function getDvsBySlb(slb) {
  return _.filter(Dvs, dvs => dvs.slb && dvs.slb.href === slb);
}

export default createApiStore('DISCOVERED_VIRTUAL_SERVERS_', {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.DISCOVERED_VIRTUAL_SERVERS_GET_COLLECTION_SUCCESS:
        setDvs(action.data);
        isLoaded = true;
        break;

      case Constants.DISCOVERED_VIRTUAL_SERVERS_GET_INSTANCE_SUCCESS:
        StoreUtils.setObjectByHref(action.data, Dvs);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return Dvs || [];
  },

  getBySlb(slb) {
    return slb ? getDvsBySlb(slb) : [];
  },

  get(href) {
    return href ? getDvs(href) : null;
  },

  isLoaded() {
    return isLoaded;
  },
});
