/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import ProvisionUtils from '../utils/ProvisionUtils';

const provisionProgress = {};
let pending = {};
let dependencies = null;
let isLoaded = false;
let depLoaded = false;
let isPending;

function setPending(data) {
  pending = data;
}

function setDependencies(data) {
  dependencies = data;
}

function setProvisionCount(data) {
  provisionProgress.red = data.red_agents.length;
  provisionProgress.total = data.online_agents;
  provisionProgress.timeout = data.red_agents_timed_out;
}

export default createApiStore(['SEC_POLICY_DEPENDENCIES_', 'SEC_POLICIES_DEPENDENCIES_', 'DASHBOARDS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SEC_POLICY_DEPENDENCIES_SUCCESS:
      case Constants.SEC_POLICIES_DEPENDENCIES_SUCCESS:
        if (action.options.data) {
          setDependencies(action.data);
          depLoaded = true;
        }

        break;

      case Constants.SEC_POLICY_PENDING_GET_SUCCESS:
        setPending(action.data);
        isLoaded = true;
        break;

      case Constants.DASHBOARDS_RULE_CALC_PROGRESS_SUCCESS:
        setProvisionCount(action.data);
        break;

      case Constants.PROVISION_PENDING:
        isPending = true;
        break;

      case Constants.PROVISION_DONE:
        isPending = false;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getSpecified() {
    return pending;
  },

  getDependencies() {
    return dependencies;
  },

  isLoaded() {
    return isLoaded;
  },

  dependenciesLoaded() {
    return depLoaded;
  },

  pendingCounts() {
    return getCounts();
  },

  pendingTotal() {
    return ProvisionUtils.provisionCountsTotal(getCounts());
  },

  getProvisionStatus() {
    return isPending ? 'pending' : 'done';
  },

  getProvisionProgress() {
    return provisionProgress;
  },
});

function getCounts() {
  return ProvisionUtils.getProvisionMenuCounts(pending);
}
