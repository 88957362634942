/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {Icon, Tooltip} from '../../components';
import actionCreators from '../../actions/actionCreators';
import MapDataSync from '../../modals/MapDataSync.jsx';
import {HealthUtils, GraphDataUtils, RestApiUtils, GridDataUtils} from '../../utils';
import {GraphStore, MapPageStore, TrafficStore, SessionStore, HealthStore} from '../../stores';

export default React.createClass({
  getInitialState() {
    return {
      ...this.getData(),
      totalWorkloads: MapPageStore.getTotalWorkloads(),
    };
  },

  componentWillReceiveProps() {
    this.setState(this.getData());
  },

  getData() {
    // Get all the nodes in the current map, and pick the oldest timestamp
    const groups = GraphStore.getClusters();
    const locations = _.isEmpty(MapPageStore.getMapRoute()) ? TrafficStore.getAllLocationNodes() : [];
    let nodes = GraphStore.getNodes()
      .concat(locations)
      .concat(MapPageStore.getMapType() === 'loc' ? groups : [])
      .concat(
        groups.reduce((result, group) => {
          result = result.concat(group.nodes);

          return result;
        }, []),
      );

    if (MapPageStore.getMapLevel() === 'globalAppGroup') {
      nodes = TrafficStore.getAllAppGroupNodes();
    }

    const stale =
      SessionStore.isSuperclusterLeader() &&
      HealthStore.getClusters().some(cluster => {
        if (cluster.network && cluster.network.illumination_sync && cluster.network.illumination_sync.length) {
          const syncTime = new Date(cluster.network.illumination_sync[0].last_succeeded_at);
          const currentTime = new Date();

          // If any member sync is older than 2 days - flag it as stale
          return currentTime - syncTime > 1000 * 60 * 60 * 48;
        }

        return false;
      });

    return (nodes || []).reduce(
      (result, node) => {
        if (node && (result.timestamp < 0 || node.built < result.timestamp)) {
          result.timestamp = node.built;
        }

        if (result.stale || (node && node.stale)) {
          result.stale = true;
        }

        if (result.truncated || node?.data?.truncated) {
          result.truncated = true;
        }

        return result;
      },
      {timestamp: -1, stale, truncated: false},
    );
  },

  formatDate(timestamp) {
    if (this.props.topLevel) {
      return intl('Map.JustNow');
    }

    if (!timestamp || timestamp === -1) {
      return intl('Common.Never');
    }

    // Remove after testing
    const add = localStorage.getItem('add_to_time') * 60 * 60 * 24 * 1000;
    const finalTimestamp = new Date(timestamp).getTime() - add;

    return HealthUtils.getIllumincationSync(finalTimestamp);
  },

  handleClick() {
    actionCreators.openDialog(
      <MapDataSync
        timestamp={this.formatDate(this.state.timestamp)}
        supercluster={_.isEmpty(MapPageStore.getMapRoute()) && SessionStore.isSuperclusterLeader()}
        onConfirm={this.handleRefresh}
      />,
    );
  },

  async handleRefresh(evt) {
    if (evt) {
      evt.stopPropagation();
    }

    if (_.isEmpty(MapPageStore.getMapRoute()) && SessionStore.isSuperclusterLeader()) {
      RestApiUtils.agentTraffic.update_subgraphs();
    }

    // No need to wait before rebuilding the traffic here because it's not preceeded by an actor change
    GraphDataUtils.loadTraffic('rebuild', false);
  },

  render() {
    const {timestamp, stale, totalWorkloads, truncated} = this.state;

    if (this.props.iconOnly) {
      const handleIconRefresh = totalWorkloads > 10_000 ? this.handleClick : this.handleRefresh;

      return (
        <div onClick={handleIconRefresh}>
          <Icon name="refresh" size="large" position="before" />
        </div>
      );
    }

    if (timestamp === -1) {
      return null;
    }

    const tooltipContent = [];

    if (truncated) {
      tooltipContent.push(<div className="Map-Timestamp--tooltip">{intl('Map.DataTooLarge')}</div>);
    }

    if (stale) {
      tooltipContent.push(<div className="Map-Timestamp--tooltip">{intl('Map.NewerDataIsAvailable')}</div>);
    }

    return (
      <div className="Map-Timestamp" onClick={this.handleClick}>
        <Tooltip
          location="topleft"
          content={intl('Map.DataGenerated', {time: GridDataUtils.formatDate(timestamp)})}
          width={262}
        >
          {truncated || stale ? (
            <span className={truncated ? 'Map-Timestamp--red' : 'Map-Timestamp--orange'}>
              <Tooltip width={175} content={tooltipContent}>
                <Icon name={truncated ? 'error' : 'warning'} size="large" />
              </Tooltip>
            </span>
          ) : null}
          {intl('Map.DataGenerated', {time: this.formatDate(timestamp)})}
        </Tooltip>
      </div>
    );
  },
});
