/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Icon, Label} from '..';
import DvsStore from '../../stores/DvsStore';
import {RestApiUtils} from '../../utils';
import {getPolicyIntlByState} from '@illumio-shared/utils/intl/dynamic';
import LoadBalancerStore from '../../stores/LoadBalancerStore';
import VirtualServerStore from '../../stores/VirtualServerStore';
import LabelStore from '../../stores/LabelStore';
import SetPolicyState from './SetPolicyStatePanel';

export default React.createClass({
  getInitialState() {
    return {
      editPolicyState: false,
      labelDisplayName: LabelStore.getLabelDisplayName(),
    };
  },

  componentDidMount() {
    _.defer(() => {
      // Until they reconfigure these apis, we are stuck with these two collections
      if (!LoadBalancerStore.isLoaded()) {
        RestApiUtils.slbs.getCollection();
      }

      if (!DvsStore.isLoaded()) {
        RestApiUtils.discoveredVirtualServers.getCollection();
      }

      if (!VirtualServerStore.getExpanded(this.props.data.href)) {
        RestApiUtils.virtualServers.get(_.last(this.props.data.href.split('/')), 'draft', true, {
          representation: 'virtual_server_provider_names_and_labels',
        });
      }
    });
  },

  shouldComponentUpdate(nextProps) {
    if (this.props.data.href !== nextProps.data.href) {
      if (!VirtualServerStore.getExpanded(nextProps.data.href)) {
        RestApiUtils.virtualServers.get(_.last(nextProps.data.href.split('/')), 'draft', true, {
          representation: 'virtual_server_provider_names_and_labels',
        });
      }

      this.setState({
        editPolicyState: false,
      });
    }

    return true;
  },

  handleEditPolicyState() {
    this.setState({editPolicyState: !this.state.editPolicyState});
  },

  handleClosePolicyState() {
    this.setState({editPolicyState: false});
  },

  renderLabel(label) {
    if (!label) {
      return null;
    }

    const {labelDisplayName} = this.state;

    return (
      <tr className="MapInfoPanel-Row">
        <th className="MapInfoPanel-Row-Label">{labelDisplayName[label.key]}</th>
        <td className="MapInfoPanel-Row-Value">
          <Label type={label.key} text={label.value} />
        </td>
      </tr>
    );
  },

  render() {
    const {data} = this.props;
    const {caps} = this.props.data;
    const vsWorkloadsIsWritable = caps.workloads.includes('write');
    // Order label values into:
    // label:role label:app, label:env, label:loc (RAEL)
    const roleLabel = this.renderLabel(data.labels.role);
    const appLabel = this.renderLabel(data.labels.app);
    const envLabel = this.renderLabel(data.labels.env);
    const locLabel = this.renderLabel(data.labels.loc);

    // Two possible policy values are:
    // enforced, unmanaged (Not Enforced)
    const policyValue = getPolicyIntlByState(data.policyState);

    const policyStateData = {
      type: 'virtualServer',
      href: data.href,
      currentState: data.policyState,
    };

    const editPolicyState = (
      <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
        <SetPolicyState onClose={this.handleClosePolicyState} data={policyStateData} />
      </td>
    );

    const displayPolicyState = (
      <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-data">
        {policyValue}
        {this.props.data.caps && vsWorkloadsIsWritable && (
          <span className="Icon-Edit">
            <Icon onClick={this.handleEditPolicyState} name="edit" tid="edit-policy-state" />
          </span>
        )}
      </td>
    );

    const policyState = (
      <tr className="MapInfoPanel-Row">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          {intl('Common.Enforcement')}
        </th>
        {this.state.editPolicyState ? editPolicyState : displayPolicyState}
      </tr>
    );

    // ipAddress for virtual server
    const ip = data.addresses ? (
      <tr className="MapInfoPanel-Row">
        <th className="MapInfoPanel-Row-Label">{intl('VirtualServers.VIP')}</th>
        <td className="MapInfoPanel-Row-Value">{data.addresses}</td>
      </tr>
    ) : null;

    // port and protocol
    const isPortProtocol = data.port && data.protocol;
    const portProtocol = isPortProtocol ? (
      <tr className="MapInfoPanel-Row">
        <th className="MapInfoPanel-Row-Label">{intl('Port.Protocol')}</th>
        <td className="MapInfoPanel-Row-Value">{`${data.port} ${data.protocol}`}</td>
      </tr>
    ) : null;

    // slb device
    const device = data.device ? (
      <tr className="MapInfoPanel-Row">
        <th className="MapInfoPanel-Row-Label">{intl('VirtualServers.SLBDevice')}</th>
        <td className="MapInfoPanel-Row-Value">{data.device}</td>
      </tr>
    ) : null;

    return (
      <table className="MapInfoPanel">
        <tbody>
          {roleLabel}
          {appLabel}
          {envLabel}
          {locLabel}
          {policyState}
          {ip}
          {portProtocol}
          {device}
        </tbody>
      </table>
    );
  },
});
