/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {RestApiUtils} from '..';
import {ExplorerBoundaryUtils, ExplorerUtils} from '.';
import {ExplorerFilterStore, SessionStore} from '../../stores';
import {getId} from '../GeneralUtils';

/*
 * Create Boundary name
 */
function getBoundaryQueryName(id, time) {
  return `enforcement_boundaries-${id}-${time}`;
}

/*
 * Expand the Boundary services into data for the explorer API
 */
function expandServices(ingressServices) {
  let allServices = false;
  let boundaryServices = [];

  ingressServices.forEach(service => {
    const services = service.service_ports || service.windows_services || [service];

    // adjust service for the traffic query
    services.forEach(servicePort => {
      const compactService = Object.keys(servicePort).reduce((result, serviceItem) => {
        if (
          servicePort[serviceItem] &&
          serviceItem !== 'protocol' &&
          serviceItem !== 'icmp_type' &&
          serviceItem !== 'icmp_code'
        ) {
          result[serviceItem === 'service_name' ? 'windows_service_name' : serviceItem] = servicePort[serviceItem];
        }

        return result;
      }, {});

      if (compactService.port < 0) {
        delete compactService.port;
      }

      if (compactService.proto < 0) {
        delete compactService.proto;
      }

      if (Object.keys(compactService).length > 0) {
        boundaryServices.push(compactService);
      } else {
        allServices = true;
      }
    });
  });

  // If any service is 'all services' then remove everything else
  if (allServices) {
    boundaryServices = [];
  }

  return boundaryServices;
}

/*
 * Expand the endpoints for the Boundaries into the Explorer Query format
 */
const expandEndpoints = endpoints => {
  const expandedEndpoints = endpoints.reduce(
    (result, endpoint) => {
      if (endpoint.label_group) {
        result.labels[endpoint.label_group.key] ||= [];

        result.labels[endpoint.label_group.key].push({label_group: {href: endpoint.label_group.href}});
      } else if (endpoint.label) {
        result.labels[endpoint.label.key] ||= [];

        result.labels[endpoint.label.key].push({label: {href: endpoint.label.href}});
      } else if (endpoint.ip_list) {
        result.ipLists.push({ip_list: {href: endpoint.ip_list.href}});
      } else if (endpoint.actors) {
        result.allWorkloads.push(endpoint);
      }

      return result;
    },
    {labels: {}, ipLists: [], allWorkloads: []},
  );

  if (expandedEndpoints.allWorkloads.length) {
    expandedEndpoints.labels = {};
  }

  return ExplorerUtils.getNestedEndpointQuery(expandedEndpoints);
};

async function getBoundaryData(id, pversion, time, setPollingId, useBackendCache, useStoreCache) {
  const response = await RestApiUtils.enforcementBoundaries.get(id, pversion, true, {
    representation: 'labels_workloads_count',
  });

  const boundary = response.body;

  if (!boundary) {
    return;
  }

  const {name, consumers, providers, ingress_services} = boundary;

  const expandedConsumers = expandEndpoints(consumers);
  const expandedProviders = expandEndpoints(providers);
  const boundaryServices = expandServices(ingress_services);

  const links = ExplorerFilterStore.getAggregatedTableLinks(`boundaries${getId(boundary.href)}`) || [];

  // If we aren't using the storeCache, or there is nothing in the store
  if (!useStoreCache || !links.length) {
    let trafficCache;

    if (useBackendCache) {
      trafficCache = await ExplorerBoundaryUtils.loadCachedBoundaryTraffic(id, time);
    }

    // If we aren't using the backend cache or it's empty
    if (!trafficCache || trafficCache === 'empty') {
      loadBoundaryTraffic(id, time, expandedConsumers, expandedProviders, boundaryServices, setPollingId);
    }
  }

  return {boundary, consumers: expandedConsumers, providers: expandedProviders, boundaryServices, name};
}

/*
 * Load the boundary traffic based on all the filled in policy data
 */
async function loadBoundaryTraffic(id, time, consumers, providers, services, setPollingId) {
  const policyDecisions = ['potentially_blocked', 'blocked', 'unknown'];
  const boundaryDecisions = [];
  const async = SessionStore.isAsyncExplorerEnabled();

  const data = ExplorerUtils.getQueryData(
    consumers,
    providers,
    services,
    time,
    policyDecisions,
    boundaryDecisions,
    'and',
  );

  if (async) {
    const response = await RestApiUtils.trafficFlows.async.create({
      ...data,
      query_name: getBoundaryQueryName(id, time),
      exclude_workloads_from_ip_list_query: false,
    });
    const queryHref = response?.body?.href;

    if (queryHref) {
      await RestApiUtils.trafficFlows.async.getCollection();

      const options = {force: true, type: 'appGroup', href: `boundaries${id}`};

      ExplorerUtils.pollQuery([queryHref], setPollingId, [options, options]);
    }
  } else if (data) {
    RestApiUtils.trafficFlows.get(data, true, 'appGroup', `boundaries${id}`);
  }
}

/*
 * Find all or the latest queries for this boundary and time
 */
async function getMatchingCachedBoundaryTraffic(id, time, latest) {
  const boundaryName = getBoundaryQueryName(id, time);

  const response = await RestApiUtils.trafficFlows.async.getCollection();

  const matchedQueries = (response.body || [])
    .filter(query => query.query_parameters?.query_name.includes(boundaryName))
    .sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
    });

  // If looking for just the latest send the first sorted, otherwise everything
  return matchedQueries.slice(0, latest ? 1 : matchedQueries.length);
}

/*
 * Load traffic cached for this boundary and time
 */
async function loadCachedBoundaryTraffic(id, time) {
  let cache = 'empty';

  if (!SessionStore.isAsyncExplorerEnabled()) {
    return cache;
  }

  const matchingCacheQueries = await getMatchingCachedBoundaryTraffic(id, time, true);

  for (const index in matchingCacheQueries) {
    const query = matchingCacheQueries[index];
    const name = query.query_parameters.query_name;

    if (ExplorerUtils.isQueryComplete(query?.status)) {
      const options = {force: true, type: 'appGroup', href: `boundaries${id}`};

      await RestApiUtils.trafficFlows.async.getResults(getId(query.href), options);

      cache = name.includes('truncated') ? 'truncated' : 'complete';
    }
  }

  return cache;
}

/*
 * Remove all queries for this boundary and time
 */
async function removeCachedBoundaryTraffic(id, time) {
  if (!SessionStore.isAsyncExplorerEnabled()) {
    return;
  }

  const matchingCacheQueries = await getMatchingCachedBoundaryTraffic(id, time);

  matchingCacheQueries.forEach(query => {
    if (query && !ExplorerUtils.isQueryPending(query.status)) {
      RestApiUtils.trafficFlows.async.delete(getId(query.href));
    }
  });
}

export default {
  getBoundaryData,
  loadBoundaryTraffic,
  loadCachedBoundaryTraffic,
  removeCachedBoundaryTraffic,
};
