/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {SessionStore, OrgStore} from '../stores';

export default {
  // ---Supercluster Support---
  // On leader PCEs, and non supercluster PCEs all permissions should be enforced normally.
  // On member PCEs, users should only have access to read only items on pages (there are some exceptions)
  // These exceptions are Blocked Traffic, SSO config, and Support Reports APIs. These exceptions will have normal permission enforcement on member PCEs.
  //
  // To support this some permission functions now have a default parameter: checkSuperclusterReadOnly=true;
  // If checkSuperclusterReadOnly is true and isSuperclusterMember() is true, the permission functions will behave as if the user is read only.

  // ---Using OrgStore and SessionStore for Permissions---
  // The permission checking functions rely first on the OrgStore.getCurrentUserRoles and then the SessionStore.getUserRoles as backup.
  // OrgStore uses the RestApiUtil.user.org call with the org_permissions representation. This gets permissions for a specific user.
  // The OrgStore is checked first because the permissions are more up to date than the Session.

  // User is Read Only-All|All|All and has no other role scopes
  // Used where RBAC is applied (rulesets pages, Illumination)
  isUserReadOnlyAll(checkSuperclusterReadOnly = true) {
    if (checkSuperclusterReadOnly && SessionStore.isSuperclusterMember()) {
      return true;
    }

    if (SessionStore.hasValidSession()) {
      const roles = OrgStore.getCurrentUserRoles() || SessionStore.getUserRoles();

      if (roles.length === 1 && roles[0] === 'read_only') {
        return true;
      }
    }

    return false;
  },

  // User is either read only or only has provisioner specific roles
  isUserReadOnlyOrProvisioner(checkSuperclusterReadOnly = true) {
    if (checkSuperclusterReadOnly && SessionStore.isSuperclusterMember()) {
      return true;
    }

    if (SessionStore.hasValidSession()) {
      const roles = OrgStore.getCurrentUserRoles() || SessionStore.getUserRoles();

      return (
        (roles.includes('read_only') || roles.includes('ruleset_provisioner') || roles.includes('ruleset_viewer')) &&
        !roles.includes('limited_ruleset_manager') &&
        !roles.includes('ruleset_manager') &&
        !roles.includes('admin') &&
        !roles.includes('owner')
      );
    }

    return false;
  },

  // User might have limited Admin access to narrow scopes, but is effectively read only for entire UI
  isUserReadOnly(checkSuperclusterReadOnly = true) {
    if (checkSuperclusterReadOnly && SessionStore.isSuperclusterMember()) {
      return true;
    }

    if (SessionStore.hasValidSession()) {
      const roles = OrgStore.getCurrentUserRoles() || SessionStore.getUserRoles();

      return !roles.includes('owner') && !roles.includes('admin');
    }

    return false;
  },

  doesUserHaveGlobalObjectPermissions(checkSuperclusterReadOnly = true) {
    if (checkSuperclusterReadOnly && SessionStore.isSuperclusterMember()) {
      return false;
    }

    const roles = OrgStore.getCurrentUserRoles() || SessionStore.getUserRoles();

    return this.isUserAdmin() || this.isUserOwner() || roles.includes('global_object_provisioner');
  },

  isUserAdmin() {
    if (SessionStore.hasValidSession()) {
      const currentUserRoles = OrgStore.getCurrentUserRoles();

      return currentUserRoles ? currentUserRoles.includes('admin') : SessionStore.isUserAdmin();
    }

    return false;
  },

  isUserOwner() {
    if (SessionStore.hasValidSession()) {
      const currentUserRoles = OrgStore.getCurrentUserRoles();

      return currentUserRoles ? currentUserRoles.includes('owner') : SessionStore.isUserOwner();
    }

    return false;
  },

  isSuperclusterUser() {
    return Boolean(SessionStore.getClusterType());
  },

  /* Check to determine if a specific workload has write, provision, etc... ability from the caps workload property
   * @param {Object} specificItem: - information for specific item
   * @param {sting} type - cap permission type: 'write', 'provision', etc...
   *
   * @returns {Boolean}
   */
  checkCapsAbility(specificItem = {}, type) {
    return Array.isArray(specificItem.caps) && specificItem.caps.length ? specificItem.caps.includes(type) : false;
  },

  /* Check to determine if the User's permission is a not a workload_manager and not Admin or Owner.
   * Admin and Owner has rights to everything regardless if they do not have workload_manager
   * e.g. roles - e.g. ['workload_manager', 'ruleset_manager'] permission from backend
   * Note: admin and owner are never read only
   */
  isWorkloadManagerReadOnly() {
    const roles = OrgStore.getCurrentUserRoles() || SessionStore.getUserRoles();

    return roles.includes('workload_manager') ? false : this.isUserReadOnly();
  },

  isWorkloadManager() {
    const roles = OrgStore.getCurrentUserRoles() || SessionStore.getUserRoles();

    return Boolean(roles.includes('workload_manager'));
  },

  isUserExternal() {
    return SessionStore.isUserExternal();
  },
};
