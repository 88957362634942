/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import {State, Navigation} from 'react-router';
import {TabTo} from '../../components';

export default React.createClass({
  mixins: [State, Navigation],

  propTypes: {
    type: PropTypes.oneOf(['appgroups', 'groups']),
    active: PropTypes.oneOf([
      'workloads',
      'containerWorkloads',
      'virtualServices',
      'rules',
      'pairingProfiles',
      'vulnerabilities',
      'map',
    ]),
  },

  defaultProps: {
    type: 'appgroups',
    active: 'workloads',
  },

  render() {
    const {items, active} = this.props;

    return (
      <div
        className="OptionSelect"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        data-tid="comp-optionSelect"
      >
        <div
          className={`OptionSelect-item ${active === 'workloads' ? 'OptionSelect-item--active' : ''}`}
          data-tid="comp-optionSelectitem-workloads"
        >
          <TabTo to="appGroupWorkloads" params={{id: this.getParams().id}}>
            {intl('Common.Workloads')}
          </TabTo>
        </div>
        {items.includes('virtualServers') ? (
          <div
            className={`OptionSelect-item ${active === 'virtualServers' ? 'OptionSelect-item--active' : ''}`}
            data-tid="comp-optionSelectitem-virtual-servers"
          >
            <TabTo to="appGroupVirtualServers" params={{id: this.getParams().id}}>
              {intl('Common.VirtualServers')}
            </TabTo>
          </div>
        ) : null}
        {items.includes('containerWorkloads') ? (
          <div
            className={`OptionSelect-item ${active === 'containerWorkloads' ? 'OptionSelect-item--active' : ''}`}
            data-tid="comp-optionSelectitem-container-workloads"
          >
            <TabTo to="appGroupContainerWorkloads" params={{id: this.getParams().id}}>
              {intl('Common.ContainerWorkloads')}
            </TabTo>
          </div>
        ) : null}
        {items.includes('virtualServices') ? (
          <div
            className={`OptionSelect-item ${active === 'virtualServices' ? 'OptionSelect-item--active' : ''}`}
            data-tid="comp-optionSelectitem-virtual-services"
          >
            <TabTo to="appGroupVirtualServices" params={{id: this.getParams().id}}>
              {intl('Common.VirtualServices')}
            </TabTo>
          </div>
        ) : null}
        {items.includes('pairingProfiles') ? (
          <div
            className={`OptionSelect-item ${active === 'pairingProfiles' ? 'OptionSelect-item--active' : ''}`}
            data-tid="comp-optionSelectitem-pairingprofiles"
          >
            <TabTo to="appGroupPairingProfiles" params={{id: this.getParams().id}}>
              {intl('PairingProfiles.Profiles')}
            </TabTo>
          </div>
        ) : null}
      </div>
    );
  },
});
