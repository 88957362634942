/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import {Form} from '../FormComponents';

export default React.createClass({
  propTypes: {
    type: PropTypes.string,
    model: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  },

  getInitialState() {
    return {
      model: {},
      errorModel: {},
      initialModel: {},
    };
  },

  componentWillMount() {
    this.formFields = [
      {
        type: 'field',
        label: 'Name',
        name: 'primary',
        required: true,
        controls: [
          {
            type: 'text',
            maxlength: 20,
            name: 'primary',
            placeholder: 'Enter a unique name no more than 20 characters',
          },
        ],
      },
      {
        type: 'field',
        label: 'Name 2',
        name: 'secondary',
        required: true,
        controls: [
          {
            type: 'text',
            maxlength: 20,
            name: 'secondary',
            placeholder: 'Enter a unique name no more than 20 characters',
          },
        ],
      },
    ];
  },

  componentDidMount() {
    this.setInitialModel(this.props.model);
  },

  componentWillReceiveProps(nextProps) {
    if (this.areModelsDifferent(this.state.initialModel, this.getFriendlyModel(nextProps.model))) {
      this.setInitialModel(nextProps.model);
    }
  },

  getFriendlyModel(unfriendlyModel) {
    return unfriendlyModel
      ? {
          id: unfriendlyModel.id,
          primary: unfriendlyModel.name,
          secondary: unfriendlyModel.name2,
        }
      : {};
  },

  setInitialModel(initialModel) {
    const model = this.getFriendlyModel(initialModel);

    this.setState({model, initialModel: model});
  },

  handleChange(modelUpdates) {
    this.setState(previousState => ({model: {...previousState.model, ...modelUpdates}}));
  },

  handleErrorChange(errorModelUpdates) {
    this.setState(previousState => ({errorModel: {...previousState.errorModel, ...errorModelUpdates}}));
  },

  handleSubmit() {
    const model = {
      id: this.state.model.id,
      name: this.state.model.primary,
      name2: this.state.model.secondary,
    };

    this.props.onSubmit(model);
  },

  areModelsDifferent(original, compare) {
    return original.primary !== compare.primary || original.secondary !== compare.secondary;
  },

  hasChanged() {
    return this.areModelsDifferent(this.state.initialModel, this.state.model);
  },

  render() {
    return (
      <Form
        type={this.props.type}
        fields={this.formFields}
        model={this.state.model}
        errorModel={this.state.errorModel}
        onChange={this.handleChange}
        onErrorChange={this.handleErrorChange}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
      />
    );
  },
});
