/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';

StepCards.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function StepCards(props) {
  return (
    <div className="StepCardsHeightChange">
      <div className="StepCards">
        {props.steps.map((step, idx) => (
          <div className="StepCards-Step" key={step}>
            <div className="StepCards-Step-Count">{idx + 1}</div>
            <div className="StepCards-Step-Content">{step}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
