/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import {State} from 'react-router';
import {DateTimePicker} from '../DateTime';
import ObjectSelector from '../ObjectSelector.jsx';
import {generateKey} from '../../utils/GeneralUtils';
import {LimitedReverseProtocolMap} from '../../utils/ServiceUtils';
import {IpUtils, PortProtocolsUtils, RestApiUtils, WorkloadUtils, RulesetUtils} from '../../utils';
import {generalUtils} from '@illumio-shared/utils/shared';

const anyIpName = intl('IPLists.Any');
const facetMap = () => ({
  [intl('Common.Labels')]: 'labels',
  [intl('Common.Workloads')]: 'workloads',
  [intl('Common.Services')]: 'services',
  [intl('Common.IPLists')]: 'ipLists',
  [intl('Labels.Groups')]: 'labelGroups',
  [intl('Common.UserGroups')]: 'securityPrincipals',
  ...(!__ANTMAN__ && {
    [intl('Common.VirtualServers')]: 'virtualServers',
    [intl('Common.VirtualServices')]: 'virtualServices',
  }),
  [intl('Port.Port')]: PortProtocolsUtils.PORT_PROTOCOL,
  [intl('Port.PortRange')]: PortProtocolsUtils.PORT_RANGE,
  [intl('Common.IPAddress')]: 'ip_address',
  [intl('RuleSearch.WindowsProcessName')]: 'windows_service',
  [intl('RuleSearch.WindowsServiceName')]: 'windows_process',
  [intl('Common.Note')]: 'note',
  [intl('RuleSearch.RulesetName')]: 'rulesetName',
});
const labelFacets = ['labels', 'labelGroups'];
const selectorFacetList = [
  intl('Common.MachineAuthentication'),
  intl('Common.Protocol'),
  intl('Common.SecureConnect'),
  intl('Common.Stateless'),
  intl('Common.Status'),
  intl('Provision.Status'),
  intl('RuleSearch.CreatedAtUc'),
  intl('RuleSearch.UpdatedAtUc'),
  intl('RuleSearch.RulesetName'),
];

export default React.createClass({
  mixins: [State],

  propTypes: {
    type: PropTypes.oneOf(['providers_or_consumers', 'providers', 'consumers']).isRequired,
    pversion: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  },

  getInitialState() {
    const singleValues = {[intl('Workloads.All')]: {allWorkloads: true}};

    if (this.props.type.includes('providers')) {
      Object.assign(singleValues, {
        [intl('Common.AllServices')]: {allServices: true},
        ...(!__ANTMAN__ && {[intl('PolicyGenerator.ExtraScopeRules')]: {extraScopeRules: true}}),
      });
    }

    return {
      items: {},
      dropdownValues: {},
      singleValues,
    };
  },

  async componentDidMount() {
    this.setStateFromProps(this.props);
    this.loadDefaultValues(this.props);
    this.getFacetPortPortocols([
      'portProtocol',
      'portRange',
      'ip_address',
      'windows_service',
      'windows_process',
      'note',
    ]);

    const {body} = await RestApiUtils.users.getCollection();

    this.setState({users: body});
  },

  getFacetPortPortocols(facets) {
    const dropdownValues = facets.reduce((data, curValue) => {
      if (!curValue) {
        return data;
      }

      data = {...data, [`${curValue}-`]: {matches: [], num_matches: 0}};

      return data;
    }, {});

    this.setState({dropdownValues});
  },

  componentWillReceiveProps(nextProps) {
    if (this.isMounted() && nextProps.selected !== this.state.initialItems) {
      this.setStateFromProps(nextProps);
    }
  },

  componentDidUpdate(prevProps) {
    if (prevProps.pversion !== this.props.pversion) {
      this.props.onChange({}, this.props.type);
      this.setState({dropdownValues: {}});
      this.loadDefaultValues(this.props);

      return;
    }

    if (
      generalUtils.sortAndStringifyArray(RulesetUtils.getSelectedLabelsHrefs(prevProps.selected)) !==
      generalUtils.sortAndStringifyArray(RulesetUtils.getSelectedLabelsHrefs(this.props.selected))
    ) {
      labelFacets.forEach(facet => {
        this.getFacetValues(facet);
      });
    }
  },

  getCustomClass(value, facet) {
    const {deleted, key} = value || {};
    const isDateTime = [intl('RuleSearch.CustomCreatedAtRange'), intl('RuleSearch.CustomUpdatedAtRange')].includes(
      facet,
    );
    let additionalClass = '';

    if (isDateTime) {
      additionalClass = 'ObjectSelector-item--DateTime';
    } else if (deleted) {
      additionalClass = 'ObjectSelector-item--workload--unpaired';
    } else if (key) {
      additionalClass = `ObjectSelector-item--label--${key}`;
    }

    return additionalClass;
  },

  async getFacetValues(facet, query = '') {
    if (!facet || /(created|updated)_at/.test(facet)) {
      return;
    }

    // For RuleSearch, selected scope only applicable to labels and labels groups API end points.
    const selectedScope = labelFacets.includes(facet) ? RulesetUtils.getSelectedScope(facet, this.props.selected) : [];

    const facetOpts = {
      resource_type: 'rule_sets',
      query,
      max_results: 25,
      ...(selectedScope.length && {selected_scope: JSON.stringify(selectedScope)}),
    };
    let facetKey;

    if (facet.includes('labels') && facet !== 'labels') {
      facetKey = 'labels';
      facetOpts.key = facet.split('-').pop();
    } else {
      facetKey = facet;
    }

    if (facet === 'ip_address') {
      this.setIpAddress(facet, query);
    } else if (['portProtocol', 'portRange', 'windows_service', 'windows_process', 'note'].includes(facet)) {
      PortProtocolsUtils.setPortProtocol.call(this, facet, query);
    } else {
      let response;

      if (facet === 'rulesetName') {
        response = await RestApiUtils.ruleSets.facets({
          facet: 'name',
          query,
          ...(selectedScope.length && {selected_scope: JSON.stringify(selectedScope)}),
        });
      } else {
        response = await RestApiUtils[facetKey].autocomplete(this.props.pversion, facetOpts);
      }

      const dropdownValues = {
        ...this.state.dropdownValues,
        [`${facet}-${query}`]: response.body,
      };

      this.setState({dropdownValues});
    }
  },

  setIpAddress(key, query) {
    let address;

    if (IpUtils.validateIPAddress(query)) {
      address = query;
    }

    this.setDropdownValues(key, query, {
      matches: address
        ? [
            {
              key,
              value: address,
              href: address,
            },
          ]
        : [],
      num_matches: address ? 1 : 0,
    });
  },

  setDropdownValues(key, query, values) {
    const dropdownValues = {...this.state.dropdownValues};

    if (
      !(
        (key === 'portProtocol' ||
          key === 'portRange' ||
          key === 'ip_address' ||
          key === 'windows_service' ||
          key === 'windows_process' ||
          key === 'note') &&
        dropdownValues.hasOwnProperty(`${key}-${query}`)
      )
    ) {
      dropdownValues[`${key}-${query}`] = values;
    }

    this.setState({dropdownValues});
  },

  setStateFromProps(props) {
    const newState = {};

    newState.initialItems = props.selected;
    newState.items = props.selected ?? {};

    Object.keys(facetMap()).forEach(item => {
      if (this.state.items[item] === null && !newState.items[item]) {
        newState.items[item] = null;
      }
    });

    this.setState(newState);
  },

  handleChange(items) {
    this.setState({items}, () => {
      this.props.onChange(items, this.props.type);
    });
  },

  focusDateTime() {
    if (!this.dateTime) {
      return;
    }

    const className = '.DTPicker-Section--From .ObjectSelector-input-elem';
    const inputNode = findDOMNode(this.dateTime).querySelector(className);

    inputNode.focus();
  },

  addItem(item, value) {
    let moveIpToBeginning = false;

    if (item === anyIpName) {
      item = intl('Common.IPLists');
      moveIpToBeginning = true;
    }

    // let items = {...this.state.items};
    let items = _.cloneDeep(this.state.items);

    if (!value && !items[item]) {
      items[item] = null;
    } else if (value) {
      if (Object.keys(this.state.singleValues).includes(item)) {
        // If a singleValue, then always add it to the beginning so that
        // the dropdown list doesn't hide it
        items = {[item]: [value], ...items};
      } else {
        if (value === intl('RuleSearch.CustomCreatedAtRange')) {
          this.setState({dateTimePicker: 'createdAt'}, this.focusDateTime);

          return;
        }

        if (value === intl('RuleSearch.CustomUpdatedAtRange')) {
          this.setState({dateTimePicker: 'updatedAt'}, this.focusDateTime);

          return;
        }

        items[item] ||= [];
        items[item].push(value);

        if (
          (moveIpToBeginning ||
            [
              intl('Common.Status'),
              intl('Common.Protocol'),
              intl('Common.MachineAuthentication'),
              intl('Common.Stateless'),
              intl('Common.SecureConnect'),
              intl('Common.CreatedBy'),
              intl('Common.UpdatedBy'),
              intl('Provision.Status'),
              intl('RuleSearch.CreatedAtUc'),
              intl('RuleSearch.UpdatedAtUc'),
              intl('RuleSearch.RulesetName'),
              intl('Common.Note'),
            ].includes(item)) &&
          items[item].length === 1
        ) {
          const temp = items[item];

          delete items[item];
          items = {[item]: temp, ...items};
        }
      }
    }

    for (const [key, value] of Object.entries(items)) {
      if (!value && key !== item) {
        delete items[key];
      }
    }

    this.handleChange(items);
  },

  loadDefaultValues() {
    Object.values(facetMap()).forEach(facet => {
      this.getFacetValues(facet);
    });
  },

  removeItem(item) {
    const items = {...this.state.items};
    const deleteItem = Array.isArray(items[item]) ? items[item].at(-1) : items[item];

    /** Check to determine if the current element that is being deleting matches exclusive items to not delete */
    const discardRemove = this.props.disableSpecificItems.some(ele => _.isEqual(ele, deleteItem));

    if (discardRemove) {
      return;
    }

    delete items[item];
    this.handleChange(items);
  },

  removeMulti(item, singleItem) {
    const items = _.omitBy({...this.state.items}, _.isEmpty);

    /** Get the current last element in the array that is being deleted with 1. mouseClick or 2. delete button */
    const deleteItem = this.state.items[item]?.slice(-1)?.[0] ?? {};

    /** Check to determine if the current element that is being deleting matches exclusive items to not delete */
    const discardRemove = this.props.disableSpecificItems.some(ele => {
      if (ele?.name && deleteItem?.name) {
        return ele.name === deleteItem.name;
      }

      if (ele?.href && deleteItem?.href) {
        return ele.href === deleteItem.href;
      }

      return false;
    });

    if (discardRemove) {
      return;
    }

    if (singleItem) {
      items[item] = items[item].filter(item => generateKey(item) !== generateKey(singleItem));
    } else if (items[item]) {
      items[item] = items[item].slice(0, -1);

      if (items[item] && !items[item].length) {
        delete items[item];
      }
    } else {
      const itemKeys = Object.keys(items);
      const lastItem = itemKeys.at(-1);

      items[lastItem] = items[lastItem].slice(0, -1);

      if (items[lastItem] && !items[lastItem].length) {
        delete items[lastItem];
      }
    }

    this.handleChange(items);
  },

  returnValue(value, facet) {
    if (!value) {
      return '';
    }

    if (facet === intl('Common.CreatedBy') || facet === intl('Common.UpdatedBy')) {
      const item = Array.isArray(value) ? value[0] : value;

      return item.full_name || item.username;
    }

    if (selectorFacetList.includes(facet)) {
      return Array.isArray(value) ? value[0] : value;
    }

    if (facet === 'initial' || facet === intl('Common.Labels')) {
      return value.value;
    }

    if (facet === intl('Common.Workloads')) {
      return WorkloadUtils.friendlyName(value);
    }

    if (value === null) {
      return;
    }

    return value.name || value.value || (typeof value === 'string' ? value : '');
  },

  getDateFromValue(value) {
    // 'now' and 'custom' handled in the DateTime component
    // All the other values are objects of amount of time to subtract
    return value;
  },

  render() {
    const {dateTimePicker, users} = this.state;
    const props = {
      facetMap: facetMap(),
      enableScroll: true,
      ref: 'objectSelector',
      hasTitle: [intl('Common.Workloads')],
      disabled: this.props.disabled,
      // Use to disable items from being deleted
      disableSpecificItems: this.props.disableSpecificItems,
      defaultSelected: intl('Common.Labels'),
      customClassItems: [
        intl('Common.Labels'),
        intl('Common.Workloads'),
        intl('Common.Timestamp'),
        intl('RuleSearch.CustomCreatedAtRange'),
        intl('RuleSearch.CustomUpdatedAtRange'),
      ],
      items: this.state.items,
      singleValues: {...this.state.singleValues},
      dropdownValues: this.state.dropdownValues,
      autoFocus: this.props.autoFocus,
      addItem: this.addItem,
      removeItem: this.removeItem,
      removeMulti: this.removeMulti,
      returnValue: this.returnValue,
      getFacetValues: this.getFacetValues,
      getCustomClass: this.getCustomClass,
      showFacetItems: [
        ...Object.keys(facetMap()).filter(item => item !== intl('Labels.Groups')),
        intl('Common.Status'),
        intl('Common.Protocol'),
        intl('Common.MachineAuthentication'),
        intl('Common.Stateless'),
        intl('Common.SecureConnect'),
        intl('Common.CreatedBy'),
        intl('Common.UpdatedBy'),
        intl('Provision.Status'),
        intl('RuleSearch.CreatedAtUc'),
        intl('RuleSearch.UpdatedAtUc'),
        intl('RuleSearch.RulesetName'),
        intl('Common.Note'),
        intl('Port.Port'),
        intl('Port.PortRange'),
        intl('Common.IPAddress'),
        intl('RuleSearch.WindowsProcessName'),
        intl('RuleSearch.WindowsServiceName'),
      ],
      partialItems: [intl('RuleSearch.RulesetName')],
      switchToActiveForThese: [
        intl('Common.Status'),
        intl('Common.Protocol'),
        intl('Common.MachineAuthentication'),
        intl('Common.Stateless'),
        intl('Common.SecureConnect'),
        intl('Common.CreatedBy'),
        intl('Common.UpdatedBy'),
        intl('Provision.Status'),
        intl('RuleSearch.CreatedAtUc'),
        intl('RuleSearch.UpdatedAtUc'),
        intl('RuleSearch.RulesetName'),
        intl('Common.Note'),
      ],
    };
    const tid = 'comp-entityselect filter-rules rulesearch';
    let dateTimePickerProps;

    if (this.state.items[intl('Common.IPLists')]) {
      const anyIpExists = this.state.items[intl('Common.IPLists')].some(ipList => ipList.name.includes(anyIpName));

      if (anyIpExists) {
        delete props.singleValues[anyIpName];
      }
    }

    switch (this.props.type) {
      case 'providers_or_consumers':
        props.placeholder = intl('RuleSearch.Placeholders.ProvidersOrConsumers');
        props.forceNotEmptyPlaceholder = intl('RuleSearch.Placeholders.ProvidersOrConsumers');
        break;
      case 'providers':
        props.facetMap = {...props.facetMap};
        delete props.facetMap[intl('Common.UserGroups')];
        props.placeholder = intl('RuleSearch.Placeholders.Providers');
        props.forceNotEmptyPlaceholder = intl('RuleSearch.Placeholders.Providers');
        break;
      case 'consumers':
        props.facetMap = {...props.facetMap};
        delete props.facetMap[intl('Common.VirtualServers')];
        delete props.facetMap[intl('Common.Services')];
        props.placeholder = intl('RuleSearch.Placeholders.Consumers');
        props.forceNotEmptyPlaceholder = intl('RuleSearch.Placeholders.Consumers');
        break;
    }

    const facetMapKeys = Object.keys(props.facetMap);

    props.initialValues = facetMapKeys;
    props.multiItems = facetMapKeys.filter(item => item !== intl('RuleSearch.RulesetName'));

    if (this.props.type.includes('providers')) {
      dateTimePickerProps = {
        onAdd: this.addItem,
        onRemove: this.removeItem,
        fromSingleValues: {
          [intl('DateTimeInput.CustomTime')]: 'custom',
          [intl('DateTimeInput.Anytime')]: 'anytime',
        },
        toSingleValues: {
          [intl('DateTimeInput.CustomTime')]: 'custom',
          [intl('DateTimeInput.Now')]: 'now',
        },
        getDateFromValue: this.getDateFromValue,
      };

      const customValuesComp = {
        [intl('RuleSearch.CustomCreatedAtRange')]: (
          <DateTimePicker {...dateTimePickerProps} name={intl('RuleSearch.CustomCreatedAtRange')} />
        ),
        [intl('RuleSearch.CustomUpdatedAtRange')]: (
          <DateTimePicker {...dateTimePickerProps} name={intl('RuleSearch.CustomUpdatedAtRange')} />
        ),
      };

      const dateTimeOptions = [
        intl('DateTimeInput.Last1Hour'),
        intl('DateTimeInput.Last24Hours'),
        intl('DateTimeInput.Last7days'),
        intl('DateTimeInput.Last30days'),
      ];

      props.statics = {
        [intl('Common.Status')]: [intl('Common.Enabled'), intl('Common.Disabled')],
        [intl('Common.Protocol')]: Object.keys(LimitedReverseProtocolMap),
        [intl('Common.MachineAuthentication')]: [intl('Common.On'), intl('Common.Off')],
        [intl('Common.Stateless')]: [intl('Common.On'), intl('Common.Off')],
        [intl('Common.SecureConnect')]: [intl('Common.On'), intl('Common.Off')],
      };

      if (users) {
        Object.assign(props.statics, {
          [intl('Common.CreatedBy')]: users,
          [intl('Common.UpdatedBy')]: users,
        });
      }

      Object.assign(props.statics, {
        [intl('Provision.Status')]: [
          intl('Provision.PendingAddition'),
          intl('Provision.PendingModification'),
          intl('Provision.PendingDeletion'),
        ],
        [intl('RuleSearch.CreatedAtUc')]: dateTimeOptions.map(
          value => `${intl('RuleSearch.CreatedIn')} ${value.toLocaleLowerCase()}`,
        ),
        [intl('RuleSearch.UpdatedAtUc')]: dateTimeOptions.map(
          value => `${intl('RuleSearch.UpdatedIn')} ${value.toLocaleLowerCase()}`,
        ),
      });

      const staticsKeys = Object.keys(props.statics);

      props.staticsKeys = staticsKeys;
      props.initialValues = [...facetMapKeys, ...staticsKeys];

      props.customValuesComp = customValuesComp;
      props.customValuesKeys = Object.keys(customValuesComp);

      props.staticsKeys.forEach((staticsKey, index) => {
        if (!props.statics[staticsKey]) {
          props.staticsKeys.splice(index, 1);
        }
      });
    } else {
      props.initialValues = props.initialValues.filter(
        value =>
          ![
            intl('Port.Port'),
            intl('Port.PortRange'),
            intl('RuleSearch.WindowsProcessName'),
            intl('RuleSearch.WindowsServiceName'),
            intl('Common.Note'),
            intl('RuleSearch.RulesetName'),
          ].includes(value),
      );
      props.enableScroll = false;
    }

    if (props.items[`${intl('Common.Destinations')} ${intl('Common.UsesVirtualServicesWorkloads')}`]) {
      delete props.singleValues[`${intl('Common.Destinations')} ${intl('Common.UsesVirtualServices')}`];
    }

    if (props.items[`${intl('Common.Destinations')} ${intl('Common.UsesVirtualServices')}`]) {
      delete props.singleValues[`${intl('Common.Destinations')} ${intl('Common.UsesVirtualServicesWorkloads')}`];
    }

    if (props.items[`${intl('Common.Sources')} ${intl('Common.UsesVirtualServicesWorkloads')}`]) {
      delete props.singleValues[`${intl('Common.Sources')} ${intl('Common.UsesVirtualServices')}`];
    }

    if (props.items[`${intl('Common.Sources')} ${intl('Common.UsesVirtualServices')}`]) {
      delete props.singleValues[`${intl('Common.Sources')} ${intl('Common.UsesVirtualServicesWorkloads')}`];
    }

    if (props.items[intl('Common.UsesVirtualServicesWorkloads')]) {
      delete props.singleValues[intl('Common.UsesVirtualServices')];
    }

    if (props.items[intl('Common.UsesVirtualServices')]) {
      delete props.singleValues[intl('Common.UsesVirtualServicesWorkloads')];
    }

    /** RuleSearch in Enforcement Boundaries Rules no longer has All Services for Consumers has 'Selector' option */
    if (this.getPath().includes('boundaries')) {
      delete props.singleValues[intl('Common.AllServices')];
    }

    return (
      <div data-tid={tid} className="RuleSearchPicker">
        {this.props.type.includes('providers') && dateTimePicker ? (
          <DateTimePicker
            {...dateTimePickerProps}
            name={intl('RuleSearch.CustomCreatedAtRange')}
            ref={node => (this.dateTime = node)}
          />
        ) : null}
        <ObjectSelector {...props} />
      </div>
    );
  },
});
