/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {ComponentUtils} from '../../utils';

const defaultTid = 'comp-toolbar';

export default function Toolbar(props) {
  const classes = cx({
    'ReForm-Toolbar': true,
    'ReForm-Toolbar--right': props.align === 'right',
  });

  const tids = ComponentUtils.tid(defaultTid, props.tid);

  return (
    <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
      {props.children}
    </div>
  );
}
