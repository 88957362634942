/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import Constants from '../constants';
import {ServiceStore, SessionStore} from '../stores';

const labelTypes = ['app', 'env', 'loc'];

export default {
  getValidationMessage(validationConstant) {
    switch (validationConstant) {
      case Constants.REQUIRED_SCOPE_FIELDS:
        return intl('Rule.Validation.ScopeRequiresLabels');

      case Constants.REQUIRED_RULE_FIELDS:
        return intl('Rule.Validation.RequiresProviderServiceConsumer');

      case Constants.REQUIRED_RULEIPTABLES_FIELDS:
        return intl('Rule.Validation.RequiresLabel');

      case Constants.STATEMENTS_INVALID:
        return intl('Rule.Validation.CorrectAllErrors');

      case Constants.IP_LIST_ON_BOTH_SIDES:
        return intl('Rule.Validation.IPListsCantBeProviderAndConsumer');

      case Constants.SECURECONNECT_INVALID_PE_CE:
        return intl('Rule.Validation.SecureConnectCantWithIPListBoundVirtual');

      case Constants.ONLY_USER_GROUPS:
        return intl('Rule.Validation.UserGroupsWithLabelOrWorkload');

      case Constants.USER_GROUP_IP_LIST:
        return intl('Rule.Validation.UserGroupsCantWithIPListConsumer');

      case Constants.WINDOWS_SERVICE_INVALID_PE:
        return intl('Rule.Validation.ProcessServicesCantWithIPListProvider');

      case Constants.SCOPE_APP_LABEL_NOT_ALL:
        return intl('Rule.Validation.NamedAppLabelCantBeUsed', {use: intl('Common.AllApplications')});

      case Constants.SCOPE_ENV_LABEL_NOT_ALL:
        return intl('Rule.Validation.NamedEnvLabelCantBeUsed', {use: intl('Common.AllEnvironments')});

      case Constants.SCOPE_LOC_LABEL_NOT_ALL:
        return intl('Rule.Validation.NamedLocLabelCantBeUsed', {use: intl('Common.AllLocations')});

      case Constants.RULE_APP_LABEL_IN_USE:
        return intl('Rule.Validation.NamedAppLabelIsUsed', {use: intl('Common.AllApplications')});

      case Constants.RULE_ENV_LABEL_IN_USE:
        return intl('Rule.Validation.NamedEnvLabelIsUsed', {use: intl('Common.AllEnvironments')});

      case Constants.RULE_LOC_LABEL_IN_USE:
        return intl('Rule.Validation.NamedLocLabelIsUsed', {use: intl('Common.AllLocations')});

      case Constants.ALL_APP_LABEL_AND_LABEL:
      case Constants.ALL_ENV_LABEL_AND_LABEL:
      case Constants.ALL_LOC_LABEL_AND_LABEL:
        return intl('Rule.Validation.ScopeLabelInRulesetWithAllLabel');

      case Constants.DUPLICATE_SCOPE:
        return intl('Rule.Validation.DuplicateScopesNotAllowed');

      case Constants.UNPAIRED_WORKLOADS:
        return intl('Rule.Validation.RemoveUnpairedWorkloads');

      case Constants.STATELESS_WITH_SECURECONNECT:
        return intl('Rule.Validation.StatelessWithSecureConnect');

      case Constants.STATELESS_WITH_MACHINE_AUTH:
        return intl('Rule.Validation.StatelessWithMachineAuth');

      case Constants.STATELESS_WITH_LABEL_GROUP:
        return intl('Rule.Validation.StatelessWithLabelGroup');

      case Constants.STATELESS_WITH_IP_LIST:
        return intl('Rule.Validation.StatelessWithIPList');

      case Constants.STATELESS_INVALID_CONSUMERS:
        return intl('Rule.Validation.StatelessInvalidConsumers');

      case Constants.MACHINE_AUTH_WITH_ALL_SERVICES:
        return intl('Rule.Validation.MachineAuthWithAllServices');

      case Constants.MACHINE_AUTH_WITH_NULL_UB_SERVICE:
        return intl('Rule.Validation.MachineAuthWithNullService');

      case Constants.SECURECONNECT_WITH_NULL_UB_SERVICE:
        return intl('Rule.Validation.SecureConnectCantWithIPListBoundVirtual');

      case Constants.SECURECONNECT_WITH_SECURITY_PRINCIPAL:
        return intl('Rule.Validation.SecureConnectWithSecurityPrincipal');

      case Constants.MACHINE_AUTH_WITH_NULL_SERVICE:
        return intl('Rule.Validation.MachineAuthWithNullUbService');

      case Constants.SECURECONNECT_WITH_NULL_SERVICE:
        return intl('Rule.Validation.SecureConnectWithNullUbService');

      case Constants.PE_IP_LIST_WITH_MACHINE_AUTH:
        return intl('Rule.Validation.ProviderIpListWithMachineAuth');

      case Constants.CE_IP_LIST_WITH_MACHINE_AUTH:
        return intl('Rule.Validation.ConsumerIpListWithMachineAuth');

      case Constants.PE_WITH_VIRTUAL_SERVICE_VIRTUAL_SERVER:
        return intl('Rule.Validation.ProviderWithVirtualService');

      case Constants.PE_WITH_IP_LIST_SCOPEDUSER:
        return intl('Rule.Validation.ProviderWithIpListScopedUser');

      case Constants.PE_WITH_IP_LIST_WORKLOAD:
        return intl('Rule.Validation.ProviderWithIpListWorkload');

      case Constants.PE_WITH_IP_LIST_WINDOWS:
        return intl('Rule.Validation.ProviderIpListWindowsService');

      case Constants.PE_WITH_IP_VS_VS_W:
        return intl('Rule.Validation.ProviderIpListVirtualServiceVirtualServerWorkload');

      case Constants.CE_WITH_VIRTUAL_SERVICE:
        return intl('Rule.Validation.ConsumerWithVirtualService');

      case Constants.CE_FROM_CONSUMERS_BOTH:
        return intl('Rule.Validation.BothVirtualServicesOnlyVirtualServicesWorkloads');

      case Constants.CE_WITH_IP_LIST_WORKLOAD:
        return intl('Rule.Validation.ConsumerIpListWorkload');

      case Constants.CE_WITH_IP_VS_W:
        return intl('Rule.Validation.ConsumerIpListVirtualServiceVirtualServerWorkload');

      case Constants.PE_WITH_VS_SERVICES:
        return intl('Rule.Validation.ProvidingServicesVirtualServices');

      case Constants.PE_WITH_VIRTUAL_SERVICE:
        return intl('Rule.Validation.ProviderWithVirtualService');

      case Constants.CH_PE_INVALID:
        return intl('Rule.Validation.ContainerHostProviders');

      case Constants.CH_BY_ITSELF:
        return intl('Rule.Validation.ContainerHostItself');

      case Constants.CH_STATELESS_MACHINE_AUTH_SECURECONNECT:
        return intl('Rule.Validation.ContainerHostSecureConnectMachineAuthStateless');

      case Constants.CH_WINDOWS_SERVICE:
        return intl('Rule.Validation.ContainerHostWindowsService');

      case Constants.CH_NON_TCP_SERVICE:
        return intl('Rule.Validation.ContainerHostNonTcpService');

      case Constants.NON_CORPORATE_MUST_USE_IP_LIST:
        return intl('Rule.Validation.NonCorporateMustUseIpList');

      case Constants.CANT_USE_SECURE_CONNECT_WITH_NON_DEFAULT_NETWORK:
        return intl('Rule.Validation.CantUseSecureConnectWithNonDefaultNetwork');

      case Constants.CANT_USE_MACHINE_AUTH_WITH_NON_DEFAULT_NETWORK:
        return intl('Rule.Validation.CantUseMachineAuthWithNonDefaultNetwork');

      case Constants.NETWORK_TYPE_MUST_BE_CORPORATE:
        return intl('Rule.Validation.NetworkTypeMustBeDefaultCorporate');

      case Constants.CONSUMER_MUST_HAVE_ONLY_IP_LISTS:
        return intl('Rule.Validation.ConsumerMustHaveOnlyIPLists');

      case Constants.PROVIDER_MUST_HAVE_ONLY_IP_LISTS:
        return intl('Rule.Validation.ProviderMustHaveOnlyIPLists');

      default:
        return '';
    }
  },

  validateScopeRule(options) {
    const {
      scopes,
      providers,
      ingressServices,
      resolveLabelsAs,
      secureConnect,
      machineAuth,
      consumers,
      consumingSecurityPrincipals,
      unscopedConsumers,
      isSubmitted,
      stateless,
      isExtraScopeRules,
      networkType,
    } = options;
    const validationErrors = [];

    const resolveLabelsAsProviders = (resolveLabelsAs || {}).providers || [];
    const providersWorkloadsOnly = resolveLabelsAsProviders.length === 1 && resolveLabelsAsProviders[0] === 'workloads';

    let providerHasAnyIp = false;
    let providerHasAppLabel = false;
    let providerHasEnvLabel = false;
    let providerHasLocLabel = false;
    let providerHasIpList = false;
    let providerHasWorkload = false;
    let providerHasVirtualServer = false;
    let providerHasVirtualService = false;
    let providerHasUnpairedWorkload = false;
    let providerHasUsesVirtualServicesWorkloads = false;
    let providerHasUsesVirtualServices = false;
    let providerHasLabelGroup = false;
    const providerHasOnlyIpLists = providers?.every(({ip_list}) => Boolean(ip_list));

    _.each(providers, entity => {
      if (entity.label || entity.label_group) {
        const key = entity.label ? entity.label.key : entity.label_group.key;

        switch (key) {
          case 'app':
            providerHasAppLabel = true;
            break;
          case 'env':
            providerHasEnvLabel = true;
            break;
          case 'loc':
            providerHasLocLabel = true;
            break;
        }

        if (entity.label_group) {
          providerHasLabelGroup = true;
        }
      } else if (entity.ip_list) {
        providerHasIpList = true;

        if (entity.ip_list.name === intl('IPLists.Any')) {
          providerHasAnyIp = true;
        }
      } else if (entity.workload) {
        providerHasWorkload = true;

        if (entity.workload.deleted) {
          providerHasUnpairedWorkload = true;
        }
      } else if (entity.virtual_server) {
        providerHasVirtualServer = true;
      } else if (entity.virtual_service) {
        providerHasVirtualService = true;
      } else if (entity.usesVirtualServicesWorkloads) {
        providerHasUsesVirtualServicesWorkloads = true;
      } else if (entity.usesVirtualServices) {
        providerHasUsesVirtualServices = true;
      }
    });

    const resolveLabelsAsConsumers = (resolveLabelsAs || {}).consumers || [];
    const consumersWorkloadsOnly = resolveLabelsAsConsumers.length === 1 && resolveLabelsAsConsumers[0] === 'workloads';
    const consumerHasUserGroup = consumingSecurityPrincipals && consumingSecurityPrincipals.length > 0;
    const consumerHasVirtualServer = false;
    const consumerLabelsKeyBoolMap = {};
    let consumerHasAnyIp = false;
    let consumerHasAppLabel = false;
    let consumerHasEnvLabel = false;
    let consumerHasLocLabel = false;
    let consumerHasRoleLabel = false;
    let consumerHasIpList = false;
    let consumerHasVirtualService = false;
    let consumerHasLabelGroup = false;
    let consumerHasWorkload = false;
    let consumerHasAllWorkload = false;
    let consumerHasContainerHost = false;
    let consumerHasUnpairedWorkload = false;
    let validStatelessConsumerLabels = true;
    let consumerLabelsCount = 0;
    let consumerHasUsesVirtualServicesWorkloads = false;
    let consumerHasUsesVirtualServices = false;
    const consumerHasOnlyIpLists = consumers?.every(({ip_list}) => Boolean(ip_list));

    const scopeLabelTypeMap = {};
    let windowsService = false;

    _.each(consumers, entity => {
      if (entity.label || entity.label_group) {
        const key = entity.label ? entity.label.key : entity.label_group.key;

        switch (key) {
          case 'app':
            consumerHasAppLabel = true;
            break;
          case 'env':
            consumerHasEnvLabel = true;
            break;
          case 'loc':
            consumerHasLocLabel = true;
            break;
          case 'role':
            consumerHasRoleLabel = true;
            break;
        }

        if (entity.label) {
          consumerLabelsCount++;

          if (stateless && validStatelessConsumerLabels) {
            // Stateless Rules only allows one of
            // each kind of Label key in consumers
            if (consumerLabelsKeyBoolMap[key]) {
              validStatelessConsumerLabels = false;
            } else {
              consumerLabelsKeyBoolMap[key] = true;
            }
          }
        } else {
          consumerHasLabelGroup = true;
        }
      } else if (entity.ip_list) {
        consumerHasIpList = true;

        if (entity.ip_list.name === intl('IPLists.Any')) {
          consumerHasAnyIp = true;
          console.log('true2');
        }
      } else if (entity.virtual_service) {
        consumerHasVirtualService = true;
      } else if (entity.workload) {
        consumerHasWorkload = true;

        if (entity.workload.deleted) {
          consumerHasUnpairedWorkload = true;
        }
      } else if (entity.actors && entity.actors === 'ams') {
        consumerHasAllWorkload = true;
      } else if (entity.actors && entity.actors === 'container_host') {
        consumerHasContainerHost = true;
      } else if (entity.usesVirtualServicesWorkloads) {
        consumerHasUsesVirtualServicesWorkloads = true;
      } else if (entity.usesVirtualServices) {
        consumerHasUsesVirtualServices = true;
      }
    });

    if (ingressServices) {
      // FIXME: UI side validation will not show up for PCEs with more than 500 services
      windowsService = ingressServices.some(
        service => (ServiceStore.getSpecified(service.href) || {}).windows_services,
      );
    }

    if (consumerHasContainerHost) {
      const nonTcpServicePort =
        ingressServices && ingressServices.some(servicePort => !servicePort.href && servicePort.proto !== 6);

      if (
        providerHasLabelGroup ||
        providerHasIpList ||
        providerHasVirtualServer ||
        providerHasVirtualService ||
        providerHasUsesVirtualServicesWorkloads ||
        providerHasUsesVirtualServices
      ) {
        validationErrors.push(Constants.CH_PE_INVALID);
      }

      if (consumers.length > 1) {
        validationErrors.push(Constants.CH_BY_ITSELF);
      }

      if (stateless || machineAuth || secureConnect) {
        validationErrors.push(Constants.CH_STATELESS_MACHINE_AUTH_SECURECONNECT);
      }

      if (windowsService) {
        validationErrors.push(Constants.CH_WINDOWS_SERVICE);
      }

      if (nonTcpServicePort) {
        validationErrors.push(Constants.CH_NON_TCP_SERVICE);
      }
    }

    if (isExtraScopeRules && providerHasIpList && !(SessionStore.isUserOwner() || SessionStore.isUserAdmin())) {
      validationErrors.push(Constants.PE_WITH_IP_LIST_SCOPEDUSER);
    }

    if (providerHasIpList && windowsService) {
      validationErrors.push(Constants.PE_WITH_IP_LIST_WINDOWS);
    }

    scopes.forEach(scope => {
      if (!scope || scope.deleted) {
        return;
      }

      scope.forEach(o => {
        const type = o.label ? o.label.key : o.label_group.key;

        if (!o.exclusion) {
          scopeLabelTypeMap[type] = true;
        }
      });
    });

    if (providersWorkloadsOnly) {
      if (providerHasUsesVirtualServicesWorkloads || providerHasVirtualServer) {
        validationErrors.push(Constants.PE_WITH_VIRTUAL_SERVICE_VIRTUAL_SERVER);
      }
    }

    if (providerHasUsesVirtualServices) {
      if (providerHasIpList || providerHasWorkload) {
        validationErrors.push(Constants.PE_WITH_IP_LIST_WORKLOAD);
      }

      if (ingressServices && ingressServices.length) {
        validationErrors.push(Constants.PE_WITH_VS_SERVICES);
      }
    }

    if (providerHasUsesVirtualServicesWorkloads) {
      if (providerHasIpList || providerHasVirtualServer || providerHasVirtualService || providerHasWorkload) {
        validationErrors.push(Constants.PE_WITH_IP_VS_VS_W);
      }
    }

    if (providersWorkloadsOnly) {
      if (providerHasVirtualService) {
        validationErrors.push(Constants.PE_WITH_VIRTUAL_SERVICE);
      }
    }

    if (providerHasUsesVirtualServicesWorkloads && providerHasUsesVirtualServices) {
      validationErrors.push(Constants.CE_FROM_CONSUMERS_BOTH);
    }

    if (consumersWorkloadsOnly) {
      if (consumerHasVirtualService) {
        validationErrors.push(Constants.CE_WITH_VIRTUAL_SERVICE);
      }
    }

    if (consumerHasUsesVirtualServices) {
      if (consumerHasIpList || consumerHasWorkload) {
        validationErrors.push(Constants.CE_WITH_IP_LIST_WORKLOAD);
      }
    }

    if (consumerHasUsesVirtualServicesWorkloads) {
      if (consumerHasIpList || consumerHasVirtualService || consumerHasWorkload) {
        validationErrors.push(Constants.CE_WITH_IP_VS_W);
      }
    }

    if (consumerHasUsesVirtualServicesWorkloads && consumerHasUsesVirtualServices) {
      validationErrors.push(Constants.CE_FROM_CONSUMERS_BOTH);
    }

    // Unpaired Workloads
    if (providerHasUnpairedWorkload || consumerHasUnpairedWorkload) {
      validationErrors.push(Constants.UNPAIRED_WORKLOADS);
    }

    // Required Fields
    if (
      (!providers ||
        !providers.length ||
        ((!consumers || !consumers.length) && (!consumingSecurityPrincipals || !consumingSecurityPrincipals.length))) &&
      isSubmitted
    ) {
      validationErrors.push(Constants.REQUIRED_RULE_FIELDS);
    }

    // Scope Labels
    if (scopeLabelTypeMap.app && ((consumerHasAppLabel && !unscopedConsumers) || providerHasAppLabel)) {
      validationErrors.push(Constants.SCOPE_APP_LABEL_NOT_ALL);
    }

    if (scopeLabelTypeMap.env && ((consumerHasEnvLabel && !unscopedConsumers) || providerHasEnvLabel)) {
      validationErrors.push(Constants.SCOPE_ENV_LABEL_NOT_ALL);
    }

    if (scopeLabelTypeMap.loc && ((consumerHasLocLabel && !unscopedConsumers) || providerHasLocLabel)) {
      validationErrors.push(Constants.SCOPE_LOC_LABEL_NOT_ALL);
    }

    if ((providerHasAnyIp || providerHasIpList) && (consumerHasAnyIp || consumerHasIpList)) {
      validationErrors.push(Constants.IP_LIST_ON_BOTH_SIDES);
    }

    if (secureConnect) {
      if (
        providerHasAnyIp ||
        consumerHasAnyIp ||
        providerHasIpList ||
        consumerHasIpList ||
        providerHasUsesVirtualServicesWorkloads ||
        consumerHasVirtualService ||
        providerHasVirtualServer ||
        consumerHasVirtualServer
      ) {
        validationErrors.push(Constants.SECURECONNECT_INVALID_PE_CE);
      }

      if (consumerHasUserGroup) {
        validationErrors.push(Constants.SECURECONNECT_WITH_SECURITY_PRINCIPAL);
      }
    }

    if (consumerHasUserGroup && (consumerHasAnyIp || consumerHasIpList)) {
      validationErrors.push(Constants.USER_GROUP_IP_LIST);
    }

    if (
      consumerHasUserGroup &&
      !consumerHasWorkload &&
      !consumerHasAllWorkload &&
      !consumerHasEnvLabel &&
      !consumerHasLocLabel &&
      !consumerHasAppLabel &&
      !consumerHasRoleLabel
    ) {
      validationErrors.push(Constants.ONLY_USER_GROUPS);
    }

    if (windowsService && (providerHasAnyIp || providerHasIpList)) {
      validationErrors.push(Constants.WINDOWS_SERVICE_INVALID_PE);
    }

    if (stateless) {
      if (secureConnect) {
        validationErrors.push(Constants.STATELESS_WITH_SECURECONNECT);
      }

      if (machineAuth) {
        validationErrors.push(Constants.STATELESS_WITH_MACHINE_AUTH);
      }

      if (providerHasIpList) {
        validationErrors.push(Constants.STATELESS_WITH_IP_LIST);
      }

      const statelessConsumers = (consumers || []).filter(
        consumer => !consumer.usesVirtualServicesWorkloads && !consumer.usesVirtualServices,
      );

      if (statelessConsumers.length) {
        // Stateless Rules' consumers can have =>
        // * Either one consumer (except Label Groups)
        // * AMS and Any IP
        // * Max four Labels (only one Label per key)
        const nonLabelsActorsCount = statelessConsumers.length - consumerLabelsCount;

        if (consumerHasLabelGroup) {
          validationErrors.push(Constants.STATELESS_WITH_LABEL_GROUP);
        }

        if (!validStatelessConsumerLabels) {
          validationErrors.push(Constants.STATELESS_INVALID_CONSUMERS);
        }

        if (nonLabelsActorsCount && consumerLabelsCount) {
          // Either Labels or Non Label actors are allowed
          validationErrors.push(Constants.STATELESS_INVALID_CONSUMERS);
        }

        if (nonLabelsActorsCount > 1 && !(nonLabelsActorsCount === 2 && consumerHasAnyIp && consumerHasAllWorkload)) {
          // Only time more than 1 non Label actor is allowed is
          // when there's two total, and they're AMS and Any IP
          validationErrors.push(Constants.STATELESS_INVALID_CONSUMERS);
        }
      }
    }

    if (machineAuth) {
      if (ingressServices && ingressServices.some(service => service.name === intl('Common.AllServices'))) {
        validationErrors.push(Constants.MACHINE_AUTH_WITH_ALL_SERVICES);
      }

      if (providerHasIpList) {
        validationErrors.push(Constants.PE_IP_LIST_WITH_MACHINE_AUTH);
      }

      if (consumerHasIpList) {
        validationErrors.push(Constants.CE_IP_LIST_WITH_MACHINE_AUTH);
      }
    }

    if (resolveLabelsAsProviders.includes('virtual_services')) {
      if (resolveLabelsAsConsumers.includes('virtual_services')) {
        if (machineAuth) {
          validationErrors.push(Constants.MACHINE_AUTH_WITH_NULL_UB_SERVICE);
        }

        if (secureConnect) {
          validationErrors.push(Constants.SECURECONNECT_WITH_NULL_UB_SERVICE);
        }
      } else {
        if (machineAuth) {
          validationErrors.push(Constants.MACHINE_AUTH_WITH_NULL_SERVICE);
        }

        if (secureConnect) {
          validationErrors.push(Constants.SECURECONNECT_WITH_NULL_SERVICE);
        }
      }
    }

    if (networkType === 'non_brn' || networkType === 'all') {
      if (!consumerHasIpList && !providerHasIpList) {
        validationErrors.push(Constants.NON_CORPORATE_MUST_USE_IP_LIST);
      }

      if (secureConnect) {
        validationErrors.push(Constants.CANT_USE_SECURE_CONNECT_WITH_NON_DEFAULT_NETWORK);
      }

      if (machineAuth) {
        validationErrors.push(Constants.CANT_USE_MACHINE_AUTH_WITH_NON_DEFAULT_NETWORK);
      }

      if (
        providerHasVirtualService ||
        consumerHasVirtualService ||
        providerHasUsesVirtualServices ||
        consumerHasUsesVirtualServices
      ) {
        validationErrors.push(Constants.NETWORK_TYPE_MUST_BE_CORPORATE);
      }

      if (consumerHasIpList && !consumerHasOnlyIpLists) {
        validationErrors.push(Constants.CONSUMER_MUST_HAVE_ONLY_IP_LISTS);
      }

      if (providerHasIpList && !providerHasOnlyIpLists) {
        validationErrors.push(Constants.PROVIDER_MUST_HAVE_ONLY_IP_LISTS);
      }
    }

    return validationErrors;
  },

  validateIpTablesRule(scopes, actors, ipVersion, statements, isSubmitted) {
    const validationErrors = [];

    const scopeLabelTypeMap = {};

    scopes.forEach(scope => {
      if (!scope || scope.deleted) {
        return;
      }

      scope.forEach(o => {
        const type = o.label ? o.label.key : o.label_group.key;

        scopeLabelTypeMap[type] = true;
      });
    });

    let actorHasAppLabel = false;
    let actorHasEnvLabel = false;
    let actorHasLocLabel = false;
    let actorHasUnpairedWorkload = false;

    _.each(actors, entity => {
      if (entity.label || entity.label_group) {
        const key = entity.label ? entity.label.key : entity.label_group.key;

        switch (key) {
          case 'app':
            actorHasAppLabel = true;
            break;
          case 'env':
            actorHasEnvLabel = true;
            break;
          case 'loc':
            actorHasLocLabel = true;
            break;
        }
      } else if (entity.workload && entity.workload.deleted) {
        actorHasUnpairedWorkload = true;
      }
    });

    // Unpaired Workloads
    if (actorHasUnpairedWorkload) {
      validationErrors.push(Constants.UNPAIRED_WORKLOADS);
    }

    // Required Fields
    if ((!actors || !actors.length || !ipVersion) && isSubmitted) {
      validationErrors.push(Constants.REQUIRED_RULEIPTABLES_FIELDS);
    }

    // Invalid Statements
    if (_.some(statements, statement => statement.error || statement.duplicate) && isSubmitted) {
      validationErrors.push(Constants.STATEMENTS_INVALID);
    }

    // Scope Labels
    if (scopeLabelTypeMap.app && actorHasAppLabel) {
      validationErrors.push(Constants.SCOPE_APP_LABEL_NOT_ALL);
    }

    if (scopeLabelTypeMap.env && actorHasEnvLabel) {
      validationErrors.push(Constants.SCOPE_ENV_LABEL_NOT_ALL);
    }

    if (scopeLabelTypeMap.loc && actorHasLocLabel) {
      validationErrors.push(Constants.SCOPE_LOC_LABEL_NOT_ALL);
    }

    return validationErrors;
  },

  // This function is clear but inefficient. If performance is a concern later on, I can change it.
  validateScope(appLabel, envLabel, locLabel, scopes, ruleLabelTypeMap, submitted) {
    const validationErrors = [];
    const allLabelTypeMap = {};

    const scopeLabelTypeMap = {};

    scopes.forEach(scope => {
      if (!scope || scope.deleted) {
        return;
      }

      labelTypes.forEach(type => {
        if (scope[type]) {
          scopeLabelTypeMap[type] = true;
        } else {
          allLabelTypeMap[type] = true;
        }
      });
    });

    const addedAllAppLabel = appLabel && appLabel.type && appLabel.type === 'all';
    const addedAllEnvLabel = envLabel && envLabel.type && envLabel.type === 'all';
    const addedAllLocLabel = locLabel && locLabel.type && locLabel.type === 'all';

    const newScopeArray = [];

    if (appLabel && appLabel.type !== 'all') {
      newScopeArray.push(appLabel.href);
    }

    if (envLabel && envLabel.type !== 'all') {
      newScopeArray.push(envLabel.href);
    }

    if (locLabel && locLabel.type !== 'all') {
      newScopeArray.push(locLabel.href);
    }

    const alreadyHasScope = _.some(scopes, scope => {
      const scopeArray = [];

      if (scope.deleted) {
        return;
      }

      if (scope && scope.app) {
        scopeArray.push(scope.app.href);
      }

      if (scope && scope.env) {
        scopeArray.push(scope.env.href);
      }

      if (scope && scope.loc) {
        scopeArray.push(scope.loc.href);
      }

      return (
        newScopeArray.length === scopeArray.length &&
        _.intersection(newScopeArray, scopeArray).length === scopeArray.length
      );
    });

    if ((!appLabel || !envLabel || !locLabel) && submitted) {
      validationErrors.push(Constants.REQUIRED_SCOPE_FIELDS);
    }

    if (appLabel && envLabel && locLabel && alreadyHasScope) {
      validationErrors.push(Constants.DUPLICATE_SCOPE);
    }

    if (appLabel) {
      if (addedAllAppLabel) {
        if (scopeLabelTypeMap.app) {
          validationErrors.push(Constants.ALL_APP_LABEL_AND_LABEL);
        }
      } else if (allLabelTypeMap.app) {
        validationErrors.push(Constants.ALL_APP_LABEL_AND_LABEL);
      } else if (ruleLabelTypeMap.app) {
        validationErrors.push(Constants.RULE_APP_LABEL_IN_USE);
      }
    }

    if (envLabel) {
      if (addedAllEnvLabel) {
        if (scopeLabelTypeMap.env) {
          validationErrors.push(Constants.ALL_ENV_LABEL_AND_LABEL);
        }
      } else if (allLabelTypeMap.env) {
        validationErrors.push(Constants.ALL_ENV_LABEL_AND_LABEL);
      } else if (ruleLabelTypeMap.env) {
        validationErrors.push(Constants.RULE_ENV_LABEL_IN_USE);
      }
    }

    if (locLabel) {
      if (addedAllLocLabel) {
        if (scopeLabelTypeMap.loc) {
          validationErrors.push(Constants.ALL_LOC_LABEL_AND_LABEL);
        }
      } else if (allLabelTypeMap.loc) {
        validationErrors.push(Constants.ALL_LOC_LABEL_AND_LABEL);
      } else if (ruleLabelTypeMap.loc) {
        validationErrors.push(Constants.RULE_LOC_LABEL_IN_USE);
      }
    }

    return validationErrors;
  },
};
