/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import Icon from '../Icon.jsx';
import {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import {Tooltip} from '..';

ZoomPanel.propTypes = {
  handleZoomIn: PropTypes.func,
  handleZoomOut: PropTypes.func,
  handleZoomToFit: PropTypes.func,
};

export default function ZoomPanel(props) {
  const zoomPanel = cx({
    'ZoomPanel': true,
    'ZoomPanel--hidden': !(props.data.locations.length || props.data.clusters.length || props.data.nodes.length),
  });

  return (
    <div className={zoomPanel}>
      <Tooltip content={intl('Common.ZoomToFit')} width={95} location="left">
        <div className="ZoomPanel-Button ZoomPanel-Button-Fit" onClick={props.handleZoomToFit} data-tid="zoom-to-fit">
          <Icon name="zoom-to-fit" styleClass="Zoom" />
        </div>
      </Tooltip>
      <div className="ZoomPanel-Button">
        <Tooltip content={intl('Common.ZoomIn')} width={84} location="left">
          <div className="ZoomPanel-Button-In" onClick={props.handleZoomIn} data-tid="zoom-in">
            <div className="ZoomPanel-Button-Side" />
            <div className="ZoomPanel-Button-Border">
              <Icon name="zoom-in" styleClass="Zoom" />
            </div>
            <div className="ZoomPanel-Button-Side" />
          </div>
        </Tooltip>
        <Tooltip content={intl('Common.ZoomOut')} width={84} location="left">
          <div className="ZoomPanel-Button-Out" onClick={props.handleZoomOut} data-tid="zoom-out">
            <Icon name="zoom-out" styleClass="Zoom" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
