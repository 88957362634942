/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import {SessionStore} from '../stores';
import Session from '../lib/session';

export default {
  sendAnalyticsEvent(name, data) {
    const {getOrgId, getLoginUrl} = Session;
    const loginUrl = new URL(getLoginUrl() || '');

    if (__LOG_ANALYTIC_EVENTS__ === true) {
      console.log(`%cAnalytic Event: %c${name}`, 'color:steelblue;', 'color:#008bff; font-weight:bold;', data);
    }

    if (SessionStore.isUIAnalyticsEnabled()) {
      import(/* webpackChunkName: 'logrocket' */ 'logrocket').then(({default: LogRocket}) => {
        // Mix in orgId and hostname for now, until Larry knows how to analyze those from the init call
        LogRocket.track(name, {orgId: getOrgId(), hostname: loginUrl.hostname, ...data});
      });
    }
  },
};
