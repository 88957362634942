/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';

let pairingProfiles = [];
let isLoaded = false;

function setPairingProfiles(data, groupLabels) {
  pairingProfiles = groupLabels
    ? data.filter(pp => {
        // Remove Roles From Pairing Profile Labels.
        const ppLabelKeys = pp.labels.map(label => label.key).filter(key => key !== 'role');
        const groupLabelKeys = groupLabels.map(label => label.key);

        return ppLabelKeys.length === groupLabelKeys.length && ppLabelKeys.every(key => groupLabelKeys.includes(key));
      })
    : data;
}

export default createApiStore(['PAIRING_PROFILE_', 'PAIRING_PROFILES_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.PAIRING_PROFILES_GET_COLLECTION_SUCCESS:
        setPairingProfiles(action.data, action.options.groupLabels);
        isLoaded = true;
        break;

      case Constants.PAIRING_PROFILES_GET_INSTANCE_SUCCESS:
        StoreUtils.setObject(action.data, pairingProfiles);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return pairingProfiles;
  },

  getSpecified(href) {
    return StoreUtils.findObjectByHref(href, pairingProfiles);
  },

  isLoaded() {
    return isLoaded;
  },
});
