/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import {createApiStore} from '../lib/store';
import Constants from '../constants';

let metrics = {
  days: undefined,
  daysLimit: undefined,
  sizeGB: undefined,
  sizeGBLimit: undefined,
};

function transformData(data = {}) {
  const {
    flows_days: days,
    flows_size_gb: sizeGB,
    flows_days_limit: daysLimit,
    flows_size_gb_limit: sizeGBLimit,
  } = data ?? {};

  return {days, daysLimit, sizeGB, sizeGBLimit};
}

export default createApiStore(['TRAFFIC_FLOWS_DATABASE_METRICS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.TRAFFIC_FLOWS_DATABASE_METRICS_GET_SUCCESS:
        this.setMetrics(action.data);
        break;
    }

    this.emitChange();

    return true;
  },

  getMetrics() {
    return metrics;
  },

  setMetrics(data) {
    metrics = transformData(data);
  },
});
