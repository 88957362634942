/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import {Icon} from '../../components';
import {RenderUtils} from '../../utils';
import {StoreMixin} from '../../mixins';
import {MapTooltipStore, TrafficFilterStore} from '../../stores';
import intl from '@illumio-shared/utils/intl';
import {getEnforcementIntlByState} from '@illumio-shared/utils/intl/dynamic';
import _ from 'lodash';
import cx from 'classnames';

function getStateFromStores() {
  const tooltipState = MapTooltipStore.getTooltip();

  return {
    ...tooltipState,
    vulnerability: tooltipState?.tooltipInfo?.vulnerability?.aggregatedValues,
    policyStates: TrafficFilterStore.getPolicyStates(),
  };
}

export default React.createClass({
  mixins: [StoreMixin([MapTooltipStore, TrafficFilterStore], getStateFromStores)],

  displayPolicyState(policyState) {
    switch (policyState) {
      case 'building':
        return intl('Common.Build');
      case 'testing':
        return intl('Common.Test');
      case 'Enforced':
      case 'enforced':
        return intl('Common.Enforced');
      case 'mixed':
        return intl('Policy.Mixed');
      case 'unmanaged':
        return intl('Common.Unmanaged');
      case 'idle':
        return intl('Common.Idle');
      case 'Not Enforced':
        return intl('Common.NotEnforced');
      default:
        return intl('Common.NA');
    }
  },

  roleInfo(tooltipInfo) {
    const roleInfo = tooltipInfo.labels.role ? (
      <div className="Map-Illumination-Tooltip-Illumination">
        <span className="Map-Illumination-Span">
          <span className="Map-Illumination-Tag">{`${intl('Tooltip.Role')} `} </span>
          <span className="Map-Illumination-Data">{String(tooltipInfo.labels.role.value)}</span>
        </span>
      </div>
    ) : null;

    return roleInfo;
  },

  componentDidUpdate(prevProps, prevState) {
    const {location, type} = this.state;
    const TooltipBox = this.refs.tooltipRef;

    if (TooltipBox) {
      const TooltipBoundingRect = TooltipBox.getBoundingClientRect();
      const TooltipWidth = TooltipBoundingRect.width;
      const TooltipHeight = TooltipBoundingRect.height;

      const style = RenderUtils.tooltipOrMenuPosition(location, type, TooltipWidth, TooltipHeight, 'tooltip').style;

      if (!_.isEqual(prevState.style, style)) {
        this.setState({style});
      }
    }
  },

  render() {
    const {location, policyStates, style, tooltipInfo, type, vulnerability} = this.state;
    const {colorBlind} = tooltipInfo;
    const modeOrder = ['unmanaged', 'idle', 'visibility', 'selective', 'enforced', 'unknown'];
    let content;

    // When filtering it is not always possible to count how many individual items have been filtered
    // For now, we are hacking this with min of the total entities, but in the future we might need
    // to remove some rows of the tool tip when filtering
    switch (type) {
      case 'location':
        if (tooltipInfo.data) {
          content = (
            <div className="Map-Illumination-Tooltip">
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Tooltip.Location')} `}</span>
                  <span className="Map-Illumination-Data">{String(tooltipInfo.name)}</span>
                </span>
              </div>
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.GroupCount',
                      {count: tooltipInfo.clustersNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
              <div
                className={
                  !tooltipInfo.containerWorkloadsNum &&
                  !tooltipInfo.virtualServicesNum &&
                  !tooltipInfo.virtualServersNum
                    ? 'Map-Illumination-Tooltip-Illumination-Last'
                    : 'Map-Illumination-Tooltip-Illumination'
                }
              >
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.WorkloadCount',
                      {count: tooltipInfo.workloadsNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
              {tooltipInfo.containerWorkloadsNum ? (
                <div
                  className={
                    !tooltipInfo.virtualServicesNum && !tooltipInfo.virtualServersNum
                      ? 'Map-Illumination-Tooltip-Illumination-Last'
                      : 'Map-Illumination-Tooltip-Illumination'
                  }
                >
                  <span className="Map-Illumination-Span">
                    <span className="Map-Illumination-Tag">
                      {intl(
                        'Tooltip.ContainerWorkloadCount',
                        {count: tooltipInfo.containerWorkloadsNum, className: 'Map-Illumination-Data'},
                        {html: true},
                      )}
                    </span>
                  </span>
                </div>
              ) : null}
              {tooltipInfo.virtualServicesNum ? (
                <div
                  className={
                    tooltipInfo.virtualServersNum
                      ? 'Map-Illumination-Tooltip-Illumination'
                      : 'Map-Illumination-Tooltip-Illumination-Last'
                  }
                >
                  <span className="Map-Illumination-Span">
                    <span className="Map-Illumination-Tag">
                      {intl(
                        'Tooltip.VirtualServiceCount',
                        {count: tooltipInfo.virtualServicesNum, className: 'Map-Illumination-Data'},
                        {html: true},
                      )}
                    </span>
                  </span>
                </div>
              ) : null}
              {tooltipInfo.virtualServersNum ? (
                <div className="Map-Illumination-Tooltip-Illumination-Last">
                  <span className="Map-Illumination-Span">
                    <span className="Map-Illumination-Tag">
                      {intl(
                        'Tooltip.VirtualServerCount',
                        {count: tooltipInfo.virtualServersNum, className: 'Map-Illumination-Data'},
                        {html: true},
                      )}
                    </span>
                  </span>
                </div>
              ) : null}
            </div>
          );
        }

        break;
      case 'cluster':
        const clusterisReadable = tooltipInfo.caps.workloads.includes('read');
        const clusterModeKeys =
          tooltipInfo.data?.mode && Object.keys(tooltipInfo.data.mode).filter(key => tooltipInfo.data.mode[key]);
        const clusterEnforcementKeys =
          tooltipInfo.data?.enforcement &&
          Object.keys(tooltipInfo.data.enforcement).filter(key => tooltipInfo.data.enforcement[key]).length;
        let clusterModeCount = 0;

        content = (
          <div className="Map-Illumination-Tooltip">
            <div className={`Map-Illumination-Tooltip-Illumination${clusterisReadable ? '' : '-Last'}`}>
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.Group')} `} </span>
                <span className="Map-Illumination-Data">{String(tooltipInfo.name)}</span>
              </span>
            </div>
            {clusterisReadable ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.RoleCount',
                      {count: tooltipInfo.roleNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && tooltipInfo.workloadsNum ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.WorkloadCount',
                      {count: tooltipInfo.workloadsNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && tooltipInfo.containerWorkloadsNum ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.ContainerWorkloadCount',
                      {count: tooltipInfo.containerWorkloadsNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && tooltipInfo.virtualServicesNum ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.VirtualServiceCount',
                      {count: tooltipInfo.virtualServicesNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && tooltipInfo.virtualServersNum ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.VirtualServerCount',
                      {count: tooltipInfo.virtualServersNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && clusterEnforcementKeys > 1 ? (
              <div className="Map-Illumination-Tooltip-Illumination-Indent-Heading">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Common.Enforcement')} `}</span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && clusterEnforcementKeys > 1
              ? modeOrder.map(key => {
                  if (tooltipInfo.data.enforcement[key] && policyStates[key]) {
                    clusterModeCount += 1;

                    return (
                      <div
                        key={`${key}-${tooltipInfo.data.enforcement[key]}`}
                        className={`Map-Illumination-Tooltip-Illumination-Indent${
                          vulnerability ? (clusterModeCount === clusterModeKeys.length ? '-Last' : '') : ''
                        }`}
                      >
                        <span className="Map-Illumination-Span">
                          <span className="Map-Illumination-Tag-Indent">{`${getEnforcementIntlByState(key)}: `} </span>
                          <span className="Map-Illumination-Data-Indent">
                            {String(tooltipInfo.data.enforcement[key])}
                          </span>
                        </span>
                      </div>
                    );
                  }

                  return null;
                })
              : null}
            {clusterisReadable && clusterEnforcementKeys <= 1 ? (
              <div className={`Map-Illumination-Tooltip-Illumination${vulnerability ? '' : '-Last'}`}>
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Tooltip.Enforcement')} `}</span>
                  <span className="Map-Illumination-Data">
                    {String(getEnforcementIntlByState(tooltipInfo.policyState))}
                  </span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Vulnerability.VEScore')}: `} </span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerabilityExposureScore)
                      ? vulnerability.vulnerabilityExposureScore
                      : String(RenderUtils.roundNumber(vulnerability.vulnerabilityExposureScore))}
                  </span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Vulnerability.Score')}: `} </span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerabilityScore)
                      ? vulnerability.vulnerabilityExposureScore
                      : String(RenderUtils.roundNumber(vulnerability.vulnerabilityScore))}
                  </span>
                </span>
              </div>
            ) : null}
            {clusterisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination-Last">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Common.ExposureScore')}: `}</span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerablePortExposure)
                      ? vulnerability.vulnerablePortExposure
                      : String(vulnerability.vulnerablePortExposure)}
                    {Object.keys(vulnerability.wideExposure).length === 0 ? (
                      ''
                    ) : (
                      <Icon name="internet" size="medium" position="after" />
                    )}
                  </span>
                </span>
              </div>
            ) : null}
          </div>
        );
        break;
      case 'role':
        const workloadEntities =
          tooltipInfo.entityCounts - tooltipInfo.virtualServicesNum - tooltipInfo.virtualServersNum;
        const workloadsNum = Math.min(tooltipInfo.workloadsNum, workloadEntities);
        const containerWorkloadsNum = Math.min(tooltipInfo.containerWorkloadsNum, workloadEntities);
        const roleisReadable = tooltipInfo.caps.workloads.includes('read');
        const roleModeKeys =
          tooltipInfo.data?.mode && Object.keys(tooltipInfo.data.mode).filter(key => tooltipInfo.data.mode[key]).length;
        const roleEnforcementKeys =
          tooltipInfo.data?.enforcement &&
          Object.keys(tooltipInfo.data.enforcement).filter(key => tooltipInfo.data.enforcement[key]).length;
        let roleModeCount = 0;

        content = (
          <div className="Map-Illumination-Tooltip">
            <div className={`Map-Illumination-Tooltip-Illumination${roleisReadable ? '' : '-Last'}`}>
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.Role')} `}</span>
                <span className="Map-Illumination-Data">{String(tooltipInfo.name)}</span>
              </span>
            </div>
            {roleisReadable && workloadsNum ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {tooltipInfo.collapsedGroup
                      ? intl(
                          'Tooltip.ConnectedWorkloadCount',
                          {count: tooltipInfo.workloadsNum, className: 'Map-Illumination-Data'},
                          {html: true},
                        )
                      : intl(
                          'Tooltip.WorkloadCount',
                          {count: tooltipInfo.workloadsNum, className: 'Map-Illumination-Data'},
                          {html: true},
                        )}
                  </span>
                </span>
              </div>
            ) : null}
            {roleisReadable && containerWorkloadsNum ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.ContainerWorkloadCount',
                      {count: tooltipInfo.containerWorkloadsNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
            ) : null}
            {roleisReadable && tooltipInfo.virtualServicesNum ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.VirtualServiceCount',
                      {count: tooltipInfo.virtualServicesNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
            ) : null}
            {roleisReadable && tooltipInfo.virtualServersNum ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">
                    {intl(
                      'Tooltip.VirtualServerCount',
                      {count: tooltipInfo.virtualServersNum, className: 'Map-Illumination-Data'},
                      {html: true},
                    )}
                  </span>
                </span>
              </div>
            ) : null}
            {roleisReadable && roleEnforcementKeys > 1 ? (
              <div className="Map-Illumination-Tooltip-Illumination-Indent-Heading">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Common.Enforcement')} `}</span>
                </span>
              </div>
            ) : null}
            {roleisReadable && roleEnforcementKeys > 1
              ? modeOrder.map(key => {
                  if (tooltipInfo.data.enforcement[key] && policyStates[key]) {
                    roleModeCount += 1;

                    return (
                      <div
                        key={`${key}-${tooltipInfo.data.enforcement[key]}`}
                        className={`Map-Illumination-Tooltip-Illumination-Indent${
                          vulnerability ? (roleModeCount === roleModeKeys.length ? '-Last' : '') : ''
                        }`}
                      >
                        <span className="Map-Illumination-Span">
                          <span className="Map-Illumination-Tag-Indent">{`${getEnforcementIntlByState(key)}: `} </span>
                          <span className="Map-Illumination-Data-Indent">
                            {String(tooltipInfo.data.enforcement[key])}
                          </span>
                        </span>
                      </div>
                    );
                  }

                  return null;
                })
              : null}
            {roleisReadable && roleEnforcementKeys <= 1 ? (
              <div className={`Map-Illumination-Tooltip-Illumination${vulnerability ? '' : '-Last'}`}>
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Tooltip.Enforcement')} `}</span>
                  <span className="Map-Illumination-Data">
                    {String(getEnforcementIntlByState(tooltipInfo.policyState))}
                  </span>
                </span>
              </div>
            ) : null}
            {roleisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Vulnerability.VEScore')}: `} </span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerablePortExposure)
                      ? vulnerability.vulnerablePortExposure
                      : String(RenderUtils.roundNumber(vulnerability.vulnerabilityExposureScore))}
                  </span>
                </span>
              </div>
            ) : null}
            {roleisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Vulnerability.Score')}: `} </span>
                  <span className="Map-Illumination-Data">
                    {String(RenderUtils.roundNumber(vulnerability.vulnerabilityScore))}
                  </span>
                </span>
              </div>
            ) : null}
            {roleisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination-Last">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Common.ExposureScore')}: `}</span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerablePortExposure)
                      ? vulnerability.vulnerablePortExposure
                      : String(RenderUtils.roundNumber(vulnerability.vulnerablePortExposure))}
                    {Object.keys(vulnerability.wideExposure).length === 0 ? (
                      ''
                    ) : (
                      <Icon name="internet" size="medium" position="after" />
                    )}
                  </span>
                </span>
              </div>
            ) : null}
          </div>
        );
        break;
      case 'ipList':
        const ipLists = tooltipInfo.internets.reduce((result, d) => {
          if (!result.includes(d.name) && d.name !== intl('IPLists.Any')) {
            result.push(d.name);
          }

          return result;
        }, []);

        const ipListbullet = (
          <ul className="IpList-List">
            {ipLists.map(d => (
              <li key={d}>
                <span className="Map-Illumination-Data">{d}</span>
              </li>
            ))}{' '}
          </ul>
        );

        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination-Last">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.IPList')} `}</span>
                <span className="Map-Illumination-Data">{ipListbullet}</span>
              </span>
            </div>
          </div>
        );
        break;
      case 'fqdn':
        const fqdns = tooltipInfo.internets
          .reduce((a, d) => {
            if (!a.some(a => a.href === d.href) && d.name !== intl('IPLists.Any')) {
              a.push(d);
            }

            return a;
          }, [])
          .sort((a, b) => {
            if (RenderUtils.isHrefFqdn(a.href) && !RenderUtils.isHrefFqdn(b.href)) {
              return 1;
            }

            if (!RenderUtils.isHrefFqdn(a.href) && RenderUtils.isHrefFqdn(b.href)) {
              return -1;
            }

            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
          });

        const maxDisplay = 10;
        const fqdnInternets = fqdns.length > maxDisplay ? fqdns.slice(0, maxDisplay) : fqdns;

        const fqdnbullet = (
          <ul className="IpList-List">
            {fqdnInternets.map(d => (
              <li key={d.href}>
                {RenderUtils.isHrefFqdn(d.href) ? null : <Icon name="fqdn" position="before" />}
                <span className="Map-Illumination-Data">{d.name}</span>
              </li>
            ))}
            {fqdns.length > maxDisplay && (
              <span className="Map-Illumination-Data-Counter">{`${fqdnInternets.length} of ${fqdns.length} FQDNs shown`}</span>
            )}
          </ul>
        );

        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination-Last">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.Fqdns')} `}</span>
                <span className="Map-Illumination-Data">{fqdnbullet}</span>
              </span>
            </div>
          </div>
        );
        break;
      case 'internet':
        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination-Last">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Common.Internet')} `}</span>
              </span>
            </div>
          </div>
        );
        break;
      case 'virtualServer':
        const virtualServerisReadable = tooltipInfo.caps.workloads.includes('read');

        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.VirtualServer')} `} </span>
                <span className="Map-Illumination-Data">{String(tooltipInfo.name)}</span>
              </span>
            </div>
            {this.roleInfo(tooltipInfo)}
            {virtualServerisReadable ? (
              <div className="Map-Illumination-Tooltip-Illumination-Last">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Tooltip.PolicyState')} `} </span>
                  <span className="Map-Illumination-Data">
                    {String(this.displayPolicyState(tooltipInfo.policyState))}
                  </span>
                </span>
              </div>
            ) : null}
          </div>
        );
        break;
      case 'virtualService':
        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.VirtualService')} `} </span>
                <span className="Map-Illumination-Data">{String(tooltipInfo.name)}</span>
              </span>
            </div>
            {this.roleInfo(tooltipInfo)}
          </div>
        );
        break;

      case 'OSWorkload':
        const OSWorkloadisReadable = tooltipInfo.caps.workloads.includes('read');

        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.OSWorkload')} `} </span>
                <span className="Map-Illumination-Data">{String(tooltipInfo.name)}</span>
              </span>
            </div>
            {this.roleInfo(tooltipInfo)}
            {OSWorkloadisReadable ? (
              <div className={`Map-Illumination-Tooltip-Illumination${vulnerability ? '' : '-Last'}`}>
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Tooltip.Enforcement')} `} </span>
                  <span className="Map-Illumination-Data">{getEnforcementIntlByState(tooltipInfo.policyState)}</span>
                </span>
              </div>
            ) : null}
            {OSWorkloadisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Vulnerability.VEScore')}: `} </span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerabilityExposureScore)
                      ? vulnerability.vulnerabilityExposureScore
                      : String(RenderUtils.roundNumber(vulnerability.vulnerabilityExposureScore))}
                  </span>
                </span>
              </div>
            ) : null}
            {OSWorkloadisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Vulnerability.Score')}: `} </span>
                  <span className="Map-Illumination-Data">
                    {String(RenderUtils.roundNumber(vulnerability.vulnerabilityScore))}
                  </span>
                </span>
              </div>
            ) : null}
            {OSWorkloadisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination-Last">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Common.ExposureScore')}: `}</span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerabilityExposureScore)
                      ? vulnerability.vulnerabilityExposureScore
                      : String(RenderUtils.roundNumber(vulnerability.vulnerablePortExposure))}
                    {Object.keys(vulnerability.wideExposure).length === 0 ? (
                      ''
                    ) : (
                      <Icon name="internet" size="medium" position="after" />
                    )}
                  </span>
                </span>
              </div>
            ) : null}
          </div>
        );
        break;
      case 'UnmanagedWorkload':
        const UnmanagedWorkloadisReadable = tooltipInfo.caps.workloads.includes('read');

        const workloadName = tooltipInfo.name ? (
          <span className="Map-Illumination-Data">{`: ${tooltipInfo.name}`}</span>
        ) : null;
        const policyState =
          tooltipInfo.policyState && UnmanagedWorkloadisReadable ? (
            <div className="Map-Illumination-Tooltip-Illumination-Last">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.Enforcement')} `} </span>
                <span className="Map-Illumination-Data">{getEnforcementIntlByState(tooltipInfo.policyState)}</span>
              </span>
            </div>
          ) : null;

        content = (
          <div className="Map-Illumination-Tooltip">
            <div
              className={
                tooltipInfo.policyState
                  ? 'Map-Illumination-Tooltip-Illumination'
                  : 'Map-Illumination-Tooltip-Illumination-Last'
              }
            >
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Common.UnmanagedWorkload')} `} </span>
                {workloadName}
              </span>
              {policyState}
            </div>
            {this.roleInfo(tooltipInfo)}
            {UnmanagedWorkloadisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Vulnerability.VEScore')}: `} </span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerabilityExposureScore)
                      ? vulnerability.vulnerabilityExposureScore
                      : String(RenderUtils.roundNumber(vulnerability.vulnerabilityExposureScore))}
                  </span>
                </span>
              </div>
            ) : null}
            {UnmanagedWorkloadisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Vulnerability.Score')}: `} </span>
                  <span className="Map-Illumination-Data">
                    {String(RenderUtils.roundNumber(vulnerability.vulnerabilityScore))}
                  </span>
                </span>
              </div>
            ) : null}
            {UnmanagedWorkloadisReadable && vulnerability ? (
              <div className="Map-Illumination-Tooltip-Illumination-Last">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Common.ExposureScore')}: `}</span>
                  <span className="Map-Illumination-Data">
                    {isNaN(vulnerability.vulnerabilityExposureScore)
                      ? vulnerability.vulnerabilityExposureScore
                      : String(RenderUtils.roundNumber(vulnerability.vulnerablePortExposure))}
                    {Object.keys(vulnerability.wideExposure).length === 0 ? (
                      ''
                    ) : (
                      <Icon name="internet" size="medium" position="after" />
                    )}
                  </span>
                </span>
              </div>
            ) : null}
          </div>
        );
        break;
      case 'ContainerWorkload':
        const ContainerWorkloadisReadable = tooltipInfo.caps.workloads.includes('read');

        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${intl('Tooltip.ContainerWorkload')} `} </span>
                <span className="Map-Illumination-Data">{String(tooltipInfo.name)}</span>
              </span>
            </div>
            {this.roleInfo(tooltipInfo)}
            {ContainerWorkloadisReadable ? (
              <div className="Map-Illumination-Tooltip-Illumination-Last">
                <span className="Map-Illumination-Span">
                  <span className="Map-Illumination-Tag">{`${intl('Tooltip.Enforcement')}  `}</span>
                  <span className="Map-Illumination-Data">{getEnforcementIntlByState(tooltipInfo.policyState)}</span>
                </span>
              </div>
            ) : null}
          </div>
        );
        break;
      case 'ExplorerLabel':
        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination-Last">
              <span className="Map-Illumination-Span">
                <span className="Map-Illumination-Tag">{`${tooltipInfo.name} `}</span>
              </span>
            </div>
          </div>
        );
        break;
      case 'link':
        const iconShapes = {
          virtualService: 'diamond',
          virtualServer: 'diamond',
          container: 'hexagon',
          workload: 'square',
          role: 'square',
          group: 'oval',
        };

        const sortedServices = RenderUtils.getTrafficServices(tooltipInfo);
        const topServices = sortedServices.length > 5 ? sortedServices.slice(0, 4) : sortedServices;
        const moreServicesCount = sortedServices.length - topServices.length;
        const serviceDescription = (
          <div className="Map-Illumination-Tooltip-Services">
            <span className="Map-Illumination-Tag"> {`${intl('Tooltip.Services')} `} </span>

            <div className="Map-Illumination-Tooltip-Services-List">
              {topServices.map((service, index) => {
                const {trafficPd} = service;
                const arrowIconName = RenderUtils.getPolicyArrowIconName(trafficPd);
                const policyDecisionClasses = RenderUtils.getPolicyDecisionClassNames(trafficPd, colorBlind);
                const arrowIconClasses = cx({
                  [policyDecisionClasses]: true,
                  'Map-Illumination-Tooltip-Services-PolicyDecision': true,
                });

                return (
                  <div key={index} className="Map-Illumination-Tooltip-Services-List-Item">
                    <Icon size="large" name={arrowIconName} customClass={arrowIconClasses} />
                    <span className="Map-Illumination-Tooltip-Services-Name">
                      {RenderUtils.getServiceName(service)}
                    </span>
                  </div>
                );
              })}
              {moreServicesCount > 0 ? (
                <div className="Map-Illumination-Tooltip-Services-List-Item">
                  {intl('Common.CountMore', {count: moreServicesCount})}
                </div>
              ) : null}
            </div>
          </div>
        );

        content = (
          <div className="Map-Illumination-Tooltip">
            <div className="Map-Illumination-Tooltip-Illumination">
              <div className="Map-Illumination-Tooltip-Source">
                {tooltipInfo.source.type !== 'ipList' ||
                tooltipInfo.source.type !== 'internet' ||
                tooltipInfo.source.type !== 'fqdn' ? (
                  <div
                    className={
                      tooltipInfo.source.subType === 'container'
                        ? 'Position-ArrowPane-1-ContainerSource'
                        : 'Position-ArrowPane-1-General'
                    }
                  >
                    <div
                      className={
                        tooltipInfo.source.subType === 'container'
                          ? iconShapes[tooltipInfo.source.subType]
                          : iconShapes[tooltipInfo.source.type]
                      }
                    />
                  </div>
                ) : null}
                {tooltipInfo.source.type === 'ipList' ||
                tooltipInfo.source.type === 'internet' ||
                tooltipInfo.source.type === 'fqdn' ? (
                  <div className="Position-ArrowPane-1-Specific">
                    <Icon
                      name={tooltipInfo.source.type.toLowerCase()}
                      styleClass={tooltipInfo.source.type.toLowerCase()}
                      position="inbetween"
                    />
                  </div>
                ) : null}
                <span className="Map-Illumination-Span">
                  {tooltipInfo.source.secondaryName ||
                    tooltipInfo.source.name ||
                    (tooltipInfo.source.type === 'fqdn' ? intl('Common.Fqdns') : tooltipInfo.source.type)}
                </span>
              </div>

              <div className="Map-Illumination-Tooltip-Arrow">
                {tooltipInfo.source.type && tooltipInfo.target.type ? (
                  <div className="Position-ArrowPane-2">
                    {' '}
                    <Icon name="arrow-right" styleClass="arrowDown" />{' '}
                  </div>
                ) : null}
              </div>

              <div className="Map-Illumination-Tooltip-Target">
                {tooltipInfo.target.type !== 'ipList' ||
                tooltipInfo.target.type !== 'internet' ||
                tooltipInfo.target.type !== 'fqdn' ? (
                  <div className="Position-ArrowPane-3-General">
                    <div
                      className={
                        tooltipInfo.target.subType === 'container'
                          ? iconShapes[tooltipInfo.target.subType]
                          : iconShapes[tooltipInfo.target.type]
                      }
                    />
                  </div>
                ) : null}
                {tooltipInfo.target.type === 'ipList' ||
                tooltipInfo.target.type === 'internet' ||
                tooltipInfo.target.type === 'fqdn' ? (
                  <div className="Position-ArrowPane-3-Specific">
                    <Icon
                      name={tooltipInfo.target.type.toLowerCase()}
                      styleClass={tooltipInfo.target.type.toLowerCase()}
                      position="inbetween"
                    />
                  </div>
                ) : null}
                <span className="Map-Illumination-Span">
                  {tooltipInfo.target.secondaryName ||
                    tooltipInfo.target.name ||
                    (tooltipInfo.target.type === 'fqdn' ? intl('Common.Fqdns') : tooltipInfo.target.type)}
                </span>
              </div>
            </div>

            <div className="Map-Illumination-Tooltip-Illumination-Last">{serviceDescription}</div>
          </div>
        );

        break;
    }

    if (!location) {
      return <div className="Map-Tooltip" />;
    }

    return (
      <div className="Map-Tooltip" style={style} data-tid="tooltip" ref="tooltipRef">
        <div className="Map-TooltipContent" style={style}>
          {content}
        </div>
      </div>
    );
  },
});
