/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import {ToolBar} from '../ToolBar';
import {GridDataUtils, IpUtils} from '../../utils';
import {Grid, Label, ToggleDropdown} from '..';
import intl from '@illumio-shared/utils/intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {TrafficFilter} from '.';

IpListGrid.propTypes = {
  innerGridData: PropTypes.array,
  editingId: PropTypes.string,
  tite: PropTypes.string,
  subtitle: PropTypes.string,
  ipLists: PropTypes.array,
  onRowExpand: PropTypes.func,
  onRowSelect: PropTypes.func,
  onRowClick: PropTypes.func,
  onConfigure: PropTypes.func,
  selection: PropTypes.array,
  sorting: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default function IpListGrid(props) {
  const testGridData = props.ipLists.map(ipList => ({
    caret: ' ',
    coverage: ipList.connectionRules && ipList.connections ? ipList.connectionRules / ipList.connections : 0,
    connectionsWithRules: ipList.connectionRules ? ipList.connectionRules : 0,
    connectionsWithRulesReplace: ipList.connectionRulesReplace ? ipList.connectionRulesReplace : 0,
    name: ipList.ipList.name,
    href: ipList.ipList.href,
    key: [ipList.ipList.href, ipList.direction].join('x'),
    fqdnType: ipList.fqdnType,
    ranges: ipList.ipList.ip_ranges,
    type: props.type,
    connections: `${ipList.connections} ${ipList.direction} Connections`,
  }));

  const columns = [
    {
      key: 'name',
      label: intl('Common.Name'),
      format: (value, row) => {
        const title = <Label icon={row.fqdnType === 'fqdnIpList' ? 'domain' : 'ip-list'} text={value} />;

        if (!row.ranges) {
          return row.name;
        }

        let addresses = row.ranges.map(range => (
          <div className="IpListGrid-address">{IpUtils.stringifyAddressObject(range)}</div>
        ));

        if (addresses.length > 5) {
          addresses = addresses.slice(0, 5);
          addresses.push(<div className="IpListGrid-address">{'\u2026'}</div>);
        }

        const data = {title, content: addresses};

        return <ToggleDropdown data={data} center={true} />;
      },
      sortable: true,
      // The Grid component doesn't do case insensitive sorting by default
      sortValue: value => value.toLocaleLowerCase(),
    },
    {
      key: 'connections',
      label: intl('Common.Connections'),
      sortable: true,
      format: value => {
        if (value.includes(intl('Common.Inbound'))) {
          return (
            <span className="PolicyGeneratorGrid-direction">
              <span className="PolicyGeneratorGrid-direction-value">{value}</span>
              <span className="PolicyGeneratorGrid-arrow-bar--iplist" />
              <span className="PolicyGeneratorGrid-arrow-head--right" />
            </span>
          );
        }

        return (
          <span className="PolicyGeneratorGrid-direction">
            <span className="PolicyGeneratorGrid-arrow-head--left" />
            <span className="PolicyGeneratorGrid-arrow-bar--iplist" />
            <span className="PolicyGeneratorGrid-direction-value">{value}</span>
          </span>
        );
      },
      // The value of row.connections would be of format "XX Connections" (Example: "52 Connections")
      // We want to sort on the number "XX" ("52" for above example)
      sortValue: value => Number(value.split(' ')[0]),
    },
    {
      key: 'coverage',
      style: 'role',
      label: intl('PolicyGenerator.Grid.Coverage'),
      format: (value, row) => (
        <div className="ConsumingAppGroupGrid-countBar">
          <div className="ConsumingAppGroupGrid-countBarContent">
            <div className="ConsumingAppGroupGrid-countBarLabel">
              {row.connectionsWithRules !== Number(row.connections.split(' ')[0]) ||
              (row.type === 'replace' && row.connectionsWithRulesReplace === Number(row.connections.split(' ')[0]))
                ? intl('PolicyGenerator.Grid.ConnectionWithRules', {count: row.connectionsWithRules})
                : intl('PolicyGenerator.Grid.CompleteCoverage')}
            </div>
            <div className="AppGroupCoverage-card-info-count-bar">
              <div className="AppGroupCoverage-card-info-count-bar-fill" style={{width: `${value * 100}%`}}>
                <ReactCSSTransitionGroup
                  transitionName="AppGroupCoverage-card-info-count-bar-fill-value"
                  transitionAppear={true}
                  transitionAppearTimeout={5000}
                  transitionEnterTimeout={0}
                  transitionLeaveTimeout={0}
                >
                  <div className="AppGroupCoverage-card-info-count-bar-fill-value" />
                </ReactCSSTransitionGroup>
              </div>
            </div>
          </div>
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <div className="ConsumingAppGroupGrid-wrapper">
      <ToolBar>
        <div className="ConsumingAppGroupGrid-title-bar">
          <div className="ConsumingAppGroupGrid-title-section">
            <div className="ConsumingAppGroupGrid-title">{intl('PolicyGenerator.IpList.ChooseIpList')}</div>
            <div className="ConsumingAppGroupGrid-subtitle">
              {intl('PolicyGenerator.Grid.NumberSelected', {subtitle: props.subtitle, count: props.selection.length})}
            </div>
            <div className="PolicyGeneratorGrid-transmission">
              <TrafficFilter onSetFilter={props.onTransmissionFilterChange} filters={props.transmissionFilters} />
            </div>
          </div>
        </div>
      </ToolBar>
      <div className="ConsumingAppGroupGrid-grid">
        <Grid
          editable={true}
          editingId={props.editingId}
          idField="key"
          data={testGridData}
          columns={columns}
          selectable={true}
          selection={props.selection}
          onRowSelectToggle={props.onRowSelect}
          onRowClick={props.onRowClick}
          rowClass={GridDataUtils.formatPolicyGeneratorChoose}
          notificationUnderRow={true}
          rowSelectable={row =>
            row.connectionsWithRules !== Number(row.connections.split(' ')[0]) ||
            (row.type === 'replace' && row.connectionsWithRulesReplace !== Number(row.connections.split(' ')[0]))
          }
          sortable={true}
          sorting={props.sorting}
          onSort={props.onSort}
        />
      </div>
    </div>
  );
}
