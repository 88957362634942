/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  propTypes: {
    timestamp: PropTypes.string,
    onConfirm: PropTypes.func,
    supercluster: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      onConfirm: _.noop,
    };
  },

  getActions() {
    return [
      {
        text: intl('Common.Refresh'),
        tid: 'continue',
        type: 'secondary',
        onClick: () => {
          this.handleClose();
          this.props.onConfirm();
        },
      },
      {
        text: intl('Common.Cancel'),
        tid: 'nofill',
        autoFocus: true,
        onClick: () => {
          this.handleClose();
        },
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    const title = intl('Map.Data');
    const message = (
      <div className="Map-Timestamp-Message">
        <div className="Map-Timestamp-Message-Body">{intl('Map.DataAge', {time: this.props.timestamp})}</div>
        {this.props.supercluster ? (
          <div className="Map-Timestamp-Message-Body">{intl('Map.SuperClusterDataRefresh')}</div>
        ) : null}
        <div className="Map-Timestamp-Message-Body">{intl('Map.DataRefresh')}</div>
      </div>
    );

    return (
      <Dialog
        ref="dialog"
        className="MapDataSync"
        type="detail"
        title={title}
        actions={this.getActions()}
        onClose={this.props.onCancel}
      >
        {message}
      </Dialog>
    );
  },
});
