/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import Button from './Button.jsx';
import Icon from './Icon.jsx';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import ResultList from './ResultList.jsx';
import ComponentUtils from '../utils/ComponentUtils';

export default React.createClass({
  propTypes: {
    options: PropTypes.array,
    disabled: PropTypes.bool,
    dual: PropTypes.bool,
    icon: PropTypes.string,
    onSelect: PropTypes.func,
    onPrimaryClick: PropTypes.func,
    onRemove: PropTypes.func,
    text: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['primary', 'secondary', 'reversed', 'strong', 'nofill']),
    tid: PropTypes.string,
  },

  getInitialState() {
    return {focusedOption: null, showOptions: false};
  },

  formatResult(option) {
    return (
      <div className={`ButtonDropdown-Remove${option.disabled ? ' ButtonDropdown-Result--disabled' : ''}`}>
        <div className="ButtonDropdown-Remove-Value" onClick={_.partial(this.handleRemoveSelect, option)}>
          {option.label}
        </div>
        {option.notRemovable ? null : (
          <Icon name="close" size="medium" onClick={_.partial(this.handleRemove, option)} />
        )}
      </div>
    );
  },

  handleRemove(option) {
    this.props.onRemove(option);
  },

  handleRemoveSelect(option, evt) {
    this.setState({focusedOption: null, showOptions: false});
    this.props.onSelect(option, evt);
  },

  handleClick(evt) {
    if (this.props.disabled) {
      return;
    }

    if (this.props.dual && !evt.target.classList.contains('Icon--after')) {
      if (this.props.onPrimaryClick) {
        this.props.onPrimaryClick(evt);
      }
    }

    this.setState({
      showOptions: !this.state.showOptions,
    });
    findDOMNode(this.refs.toggleButton).focus();
  },

  saveRefHtmlDivElement(htmlDivElement) {
    this.htmlDivContainer = htmlDivElement;
  },

  handleKeyDown(event) {
    event.stopPropagation();

    const newState = {};
    const focusedOption = this.state.focusedOption;
    const numOptions = this.props.options.length;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();

        if (!this.state.showOptions) {
          newState.showOptions = true;
        }

        if (focusedOption === null || focusedOption === numOptions - 1) {
          newState.focusedOption = 0;
        } else {
          newState.focusedOption = focusedOption + 1;
        }

        break;

      case 'ArrowUp':
        event.preventDefault();

        if (!this.state.showOptions) {
          newState.showOptions = true;
        }

        if (focusedOption === null || focusedOption === 0) {
          newState.focusedOption = numOptions - 1;
        } else {
          newState.focusedOption = focusedOption - 1;
        }

        break;

      case 'Enter':
        event.preventDefault();

        if (focusedOption !== null) {
          newState.showOptions = false;
          newState.focusedOption = null;
          this.handleSelect(focusedOption);
        }

        break;
    }

    this.setState(newState);
  },

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown);
  },

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown);
  },

  handleDocumentMouseDown(evt) {
    if (this.htmlDivContainer !== null && this.htmlDivContainer.contains(evt.target) === false) {
      this.setState({focusedOption: null, showOptions: false});
    }
  },

  handleSelect(idx, evt) {
    this.setState({focusedOption: null, showOptions: false});
    this.props.onSelect(this.props.options[idx], evt);
  },

  htmlDivContainer: null,

  render() {
    const props = _.omit(this.props, ['tid', 'onSelect', 'options', 'text']);
    const tids = ComponentUtils.tid('comp-buttondropdown', this.props.tid);
    const classes = cx({
      'ButtonDropdown': true,
      'ButtonDropdown--dual': this.props.dual,
    });

    return (
      <div
        ref={this.saveRefHtmlDivElement}
        className={classes}
        data-tid={ComponentUtils.tidString(tids)}
        onKeyDown={this.handleKeyDown}
      >
        <Button
          text={this.props.text}
          type={this.props.type}
          onClick={this.handleClick}
          icon={this.props.icon}
          dual={this.props.dual}
          tile={this.props.title}
          iconAfter="dropdown"
          ref="toggleButton"
          {...props}
        />
        <div onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp}>
          <ResultList
            data={this.props.options}
            onSelect={this.props.onRemove ? _.noop : this.handleSelect}
            formatResult={this.props.onRemove ? this.formatResult : null}
            focusedResult={this.state.focusedOption}
            showResults={this.state.showOptions}
          />
        </div>
      </div>
    );
  },
});
