/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import Constants from '../constants';

let tooltip = {
  tooltipInfo: {},
  type: '',
  location: null,
};

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SHOW_MAP_TOOLTIP:
        tooltip = action.data;
        break;

      case Constants.HIDE_MAP_TOOLTIP:
        tooltip = action.data
          ? action.data
          : {
              tooltipInfo: {},
              type: '',
              location: null,
            };

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getTooltip() {
    return tooltip;
  },
});
