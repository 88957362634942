/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-checkbox';

export default React.createClass({
  propTypes: {
    autoFocus: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    label: PropTypes.any,
    tid: PropTypes.string,
  },

  getDefaultProps() {
    return {
      autoFocus: false,
      disabled: false,
      label: '',
      onChange: _.noop,
      onBlur: _.noop,
      tid: '',
    };
  },

  getInitialState() {
    return {checked: this.props.checked === undefined ? false : this.props.checked};
  },

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focusInput();
    }
  },

  getChecked() {
    if (this.props.checked !== undefined) {
      return this.props.checked;
    }

    return this.state.checked;
  },

  handleBlur(evt) {
    if (this.disableBlur) {
      evt.preventDefault();
    } else {
      this.props.onBlur(evt);
    }
  },

  handleChange(evt) {
    this.setState({checked: !this.getChecked()});
    this.focusInput();
    this.props.onChange(evt);
  },

  handleMouseDown() {
    this.disableBlur = true;
  },

  handleMouseUp() {
    this.disableBlur = false;
  },

  disableBlur: false,

  focusInput() {
    if (this.refs.input) {
      findDOMNode(this.refs.input).focus();
    }
  },

  render() {
    const classes = cx({
      'Checkbox': true,
      'Checkbox--disabled': this.props.disabled,
    });
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <label
        className={classes}
        data-tid={ComponentUtils.tidString(tids)}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        <input
          tabIndex="0"
          autoFocus={this.props.autoFocus}
          className="Checkbox-input"
          data-tid="elem-input"
          type="checkbox"
          ref="input"
          disabled={this.props.disabled}
          checked={this.getChecked()}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        <span className="Checkbox-label" data-tid="elem-text">
          {this.props.label}
        </span>
      </label>
    );
  },
});
