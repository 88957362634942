/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import Select from './Select.jsx';
import {getPolicyIntlByState} from '@illumio-shared/utils/intl/dynamic';

const policyStates = [
  {
    value: 'unmanaged',
    data: {mode: 'unmanaged'},
  },
  {
    value: 'enforced',
    data: {mode: 'enforced'},
  },
];

function getPolicyState(value) {
  return _.find(policyStates, state => state.value === value);
}

export default React.createClass({
  getInitialState() {
    return {
      selectedState: getPolicyState(this.props.data.value),
    };
  },

  handleChange(selected) {
    const state = getPolicyState(selected.value);

    this.props.onChange(state);
    this.setState({
      selectedState: state,
    });
  },

  render() {
    // The different virtual server policy states are:
    // enforced (Enforced) and unmanaged (Not enforced)
    const options = _.map(policyStates, state => ({
      label: getPolicyIntlByState(state.value),
      value: state.value,
    }));
    const selected = this.state.selectedState && this.state.selectedState.value;

    return (
      <div className="PolicyStateSelect">
        <Select options={options} selected={selected} onSelect={this.handleChange} />
      </div>
    );
  },
});
