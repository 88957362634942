/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {ComponentUtils} from '../../utils';

const defaultTid = 'comp-fieldset';

export default function Fieldset(props) {
  const classes = cx({
    'ReForm-Fieldset': true,
    'ReForm-Fieldset--notitle': !props.title,
    'ReForm-Fieldset--disabled': props.disabled,
  });

  const tids = ComponentUtils.tid(defaultTid, props.tid);

  return (
    <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
      {props.title ? (
        <div className="ReForm-Fieldset-Title" data-tid="comp-fieldset-title">
          <span data-tid="elem-text">{props.title}</span>
        </div>
      ) : null}
      {props.subtitle ? (
        <div className="ReForm-Fieldset-Subtitle" data-tid="comp-fieldset-subtitle">
          <span data-tid="elem-text">{props.subtitle}</span>
        </div>
      ) : null}
      <div className="ReForm-Fieldset-Content" data-tid="comp-fieldset-content">
        {props.children}
      </div>
    </div>
  );
}
