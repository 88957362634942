/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../actions/actionCreators';
import Dialog from '../components/Dialog.jsx';

export default React.createClass({
  getActions() {
    return [
      {
        text: intl('Common.OK'),
        tid: 'ok',
        content: 'text-only',
        ref: 'confirmButton',
        onClick: this.handleClose,
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    return (
      <Dialog
        ref="dialog"
        className="PolicyGeneratorVulnerabilities"
        type="detail"
        actions={this.getActions()}
        title={intl('Vulnerability.ExposedVulnerabilities')}
      >
        <div className="PolicyGeneratorGrid-vulnerability-list">{this.props.vulnerabilities}</div>
      </Dialog>
    );
  },
});
