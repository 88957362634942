/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import {Navigation} from 'react-router';
import {Icon} from '.';
import {SessionStore} from '../stores';

const storageCloseKey = 'rollingUpgrade';

export default React.createClass({
  propTypes: {
    rollingUpgradeCount: PropTypes.number,
    remainingRollingUpgradeCount: PropTypes.number,
  },

  mixins: [Navigation],

  getDefaultProps() {
    return {
      rollingUpgradeCount: 0,
      remainingRollingUpgradeCount: 0,
    };
  },

  handleClose() {
    sessionStorage.setItem(storageCloseKey, true);
    this.setState({close: true});
  },

  handleInfo() {
    this.transitionTo('health.list');
  },

  render() {
    const {rollingUpgradeCount, remainingRollingUpgradeCount} = this.props;
    const completedRollingUpgradeCount =
      rollingUpgradeCount > remainingRollingUpgradeCount ? rollingUpgradeCount - remainingRollingUpgradeCount : 0;

    if (!remainingRollingUpgradeCount || sessionStorage.getItem(storageCloseKey)) {
      return null;
    }

    const title = intl('Health.PceInRollingUpgrade');

    const counter = (
      <div className="Reminder-counter Reminder-counter--rolling-upgrade">
        <div className="Reminder-counter-time-left">{rollingUpgradeCount}</div>
        {intl('Common.TotalPCEs')}
      </div>
    );

    return (
      <div className="Reminder Reminder--short Reminder--gray Reminder--rolling-upgrade">
        {counter}
        <div className="Reminder-listen-only Reminder-listen-only--rolling-upgrade">
          <div className="Reminder-title Reminder-title--rolling-upgrade">{title}</div>
          <div className="Reminder-status--rolling-upgrade">
            <div className="Reminder-completed">
              {intl('Health.CompletedCount', {count: completedRollingUpgradeCount})}
            </div>
            <div className="Reminder-pending">{intl('Health.PendingCount', {count: remainingRollingUpgradeCount})}</div>
          </div>
          <span className="Reminder-close" onClick={this.handleClose}>
            <Icon name="close" />
          </span>
          {SessionStore.isHealthEnabled() && (
            <div className="Reminder-link-rolling-upgrade" onClick={this.handleInfo}>
              {intl('Health.ViewPCEHealth')}
            </div>
          )}
        </div>
      </div>
    );
  },
});
