/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {Address4, Address6} from 'ip-address';
import NetworkStore from '../stores/NetworkStore';

const parsedWithoutBrnCache = new Map();
const parsedWithBrnCache = new Map();

function validateIPAddress(ip) {
  // Use try/catch block for checking against valid ip
  try {
    const ipv4Address = new Address4(ip);

    if (ipv4Address) {
      return true;
    }
  } catch {
    try {
      const ipv6Address = new Address6(ip);

      if (ipv6Address) {
        return true;
      }
    } catch {
      return false;
    }
  }
}

function validateCidrBlock(ip) {
  if (!ip) {
    return false;
  }

  let isValid;

  /** Need try/catch block when checking newAddress[4,6] */
  try {
    const address = ip.includes(':') ? new Address6(ip) : new Address4(ip);

    isValid = address.startAddress().correctForm() !== address.endAddress().correctForm();
  } catch {
    isValid = false;
  }

  return isValid;
}

function areIpRangesOverlapping(oldIp, newIp) {
  if (oldIp.text && oldIp.text.trim() && oldIp.text === newIp.text) {
    // if they are the same
    return true;
  }

  if (!oldIp || !newIp || !oldIp.fromIp || !newIp.fromIp) {
    return false;
  }

  // If versions of ip are different and only one ip in each line, compare ip4 and converted 6to4
  if (oldIp.fromIp.version !== newIp.fromIp.version) {
    // Don't check if one of line is range or gateway
    if (oldIp.toIp || newIp.toIp) {
      return false;
    }

    const [ip4, ip6] = newIp.fromIp.ip6 ? [oldIp, newIp] : [newIp, oldIp];

    // Check only Teredo
    if (!ip6.fromIp.helper.isTeredo()) {
      return false;
    }

    const ip6to4Helper = ip6.fromIp.helper.to4();

    return ip4.fromIp.helper.isInSubnet(ip6to4Helper) || ip6to4Helper.isInSubnet(ip4.fromIp.helper);
  }

  if (!oldIp.toIp && !newIp.toIp) {
    if (oldIp.fromIp.version === 6 || oldIp.fromIp.ip.includes('/') || newIp.fromIp.ip.includes('/')) {
      // This calculation is expensive so only do it if necessary
      return oldIp.fromIp.helper.isInSubnet(newIp.fromIp.helper) || newIp.fromIp.helper.isInSubnet(oldIp.fromIp.helper);
    }

    return oldIp.fromIp.ip === newIp.fromIp.ip;
  }

  if (!oldIp.toIp) {
    return (
      oldIp.fromIp.bigInt.compareTo(newIp.fromIp.bigInt) >= 0 && oldIp.fromIp.bigInt.compareTo(newIp.toIp.bigInt) <= 0
    );
  }

  if (!newIp.toIp) {
    return (
      newIp.fromIp.bigInt.compareTo(oldIp.fromIp.bigInt) >= 0 && newIp.fromIp.bigInt.compareTo(oldIp.toIp.bigInt) <= 0
    );
  }

  return oldIp.fromIp.bigInt.compareTo(newIp.toIp.bigInt) <= 0 && newIp.fromIp.bigInt.compareTo(oldIp.toIp.bigInt) <= 0;
}

export function isIpContainedInSubnetString(ip, containingIp) {
  if (!containingIp || !ip || !containingIp.fromIp || !ip.fromIp) {
    return false;
  }

  let isValid;

  /** Need try/catch block when checking newAddress[4,6] */
  try {
    let containing = new Address4(containingIp.fromIp);
    let address = new Address4(ip.fromIp);

    if (ip.fromIp.includes(':')) {
      containing = new Address4(containingIp.fromIp);
      address = new Address6(ip.fromIp);
    }

    isValid = containingIp.fromIp !== ip.fromIp && address.isInSubnet(containing);
  } catch {
    isValid = false;
  }

  return isValid;
}

export function isIpContainedIn(ip, containingIp) {
  if (!containingIp || !ip || !containingIp.fromIp || !ip.fromIp) {
    return false;
  }

  if (!containingIp.toIp) {
    return containingIp.from_ip !== ip.from_ip && ip.fromIp.helper.isInSubnet(containingIp.fromIp.helper);
  }

  // FromIp must be in container
  if (
    ip.fromIp.bigInt.compareTo(containingIp.fromIp.bigInt) < 0 ||
    ip.fromIp.bigInt.compareTo(containingIp.toIp.bigInt) > 0
  ) {
    return false;
  }

  if (!ip.toIp) {
    return true;
  }

  // If they are both ranges, one end can match, but not both
  return (
    ip.toIp.bigInt.compareTo(containingIp.toIp.bigInt) < 0 ||
    (ip.toIp.bigInt.compareTo(containingIp.toIp.bigInt) === 0 &&
      ip.fromIp.bigInt.compareTo(containingIp.fromIp.bigInt) !== 0)
  );
}

export function areIpRangesEqual(oldRange, newRange, ignoreExclusion) {
  if (!oldRange && !newRange) {
    return true;
  }

  if (!oldRange || !newRange) {
    return false;
  }

  const oldExclusion = ignoreExclusion ? null : Boolean(oldRange.exclusion);
  const newExclusion = ignoreExclusion ? null : Boolean(newRange.exclusion);

  const oldDescription = oldRange.description ? oldRange.description : null;
  const newDescription = newRange.description ? newRange.description : null;

  return (
    oldRange.from_ip === newRange.from_ip &&
    oldRange.to_ip === newRange.to_ip &&
    oldDescription === newDescription &&
    oldExclusion === newExclusion
  );
}

export function areInterfacesDuplicates(oldRange, newRange) {
  if (!oldRange || !newRange || !oldRange.name || !newRange.name) {
    return false;
  }

  return (
    oldRange.name === newRange.name &&
    oldRange.address === newRange.address &&
    ((!oldRange.default_gateway_address && !newRange.default_gateway_address) ||
      oldRange.default_gateway_address === newRange.default_gateway_address) &&
    ((!oldRange.cidr_block && !newRange.cidr_block) || oldRange.cidr_block === newRange.cidr_block)
  );
}

// Via: http://stackoverflow.com/a/10834843
export function isPositiveInteger(value, lessOrEqualThan = Infinity) {
  if (!value) {
    return false;
  }

  const n = Math.trunc(Number(value));

  return String(n) === value && n >= 0 && n <= lessOrEqualThan;
}

export function isValidIP(ip, isSingleIp) {
  return !parseAddressLine(ip, isSingleIp).error;
}

// Translate number of bits as a string to a subnet mask string
export function getIpv4MaskString(bitsString) {
  const bits = parseInt(bitsString, 10);
  const mask = getIpv4Mask(bits);

  return convertIntToIpv4(mask);
}

// Translate number of bits to a subnet mask integer
export function getIpv4Mask(bits) {
  let mask = 0;

  if (bits > 32 || bits <= 0) {
    return 0;
  }

  for (let i = 31; i > 31 - bits; i--) {
    // Using the >>> operator to convert to unsigned
    mask += (1 << i) >>> 0;
  }

  return mask;
}

// Validate the ip bits corresponding to the subnet are zero
// Not used
export function validateIpv4Cidr(ip, cidr) {
  if (cidr < 0 || cidr > 32) {
    return false;
  }

  const mask = getIpv4Mask(cidr);

  return (ip & ~mask) === 0;
}

export function validateMaskBits(bitString) {
  return isPositiveInteger(bitString) && parseInt(bitString, 10) <= 32;
}

// Convert Cidr block to range
export function convertCidrToRangeInt(range) {
  let fromIp;
  let mask;

  if (range.from_ip === '0.0.0.0/0') {
    return {
      fromIp: 0,
      toIp: convertIpv4ToInt('255.255.255.255'),
    };
  }

  let toIp = range.to_ip ? convertIpv4ToInt(range.to_ip) : null;

  if (range.from_ip.indexOf('/') > 0) {
    fromIp = convertIpv4ToInt(range.from_ip.split('/')[0]);
    mask = getIpv4Mask(parseInt(range.from_ip.split('/')[1], 10));
    fromIp = (fromIp & mask) >>> 0;
    toIp = (fromIp | ~mask) >>> 0;
  } else {
    fromIp = convertIpv4ToInt(range.fromIp);
  }

  return {
    fromIp,
    toIp,
  };
}

// Convert IPv4 dot format into an integer
export function convertIpv4ToInt(value) {
  let ip = value.trim();

  // Strip the subnet mask if present
  if (ip.includes('/')) {
    ip = ip.substr(0, ip.indexOf('/'));
  }

  if (!ip || !isValidIP(ip)) {
    return 0;
  }

  const ipInt = ip.split('.').reduce((result, octet, idx) => result + (octet << (8 * (3 - idx))), 0);

  return ipInt >>> 0;
}

// Convert an integer into the IPv4 dot format
function convertIntToIpv4(ipInt) {
  const octet = 256;
  let value = ipInt % octet;

  for (let i = 1; i < 4; i++) {
    ipInt = Math.floor(ipInt / octet);
    value = `${ipInt % octet}.${value}`;
  }

  return value;
}

export function validateFqdn(fqdn) {
  return !/^([\da-z][\da-z-]{0,62}\.)+[a-z]{2,4}$/i.test(fqdn);
}

export function validateUrl(url) {
  return !/^https?:\/\/[^\s/]+(\/.*)?$/.test(url);
}

export function parseInterfaceString(value) {
  return parseAddressLine(value, false, true);
}

export function parseDnsString(value) {
  return parseAddressLine(value, true);
}

// Validate IP address string
export function parseIP(ipString, noCIDR, checkNonPrefixValidation) {
  ipString = _.trim(ipString);

  const result = {ipString};
  let ip = ipString;
  let isIPv6 = ip.includes(':');
  let cidr;

  if (ip.includes('/')) {
    if (noCIDR) {
      throw {message: 'CIDR is not allowed'};
    }

    [ip, cidr] = ip.split('/').map(_.trim);

    if (!ip) {
      throw {message: 'Missing IP address'};
    }

    if (!cidr) {
      throw {message: 'Missing CIDR block'};
    }

    let addressInfo;
    let isValidCidr;

    isIPv6 = ip.includes(':');

    if (isIPv6) {
      // IPv6
      // ipString : 2620:0:860:2::/64
      try {
        /** Address6 will throw an error if ipString fails */
        addressInfo = new Address6(ipString);
      } catch {
        isValidCidr = false;
      } finally {
        // Don't check when there is a pre-fix attached e.g. !3ffe:1900:4545:3:200:f8ff:fe21:67cf
        if (checkNonPrefixValidation && isValidCidr) {
          // Without cidr, example: ip = 2620:0:860:2:: (original: 2620:0:860:2::/64)
          const addressInfoWithoutCIDR = Address6.isValid(ip);
          const canonicalWithoutCIDR = addressInfoWithoutCIDR.canonicalForm();

          // Address with cidr 2620:0:860:2::/64
          const addressWithCIDR = addressInfo.startAddress().address;

          // Worked with backend to confirm logic that a CIDR starting address and without CIDR canonical address needs to match to be valid
          isValidCidr = canonicalWithoutCIDR === addressWithCIDR;
        }
      }
    } else {
      // IPv4
      try {
        addressInfo = new Address4(ipString);
      } catch {
        isValidCidr = false;
      } finally {
        // Not all ipString require validation to determine if ipString is the start network
        // ip with prefixes: e.g. eth0: 10.0.0.1, !10.0.0.0 does not need to be check.
        // checkNonPrefixValidation - ipString without prefix must be boolean true
        if (checkNonPrefixValidation === true && isValidCidr) {
          // addressInfo.startAddress().address must match the user ip input to be valid cidr
          isValidCidr = ip && ip === addressInfo.startAddress().address;
        }
      }
    }

    // cidr must be greater than 0 (cidr > 0) to be valid - this check isn't valid for isIPv6 only for IPV4
    // ip/cdr: "0.0.0.0/0" is valid for isPostiiveInteger check
    // isValidCidr: false - invalid cidr for both IPv4 and IPv6
    // checkNonPrefixValidation - ipString without prefix must be boolean true
    if (
      (checkNonPrefixValidation === true && !isValidCidr) ||
      (ip !== '0.0.0.0' && cidr <= 0 && !isIPv6) ||
      !isPositiveInteger(cidr, isIPv6 ? 128 : 32)
    ) {
      throw {message: 'Invalid CIDR block'};
    }
  }

  if (isIPv6) {
    // IP6, must be checked first, because can contain IP4
    let errorMessage;

    try {
      /** A failed ipString will throw an AddressError */
      result.helper = new Address6(ipString);
    } catch (error) {
      /** Set the error message from AddressError */
      errorMessage = error.message;
    }

    if (result.helper) {
      result.ip6 = true;
      result.version = 6;

      if (cidr) {
        result.cidr = Number(cidr);
      }

      if (result.helper.zone) {
        throw {message: `Zone or scope id must be removed: ${result.helper.zone}`};
      }
    } else {
      throw {message: errorMessage.replace('Address failed regex', 'Bad block detected in address')};
    }
  } else if (ip.includes('.')) {
    // IP4
    let isValid;

    try {
      /** Invalid ipString will throw an AddressError thus need to add try/catch block
       *  Allow the logic below to throw the appropriate error thus don't put inside finally
       * */
      result.helper = new Address4(ipString);
      isValid = true;
    } catch {
      isValid = false;
    }

    if (isValid && result.helper.isCorrect()) {
      result.ip4 = true;
      result.version = 4;

      if (cidr) {
        result.cidr = Number(cidr);
      }
    } else {
      const match = _.tail(ip.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/));

      if (_.isEmpty(match)) {
        throw {message: 'Invalid IPv4 or DNS Entry'};
      }

      if (match.some(block => block.startsWith('0') && block.length > 1)) {
        throw {message: 'Only decimal numbers are allowed in IPv4'}; // No octal (leading 0) or hexadecimal (leading 0X)
      }

      if (match.some(block => Number(block) > 255)) {
        throw {message: 'Invalid IPv4 value'};
      }

      throw {message: 'Invalid IP'};
    }
  } else {
    throw {message: 'Invalid IP'};
  }

  result.ip = ip;
  result.isValid = true;
  result.bigInt = result.helper.bigInteger();

  return result;
}

function parseIPNoCIDR(ip) {
  return parseIP(ip, true);
}

export function getLineWithBrn(line) {
  const [ip, brnPart] = line.split(/\s+"/).map(_.trim);

  if (brnPart && brnPart.length > 0 && brnPart.slice(-1) === '"') {
    const brn = brnPart.slice(0, -1);

    const brnObjs = NetworkStore.getAll().filter(value => value.name === brn && value.data_center !== 'link_local');

    if (brnObjs.length === 0) {
      throw {message: intl('Common.NetworkNameInvalid')};
    }

    return {ip, brnObjs};
  }

  return {ip: line};
}

const isValidDomain = domain => {
  if (typeof domain === 'string') {
    // not *, not have just numbers, not start with - or ., not end with - or ., valid characters with length 1 to 253,
    // not just . and *, no two consecutive *, no two consecutive .
    return (
      domain !== '*' &&
      /\D/.test(domain) &&
      !/^[.-]/.test(domain) &&
      !/[.-]$/.test(domain) &&
      /^[\w*.-]{1,253}$/.test(domain) &&
      !/^[*.]*$/.test(domain) &&
      !domain.includes('**') &&
      !domain.includes('..')
    );
  }

  return false;
};

// Parse IP string into consumable IP range
// Accepts *, x.x.x.x, x.x.x.x/x and x.x.x.x - y.y.y.y
// Accepts # as the description separator
export function parseAddressLine(line, isSingle = false, isInterface = false, allowBrn = false) {
  const parsedCache = allowBrn ? parsedWithBrnCache : parsedWithoutBrnCache;
  let result = parsedCache.get(line);
  let brnObjs;

  if (result) {
    return result;
  }

  result = {text: line};

  let value = _.trim(line);

  try {
    if (allowBrn) {
      const ipWithBrn = getLineWithBrn(value);

      value = ipWithBrn.ip;
      brnObjs = ipWithBrn.brnObjs;
    }

    if (!value) {
      parsedCache.set(line, result);

      return result;
    }

    // Remove the exclusion
    if (!isInterface && value.startsWith('!')) {
      value = value.substr(1);
      result.exclusion = true;
    }

    // Determine ip without prefix interface: e.g. '10.10.1.1', '10.1.1.1/24'
    // ip with prefix interface: eth0: 10.10.1.1, !10.0.0.0
    const checkNonPrefixValidation = !isInterface && !result.exclusion;

    // Split the interface Name
    if (isInterface) {
      let name = null;

      [, name, value] = value.split(/^([^\s:]+):/).map(_.trim);

      if (!name) {
        throw {message: 'Missing Interface Name'};
      }

      if (!value) {
        throw {message: 'Missing IP address'};
      }

      value.split(' ').map(_.trim).map(parseIP);

      result.name = name;
    }

    // Split the description
    let description = null;

    if (!isSingle && value.includes('#')) {
      [value, description] = value.split('#').map(_.trim);
      result.description = description;
    }

    if ((value === '*' || value === '0.0.0.0/0') && !isSingle) {
      // All IPs
      result.fromIp = parseIP('0.0.0.0/0');
    } else if (!isSingle && !isInterface && value.includes('-')) {
      // IP range using '-'
      const [fromIp, toIp] = value.split('-').map(parseIPNoCIDR);

      if (fromIp.version !== toIp.version) {
        throw {message: 'Combining IPv4 and IPv6 in range is forbidden'};
      }

      if (fromIp.bigInt.compareTo(toIp.bigInt) >= 0) {
        throw {message: 'Invalid address range'};
      }

      result.fromIp = fromIp;
      result.toIp = toIp;
      result.to_ip = toIp.ipString;
    } else if (isInterface && value.includes(' ')) {
      // Interface with gateway
      let [fromIp, gateway] = value.split(' ');

      fromIp = parseIP(fromIp);
      gateway = parseIP(gateway, true);

      result.fromIp = fromIp;

      result.gateway = gateway;
      result.default_gateway_address = gateway.ip;

      result.interface = true;
    } else {
      // Simple one IP
      result.fromIp = parseIP(value, isSingle, checkNonPrefixValidation);
    }

    result.from_ip = result.fromIp.ipString;
    // We sould pass pure ip as 'address' and cidr as 'cidr_block' in unmanaged workloads (interface) separately
    result.address = result.fromIp.ip;

    if (result.fromIp.cidr) {
      result.cidr_block = result.fromIp.cidr;
    }
  } catch (err) {
    result.error = err.message;
  }

  if (allowBrn) {
    result.brnObjs = brnObjs;
  }

  if (result.error && !result.exclusion) {
    if (isValidDomain(value)) {
      result.error = null;
      result.fqdn = value;
    }
  }

  parsedCache.set(line, result);

  return result;
}

export function stringifyAddressObject(value, readonly, isExternalPartner) {
  let result = value.from_ip || '';

  if (value.fqdn && result === '') {
    result = value.fqdn;
  }

  if (value.to_ip) {
    result = `${result} - ${value.to_ip} `;
  }

  if (value.description) {
    result = `${result}  #${value.description}`;
  }

  if (value.exclusion) {
    result = `!${result}`;
  }

  if (value.name) {
    result = `${value.name}: ${result}`;
  }

  if (value.default_gateway_address) {
    result = `${result} ${value.default_gateway_address}`;
  }

  if (value.exclusion && readonly) {
    result = `${result} (exclude)`;
  }

  if (value.friendly_name && readonly) {
    result = `${result} (${value.friendly_name})`;
  }

  if (value.network?.name && readonly && !isExternalPartner) {
    result = `${result} (${value.network.name})`;
  }

  return result;
}

export function validateAddresses(values) {
  const verifyOverlapping = values.length < 1000;

  const data = values.map((value, index, array) => {
    if (!value) {
      return value;
    }

    if (
      verifyOverlapping &&
      !value.error &&
      !value.removed &&
      !value.exclusion &&
      _.some(
        array,
        (currentValue, currentIndex) =>
          index !== currentIndex &&
          !currentValue.removed &&
          !currentValue.error &&
          !currentValue.exclusion &&
          ((!value.name && areIpRangesOverlapping(value, currentValue)) ||
            (value.name && areInterfacesDuplicates(value, currentValue))),
      )
    ) {
      if (value.fqdn) {
        value.duplicate = 'Overlapping FQDN';
      } else if (value.name) {
        value.duplicate = 'Overlapping Interfaces';
      } else {
        value.duplicate = 'Overlapping Addresses';
      }
    } else if (
      verifyOverlapping &&
      !value.error &&
      !value.removed &&
      value.exclusion &&
      !value.name &&
      !_.some(
        array,
        (currentValue, currentIndex) =>
          index !== currentIndex &&
          !currentValue.removed &&
          !currentValue.error &&
          !currentValue.exclusion &&
          isIpContainedIn(value, currentValue),
      )
    ) {
      value.duplicate = 'Must add a containing IP range';
    } else {
      value.duplicate = null;
    }

    if (areIpRangesEqual(value.original, value)) {
      value.type = value.type === 'updated' ? 'old' : value.type;
    } else {
      value.type = value.type === 'old' ? 'updated' : value.type;
    }

    return value;
  });

  return data;
}

function getRangeSize(range) {
  let {fromIp, toIp} = convertCidrToRangeInt(range);

  toIp ||= fromIp;

  return toIp - fromIp;
}

export function getListSize(ipList) {
  return ipList.ip_ranges.reduce((result, range) => {
    result += getRangeSize(range);

    return result;
  }, 0);
}

export function handleIpRanges(ipRanges) {
  const ipOverrides = [];
  const serviceAddresses = [];

  if (ipRanges) {
    ipRanges
      .filter(range => !range.error && !range.removed && range.from_ip)
      .forEach(range => {
        let ip = range.from_ip;
        let brnObjs = range.brnObjs;

        if (range.from_ip.slice(-1) === '"' || (Array.isArray(brnObjs) && brnObjs.length > 0)) {
          if (!brnObjs && range.from_ip.slice(-1) === '"') {
            // unparsed brn
            const obj = getLineWithBrn(range.from_ip);

            ip = obj.ip;
            brnObjs = obj.brnObjs;
          }

          let brnObj;

          if (brnObjs.length > 1) {
            brnObj = brnObjs.find(
              value =>
                (range.ip_version === 4 && Address4.isValid(value.from_ip)) ||
                (range.ip_version === 6 && Address6.isValid(value.from_ip)),
            );
          }

          brnObj ||= brnObjs[0];

          serviceAddresses.push({ip, network: {href: brnObj.href}});
        } else {
          ipOverrides.push(range.from_ip);
        }
      });
  }

  return {ipOverrides, serviceAddresses};
}

export function getFriendlyIpRanges(ipRanges) {
  // TODO This method seems useless
  return ipRanges.filter(range => !range.error && !range.removed && range.from_ip).map(range => range.from_ip);
}

// Takes a range of IP addresses and turns it into one or more of CIDR blocks
// inspired by http://yuweijun.blogspot.com/2007/04/convert-ip-ranges-to-set-of-cidr.html
function rangeToCidr(start, end, result, negativeCount) {
  // prettier-ignore
  const mask = 0xFF_FF_FF_FF;
  const total = 31;

  let first = start;
  let last = end;

  if (typeof first === 'string') {
    if (!validateIPAddress(first)) {
      return result;
    }

    first = convertIpv4ToInt(first);
  }

  if (typeof last === 'string') {
    if (!validateIPAddress(last)) {
      return result;
    }

    last = convertIpv4ToInt(last);
  }

  if (first < last) {
    let idx1 = total;

    while (idx1 >= 0 && ((first >>> idx1) & 1) === ((last >>> idx1) & 1)) {
      idx1 -= 1;
    }

    const prefix = (first >> (idx1 + 1)) << (idx1 + 1);

    let idx2 = 0;

    while (idx2 <= total && idx2 <= idx1 && ((first >>> idx2) & 1) === 0 && ((last >>> idx2) & 1) === 1) {
      idx2 += 1;
    }

    if (Math.sign(first) === -1) {
      negativeCount++;
    }

    if (idx2 <= idx1 && negativeCount < 2) {
      result = rangeToCidr(Math.abs(first), Math.abs(prefix) | (2 ** idx1 - 1), result, negativeCount);
      result = rangeToCidr(Math.abs(prefix) | (1 << idx1), last, result, negativeCount);
    } else {
      let subnet = 0;

      while (subnet <= total && ((((mask << idx2) & mask) >>> (total - subnet)) & 1) === 1) {
        subnet += 1;
      }

      result.push(`${Address4.fromInteger(prefix).address}/${subnet}`);

      return result;
    }
  } else {
    let subnet = 0;

    while (subnet <= total && ((mask >>> (total - subnet)) & 1) === 1) {
      subnet += 1;
    }

    result.push(`${Address4.fromInteger(first).address}/${subnet}`);

    return result;
  }

  if (negativeCount === 1) {
    result.pop();
  }

  return result;
}

export default {
  getLineWithBrn,
  validateIPAddress,
  validateCidrBlock,
  isIpContainedIn,
  isIpContainedInSubnetString,
  areIpRangesEqual,
  areInterfacesDuplicates,
  isPositiveInteger,
  isValidIP,
  getIpv4MaskString,
  getIpv4Mask,
  validateIpv4Cidr,
  validateMaskBits,
  convertIpv4ToInt,
  validateFqdn,
  validateUrl,
  parseInterfaceString,
  parseDnsString,
  parseAddressLine,
  stringifyAddressObject,
  validateAddresses,
  getListSize,
  handleIpRanges,
  getFriendlyIpRanges,
  rangeToCidr,
};
