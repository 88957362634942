/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {GridDataUtils} from '../utils';

let session;

export const roleNamesMap = () => ({
  owner: 'Global Organization Owner',
  admin: 'Global Administrator',
  read_only: 'Global Read Only',
  global_object_provisioner: 'Global Policy Object Provisioner',
  workload_manager: 'Workload Manager',
  ruleset_manager: 'Full Ruleset Manager',
  ruleset_provisioner: 'Ruleset Provisioner',
  ruleset_viewer: 'Ruleset Viewer',
  limited_ruleset_manager: 'Limited Ruleset Manager',
});

export const anyRoles = ['Anyone', 'Any Authenticated User', 'This Authenticated User'];

function getSession() {
  return session;
}

function setSession(data) {
  data.user_id ||= GridDataUtils.getIdFromHref(data.href);

  session = data;
}

function clearSession() {
  session = undefined;
}

function saveUriForLoginRedirect() {
  const uri = window.getUri();

  if (uri !== '/landing') {
    window.saveUriForLoginRedirect(uri);
  }
}

function getSessionToken() {
  return session ? window.btoa(`user_${session.user_id}:${session.session_token}`) : undefined;
}

function getUserOrgInfo() {
  if (!session || !session.orgs) {
    return null;
  }

  // With the new MSSP feature (EYE-78598), a user can have access to multiple organizations,
  // and the first organization in the orgs list is the organization the user is currently accessing.
  return session.orgs[0];
}

function getSessionOrgId() {
  if (session && session.orgs) {
    const org = getUserOrgInfo();

    return org ? _.last(org.href.split('/')) : '';
  }
}

function getSessionOrgUri(path, orgId) {
  return path.replace(':xorg_id', orgId || getSessionOrgId() || 0);
}

function getUserName() {
  return session ? session.full_name || session.auth_username : null;
}

function getLoginUrl() {
  return session ? session.login_url : null;
}

function getUserRoles() {
  if (!session || !session.orgs) {
    return null;
  }

  const org = getUserOrgInfo();

  if (!org) {
    return [];
  }

  // This array is cast to a Set then back to an array to deduplicate the array
  return [...new Set(org.permissions.map(permission => _.last(permission.role.href.split('/'))))];
}

function getRoleNames() {
  const roleNames = new Set(anyRoles);

  getUserRoles().forEach(userRole => {
    if (roleNamesMap()[userRole]) {
      roleNames.add(roleNamesMap()[userRole]);
    }
  });

  return roleNames;
}

function isUserSystemAdmin() {
  if (!session || !session.orgs || !session.orgs[0]) {
    return null;
  }

  return (
    session.orgs &&
    session.orgs.some(
      org =>
        org.permissions &&
        org.permissions.some(permission => {
          const href = _.get(permission, 'role.href');
          const role = href && _.last(href.split('/'));

          return role === 'system_admin';
        }),
    )
  );
}

function isUserExternal() {
  return session && session.type === 'external';
}

function getSessionTimeout() {
  return (typeof session?.inactivity_expiration_minutes === 'number' && session.inactivity_expiration_minutes) || 20;
}

function getCertExpiration() {
  return _.get(session, 'certificate.expiration');
}

function getCertType() {
  return _.get(session, 'certificate.generated');
}

function healthEnabled() {
  return session.health_dashboard_enabled;
}

function getClusterType() {
  return session.pce_cluster_type;
}

function flowAnalyticsEnabled() {
  return session.flow_analytics_enabled;
}

function isMSPOwner() {
  return session.is_msp_owner;
}

function mspOrgUrl() {
  return session.msp_org_url;
}

export default {
  set: setSession,
  get: getSession,
  getToken: getSessionToken,
  clear: clearSession,
  getOrgId: getSessionOrgId,
  getOrgUri: getSessionOrgUri,
  getSessionTimeout,
  getCertExpiration,
  getCertType,
  getUserName,
  getLoginUrl,
  isUserSystemAdmin,
  isUserExternal,
  getUserRoles,
  getRoleNames,
  getUserOrgInfo,
  healthEnabled,
  getClusterType,
  flowAnalyticsEnabled,
  saveUriForLoginRedirect,
  isValid() {
    return Boolean(session);
  },
  isMSPOwner,
  mspOrgUrl,
};
