/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

let apiKeys = null;

function setAPIKeys(data) {
  apiKeys = data;
}

export default createApiStore(['API_KEYS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.API_KEYS_GET_COLLECTION_SUCCESS:
        setAPIKeys(action.data);
        break;

      case Constants.API_KEYS_GET_INSTANCE_SUCCESS:
        apiKeys = action.data;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAPIKeys() {
    return apiKeys;
  },
});
