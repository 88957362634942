/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../../actions/actionCreators';
import Slider from 'react-rangeslider';
import RenderUtils from '../../utils/RenderUtils';

// Number of increments per type
const numMinutes = 5;
const minutes = 60 / numMinutes;
const hours = minutes + 24;
const days = hours + 30;
const month = days + 3;
const totalSteps = month + 2;

export default React.createClass({
  getInitialState() {
    const {filter} = this.props;

    return {filter: filter || {type: 'time', value: 0}};
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.filter, this.props.filter)) {
      this.setState({filter: nextProps.filter || {type: 'time', value: 0}});
    }
  },

  handleRangeChange(val) {
    const value = this.getTimeValueFromSlider(totalSteps - Number(val));

    if (this.getSliderValue(value) !== this.getSliderValue(this.state.filter.value)) {
      actionCreators.selectTrafficTimeFilters({label: 'timeFilter', value: {type: 'time', value}});
    }
  },

  getSliderValue(timestamp) {
    if (!timestamp || timestamp === 'anytime') {
      return totalSteps;
    }

    const dateObj = new Date(timestamp);
    const currentDate = new Date();
    const differenceInSeconds = (currentDate - dateObj) / 1000;

    const minInterval = 60 * numMinutes;
    const hour = 60 * 60;
    const day = hour * 24;
    const month = day * 30;

    // 60/10 (minute interval) + 24 (hours) + 30 (days) + 3 (months)
    if (differenceInSeconds < hour) {
      return Math.round(differenceInSeconds / minInterval);
    }

    if (differenceInSeconds < day) {
      return minutes + Math.round(differenceInSeconds / hour);
    }

    if (differenceInSeconds < month) {
      return hours + Math.round(differenceInSeconds / day);
    }

    return Math.min(days + Math.round(differenceInSeconds / month), totalSteps);
  },

  getTimeValueFromSlider(value) {
    if (value === totalSteps) {
      return 'anytime';
    }

    if (value <= minutes) {
      return intl.utils.subtractTime(new Date(), 'm', value * numMinutes).getTime();
    }

    if (value <= hours) {
      return intl.utils.subtractTime(new Date(), 'h', value - minutes).getTime();
    }

    if (value <= days) {
      return intl.utils.subtractTime(new Date(), 'd', value - hours).getTime();
    }

    return intl.utils.subtractTime(new Date(), 'M', value - days).getTime();
  },

  render() {
    const {filter} = this.state;
    const value = this.getSliderValue(RenderUtils.getTimeFromFilter(filter) * 1000);

    return (
      <span className="TimeFilterContent">
        <div className="TimeFilterContent-Labels">
          {intl('Common.FilterByTime')}
          {this.getTimeValueFromSlider(value) !== 'anytime' && (
            <span className="TimeFilterContent-Labels--time">
              {`Since ${intl('DateTimeInput.SelectedTime', {when: filter.value})}`}
            </span>
          )}
        </div>
        <div>
          <Slider
            min={0}
            max={totalSteps}
            tooltip={false}
            value={totalSteps - value}
            onChange={this.handleRangeChange}
          />
        </div>
        <div className="TimeFilterContent-Labels--small">
          {intl('DateTimeInput.Anytime')}
          {intl('DateTimeInput.Now')}
        </div>
      </span>
    );
  },
});
