/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import Icon from './Icon.jsx';
import Spinner from './Spinner.jsx';
import React, {PropTypes} from 'react';
import cx from 'classnames';

const graphic = {
  ok: <Icon name="check" size="huge" />,
  error: <Icon name="error" size="huge" />,
  progress: <Spinner color="dark" size="banner" />,
  progresscentered: <Spinner color="dark" size="banner" />,
  progresscenterednooverlay: <Spinner color="dark" size="banner" />,
};

export default React.createClass({
  propTypes: {
    header: PropTypes.any,
    message: PropTypes.string,
    type: PropTypes.oneOf([
      'ok',
      'progress',
      'notice',
      'error',
      'progresscentered',
      'info',
      'progresscenterednooverlay',
      'action',
    ]),
    content: PropTypes.element,
    position: PropTypes.string,
  },

  getDefaultProps() {
    return {
      type: 'ok',
    };
  },

  render() {
    const {header, message, type, content} = this.props;

    const classes = cx('Banner', {
      'Banner--progresscenterednooverlay': type === 'progresscenterednooverlay',
      'Banner--progresscentered': type === 'progresscentered',
    });

    return (
      <div className={classes} data-tid="elem-banner">
        {header ? (
          <div className={`Banner-header Banner--${type}`}>
            <div className={`Banner-graphic Banner--${type}`}>{graphic[type]}</div>
            <div className="Banner-header-text" data-tid="elem-banner-header">
              {header}
            </div>
          </div>
        ) : null}
        {message ? (
          <div className={`Banner-message Banner--${type}`} data-tid="elem-banner-message">
            {message}
          </div>
        ) : null}
        {content}
      </div>
    );
  },
});
