/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Navigation} from 'react-router';
import StoreMixin from '../mixins/StoreMixin';
import UserMixin from '../mixins/UserMixin';
import ProvisionStore from '../stores/ProvisionStore';
import Icon from './Icon.jsx';
import {Menu, MenuItem} from './Menu';
import ProvisionProgressMenu from './ProvisionProgressMenu';

function getStateFromStores() {
  return {
    counts: ProvisionStore.pendingCounts(),
    total: ProvisionStore.pendingTotal(),
  };
}

export default React.createClass({
  mixins: [UserMixin, Navigation, StoreMixin(ProvisionStore, getStateFromStores)],

  render() {
    const {state} = this;

    let counter = null;

    if (state.total !== 0) {
      counter = (
        <div className="ProvisionCount-counter" data-tid="count">
          {intl.num(state.total)}
        </div>
      );
    }

    return (
      <div className="ProvisionCount">
        <Menu data-tid="provision" icon={<Icon name="provision" size="xxlarge" />}>
          <MenuItem
            link="pending"
            text={intl('Provision.DraftChangeCount', {count: state.total})}
            data-tid="comp-navbar-provision"
          />
          <MenuItem
            link="versions.list"
            text={intl('Provision.Versions', {multiple: true})}
            data-tid="comp-navbar-versions"
          />
          <MenuItem link="provisioning" text={<ProvisionProgressMenu />} data-tid="comp-navbar-provision-bar" />
        </Menu>
        {counter}
      </div>
    );
  },
});
