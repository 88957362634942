/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {findDOMNode} from 'react-dom';
import update from 'react-addons-update';

import actionCreators from '../../actions/actionCreators';
import LocationVisualization from '../../utils/Location';
import MapPageStore from '../../stores/MapPageStore';
import GraphTransformStore from '../../stores/GraphTransformStore';

let dragged = false;

export default React.createClass({
  componentDidMount() {
    this.d3Wrapper = d3.select(findDOMNode(this));

    if (
      GraphTransformStore.getInteractionType() === 'select' &&
      this.props.whileDragLocation &&
      this.props.afterDragLocation
    ) {
      this.d3Wrapper.call(LocationVisualization.dragLocation, this.dragMove, this.dragEnd, this.handleSelectLocation);
    } else {
      this.d3Wrapper.on('mousedown.drag', null);
    }

    this.componentDidUpdate();
  },

  shouldComponentUpdate() {
    return !this.props.preventGraphElementAnimation;
  },

  componentDidUpdate() {
    this.d3Wrapper.datum(this.props.data).call(_.bind(LocationVisualization.update, LocationVisualization));

    if (
      GraphTransformStore.getInteractionType() === 'select' &&
      this.props.whileDragLocation &&
      this.props.afterDragLocation
    ) {
      this.d3Wrapper.call(LocationVisualization.dragLocation, this.dragMove, this.dragEnd, this.handleSelectLocation);
    } else {
      this.d3Wrapper.on('mousedown.drag', null);
    }
  },

  dragEnd() {
    if (!dragged || GraphTransformStore.getInteractionType() === 'move') {
      dragged ||= false;

      return;
    }

    dragged = false;

    const data = {
      href: this.props.data.href,
      type: 'location',
      x: this.props.data.x,
      y: this.props.data.y,
      r: this.props.data.r,
    };

    this.props.afterDragLocation(data);
  },

  dragMove(dx, dy, isClicked) {
    if (GraphTransformStore.getInteractionType() === 'move') {
      return;
    }

    dragged = !isClicked;

    if (isClicked) {
      return;
    }

    const x = this.props.data.x + dx;
    const y = this.props.data.y + dy;

    const location = update(this.props.data, {
      $merge: {x, y, drag: true},
    });

    this.props.whileDragLocation(location);
  },

  goToAppGroup(id) {
    const mapRoute = MapPageStore.getMapRoute();
    const newMapLevel = {
      prevtype: mapRoute.prevtype || mapRoute.type,
      previd: mapRoute.previd || mapRoute.id,
      type: this.props.data.id,
      id,
    };

    actionCreators.selectComponent([
      {
        type: 'appGroup',
        href: id,
      },
    ]);
    this.props.updateMapLevel(newMapLevel);
  },

  handleSelectNextAppGroup(evt) {
    // Prevent handleSelectLocation from being called
    evt.preventDefault();

    if (this.props.data.nextAppGroup) {
      this.goToAppGroup(this.props.data.nextAppGroup);
    }
  },

  handleSelectPrevAppGroup(evt) {
    // Prevent handleSelectLocation from being called
    evt.preventDefault();

    if (this.props.data.prevAppGroup) {
      this.goToAppGroup(this.props.data.prevAppGroup);
    }
  },

  handleSelectLocation(evt) {
    if (GraphTransformStore.getInteractionType() === 'move') {
      return;
    }

    const mapLevel = MapPageStore.getMapLevel();
    const mapRoute = MapPageStore.getMapRoute();

    // on location view, click and drag location is controlled by dragged
    // on the other view, click and drag is controlled by dragged and event defaultprevented
    if (mapLevel === 'location' && dragged) {
      dragged = false;

      return;
    }

    if (evt.defaultPrevented || dragged) {
      dragged = false;

      return;
    }

    if (this.props.mapType === 'app') {
      // Prevent handleSelectNextAppGroup from being called
      evt.preventDefault();

      actionCreators.selectComponent([
        {
          type: 'appGroup',
          superAppGroup: true,
          href: mapRoute.previd || mapRoute.id,
        },
      ]);

      actionCreators.clickActionItem({
        type: this.props.data.id === 'consuming' ? 'findConsumingGroupAction' : 'findProvidingGroupAction',
      });

      return;
    }

    const mapRouteId = MapPageStore.getMapRoute().id;
    const selectedLocationId = this.props.data.id;

    const location = [
      {
        type: this.props.data.type,
        href: this.props.data.href,
      },
    ];

    if (this.props.data.selected) {
      actionCreators.unselectComponent(location);
    } else {
      actionCreators.updateComponentSelection(location);
    }

    if (this.props.data.expanded || mapRouteId === selectedLocationId) {
      // if selected an expanded location, zoom to fit to the selected location
      // otherwise, navigation to group view of the selected location
      actionCreators.updateZoomToFit(this.props.data);
    } else {
      // if discovered or no_location, route to full map
      // otherwise, route to group view for the selected location.
      const newMapLevel = {
        type: this.props.data.id === 'discovered' || this.props.data.id === 'no_location' ? 'full' : 'location',
        id: selectedLocationId,
      };

      this.props.updateMapLevel(newMapLevel);
    }

    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  handleHoverLocation(evt) {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (!this.props.data.focused && this.props.data.id !== 'consuming' && this.props.data.id !== 'providing') {
      this.d3Wrapper.call(_.bind(LocationVisualization.hoverLocation, LocationVisualization));

      if (this.props.hoverNode) {
        this.props.hoverNode(this.props.data);
      }

      actionCreators.showMapTooltip({
        type: 'location',
        tooltipInfo: {...this.props.data},
        location: {x: evt.pageX, y: evt.pageY},
      });
    }
  },

  handleUnhoverLocation() {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (!this.props.data.focused && this.props.data.id !== 'consuming' && this.props.data.id !== 'providing') {
      this.d3Wrapper.call(_.bind(LocationVisualization.unhoverLocation, LocationVisualization));
      actionCreators.hideMapTooltip();
    }
  },

  handleHoverText(evt) {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.data.focused && this.props.data.id !== 'consuming' && this.props.data.id !== 'providing') {
      this.d3Wrapper.call(_.bind(LocationVisualization.hoverLocation, LocationVisualization));

      if (this.props.hoverNode) {
        this.props.hoverNode(this.props.data);
      }

      actionCreators.showMapTooltip({
        type: 'location',
        tooltipInfo: {...this.props.data},
        location: {x: evt.pageX, y: evt.pageY},
      });
    }
  },

  handleUnhoverText() {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.data.focused && this.props.data.id !== 'consuming' && this.props.data.id !== 'providing') {
      this.d3Wrapper.call(_.bind(LocationVisualization.unhoverLocation, LocationVisualization));
      actionCreators.hideMapTooltip();
    }
  },

  handleContextMenu(evt) {
    evt.preventDefault();

    if (
      this.props.mapType === 'loc' &&
      !(this.props.data.href === 'discovered' || this.props.data.href === 'no_location')
    ) {
      actionCreators.showMapMenu({
        type: 'location',
        data: {...this.props.data},
        location: {x: evt.pageX, y: evt.pageY},
      });
    }

    /* Hide the tooltip on right click */
    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  render() {
    const {data} = this.props;
    const AppMapCircle = {
      NoneAppMapCircle: data.vulnerabilitySeverity === 'none' && data.appMapVersion === 'vulnerability',
      LowAppMapCircle: data.vulnerabilitySeverity === 'low' && data.appMapVersion === 'vulnerability',
      MediumAppMapCircle: data.vulnerabilitySeverity === 'medium' && data.appMapVersion === 'vulnerability',
      CriticalAppMapCircle: data.vulnerabilitySeverity === 'critical' && data.appMapVersion === 'vulnerability',
      HighAppMapCircle: data.vulnerabilitySeverity === 'high' && data.appMapVersion === 'vulnerability',
    };
    const Criticality = {
      None: data.vulnerabilitySeverity === 'none' && data.appMapVersion === 'vulnerability',
      Low: data.vulnerabilitySeverity === 'low' && data.appMapVersion === 'vulnerability',
      Medium: data.vulnerabilitySeverity === 'medium' && data.appMapVersion === 'vulnerability',
      Critical: data.vulnerabilitySeverity === 'critical' && data.appMapVersion === 'vulnerability',
      High: data.vulnerabilitySeverity === 'high' && data.appMapVersion === 'vulnerability',
    };

    const appMapClass = cx('il-location-rec', AppMapCircle);
    const appGroupMapNumberClass = cx('il-appgroup-num', 'il-location-text', Criticality);
    const appGroupTextClass = cx('il-appgroup-text', Criticality);
    const appGroupPrevArrowClass = cx('il-appgroup-arrow-prev', Criticality);

    const appGroupTextPrev = cx('il-appgroup-text-prev', Criticality);
    const appGroupTextNext = cx('il-appgroup-text-next', Criticality);
    const appGroupArrowNext = cx('il-appgroup-arrow-next', Criticality);

    let bubbleElements;

    const interactionType = GraphTransformStore.getInteractionType();

    if (this.props.mapType === 'loc') {
      let locationText;

      if (data.name) {
        locationText = data.name;
      } else if (data.href === 'nolocation') {
        locationText = intl('Common.NoLocation');
      } else {
        locationText = intl('Map.Discovered');
      }

      let virtualServersNum;
      let virtualServersText;

      if (data.virtualServersNum) {
        virtualServersNum = (
          <text className="il-virtualServers-num il-details-num">{intl.num(data.virtualServersNum)}</text>
        );
        virtualServersText = (
          <text className="il-virtualServers-text il-details-text">
            {intl('VirtualServers.ByCount', {count: data.virtualServersNum})}
          </text>
        );
      }

      let virtualServicesNum;
      let virtualServicesText;

      if (data.virtualServicesNum) {
        virtualServicesNum = (
          <text className="il-virtualServices-num il-details-num">{intl.num(data.virtualServicesNum)}</text>
        );
        virtualServicesText = (
          <text className="il-virtualServices-text il-details-text">
            {intl('Provision.TallyLabel.VirtualServices', {count: data.virtualServicesNum})}
          </text>
        );
      }

      let containerWorkloadsNum;
      let containerWorkloadsText;

      if (data.containerWorkloadsNum) {
        containerWorkloadsNum = (
          <text className="il-containerWorkloads-num il-details-num">{intl.num(data.containerWorkloadsNum)}</text>
        );
        containerWorkloadsText = (
          <text className="il-containerWorkloads-text il-details-text">
            {intl('ContainerWorkloads.ByCount', {count: data.containerWorkloadsNum})}
          </text>
        );
      }

      bubbleElements = (
        <g
          className="il-location-bg"
          onMouseMove={this.handleHoverLocation}
          onMouseLeave={this.handleUnhoverLocation}
          onClick={interactionType === 'select' ? this.handleSelectLocation : null}
          onContextMenu={this.handleContextMenu}
        >
          <circle className="il-location-rec" />
          <text className="il-location-text" onMouseMove={this.handleHoverText} onMouseLeave={this.handleUnhoverText}>
            {locationText}
          </text>
          <line className="il-location-line" />
          <g className="il-location-elements">
            <text className="il-clusters-num il-details-num">{intl.num(data.clustersNum)}</text>
            <text className="il-clusters-text il-details-text">
              {intl('Common.GroupsByCount', {count: data.clustersNum})}
            </text>
            <text className="il-workloads-num il-details-num">{intl.num(data.workloadsNum)}</text>
            <text className="il-workloads-text il-details-text">
              {intl('Workloads.ByCount', {count: data.workloadsNum})}
            </text>
            {containerWorkloadsNum}
            {containerWorkloadsText}
            {virtualServicesNum}
            {virtualServicesText}
            {virtualServersNum}
            {virtualServersText}
          </g>
        </g>
      );
    } else if (this.props.mapType === 'app') {
      const appGroupType = data.appGroupType === 'consuming' ? intl('Common.Source') : intl('Common.Destination');
      // For View, both the bubble and the text bring up the modal
      let action = intl('Common.View');
      let prevAction = null;
      let nextAction = null;
      let prevArrow = null;
      let nextArrow = null;
      let handleTextAction = this.handleSelectLocation;
      let handleTextPrevAction = null;
      let handleTextNextAction = null;
      let handleBubbleAction = this.handleSelectLocation;

      if (data.appGroupsNum === 1) {
        // With only one group, there's no text, and the bubble will open the only group
        action = null;
        handleTextAction = null;
        handleBubbleAction = this.handleSelectNextAppGroup;
      } else if (this.props.data.action === 'next') {
        // For Find, the text will cycle to the next group, while be bubble still brings up the modal
        prevAction = intl('Common.Prev');
        nextAction = intl('Common.Next');
        prevArrow = ' < ';
        nextArrow = ' > ';
        action = null;
        handleTextAction = null;
        handleTextPrevAction = this.handleSelectPrevAppGroup;
        handleTextNextAction = this.handleSelectNextAppGroup;
      }

      bubbleElements = (
        <g
          className="il-appgroup-bg"
          onClick={interactionType === 'select' ? handleBubbleAction : null}
          onMouseMove={this.handleHoverLocation}
          onMouseLeave={this.handleUnhoverLocation}
          onContextMenu={this.handleContextMenu}
        >
          <circle className={appMapClass} />
          <text className={appGroupMapNumberClass}>{data.appGroupsNum}</text>
          <text className="il-appgroup-type">{appGroupType}</text>
          <text className="il-appgroup-label">{intl('Common.AppGroups')}</text>
          <text className={appGroupTextClass} onClick={interactionType === 'select' ? handleTextAction : null}>
            {action}
          </text>

          <text className={appGroupPrevArrowClass}>{prevArrow}</text>
          <text className={appGroupTextPrev}>{prevAction}</text>
          <rect className="il-appgroup-rec-prev" onClick={interactionType === 'select' ? handleTextPrevAction : null} />

          <text className={appGroupTextNext}>{nextAction}</text>
          <text className={appGroupArrowNext}>{nextArrow}</text>
          <rect className="il-appgroup-rec-next" onClick={interactionType === 'select' ? handleTextNextAction : null} />
        </g>
      );
    }

    let truncatedText;

    if (data.truncated) {
      truncatedText = (
        <g className="il-truncated-location-msg">
          <text className="il-truncated-text">
            {intl('Map.Locations.LocationGroupsContains', {count: data.clustersNum})}
          </text>
          <text className="il-truncated-text">{intl('Map.Locations.LocationGroupsLimits')}</text>
          <text className="il-truncated-text">{intl('Map.Locations.LocationGroupsFindGroup')}</text>
        </g>
      );
    }

    return (
      <g className="il-location" data-tid={`location-${data.name}`}>
        {bubbleElements}
        {truncatedText}
      </g>
    );
  },
});
