/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import Icon from './Icon.jsx';

export default React.createClass({
  propTypes: {
    allowBrn: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.object).isRequired,
    // An array of objects to display
    // For Diff displays the objects should have a type:
    //  'new', 'old', 'updated'
    onChange: PropTypes.func,
    // This will return a new array of modified objects, with additional fields
    parse: PropTypes.func,
    // 'parse' should turn an entered string into an object in the above format
    // Any error messages should be added to the object as object.error
    stringify: PropTypes.func,
    // 'stringify' should turn the object into a string to display
    validate: PropTypes.func,
    // 'validate' can compare the objects of the array against each other
    // Any validation errors should be added to the object as object.duplicate
    readonly: PropTypes.bool,
    showDiff: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.any,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  },

  getDefaultProps() {
    return {
      allowBrn: false,
      onChange: _.noop,
      parse: string => ({item: string}),
      stringify: value => value.item,
      validate: values => values,
      readonly: false,
      showDiff: false,
      name: 'input',
      placeholder: intl('MultilineInput.TypeOrPaste'),
      onFocus: _.noop,
      onBlur: _.noop,
    };
  },

  getInitialState() {
    let newValues = this.getNewValues(this.props).map(value => {
      // Save the original value
      value.original = value;

      return value;
    });

    // Validate values against each other
    newValues = this.props.validate(newValues);

    return {values: newValues};
  },

  componentWillMount() {
    this.lastIndex = this.state.values.length - 1;
    this.focusIndex = this.lastIndex;
  },

  componentWillReceiveProps(nextProps) {
    let newValues = this.getNewValues(nextProps).map(value => {
      // Save the original value
      value.original ||= value;

      return value;
    });

    // Validate values against each other
    newValues = nextProps.validate(newValues);
    this.lastIndex = newValues.length - 1;

    if (this.focusIndex === this.state.values.length - 1) {
      this.focusIndex = this.lastIndex;
    }

    this.setState({values: newValues});
  },

  componentDidUpdate() {
    this.setFocusPosition();
  },

  getFocusPosition(index) {
    let input = index >= this.lastIndex ? this.refs.textarea : this.refs[this.props.name + index];

    if (input) {
      input = findDOMNode(input);
      this.position = input.selectionStart;
    }
  },

  getNewValues(props) {
    const newValues = props.values.map(value => {
      const newValue = {...value};

      // Turn the object into a string
      newValue.text ||= props.stringify(newValue, props.readonly);

      return newValue;
    });

    if (!this.props.readonly && (!props.values.length || props.values[0].text === undefined)) {
      // Add a new empty value
      const newValue = this.props.parse('');

      newValue.type = 'new';
      newValues.push(newValue);
    }

    return newValues;
  },

  setFocusIndex(focusIndex, beginning) {
    if (focusIndex === this.focusIndex) {
      return;
    }

    // First Blur the current item
    let input = this.focusIndex >= this.lastIndex ? this.refs.textarea : this.refs[this.props.name + this.focusIndex];

    if (input) {
      findDOMNode(input).blur();
    }

    // Then focus the next item
    input = focusIndex >= this.lastIndex ? this.refs.textarea : this.refs[this.props.name + focusIndex];

    if (input) {
      input = findDOMNode(input);
      input.focus();

      if (focusIndex < this.lastIndex) {
        // Choose the beginning or the end of the line
        const location = beginning ? 0 : input.value.length + 1;

        input.setSelectionRange(location, location);
      }
    }

    this.focusIndex = focusIndex;
  },

  setFocusPosition() {
    const input = this.focusIndex >= this.lastIndex ? this.refs.textarea : this.refs[this.props.name + this.focusIndex];

    if (input && input === document.activeElement && this.position) {
      input.setSelectionRange(this.position, this.position);
    }
  },

  handleAdd(index, silent) {
    const newValues = [...this.state.values];

    // Add a new entry after the index
    const newValue = this.props.parse('');

    newValue.type = 'new';
    newValues.splice(index + 1, 0, newValue);

    // Set the focus
    this.lastIndex = newValues.length - 1;

    if (!silent) {
      this.setFocusIndex(index + 1);
    }

    // Update the parent with the new values
    this.props.onChange(newValues);
    this.setState({values: newValues});
  },

  handleChange(text, index) {
    // If the string is empty, remove the value
    if (index !== this.lastIndex && text === '') {
      this.toggleRemove(index);

      return;
    }

    // Update to the newly parsed value
    let newValues = [...this.state.values];

    if (this.props.allowBrn) {
      text = text.replaceAll(/[“”„‟″‶]/g, '"'); // Replace smart quotes
      text = text.replace(/\s+"/, ' "'); // Replace multiple space to one
      newValues[index].text = text;
    }

    const newValue = this.props.parse(text, false, false, this.props.allowBrn);

    // Keep track of the type, and the original value
    newValue.original = newValues[index].original;
    newValue.type = newValues[index].type;

    if (index === this.lastIndex) {
      newValue.type = 'new';
    }

    newValues[index] = newValue;

    // Run validation on the set of values
    newValues = this.props.validate(newValues);
    this.getFocusPosition(index);

    // Update the parent with the new values
    this.props.onChange(newValues);
    this.setState({values: newValues});
  },

  handleKeyDown(evt, index) {
    let input;

    switch (evt.key) {
      case 'Enter':
        evt.preventDefault();

        if (index < this.lastIndex - 1 || this.state.values[this.lastIndex].text !== '') {
          this.handleAdd(index);
        }

        break;

      case 'ArrowUp':
        evt.preventDefault();
        this.setFocusIndex(this.focusIndex === 0 ? this.lastIndex : this.focusIndex - 1);
        break;

      case 'ArrowDown':
        evt.preventDefault();
        this.setFocusIndex(this.focusIndex === this.lastIndex ? 0 : this.focusIndex + 1);
        break;

      case 'ArrowLeft':
        input = this.focusIndex === this.lastIndex ? this.refs.textarea : this.refs[this.props.name + this.focusIndex];

        if (input) {
          input = findDOMNode(input);

          // If the cursor is at the beginning, move to the end of the previous line
          if (input.selectionStart === 0) {
            evt.preventDefault();
            this.setFocusIndex(this.focusIndex === 0 ? this.lastIndex : this.focusIndex - 1);
          }
        }

        break;

      case 'ArrowRight':
        input = this.focusIndex === this.lastIndex ? this.refs.textarea : this.refs[this.props.name + this.focusIndex];

        if (input) {
          input = findDOMNode(input);

          // If the cursor is at the end, move the beginning of the next line
          if (input.selectionStart === input.value.length) {
            evt.preventDefault();
            this.setFocusIndex(this.focusIndex === this.lastIndex ? 0 : this.focusIndex + 1, true);
          }
        }

        break;

      case 'Delete':
      case 'Backspace':
        if (index !== this.lastIndex && (evt.shiftKey || this.state.values[index].text === '')) {
          // For the single inputs, remove the whole value if the shift key is down, or the text is empty
          evt.preventDefault();
          this.toggleRemove(index);
        } else if (index === this.lastIndex && index !== 0 && this.state.values[index].text === '') {
          // For the text area, if there is another line, and the text area is empty,
          // move the cursor to the previous line
          evt.preventDefault();
          this.setFocusIndex(index - 1);
        }

        break;
    }
  },

  handlePaste(evt) {
    const data = evt.clipboardData.getData('text').replaceAll(/\f|\r/gm, '\n'); // Replace page-break/carriage-return with line-feed
    const lines = _.compact(data.split('\n'));

    const newValues = [...this.state.values];

    lines.forEach((line, index) => {
      if (index === 0) {
        // Include anything already in the text area
        line = this.state.values[this.lastIndex].text + line;
      }

      if (!line.trim()) {
        return;
      }

      const value = this.props.parse(line, false, false, true);

      value.type = 'new';
      newValues.splice(this.lastIndex, 0, value);

      // Increment the last index for each line
      this.lastIndex += 1;
    });

    // Clear the last value;
    newValues[this.lastIndex] = this.props.parse('');
    newValues[this.lastIndex].type = 'new';

    // Run validation on the set of values
    this.props.validate(newValues);

    // Set the focus
    this.setFocusIndex(this.lastIndex);

    // Update the parent with the new values
    this.props.onChange(newValues);
    this.setState({values: newValues});

    evt.preventDefault();
  },

  handleRemove(index) {
    let newValues = [...this.state.values];

    // Remove the item
    newValues.splice(index, 1);

    // Set the focus
    this.lastIndex = newValues.length - 1;

    if (this.focusIndex > this.lastIndex) {
      this.focusIndex = this.lastIndex;
    }

    if (this.focusIndex === index) {
      this.setFocusIndex(this.focusIndex === 0 ? this.lastIndex : this.focusIndex - 1);
    }

    // Run validation on the set of values
    newValues = this.props.validate(newValues);

    // Update the parent with the new values
    this.props.onChange(newValues);
    this.setState({values: newValues});
  },

  singleLineInput(value, index) {
    const invalid = value.error && !value.removed;
    let type = invalid ? 'invalid' : value.type;

    type = this.props.showDiff ? type : 'old';

    const classes = cx({
      'SingleInput--disabled': this.props.readonly,
      'text-old': type === 'old',
      'text-updated': type === 'new',
      'text-modified': type === 'updated',
      'text-invalid': type === 'invalid',
      'text-deleted': value.removed,
    });

    const tids = cx('comp-single-input-input', {
      'comp-single-input-disabled': this.props.readonly,
      'comp-single-input-old': type === 'old',
      'comp-single-input-new': type === 'new',
      'comp-single-input-updated': type === 'updated',
      'comp-single-input-invalid': type === 'invalid',
      'comp-single-input-removed': value.removed,
    });

    let input = null;
    let remove = null;
    let exclude = null;
    let error = null;

    if (value.exclusion) {
      exclude = '(exclude) ';
    }

    if (this.props.readonly) {
      input = (
        <div className={classes} data-tid={tids} ref="address">
          {value.text}
        </div>
      );
    } else {
      // For active inputs
      const inputClasses = `SingleInput-input ${classes}`;

      input = (
        <input
          value={value.text}
          className={inputClasses}
          data-tid={tids}
          ref={this.props.name + index}
          autoFocus={index === this.focusIndex}
          disabled={this.props.disabled}
          onFocus={() => {
            this.setFocusIndex(index);
            this.props.onFocus();
            this.setState({focused: true});
          }}
          onBlur={() => {
            this.props.onBlur();
            this.setState({focused: false});
          }}
          onChange={evt => this.handleChange(evt.target.value, index)}
          onKeyDown={evt => this.handleKeyDown(evt, index)}
        />
      );

      const removeClass = value.removed ? 'SingleInput-revert' : 'SingleInput-remove';
      const icon = value.removed ? 'revert' : 'close';
      const tid = value.removed ? 'comp-single-input-revert' : 'comp-single-input-delete';

      remove = (
        <span className="SingleInput-remove-before">
          <div className="SingleInput-remove" data-tid={tid}>
            <Icon onClick={() => this.toggleRemove(index)} styleClass={removeClass} name={icon} />
          </div>
        </span>
      );

      const errorClasses = `SingleInput-message ${classes}`;

      error = (
        <span className={errorClasses} data-tid="comp-single-input-error">
          {exclude}
          <span className="SingleInput-error ">
            {value.error || value.duplicate ? <Icon name="error" /> : null} {value.error || value.duplicate}
          </span>
        </span>
      );
    }

    return (
      <div key={index} className="SingleInput" data-tid="comp-single-input">
        {remove}
        {input}
        {error}
      </div>
    );
  },

  toggleRemove(index) {
    let newValues = [...this.state.values];
    let value = newValues[index];

    if (value.type === 'new' || !this.props.showDiff) {
      // We can go ahead and delete the value
      this.handleRemove(index);

      return;
    }

    // Reset any edits and toggle the removed flag
    if (value.original) {
      value = value.original;
      value.text = this.props.stringify(value);
      value.original = value;
    }

    value.removed = !value.removed;
    newValues[index] = value;

    // Set the focus
    this.setFocusIndex(this.focusIndex === 0 ? this.lastIndex : this.focusIndex - 1);

    // Run validation on the set of values
    newValues = this.props.validate(newValues);

    // Update the parent with the new values
    this.props.onChange(newValues);
    this.setState({values: newValues});
  },

  render() {
    const singleValues = [...this.state.values];
    let newInput = null;
    let hint = null;

    if (!this.props.readonly) {
      // Save the last value for the text area
      const text = singleValues.pop().text;

      newInput = (
        <textarea
          value={text}
          ref="textarea"
          className="MultilineInput-textarea"
          disabled={this.props.readonly || this.props.disabled}
          onFocus={() => {
            this.setFocusIndex(this.lastIndex);
            this.props.onFocus();
            this.setState({focused: true});
          }}
          onBlur={() => {
            if (this.state.values[this.lastIndex].text !== '') {
              this.handleAdd(this.lastIndex, true);
            }

            this.props.onBlur();
            this.setState({focused: false});
          }}
          onPaste={this.handlePaste}
          onChange={evt => this.handleChange(evt.target.value, this.lastIndex)}
          onKeyDown={evt => this.handleKeyDown(evt, this.lastIndex)}
          placeholder={this.props.placeholder}
          data-tid="comp-multilineinput-textarea"
        />
      );
      hint = <div className="MultilineInput-hints">{intl('MultilineInput.ShiftDeleteToDeleteRow')}</div>;
    }

    const singleInputs = singleValues.map((value, index) => this.singleLineInput(value, index));

    const classes = cx('MultilineInput', {
      'MultilineInput--focused': this.state.focused,
      'MultilineInput--error': this.props.error,
      'MultilineInput--disabled': this.props.disabled,
      'MultilineInput--readonly': this.props.readonly,
    });

    return (
      <div className={classes} data-tid="comp-multilineinput">
        {singleInputs}
        {newInput}
        {hint}
      </div>
    );
  },
});
