/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';

Spinner.propTypes = {
  'color': PropTypes.oneOf(['dark', 'light']),
  'position': PropTypes.oneOf(['normal', 'before', 'after', 'inbetween', 'center']),
  'size': PropTypes.oneOf(['small', 'medium', 'large', 'twenty', 'xlarge', 'xxlarge', 'fifty', 'huge', 'banner']),
  'data-tid': PropTypes.string,
};

Spinner.defaultProps = {
  'color': 'dark',
  'position': 'normal',
  'size': 'fifty',
  'data-tid': 'comp-spinner',
};

export default function Spinner({color, size, position, ...props}) {
  props.className = `Spinner Spinner--${size} Spinner--${color} Spinner--${position}`;

  return <div {...props} />;
}
