/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import cx from 'classnames';

function Tooltip(props) {
  const {children, content, dataTid, delayed, isHeader, position, location, subContent, width, zIndex} = props;
  const tooltipClass = cx('TooltipListener', {
    'TooltipCursor': Boolean(content),
    'Tooltip--header': isHeader,
  });
  const contentWrapperStyle = {
    width,
    zIndex,
  };
  let contentClassName = `TooltipBody Tooltip--${location}`;

  if (position) {
    contentClassName += ` Tooltip--${position}`;
  }

  if (delayed) {
    contentClassName += ' TooltipDelayed';
  }

  return (
    <div className={tooltipClass}>
      <span data-tid={dataTid}>{children}</span>
      {content ? (
        <div className={contentClassName} style={contentWrapperStyle}>
          <div className={`TooltipContent ${subContent ? 'Map-Illumination-Tooltip-Illumination' : null}`}>
            {content}
          </div>
          <div className="TooltipArrow" />
          {subContent ? <div className="TooltipSubContent">{subContent}</div> : null}
        </div>
      ) : null}
    </div>
  );
}

Tooltip.propTypes = {
  content: PropTypes.any, // Means content of popup tooltip
  dataTid: PropTypes.any,
  delayed: PropTypes.bool,
  isHeader: PropTypes.bool,
  location: PropTypes.oneOf([
    'topleft',
    'top',
    'topright',
    'left',
    'fixed-right',
    'fixed-right-table',
    'bottomleft',
    'bottom',
    'bottomright',
    'fixed',
    'services', //Position fixed is when tooltip needs to sit in the command panel.
  ]),
  subContent: PropTypes.any, // sub content of popup tooltip
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zIndex: PropTypes.number,
};

Tooltip.defaultProps = {
  delayed: false,
  isHeader: false,
  location: 'topright',
  width: 300,
};

export default Tooltip;
