/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import actionCreators from '../actions/actionCreators';
import Button from './Button.jsx';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';
import {Navigation} from 'react-router';

const defaultTid = 'comp-dialog';

export default React.createClass({
  mixins: [Navigation],

  propTypes: {
    modal: PropTypes.bool,
    confirmation: PropTypes.bool,
    alert: PropTypes.bool,
    type: PropTypes.oneOf(['alert', 'detail', 'form', 'instantSearch']),
    title: PropTypes.any,
    subTitle: PropTypes.string,
    tid: PropTypes.string,
    actions: PropTypes.array,
    hasBorder: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
  },

  getDefaultProps() {
    return {
      modal: true,
      type: 'alert',
      confirmation: false,
      alert: false,
      subTitle: null,
      actions: [],
      onClose: _.noop,
    };
  },

  componentDidMount() {
    this.initRoute = this.context.router.getCurrentPath();
  },

  componentWillReceiveProps() {
    if (this.context.router.getCurrentPath() !== this.initRoute) {
      actionCreators.closeDialog();
      this.props.onClose();
    }
  },

  handleClose() {
    actionCreators.closeDialog();
    this.props.onClose();
  },

  handleOverlayClick() {
    actionCreators.closeDialog();
    this.props.onClose();
  },

  render() {
    const classes = cx('Dialog', this.props.className, {
      'Dialog-overlay': this.props.modal,
      'Dialog-detail': this.props.type === 'detail',
      'Dialog-form': this.props.type === 'form',
      'Dialog-confirmation': this.props.confirmation,
      'Dialog-alert': this.props.alert,
    });

    const contentClasses = cx({
      'Dialog-content': this.props.type !== 'instantSearch',
      'Dialog-border': this.props.hasBorder,
    });

    const hasTitle = Boolean(this.props.title);
    const hasSubTitle = this.props.subTitle && _.isString(this.props.subTitle);
    const subTitle = hasSubTitle ? (
      <div className="Dialog-subtitle" data-tid="comp-dialog-subtitle">
        {this.props.subTitle}
      </div>
    ) : null;
    const actions = _.compact(this.props.actions.map(action => (_.isFunction(action) ? action() : action)));
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <div
        className={classes}
        data-tid={ComponentUtils.tidString(tids)}
        onClick={this.props.modal && this.props.alert ? this.handleOverlayClick : null}
      >
        <div
          className={this.props.type === 'instantSearch' ? 'Dialog-instantSearchElement' : 'Dialog-element'}
          data-tid="comp-dialog-element"
          onClick={evt => evt.stopPropagation()}
        >
          {this.props.type === 'detail' ? (
            <div className="Dialog-detail-close" data-tid="comp-dialog-detail-close">
              <Icon onClick={this.handleClose} name="close" styleClass="Close" />
            </div>
          ) : null}
          {hasTitle ? (
            <div className="Dialog-header">
              <div className="Dialog-title" data-tid="comp-dialog-title">
                {this.props.title}
              </div>
              {subTitle}
            </div>
          ) : null}
          <div className={contentClasses} data-tid="comp-dialog-content">
            {this.props.children}
          </div>
          {actions.length ? (
            <div className="Dialog-actions" data-tid="comp-dialog-actions">
              {actions.map((action, index) => (
                <Button key={index} {...action} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
});
