/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import InstantSearchStore from './InstantSearchStore';

let matches = [];

export default createApiStore(
  [
    'OPEN_',
    'VIRTUAL_SERVICES_',
    'VIRTUAL_SERVERS_',
    'IP_LISTS_',
    'WORKLOADS_',
    'LABEL_GROUPS_',
    'LABELS_',
    'SERVICES_',
    'NETWORKS_',
    'CONTAINER_CLUSTERS_',
    'CONTAINER_WORKLOADS_',
  ],
  {
    dispatchHandler(action) {
      switch (action.type) {
        case Constants.VENS_AUTOCOMPLETE_SUCCESS:
        case Constants.VIRTUAL_SERVICES_AUTOCOMPLETE_SUCCESS:
        case Constants.VIRTUAL_SERVERS_AUTOCOMPLETE_SUCCESS:
        case Constants.IP_LISTS_AUTOCOMPLETE_SUCCESS:
        case Constants.WORKLOADS_AUTOCOMPLETE_SUCCESS:
        case Constants.CONTAINER_CLUSTERS_AUTOCOMPLETE_SUCCESS:
        case Constants.CONTAINER_WORKLOADS_AUTOCOMPLETE_SUCCESS:
        case Constants.LABEL_GROUPS_AUTOCOMPLETE_SUCCESS:
        case Constants.LABELS_AUTOCOMPLETE_SUCCESS:
        case Constants.SERVICES_AUTOCOMPLETE_SUCCESS:
        case Constants.NETWORKS_AUTOCOMPLETE_SUCCESS:
          matches = InstantSearchStore.isOpen() ? action.data.matches : [];
          break;

        default:
          return true;
      }

      this.emitChange();

      return true;
    },

    getAll() {
      return matches;
    },

    clear() {
      matches = [];

      this.emitChange();
    },
  },
);
