/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';

export default function (store, getState) {
  if (!store) {
    throw new Error('StoreMixin requires a store as its first parameter' + 'mixins: [StoreMixin(MyStore, ...)]');
  }

  if (!_.isFunction(getState)) {
    throw new TypeError(
      'StoreMixin requires a getState function as its second parameter' + 'mixins: [StoreMixin(..., getState)]',
    );
  }

  const stores = Array.isArray(store) ? store : [store];

  return {
    componentWillMount() {
      stores.forEach(store => {
        store.addChangeListener(this.setStateFromStore);
      });
    },

    componentWillUnmount() {
      stores.forEach(store => {
        store.removeChangeListener(this.setStateFromStore);
      });
    },

    setStateFromStore() {
      if (this.isMounted()) {
        this.setState(getState.call(this), () => {
          if (_.isFunction(this.onStoreChange)) {
            this.onStoreChange();
          }
        });
      }
    },

    getInitialState() {
      return getState.call(this);
    },
  };
}
