/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Children, cloneElement, createElement, PropTypes, Component} from 'react';
import MenuDelimiter from './MenuDelimiter';
import Item from './MenuItem';

export const itemsType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.node,
  PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    const element = propValue[key];

    if (element && element.type !== Item && element.type !== MenuDelimiter) {
      return new Error(
        `Prop '${propFullName}' supplied to '${componentName}' can contain MenuItem, MenuDelimiter or null`,
      );
    }
  }),
]);

export default class MenuItems extends Component {
  static propTypes = {
    style: PropTypes.object,
    active: PropTypes.bool,

    saveRef: PropTypes.func, // Pass item instance upwards
    onItemClick: PropTypes.func,
    onItemFocus: PropTypes.func,
    onItemMouse: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.rect = null;
    this.items = [];
  }

  componentWillMount() {
    this.saveRef = this.saveRef.bind(this);
    this.saveItemRef = this.saveItemRef.bind(this);
  }

  saveRef(element) {
    this.listElement = element;
    this.calcRect();
    this.props.saveRef(this);
  }

  saveItemRef(item, add) {
    if (add) {
      this.items.push(item);
    } else {
      const itemIndex = this.items.indexOf(item);

      if (itemIndex > -1) {
        this.items.splice(itemIndex, 1);
      }
    }
  }

  calcRect() {
    let rect = null;

    if (this.listElement) {
      rect = this.listElement.getBoundingClientRect();

      // Make sure element is not hidden (display: none)
      if (!rect.width && !rect.height) {
        rect = null;
      }
    }

    this.rect = rect;

    return this.rect;
  }

  renderItem(item) {
    if (item) {
      const {props} = this;

      if (item.type === Item) {
        const addedProps = {
          tabIndex: '-1', // To make each item focusable
          onRef: this.saveItemRef,
          onClick: props.onItemClick,
          onFocus: props.onItemFocus,
          onMouse: props.onItemMouse,
        };

        return cloneElement(item, addedProps);
      }

      if (item.type === MenuDelimiter) {
        return item;
      }
    }

    return item;
  }

  renderItems() {
    const {children} = this.props;

    // Cache react elements for each child item
    this.childrenElements ||=
      // If children is function, call it first to get array of MenuItems
      (typeof children === 'function'
        ? children().map(this.renderItem.bind(this))
        : Children.map(children, this.renderItem, this)) || [];

    return this.childrenElements;
  }

  render() {
    const {active, style} = this.props;
    const children = [...this.renderItems()];

    const props = {
      ref: this.saveRef,
      className: cx('Menu-itemsList', {
        'Menu-itemsList--active': active,
      }),
      style: {
        opacity: style.opacity,
        transform: `translate3d(${style.x || 0}px, ${style.y || 0}px, 0)`,
      },
    };

    return createElement('ul', props, ...children);
  }
}
