/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

let rules = [];

function setRules(data) {
  rules = data;
}

export default createApiStore(['ESSENTIAL_SERVICE_RULES'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.ESSENTIAL_SERVICE_RULES:
        setRules(action.data);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getRules() {
    return rules;
  },
});
