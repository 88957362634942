/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Navigation, State} from 'react-router';
import {StoreMixin} from '../../mixins';
import {MapPageStore} from '../../stores';
import actionCreators from '../../actions/actionCreators';
import {ObjectSelector, Icon, Vulnerability} from '../../components';
import MapSearchStore from '../../stores/MapSearchStore';
import {RenderUtils} from '../../utils';

function getStateFromStores() {
  return MapSearchStore.getSearch();
}

export default React.createClass({
  mixins: [Navigation, State, StoreMixin(MapSearchStore, getStateFromStores)],

  getInitialState() {
    return {items: {}, open: false};
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.navbar && nextProps.title !== this.props.title) {
      this.setState({open: false});
    }
  },

  generateCustomList(listItem) {
    if (!listItem) {
      return;
    }

    const {text, props, item} = listItem;
    const value =
      item && this.state && this.state.options && typeof this.state.options === 'object' && this.state.options[item];

    if (item && item.footer) {
      return (
        <li key={item.text} {...props} data-tid="comp-select-results-item">
          {item.text}
        </li>
      );
    }

    if (!item || !value || value.tooManyWorkloads) {
      return (
        <li key={text} {...props} data-tid="comp-select-results-item">
          {text}
        </li>
      );
    }

    let details;
    let coverage;
    let vulnerability;
    let truncatedName;

    switch (value.type) {
      case 'loc':
        details = (
          <small>
            {`${intl('Common.Groups')}: ${value.groups}, ${intl('Common.Workloads')}: ${
              value.workloads || value.num_workloads
            }`}
          </small>
        );
        break;
      case 'group':
        details = <small>{`${intl('Common.Workloads')}: ${value.workloads}`}</small>;
        break;
      case 'appGroup':
        let percentage;

        if (value.intraGroupCoverage && value.extraGroupCoverage && value.ipListCoverage) {
          const total =
            value.intraGroupCoverage.num_services +
            value.extraGroupCoverage.num_services +
            value.ipListCoverage.num_services;

          percentage =
            total &&
            (value.intraGroupCoverage.num_rules + value.extraGroupCoverage.num_rules + value.ipListCoverage.num_rules) /
              total;

          if (percentage > 0) {
            // Always show at least 10% if any coverage exists
            percentage = Math.max(percentage, 0.1);
          }
        }

        details = <small>{`${intl('Common.Workloads')}: ${value.workloads}`}</small>;

        if (value.intraGroupCoverage) {
          coverage = (
            <div className="SearchBar-CoverageBar">
              <div className="SearchBar-CoverageBar-Fill" style={{width: `${percentage * 100}%`}} />
            </div>
          );
        }

        if (MapPageStore.getAppMapVersion() === 'vulnerability' && value && value.vulnerability) {
          vulnerability = (
            <span className="FormPanel-FindGroup-Vulnerability">
              <Vulnerability vulnerability={value.vulnerability} />
            </span>
          );
        }

        if (typeof text === 'string') {
          truncatedName = RenderUtils.truncateAppGroupName(text, 60, [30, 15, 15]);
        }
    }

    let name = text;

    // If the name is truncated, use that rather that the bolded matching text version
    if ((truncatedName || value.truncatedName) !== value.searchName) {
      name = truncatedName || value.truncatedName;
    }

    return (
      <li key={text} {...props} data-tid="comp-select-results-item">
        <div className={coverage ? 'SearchBar-Result' : ''}>
          <div className="SearchBar-Result-Row">
            <div className="SearchBar-Result-Details">{vulnerability}</div>
            <div className="SearchBar-Result-Text">{name}</div>
            <div className="SearchBar-Result-Details">{details}</div>
          </div>
          {coverage}
        </div>
      </li>
    );
  },

  handleMouseOver(item) {
    const {href, type} = this.state.options[item.value];

    if (this.select) {
      this.select = false;
    } else {
      actionCreators.unselectComponent();
    }

    if (type === 'workload') {
      actionCreators.hoverComponent([{type, href}]);
      this.setState({hovered: true});
    } else if (this.state.hovered) {
      this.setState({hovered: false});
      actionCreators.unhoverComponent();
    }
  },

  handleClose() {
    if (this.state.hovered) {
      this.setState({hovered: false});
      actionCreators.unhoverComponent();
    }
  },

  onSelectOption(filter, value) {
    if (_.isEmpty(value)) {
      return;
    }

    let selection = filter.split(':')[0];
    const href = value.href;
    let route = 'mapLevel';
    let params = {};
    let type;
    let clusterHref;

    this.select = true;

    if (!filter.includes(':') && MapPageStore.getMapType() === 'loc') {
      selection = intl('Common.Group');
    }

    switch (selection) {
      case intl('Common.Location'):
        type = 'location';
        params = {
          type,
          id: _.last(href.split('/')),
        };
        break;
      case intl('Common.Group'):
        type = 'group';
        route = 'prevMapLevel';
        params = {
          prevtype: 'location',
          previd: _.last(_.find(value.labels, label => label.key === 'loc').href.split('/')),
          type,
          id: href,
        };
        actionCreators.clearExpandedClusters();
        break;
      case intl('Common.Workload'):
      case intl('Common.ContainerWorkload'):
        type = 'workload';
        clusterHref = value.clusterParent;
        break;
      case intl('Common.VirtualService'):
        type = 'virtualService';
        clusterHref = value.clusterParent;
        break;
      case intl('Common.VirtualServer'):
        type = 'virtualService';
        clusterHref = value.clusterParent;
        break;
      default:
        const id = _.last(href.split('/'));

        type = 'appGroup';
        route = this.props.route;
        params =
          route === 'appGroupIllumination'
            ? {id}
            : {
                type: 'focused',
                id,
              };
        break;
    }

    if (this.props.type !== 'full') {
      actionCreators.startSpinner();
      actionCreators.updateMapRoute(params);
      this.transitionTo(route, params);
    }

    _.defer(() => this.refs.search?.click());
    actionCreators.updateComponentSelection([{type, href, clusterHref}]);
  },

  handleSearchClick(evt) {
    if (this.input) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      this.input.refs.itemInput.click();
    }
  },

  handleCloseNav() {
    this.setState({open: false});
  },

  handleToggleOpen() {
    this.setState({open: !this.state.open});
  },

  render() {
    const {type, policyVersion, rulesetRead} = this.props;
    const {options, items} = this.state;
    const totalWorkloadThreshold = parseInt(localStorage.getItem('total_workload_threshold'), 10) || 100_000;
    const totalWorkloads = MapPageStore.getTotalWorkloads();

    const disabled = totalWorkloads > totalWorkloadThreshold;

    const classes = cx('SearchBar', {'SearchBar-draft': policyVersion === 'draft'});

    const filteredOptions = rulesetRead
      ? Object.keys(options || {}).reduce((results, key) => {
          if (options[key].caps?.rulesets?.includes('read')) {
            results[key] = options[key];
          }

          return results;
        }, {})
      : options;

    let totals;

    if (type === 'app') {
      totals = `${intl('Common.Total')} ${intl('Common.AppGroups')}: ${this.state.appGroupCount}`;
    } else if (type === 'loc') {
      totals = `${intl('Common.Total')} - ${intl('Common.Locations')}: ${this.state.locationCount}, ${intl(
        'Common.Groups',
      )}: ${this.state.groupCount}`;
    } else {
      totals = `${intl('Common.Total')} - ${intl('Common.Groups')}: ${this.state.groupCount}, ${intl(
        'Common.Workloads',
      )}: ${this.state.workloadCount}`;
    }

    const objectSelectorProps = {
      addItem: this.onSelectOption,
      removeItem: _.noop,
      dropdownValues: {},
      facetMap: {},
      getFacetValues: _.noop,
      initialValues: [],
      items,
      onMouseOver: this.handleMouseOver,
      onClose: this.handleClose,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      placeholder:
        Object.keys(items).length < 2
          ? type === 'app'
            ? intl('Map.SearchAppGroups')
            : intl('Map.SearchIllumination')
          : null,
      returnValue: item => item,
      singleValues: filteredOptions,
      customListItem: this.generateCustomList,
      allowOne: true,
      showCountOnFilter: true,
      ref: node => (this.input = node),
      tid: 'search-illumination',
      footerValues: [
        {
          footer: true,
          text: totals,
          className: 'ObjectSelector-dd-values-item--hint',
        },
      ],
    };

    if (type === 'app' && this.props.navbar) {
      return (
        <div className="SearchBar SearchBar-NavBar">
          {this.state.open ? (
            <div className="SearchBar-Selector">
              <ObjectSelector
                {...{
                  ...objectSelectorProps,
                  placeholder: '',
                  alwaysOpen: true,
                  onClose: this.handleCloseNav,
                  autoFocus: true,
                  maxResults: 15,
                  enforceMaxItems: true,
                }}
              />
              <div className="SearchBar-Open" onClick={this.handleToggleOpen}>
                <Icon name="caret-up" />
              </div>
            </div>
          ) : (
            (this.props.title || this.props.routeType === 'main') && (
              <div className="SearchBar-Title" onClick={this.handleToggleOpen}>
                <span className="SearchBar-Separator">{this.props.routeType === 'detail' ? '-' : ''}</span>
                <span data-tid="comp-navbar-title">{this.props.title}</span>
                <div className="SearchBar-Open">
                  <Icon name="caret-down" />
                </div>
              </div>
            )
          )}
        </div>
      );
    }

    if (type === 'app' && !this.props.main) {
      return <div className={classes} />;
    }

    return (
      <div className={classes}>
        <div className="SearchBar-search-icon">
          {disabled && type === 'loc' ? null : (
            <Icon name="search" onClick={this.handleSearchClick} styleClass="Search" />
          )}
        </div>
        <div className="SearchBar-search" data-tid="search-illumination">
          {disabled && type === 'loc' ? null : <ObjectSelector {...objectSelectorProps} />}
        </div>
        <div className="SearchBar-controls" ref="search" />
      </div>
    );
  },
});
