/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

let dropdownValues = {};
let isLoaded = false;

function setDropdownValues(facet, query, data) {
  dropdownValues[`${facet}-${query}`] = data;
}

export default createApiStore('LABEL_GROUPS_FACETS_', {
  dispatchHandler(action) {
    const query = action.options && action.options.query;

    switch (action.type) {
      case Constants.LABEL_GROUPS_FACETS_SUCCESS:
        setDropdownValues(query.facet, query.query, action.data);
        isLoaded = true;
        break;

      case Constants.RESET_FACET_VALUES:
        if (action.data === 'labelGroupList') {
          dropdownValues = {};
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getDropdownValues() {
    return dropdownValues;
  },

  isLoaded() {
    return isLoaded;
  },
});
