/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import {ToolBar} from '../ToolBar';
import {GridDataUtils} from '../../utils';
import {Grid} from '..';
import intl from '@illumio-shared/utils/intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {TrafficFilter} from '.';

ConsumingAppGroupGrid.propTypes = {
  innerGridData: PropTypes.array,
  editingId: PropTypes.string,
  tite: PropTypes.string,
  subtitle: PropTypes.string,
  appGroups: PropTypes.array,
  onRowExpand: PropTypes.func,
  onRowSelect: PropTypes.func,
  onRowClick: PropTypes.func,
  selection: PropTypes.array,
  sorting: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default function ConsumingAppGroupGrid(props) {
  const testGridData = props.appGroups.map(appGroup => ({
    caret: ' ',
    coverage: appGroup.connectionRules && appGroup.connections ? appGroup.connectionRules / appGroup.connections : 0,
    connectionsWithRules: appGroup.connectionRules ? appGroup.connectionRules : 0,
    connectionsWithRulesReplace: appGroup.connectionRulesReplace ? appGroup.connectionRulesReplace : 0,
    name: appGroup.appGroup.name,
    href: appGroup.appGroup.href,
    type: props.type,
    connections: intl('PolicyGenerator.Connections', {count: appGroup.connections}),
  }));

  const columns = [
    {
      key: 'name',
      style: 'consuming-app-group',
      label: intl('Common.Name'),
      format: value => {
        if (value.length < 80) {
          return <span title={value}>{value}</span>;
        }

        const labels = value.split(' | ');

        if (labels[0].length > 32) {
          labels[0] = `${labels[0].substr(0, 30)}\u2026`;
        }

        if (labels[1].length > 22) {
          labels[1] = `${labels[1].substr(0, 15)}\u2026`;
        }

        if (labels[2] && labels[2].length > 22) {
          labels[2] = `${labels[2].substr(0, 15)}\u2026`;
        }

        return <span title={value}>{labels.join(' | ')}</span>;
      },
      sortable: true,
      // The Grid component doesn't do case insensitive sorting by default
      sortValue: value => value.toLocaleLowerCase(),
    },
    {
      key: 'connections',
      style: 'connections',
      label: intl('Common.Connections'),
      sortable: true,
      // The value of row.connections would be of format "XX Connections" (Example: "52 Connections")
      // We want to sort on the number "XX" ("52" for above example)
      sortValue: value => Number(value.split(' ')[0]),
    },
  ];

  if (!props.noRules) {
    columns.push({
      key: 'coverage',
      style: 'coverage',
      label: intl('PolicyGenerator.Grid.Coverage'),
      format: (value, row) => (
        <div className="ConsumingAppGroupGrid-countBar">
          <div className="ConsumingAppGroupGrid-countBarContent">
            <div className="ConsumingAppGroupGrid-countBarLabel">
              {row.connectionsWithRules !== Number(row.connections.split(' ')[0]) ||
              (row.type === 'replace' && row.connectionsWithRulesReplace === Number(row.connections.split(' ')[0]))
                ? intl('PolicyGenerator.Grid.ConnectionWithRules', {count: row.connectionsWithRules})
                : intl('PolicyGenerator.Grid.CompleteCoverage')}
            </div>
            <div className="AppGroupCoverage-card-info-count-bar">
              <div className="AppGroupCoverage-card-info-count-bar-fill" style={{width: `${value * 100}%`}}>
                <ReactCSSTransitionGroup
                  transitionName="AppGroupCoverage-card-info-count-bar-fill-value"
                  transitionAppear={true}
                  transitionAppearTimeout={5000}
                  transitionEnterTimeout={0}
                  transitionLeaveTimeout={0}
                >
                  <div className="AppGroupCoverage-card-info-count-bar-fill-value" />
                </ReactCSSTransitionGroup>
              </div>
            </div>
          </div>
        </div>
      ),
      sortable: true,
    });
  }

  return (
    <div className="ConsumingAppGroupGrid-wrapper">
      <ToolBar>
        <div className="ConsumingAppGroupGrid-title-bar">
          <div className="ConsumingAppGroupGrid-title-section">
            <div className="ConsumingAppGroupGrid-title">{intl('PolicyGenerator.ExtraScope.ChooseExtraScope')}</div>
            <div className="ConsumingAppGroupGrid-subtitle">
              {intl('PolicyGenerator.Grid.NumberSelected', {subtitle: props.subtitle, count: props.selection.length})}
            </div>
            <div className="PolicyGeneratorGrid-transmission">
              <TrafficFilter onSetFilter={props.onTransmissionFilterChange} filters={props.transmissionFilters} />
            </div>
          </div>
        </div>
      </ToolBar>
      <div className="ConsumingAppGroupGrid-grid">
        <Grid
          editable={true}
          editingId={props.editingId}
          idField="href"
          data={testGridData}
          columns={columns}
          selectable={true}
          selection={props.selection}
          onRowSelectToggle={props.onRowSelect}
          onRowClick={props.onRowClick}
          rowClass={GridDataUtils.formatPolicyGeneratorChoose}
          notificationUnderRow={true}
          rowSelectable={row =>
            row.name !== intl('PolicyGenerator.MissingLabels') &&
            (row.connectionsWithRules !== Number(row.connections.split(' ')[0]) ||
              (row.type === 'replace' && row.connectionsWithRulesReplace !== Number(row.connections.split(' ')[0])))
          }
          sortable={true}
          sorting={props.sorting}
          onSort={props.onSort}
        />
      </div>
    </div>
  );
}
