/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {PropTypes} from 'react';

export default {
  childContextTypes: {
    getCurrentParams: PropTypes.func,
    getCurrentPath: PropTypes.func,
    getCurrentPathname: PropTypes.func,
    getCurrentQuery: PropTypes.func,
    getCurrentRoutes: PropTypes.func,
    goBack: PropTypes.func,
    isActive: PropTypes.func,
    makeHref: PropTypes.func,
    makePath: PropTypes.func,
    replaceWith: PropTypes.func,
    transitionTo: PropTypes.func,
  },

  getChildContext() {
    return {
      getCurrentParams: _.noop,
      getCurrentPath: _.noop,
      getCurrentPathname: _.noop,
      getCurrentQuery: _.noop,
      getCurrentRoutes: _.noop,
      goBack: _.noop,
      isActive: _.noop,
      makeHref: _.noop,
      makePath: _.noop,
      replaceWith: _.noop,
      transitionTo: _.noop,
    };
  },
};
