/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-notification';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    message: PropTypes.any,
    button: PropTypes.any,
    icon: PropTypes.string,
    alwaysWrap: PropTypes.bool,
    closeable: PropTypes.bool,
    onClose: PropTypes.func,
    onIconClick: PropTypes.func,
    shrink: PropTypes.bool,
    sidebar: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      onClose: _.noop,
      onIconClick: _.noop,
      sidebar: true,
      shrink: false,
    };
  },

  getIcon() {
    if (this.props.icon !== undefined) {
      return this.props.icon;
    }

    switch (this.props.type) {
      case 'instruction':
        return 'info';
      case 'success':
        return 'success';
      case 'warning':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return this.props.type;
      //no default
    }
  },

  handleClose() {
    this.props.onClose();
  },

  render() {
    const classes = cx({
      'Notification': true,
      'Notification--instruction': this.props.type === 'instruction',
      'Notification--success': this.props.type === 'success',
      'Notification--warning': this.props.type === 'warning',
      'Notification--error': this.props.type === 'error',
      'Notification--wrap': this.props.alwaysWrap,
      'Notification--noMessage': !this.props.message,
      'Notification-shrink': this.props.shrink,
      'Notification-sidebar': this.props.sidebar,
    });

    const tids = ComponentUtils.tid(defaultTid, [this.props.tid, this.props.type]);

    const icon = this.getIcon() ? (
      <span className="Notification-icon" data-tid="comp-notification-icon">
        <Icon name={this.getIcon()} onClick={this.props.onIconClick} />
      </span>
    ) : null;
    const title = this.props.title ? (
      <span className="Notification-title" data-tid="comp-notification-title">
        {this.props.title}
      </span>
    ) : null;
    const message = this.props.message ? (
      <span className="Notification-message" data-tid="comp-notification-message">
        {this.props.message}
      </span>
    ) : null;
    const button = this.props.button ? (
      <span className="Notification-button" data-tid="comp-notification-button">
        {this.props.button}
      </span>
    ) : null;
    const close = this.props.closeable ? (
      <span className="Notification-close" data-tid="comp-notification-close" onClick={this.handleClose}>
        <Icon name="close" styleClass="Close" />
      </span>
    ) : null;

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
        {icon}
        <span className="Notification-grow">
          {title}
          {message}
          <span className="Notification-clear" />
        </span>
        {button}
        {close}
      </div>
    );
  },
});
