/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {ObjectSelector} from '..';
import React, {PropTypes} from 'react';

export default React.createClass({
  propTypes: {
    type: PropTypes.oneOf(['from', 'to']).isRequired,
    items: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCustom: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    customValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.bool]),
    onClick: PropTypes.func.isRequired,
    autoLoadCustom: PropTypes.bool,
  },

  handleAdd(item, value) {
    const items = {[item]: value};

    this.props.onChange(items, this.props.type);

    if (value === 'custom') {
      // Enable calendar
      this.props.onCustom(this.props.type);
    } else {
      // If any other value is selected, clear existing calendar value
      this.props.onCustom(this.props.type, true);
    }
  },

  handleRemove(item) {
    this.props.onChange({}, this.props.type);

    if (item === intl('DateTimeInput.CustomTime')) {
      // If custom value is removed, clear existing calendar value
      this.props.onCustom(this.props.type, true);
    }
  },

  returnValue(value) {
    if (value === 'custom') {
      // Format DateTime => 5/3/2017, 12:00 AM
      return intl.date(this.props.customValue, 'l_HH_mm');
    }

    return value;
  },

  handleClick(evt) {
    const {type, items, autoLoadCustom} = this.props;

    this.props.onClick(evt, type);

    if (autoLoadCustom && !items.hasOwnProperty(intl('DateTimeInput.CustomTime'))) {
      this.handleAdd([intl('DateTimeInput.CustomTime')], 'custom');
    }

    this.setState({clickAfterRemove: false});
  },

  render() {
    const {customValue, active, items, singleValues} = this.props;
    const classnames = cx('DTPickerInput', {
      'DTPickerInput--Active': active,
      'DTPickerInput--CustomValue': customValue,
    });

    const inputProps = {
      addItem: this.handleAdd,
      removeItem: this.handleRemove,
      dropdownValues: {},
      facetMap: {},
      getFacetValues: _.noop,
      initialValues: [],
      items,
      placeholder: Object.keys(this.props.items).length ? '' : intl('DateTimeInput.SelectDateAndTime'),
      returnValue: this.returnValue,
      singleValues,
      allowOne: true,
      onFocus: this.handleClick,
      autoLoadCustom: this.props.autoLoadCustom,
    };

    if (customValue) {
      // This makes the custom a regular object and shows the selected value in the items list in ObjectSelector
      inputProps.singleValues = {...inputProps.singleValues};
      delete inputProps.singleValues[intl('DateTimeInput.CustomTime')];
    }

    return (
      <div className={classnames}>
        <ObjectSelector {...inputProps} />
      </div>
    );
  },
});
