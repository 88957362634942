/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {Motion, TransitionMotion} from 'react-motion';
import {Component, PropTypes} from 'react';
import Items from './MenuItems';
import {items as itemsMotions} from './motions';

export default class MenuItemsContainer extends Component {
  static propTypes = {
    dir: PropTypes.number,
    theme: PropTypes.object,

    saveItemsRef: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
    onItemFocus: PropTypes.func.isRequired,
    onItemMouse: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    // Items list key as simple increasing sequence number
    this.itemsKey = 1;

    // Cache to save key and rectangle (width, height) values for each items list
    // Map<children:{key, rect}>
    this.itemsCacheMap = new Map();

    // Items that are currently rendered (have dom elements) to track really active items list
    // Map<children:itemListInstance>
    this.showingItemsListsMap = new Map();

    // By default container size is zero, it will be calculated after first items list render
    this.itemsContainerMotionConfig = {width: 0, height: 0};

    // At the first show children should be in opened position
    this.itemsTransitionMotionConfig = [
      {
        key: String(this.itemsKey),
        data: props.children,
        style: {y: 0, opacity: 1},
      },
    ];
  }

  componentWillMount() {
    this.saveItemsRef = this.saveItemsRef.bind(this);

    this.itemsEnter = this.itemsEnter.bind(this);
    this.itemsLeave = this.itemsLeave.bind(this);
    this.interpolateContainerMotion = this.interpolateContainerMotion.bind(this);
    this.interpolateTransitionMotion = this.interpolateTransitionMotion.bind(this);
    this.interpolateTransitionWrapper = this.interpolateTransitionWrapper.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.props.children) {
      let key;
      const itemsCache = this.itemsCacheMap.get(nextProps.children);

      if (itemsCache) {
        // If we know dimensions of new children (they were rendered sometime before current children),
        // set this height as target rigth away (don't need to wait saveItemsRef)
        // and use its key to return list back if it's in closing animation state
        this.itemsContainerMotionConfig = itemsMotions.containerSizes(itemsCache.rect);

        key = itemsCache.key;
      } else {
        // If we are going to render new children for the first time,
        // stop height animation (for instance, if width/height is being animated in different direction),
        // by assigning plain values to motion config and wait for the new children's dimensions in saveItemsRef
        const currentConfig = this.itemsContainerMotionConfig;

        this.itemsContainerMotionConfig = {
          width: currentConfig.width.val || currentConfig.width,
          height: currentConfig.height.val || currentConfig.height,
        };

        key = String(++this.itemsKey);
      }

      this.itemsTransitionMotionConfig = [
        {
          key,
          data: nextProps.children,
          style: itemsMotions.listActive,
        },
      ];
    }
  }

  // Fast check for children change only, instead of using PureComponent
  shouldComponentUpdate(nextProps) {
    return nextProps.children !== this.props.children;
  }

  componentDidUpdate() {
    // Need to check if active list has been changed
    // It can happen when user's pointer hovers previously hovered element before its items list is destroyed.
    // For instance, user move pointer forth and back over items with children as quickly as transition motion from
    // one item list to another is not finished and there will be no saveItemsRef on returned items list
    this.notifyUpperDropdownOnRefChange();
  }

  saveItemsRef(itemList) {
    const incoming = Boolean(itemList.listElement);

    if (incoming) {
      // Add rendered items list to map
      this.showingItemsListsMap.set(itemList.props.children, itemList);

      if (!this.itemsCacheMap.get(itemList.props.children)) {
        const {rect} = itemList;

        // If it's a first render, simply assign plain values to avoid container size animation,
        // if subsequent render - animate size transition
        this.itemsContainerMotionConfig = this.itemsCacheMap.size
          ? itemsMotions.containerSizes(rect)
          : {
              width: rect.width,
              height: rect.height,
            };

        // Save items list dimensions for transitioning to it next time without waiting for the ref
        this.itemsCacheMap.set(itemList.props.children, {key: this.itemsTransitionMotionConfig[0].key, rect});

        // Render one more time with new dimensions
        // forceUpdate invokes render on next tick, after componentDidMount/DidUpdate
        this.forceUpdate();
      }
    } else {
      // Delete unmounted items list from map
      this.showingItemsListsMap.delete(itemList.props.children);
    }

    this.notifyUpperDropdownOnRefChange();
  }

  notifyUpperDropdownOnRefChange() {
    // It is called when component itself or ref for underlying itemList have been updated
    // to notify upper dropdown component that active items list probably has been changed
    const activeItemList = this.showingItemsListsMap.get(this.props.children);

    if (activeItemList) {
      this.props.saveItemsRef(activeItemList);
    }
  }

  itemsEnter() {
    // Items list starting position
    return itemsMotions.listEnter(this.props.dir);
  }

  itemsLeave() {
    // Items list unmounting position
    return itemsMotions.listLeave(this.props.dir);
  }

  interpolateTransitionWrapper(interpolatedStyles) {
    return <div className="Menu-itemsExtender">{interpolatedStyles.map(this.interpolateTransitionMotion)}</div>;
  }

  interpolateTransitionMotion(config) {
    const {props} = this;

    return (
      <Items
        key={config.key}
        active={config.key === this.itemsTransitionMotionConfig[0].key}
        style={config.style}
        theme={props.theme}
        saveRef={this.saveItemsRef}
        onItemClick={props.onItemClick}
        onItemFocus={props.onItemFocus}
        onItemMouse={props.onItemMouse}
      >
        {config.data}
      </Items>
    );
  }

  interpolateContainerMotion(config) {
    // Important optimisation. Render TransitionMotion only once for all container dimentions motion interpolations
    if (this.itemsDimensionsCofigPrev !== this.itemsContainerMotionConfig) {
      this.itemsDimensionsCofigPrev = this.itemsContainerMotionConfig;
      this.transitionMotionForDimention = (
        <TransitionMotion
          willEnter={this.itemsEnter}
          willLeave={this.itemsLeave}
          styles={this.itemsTransitionMotionConfig}
        >
          {this.interpolateTransitionWrapper}
        </TransitionMotion>
      );
    }

    return (
      <div className="Menu-itemsContainer" style={{width: `${config.width}px`, height: `${config.height}px`}}>
        {this.transitionMotionForDimention}
      </div>
    );
  }

  render() {
    return <Motion style={this.itemsContainerMotionConfig}>{this.interpolateContainerMotion}</Motion>;
  }
}
