/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';
import LabelStore from '../stores/LabelStore';
import SettingsOrgStore from '../stores/SettingsOrgStore';

const defaultTid = 'comp-label-group';

export default React.createClass({
  propTypes: {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.any,
    type: PropTypes.string,
    labelType: PropTypes.string,
    exclusion: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      onClick: null,
      text: '',
    };
  },

  getInitialState() {
    return {
      labelSettingList: LabelStore.getLabelSettingsList(),
      labelTypes: LabelStore.getLabelTypes(),
      labelStyle: SettingsOrgStore.getLabelStyle(),
    };
  },

  render() {
    const {type, labelType: labelTypeProps, exclusion} = this.props;
    const labelType = labelTypeProps || type;
    const {labelSettingList, labelTypes, labelStyle} = this.state;
    const labelSetting = labelSettingList.find(label => label.key === labelType);
    const classes = cx({
      'LabelGroup': true,
      'LabelGroup--role': type === 'role',
      'LabelGroup--app': type === 'app',
      'LabelGroup--env': type === 'env',
      'LabelGroup--loc': type === 'loc',
      'LabelGroup--created': type === 'created',
      'LabelGroup--deleted': type === 'deleted',
      'LabelGroup--exclusion': exclusion,
    });

    const tids = ComponentUtils.tid(defaultTid, type);

    let {text} = this.props;
    let icon = this.props.icon ? this.props.icon : null;
    let exclusionText = '';
    let titleText = text;

    if (typeof text === 'object') {
      titleText = text.props?.children;
    }

    const exclusionTextName = labelSetting?.display_info.display_name_plural || labelSetting?.display_name || '';

    if (exclusion && labelTypes?.includes(icon || labelType)) {
      exclusionText = `${intl('Labels.Excluded', {type: exclusionTextName})} `;
    }

    const style = {};
    let initial;

    if (labelType && labelTypes?.includes(labelType)) {
      if (Array.isArray(text)) {
        text = this.props.text.map(({props: {children}}) => children).join('');
      }

      const excludedTitleText = exclusion ? intl('Labels.Excluded', {type: exclusionTextName}) : exclusionTextName;

      titleText = `${excludedTitleText} ${titleText}`;

      icon = labelSetting?.display_info?.icon || icon;

      if (labelSetting?.display_info) {
        style.backgroundColor = labelSetting.display_info.background_color;
        style.color = labelSetting.display_info.foreground_color;
      }

      initial = `${__ANTMAN__ ? 'G' : ''}${labelSetting?.display_info?.initial}`;

      if (labelStyle?.includes('prefix') && initial) {
        text = (
          <span>
            <strong>{initial}:&nbsp;</strong>
            {text}
          </span>
        );
      } else if (labelStyle?.includes('suffix') && initial) {
        text = (
          <span>
            {text}
            <strong>-{initial}</strong>
          </span>
        );
      }
    }

    if (icon === 'loc') {
      icon = 'location';
    }

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)} onClick={this.props.onClick} title={titleText}>
        <span className="LabelGroup-content" data-tid="label-group-content">
          {(icon || initial) && (
            <div className={`LabelGroup-icon-group LabelGroup--${icon}`} style={style}>
              <Icon
                labelGroup
                name={icon}
                position="before"
                initial={initial}
                labelStyle={labelStyle && initial ? labelStyle : 'icon'}
              />
            </div>
          )}
          <span className="LabelGroup-text LabelGroup-flex" data-tid="elem-text">
            {exclusionText}
            {text}
          </span>
        </span>
      </div>
    );
  },
});
