/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import {webStorageUtils} from '@illumio-shared/utils';

export default React.createClass({
  getInitialState() {
    // For sharing user between tessereact<->legacy page. #USERJUMP
    if (window['--JUMPS_USER--']) {
      webStorageUtils.setSessionItem('JUMPS_USER', window['--JUMPS_USER--']);
    }

    window.location.reload(false);

    return null;
  },

  render() {
    return null;
  },
});
