/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import Constants from '../constants';
import {createApiStore} from '../lib/store';
import StoreUtils from '../utils/StoreUtils';

let containerWorkloads = [];
let runningContainers = [];
let count = 0;
const workloads = {};

export default createApiStore(['CONTAINER_WORKLOADS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.CONTAINER_WORKLOADS_GET_COLLECTION_SUCCESS:
        containerWorkloads = action.data;
        count = action.count;
        break;

      case Constants.CONTAINER_WORKLOADS_GET_INSTANCE_SUCCESS:
        StoreUtils.setObject(action.data, containerWorkloads);
        break;

      case Constants.CONTAINER_WORKLOADS_GET_RUNNING_CONTAINERS_SUCCESS:
        runningContainers = action.data;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getSpecified(href) {
    return StoreUtils.findObjectByHref(href, containerWorkloads);
  },

  getCount() {
    return count;
  },

  getContainerWorkload(href) {
    return workloads[href.split('/').pop()];
  },

  getAll() {
    return containerWorkloads;
  },

  getRunningContainers() {
    return runningContainers;
  },
});
