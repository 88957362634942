/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import actionCreators from '../../actions/actionCreators';
import {RouterMixin, PolicyGeneratorMixin} from '../../mixins';
import {RBSuccessMessage} from '../../components/PolicyGenerator';

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin],

  componentWillMount() {
    const state = this.getExtraSuccessInfo();

    if (!state.appGroupName && !(this.getParams() && this.getParams().id)) {
      this.transitionTo('policygenerator');
    }

    // getSuccessInfo returns an obj with following keys: appGroupName, newRules, newServices, rulesetId
    this.setState(state);
    this.setSelectedConnectedAppGroups([]);
  },

  onContinue() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupPolicyGenerator', {id});
    } else {
      this.transitionTo('policygenerator');
    }
  },

  onSelectAnother() {
    this.setAppGroup();
    this.transitionTo('policygenerator');
  },

  onGoToRule() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupRules', {id});
    } else {
      this.transitionTo('rulesets.item', {id: this.state.rulesetId, pversion: 'draft', tab: 'extrascope'});
    }
  },

  onGoToAppGroupMap() {
    actionCreators.setMapPolicyState('draft');
    this.transitionTo('prevAppMapLevel', {
      prevtype: 'focused',
      previd: this.state.appGroupHref,
      type: 'consuming',
      id: this.state.connectedAppGroup,
    });
  },

  render() {
    const {appGroupName, newRules, newServices} = this.state;
    const id = this.getParams() && this.getParams().id;

    return (
      <div className="RBSuccess">
        <RBSuccessMessage
          name={appGroupName}
          newRules={newRules}
          newServices={newServices}
          type="extra"
          continue={this.onContinue}
          selectAnother={id ? null : this.onSelectAnother}
          goToRule={this.onGoToRule}
          goToAppGroupMap={this.onGoToAppGroupMap}
        />
      </div>
    );
  },
});
