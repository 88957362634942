/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import {Icon, RouteLink} from '.';
import Visibility from 'visibilityjs';
import {Navigation} from 'react-router';
import {UserMixin, StoreMixin} from '../mixins';
import RestApiUtils from '../utils/RestApiUtils';
import {SessionStore, HealthStore} from '../stores';
import {HealthUtils} from '../utils';

function getStateFromStores() {
  let clusterStatus = 'Normal';
  let count = 0;
  let clusters;

  if (this.isSuperclusterUser() && !HealthStore.getInRollingUpgrade() && sessionStorage.getItem('rollingUpgrade')) {
    sessionStorage.removeItem('rollingUpgrade');
  }

  if (SessionStore.isSuperclusterMember() && HealthStore.getLocalData()) {
    // Don't show status and count from other clusters on Supercluster Member
    // as  in the UI, the other member information needs to be suppressed
    clusters = [HealthStore.getLocalData()];
  } else {
    clusters = HealthStore.getClusters();
  }

  clusters.forEach(healthStatus => {
    const status = healthStatus.status;
    const clusterUnknown = HealthUtils.isRollingUpgradeUnknown(healthStatus);

    // Don't set any count or clusterStatus during unknown rolling upgrade
    if (!clusterUnknown) {
      if (status !== 'normal') {
        count++;
      }

      if (status === 'warning' && status !== 'critical') {
        clusterStatus = 'Degraded';
      }

      if (status === 'critical') {
        clusterStatus = 'Error';
      }
    }
  });

  return {clusterStatus, count};
}

export default React.createClass({
  mixins: [Navigation, UserMixin, StoreMixin(HealthStore, getStateFromStores)],

  componentDidMount() {
    const checkFunction = RestApiUtils.health.get;

    // Get health every minute if current browser tab is active
    // If Health is disabled, still call Health.get as it will
    // contain information about listen only mode
    _.defer(checkFunction);
    this.checkIntervalId = Visibility.every(60_000, checkFunction);
  },

  componentWillUnmount() {
    if (this.checkIntervalId) {
      Visibility.stop(this.checkIntervalId);
    }
  },

  render() {
    if (!SessionStore.isHealthEnabled()) {
      // Do not show Health State badge if Health is disabled
      // as the API response only contains information about listen only mode
      // and no Health information
      return null;
    }

    const {clusterStatus, count} = this.state;
    // No styles for 'Normal'
    // 'Error' and 'Degraded' are returned from the API and not intlized
    const classnames = cx('HealthState', {
      'HealthState--Error': clusterStatus === 'Error',
      'HealthState--Degraded': clusterStatus === 'Degraded',
    });
    const icon = <Icon name="pce-health" size="xxlarge" />;
    const routeLinkProps = {'data-tid': 'elem-link'};

    // Supercluster members can only go the local health page
    if (this.isSuperclusterUser() && !SessionStore.isSuperclusterMember()) {
      routeLinkProps.to = 'health.list';
    } else {
      // If non supercluster, directly go to 'local' Health detail page and don't show the list page
      routeLinkProps.to = 'healthDetail';
      routeLinkProps.params = {fqdn: 'local', tab: 'group'};
    }

    const showCount = count || (clusterStatus && clusterStatus !== 'Normal');

    // Admin and owners can only go to health details page
    // All users can see the health icon
    // For Superclusters, show the Yellow icon, with the count, if >=
    // one cluster is not in 'Normal' state
    // For Non Superclusters, show the Yellow icon if the cluster is not in
    // 'Normal' state (no count). Show a default count value of 1.
    return (
      <div className={classnames} data-tid="healthstate">
        {this.isUserAdmin() || this.isUserOwner() || this.isUserReadOnly() ? (
          <RouteLink {...routeLinkProps}>{icon}</RouteLink>
        ) : (
          icon
        )}
        <div className="HealthState-Status" data-tid="status">
          {showCount ? (
            <div className="HealthState-Status-Count" data-tid="count">
              {count || 1}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
});
