/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Component} from 'react';
import intl from '@illumio-shared/utils/intl';
import {Icon, Label, ObjectSelector, Badge} from '../..';
import {IpListStore, TrafficStore, SessionStore} from '../../../stores';
import RenderUtils from '../../../utils/RenderUtils';

export default class SelectEndpoint extends Component {
  constructor(props) {
    super(props);

    this.endpointSelect = this.endpointSelect.bind(this);
    this.endpointRemove = this.endpointRemove.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.customListItem = this.customListItem.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOverCaret = this.handleMouseOverCaret.bind(this);

    this.state = {options: {}};
  }

  componentWillReceiveProps(nextProps) {
    const {selectedEndpoint, edit, type} = nextProps;
    let options = this.state.options;

    // If we have closed the edit by clicking somewhere else before selecting an enpoint
    // return to the last saved endpoint
    if (!nextProps.edit && this.props.edit && _.isEmpty(selectedEndpoint)) {
      this.props.onSelect(type, this.state.savedEndpoint);
    }

    if (nextProps.data !== this.props.data || nextProps.ruleset !== this.props.ruleset) {
      options = this.getEndpoints(nextProps);

      const selected = select =>
        Object.keys(select)[0] === 'resolveAs' ? Object.keys(select)[1] : Object.keys(select)[0];
      const endpoint = _.pick(options, selected(selectedEndpoint));

      this.props.onSelect(type, _.isEmpty(endpoint) ? _.pick(options, selected(options)) : endpoint, options.resolveAs);
    }

    if (!_.isEmpty(selectedEndpoint) && _.isEqual(nextProps.ruleset?.scopes, this.props.ruleset?.scopes)) {
      // Only use the selected as the saved if not in edit mode
      const savedEndpoint = edit ? this.state.savedEndpoint : selectedEndpoint;

      // Move savedEndpoint to the top
      options = _.reduce(
        options,
        (result, option, key) => {
          if (!_.isEqual(savedEndpoint, {[key]: option})) {
            result = {...result, [key]: option};
          }

          return result;
        },
        savedEndpoint,
      );

      this.setState({
        savedEndpoint,
        options,
      });
    } else {
      this.setState({options});
    }
  }

  componentDidUpdate() {
    if (this.input) {
      this.input.refs.itemInput.click();
    }
  }

  // Get all the options for each endpoint given the rule type and traffic
  getEndpoints(props) {
    const {data, type, ruleset, extraScope} = props;
    let endpoint = {...data};
    let endpointType = data.ruleType;

    if (data.ruleType !== 'ringfencing') {
      endpoint = type === 'consumer' ? data.traffic.source : data.traffic.target;
      endpointType = endpoint.type;
    }

    const any = IpListStore.getAnyIpList();
    let options = {};
    let name;

    switch (endpointType) {
      case 'ipList':
      case 'fqdn':
        const {traffic, trafficSelection} = data;
        const {service} = trafficSelection;
        const selectedEndpoint = type === 'consumer' ? trafficSelection.ub : trafficSelection.pb;
        let selectedListName;

        traffic.internetLinks.forEach(internet => {
          // Does this link contain this service?
          if (internet.connections[service.key]) {
            // Find all the ipLists by parsing the href of this internetLink
            // prettier-ignore
            internet.href.split(',')[type === 'consumer' ? 0 : 1].split('x').forEach(list => {
              const any = IpListStore.getAnyIpList();
              const listhref = list === 'internet' ? any && any.href : list;

              if (!listhref || list === 'fqdn') {
                return;
              }

              const ipList = _.find(endpoint.internets, internet => listhref === String(internet.href));
              let name;
              let href;

              if (ipList && !RenderUtils.isHrefFqdn(selectedEndpoint?.href)) {
                name = ipList.name;
                href = ipList.href;
                options[name] = [{ip_list: {href, name}}];
              } else if (RenderUtils.isHrefFqdn(selectedEndpoint?.href) && SessionStore.isGlobalEditEnabled()) {
                name = TrafficStore.getFqdn(selectedEndpoint?.href);
                options[name] = [{ip_list: {fqdns: [{fqdn: name}], name}}];
              }

              if (name) {
                // Remember the selected list
                if (selectedEndpoint?.href === href || !isNaN(selectedEndpoint?.href) && !href) {
                  selectedListName = name;
                }
              }
            });
          }
        });

        const anyIpList = IpListStore.getAnyIpList();

        if (!selectedListName || RenderUtils.isHrefFqdn(selectedEndpoint.href)) {
          options[anyIpList.name] = [{ip_list: {href: anyIpList.href, name: anyIpList.name}}];
        }

        // Move the selected IP list to the top
        const selectedList = [...options[selectedListName || anyIpList.name]];

        delete options[selectedListName];

        options = {
          [selectedListName || anyIpList.name]: selectedList,
          ...options,
        };
        break;

      case 'internet':
        options[any.name] = [{ip_list: any}];
        break;
      case 'workload':
      case 'virtualServer':
      case 'virtualService':
        // Workload options: role, all workloads, specific workload
        options = this.getEndpointLabels(endpoint, 'role', ruleset, extraScope && type === 'consumer');

        // If there are no labels put the 'all' option last
        // Resolve labels will always be the one option
        if (Object.keys(options).length > 1) {
          options[intl('Workloads.All')] = [{actors: 'ams'}];
          options.resolveAs = RenderUtils.getEntityTypes(endpoint);
        }

        // Container rules can only be written with labels
        if (endpoint.subType !== 'container') {
          const type = _.snakeCase(endpointType);

          name = endpoint.secondaryName || endpoint.name;
          name =
            options.hasOwnProperty(name) && options[name].length
              ? `${endpointType === 'workload' ? 'wl' : 'vs'}: ${name}`
              : name;
          options[name] = [{[type]: {href: endpoint.href, name: endpoint.secondaryName || endpoint.name}}];
        }

        if (!options.hasOwnProperty(intl('Workloads.All'))) {
          options[intl('Workloads.All')] = [{actors: 'ams'}];
          options.resolveAs = RenderUtils.getEntityTypes(endpoint);
        }

        Object.keys(options).forEach(key => {
          // Remove any empty items
          if (_.isEmpty(options[key])) {
            delete options[key];
          }
        });

        break;
      case 'role':
      case 'group':
      case 'ringfencing':
        // Label options
        options = this.getEndpointLabels(endpoint, endpointType, ruleset, extraScope && type === 'consumer');

        // Remove options with no values
        if (_.isEmpty(Object.values(options)[0])) {
          options = {};
        }

        options[intl('Workloads.All')] = [{actors: 'ams'}];
        options.resolveAs = RenderUtils.getEntityTypes(endpoint);
        break;
    }

    return options;
  }

  getEndpointLabels(endpoint, level, ruleset = this.state && this.state.selected.ruleset, extraScope) {
    const labels = _.reduce(
      endpoint.labels,
      (result, label) => {
        if (label.key) {
          result[label.key] = label;
        }

        return result;
      },
      {},
    );

    // Use loc label if the app group type or the chosen ruleset has the location label
    const loc =
      level === 'role' &&
      (this.props.data.groupTypes.includes('loc') ||
        (!_.isEmpty(ruleset) &&
          ruleset.scopes.some(scope => scope.find(label => (label.label || label.label_group).key === 'loc'))));

    if (!ruleset || _.isEmpty(labels)) {
      return {};
    }

    // Find all the unique scopes
    const scopeLabels = _.isEmpty(ruleset)
      ? []
      : Object.keys(
          ruleset.scopes.reduce((result, scope) => {
            scope.forEach(item => {
              result[(item.label || item.label_group)?.href] = true;
            });

            return result;
          }, {}),
        );

    // Return {
    //  Group Name: [All the group labels], - if level is group or ringfencing
    //  Role Name: [All the labels except loc if 2 labeled app group], - if level is role
    //  Role Name | Loc Name: [All the labels], - if level is role and 2 labeled app group
    // }
    const name = level === 'role' && labels.role ? labels.role.value : endpoint.name;
    const missingRole = level === 'role' && !labels.role;
    const roleLocName =
      level === 'role' && labels.role && labels.loc ? `${labels.role.value} | ${labels.loc.value}` : null;
    const labelsObj = {
      [name]: [],
      resolveAs: RenderUtils.getEntityTypes(endpoint),
    };

    if (endpoint.type === 'virtualService') {
      labelsObj.resolveAs = ['virtual_services'];
    }

    if (!loc && level === 'role' && roleLocName) {
      labelsObj[roleLocName] = [];
    }

    if (missingRole) {
      return labelsObj;
    }

    return Object.values(labels).reduce((result, item) => {
      // Include the label if it is not in the scope of the ruleset
      // Include the role if the level is 'role'
      const label = item.label ? item.label : item;

      if ((extraScope || !scopeLabels.includes(label.href)) && (label.key !== 'role' || level === 'role')) {
        // Only add the 'loc' label if there is loc in the group type
        // For ringfence rules, only add the location to a global ruleset
        if (
          (this.props.data.ruleType !== 'ringfencing' || !scopeLabels.length) &&
          (label.key !== 'loc' || loc || level !== 'role')
        ) {
          result[name].push({label});
        }

        // Create a second option if no loc in the group type with all the labels
        if (!loc && level === 'role' && roleLocName) {
          result[roleLocName].push({label});
        }
      }

      return result;
    }, labelsObj);
  }

  endpointSelect(key, value) {
    const {onSelect, onEdit, type} = this.props;

    onSelect(type, {[key]: value}, this.state.options.resolveAs);
    onEdit(false);
  }

  endpointRemove() {
    const {onSelect, type} = this.props;

    onSelect(type, {});
  }

  handleEdit() {
    const {onSelect, onEdit, type} = this.props;

    onSelect(type, {});
    onEdit(this.props.type);
  }

  handleCancel() {
    const {onSelect, onEdit, type} = this.props;

    onSelect(type, this.state.savedEndpoint);
    onEdit(false);
  }

  handleMouseOver(item) {
    if (Object.keys(this.state.savedEndpoint)[0] !== item.value) {
      this.setState({removeHighlight: true});
    }
  }

  handleMouseOverCaret() {
    if (this.state.removeHighlight) {
      this.setState({removeHighlight: false});
    }
  }

  customListItem({text, props}) {
    let className = `${props.className || ''} OSRulesetSelectResultItem`;

    if (Object.keys(this.state.savedEndpoint)[0] === text && !this.state.removeHighlight) {
      className += ' OSRulesetSelectSelected';
    }

    return (
      <li key={text} {...props} className={className} data-tid="comp-select-results-item">
        {text}
      </li>
    );
  }

  renderEndpointItems(endpoint, deleted) {
    const empty = _.isEmpty(endpoint);
    // Key is the option name, split out the label names
    const options = empty ? [] : Object.keys(endpoint)[0].split(' | ');

    // Value is the avenger ready object
    let items = empty ? [] : Object.values(endpoint)[0];
    let newBadge = false;

    items = _.sortBy(items, item => item && item.label && item.label.key);

    return items.reduce(
      (result, item) => {
        let key;
        let value;

        if (item.label) {
          ({key, value} = item.label);
        } else if (item.actors === 'ams') {
          key = 'all-workloads';
          value = intl('Workloads.All');
        } else if (item.ip_list) {
          if (item.ip_list.href) {
            key = 'ip-list';
          } else {
            newBadge = true;
            key = 'domain';
          }

          value = item.ip_list.name;
        } else if (item.workload) {
          key = 'workload';
          value = item.workload.name || item.workload.hostname;
        } else if (item.virtual_server) {
          key = 'virtual-server';
          value = item.virtual_server.name;
        } else if (item.virtual_service) {
          key = 'virtual-service';
          value = item.virtual_service.name;
        }

        const label = (
          <span>
            {newBadge ? (
              <span className="MapFormPanel-Selector-Badge">
                <Badge type="new" />
              </span>
            ) : null}
            <Label
              key={`${key}-${value}`}
              text={value}
              type={deleted ? 'deleted' : key}
              icon={key}
              exclusion={item.exclusion}
            />
          </span>
        );

        // Any item in the option name goes into the 'selected' set
        // Any item not in the name goes into the 'scopes' set
        if (options.includes(value) || options.includes(`wl: ${value}`) || options.includes(`vs: ${value}`)) {
          result.selected.push(label);
        } else {
          result.scopes.push(label);
        }

        return result;
      },
      {selected: [], scopes: []},
    );
  }

  render() {
    if (!this.state) {
      return null;
    }

    const {options} = this.state;
    const {type, selectedEndpoint, editable, merged, removed} = this.props;
    const placeholder = type === 'consumer' ? intl('Common.SelectConsumers') : intl('Common.SelectProviders');
    const {selected, scopes} = this.renderEndpointItems(selectedEndpoint);

    let mergeItems = merged ? <div> + {this.renderEndpointItems({merged}).scopes} </div> : null;

    if (removed) {
      mergeItems = <div> - {this.renderEndpointItems({removed}, true).scopes} </div>;
    }

    const singleValues = _.cloneDeep(options);

    delete singleValues.resolveAs;

    if (this.props.edit) {
      return (
        <div className="MapFormPanel-Selector" data-tid="map-info-panel-row-value">
          <ObjectSelector
            singleValues={singleValues}
            items={selectedEndpoint}
            addItem={this.endpointSelect}
            removeItem={this.endpointRemove}
            placeholder={_.isEmpty(selectedEndpoint) ? placeholder : ''}
            returnValue={item => item}
            allowOne={true}
            ref={node => (this.input = node)}
            dropdownValues={{}}
            initialValues={[]}
            customListItem={this.customListItem}
            customCaretIcon={
              <span onMouseOver={this.handleMouseOverCaret}>
                <Icon name="caret-up" size="xlarge" styleClass="ObjectSelector-down-arrow" />
              </span>
            }
            onClose={this.handleCancel}
            onMouseOver={this.handleMouseOver}
            facetMap={{}}
            getFacetValues={_.noop}
            autoFocus={true}
          />
          {scopes}
          {mergeItems}
        </div>
      );
    }

    const edit = editable && _.size(options) > 1;

    return (
      <div
        className={`MapFormPanelForm${edit ? ' MapFormPanel-Selected-Item--Editable' : ''}`}
        data-tid="map-info-panel-row-value"
        onClick={edit ? this.handleEdit : _.noop}
      >
        <span data-tid="map-info-panel-row-value">{selected}</span>
        {edit ? (
          <span className="Icon-Edit">
            <Icon name="caret-down" size="xlarge" tid={`edit-${type}`} />
          </span>
        ) : null}
        <div className="MapFormPanel-Wrap">{scopes}</div>
        <div className="MapFormPanel-Wrap">{mergeItems}</div>
      </div>
    );
  }
}
