/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
export default {
  path: {
    login: '/login',
    logout: '/logout',
    changePassword: '/users/password/change',
  },
};
