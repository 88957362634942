/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Link, State} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import Constants from '../../constants';
import {
  Icon,
  Button,
  ButtonDropdown,
  Checkbox,
  ConfirmationDialog,
  Grid,
  Select,
  SpinnerOverlay,
  Pagination,
} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {RouterMixin, StoreMixin, UserMixin} from '../../mixins';
import {ConfirmPolicyState, RemoveWorkloadsDialog, EditLabelsDialog} from '../../modals';
import {
  SessionStore,
  GeneralStore,
  LabelStore,
  VirtualServerStore,
  WorkloadStore,
  ContainerWorkloadStore,
  VirtualServiceStore,
  PairingProfileStore,
  HealthStore,
  TrafficFilterStore,
} from '../../stores';
import {GridDataUtils, GraphDataUtils, GroupDataUtils, RenderUtils, RestApiUtils, WorkloadUtils} from '../../utils';
import {enforcementStateMap, ransomwareExposureMap} from '../../utils/RenderUtils';
import GroupMemberSelect from './GroupMemberSelect';
import {getWorkloadStatusIntl} from '@illumio-shared/utils/intl/dynamic';

const MAX_RESULTS_PER_PAGE = 50;

function getStateFromStores() {
  const {groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

  const virtualServers = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServerStore.getAll());
  const pairingProfiles = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, PairingProfileStore.getAll());
  let workloads = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, WorkloadStore.getAll());
  let containerWorkloads = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, ContainerWorkloadStore.getAll());
  let virtualServices = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServiceStore.getAll().draft);

  // Find the workloads in a discovery group defined by the traffic
  if (group && group.discovered) {
    workloads = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const workload = WorkloadStore.getSpecified(href);

        if (workload) {
          result.push(workload);
        }
      },
      [],
    );

    containerWorkloads = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const containerWorkload = ContainerWorkloadStore.getSpecified(href);

        if (containerWorkload) {
          result.push(containerWorkload);
        }
      },
      [],
    );

    virtualServices = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const virtualService = VirtualServiceStore.getSpecified(href);

        if (virtualService) {
          result.push(virtualService);
        }
      },
      [],
    );
  }

  return {
    group,
    virtualServices,
    virtualServers,
    containerWorkloads,
    workloads,
    pairingProfiles,
    status: [WorkloadStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [
    State,
    RouterMixin,
    UserMixin,
    StoreMixin(
      [LabelStore, VirtualServiceStore, ContainerWorkloadStore, PairingProfileStore, WorkloadStore],
      getStateFromStores,
    ),
  ],

  getInitialState() {
    const {groupHref, groupLabelHrefs} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);
    const selectionObject = GeneralStore.getSelection('groupWorkloads');
    const sorting = GeneralStore.getSorting('groupWorkloads');
    const filter = this.getParams().filter || GeneralStore.getFilter('groupWorkloads');
    const type = GroupDataUtils.getType(this.getPathname());
    const vulnerabilitiesEnabled = SessionStore.areVulnerabilitiesEnabled() && type === 'appgroups';
    let selection = [];

    if (selectionObject && selectionObject.selection && selectionObject.id === this.getParams().id) {
      selection = selectionObject.selection;
    }

    return {
      type,
      groupHref,
      groupLabelHrefs,
      selection,
      sorting:
        sorting || vulnerabilitiesEnabled
          ? [{key: 'vulnerability_summary', direction: true}]
          : [{key: 'name', direction: false}],
      filter: filter || 'all',
      expandedRow: null,
      currentPage: 1,
      groupExists: true,
      vulnerabilitiesEnabled,
    };
  },

  async componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
    RestApiUtils.virtualServers.getCollection();

    this.mapLevel = await GraphDataUtils.getMapLevelByTotalWorkloads();

    const {groupHref, type, vulnerabilitiesEnabled} = this.state;
    const {group, caps} = await GroupDataUtils.getGroup(
      this.state.group,
      groupHref,
      type,
      vulnerabilitiesEnabled,
      true,
    );

    this.setState({groupExists: !_.isEmpty(group), caps});
  },

  componentWillReceiveProps() {
    const type = GroupDataUtils.getType(this.getPathname());

    if (this.getParams().id !== this.state.groupHref || type !== this.state.type) {
      const {groupHref, groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

      GroupDataUtils.getGroup(group, groupHref, type, this.state.vulnerabilitiesEnabled, true);

      this.setState({
        group,
        groupHref,
        groupLabelHrefs,
        selection: [],
      });
    }
  },

  getProtectionCoverageScore(value, row) {
    return row.risk_summary === null || typeof value !== 'number' ? '' : `${value}%`;
  },

  getRansomwareExposure(value, row) {
    return row.risk_summary === null ? '' : ransomwareExposureMap[value];
  },

  getRansomwareExposureSortValue(value, row) {
    return row.risk_summary === null ? -1 : ['fully_protected', 'low', 'medium', 'high', 'critical'].indexOf(value);
  },

  getSelectedWorkloads() {
    return _.compact(_.map(this.state.selection, href => _.find(this.state.workloads, w => w.href === href)));
  },

  getWorkloadPolicySync(value, online) {
    return WorkloadUtils.workloadHealthTag(
      value,
      {
        labelOnly: true,
        withIcon: true,
        isSuperclusterMember: SessionStore.isSuperclusterMember(),
      },
      HealthStore.getLocalData(),
      online,
    );
  },

  getWorkloadPolicySyncSortValue(value, online) {
    return WorkloadUtils.workloadHealthTag(
      value,
      {
        labelOnly: true,
        withIcon: true,
        isSuperclusterMember: SessionStore.isSuperclusterMember(),
      },
      HealthStore.getLocalData(),
      online,
    );
  },

  handleChangeComplete() {
    const {groupHref, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

    GroupDataUtils.getGroup(group, groupHref, this.state.type, this.state.vulnerabilitiesEnabled, true);

    // If due to a change a group no more exists in the location summary, navigate to landing page.
    if (!group) {
      this.transitionTo('landing');
    }

    if (group?.caps?.rulesets?.includes('read')) {
      const appGroupHref = group.type === 'appGroup' ? group.href : group.appGroupParent;

      const transmissionFilters = TrafficFilterStore.getTransmissionFilters();
      const trafficClasses = ['unicast', 'broadcast', 'multicast', 'core_service'];

      transmissionFilters.forEach((transmissionFilter, i) => {
        if (transmissionFilter) {
          if (appGroupHref) {
            GraphDataUtils.getTraffic(
              {roles: true, traffic_class: trafficClasses[i], app_group_keys: JSON.stringify([appGroupHref])},
              {type: 'rebuild'},
            );
          } else {
            GraphDataUtils.getTraffic(
              {roles: true, traffic_class: trafficClasses[i], cluster_keys: JSON.stringify([groupHref])},
              {type: 'rebuild'},
            );
          }
        }
      });
    }

    actionCreators.updateGeneralSelection('groupWorkloads', []);
    this.setState({selection: []});
  },

  handleEditLabels() {
    const workloads = this.getSelectedWorkloads();

    if (!workloads.length) {
      return;
    }

    const dontAskAgain = evt => {
      this.dontAskAgain = evt.target.checked;
    };

    const confirmMessage = (
      <div>
        {intl('Workloads.List.ConfirmAffectMultipleWorkloads')}
        <div className="Dialog-confirmation-message-checkbox">
          <Checkbox label={intl('Workloads.List.AskAgainCheck')} onChange={dontAskAgain} />
        </div>
      </div>
    );

    if (workloads.length === 1 || JSON.parse(localStorage.getItem('editMultiLabelDontAskAgain')) === true) {
      this.openEditLabels(workloads);
    } else {
      actionCreators.openDialog(
        <ConfirmationDialog
          className="Dialog-editmultiple-workloads"
          title={intl('Workloads.List.EditMultipleLabels')}
          message={confirmMessage}
          onConfirm={() => {
            if (this.dontAskAgain) {
              localStorage.editMultiLabelDontAskAgain = this.dontAskAgain;
            }

            _.defer(this.openEditLabels, workloads);
          }}
        />,
      );
    }
  },

  handleFilterChange(evt) {
    actionCreators.updateGeneralFilter('groupWorkloads', evt.value);
    actionCreators.updateGeneralSelection('groupWorkloads', []);
    this.setState({filter: evt.value, selection: [], currentPage: 1});
  },

  handlePageChange(page) {
    this.setState({
      currentPage: page,
    });
  },

  handlePair(selection) {
    const pairingProfile = this.state.pairingProfiles[0];

    if (selection.value === 'add') {
      this.transitionTo('workloadCreate');
    } else if (pairingProfile) {
      this.transitionTo('pairingProfiles.item', {id: pairingProfile.href.split('/').pop()});
    } else {
      this.transitionTo('pairingProfiles.create');
    }
  },

  handleRowClick(row) {
    const url = row.href.split('/');
    const id = url[url.length - 1];

    if (row.href.includes('workload')) {
      this.transitionTo('workloads.item', {id});
    } else if (row.href.includes('virtual_server')) {
      this.transitionTo('virtualServerTab', {id, tab: 'summary', pversion: 'draft'});
    }
  },

  handleSelectToggle(selection) {
    const newSelection = GridDataUtils.selectToggle(this.state.selection, selection);

    actionCreators.updateGeneralSelection('groupWorkloads', {
      id: this.getParams().id,
      selection: newSelection,
    });
    this.setState({selection: newSelection});
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('groupWorkloads', sorting);
    this.setState({sorting});
  },

  handleStateSelect(item) {
    const workloads = this.getSelectedWorkloads();

    if (!workloads.length) {
      return;
    }

    actionCreators.openDialog(
      <ConfirmPolicyState
        workloads={workloads}
        policyState={item.policyState}
        visibilityLevel={item.visibilityLevel}
        onUpdate={this.handleChangeComplete}
      />,
    );
  },

  handleUnpair() {
    const workloads = this.getSelectedWorkloads();

    if (!workloads.length) {
      return;
    }

    actionCreators.openDialog(<RemoveWorkloadsDialog onRemove={this.handleChangeComplete} workloads={workloads} />);
  },

  openEditLabels(workloads) {
    actionCreators.openDialog(<EditLabelsDialog workloads={workloads} onComplete={this.handleChangeComplete} />);
  },

  render() {
    const {type, caps} = this.state;
    const workloadManagerdisabled =
      SessionStore.isSuperclusterMember() || (!caps?.workloads?.includes('write') && !caps?.workloads?.includes('add'));
    const listPage = sessionStorage.getItem('app_group_list') === 'recents' ? {route: 'appMap'} : {route: 'appGroups'};

    if (!this.state.groupExists) {
      // if we can't find the group, it must be because it's a discovered group
      this.replaceWith(type === 'appgroups' ? listPage.route : 'map');
    } else if (!this.state.group) {
      return <SpinnerOverlay />;
    }

    const columns = [
      {
        key: 'connectivity',
        label: intl('Common.Connectivity'),
        sortable: true,
        format: (value, row) => (
          <div>
            <Icon
              name={WorkloadUtils.getWorkloadStatus(row)}
              styleClass={getWorkloadStatusIntl(WorkloadUtils.getWorkloadStatus(row))}
            />
            &nbsp;
            {getWorkloadStatusIntl(WorkloadUtils.getWorkloadStatus(row))}
          </div>
        ),
        sortValue: (value, row) => WorkloadUtils.getWorkloadStatus(row),
      },
      {
        key: 'enforcement_mode',
        label: intl('Common.Enforcement'),
        type: 'string',
        sortable: true,
        format: (value, row) => {
          if (row?.agent?.status) {
            return enforcementStateMap[value];
          }
        },
        sortValue: (value, row) => (row?.agent?.status ? value : ''),
      },
      {
        key: 'agent',
        label: intl('Workloads.PolicySync'),
        sortable: true,
        format: (value, row) => this.getWorkloadPolicySync(value, row.online),
        sortValue: (value, row) => this.getWorkloadPolicySyncSortValue(value, row.online),
      },
      ...(SessionStore.isRansomwareReadinessEnabled()
        ? [
            {
              key: 'risk_summary.ransomware.workload_exposure_severity',
              label: intl('RansomwareDashboard.RansomwareExposure'),
              sortable: true,
              format: (value, row) => this.getRansomwareExposure(value, row),
              sortValue: (value, row) => this.getRansomwareExposureSortValue(value, row),
            },
            {
              key: 'risk_summary.ransomware.ransomware_protection_percent',
              label: intl('RansomwareDashboard.ProtectionCoverageScore'),
              type: 'number',
              sortable: true,
              format: (value, row) => this.getProtectionCoverageScore(value, row),
            },
          ]
        : []),
      {
        key: 'name',
        label: intl('Common.Workload'),
        sortable: true,
        format(value, row) {
          if (row.href.includes('virtual_server')) {
            return (
              <Link
                to="virtualServerTab"
                className="Grid-link"
                params={{id: GridDataUtils.getIdFromHref(row.href), pversion: 'draft', tab: 'summary'}}
              >
                {value}
              </Link>
            );
          }

          return (
            <Link to="workloads.item" className="Grid-link" params={{id: GridDataUtils.getIdFromHref(row.href)}}>
              {WorkloadUtils.friendlyName(row)}
            </Link>
          );
        },
        sortValue(value, row) {
          return WorkloadUtils.friendlyName(row).toLowerCase();
        },
      },
      {
        key: 'role',
        label: intl('Common.Role'),
        formatHeader: GridDataUtils.formatLabelLabel,
        format: GridDataUtils.formatLabelValue,
        sortValue: (value, row) => {
          const role = row.labels.find(label => label.key === 'role');

          console.log(row);

          return role ? role.value && role.value.toLocaleLowerCase() : null;
        },
        sortable: true,
      },
      {
        key: 'services',
        label: intl('Common.Processes'),
        sortable: true,
        style: 'wrap',
        format: (value, row) => {
          if (row.href.includes('virtual_server')) {
            return '-';
          }

          const groupedServiceData = RenderUtils.getWorkloadServicesGrouped(row);

          if (this.state.expandedRow && this.state.expandedRow.href === row.href && groupedServiceData) {
            if (groupedServiceData) {
              const services = [];

              Object.keys(groupedServiceData).forEach(key => {
                if (groupedServiceData[key]) {
                  services.push(
                    <div>
                      {key} <span className="Workload-ServicePorts">{groupedServiceData[key].join(', ')}</span>
                    </div>,
                  );
                }
              });

              return services;
            }
          } else {
            if (groupedServiceData) {
              const onProcessClick = evt => {
                evt.stopPropagation();
                this.setState({expandedRow: row});
              };

              return (
                <div className="Grid-link" onClick={onProcessClick}>
                  {groupedServiceData ? Object.keys(groupedServiceData).length : 0}
                </div>
              );
            }

            return intl('Common.None');
          }
        },
        sortValue(value, row) {
          const groupedServicesData = RenderUtils.getWorkloadServicesGrouped(row);

          return groupedServicesData ? Object.keys(groupedServicesData).length : 0;
        },
      },
      {
        key: 'updated_at',
        label: intl('Workloads.LastAppliedPolicy'),
        format: (value, row) =>
          !row?.unmanaged &&
          row.agent?.status?.security_policy_refresh_at && [
            intl.date(row.agent?.status?.security_policy_refresh_at, 'L'),
            ' ',
            intl.date(row.agent?.status?.security_policy_refresh_at, 'HH_mm_ss'),
          ],
      },
    ];

    if (this.state.vulnerabilitiesEnabled) {
      columns.unshift({
        key: 'vulnerability_summary',
        style: 'vulnerability',
        label: intl('Vulnerability.VEScore'),
        format: (value, row) => GridDataUtils.formatVulnerability(value, row),
        sortFunction: (a, b) => GridDataUtils.sortVulnerability(a.vulnerability_summary, b.vulnerability_summary),
        sortable: true,
      });
    }

    const selectOptions = [
      {value: 'all', label: intl('Common.All')},
      {value: 'unmanaged', label: intl('Common.Unmanaged')},
      {value: 'idle', label: intl('Common.Idle')},
      {value: 'visibility', label: intl('Common.VisibilityOnly')},
      {value: 'selective', label: intl('Workloads.Selective')},
      {value: 'enforced', label: intl('Workloads.Full')},
    ];

    const stateOptions = [
      {
        label: intl('Common.VisibilityOnly'),
        policyState: 'visibility_only',
      },
      {
        label: intl('Workloads.Selective'),
        policyState: 'selective',
      },
      {
        label: intl('Workloads.Full'),
        policyState: 'full',
      },
    ];

    const addOptions = [
      {
        label: (
          <div>
            {intl('Workloads.List.PairWithPairingProfile')}
            <br />
            <small>{intl('Workloads.List.PairInstallVEN')}</small>
          </div>
        ),
        value: 'pair',
        tid: 'pair',
      },
      {
        label: (
          <div>
            {intl('Workloads.AddUnmanaged')}
            <br />
            <small>{intl('Workloads.List.AddWorkloadWithoutVEN')}</small>
          </div>
        ),
        value: 'add',
        tid: 'add',
      },
    ];

    const gridData = WorkloadUtils.filterWorkloadsByPolicyState(this.state.workloads, this.state.filter);
    const tabs = GroupDataUtils.getTabs(this.state);
    const appGroups = this.state.type === 'appgroups';
    const selectionManaged = this.getSelectedWorkloads().some(workload => workload.agent.status);

    return (
      <div className="GroupWorkloads ListPage" data-tid="page-appcontainer-workloads">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        {appGroups && tabs.length > 1 ? <GroupMemberSelect active="workloads" items={tabs} /> : null}
        <ToolBar>
          <ToolGroup>
            <ButtonDropdown
              text={intl('Common.Add')}
              icon="add"
              options={addOptions}
              onSelect={this.handlePair}
              disabled={workloadManagerdisabled}
              tid="workloadadd"
            />
            <Button
              text={intl('Labels.Edit')}
              icon="edit"
              onClick={this.handleEditLabels}
              disabled={this.state.selection.length === 0 || workloadManagerdisabled}
              type="secondary"
              tid="editlabels"
            />
            <Button
              text={intl('Common.Unpair')}
              onClick={this.handleUnpair}
              disabled={this.state.selection.length === 0 || workloadManagerdisabled}
              type="secondary"
              tid="unpair"
            />
            <ButtonDropdown
              text={intl('Workloads.SetEnforcement')}
              type="secondary"
              options={stateOptions}
              onSelect={this.handleStateSelect}
              disabled={this.state.selection.length === 0 || workloadManagerdisabled || !selectionManaged}
              tid="workloadstate"
            />
            <div className="ListPage-Filter">
              <Select options={selectOptions} selected={this.state.filter} onSelect={this.handleFilterChange} />
            </div>
            {this.state.selection.length > 0 &&
              intl(
                'Common.SelectedCount',
                {className: 'Count', count: this.state.selection.length},
                {
                  html: true,
                  htmlProps: {
                    'className': 'ListPage-Count-Selection',
                    'data-tid': 'elem-count-selection',
                  },
                },
              )}
          </ToolGroup>
          {gridData.length ? (
            <ToolGroup tid="pagination">
              <Pagination
                page={this.state.currentPage}
                totalRows={gridData.length}
                pageLength={MAX_RESULTS_PER_PAGE}
                onPageChange={this.handlePageChange}
              />
            </ToolGroup>
          ) : null}
        </ToolBar>
        <Grid
          columns={columns}
          data={gridData}
          sorting={this.state.sorting}
          selection={this.state.selection}
          sortable={true}
          selectable={!workloadManagerdisabled}
          idField="href"
          onSort={this.handleSort}
          onRowSelectToggle={this.handleSelectToggle}
          onRowClick={this.handleRowClick}
          resultsPerPage={MAX_RESULTS_PER_PAGE}
          currentPage={this.state.currentPage}
        />
      </div>
    );
  },
});
