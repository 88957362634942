/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import Constants from '../constants';
import {MapPageStore} from '.';

const UPDATE_EVENT = 'update';

let renderingSpinner = false;
let loadRuleCoverageIterate = 0;
const loadNetworkTrafficIterate = {};
const loadNetworkTrafficRebuild = {};
let loadLocationSummary = 0;
let loadAppGroupRuleCoverage = 0;
let loadAppGroupSummary = 0;
let updateAppGroupRuleCoverage = 0;

function adjustLoadNetworkTrafficIterate(route, increment) {
  if (loadNetworkTrafficIterate.hasOwnProperty(route)) {
    loadNetworkTrafficIterate[route] += increment;
  } else {
    loadNetworkTrafficIterate[route] = increment;
  }
}

function adjustLoadNetworkTrafficRebuild(route, increment) {
  if (loadNetworkTrafficRebuild.hasOwnProperty(route)) {
    loadNetworkTrafficRebuild[route] += increment;
  } else {
    loadNetworkTrafficRebuild[route] = increment;
  }
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.START_SPINNER:
        renderingSpinner = true;
        break;

      case Constants.STOP_SPINNER:
        renderingSpinner = false;
        break;

      case Constants.LOCATION_SUMMARY_GET_INIT:
        loadLocationSummary += 1;
        break;

      case Constants.LOCATION_SUMMARY_GET_COMPLETE:
        loadLocationSummary -= 1;
        break;

      case Constants.NETWORK_TRAFFIC_REBUILD_START:
        adjustLoadNetworkTrafficRebuild(JSON.stringify(action.data), 1);
        this.emitUpdate();
        break;

      case Constants.NETWORK_TRAFFIC_REBUILD_COMPLETE:
        adjustLoadNetworkTrafficRebuild(JSON.stringify(action.data), -1);
        this.emitUpdate();
        break;

      case Constants.NETWORK_TRAFFIC_ITERATE_INIT:
        adjustLoadNetworkTrafficIterate(JSON.stringify(action.data), 1);
        break;

      case Constants.NETWORK_TRAFFIC_ITERATE_COMPLETE:
        adjustLoadNetworkTrafficIterate(JSON.stringify(action.data), -1);
        break;

      case Constants.SEC_POLICY_RULE_COVERAGE_ITERATE_INIT:
      case Constants.SEC_POLICY_RULE_COVERAGE_CREATE_INIT:
        loadRuleCoverageIterate += 1;
        break;

      case Constants.SEC_POLICY_RULE_COVERAGE_ITERATE_COMPLETE:
      case Constants.SEC_POLICY_RULE_COVERAGE_CREATE_COMPLETE:
        loadRuleCoverageIterate -= 1;
        break;

      case Constants.APP_GROUPS_OBSERVED_RULE_COVERAGE_INIT:
        loadAppGroupRuleCoverage += 1;
        break;

      case Constants.APP_GROUPS_OBSERVED_RULE_COVERAGE_COMPLETE:
        loadAppGroupRuleCoverage -= 1;
        break;

      case Constants.APP_GROUP_OBSERVED_RULE_COVERAGE_INIT:
        updateAppGroupRuleCoverage += 1;
        break;

      case Constants.APP_GROUP_OBSERVED_RULE_COVERAGE_COMPLETE:
        updateAppGroupRuleCoverage -= 1;
        break;

      case Constants.APP_GROUP_SUMMARY_GET_INIT:
        loadAppGroupSummary += 1;
        break;

      case Constants.APP_GROUP_SUMMARY_GET_COMPLETE:
        loadAppGroupSummary -= 1;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  addUpdateListener(callback) {
    this.on(UPDATE_EVENT, callback);
  },

  removeUpdateListener(callback) {
    this.removeListener(UPDATE_EVENT, callback);
  },

  emitUpdate() {
    this.emit(UPDATE_EVENT);
  },

  getSpinner() {
    return (
      renderingSpinner ||
      loadRuleCoverageIterate ||
      loadNetworkTrafficRebuild[JSON.stringify(MapPageStore.getMapRoute())] ||
      loadNetworkTrafficIterate[JSON.stringify(MapPageStore.getMapRoute())] ||
      loadLocationSummary ||
      loadAppGroupSummary
    );
  },

  getSpinnerWithRules() {
    return (
      renderingSpinner ||
      loadRuleCoverageIterate ||
      loadLocationSummary ||
      loadAppGroupRuleCoverage ||
      loadAppGroupSummary
    );
  },

  getRuleCoverageSpinner() {
    return updateAppGroupRuleCoverage || loadAppGroupRuleCoverage;
  },

  getRuleSpinner() {
    return loadRuleCoverageIterate;
  },

  getTrafficSpinner() {
    return (
      loadNetworkTrafficRebuild[JSON.stringify(MapPageStore.getMapRoute())] ||
      loadNetworkTrafficIterate[JSON.stringify(MapPageStore.getMapRoute())]
    );
  },

  getAppGroupSummarySpinner() {
    return loadAppGroupSummary;
  },
});
