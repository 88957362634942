/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import actionCreators from '../../actions/actionCreators';
import Icon from '../../components/Icon';
import RadioGroup from '../../components/RadioGroup';
import Radio from '../../components/Radio';

export default React.createClass({
  propTypes: {
    policyVersion: PropTypes.string.isRequired,
    appMapVersion: PropTypes.string,
  },

  getInitialState() {
    return {
      hidden: true,
      version: this.props.policyVersion,
      appMapVersion: this.props.appMapVersion,
    };
  },

  componentDidMount() {
    document.body.classList.add(this.props.policyVersion);
  },

  componentWillReceiveProps(nextProps) {
    if (this.state.version !== nextProps.policyVersion || this.state.appMapVersion !== nextProps.appMapVersion) {
      this.setState({version: nextProps.policyVersion, appMapVersion: nextProps.appMapVersion});
    }

    document.body.classList.remove(this.props.policyVersion);
    document.body.classList.add(nextProps.policyVersion);
  },

  componentWillUnmount() {
    document.body.classList.remove(this.props.policyVersion);
  },

  handleSelectVersion(nextVersion) {
    actionCreators.setMapPolicyState(nextVersion);
    actionCreators.closeActionItem();
    this.setState({version: nextVersion});
  },

  handleOpenPanel() {
    actionCreators.setTrafficFilterHidden(true);
    this.setState({hidden: !this.state.hidden});
  },

  handleRadioToggle(evt) {
    if (!this.state.hidden) {
      findDOMNode(this).focus();
    }

    if (evt.target.value !== this.state.version) {
      this.handleSelectVersion(evt.target.value);
    }
  },

  handleHidePanel(evt) {
    // if the element being blurred out contains
    // the element that caused the blur (e.relatedTarget)
    // then don't hide it, and keep the focus on
    const relatedTarget = evt.relatedTarget || evt.nativeEvent.explicitOriginalTarget || document.activeElement;

    if (findDOMNode(this).contains(relatedTarget)) {
      findDOMNode(this).focus();

      return;
    }

    this.setState({hidden: true});
  },

  render() {
    const {policyVersion, disabled} = this.props;
    const {hidden, version} = this.state;

    const buttonClasses = disabled
      ? 'PolicyButton-disabled'
      : cx('PolicyButton', {
          'PolicyButton-draft': policyVersion === 'draft',
          'PolicyButton--active': !hidden,
        });

    const policyVersionClasses = cx('PolicyVersionPanel-body', {
      hidden,
    });

    return (
      <div className="PolicyVersionPanel" tabIndex="-1" onBlur={this.handleHidePanel}>
        <div className={buttonClasses} onClick={!disabled && this.handleOpenPanel} data-tid="policy-version-button">
          {this.state.version === 'draft' ? intl('Map.DraftView') : intl('Map.ReportedView')}&nbsp;
          <Icon name="dropdown" />
        </div>
        <div className={policyVersionClasses}>
          <RadioGroup value={version}>
            <Radio
              value="reported"
              onChange={this.handleRadioToggle}
              checked={version === 'reported'}
              label={intl('Map.ReportedView')}
              tid="reported"
            />
            <div className="PolicyVersionPanel-details">{intl('Map.PolicyVersionPanel.ReportedViewDetails')}</div>
            <Radio
              value="draft"
              onChange={this.handleRadioToggle}
              checked={version === 'draft'}
              label={intl('Map.DraftView')}
              tid="draft"
            />
            <div className="PolicyVersionPanel-details">{intl('Map.PolicyVersionPanel.DraftViewDetails')}</div>
          </RadioGroup>
        </div>
      </div>
    );
  },
});
