/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import {ComponentUtils} from '../../utils';
import {Icon} from '..';

const defaultTid = 'comp-resultlist';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.string,
    onSelect: PropTypes.func,
    focusedResult: PropTypes.number,
    formatResult: PropTypes.func,
  },

  getDefaultProps() {
    return {
      options: [],
      staticOptions: [],
      onSelect: _.noop,
    };
  },

  componentDidUpdate() {
    this.scrollToFocusedNode();
  },

  scrollToFocusedNode() {
    // Necessary when keyboard nav focuses a node outside of the results window.
    if (this.refs.results && this.refs.focused) {
      const focusedNode = this.refs.focused;
      const focusedRect = focusedNode.getBoundingClientRect();
      const resultsNode = this.refs.results;
      const resultsRect = resultsNode.getBoundingClientRect();
      let newScrollTop;

      if (focusedRect.bottom > resultsRect.bottom) {
        newScrollTop = resultsNode.scrollTop + (focusedRect.bottom - resultsRect.bottom);
        resultsNode.scrollTop = newScrollTop;
      } else if (focusedRect.top < resultsRect.top) {
        newScrollTop = resultsNode.scrollTop - (resultsRect.top - focusedRect.top);
        resultsNode.scrollTop = newScrollTop;
      }
    }
  },

  renderOption(option) {
    if (option.label && option.sublabel) {
      return [
        <div
          key={option.label}
          data-tid="comp-resultlist-labelprimary"
          className="ReForm-ResultList-Option-Label-Primary"
        >
          {option.label}
        </div>,
        <div
          key={option.sublabel}
          data-tid="comp-resultlist-labelsecondary"
          className="ReForm-ResultList-Option-Label-Secondary"
        >
          {option.sublabel}
        </div>,
      ];
    }

    return option.label || option.value;
  },

  renderOptions() {
    return this.props.options.map((option, index) => {
      const isFocused = index === this.props.focusedResult;
      const isSelected = option.value === this.props.selected;
      const classes = cx({
        'ReForm-ResultList-Option': true,
        'ReForm-ResultList-Option--focused': isFocused,
        'ReForm-ResultList-Option--selected': isSelected,
        'ReForm-ResultList-Option--disabled': option.disabled,
      });

      return (
        <li
          ref={isFocused ? 'focused' : undefined}
          className={classes}
          key={option.value}
          onClick={_.partial(this.props.onSelect, option)}
          data-tid="comp-resultlist-option"
        >
          <span className="ReForm-ResultList-Option-Check">{isSelected ? <Icon name="check" /> : null}</span>
          <span className="ReForm-ResultList-Option-Label" data-tid="comp-resultlist-label">
            {this.props.formatResult ? this.props.formatResult(option) : this.renderOption(option)}
          </span>
        </li>
      );
    });
  },

  render() {
    const classes = cx({
      'ReForm-ResultList': true,
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <ul ref="results" className={classes} data-tid={ComponentUtils.tidString(tids)}>
        {this.renderOptions()}
      </ul>
    );
  },
});
