/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createStore} from '../lib/store';
import Constants from '../constants';
import ErrorUtils from '../utils/ErrorUtils.jsx';
import DialogStore from './DialogStore';

export default createStore({
  dispatchHandler(action) {
    switch (action.failureType) {
      case Constants.TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_FAIL:
        if (action.response && action.response.status === 503) {
          DialogStore.openDialog(ErrorUtils.DataStoreUnavailable());

          return;
        }

        break;
      // APIs whose errors are handled by the caller.
      case Constants.APP_GROUPS_OBSERVED_RULE_COVERAGE_FAIL:
      case Constants.APP_GROUP_OBSERVED_RULE_COVERAGE_FAIL:
      case Constants.WORKLOADS_SET_FLOW_REPORTING_FREQUENCY_FAIL:
      case Constants.LABEL_GROUPS_GET_INSTANCE_FAIL:
      case Constants.USERS_LOGIN_FAIL:
        return true;
      // handle these failures manually if 404 response
      case Constants.AUDIT_LOG_EVENTS_GET_INSTANCE_FAIL:
        if (action.response && action.response.status === 404) {
          return true;
        }

        break;
      // handle these failures manually if 406 response
      case Constants.SERVICES_DELETE_FAIL:
      case Constants.IP_LISTS_DELETE_FAIL:
      case Constants.SEC_POLICY_RULE_VIEW_GET_FAIL:
      case Constants.BLOCKED_TRAFFICS_GET_COLLECTION_FAIL:
      case Constants.BLOCKED_TRAFFICS_GET_INSTANCE_FAIL:
        if (action.response && action.response.status === 406) {
          return true;
        }

        break;
      case Constants.LOCATION_SUMMARY_GET_FAIL:
      case Constants.APP_GROUP_SUMMARY_GET_FAIL:
      case Constants.NETWORK_TRAFFIC_GET_FAIL:
        // Handle these errors in GraphDataUtils
        return true;
      case Constants.LABELS_CREATE_FAIL:
      case Constants.LABEL_GROUPS_CREATE_FAIL:
      case Constants.LABEL_GROUP_UPDATE_FAIL:
      case Constants.WORKLOADS_CREATE_FAIL:
      case Constants.PAIRING_PROFILES_CREATE_FAIL:
      case Constants.SERVICES_CREATE_FAIL:
      case Constants.SERVICE_UPDATE_FAIL:
      case Constants.ORG_AUTH_SECURITY_PRINCIPALS_CREATE_FAIL:
      case Constants.ORG_PERMISSIONS_CREATE_FAIL:
      case Constants.IP_LISTS_CREATE_FAIL:
      case Constants.SECURITY_PRINCIPALS_CREATE_FAIL:
      case Constants.SERVICE_BINDINGS_CREATE_FAIL:
      case Constants.PAIRING_PROFILE_PAIRING_KEY_FAIL:
        if (action.response && action.response.status === 406) {
          const token = _.get(action, 'response.body[0].token', '');

          if (token.includes('object_limit_hard_limit_reached')) {
            DialogStore.openDialog(
              ErrorUtils.getObjectLimitError(action.failureType, action.response.headers.get('x-request-id')),
              true,
            );

            return true;
          }

          if (
            token.includes('rbac_cannot_operate_on_workload_with_broader_scope') ||
            token.includes('rbac_cannot_operate_on_resource_with_broader_scope')
          ) {
            let errorMessage = '';

            if (action.error && action.error.message && action.error.message.length) {
              errorMessage = action.error.message;
            } else if (
              action.response &&
              action.response.error &&
              action.response.error.message &&
              action.response.error.message.length
            ) {
              errorMessage = action.response.error.message;
            }

            DialogStore.openDialog(
              ErrorUtils.getErrorAlert(
                action.type,
                _.get(action, 'response.body[0].token', null),
                _.get(action, 'response.body[0].message', null),
                errorMessage,
                action.showRequestId ? action.response.headers.get('x-request-id') : null,
                _.get(action, 'response.status', null),
                action.error,
              ),
              true,
            );

            return true;
          }

          if (token.includes('rbac')) {
            return true;
          }

          break;
        }
      case Constants.RULE_SETS_CREATE_FAIL:
      case Constants.RULE_SET_UPDATE_FAIL:
      case Constants.SEC_RULES_CREATE_FAIL:
      case Constants.SEC_RULE_UPDATE_FAIL:
      case Constants.IP_TABLES_RULES_CREATE_FAIL:
      case Constants.IP_TABLES_RULE_UPDATE_FAIL:
        if (action.response && action.response.status === 406) {
          const token = _.get(action, 'response.body[0].token', '');

          if (token.includes('rbac')) {
            return true;
          }

          if (token.includes('object_limit_hard_limit_reached')) {
            DialogStore.openDialog(
              ErrorUtils.getObjectLimitError(action.failureType, action.response.headers.get('x-request-id')),
              true,
            );

            return true;
          }
        }
    }

    switch (action.type) {
      case Constants.API_SERVER_CONNECTION_FAIL:
      case Constants.API_NOT_ACCEPTABLE_FAIL:
      case Constants.API_TOO_MANY_REQUESTS_FAIL:
      case Constants.API_ERROR_FAIL:
      case Constants.API_FAIL:
      case Constants.API_BAD_GATEWAY_FAIL:
      case Constants.API_SERVICE_UNAVAILABLE:
      case Constants.API_TIMEOUT_FAIL:
        // Do not show the timeout dialog for pending/health apis for large scale issues
        if (
          action.error &&
          action.error.message === 'TIMEOUT' &&
          (action.failureType === 'SEC_POLICY_PENDING_GET_FAIL' || action.failureType === 'HEALTH_GET_FAIL')
        ) {
          return true;
        }

        let errorMessage = '';

        if (action.error && action.error.message && action.error.message.length) {
          errorMessage = action.error.message;
        } else if (
          action.response &&
          action.response.error &&
          action.response.error.message &&
          action.response.error.message.length
        ) {
          errorMessage = action.response.error.message;
        }

        DialogStore.openDialog(
          ErrorUtils.getErrorAlert(
            action.type,
            _.get(action, 'response.body[0].token', null),
            _.get(action, 'response.body[0].message', null),
            errorMessage,
            action.showRequestId ? action.response.headers.get('x-request-id') : null,
            _.get(action, 'response.status', null),
            action.error,
          ),
          true,
        );
        break;

      case Constants.API_NOT_FOUND_FAIL:
      default:
        return true;
    }

    this.emitChange();

    return true;
  },
});
