/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
export default {
  unmanagedIcon(selection) {
    selection
      .append('pattern')
      .attr('id', 'unmanagedIcon')
      .attr('patternUnits', 'objectBoundingBox')
      .attr('width', '20')
      .attr('height', '20')
      .append('svg:image')
      .attr('xlink:href', 'images/unmanagedIcon.png')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', 20);

    selection
      .append('pattern')
      .attr('id', 'unmanagedIconHover')
      .attr('patternUnits', 'objectBoundingBox')
      .attr('width', '20')
      .attr('height', '20')
      .append('svg:image')
      .attr('xlink:href', 'images/unmanagedIcon.png')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', 20);

    selection
      .append('pattern')
      .attr('id', 'idleIcon')
      .attr('patternUnits', 'objectBoundingBox')
      .attr('width', '20')
      .attr('height', '20')
      .append('svg:image')
      .attr('xlink:href', 'images/idleIcon.png')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', 20);
  },
};
