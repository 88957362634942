/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import {Navigation} from 'react-router';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-tabto';

export default React.createClass({
  propTypes: {
    to: PropTypes.string.isRequired,
    params: PropTypes.object,
    tid: PropTypes.string,
  },

  mixins: [Navigation],

  getDefaultProps() {
    return {
      params: {},
    };
  },

  handleClick() {
    this.replaceWith(this.props.to, this.props.params);
  },

  render() {
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <div className="TabTo" onClick={this.handleClick} data-tid={ComponentUtils.tidString(tids)}>
        {this.props.children}
      </div>
    );
  },
});
