/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {Navigation} from 'react-router';
import GraphDataUtils from '../../utils/GraphDataUtils';
import TrafficStore from '../../stores/TrafficStore';
import RestApiUtils from '../../utils/RestApiUtils';
import IpListStore from '../../stores/IpListStore';
import TrafficFilterStore from '../../stores/TrafficFilterStore';
import Constants from '../../constants';
import {StoreMixin, UserMixin} from '../../mixins';
import {Label, Icon, Spinner} from '..';
import actionCreators from '../../actions/actionCreators';
import RenderUtils from '../../utils/RenderUtils';

const trafficClasses = ['unicast', 'broadcast', 'multicast', 'core_service'];

function getStateFromStore() {
  // In the future we can abstract this up graph.jsx
  return {
    status: TrafficStore.getStatus(),
    transmissionFilters: TrafficFilterStore.getTransmissionFilters(),
  };
}

export default React.createClass({
  mixins: [Navigation, UserMixin, StoreMixin(TrafficStore, getStateFromStore)],

  componentWillMount() {
    if (!IpListStore.getAnyIpList()) {
      RestApiUtils.ipLists.getInstance('any');
    }
  },

  async getPrivateAddressData(showAddresses) {
    let {loadedNodes} = this;
    let nodes = [];
    const requests = [];

    loadedNodes ||= [];
    // get private addresses for connected workloads
    this.state.transmissionFilters.forEach((transmissionFilter, i) => {
      if (transmissionFilter) {
        if (this.props.data.name !== intl('Common.IPLists') && this.props.data.linkedWorkloads.length > 0) {
          const transmissionLinkedWorkloads = [];

          this.props.data.linkedWorkloads.forEach(wl => {
            transmissionLinkedWorkloads.push(`${wl}x${trafficClasses[i]}`);
          });

          nodes = _.difference(transmissionLinkedWorkloads, loadedNodes);

          if (nodes.length) {
            nodes.forEach(node => {
              const query = {workloads: JSON.stringify([node.split('x')[0]]), include_private: true};

              actionCreators.clearTrafficOnNext(JSON.stringify(query));
              requests.push(
                GraphDataUtils.getTraffic({...query, traffic_class: trafficClasses[i]}, {type: 'rebuildStale'}),
              );

              loadedNodes.push(node);
            });
          }
        }

        // get private addresses for connected roles
        if (this.props.data.name !== intl('Common.IPLists') && this.props.data.linkedRoles.length > 0) {
          const transmissionLinkedRoles = [];

          this.props.data.linkedRoles.forEach(rl => {
            transmissionLinkedRoles.push(`${rl}x${trafficClasses[i]}`);
          });

          nodes = _.difference(transmissionLinkedRoles, loadedNodes);

          if (nodes.length) {
            nodes.forEach(node => {
              const roleKeys = node.split('x');

              roleKeys.pop();

              const query = {roles: true, role_keys: JSON.stringify([roleKeys.join('x')]), include_private: true};

              actionCreators.clearTrafficOnNext(JSON.stringify(query));
              requests.push(
                GraphDataUtils.getTraffic({...query, traffic_class: trafficClasses[i]}, {type: 'rebuildStale'}),
              );
              loadedNodes.push(node);
            });
          }
        }
      }
    });

    await Promise.all(requests);
    this.loadedNodes = loadedNodes;
    this.handleSelectPbUb(showAddresses);
  },

  handleIpAddress(address) {
    if (!this.isUserReadOnly()) {
      this.transitionTo('workloadCreate', null, {address});
    }
  },

  handleSelectPbUb(showAddresses) {
    const nodes = this.props.data.linkedWorkloads.concat(this.props.data.linkedRoles);
    const transmissionNodes = [];

    this.state.transmissionFilters.forEach((transmissionFilter, i) => {
      if (transmissionFilter) {
        nodes.forEach(node => {
          transmissionNodes.push(`${node}x${trafficClasses[i]}`);
        });
      }
    });

    // For the internet, are all the nodes loaded with private addresses?
    if (this.props.data.name === intl('Common.IPLists') || !_.difference(transmissionNodes, this.loadedNodes).length) {
      _.defer(() => {
        // Get the latest data from the props
        const internet = this.props.data.internets.find(internet => internet.href === showAddresses.href);

        actionCreators.clickActionItem({
          type: 'internetAction',
          formData: internet,
        });
      });
    } else {
      // Get the full data
      this.getPrivateAddressData(showAddresses);
      this.setState({showAddresses});
    }
  },

  handleClickInternet(internet, internetAddresses) {
    if (internetAddresses.length || this.props.data.type === 'fqdn') {
      _.partial(this.handleSelectPbUb, internet, internetAddresses)();
    }

    this.setState({
      clickedRow: internet.href,
    });
  },

  renderLabel(label) {
    return <Label type={label.key} icon={label.icon} text={label.value} />;
  },

  renderInternets() {
    if (!this.props.data.internets) {
      return;
    }

    // if there's more than 1 internet, and if one of them is "N/A", then don't render it
    const internets = _.filter(this.props.data.internets, internet => internet.name);

    if (_.isEmpty(internets)) {
      return intl('Common.NA');
    }

    internets.sort((a, b) => {
      const aIsNumber = Number(a.name.split('.')[0]);
      const bIsNumber = Number(b.name.split('.')[0]);

      if ((aIsNumber && !bIsNumber) || (RenderUtils.isHrefFqdn(a.href) && !RenderUtils.isHrefFqdn(b.href))) {
        return 1;
      }

      if ((!aIsNumber && bIsNumber) || (!RenderUtils.isHrefFqdn(a.href) && RenderUtils.isHrefFqdn(b.href))) {
        return -1;
      }

      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });

    return _.map(internets, (internet, index) => {
      const addressClass = cx('InternetAddress', {
        Link: !this.isUserReadOnly(),
      });

      const internetAddresses = _.map(internet.addresses, address => (
        <div className={addressClass} onClick={() => this.handleIpAddress(address)}>
          {address}
        </div>
      ));

      const classNames = cx({
        'TrafficPanel': true,
        'MapSubInfoPanel-Row': true,
        'MapSubInfoPanel-Row--Wrap': true,
        'MapSubInfoPanel-Row--NoHover--NoPointer': !(internetAddresses.length || this.props.data.type === 'fqdn'),
        'MapSubInfoPanel-Row--Selectable':
          (internetAddresses.length || this.props.data.type === 'fqdn') && internets.length > 1,
      });

      let icon = 'internet';

      // If the href is a number it is an fqdn
      if (RenderUtils.isHrefFqdn(internet.href)) {
        icon = null;
      } else if (internet.href.includes('ip_list')) {
        icon = this.props.data.type === 'fqdn' ? 'domain' : 'ip-list';
      }

      const sourceLabels = {key: null, value: internet.name, icon};
      const title = icon ? this.renderLabel(sourceLabels) : internet.name;

      const sourceRow = <div className="MapSubInfoPanel-Row--Wrap">{title}</div>;

      return (
        <tr className={classNames} key={index}>
          <td
            className="MapSubInfoPanel-Row-Label"
            onClick={() => this.handleClickInternet(internet, internetAddresses)}
          >
            {sourceRow}
          </td>
          {internetAddresses.length || this.props.data.type === 'fqdn' ? (
            <td onClick={() => this.handleClickInternet(internet, internetAddresses)}>
              {internet.href === this.state.clickedRow && this.state.status === Constants.STATUS_BUSY ? (
                <Spinner position="center" size="twenty" />
              ) : (
                <Icon styleClass="Chevron-Padding" size="xxlarge" name="next" />
              )}
            </td>
          ) : null}
        </tr>
      );
    });
  },

  render() {
    const internets = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row--SetBorder">
        <th className="MapInfoPanel-Row-Label">{intl('PolicyGenerator.Addresses')}</th>
        <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow">
          <div className="MapInfoPanel-Row--Scroll MapInfoPanel-Row--SetBorder MapInfoPanel-Row--InternetMargin">
            <table className="MapSubInfoPanel MapSubInfo-Panel--Align">
              <tbody>{this.renderInternets()}</tbody>
            </table>
          </div>
        </td>
      </tr>
    );

    return (
      <table className="MapInfoPanel MapInfoPanel-InternetHeight">
        <tbody>{internets}</tbody>
      </table>
    );
  },
});
