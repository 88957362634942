/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import dispatcher from './dispatcher';
import Constants from '../constants';

export default {
  loadTrafficData(data) {
    dispatcher.dispatch({
      type: Constants.LOAD_TRAFFIC_DATA,
      data,
    });
  },

  loadFiles(data) {
    dispatcher.dispatch({
      type: Constants.LOAD_FILES,
      data,
    });
  },

  cleanData(data) {
    dispatcher.dispatch({
      type: Constants.CLEAN_DATA,
      data,
    });
  },

  updateInfo(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_INFO,
      data,
    });
  },

  clickTick(clickedTick, clickedTickPath) {
    dispatcher.dispatch({
      type: Constants.CLICK_TICK,
      data: {clickedTick, clickedTickPath},
    });
  },

  choosePCLabel(type, label) {
    dispatcher.dispatch({
      type: Constants.CHOOSE_PC_LABEL,
      data: {type, label},
    });
  },

  loadPatterns(data) {
    dispatcher.dispatch({
      type: Constants.LOAD_PATTERNS,
      data,
    });
  },

  setFilters(data) {
    dispatcher.dispatch({
      type: Constants.SET_TRAFFIC_EXPLORER_FILTERS,
      data,
    });
  },

  interruptDnsLookup(data) {
    dispatcher.dispatch({
      type: Constants.INTERRUPT_DNS_LOOKUP,
      data,
    });
  },

  addAppGroupFilter(data) {
    dispatcher.dispatch({
      type: Constants.ADD_TRAFFIC_EXPLORER_APPGROUP_FILTER,
      data,
    });
  },

  removeAppGroupFilter(data) {
    dispatcher.dispatch({
      type: Constants.REMOVE_TRAFFIC_EXPLORER_APPGROUP_FILTER,
      data,
    });
  },

  removeMultiAppGroupFilter(data) {
    dispatcher.dispatch({
      type: Constants.REMOVE_MULTI_TRAFFIC_EXPLORER_APPGROUP_FILTER,
      data,
    });
  },

  resetAppGroupFilter() {
    dispatcher.dispatch({
      type: Constants.RESET_TRAFFIC_EXPLORER_APPGROUP_FILTER,
    });
  },

  setDefaultExclusions(data) {
    dispatcher.dispatch({
      type: Constants.SET_DEFAULT_EXCLUSIONS,
      data,
    });
  },
};
