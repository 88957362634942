/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import Icon from '../components/Icon.jsx';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  propTypes: {
    message: PropTypes.any.isRequired,
    chainAction: PropTypes.string,
    onChain: PropTypes.func,
    confirm: PropTypes.string,
    onConfirm: PropTypes.func,
    tid: PropTypes.string,
    confirmTid: PropTypes.string,
  },

  getDefaultProps() {
    return {
      confirm: intl('Common.Done'),
      onConfirm: _.noop,
      confirmTid: 'done',
    };
  },

  getActions() {
    if (this.props.chainAction) {
      return [
        {
          text: this.props.chainAction,
          tid: this.props.tid || this.props.chainAction.toLowerCase(),
          type: 'secondary',
          content: 'text-only',
          onClick: () => {
            this.handleChain();
          },
        },
        {
          text: this.props.confirm,
          tid: this.props.confirmTid,
          autoFocus: true,
          onClick: () => {
            this.props.onConfirm();
            this.handleClose();
          },
        },
      ];
    }

    return [
      {
        text: this.props.confirm,
        tid: this.props.confirmTid,
        content: 'text-only',
        autoFocus: true,
        onClick: () => {
          this.props.onConfirm();
          this.handleClose();
        },
      },
    ];
  },

  handleChain() {
    this.handleClose();
    this.props.onChain();
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    const chain = null;
    const cancel = null;

    return (
      <Dialog
        ref="dialog"
        type="detail"
        className="Success"
        title={`${intl('Common.Success')}!`}
        actions={this.getActions()}
      >
        <div className="Success-message-box">
          <span className="Success-icon">
            <Icon name="success" />
          </span>
          <span className="Success-message">{this.props.message}</span>
        </div>
        {chain}
        {cancel}
      </Dialog>
    );
  },
});
