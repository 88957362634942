/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {minmaxFor} from '../utils/GeneralUtils';
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';

let versions = [];
let modifiedObjects = {};
let isLoaded = false;
let latestProvisionTime = null;

function calculateLatestVersion(data) {
  return minmaxFor(data, 'version').max;
}

function getLatestProvisionTime(data) {
  const latestVersion = _.find(versions, {version: calculateLatestVersion(data)});
  const latestTime = latestVersion ? latestVersion.created_at : 0;

  return Math.floor(Date.parse(latestTime) / 1000);
}

function clearVersions() {
  versions = [];
  modifiedObjects = {};
}

function setVersions(data) {
  versions = data;
  // Calculate the latest provision time once.
  // This data is requested frequent when parsing traffic data
  latestProvisionTime = getLatestProvisionTime(data);
}

function setModifiedObjects(pversion, data) {
  modifiedObjects[pversion] = data;
}

export default createApiStore(['SEC_POLICIES_', 'SEC_POLICY_'], {
  dispatchHandler(action) {
    const pversion = action.options && action.options.params && action.options.params.pversion;

    switch (action.type) {
      case Constants.SEC_POLICIES_GET_COLLECTION_SUCCESS:
        setVersions(action.data);
        isLoaded = true;
        break;

      case Constants.SEC_POLICIES_GET_INSTANCE_SUCCESS:
        StoreUtils.setObject(action.data, versions);
        break;

      case Constants.SEC_POLICY_MODIFIED_OBJECTS_SUCCESS:
        if (pversion) {
          setModifiedObjects(pversion, action.data);
        }

        break;

      case Constants.CLEAR_VERSIONS:
        clearVersions();
        isLoaded = false;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return versions;
  },

  get(href) {
    return StoreUtils.findObjectByHref(href, versions, true);
  },

  getLatestVersion() {
    return calculateLatestVersion(versions);
  },

  getModifiedObjectsByHref(href) {
    return modifiedObjects[href];
  },

  isLoaded() {
    return isLoaded;
  },

  getLatestProvisionTime() {
    return latestProvisionTime;
  },
});
