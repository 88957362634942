/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import Spinner from './Spinner.jsx';

export default function SpinnerOverlay() {
  return (
    <div className="Spinner-overlay" data-tid="comp-spinneroverlay">
      {/* Pass in null to avoid having Spinner component set Spinner-normal for alignment. Don't need margin-auto within flex container */}
      <Spinner position={null} />
    </div>
  );
}
