/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import {ButtonDropdown} from '..';

export default React.createClass({
  propTypes: {
    onEdit: PropTypes.func.isRequired,
    onEditSecondary: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
  },

  handleEdit(evt) {
    this.props.onEdit(this.props.row.href, false, evt);
  },

  handleEditSecondary(option, evt) {
    this.props.onEditSecondary(this.props.row.href, option, evt);
  },

  render() {
    const {row, proposed} = this.props;

    if (row.update_type === 'delete') {
      return null;
    }

    const options = [];

    if (proposed) {
      options.push({
        action: 'delete',
        label: intl('Common.Delete'),
      });
    } else {
      if (row.enabled) {
        options.push({
          action: 'disable',
          label: intl('Common.Disable'),
        });
      } else {
        options.push({
          action: 'enable',
          label: intl('Common.Enable'),
        });
      }

      options.push(
        {
          action: 'duplicate',
          label: intl('Common.Duplicate'),
        },
        {
          action: 'delete',
          label: intl('Common.Delete'),
        },
      );

      if (!row.unscoped_consumers && !row.actors) {
        // No reverse for Extra-Scope and Custom iptables Rules
        options.push({
          action: 'reverse',
          label: intl('Common.Reverse'),
        });
      }
    }

    if (row.description) {
      options.push(
        {
          action: 'editNote',
          label: intl('Rulesets.Rules.EditNote'),
        },
        {
          action: 'deleteNote',
          label: intl('Rulesets.Rules.DeleteNote'),
        },
      );
    } else {
      options.push({
        action: 'addNote',
        label: intl('Rulesets.Rules.AddNote'),
      });
    }

    return (
      <ButtonDropdown
        dual
        icon="edit"
        tid="edit"
        options={options}
        onPrimaryClick={this.handleEdit}
        onSelect={this.handleEditSecondary}
      />
    );
  },
});
