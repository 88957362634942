/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {PropTypes} from 'react';
import {Icon} from '.';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-collapsetitle';

RulesetRulesAndScopesCollapseTitle.propTypes = {
  actionButtons: PropTypes.element,
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
  pagination: PropTypes.element,
  tid: PropTypes.string,
  title: PropTypes.string,
  totalRules: PropTypes.number,
  type: PropTypes.string,
  empty: PropTypes.element,
  animationClass: PropTypes.string,
  reorderMode: PropTypes.string,
  inAnimation: PropTypes.bool,
};

export default function RulesetRulesAndScopesCollapseTitle(props) {
  const tids = ComponentUtils.tid(defaultTid, props.tid);
  let message;
  let right;

  if (props.type === 'scopes') {
    message = <div>{props.actionButtons}</div>;
    right = props.pagination;
  } else {
    message = <div>{props.actionButtons}</div>;
    right = (
      <div data-tid="comp-collapsetitle-total-count">{`${intl.num(props.totalRules)} ${intl('Common.Total')}`}</div>
    );
  }

  let children = <div className="collapseTitle-Content">{props.children}</div>;

  if (props.empty) {
    children = props.empty;
  }

  if (props.collapsed) {
    children = null;
  }

  return (
    <div className="RulesetScopesAndRules-Section" data-tid={ComponentUtils.tidString(tids)}>
      {(props.inAnimation || props.reorderMode === 'none') && (
        <div
          className={`RulesetScopesAndRules-Section-header ${props.animationClass}`}
          data-tid="comp-collapsetitle-header"
        >
          <div className="RulesetScopesAndRules-Section-header-CollapseIcon">
            <Icon name={`caret-${props.collapsed ? 'right' : 'down'}`} size="large" onClick={props.onClick} />
          </div>
          <div className="RulesetScopesAndRules-Section-header-Title" data-tid="comp-collapsetitle-title">
            {props.title}
          </div>
          <div className="RulesetScopesAndRules-Section-header-Message" data-tid="comp-collapsetitle-message">
            {message}
          </div>
          <div className="RulesetScopesAndRules-Section-header-Pagination" data-tid="comp-collapsetitle-pagination">
            {right}
          </div>
        </div>
      )}
      {children}
    </div>
  );
}
