/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import {Form} from '../FormComponents';
import {looseEqual} from '../../utils/GeneralUtils';

export default React.createClass({
  propTypes: {
    type: PropTypes.string,
    model: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      model: {},
      errorModel: {},
      initialModel: {},
    };
  },

  componentWillMount() {
    this.formFields = [
      {
        type: 'field',
        label: intl('PCE.FQDN'),
        name: 'name',
        required: true,
        hint: [
          <div>{intl('Map.Fqdn.Hint')}</div>,
          <div>{`${intl('Common.Examples')}: ${intl('Map.Fqdn.Examples')}`}</div>,
        ],
        controls: [
          {
            type: 'text',
            maxlength: 255,
            name: 'name',
            placeholder: intl('Map.Fqdn.Placeholder'),
          },
        ],
      },
      {
        type: 'field',
        label: intl('Common.Description'),
        name: 'description',
        controls: [
          {
            type: 'textarea',
            maxlength: 255,
            name: 'description',
            placeholder: intl('Map.Fqdn.DescriptionPlaceholder'),
          },
        ],
      },
    ];
  },

  componentDidMount() {
    this.setInitialModel(this.props.model);
  },

  componentWillReceiveProps(nextProps) {
    if (this.areModelsDifferent(this.state.initialModel, this.getFriendlyModel(nextProps.model))) {
      this.setInitialModel(nextProps.model);
    }
  },

  setInitialModel(initialModel) {
    this.setState({model: {...initialModel}, initialModel});
  },

  areModelsDifferent(original, compare) {
    return !looseEqual(original.name, compare.name) || !looseEqual(original.description, compare.description);
  },

  handleChange(modelUpdates) {
    this.setState(previousState => ({model: {...previousState.model, ...modelUpdates}}));
  },

  handleErrorChange(errorModelUpdates) {
    this.setState(previousState => ({errorModel: {...previousState.errorModel, ...errorModelUpdates}}));
  },

  handleSubmit() {
    const model = {
      name: this.state.model.name,
      fqdns: [{fqdn: this.state.model.name}],
    };

    if (this.state.model.description || this.state.model.description === '') {
      model.description = this.state.model.description.trim();
    }

    this.props.onSubmit(model);
  },

  hasChanged() {
    return this.areModelsDifferent(this.state.initialModel, this.state.model);
  },

  render() {
    return (
      <Form
        type={this.props.type}
        fields={this.formFields}
        model={this.state.model}
        errorModel={this.state.errorModel}
        onChange={this.handleChange}
        onErrorChange={this.handleErrorChange}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        onHelp={this.handleHelp}
      />
    );
  },
});
