/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import Dialog from '../components/Dialog.jsx';
import Checkbox from '../components/Checkbox.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  propTypes: {
    onConfirm: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      doNotShow: false,
    };
  },

  onClose() {
    actionCreators.closeDialog();
  },

  handleDoNotShowChange() {
    this.setState({doNotShow: !this.state.doNotShow});
  },

  render() {
    const actions = [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        key: 'cancel',
        type: 'secondary',
        onClick: () => {
          actionCreators.closeDialog();
        },
      },
      {
        text: intl('Common.Continue'),
        tid: 'continue',
        key: 'continue',
        type: 'primary',
        onClick: () => {
          actionCreators.closeDialog();
          this.props.onConfirm(this.state.doNotShow);
        },
      },
    ];

    return (
      <Dialog title={intl('Map.EditPolicyState')} actions={actions} type="detail">
        <div className="Dialog-confirmation-message-padding">
          {intl('Map.EditPolicyStateWorkloadsOnly')}
          <div className="Dialog-confirmation-message-padding">
            <Checkbox
              label={intl('Map.DoNotShowAgain')}
              checked={this.state.doNotShow}
              onChange={this.handleDoNotShowChange}
            />
          </div>
        </div>
      </Dialog>
    );
  },
});
