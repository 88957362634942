/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Navigation} from 'react-router';
import actionCreators from '../actions/actionCreators';
import UserMixin from '../mixins/UserMixin';
import SessionStore from '../stores/SessionStore';
import Session from '../lib/session';
import Icon from './Icon.jsx';
import {Menu, MenuItem} from './Menu';
import Tooltip from './Tooltip';
import {StoreMixin} from 'scripts/mixins';

const tid = 'comp-navbar-';

const getStateFromStores = () => ({
  orgName: SessionStore.getOrgName(),
});

export default React.createClass({
  mixins: [UserMixin, Navigation, StoreMixin([SessionStore], getStateFromStores)],

  handleLogout() {
    actionCreators.logout();
  },

  // Pass content as function to prevent creating react elements at the beginning, create only when dropdown is open
  renderContent() {
    const mspOrgUrl = Session.mspOrgUrl();

    return [
      <MenuItem link="myprofile" text={intl('Users.MyProfile')} data-tid={`${tid}myprofile`} />,

      Session.isMSPOwner() && mspOrgUrl && (
        <MenuItem link={{href: mspOrgUrl}} text={intl('MSP.MyManagedTenants')} data-tid={`${tid}mymanagedtenants`} />
      ),

      <MenuItem link="myroles.list" text={intl('Users.MyRoles')} data-tid={`${tid}myroles`} />,
      !this.isUserExternal() && (
        <MenuItem link="apikeys.list" text={intl('Users.APIKeys.MyAPIKeys')} data-tid={`${tid}apikeys`} />
      ),
      <MenuItem onSelect={this.handleLogout} text={intl('Users.Logout')} data-tid={`${tid}logout`} />,
    ];
  },

  render() {
    return (
      <Menu
        label={
          <Tooltip
            content={
              <div className="Navbar-user-organization">
                <div className="Navbar-user-organization-title">{intl('Common.Organization')}</div>
                <div>{this.state.orgName}</div>
              </div>
            }
            location="bottom"
            width="fit-content"
            zIndex={101}
            delayed
          >
            {Session.getUserName()}
          </Tooltip>
        }
        tid="Navbar-user"
        icon={<Icon name="down" size="xlarge" position="after" />}
      >
        {this.renderContent}
      </Menu>
    );
  },
});
