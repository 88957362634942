/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createStore} from '../lib/store';
import {webStorageUtils} from '@illumio-shared/utils';
import Constants from '../constants';

const filters = {};
const sorts = {};
const history = [];
const facetFilters = {};
const facetCollectionStatus = {};
const selections = new Map();

const storedSelections = webStorageUtils.getSessionItem('GeneralSelection', {remove: true});

if (Array.isArray(storedSelections)) {
  for (const selection of storedSelections) {
    if (selection.key && !selections.has(selection.key) && selection.value) {
      selections.set(selection.key, selection.value);
    }
  }
}

const previousRoute = webStorageUtils.getSessionItem('PreviousRoute', {remove: true});

if (_.isObject(previousRoute) && previousRoute.route) {
  history.push(previousRoute);
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.UPDATE_GENERAL_SORTING:
        sorts[action.data.key] = action.data.value;
        break;

      // Scope Selector Filters which do not use API (like Illumination)
      case Constants.UPDATE_GENERAL_FILTER:
        filters[action.data.key] = action.data.value;
        break;

      // Scope Selector Filters which do use API (like Workloads)
      case Constants.UPDATE_GENERAL_API_FILTER:
        filters[action.data.key] = action.data.value;
        break;

      // Non Scope Selector Facet Filters which use API (like Workload Filters [not the scope selector])
      case Constants.UPDATE_GENERAL_FACET_FILTER:
        facetFilters[action.data.key] = action.data.value;
        break;

      case Constants.UPDATE_GENERAL_SELECTION:
        selections.set(action.data.key, action.data.value);
        break;

      case Constants.GENERAL_FACET_UPDATE_STATUS:
        facetCollectionStatus[action.data.key] = action.data.value;
        break;

      case Constants.CLEAR_GENERAL_SELECTION:
        if (action.data) {
          selections.set(action.data, []);
        } else {
          selections.clear();
        }

        break;

      case Constants.UPDATE_GENERAL_HISTORY:
        history.push(action.data);

        if (history.length > 5) {
          history.shift();
        }

        break;

      case Constants.REMOVE_LAST_GENERAL_HISTORY:
        history.pop();
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getSorting(key) {
    return sorts[key];
  },

  getFilter(key) {
    return filters[key];
  },

  getListPageForce(key) {
    return facetCollectionStatus[key];
  },

  getSelection(key) {
    return selections.get(key);
  },

  getHistory() {
    return _.last(history);
  },

  getFacetFilter(key) {
    return facetFilters[key];
  },
});
