/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import {DropTarget, DragSource} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';

const Types = {
  GRIDROW: 'gridrow',
};

const rowSource = {
  beginDrag(props) {
    return {
      'id': props.id,
      'index': props.index,
      'key': props.key,
      'className': props.className,
      'columns': props.columns,
      'data-tid': props['data-tid'],
    };
  },
};

const rowTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const sourceCollect = function (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag(),
  };
};

const targetCollect = function (connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
};

const GridRow = React.createClass({
  propTypes: {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    moveRow: PropTypes.func,
  },

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  },

  render() {
    const opacity = this.props.isDragging ? 0 : 1;
    const dragClass = this.props.canDrag ? 'GridRowNoDrag' : '';
    const connectDragSource = this.props.connectDragSource;
    const connectDropTarget = this.props.connectDropTarget;

    return connectDragSource(
      connectDropTarget(
        <tr
          style={{opacity}}
          className={`${this.props.className} ${dragClass}`}
          onClick={this.props.onClick}
          data-tid={this.props['data-tid']}
        >
          {this.props.columns}
        </tr>,
      ),
    );
  },
});

const source = DragSource(Types.GRIDROW, rowSource, sourceCollect)(GridRow);

export default DropTarget(Types.GRIDROW, rowTarget, targetCollect)(source);
