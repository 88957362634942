/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import {ComponentUtils} from '../../utils';

const defaultTid = 'comp-radio';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    autoFocus: PropTypes.bool,
    tabIndex: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    sublabel: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
  },

  getDefaultProps() {
    return {
      onFocus: _.noop,
      onBlur: _.noop,
      onChange: _.noop,
      onKeyDown: _.noop,
      onKeyUp: _.noop,
    };
  },

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focusInput();
    }
  },

  handleChange(evt) {
    this.focusInput();
    this.props.onChange(evt.target.value, evt);
  },

  handleKeyDown(evt) {
    this.props.onKeyDown(evt);

    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();
      this.handleChange(evt);
    }
  },

  focusInput() {
    if (this.refs.input) {
      this.refs.input.focus();
    }
  },

  render() {
    const classes = cx({
      'ReForm-Radio': true,
      'ReForm-Radio--checked': this.props.checked,
      'ReForm-Radio--error': this.props.error,
      'ReForm-Radio--disabled': this.props.disabled,
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <label className={classes} data-tid={ComponentUtils.tidString(tids)}>
        <input
          className="ReForm-Radio-Input"
          data-tid="elem-input"
          tabIndex={this.props.tabIndex}
          type="radio"
          ref="input"
          autoFocus={this.props.autoFocus}
          name={this.props.name}
          value={this.props.value}
          checked={this.props.checked}
          disabled={this.props.disabled}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.props.onKeyUp}
        />
        <span className="ReForm-Radio-Input-Cover" />
        {this.props.sublabel ? (
          <span className="ReForm-Radio-Label ReForm-Radio-Label--complex" data-tid="comp-radio-label">
            <div className="ReForm-Radio-Label-Primary" data-tid="elem-text">
              {this.props.label}
            </div>
            <div className="ReForm-Radio-Label-Secondary" data-tid="elem-text">
              {this.props.sublabel}
            </div>
          </span>
        ) : (
          <span className="ReForm-Radio-Label" data-tid="comp-radio-label">
            <span data-tid="elem-text">{this.props.label}</span>
          </span>
        )}
      </label>
    );
  },
});
