/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import {ResultList, Icon} from '.';
import {findDOMNode} from 'react-dom';

export default React.createClass({
  propTypes: {
    trigger: PropTypes.any,
    caret: PropTypes.bool,
    onSelect: PropTypes.func,
    options: PropTypes.array,
    tid: PropTypes.string,
  },

  getDefaultProps() {
    return {
      onChange: _.noop,
      options: [],
      caret: false,
      tid: '',
    };
  },

  getInitialState() {
    return {
      options: this.props.options,
    };
  },

  componentDidMount() {
    document.addEventListener('click', this.handleCompClick);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCompClick);
  },

  handleCompClick(evt) {
    if (!findDOMNode(this.selector).contains(evt.target) && this.state.showOptions) {
      this.setState({showOptions: false});
    }
  },

  handleClick() {
    if (this.state.showOptions) {
      this.setState({showOptions: false});
    } else {
      this.setState({showOptions: true});
    }
  },

  handleOptionsSelect(idx, evt) {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    const option = this.state.options[idx];

    if (option) {
      this.props.onSelect(option.key);
    }

    this.setState({showOptions: false});
  },

  render() {
    const comboClasses = cx({
      'Selector': true,
      'Selector--open': this.state.showOptions,
    });

    let options = null;

    if (this.state.options.length > 0) {
      options = (
        <ResultList
          data={this.state.options}
          onSelect={this.handleOptionsSelect}
          showResults={this.state.showOptions}
        />
      );
    }

    const caret = this.state.showOptions ? (
      <Icon name="sort-up" tid="up" styleClass="Selector" />
    ) : (
      <Icon name="sort-down" tid="down" styleClass="Selector" />
    );

    return (
      <div ref={node => (this.selector = node)} data-tid="selector" className={comboClasses}>
        <div className="Selector-trigger" onClick={this.handleClick}>
          {this.props.trigger}
          {this.props.caret ? caret : null}
        </div>
        <div className="TextCombobox-results">{options}</div>
      </div>
    );
  },
});
