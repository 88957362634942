/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';

const defaultTextMap = () => ({
  created: intl('Common.Created'),
  updated: intl('Common.Updated'),
  edited: intl('SegmentationTemplates.Edited'),
  deleted: intl('Common.Deleted'),
  disabled: intl('Common.Disabled'),
  ok: intl('Common.OK'),
  warning: intl('Common.Warning'),
  error: intl('Common.Error'),
  preview: intl('Common.Preview'),
  new: intl('Common.New'),
  toBeDeprecated: intl('Common.ToBeDeprecated'),
});

Badge.propTypes = {
  type: PropTypes.oneOf([
    'created',
    'updated',
    'deleted',
    'edited',
    'ok',
    'warning',
    'error',
    'disabled',
    'preview',
    'new',
    'info',
    'low',
    'medium',
    'high',
    'critical',
    'toBeDeprecated',
  ]).isRequired,
  children: PropTypes.node,
};

export default function Badge(props) {
  return (
    <div className={`Badge Badge--${props.type}`} data-tid={`comp-badge ${props.type}`}>
      {props.children || defaultTextMap()[props.type]}
    </div>
  );
}
