/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {
  SegmentationTemplateStore,
  LabelStore,
  LabelGroupStore,
  IpListStore,
  RulesetStore,
  ServiceStore,
  ProvisionStore,
} from '../../stores';
import {SegmentationTemplateUtils, RestApiUtils} from '../../utils';
import Dialog from '../../components/Dialog.jsx';
import actionCreators from '../../actions/actionCreators';

export default {
  getVersion(template) {
    const status =
      template.status === 'updateAvailable' ? (
        <span className="Template-new">{intl('SegmentationTemplates.New')}</span>
      ) : null;

    return (
      <div className="Template-version">
        <div className="Template-key">{intl('SegmentationTemplates.Version')}</div>
        <div className="Template-values">
          {template.version}
          {status}
        </div>
      </div>
    );
  },

  getLogo(icon, className) {
    if (SegmentationTemplateUtils.getPngIcons().includes(icon)) {
      return (
        <div className={className} data-tid="templates-logo" onClick={this.handleViewDetails}>
          <img
            data-tid="templates-png-logo"
            className="Template-logo-png"
            alt={`${icon} logo`}
            src={`images/segmentationtemplates/${icon}.png`}
          />
        </div>
      );
    }

    const initials = icon.split('#')[0];
    const backgroundColor = `#${icon.split('#')[1]}`;

    return (
      <div className={className} data-tid="templates-logo" onClick={this.handleViewDetails}>
        <div className="Template-logo-circle" style={{backgroundColor}}>
          {initials}
        </div>
      </div>
    );
  },

  async handleInstall() {
    let templateToInstall = {};

    if (this.state && this.state.template) {
      templateToInstall = this.state.template;
    } else {
      templateToInstall = this.props.template;
    }

    actionCreators.setSegmentationTemplateStatus({key: templateToInstall.key, status: 'installing'});

    // Errors = An object with that name already exists and was created by the user.  Installation fails - user must change object name
    const errors = [];

    // Provisioned and deleted objects are Read Only. User must either Provision or Delete these objects before an Update can occur.
    const provisionedAndDeletedObjects = [];

    // Labels cannot be provisioned, so the only possible install errors are naming conflicts
    if (templateToInstall.labels.length) {
      for (const label of templateToInstall.labels) {
        await RestApiUtils.labels.getCollection({key: label.key, value: label.value}, true);

        const preExistingLabel = LabelStore.getAll().find(
          labelFromStore => labelFromStore.key === label.key && labelFromStore.value === label.value,
        );

        if (
          preExistingLabel &&
          (!preExistingLabel.external_data_set ||
            preExistingLabel.external_data_set !== 'illumio_segmentation_templates')
        ) {
          errors.push({type: intl('Common.Label'), name: preExistingLabel.value, href: preExistingLabel.href});
        }
      }
    }

    //Provisionable Objects are checked for naming conflicts first. If no naming conflict, the object is checked for the deleted_at property,
    // which only exists in read-only objects that have been deleted but not provisioned
    if (templateToInstall.services.length) {
      const installableServices = _.reject(templateToInstall.services, {name: 'All Services'});
      const segmentationTemplateServices = SegmentationTemplateStore.getSegmentationTemplateServices();

      for (const service of installableServices) {
        await RestApiUtils.services.getCollection({name: service.name}, 'draft', true);

        let preExistingService = segmentationTemplateServices[service.key];

        if (!preExistingService && ServiceStore.getAll().length) {
          preExistingService = ServiceStore.getAll().find(serviceFromStore => serviceFromStore.name === service.name);
        }

        if (preExistingService) {
          if (
            !preExistingService.external_data_set ||
            preExistingService.external_data_set !== 'illumio_segmentation_templates'
          ) {
            errors.push({type: intl('Common.Service'), name: preExistingService.name, href: preExistingService.href});
          } else if (
            preExistingService.deleted_at &&
            preExistingService.external_data_set === 'illumio_segmentation_templates'
          ) {
            provisionedAndDeletedObjects.push({
              type: intl('Common.Service'),
              name: preExistingService.name,
              href: preExistingService.href,
            });
          }
        }
      }
    }

    if (templateToInstall.ip_lists.length) {
      const installableIpLists = _.reject(templateToInstall.ip_lists, {name: intl('IPLists.Any')});
      const segmentationTemplateIpLists = SegmentationTemplateStore.getSegmentationTemplateIpLists();

      for (const ipList of installableIpLists) {
        await RestApiUtils.ipLists.getCollection({name: ipList.name}, 'draft', true);

        let preExistingIpList = segmentationTemplateIpLists[ipList.name];

        if (!preExistingIpList && IpListStore.getAll().length) {
          preExistingIpList = IpListStore.getAll().find(ipListFromStore => ipListFromStore.name === ipList.name);
        }

        if (preExistingIpList) {
          if (
            !preExistingIpList.external_data_set ||
            preExistingIpList.external_data_set !== 'illumio_segmentation_templates'
          ) {
            errors.push({type: intl('Common.IPList'), name: preExistingIpList.name, href: preExistingIpList.href});
          } else if (
            preExistingIpList.deleted_at &&
            preExistingIpList.external_data_set === 'illumio_segmentation_templates'
          ) {
            provisionedAndDeletedObjects.push({
              type: intl('Common.IPList'),
              name: preExistingIpList.name,
              href: preExistingIpList.href,
            });
          }
        }
      }
    }

    if (templateToInstall.rule_sets.length) {
      const segmentationTemplateRulesets = SegmentationTemplateStore.getSegmentationTemplateRulesets();

      for (const ruleset of templateToInstall.rule_sets) {
        await RestApiUtils.ruleSets.getCollection(
          {representation: 'rule_set_scopes', name: ruleset.name},
          'draft',
          true,
        );

        let preExistingRuleset = segmentationTemplateRulesets[ruleset.key];

        if (!preExistingRuleset && RulesetStore.getAll('draft', 'rule_set_scopes').length) {
          preExistingRuleset = RulesetStore.getAll('draft', 'rule_set_scopes').find(
            rulesetFromStore => rulesetFromStore.name === ruleset.name,
          );
        }

        if (preExistingRuleset) {
          if (
            !preExistingRuleset.external_data_set ||
            preExistingRuleset.external_data_set !== 'illumio_segmentation_templates'
          ) {
            errors.push({type: intl('Explorer.Policy'), name: preExistingRuleset.name, href: preExistingRuleset.href});
          } else if (
            preExistingRuleset.external_data_set === 'illumio_segmentation_templates' &&
            preExistingRuleset.deleted_at
          ) {
            provisionedAndDeletedObjects.push({
              type: intl('Explorer.Policy'),
              name: preExistingRuleset.name,
              href: preExistingRuleset.href,
            });
          }
        }
      }
    }

    if (templateToInstall.label_groups.length) {
      const segmentationTemplateLabelGroups = SegmentationTemplateStore.getSegmentationTemplateLabelGroups();

      for (const labelGroup of templateToInstall.label_groups) {
        await RestApiUtils.labelGroups.getCollection({name: labelGroup.name}, 'draft', true);

        let preExistingLabelGroup = segmentationTemplateLabelGroups[labelGroup.name];

        if (!preExistingLabelGroup && LabelGroupStore.getAll().length) {
          preExistingLabelGroup = LabelGroupStore.getAll().find(
            labelGroupFromStore => labelGroupFromStore.name === labelGroup.name,
          );
        }

        if (preExistingLabelGroup) {
          if (
            !preExistingLabelGroup.external_data_set ||
            preExistingLabelGroup.external_data_set !== 'illumio_segmentation_templates'
          ) {
            errors.push({
              type: intl('Labels.Group'),
              name: preExistingLabelGroup.name,
              href: preExistingLabelGroup.href,
            });
          } else if (
            preExistingLabelGroup.external_data_set === 'illumio_segmentation_templates' &&
            preExistingLabelGroup.deleted_at
          ) {
            provisionedAndDeletedObjects.push({
              type: intl('Labels.Group'),
              name: preExistingLabelGroup.name,
              href: preExistingLabelGroup.href,
            });
          }
        }
      }
    }

    if (errors.length) {
      this.handleErrors(errors, templateToInstall.key);
    } else if (provisionedAndDeletedObjects.length) {
      this.handleProvisionedAndDeletedObjects(provisionedAndDeletedObjects, templateToInstall);
    } else {
      this.handlePrepareObjectsForInstall(templateToInstall);
    }
  },

  handlePrepareObjectsForInstall(templateToInstall) {
    actionCreators.setSegmentationTemplateStatus({key: templateToInstall.key, status: 'installing'});

    // Labels can only be created or updated
    let labelsToInstall = {create: [], update: []};

    if (templateToInstall.labels.length) {
      labelsToInstall = this.prepareLabelsForInstall(templateToInstall);
    }

    //Other objects: If an object already exists, and the user has edited it, we give the user the option of either preserving or overwriting
    // their changes. Thus, we must keep track of all of the "original" --meaning OUR version and "edited" -- meaning the user's edits.
    // After all edited objects have been found, the user is given the option of preserving or overwriting ALL objects
    let servicesToInstall = {create: [], update: [], edited: [], original: []};
    let ipListsToInstall = {create: [], update: [], edited: [], original: []};
    const installableLabelGroups = {install: [], edited: [], original: []};
    const installableRulesets = {install: [], edited: [], original: []};

    // Warnings are objects that have been edited by the user.  A warning pop-up appears and they must choose a path to continue
    const warnings = [];

    if (templateToInstall.services.length) {
      const installableServices = _.reject(templateToInstall.services, {name: 'All Services'});

      servicesToInstall = this.prepareServicesForInstall(installableServices, templateToInstall);
    }

    if (servicesToInstall.edited.length) {
      servicesToInstall.edited.forEach(editedService => {
        warnings.push({type: intl('Common.Service'), name: editedService.name, entity: editedService});
      });
    }

    if (templateToInstall.ip_lists.length) {
      const installableIpLists = _.reject(templateToInstall.ip_lists, {name: intl('IPLists.Any')});

      ipListsToInstall = this.prepareIpListsForInstall(installableIpLists, templateToInstall);
    }

    if (ipListsToInstall.edited.length) {
      ipListsToInstall.edited.forEach(editedIpList => {
        warnings.push({type: intl('Common.IPList'), name: editedIpList.name, entity: editedIpList});
      });
    }

    // Label groups and Rulesets are dependent on the Labels, IP Lists, and services being installed already (they need the hrefs of those objects)
    // So they cannot be fully parsed until the other objects are created. This only determines the edited and original of these entities
    if (templateToInstall.label_groups) {
      const segmentationTemplateLabelGroups = SegmentationTemplateStore.getSegmentationTemplateLabelGroups();

      templateToInstall.label_groups.forEach(labelGroup => {
        if (segmentationTemplateLabelGroups[labelGroup.name]) {
          if (segmentationTemplateLabelGroups[labelGroup.name].isEdited) {
            warnings.push({type: intl('Labels.Group'), name: labelGroup.name, entity: labelGroup});

            const dataReference = `${labelGroup.key} -- ${templateToInstall.name} - Version ${templateToInstall.version}`;

            installableLabelGroups.edited.push({
              id: segmentationTemplateLabelGroups[labelGroup.name].href.split('/').pop(),
              data: {
                external_data_reference: SegmentationTemplateUtils.configureExternalDataReference(
                  segmentationTemplateLabelGroups[labelGroup.name].external_data_reference,
                  dataReference,
                ),
              },
            });
            labelGroup.href = segmentationTemplateLabelGroups[labelGroup.name].href;
            labelGroup.overwrite = true;
            installableLabelGroups.original.push(labelGroup);
          } else {
            installableLabelGroups.install.push(segmentationTemplateLabelGroups[labelGroup.name]);
          }
        } else {
          installableLabelGroups.install.push(labelGroup);
        }
      });
    }

    if (templateToInstall.rule_sets) {
      const segmentationTemplateRulesets = SegmentationTemplateStore.getSegmentationTemplateRulesets();

      templateToInstall.rule_sets.forEach(ruleset => {
        if (segmentationTemplateRulesets[ruleset.key]) {
          if (segmentationTemplateRulesets[ruleset.key].isEdited) {
            warnings.push({type: intl('Explorer.Policy'), name: ruleset.name, entity: ruleset});

            const dataReference = `${ruleset.key} -- ${templateToInstall.name} - Version ${templateToInstall.version}`;

            installableRulesets.edited.push({
              id: segmentationTemplateRulesets[ruleset.key].href.split('/').pop(),
              data: {
                external_data_reference: SegmentationTemplateUtils.configureExternalDataReference(
                  segmentationTemplateRulesets[ruleset.key].external_data_reference,
                  dataReference,
                ),
              },
            });
            ruleset.href = segmentationTemplateRulesets[ruleset.key].href;
            ruleset.overwrite = true;
            installableRulesets.original.push(ruleset);
          } else {
            ruleset.href = segmentationTemplateRulesets[ruleset.key].href;
            installableRulesets.install.push(ruleset);
          }
        } else {
          installableRulesets.install.push(ruleset);
        }
      });

      const installData = {
        labelsToInstall,
        installableLabelGroups,
        servicesToInstall,
        ipListsToInstall,
        installableRulesets,
      };

      if (warnings.length) {
        this.handleWarnings(warnings, installData, templateToInstall);
      } else {
        this.installTemplate(installData, templateToInstall);
      }
    }
  },

  handleErrors(errors, key) {
    actionCreators.openDialog(
      <Dialog
        className="SegmentationTemplateErrorModal"
        type="detail"
        title={intl('Common.Error')}
        subTitle={intl('SegmentationTemplates.NamingConflict')}
        actions={[
          {
            text: intl('Common.Close'),
            tid: 'cancel',
            type: 'primary',
            onClick: () => {
              actionCreators.closeDialog();
            },
          },
        ]}
      >
        <ul>
          {errors.map(error => (
            <li>
              {`${error.type}: `}
              <span className="SegmentationTemplateErrorModal-EntityName">{error.name}</span>
            </li>
          ))}
        </ul>
      </Dialog>,
    );
    actionCreators.setSegmentationTemplateStatus({key});
  },

  handleWarnings(warnings, installData, templateToInstall) {
    actionCreators.setSegmentationTemplateStatus({key: templateToInstall.key});
    actionCreators.openDialog(
      <Dialog
        className="SegmentationTemplateErrorModal"
        type="detail"
        title="Install Template"
        subTitle="The following members of the selected template have been modified. Do you want to preserve these changes or overwrite them?"
        actions={[
          {
            text: intl('Common.Cancel'),
            tid: 'cancel',
            type: 'nofill',
            onClick: () => {
              actionCreators.closeDialog();
            },
          },
          {
            text: intl('SegmentationTemplates.Overwrite'),
            tid: 'overwrite',
            type: 'secondary',
            onClick: () => {
              actionCreators.closeDialog();
              this.calculateOverwrite(installData, templateToInstall);
            },
          },
          {
            text: intl('SegmentationTemplates.PreserveChanges'),
            tid: 'preserve',
            type: 'primary',
            onClick: () => {
              actionCreators.closeDialog();
              this.calculatePreserveChanges(installData, templateToInstall);
            },
          },
        ]}
      >
        <ul>
          {warnings.map(warning => (
            <li>
              {`${warning.type}: `}
              <span className="SegmentationTemplateErrorModal-EntityName">{warning.name}</span>
            </li>
          ))}
        </ul>
      </Dialog>,
    );
  },

  handleProvisionedAndDeletedObjects(provisionedAndDeletedObjects, templateToInstall) {
    actionCreators.openDialog(
      <Dialog
        className="SegmentationTemplateErrorModal"
        type="detail"
        title={intl('Common.Error')}
        subTitle={intl('SegmentationTemplates.ReadOnlyError')}
        actions={[
          {
            text: intl('SegmentationTemplates.CancelInstallation'),
            tid: 'cancel',
            type: 'secondary',
            onClick: () => {
              actionCreators.closeDialog();
            },
          },
          {
            text: intl('Common.Revert'),
            tid: 'revert',
            type: 'secondary',
            onClick: () => {
              actionCreators.closeDialog();
              this.handleRevertChangesBeforeInstall(provisionedAndDeletedObjects, templateToInstall);
            },
          },
          {
            text: 'Provision Now',
            tid: 'provision',
            type: 'primary',
            onClick: () => {
              actionCreators.closeDialog();
              this.handleProvisionChangesBeforeInstall(provisionedAndDeletedObjects, templateToInstall);
            },
          },
        ]}
      >
        <ul>
          {provisionedAndDeletedObjects.map(object => (
            <li>
              {`${object.type}: `}
              <span className="SegmentationTemplateErrorModal-EntityName">{object.name}</span>
            </li>
          ))}
        </ul>
      </Dialog>,
    );
    actionCreators.setSegmentationTemplateStatus({key: templateToInstall.key});
  },

  async handleRevertChangesBeforeInstall(provisionedAndDeletedObjects, templateToInstall) {
    // If the user reverts changes, the 'deletion pending' objects will revert to their active state and we will then update those objects

    const changeSubset = {};

    provisionedAndDeletedObjects.forEach(object => {
      const objectType = object.href.split('/').slice(-2)[0];

      if (changeSubset[objectType]) {
        changeSubset[objectType].push({href: object.href});
      } else {
        changeSubset[objectType] = [{href: object.href}];
      }
    });

    await RestApiUtils.secPolicies.dependencies({operation: 'revert', change_subset: changeSubset});

    const dependencies = ProvisionStore.getDependencies();

    dependencies.forEach(object => {
      const objectType = Object.keys(object.dependency)[0];

      if (changeSubset[objectType]) {
        changeSubset[objectType].push({href: object.dependency[objectType].href});
      } else {
        changeSubset[objectType] = [{href: object.data.href}];
      }
    });

    await RestApiUtils.secPolicies.delete({change_subset: changeSubset});
    await RestApiUtils.ruleSets.getCollection(
      {
        representation: 'rule_set_scopes',
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    await RestApiUtils.services.getCollection(
      {
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    await RestApiUtils.ipLists.getCollection(
      {
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    await RestApiUtils.labelGroups.getCollection(
      {
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    this.handlePrepareObjectsForInstall(templateToInstall);
  },

  async handleProvisionChangesBeforeInstall(provisionedAndDeletedObjects, templateToInstall) {
    // If the user Provisions the deleted objects, all of the objects will be permanently deleted, and new objects will be created on install

    const changeSubset = {};

    provisionedAndDeletedObjects.forEach(object => {
      const objectType = object.href.split('/').slice(-2)[0];

      if (changeSubset[objectType]) {
        changeSubset[objectType].push({href: object.href});
      } else {
        changeSubset[objectType] = [{href: object.href}];
      }
    });
    await RestApiUtils.secPolicies.dependencies({operation: 'commit', change_subset: changeSubset});

    const dependencies = ProvisionStore.getDependencies();

    dependencies.forEach(object => {
      const objectType = Object.keys(object.dependency)[0];

      if (changeSubset[objectType]) {
        changeSubset[objectType].push({href: object.dependency[objectType].href});
      } else {
        changeSubset[objectType] = [{href: object.data.href}];
      }
    });
    await RestApiUtils.secPolicies.create({update_description: '', change_subset: changeSubset});
    await RestApiUtils.ruleSets.getCollection(
      {
        representation: 'rule_set_scopes',
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    await RestApiUtils.services.getCollection(
      {
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    await RestApiUtils.ipLists.getCollection(
      {
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    await RestApiUtils.labelGroups.getCollection(
      {
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );
    this.handlePrepareObjectsForInstall(templateToInstall);
  },

  calculateOverwrite(installData, templateToInstall) {
    actionCreators.setSegmentationTemplateStatus({key: templateToInstall.key, status: 'installing'});

    //If the user overwrites their data, we will use the 'original' data from the template to update the objects
    if (installData.installableLabelGroups.original.length) {
      installData.installableLabelGroups.install = installData.installableLabelGroups.install.concat(
        installData.installableLabelGroups.original,
      );
    }

    if (installData.servicesToInstall.original.length) {
      installData.servicesToInstall.update = installData.servicesToInstall.original.concat(
        installData.servicesToInstall.update,
      );
    }

    if (installData.ipListsToInstall.original.length) {
      installData.ipListsToInstall.update = installData.ipListsToInstall.original.concat(
        installData.ipListsToInstall.update,
      );
    }

    if (installData.installableRulesets.original.length) {
      installData.installableRulesets.install = installData.installableRulesets.install.concat(
        installData.installableRulesets.original,
      );
    }

    this.installTemplate(installData, templateToInstall);
  },

  calculatePreserveChanges(installData, templateToInstall) {
    actionCreators.setSegmentationTemplateStatus({key: templateToInstall.key, status: 'installing'});

    //If user preserves changes, only the 'external_data_reference' is changed to indicate new version number
    if (installData.installableLabelGroups.edited.length) {
      installData.installableLabelGroups.install = installData.installableLabelGroups.install.concat(
        installData.installableLabelGroups.edited,
      );
    }

    if (installData.servicesToInstall.edited.length) {
      installData.servicesToInstall.update = installData.servicesToInstall.edited.concat(
        installData.servicesToInstall.update,
      );
    }

    if (installData.ipListsToInstall.edited.length) {
      installData.ipListsToInstall.update = installData.ipListsToInstall.edited.concat(
        installData.ipListsToInstall.update,
      );
    }

    if (installData.installableRulesets.edited.length) {
      installData.installableRulesets.install = installData.installableRulesets.install.concat(
        installData.installableRulesets.edited,
      );
    }

    this.installTemplate(installData, templateToInstall);
  },

  async installTemplate(installData, templateToInstall) {
    let labelGroupsToInstall = {create: [], update: []};
    let ruleSetsToInstall = {create: [], update: []};

    actionCreators.segmentationTemplatesClearInstalledTemplates();
    await Promise.all(installData.labelsToInstall.create.map(label => RestApiUtils.labels.create(label)));
    await Promise.all(installData.labelsToInstall.update.map(label => RestApiUtils.label.update(label.id, label.data)));
    await RestApiUtils.labels.getCollection({external_data_set: 'illumio_segmentation_templates'}, true);

    if (installData.installableLabelGroups.install.length) {
      labelGroupsToInstall = this.prepareLabelGroupsForInstall(
        installData.installableLabelGroups.install,
        templateToInstall,
      );
    }

    await Promise.all(labelGroupsToInstall.create.map(labelGroup => RestApiUtils.labelGroups.create(labelGroup)));
    await Promise.all(
      labelGroupsToInstall.update.map(labelGroup => RestApiUtils.labelGroup.update(labelGroup.id, labelGroup.data)),
    );
    await RestApiUtils.labelGroups.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);

    await Promise.all(installData.servicesToInstall.create.map(service => RestApiUtils.services.create(service)));
    await Promise.all(
      installData.servicesToInstall.update.map(service => RestApiUtils.service.update(service.id, service.data)),
    );
    await RestApiUtils.services.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);

    await Promise.all(installData.ipListsToInstall.create.map(ipList => RestApiUtils.ipLists.create(ipList)));
    await Promise.all(
      installData.ipListsToInstall.update.map(ipList => RestApiUtils.ipList.update(ipList.id, ipList.data)),
    );
    await RestApiUtils.ipLists.getCollection(
      {
        external_data_set: 'illumio_segmentation_templates',
      },
      'draft',
      true,
    );

    if (installData.installableRulesets.install.length) {
      await RestApiUtils.ruleSets.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);
      ruleSetsToInstall = this.prepareRuleSetsForInstall(installData.installableRulesets.install, templateToInstall);
    }

    await Promise.all(ruleSetsToInstall.create.map(ruleset => RestApiUtils.ruleSets.create(ruleset)));

    if (ruleSetsToInstall.update.length) {
      await RestApiUtils.ruleSets.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);
      await Promise.all(ruleSetsToInstall.update.map(ruleset => RestApiUtils.ruleSet.update(ruleset.id, ruleset.data)));
    }

    RestApiUtils.secPolicies.dependencies();
    await RestApiUtils.ruleSets.getCollection({external_data_set: 'illumio_segmentation_templates'}, 'draft', true);
    actionCreators.segmentationTemplatesCompareInstalledTemplates();
    actionCreators.setSegmentationTemplateStatus({key: templateToInstall.key});
  },

  prepareLabelsForInstall(template) {
    const create = [];
    const update = [];

    const segmentationTemplateLabels = SegmentationTemplateStore.getSegmentationTemplateLabels();

    template.labels.forEach(label => {
      const preExistingLabel = segmentationTemplateLabels[label.value]
        ? segmentationTemplateLabels[label.value]
        : false;
      const validLabel = {};
      const dataReference = `${label.value} -- ${template.name} - Version ${template.version}`;

      if (preExistingLabel) {
        if (preExistingLabel.external_data_set === 'illumio_segmentation_templates') {
          validLabel.external_data_reference = SegmentationTemplateUtils.configureExternalDataReference(
            preExistingLabel.external_data_reference,
            dataReference,
          );

          if (validLabel.external_data_reference !== preExistingLabel.external_data_reference) {
            update.push({id: preExistingLabel.href.split('/').pop(), data: validLabel});
          }
        }
      } else {
        validLabel.external_data_set = 'illumio_segmentation_templates';
        validLabel.key = label.key;
        validLabel.value = label.value;
        validLabel.external_data_reference = dataReference;
        create.push(validLabel);
      }
    });

    return {create, update};
  },

  prepareLabelGroupsForInstall(installableLabelGroups, template) {
    const create = [];
    const update = [];

    const segmentationTemplateLabels = SegmentationTemplateStore.getSegmentationTemplateLabels();

    installableLabelGroups.forEach(labelGroup => {
      if (labelGroup.id) {
        update.push(labelGroup);
      } else {
        const validLabelGroup = {};
        const validLabelHrefs = [];

        labelGroup.labels.forEach(label => {
          const existingLabel = segmentationTemplateLabels[label.value];

          if (existingLabel && existingLabel.href && !_.includes(validLabelHrefs, existingLabel.href)) {
            validLabelHrefs.push(existingLabel.href);
          }
        });

        const dataReference = `${labelGroup.name} -- ${template.name} - Version ${template.version}`;

        if (labelGroup.href) {
          if (labelGroup.overwrite) {
            validLabelGroup.description = labelGroup.description;
            validLabelGroup.external_data_reference = SegmentationTemplateUtils.configureExternalDataReference(
              labelGroup.external_data_reference,
              dataReference,
              true,
            );
          } else {
            validLabelGroup.external_data_reference = SegmentationTemplateUtils.configureExternalDataReference(
              labelGroup.external_data_reference,
              dataReference,
            );
          }

          if (validLabelGroup.external_data_reference !== labelGroup.external_data_reference) {
            validLabelGroup.labels = validLabelHrefs.map(labelHref => ({href: labelHref}));
            update.push({id: labelGroup.href.split('/').pop(), data: validLabelGroup});
          }
        } else {
          validLabelGroup.labels = validLabelHrefs.map(labelHref => ({href: labelHref}));
          validLabelGroup.external_data_set = 'illumio_segmentation_templates';
          validLabelGroup.name = labelGroup.name;
          validLabelGroup.key = labelGroup.key;
          validLabelGroup.description = labelGroup.description;
          validLabelGroup.external_data_reference = dataReference;
          create.push(validLabelGroup);
        }
      }
    });

    return {create, update};
  },

  prepareIpListsForInstall(ipLists, template) {
    const create = [];
    const update = [];
    const edited = [];
    const original = [];
    const segmentationTemplateIpLists = SegmentationTemplateStore.getSegmentationTemplateIpLists();

    ipLists.forEach(ipList => {
      const dataReference = `${ipList.name} -- ${template.name} - Version ${template.version}`;
      const preExistingIpList = segmentationTemplateIpLists[ipList.name];
      const validIpList = {};

      if (ipList.description) {
        validIpList.description = ipList.description;
      }

      validIpList.ip_ranges = ipList.ip_ranges;

      if (preExistingIpList) {
        if (preExistingIpList.created_by.href === '/users/0') {
          return;
        }

        validIpList.external_data_reference = SegmentationTemplateUtils.configureExternalDataReference(
          preExistingIpList.external_data_reference,
          dataReference,
        );

        if (preExistingIpList.isEdited) {
          edited.push({
            type: intl('Common.IPList'),
            name: preExistingIpList.name,
            id: preExistingIpList.href.split('/').pop(),
            data: {external_data_reference: validIpList.external_data_reference},
          });
          validIpList.external_data_reference = SegmentationTemplateUtils.configureExternalDataReference(
            preExistingIpList.external_data_reference,
            dataReference,
            true,
          );
          original.push({id: preExistingIpList.href.split('/').pop(), data: validIpList});
        } else if (preExistingIpList.external_data_reference !== validIpList.external_data_reference) {
          update.push({id: preExistingIpList.href.split('/').pop(), data: validIpList});
        }
      } else {
        validIpList.external_data_set = 'illumio_segmentation_templates';
        validIpList.external_data_reference = dataReference;
        validIpList.name = ipList.name;
        create.push(validIpList);
      }
    });

    return {create, update, edited, original};
  },

  prepareServicesForInstall(services, template) {
    const create = [];
    const update = [];
    const edited = [];
    const original = [];
    const segmentationTemplateServices = SegmentationTemplateStore.getSegmentationTemplateServices();

    services.forEach(service => {
      const dataReference = `${service.key} -- ${template.name} - Version ${template.version}`;
      const preExistingService = segmentationTemplateServices[service.key.toString()];
      const validService = {};

      if (service.description) {
        validService.description = service.description;
      }

      if (service.windows_services && service.windows_services.length) {
        validService.windows_services = service.windows_services;
      } else if (service.service_ports && service.service_ports.length) {
        validService.service_ports = service.service_ports;
      }

      if (preExistingService) {
        if (!preExistingService.created_by) {
          return;
        }

        const serviceToUpdate = {...validService};
        const editedDataRef = SegmentationTemplateUtils.configureExternalDataReference(
          preExistingService.external_data_reference,
          dataReference,
        );

        if (preExistingService.isEdited) {
          edited.push({
            type: intl('Common.Service'),
            name: service.name,
            id: preExistingService.href.split('/').pop(),
            data: {external_data_reference: editedDataRef},
          });
          serviceToUpdate.external_data_reference = SegmentationTemplateUtils.configureExternalDataReference(
            preExistingService.external_data_reference,
            dataReference,
            true,
          );
          original.push({id: preExistingService.href.split('/').pop(), data: serviceToUpdate});
        } else if (preExistingService.external_data_reference !== editedDataRef) {
          serviceToUpdate.external_data_reference = editedDataRef;
          update.push({id: preExistingService.href.split('/').pop(), data: serviceToUpdate});
        }
      } else {
        validService.name = service.name;
        validService.external_data_set = 'illumio_segmentation_templates';
        validService.external_data_reference = dataReference;
        create.push(validService);
      }
    });

    return {create, update, edited, original};
  },

  prepareRuleSetsForInstall(installableRulesets, template) {
    const create = [];
    const update = [];

    installableRulesets.forEach(ruleSet => {
      if (ruleSet.id) {
        update.push(ruleSet);
      } else {
        const dataReference = `${ruleSet.key} -- ${template.name} - Version ${template.version}`;
        const validRuleSet = {};

        if (ruleSet.description) {
          validRuleSet.description = ruleSet.description;
        }

        validRuleSet.scopes = SegmentationTemplateUtils.getValidScopes(ruleSet);
        validRuleSet.enabled = true;

        if (ruleSet.rules) {
          validRuleSet.rules = SegmentationTemplateUtils.getValidRules(ruleSet.rules);
        }

        if (ruleSet.href) {
          validRuleSet.external_data_reference = SegmentationTemplateUtils.configureExternalDataReference(
            ruleSet.external_data_reference,
            dataReference,
            true,
          );

          if (ruleSet.external_data_reference !== validRuleSet.external_data_reference) {
            update.push({id: parseInt(ruleSet.href.split('/').pop(), 10), data: validRuleSet});
          }
        } else {
          validRuleSet.name = ruleSet.name;
          validRuleSet.external_data_set = 'illumio_segmentation_templates';
          validRuleSet.external_data_reference = dataReference;
          create.push(validRuleSet);
        }
      }
    });

    return {create, update};
  },

  calculateDependencies(hrefs) {
    const allDependencies = ProvisionStore.getDependencies();
    let provisionDependencies = hrefs;

    if (allDependencies) {
      hrefs.forEach(href => {
        const dependency = allDependencies.find(object => object.href === href);

        if (dependency) {
          provisionDependencies = dependency.depends_on
            ? provisionDependencies.concat(dependency.depends_on)
            : provisionDependencies;
          provisionDependencies = dependency.depended_by
            ? provisionDependencies.concat(dependency.depended_by)
            : provisionDependencies;
        }
      });
    }

    return _.uniq(provisionDependencies);
  },
};
