/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PropTypes} from 'react';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-attributelist';
const defaultRowTid = 'comp-attributerow';

AttributeList.propTypes = {
  data: PropTypes.array,
  tid: PropTypes.string,
};

export default function AttributeList(props) {
  const tids = ComponentUtils.tid(defaultTid, props.tid);

  return (
    <div className="AttributeList" data-tid={ComponentUtils.tidString(tids)}>
      {props.data.reduce((prev, cur, idx) => {
        if (!cur) {
          return prev;
        }

        const tids = ComponentUtils.tid(defaultRowTid, cur.tid);
        const rowClasses = cx('AttributeList-row', cur.className);

        const valueClasses = cx('AttributeList-value', {
          'text-updated': cur.state === 'new',
          'text-modified': cur.state === 'updated',
          'text-deleted': cur.state === 'removed',
        });

        prev.push(
          <div key={idx} className={rowClasses} data-tid={ComponentUtils.tidString(tids)}>
            <div className="AttributeList-key" data-tid="comp-attributerow-label">
              {cur.label || cur.key}
            </div>
            <div className={valueClasses} data-tid="comp-attributerow-value">
              {cur.value}
            </div>
          </div>,
        );

        return prev;
      }, [])}
    </div>
  );
}
