/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import actions from '../actions/actionCreators';
import LabelStore from '../stores/LabelStore';
import ServiceUtils from './ServiceUtils';
import GeneralUtils from './GeneralUtils';
import WorkloadStore from '../stores/WorkloadStore';
import ContainerWorkloadStore from '../stores/ContainerWorkloadStore';
import LabelGroupStore from '../stores/LabelGroupStore';
import MapPageStore from '../stores/MapPageStore';
import VirtualServiceStore from '../stores/VirtualServiceStore';
import {getSessionUri, getInstanceUri} from '../lib/api';
import Label from '../components/Label.jsx';
import TrafficFilterStore from '../stores/TrafficFilterStore';
import LabelGroup from '../components/LabelGroup.jsx';
import VersionStore from '../stores/VersionStore';
import cx from 'classnames';
import {GridDataUtils} from '.';
import d3 from 'd3';

export const collator = new Intl.Collator('en', {
  usage: 'sort',
  sensitivity: 'base',
  numeric: true,
});

const vulnerabilityMap = {
  0: {severity: 'none', display: intl('Common.None')},
  1: {severity: 'info', display: intl('Common.Info')},
  2: {severity: 'low', display: intl('Common.Low')},
  3: {severity: 'medium', display: intl('Common.Medium')},
  4: {severity: 'high', display: intl('Common.High')},
  5: {severity: 'critical', display: intl('Common.Critical')},
};

const enforcementModeMap = {
  selective: 'selective',
  visibility_only: 'visibility',
  full: 'enforced',
  idle: 'idle',
};

function unionAllNodes(clusters, nodes) {
  let allNodes = [...nodes];

  _.each(clusters, cluster => {
    if (!_.isEmpty(cluster.nodes)) {
      allNodes.push(...cluster.nodes);
    }
  });

  allNodes = _.uniqBy(allNodes, 'href');

  return allNodes;
}

export const getLabels = oldLabels => {
  const labels = {};

  _.each(oldLabels, label => {
    label = LabelStore.getSpecified(label.href) || label;

    if (label && label.key && label.value) {
      labels[label.key] = {
        href: label.href,
        key: label.key,
        value: label.value,
      };
    }
  });

  return labels;
};

export const getScope = ruleset => {
  let scopes;

  if (ruleset.scopes?.[0]?.length > 0) {
    scopes = ruleset.scopes.map(scope => {
      const newScope = {};

      scope.forEach(label => {
        if (label.label_group) {
          newScope[label.label_group.key] = (
            <LabelGroup type={label.label_group.key} text={label.label_group.name} exclusion={label.exclusion} />
          );
        } else if (label.label) {
          newScope[label.label.key] = (
            <Label type={label.label.key} text={label.label.value} exclusion={label.exclusion} />
          );
        }
      });

      return Object.values(newScope);
    });
  } else {
    scopes = [[<Label icon="scope" text={intl('Common.All')} />]];
  }

  return scopes.map(scope => (
    <div className="GroupRulesets-Scope" data-tid="ruleset-scopes">
      {scope}
    </div>
  ));
};

export const constructxxxLabels = (labels, isCluster) => {
  labels = getLabels(labels);

  const xxxlabels = _.map(labels, 'href');
  const labelKeys = ['app', 'env', 'loc'];

  if (!isCluster) {
    labelKeys.push('role');
  }

  _.each(labelKeys, key => {
    if (!labels[key]) {
      let xlabel = getSessionUri(getInstanceUri('labels'), {});

      xlabel = xlabel.replace(/labels\/.*/, `labels?key=${key}&exists=false`);
      xxxlabels.push(xlabel);
    }
  });

  return xxxlabels;
};

export const getEnforcementString = type => {
  const policyStates = {
    visibility: intl('Common.VisibilityOnly'),
    enforced: intl('Workloads.Full'),
    unmanaged: intl('Common.Unmanaged'),
    idle: intl('Common.Idle'),
    unknown: intl('Common.Unknown'),
    selective: intl('Workloads.Selective'),
  };

  return policyStates[type];
};

export const enforcementStateMap = {
  full: intl('Workloads.Full'),
  selective: intl('Workloads.Selective'),
  visibility_only: intl('Common.VisibilityOnly'),
  idle: intl('Common.Idle'),
};

export const ransomwareExposureMap = {
  critical: intl('Common.Critical'),
  high: intl('Common.High'),
  medium: intl('Common.Medium'),
  low: intl('Common.Low'),
  fully_protected: intl('Common.Protected'),
};

export const getPolicyState = (node, type, entityCounts) => {
  if (type === 'workload') {
    const mode = node.mode || enforcementModeMap[node.enforcement_mode];

    // unmanaged workload doesn't have policy state
    if (
      !node.href?.includes('container_workloads') &&
      (node.unmanaged || (node.enforcement_mode && (!node.agent || !node.agent.status)))
    ) {
      return;
    }

    return mode;
  }

  if (type === 'virtualService') {
    return node.subType === 'virtualServer' ? node.mode : null;
  }

  if (type === 'role' || type === 'group') {
    if (node.discovered && !node.workloadsNum && !node.containerWorkloadsNum) {
      return;
    }

    if (
      !node.discovered &&
      (!node.mode || !((entityCounts || node.entityCounts) - node.virtualServiceCounts - node.virtualServerCounts))
    ) {
      return;
    }

    const policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();
    const containerMode = node.containerMode;
    let {visibility, enforced, selective, unmanaged, idle} = node.mode;

    if (containerMode) {
      visibility = visibility + containerMode.visibility;
      enforced = enforced + containerMode.enforced;
      selective = selective + containerMode.selective;
    }

    if (policyStateFilters.includes('enforce')) {
      enforced = 0;
    }

    if (policyStateFilters.includes('unmanaged')) {
      unmanaged = 0;
    }

    if (policyStateFilters.includes('idle')) {
      idle = 0;
    }

    // TODO: update the names of policy state from backend
    const states = [visibility, enforced, unmanaged, idle, selective].reduce((result, state) => {
      if (state) {
        result += 1;
      }

      return result;
    }, 0);

    if (states > 1) {
      if (visibility) {
        return 'visibility';
      }

      if (selective) {
        return 'selective';
      }

      if (enforced) {
        return 'enforced';
      }

      return 'mixed';
    }

    if (enforced) {
      return 'enforced';
    }

    if (visibility) {
      return 'visibility';
    }

    // Only define the group as unmanaged or idle if there are no other states available
    if (unmanaged) {
      return 'unmanaged';
    }

    if (idle) {
      return 'idle';
    }

    if (selective) {
      return 'selective';
    }
  }
};

export const getAppPolicyState = nodes => {
  if (_.isEmpty(nodes)) {
    return;
  }

  // don't take unmanaged nodes into consideration for cluster policy state
  nodes = _.filter(nodes, node => !node.unmanaged);

  let policyState = !_.isEmpty(nodes) && nodes[0].policyState;

  _.some(nodes, node => {
    if (node.policyState !== policyState) {
      policyState = 'mixed';

      return true;
    }
  });

  return policyState;
};

export const getEntityTypes = node => {
  let types = [];

  if (
    node &&
    (node.workloadCounts || node.workloadsNum || node.containerWorkloadCounts || node.containerWorkloadsNum)
  ) {
    types.push('workloads');
  }

  if (node && (node.virtualServiceCounts || node.virtualServicesNum || node.type === 'virtualService')) {
    types.push('virtual_services');
  }

  if (!types.length) {
    types = ['workloads'];
  }

  return types;
};

export const getPolicyStateGroupWorkloads = (group, policyStateFilters) => {
  let totalPolicyStateFiltered = 0;

  policyStateFilters.forEach(policyState => {
    // Api now sending counts for each state
    totalPolicyStateFiltered += group.mode[policyState];
  });

  return totalPolicyStateFiltered;
};

export const getPolicyStateGroupContainerWorkloads = (group, policyStateFilters) => {
  let totalPolicyStateFiltered = 0;

  if (group.containerMode) {
    policyStateFilters.forEach(policyState => {
      // Api now sending counts for each state
      totalPolicyStateFiltered += group.containerMode[policyState];
    });
  }

  return totalPolicyStateFiltered;
};

export const getAppVisibility = workloads => {
  if (_.isEmpty(workloads)) {
    return;
  }

  let visibility = workloads[0].visibility;

  _.some(workloads, workload => {
    if (workload.visibility !== visibility) {
      visibility = 'mixed';

      return true;
    }
  });

  return visibility;
};

export const getWorkloadVisibility = workload => {
  const agent = workload.agent;

  if (!agent) {
    return;
  }

  return workload.visibility_level || agent.config.visibility_level;
};

export const getWorkloadServicesData = workload => {
  let openServicePorts;

  if (workload.services && workload.services.open_service_ports) {
    openServicePorts = workload.services.open_service_ports;
  } else if (
    workload.agent_info &&
    workload.agent_info.service_report &&
    workload.agent_info.service_report.open_service_ports
  ) {
    openServicePorts = workload.agent_info.service_report.open_service_ports;
  }

  return openServicePorts;
};

export const getWorkloadServices = workload => {
  const openServicePorts = getWorkloadServicesData(workload);

  if (!openServicePorts) {
    return;
  }

  // get all services based on service name port and protocol
  const allServices = _.transform(
    openServicePorts,
    (result, service) => {
      let serviceName = service.process_name || intl('Common.Unknown');

      if (service.win_service_name) {
        const winService = ` (${service.win_service_name})`;

        serviceName = service.process_name ? service.process_name + winService : winService;
      }

      const resultKey = `${serviceName},${service.port},${service.protocol}`;

      result[resultKey] ||= {
        serviceName,
        port: service.port,
        protocol: ServiceUtils.lookupProtocol(service.protocol),
      };
    },
    {},
  );

  // return sorted services
  return _.values(allServices).sort((a, b) => {
    if (a.serviceName > b.serviceName) {
      return 1;
    }

    if (a.serviceName < b.serviceName) {
      return -1;
    }

    return a.port > b.port ? 1 : a.port < b.port ? -1 : 0;
  });
};

export const getWorkloadServicesGrouped = workload => {
  const serviceData = getWorkloadServices(workload);

  if (serviceData) {
    const groupedServices = {};

    _.forEach(serviceData, s => {
      if (groupedServices[s.serviceName]) {
        groupedServices[s.serviceName].push(`${s.port} ${s.protocol}`);
      } else {
        groupedServices[s.serviceName] = [`${s.port} ${s.protocol}`];
      }
    });

    return groupedServices;
  }
};

export const getWorkloadData = node => {
  if (node.subType === 'container') {
    const specifiedContainerWorkload = ContainerWorkloadStore.getSpecified(node.href);

    return specifiedContainerWorkload
      ? {...specifiedContainerWorkload, mode: enforcementModeMap[specifiedContainerWorkload.enforcement_mode]}
      : node.data;
  }

  const specifiedWorkload = WorkloadStore.getSpecified(node.href);

  return specifiedWorkload
    ? {...specifiedWorkload, mode: enforcementModeMap[specifiedWorkload.enforcement_mode]}
    : node.data;
};

export const getVirtualServiceData = node => VirtualServiceStore.getSpecified(node.href) || node.data;

export const isEmptyCluster = ({nodes, links, entityCounts}) => _.isEmpty(nodes) && _.isEmpty(links) && !entityCounts;

export const hasAllScopes = ({labels}) => _.size(labels) === 3;

export const hasOneOrTwoLabels = ({labels}) => _.size(labels) === 1 || _.size(labels) === 2;

export const hasAllRoles = nodes =>
  _.every(nodes, node => {
    if (node.type === 'workload' || node.type === 'virtualServer') {
      const labels = node.labels || getLabels(node.data.labels);

      return _.get(labels, 'role.value');
    }

    if (node.type === 'role') {
      return node.data.roleHref !== 'discovered';
    }
  });

export const hasAllLabels = ({labels}) => _.size(labels) === 4;

export const isInternetIpList = node =>
  node && (node.type === 'internet' || node.type === 'ipList' || node.type === 'fqdn');

export const isHrefFqdn = href => !isNaN(href);

export const getWorkloadClusterParent = wl => {
  const clusterLabels = wl?.labels?.filter(label => label.key && label.key !== 'role');
  const parentIds = clusterLabels.map(label => GeneralUtils.getId(label?.href)).sort((a, b) => collator.compare(a, b));

  return parentIds.join('x') || 'discovered';
};

export const isVenInstalled = node => {
  if (node && node.type === 'workload') {
    return !node.unmanaged && node.status && !node.status.includes('deactivated');
  }

  return true;
};

// Aggregates all the vulnerability calculations
// Use the key if aggregating at the link level, not at the connection level
// Values are the current aggregated values
// Instance is the values to be aggregated in
export const aggregateVulnerabilityValues = (values, instance, key) => {
  const aggregatedValues = values || {
    maxSeverity: 0,
    maxExpSeverity: -1,
    wideExposure: {},
    vulnerablePortExposure: null,
    vulnerabilityScore: 0,
    vulnerabilityExposureScore: null,
    maxVulnerabilityExposure: -1,
    portValue: false,
  };

  let {
    wideExposure,
    maxSeverity,
    maxExpSeverity,
    vulnerablePortExposure,
    vulnerabilityScore,
    vulnerabilityExposureScore,
    maxVulnerabilityExposure,
    portValue,
  } = aggregatedValues;

  if (instance.wideExposure && (instance.wideExposure.any || instance.wideExposure.ip_list)) {
    maxExpSeverity = Math.max(
      maxExpSeverity,
      instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity,
    );

    if (key) {
      wideExposure[key] = true;
    } else {
      wideExposure = true;
    }
  }

  // Calculate the maximum vulnerability severity
  maxSeverity = Math.max(maxSeverity, instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity);
  maxVulnerabilityExposure = Math.max(vulnerabilityExposureScore, instance.vulnerabilityExposureScore);

  if (instance.vulnerablePortExposure) {
    maxExpSeverity = Math.max(
      maxExpSeverity,
      instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity,
    );
  }

  // Aggregate the vulnerability and exposure scores
  if (instance.vulnerablePortExposure !== null && instance.vulnerablePortExposure !== intl('Common.NA')) {
    if (vulnerablePortExposure === null || vulnerablePortExposure === intl('Common.NA')) {
      vulnerablePortExposure = 0;
      vulnerabilityExposureScore = 0;
    }

    vulnerablePortExposure += instance.vulnerablePortExposure;
    vulnerabilityExposureScore += instance.vulnerabilityExposureScore;
  }

  if (instance.vulnerablePortExposure === intl('Common.NA') && vulnerablePortExposure === null) {
    vulnerablePortExposure = intl('Common.NA');
    vulnerabilityExposureScore = intl('Common.NA');
  }

  vulnerabilityScore += instance.vulnerabilityScore;

  portValue |= instance.port !== null;

  return {
    wideExposure,
    maxSeverity,
    maxExpSeverity,
    vulnerablePortExposure,
    vulnerabilityScore,
    vulnerabilityExposureScore,
    maxVulnerabilityExposure,
    portValue,
  };
};

export const getEmptyExposures = (aggregatedVulnerability, policyState) => {
  const vulnerability = {...aggregatedVulnerability};

  if (
    policyState === 'unmanaged' ||
    policyState === 'idle' ||
    (vulnerability.vulnerabilityExposureScore === null &&
      ((vulnerability.hasOwnProperty('protocol') && !vulnerability.protocol) ||
        (!vulnerability.portValue && !vulnerability.port)))
  ) {
    vulnerability.vulnerabilityExposureScore = intl('Common.NA');
    vulnerability.vulnerablePortExposure = intl('Common.NA');
  } else if (vulnerability.vulnerabilityExposureScore === null) {
    vulnerability.vulnerabilityExposureScore = intl('Workloads.Status.Syncing');
    vulnerability.vulnerablePortExposure = intl('Workloads.Status.Syncing');
  }

  return vulnerability;
};

// Fills the trafficAllowed attribute.
// If there's no rule href, there has to be an outbound rule.
// If link is outbound to internet, this is sufficient to turn it green.
// However, if link is between workloads, then it needs at least the managed service rule.
export const isConnectionAllowed = (connection, version, ruleCoverageTruncated) => {
  if (!connection) {
    return false;
  }

  if (version === 'reported') {
    return connection.policyCounts?.allowed;
  }

  // If we are not loading all the rules, show the links until proved blocked
  if (ruleCoverageTruncated) {
    return true;
  }

  // There may be multiple rules, but as long as one fulfills the requirement, we're good.
  return connection.rules && Array.isArray(connection.rules) && connection.rules.length;
};

export const isConnectionAllowedAcrossBoundary = (connection, version, ruleCoverageTruncated) => {
  if (!connection || version === 'reported') {
    return false;
  }

  // If we are not loading all the rules, show the links until proved blocked
  if (ruleCoverageTruncated) {
    return true;
  }

  const sourceEnforcement = connection.endpointEnforcement.source;
  const targetEnforcement = connection.endpointEnforcement.target;
  const fullEnforcement = sourceEnforcement?.enforced || targetEnforcement?.enforced;

  // There may be multiple rules, but as long as one fulfills the requirement, we're good.
  return (
    connection.rules &&
    Array.isArray(connection.rules) &&
    connection.rules.length &&
    connection.denyRules &&
    connection.denyRules.length &&
    !fullEnforcement
  );
};

export const isConnectionBlockedByBoundary = (connection, version) => {
  if (!connection) {
    return false;
  }

  if (version === 'reported') {
    return connection.policyCounts?.blockedByBoundary;
  }

  const allowRules = connection.rules && connection.rules.length && !connection.blockedConnection;
  const denyRules = connection.denyRules && connection.denyRules.length;

  const sourceEnforcement = connection.endpointEnforcement.source;
  const targetEnforcement = connection.endpointEnforcement.target;
  const selectiveEnforcement = sourceEnforcement?.selective || targetEnforcement?.selective;
  const fullEnforcement = sourceEnforcement?.enforced || targetEnforcement?.enforced;

  if (!allowRules && denyRules && selectiveEnforcement && !fullEnforcement) {
    return true;
  }

  return false;
};

export const isConnectionBlocked = (connection, version) => {
  if (!connection) {
    return false;
  }

  if (version === 'reported') {
    return connection.policyCounts?.blocked;
  }

  const allowRules = connection.rules && connection.rules.length && !connection.blockedConnection;
  const groupLink = MapPageStore.getMapType() === 'loc' && connection.groupLink;

  const sourceEnforcement = connection.endpointEnforcement.source;
  const targetEnforcement = connection.endpointEnforcement.target;

  if (!allowRules && !groupLink && (sourceEnforcement?.enforced || targetEnforcement?.enforced)) {
    return true;
  }

  return false;
};

export const isConnectionPotentiallyBlocked = (connection, version) => {
  if (!connection) {
    return false;
  }

  if (version === 'reported') {
    return connection.policyCounts?.potentiallyBlocked;
  }

  const groupLink = MapPageStore.getMapType() === 'loc' && connection.groupLink;
  const allowRules = connection.rules && connection.rules.length && !connection.blockedConnection;
  const denyRules = connection.denyRules && connection.denyRules.length;

  const sourceEnforcement = connection.endpointEnforcement.source;
  const targetEnforcement = connection.endpointEnforcement.target;
  const fullEnforcement = sourceEnforcement?.enforced || targetEnforcement?.enforced;

  if (!allowRules && !groupLink && !denyRules && !fullEnforcement) {
    return true;
  }

  return false;
};

export const isConnectionPotentiallyBlockedByBoundary = (connection, version) => {
  if (!connection) {
    return false;
  }

  if (version === 'reported') {
    return connection.policyCounts?.potentiallyBlockedByBoundary;
  }

  const allowRules = connection.rules && connection.rules.length && !connection.blockedConnection;
  const denyRules = connection.denyRules && connection.denyRules.length;

  const sourceEnforcement = connection.endpointEnforcement.source;
  const targetEnforcement = connection.endpointEnforcement.target;
  const selectiveEnforcement = sourceEnforcement?.selective || targetEnforcement?.selective;
  const fullEnforcement = sourceEnforcement?.enforced || targetEnforcement?.enforced;

  if (!allowRules && denyRules && !selectiveEnforcement && !fullEnforcement) {
    return true;
  }

  return false;
};

export const isConnectionUnknown = (connection, version) => {
  if (!connection) {
    return false;
  }

  if (version === 'draft') {
    const groupLink = MapPageStore.getMapType() === 'loc' && connection.groupLink;
    const allowRules = connection.rules && connection.rules.length && !connection.blockedConnection;
    const denyRules = connection.denyRules && connection.denyRules.length && !connection.blockedConnection;
    const sourceEnforcement = connection.endpointEnforcement.source;
    const targetEnforcement = connection.endpointEnforcement.target;
    const fullEnforcement = sourceEnforcement?.enforced || targetEnforcement?.enforced;

    // Group Links we are never sure if there are underlying rules for everything
    return !allowRules && (!denyRules || fullEnforcement) && groupLink;
  }

  return connection.policyCounts?.unknown;
};

export const isConnection = (policyDecision, connection, version, ruleCoverageTruncated) => {
  switch (policyDecision) {
    case 'allowed':
      return isConnectionAllowed(connection, version, ruleCoverageTruncated);

    case 'allowedAcrossBoundary':
      return isConnectionAllowedAcrossBoundary(connection, version, ruleCoverageTruncated);

    case 'blocked':
      return isConnectionBlocked(connection, version, ruleCoverageTruncated);

    case 'blockedByBoundary':
      return isConnectionBlockedByBoundary(connection, version, ruleCoverageTruncated);

    case 'potentiallyBlocked':
      return isConnectionPotentiallyBlocked(connection, version, ruleCoverageTruncated);

    case 'potentiallyBlockedByBoundary':
      return isConnectionPotentiallyBlockedByBoundary(connection, version, ruleCoverageTruncated);

    case 'unknown':
      return isConnectionUnknown(connection, version, ruleCoverageTruncated);
  }
};

export const policyDecisions = () => [
  'unknown',
  'allowed',
  'allowedAcrossBoundary',
  'potentiallyBlocked',
  'potentiallyBlockedByBoundary',
  'blockedByBoundary',
  'blocked',
];

export const getNewPolicyDecisions = initialValue => {
  return policyDecisions().reduce((result, policyDecision) => {
    result[policyDecision] = initialValue;

    return result;
  }, {});
};

export const getPolicyDecisionCounts = connections => {
  const trafficFilters = TrafficFilterStore.getAll();
  const policyVersion = MapPageStore.getPolicyVersion();

  return connections.reduce((counts, connection) => {
    policyDecisions().forEach(policyDecision => {
      if (
        trafficFilters[`allow${_.upperFirst(policyDecision)}Traffic`] &&
        isConnection(policyDecision, connection, policyVersion)
      ) {
        counts[policyDecision] += 1;
      }
    });

    return counts;
  }, getNewPolicyDecisions(0));
};

export const getFinalPolicyDecision = counts => {
  return policyDecisions()
    .reverse()
    .find(policyDecision => counts[policyDecision]);
};

export const aggregatePolicyDecisionCounts = (initialCounts, counts) => {
  return policyDecisions().reduce((result, policyDecision) => {
    result[policyDecision] += counts[policyDecision];

    return result;
  }, initialCounts);
};

export const aggregateConnections = (resultingConnections, connections) => {
  let overlappingConnections = 0;

  _.each(connections, (connection, key) => {
    let resultingConnection = resultingConnections[key];

    if (resultingConnection) {
      overlappingConnections += 1;
    } else {
      resultingConnections[key] = resultingConnection = {
        endpointEnforcement: connection.endpointEnforcement,
        policyCounts: getNewPolicyDecisions(0),
        port: connection.port,
        protocol: connection.protocol,
        friendlyProtocol: connection.friendlyProtocol,
        service: connection.service,
        processName: connection.processName,
        serviceName: connection.serviceName,
        sessions: 0,
        rules: [],
        denyRules: [],
        timestamp: connection.timestamp,
        vulnerabilities: {},
        connectionClass: connection.connectionClass,
      };
    }

    resultingConnection.sessions += connection.sessions;

    if (connection.vulnerabilities) {
      if (!resultingConnection.hasOwnProperty('vulnerabilities')) {
        resultingConnection.vulnerabilities = {};
      }

      resultingConnection.vulnerabilities.aggregatedValues = aggregateVulnerabilityValues(
        resultingConnection.vulnerabilities.aggregatedValues,
        connection.vulnerabilities.aggregatedValues,
      );

      resultingConnection.vulnerabilities.instances = (resultingConnection.vulnerabilities.instances || []).concat(
        connection.vulnerabilities.instances,
      );
    }

    resultingConnection.policyCounts = aggregatePolicyDecisionCounts(
      resultingConnection.policyCounts,
      connection.policyCounts,
    );

    if (connection.rules) {
      resultingConnection.rules = [...resultingConnection.rules, ...connection.rules];
    }

    if (connection.denyRules) {
      resultingConnection.denyRules = [...resultingConnection.denyRules, ...connection.denyRules];
    }
  });

  return overlappingConnections;
};

export const getVulnerabilityCounts = connections => {
  // Aggregate connection vulnerabilities
  // This is only needed for app group to app group traffic which could have
  // different vulnerabilities on the same port
  return connections.reduce(
    (counts, connection) => {
      if (connection.vulnerabilities) {
        const aggregatedVulnerability = aggregateVulnerabilityValues(
          counts.vulnerabilities.aggregatedValues,
          connection.vulnerabilities.aggregatedValues,
          connection.key,
        );

        counts.vulnerabilities.aggregatedValues = {
          ...aggregatedVulnerability,
          ...getEmptyExposures(aggregatedVulnerability, counts.policyState),
        };

        counts.vulnerabilities.instances = (counts.vulnerabilities.instances || []).concat(
          connection.vulnerabilities.instances,
        );
      }

      return counts;
    },
    {vulnerabilities: {}},
  );
};

export const isInterappTraffic = link => {
  const full = MapPageStore.getMapLevel() === 'full';
  // The full map nodes without a group do not have link.source.cluster
  // So use that to decide if they are in or out of a group
  let sourceGroup = full ? link.source.cluster && link.source.clusterParent : link.source.clusterParent;
  let targetGroup = full ? link.target.cluster && link.target.clusterParent : link.target.clusterParent;

  if (MapPageStore.getMapType() === 'app') {
    sourceGroup = link.source.appGroupParent;
    targetGroup = link.target.appGroupParent;
  }

  // If the source or target is the internet/ipList use the cluster from the other end
  sourceGroup = isInternetIpList(link.source) ? targetGroup : sourceGroup;
  targetGroup = isInternetIpList(link.target) ? sourceGroup : targetGroup;

  return (
    !sourceGroup ||
    !targetGroup || // interapp link between node & cluster
    (sourceGroup && targetGroup && sourceGroup !== targetGroup) || // interapp link between clusters
    link.source.type === 'group' ||
    link.target.type === 'group'
  ); // one side is a collapsed cluster
};

export const emptyLabels = labels => !labels || !Object.values(labels).some(label => label.href);

export const isDiscoveredGroupLink = link =>
  (!isInternetIpList(link.source) &&
    emptyLabels(link.source.labels) &&
    (link.source.type === 'role' || link.source.type === 'group')) ||
  (!isInternetIpList(link.target) &&
    emptyLabels(link.target.labels) &&
    (link.target.type === 'role' || link.target.type === 'group'));

export const isDiscoveredContainerWorkloadLink = link =>
  (emptyLabels(link.source.labels) && link.source.subType === 'container') ||
  (emptyLabels(link.target.labels) && link.target.subType === 'container');

export const isOutboundTraffic = function (link) {
  return isInternetIpList(link.target);
};

export const isToVirtualServerTraffic = link =>
  link.target.type === 'virtualService' && link.target.subType === 'virtualServer';
export const isToVirtualServiceTraffic = link =>
  link.target.type === 'virtualService' && link.target.subType !== 'virtualServer';

export const getLabelHrefFromNode = (node, type) => node.labels && node.labels[type] && node.labels[type].href;

export const getLabelNameFromNode = (node, type) => node.labels && node.labels[type] && node.labels[type].value;

export const getTypeFromNode = (node, href) => (href ? 'label' : node.type);

export const getScopeHrefsFromInterappLink = (node, scopes) => {
  scopes ||= ['app', 'env', 'loc'];

  const appHref = getLabelHrefFromNode(node, 'app');
  const envHref = getLabelHrefFromNode(node, 'env');
  const locHref = getLabelHrefFromNode(node, 'loc');
  const allHrefs = [];

  const appLabels = {
    href: appHref,
    name: getLabelNameFromNode(node, 'app'),
    type: getTypeFromNode(node, appHref),
    key: 'app',
    isClusterLabel: true,
  };

  const envLabels = {
    href: envHref,
    name: getLabelNameFromNode(node, 'env'),
    type: getTypeFromNode(node, envHref),
    key: 'env',
    isClusterLabel: true,
  };

  const locLabels = {
    href: locHref,
    name: getLabelNameFromNode(node, 'loc'),
    type: getTypeFromNode(node, locHref),
    key: 'loc',
    isClusterLabel: true,
  };

  if (appLabels.href && scopes.includes('app')) {
    allHrefs.push(appLabels);
  }

  if (envLabels.href && scopes.includes('env')) {
    allHrefs.push(envLabels);
  }

  if (locLabels.href && scopes.includes('loc')) {
    allHrefs.push(locLabels);
  }

  return allHrefs;
};

export const getLabelName = (labels, href) => {
  if (href && href.includes('label_group')) {
    return LabelGroupStore.getSpecified(href);
  }

  const thisLabel = _.find(labels, label => label.href === href);

  return thisLabel;
};

// Call this RenderUtils.for title of traffic panel
export const getNodeTitle = node => {
  if (!node) {
    return;
  }

  if (node.type === 'internet') {
    return intl('Common.Internet');
  }

  if (node.type === 'ipList') {
    return intl('Common.IPLists');
  }

  if (node.type === 'fqdn') {
    return intl('Common.Fqdns');
  }

  if (node.data) {
    return node.data.name || node.data.hostname;
  }

  if (node.type === 'workload') {
    return WorkloadStore.getSpecified(node.href).name || WorkloadStore.getSpecified(node.href).hostname;
  }
};

export const getNodeName = obj => {
  if (obj.type === 'role' || obj.type === 'label' || obj.type === 'labelGroup' || obj.type === 'virtualService') {
    return obj.value || obj.name;
  }

  return obj.data ? obj.data.name || obj.data.hostname : obj.name;
};

// Call this RenderUtils.for name of source and target nodes within info panel
export const getInternetName = node => {
  if (node.type === 'internet' || node.type === 'fqdn') {
    if (node.href === 'class_a') {
      return '10.0.0.0/8';
    }

    if (node.href === 'class_b') {
      return '172.16.0.0/12';
    }

    if (node.href === 'class_c') {
      return '192.168.0.0/16';
    }

    if (node.href === 'internet') {
      return intl('IPLists.Any');
    }
  } else if (node.type === 'ams') {
    return intl('Workloads.All');
  }
};

export const trafficHasProcessName = connection => connection.service && connection.service !== 'unknown';

export const matchServiceName = connection => {
  let name = intl('Common.Unknown');
  const matchedServices = ServiceUtils.matchConnectionWithService(connection);

  if (matchedServices.length) {
    name = matchedServices[0].name;
  } else if (trafficHasProcessName(connection)) {
    // if there's no matched services, default to process name
    name = connection.service;
  }

  return name;
};

export const getServiceName = connection => {
  const protocol = ServiceUtils.lookupProtocol(connection.protocol);
  const name = matchServiceName(connection);

  return `${ServiceUtils.getPort(connection) || ''} ${protocol} ${name}`;
};

export const isSelectedService = (connection, selectedService = {}) => {
  connection ||= {};
  selectedService ||= {};

  if (_.isEmpty(connection) || _.isEmpty(selectedService)) {
    return false;
  }

  let serviceNameMatched = false;
  let processNameMatched = false;

  // check if service name matched for connection with multiple services
  if (selectedService.serviceNames) {
    serviceNameMatched = [...selectedService.serviceNames].includes(connection.serviceName);
    // for connection with multiple services
  } else if (!selectedService.serviceNames && selectedService.serviceName) {
    serviceNameMatched = connection.serviceName === selectedService.serviceName;
  }

  // check if process name matched for connection with multiple services
  if (selectedService.processNames) {
    processNameMatched = [...selectedService.processNames].includes(connection.processName);
    // for connection with multiple services
  } else if (!selectedService.processNames && selectedService.processName) {
    processNameMatched = connection.processName === selectedService.processName;
  }

  return (
    connection.port === selectedService.port &&
    serviceNameMatched &&
    processNameMatched &&
    connection.protocol === selectedService.protocol &&
    connection.connectionClass === selectedService.connectionClass
  );
};

export const truncateText = str => {
  if (typeof str === 'string' && str.length) {
    let resultVal;

    if (str.includes('...')) {
      resultVal = `${str.slice(0, Math.abs(Math.ceil(str.length / 2) - Math.ceil(str.length / 9)))}...${str.slice(
        Math.abs(Math.ceil(str.length / 2) + Math.ceil(str.length / 6)),
        str.length,
      )}`;
    } else {
      resultVal = `${str.slice(0, Math.floor(str.length / 2) - 4)}...${str.slice(
        Math.ceil(str.length / 2) + 1,
        str.length,
      )}`;
    }

    return resultVal;
  }
};

/*
   This function computes the difference between 2 strings and creates a new result string in the following format:
   (first 10 characters) + 3 ellipsis (respresents the difference) + (Last 3 characters, irrespctive of
   the number of characters that are different in the end).

   Example:
   Input :
    abcdefghijklmnopqrstuvwxyz4 | Staging | London str1
    abcdefghijklmnopqrstuvwxyz2 | Staging | London str2

    Output(Result String):
    abcdefghijk...yz4 | Staging | London result

    Note 1:
    This should work for strings of any length. Useful in truncation when you want to show full string using tooltip.

    Note 2:
    We apply this only starting from the second string that is different. The common strings are already sorted into groups in an object
    Look at getGroupActionValues method for more details.

    Note 3:
    The first string is always truncated using truncateAppGroupName. Look at getGroupActionValues method for further details.
 */
export const composeTruncatedStringDifference = (str1, str2) => {
  let indexStr1 = 0;
  let indexStr2 = 0;
  let countEllipsis = 0;
  let result = '';

  for (const [valueStr2] of str2) {
    if (indexStr1 <= 10 || str1[indexStr1] !== valueStr2 || indexStr1 === str1.length) {
      if (countEllipsis === 3) {
        const labels = str1.split(' | ');

        for (const [index, label] of labels) {
          result += index === 0 ? label.slice(-3) : ` | ${label}`;
        }

        break;
      } else {
        result += str1[indexStr2];

        if (indexStr1 <= 10) {
          indexStr1++;
        }
      }
    } else {
      if (countEllipsis < 3) {
        result += '.';
        countEllipsis++;
      }

      indexStr1++;
    }

    indexStr2++;
  }

  return result;
};

export const trimLabels = name => name.split('|').map(label => label.trim());

export const truncateAppGroupName = (appGroupName, totalCharacters, numberOfCharactersPerLabel = []) => {
  //number of characters per label should be an array of numbers for app, env, loc labels
  if (appGroupName && appGroupName.length > totalCharacters) {
    const labels = trimLabels(appGroupName);

    labels.forEach((label, index) => {
      let prevLabel;

      // Ensure no infinite loop
      while (label.length > (numberOfCharactersPerLabel[index] || totalCharacters) && prevLabel !== label) {
        prevLabel = label;
        label = truncateText(label);
      }

      labels[index] = label;
    });

    return labels.join(' | ');
  }

  return appGroupName;
};

export const getLabelObject = labels => {
  if (labels) {
    return labels.reduce((result, label) => {
      label.id = GeneralUtils.getId(label.href);
      result[label.key] = label;

      return result;
    }, {});
  }
};

export const renderScopeLabels = labels => {
  const globalScopeMap = {
    app: intl('Common.AllApplications'),
    env: intl('Common.AllEnvironments'),
    loc: intl('Common.AllLocations'),
  };

  return Object.keys(globalScopeMap).map(labelKey => {
    const rulesetLabel = labels.find(
      scope => scope.key === labelKey || scope.label?.key === labelKey || scope.label_group?.key === labelKey,
    );

    const labelInfo = rulesetLabel?.label ||
      rulesetLabel?.label_group ||
      rulesetLabel || {key: labelKey, value: globalScopeMap[labelKey], href: 'all'};

    return labelInfo.href.includes('label_group') ? (
      <LabelGroup key={labelKey} text={labelInfo.name} type={labelInfo.key} exclusion={rulesetLabel?.exclusion} />
    ) : (
      <Label key={labelKey} text={labelInfo.value} type={labelInfo.key} exclusion={rulesetLabel?.exclusion} />
    );
  });
};

export const getLabelTypesRank = scope => {
  const labelTypeRank = ['loc', 'env', 'app'];

  return labelTypeRank.reduce((result, type, index) => {
    if (scope.some(item => item.label?.key === type || item.label_group?.key === type)) {
      result += index;
    }

    return result;
  }, 0);
};

export const getMissingLabelHref = key => {
  const hrefPrefix = getSessionUri(getInstanceUri('labels'), {label_id: ''}).slice(0, -1);

  return `${hrefPrefix}?key=${key}&exists=false`;
};

export const getCompleteLabels = labels => {
  const labelTypes = ['app', 'env', 'loc', 'role'];

  return labelTypes.reduce((result, key) => {
    // If this key exists in the set use it, otherwise use the 'noLabels' version
    if (labels[key]) {
      result.push(labels[key].href);
    } else {
      result.push(getMissingLabelHref(key));
    }

    return result;
  }, []);
};

export const getAppGroupParent = (node, appGroupsType) => {
  if (
    !appGroupsType.length ||
    !node.labels ||
    !appGroupsType.every(key => node.labels.find(label => label.key === key))
  ) {
    return;
  }

  const appGroupParent = node.labels
    .reduce((result, label) => {
      if (appGroupsType && appGroupsType.includes(label.key)) {
        const id = _.last(label.href.split('/'));

        if (id) {
          result.push(id);
        }
      }

      return result;
    }, [])
    .sort((a, b) => collator.compare(a, b))
    .join('x');

  return appGroupParent;
};

export const getRuleSetId = ruleHref => (ruleHref.includes('/') ? ruleHref.split('/')[6] : ruleHref);

export const isExtraScopeTraffic = (sourceHref, targetHref) => {
  if (!sourceHref || !targetHref || !sourceHref.includes('-') || !targetHref.includes('-')) {
    return false;
  }

  return sourceHref.split('-')[0] !== targetHref.split('-')[0];
};

export const getAllLinks = (clusters, links, clusterLinks = [], nodes, appSupergroupLinks, truncated) => {
  let appGroupLinks = [];
  let overLimit = false;
  const maxLinks = localStorage.getItem('connected_app_group_rules') || 5000;
  const filters = TrafficFilterStore.getAll();
  const draftPolicyFilter = !filters.allowAllowedTraffic || !filters.allowBlockedTraffic;

  // Only load the superAppGroup link draft coverage if a draft policy filter is not allowed
  if (appSupergroupLinks && draftPolicyFilter) {
    appGroupLinks = Object.values(appSupergroupLinks).reduce((aggregatedLinks, link) => {
      let nextTraffic = [];

      nextTraffic = link.traffic.reduce((roleLinks, appGroupLink) => {
        // Until we get to 1000 aggregated links get the role traffic
        if (aggregatedLinks.length + roleLinks.length + appGroupLink.data.childrenTraffics.length < maxLinks) {
          roleLinks = [...roleLinks, ...appGroupLink.data.childrenTraffics];
        } else {
          overLimit = true;
        }

        return roleLinks;
      }, []);

      aggregatedLinks = [...aggregatedLinks, ...nextTraffic];

      return aggregatedLinks;
    }, []);

    if (truncated !== overLimit) {
      // If rule coverage is truncated, show app groups before the rule coverage returns
      actions.ruleCoverageIsTruncated(overLimit);
    }
  }

  const result = [...links];

  nodes.forEach(({links}) => {
    if (links && links.length) {
      result.push(...links);
    }
  });

  clusters.forEach(({links}) => {
    if (links && links.length) {
      result.push(...links);
    }
  });

  return [...result, ...clusterLinks, ...appGroupLinks];
};

export const getAllLinksForRules = (clusters, links, clusterLinks, nodes, appSupergroupLinks, truncated) => {
  const allLinks = getAllLinks(clusters, links, clusterLinks, nodes, appSupergroupLinks, truncated) || [];

  // take all links that are to be rendered, and expand the internet links back out
  return allLinks.reduce((result, link) => {
    if (link.internetLinks) {
      const href = link.internetLinks.map(link => link.href);

      result.push(...href);
    } else {
      result.push(link.href);
    }

    return result;
  }, []);
};

export const getAllClusters = clusters =>
  _.transform(
    clusters,
    (result, cluster) => {
      // note(swu): for now, filter out the clusters that are discovered
      // since the full traffic API doesn't support it
      if (cluster.href && cluster.href !== 'discovered' && !cluster.href.includes('discovery')) {
        result.push(cluster.href);
      }
    },
    [],
  );

export const getAllNodes = (clusters, nodes, type) =>
  unionAllNodes(clusters, nodes).reduce((result, node) => {
    if (node && node.type === 'workload') {
      // The single detected vulnerability api should never be called without workload caps for the workload
      if (node.href.split('/').pop().length && (type !== 'vulnerability' || node.caps?.workloads?.length)) {
        result.push(node.href);
      }
    }

    return result;
  }, []);

export const getAllRoles = (clusters, nodes) =>
  unionAllNodes(clusters, nodes).reduce((result, node) => {
    if (node && node.type === 'role') {
      result.push(node.href);
    }

    return result;
  }, []);

export const getVulnerabilityForRender = (value, attribute) => {
  let key = 5;

  if (value < 0) {
    key = 0;
  } else if (value === 0) {
    key = 1;
  } else if (value <= 4) {
    key = 2;
  } else if (value <= 7) {
    key = 3;
  } else if (value <= 9) {
    key = 4;
  }

  if (attribute === 'key') {
    return key;
  }

  return vulnerabilityMap[key][attribute];
};

export const roundNumber = value => {
  const s = 10;
  const k = 1000;
  const m = 1_000_000;
  const b = 1_000_000_000;
  const t = 1_000_000_000_000;

  if (!value) {
    return value;
  }

  if (value < 0.1) {
    return 0.1;
  }

  if (value < s) {
    return Math.round(value * 10) / 10;
  }

  if (value < k) {
    return Math.round(value);
  }

  if (value <= m) {
    return value / k > 100 ? `${Math.round(value / k)}K` : `${Math.round((value * 10) / k) / 10}K`;
  }

  if (value <= b) {
    return value / m > 100 ? `${Math.round(value / m)}M` : `${Math.round((value * 10) / m) / 10}M`;
  }

  if (value <= t) {
    return value / b > 100 ? `${Math.round(value / b)}B` : `${Math.round((value * 10) / b) / 10}B`;
  }

  return value / t > 100 ? `${Math.round(value / t)}T` : `${Math.round((value * 10) / t) / 10}T`;
};

export const cleanVulnerabilityValues = values => ({
  ...values,
  vulnerablePortExposure: roundNumber(values.vulnerablePortExposure),
  vulnerabilityScore: roundNumber(values.vulnerabilityScore),
  vulnerabilityExposureScore: roundNumber(values.vulnerabilityExposureScore),
});

export const getVulnerabilityOptions = () =>
  _.reduce(
    vulnerabilityMap,
    (result, option, key) => {
      if (option.severity !== 'none') {
        result[key] = option.display;
      }

      return result;
    },
    {},
  );

export const truncateUsername = (username, maxLength) => {
  const truncatedUsername = username.split('@');

  // if it is not an email address, has 0 or multiple @ symbols, just truncate as a string
  if (truncatedUsername.length !== 2) {
    return truncateAppGroupName(truncatedUsername.join('@'), maxLength, [maxLength]);
  }

  if (truncatedUsername[1].length < 15) {
    // If username is an email address, remove the length of the domain from the total length
    maxLength -= truncatedUsername[1].length;
  } else {
    // If the domain length is ALSO very long, compose the email of equal parts truncated-username@truncated-domain
    maxLength = maxLength / 2;
    truncatedUsername[1] = truncateAppGroupName(truncatedUsername[1], maxLength, [maxLength]);
  }

  truncatedUsername[0] = truncateAppGroupName(truncatedUsername[0], maxLength, [maxLength]);

  return truncatedUsername.join('@');
};

export const tooltipOrMenuPosition = (location, type, width, height, tooltipOrMenu) => {
  const bubbleOffset = tooltipOrMenu === 'tooltip' ? 5 : 0; // To avoid bubbling of tooltip, due to hover "ON" on tooltip div.
  let left = `${location.x + bubbleOffset}px`;
  let top = `${location.y + bubbleOffset}px`;
  let right;
  let bottom;
  const opacity = 1;
  const transition = 'opacity 0.2s ease-in';
  let transitionDelay = '0.1s';

  if (tooltipOrMenu === 'tooltip' && type === 'location') {
    transitionDelay = '0.5s';
  }

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const tooltipWidth = width;
  const tooltipOffset = 10; // Offseting the tooltip content div
  const totalWidthOffset = tooltipWidth + tooltipOffset + bubbleOffset;

  const totalHeightOffset = height;
  let style = {top, left, opacity, transition, transitionDelay};
  let styleContent = {top: `${tooltipOffset}px`, left: `${tooltipOffset}px`};

  if (location.x > windowWidth - totalWidthOffset || location.y > windowHeight - totalHeightOffset) {
    if (location.x > windowWidth - totalWidthOffset && location.y > windowHeight - totalHeightOffset) {
      // MoveTopLeft
      right = `${windowWidth - location.x + bubbleOffset}px`;
      bottom = `${windowHeight - location.y + bubbleOffset}px`;
      style = {right, bottom, opacity, transition, transitionDelay};
      styleContent = {bottom: `${tooltipOffset}px`, right: `${tooltipOffset}px`};
    } else if (location.x > windowWidth - totalWidthOffset) {
      // MoveLeft
      right = `${windowWidth - location.x + bubbleOffset}px`;
      top = `${location.y + bubbleOffset}px`;
      style = {right, top, opacity, transition, transitionDelay};
      styleContent = {top: `${tooltipOffset}px`, right: `${tooltipOffset}px`};
    } else if (location.y > windowHeight - totalHeightOffset) {
      // MoveTop
      left = `${location.x + bubbleOffset}px`;
      bottom = `${windowHeight - location.y + bubbleOffset}px`;
      style = {bottom, left, opacity, transition, transitionDelay};
      styleContent = {bottom: `${tooltipOffset}px`, left: `${tooltipOffset}px`};
    }
  }

  return {style, styleContent};
};

export const getTransmissionMode = connectionClass => {
  switch (connectionClass) {
    case 'B':
      return intl('Map.Traffic.Broadcast');
    case 'M':
      return intl('Map.Traffic.Multicast');
    case 'U':
      return intl('Map.Traffic.Unicast');
  }
};

export const getTransmissionModeIcon = connectionClass => {
  switch (connectionClass) {
    case 'B':
      return 'broadcast';
    case 'M':
      return 'multicast';
    case 'U':
      return 'unicast';
  }
};

export const getTimeFromFilter = (timeFilter, lastProvisionTime = VersionStore.getLatestProvisionTime()) => {
  // Passing in the provision time, so we don't make that call here for performance concerns
  if (timeFilter.type === 'provision') {
    if (MapPageStore.getPolicyVersion() === 'draft') {
      return 'anytime';
    }

    return lastProvisionTime;
  }

  if (timeFilter.type === 'time') {
    if (timeFilter.value === 'anytime') {
      return 'anytime';
    }

    return timeFilter.value / 1000;
  }
};

export const getUniqueRulesetName = (
  appGroupName,
  rulesetsWithName,
  tag = intl('PolicyGenerator.PolicyGeneratorTag'),
) => {
  const policyGeneratorName = `${appGroupName} ${tag}`;
  const rulesetNumbers = rulesetsWithName
    .reduce((result, ruleset) => {
      const findNumber = ruleset.name.split(policyGeneratorName);

      // Found the policy generator name at the front of the string
      if (!findNumber[0]) {
        // If the first item is empty, the policyGeneratorName was found at the beginning of the string
        if (findNumber[1]) {
          const number = Number(findNumber[1].trim());

          if (!isNaN(number)) {
            // Found a tailing number
            result.push(number);
          }
        } else {
          // If the second item is empty the policyGeneratorName is an exact match
          // For an exact match push zero, so we will add '1' to the end of the string
          result.push(0);
        }
      }

      return result;
    }, [])
    .sort((a, b) => b - a);

  // If there are any matching names, add 1 to the largest number
  if (rulesetNumbers.length) {
    // Create policy genrator name which looks like 'app group name (Policy Generator) 2'
    return `${policyGeneratorName} ${rulesetNumbers[0] + 1}`;
  }

  return policyGeneratorName;
};

function getPolicyArrowIconName(trafficPd = '', direction = 'right') {
  let arrowIconName = `arrow-${direction}`;

  switch (trafficPd) {
    case 'blockedByBoundary':
    case 'potentiallyBlockedByBoundary':
      arrowIconName = `boundary-${direction}`;
      break;
    case 'allowedAcrossBoundary':
      arrowIconName = `across-boundary-${direction}`;
    default:
      break;
  }

  return arrowIconName;
}

function getPolicyDecisionClassNames(trafficPd = '', colorBlind = 'normal') {
  return cx({
    TrafficAllowed: trafficPd === 'allowed' || trafficPd === 'allowedAcrossBoundary',
    TrafficBlocked: trafficPd === 'blocked' || trafficPd === 'blockedByBoundary',
    TrafficPotentiallyBlocked: trafficPd === 'potentiallyBlocked' || trafficPd === 'potentiallyBlockedByBoundary',
    TrafficUnknown: trafficPd === 'unknown',
    ColorDeficiency: colorBlind !== 'normal',
    Boundary: trafficPd.toLowerCase().includes('boundary'),
  });
}

function getPolicyLabel(trafficPd) {
  return cx({
    [intl('Common.Allowed')]: trafficPd === 'allowed',
    [intl('Common.AllowedAcrossBoundary')]: trafficPd === 'allowedAcrossBoundary',
    [intl('Common.Blocked')]: trafficPd === 'blocked',
    [intl('Common.BlockedByDenyRules')]: trafficPd === 'blockedByBoundary',
    [intl('Common.PotentiallyBlocked')]: trafficPd === 'potentiallyBlocked',
    [intl('Common.PotentiallyBlockByDenyRules')]: trafficPd === 'potentiallyBlockedByBoundary',
    [intl('Common.Unknown')]: trafficPd === 'unknown',
  });
}

function getTrafficPanelEnforcementModeText(service = {}, direction = '') {
  const hasIpList = (service[direction === 'consumers' ? 'sourceAddresses' : 'targetAddresses'] || []).length > 0;
  const enforcementObject = (service.endpointEnforcement || {})[direction === 'consumers' ? 'source' : 'target'] || {};
  let enforcementTypes = Object.keys(enforcementObject).filter(key => enforcementObject[key]);
  const hasUnmanagedWorkload = enforcementTypes.includes('unmanaged');

  // ignore unmanaged workloads when combined with other enforcement type(s)
  if (enforcementTypes.length > 1 && hasUnmanagedWorkload) {
    enforcementTypes = enforcementTypes.filter(type => type !== 'unmanaged');
  }

  if (hasIpList) {
    return '';
  }

  // mixed enforcement when there are multiple types (excluding unmanaged)
  if (enforcementTypes.length > 1) {
    return intl('EnforcementBoundaries.MixedEnforcement');
  }

  switch (enforcementTypes.join('')) {
    case 'visibility':
      return intl('Common.VisibilityOnly');
    case 'selective':
      return intl('EnforcementBoundaries.SelectiveEnforcement');
    case 'enforced':
      return intl('Workloads.FullEnforcement');
    case 'idle':
      return intl('Common.Idle');
    case 'unmanaged':
      return intl('Common.Unmanaged');
    case 'unknown':
      return intl('Common.Unknown');
    default:
      return '';
  }
}

function getSortedTrafficPanelServices(services, key = 'trafficWeight', reverse = true, isVulnerabilityView = false) {
  return services.sort((a, b) => {
    let value = b[key] > a[key] ? 1 : b[key] < a[key] ? -1 : 0;

    if (key === 'name') {
      value =
        b[key].toLocaleLowerCase() > a[key].toLocaleLowerCase()
          ? 1
          : b[key].toLocaleLowerCase() < a[key].toLocaleLowerCase()
          ? -1
          : 0;
    }

    if (key === 'trafficWeight' && isVulnerabilityView) {
      if (a.vulnerabilities && b.vulnerabilities) {
        value = GridDataUtils.sortVulnerability(a.vulnerabilities.aggregatedValues, b.vulnerabilities.aggregatedValues);
      } else if (!a.vulnerabilities && !b.vulnerabilities) {
        value = 0;
      } else if (b.vulnerabilities) {
        value = -1;
      } else {
        value = 1;
      }
    } else if (key === 'trafficWeight') {
      value *= -1;
    }

    return reverse ? -1 * value : value;
  });
}

function getTrafficPanelServicesLabelText(services, serviceNum, maxServices) {
  let servicesTitle;
  let serviceNumText;

  // If there are more services than we are displaying, show the total number
  if (serviceNum > services.length || services.length > maxServices) {
    servicesTitle = intl('Map.Traffic.TopServices');
    serviceNumText = intl('Common.NumOfNum', {
      low: Math.min(services.length, maxServices),
      high: serviceNum,
    });

    if (this.spinner) {
      serviceNumText = serviceNum;
    }
  } else {
    serviceNumText = intl.num(services.length);
    servicesTitle = intl('Map.Traffic.ServiceCount', {count: serviceNumText});
  }

  return `${serviceNumText} ${servicesTitle}`;
}

function getTrafficPanelSortClasses(columnKey, key, direction) {
  let sortClass = 'MapSubInfoPanel-Sort-Off';
  let sortUpIconClass = '';
  let sortDownIconClass = '';

  if (key === columnKey) {
    sortClass = 'MapSubInfoPanel-Sort-On';

    if (direction) {
      sortDownIconClass = 'MapSubInfoPanel-Sort-Min';
    } else {
      sortUpIconClass = 'MapSubInfoPanel-Sort-Min';
    }
  }

  return [`${sortClass} ${sortUpIconClass}`, `${sortClass} ${sortDownIconClass}`];
}

// Calculates traffic panel & tooltip info for all types of links
function getTrafficServices(link, selectedService) {
  let linkConnections = _.orderBy(link.connections, ['timestamp'], ['desc']);

  // if link is a internetLinks, we need get all connections
  if (link.internetLinks) {
    linkConnections = [];
    _.each(link.internetLinks, internetLink => {
      _.each(internetLink.connections, connection => {
        linkConnections.push(connection);
      });
    });
  }

  const allConnections = _.transform(
    linkConnections,
    (result, connection) => {
      if (!connection.isHidden) {
        let name = intl('Common.Unknown');
        const matchedServices = ServiceUtils.matchConnectionWithService(connection);

        if (matchedServices.length > 1) {
          name = `${matchedServices[0].name}, +${matchedServices.length - 1}`;
        } else if (matchedServices.length) {
          name = matchedServices[0].name;
        } else if (trafficHasProcessName(connection)) {
          // If there's no matched services, default to process name
          name = connection.service;
        }

        const newConnection = {...connection, name, matchedServices};
        const key = [
          newConnection.name,
          newConnection.port,
          newConnection.protocol,
          newConnection.connectionClass,
        ].join(',');

        result[key] ||= [];

        result[key].push(newConnection);
      }
    },
    {},
  );

  const allSessions = _.map(allConnections, connections => _.reduce(connections, (memo, c) => c.sessions + memo, 0));
  const minTraffic = _.min(allSessions);
  const maxTraffic = _.max(allSessions);
  const connectionScale = d3.scale.linear().domain([minTraffic, maxTraffic]).range([3, 50]);
  const appMapVersion = MapPageStore.getAppMapVersion();
  const allConnectionsLength = Object.values(allConnections).length;

  const trafficServices = _.transform(
    allConnections,
    (result, connections) => {
      const sessions = _.reduce(connections, (memo, c) => c.sessions + memo, 0);
      // Only highlight a connection as selected if there's more than one connection.
      const isSelected =
        allConnectionsLength > 1 && selectedService && isSelectedService(connections[0], selectedService);
      // Use the rule for the connection with the most recent timestamp

      const filteredPdCounts = getPolicyDecisionCounts(connections);

      let vulnerabilities = null;

      if (appMapVersion !== 'policy') {
        vulnerabilities = getVulnerabilityCounts(connections);
      }

      result.push({
        ...connections[0],
        processNames: new Set(connections.map(connection => connection.processName)),
        serviceNames: new Set(connections.map(connection => connection.serviceName)),
        friendlyProtocol: connections[0].friendlyProtocol,
        trafficWeight: minTraffic === maxTraffic ? connectionScale.range()[3] : connectionScale(sessions),
        filteredPdCounts,
        trafficPd: getFinalPolicyDecision(filteredPdCounts),
        isSelected,
        policyState: link.target?.policyState,
        ...vulnerabilities,
      });
    },
    [],
  );

  if (MapPageStore.getAppMapVersion() === 'vulnerability') {
    return trafficServices.sort(
      (a, b) =>
        (b.vulnerabilities?.aggregatedValues?.vulnerabilityExposureScore || 0) -
        (a.vulnerabilities?.aggregatedValues?.vulnerabilityExposureScore || 0),
    );
  }

  return trafficServices.sort((a, b) =>
    a.trafficWeight > b.trafficWeight ? -1 : a.trafficWeight < b.trafficWeight ? 1 : 0,
  );
}

export default {
  collator,
  getScope,
  getLabels,
  trimLabels,
  isHrefFqdn,
  roundNumber,
  getAllRoles,
  getAllNodes,
  getAllLinks,
  getNodeName,
  hasAllRoles,
  truncateText,
  hasAllScopes,
  hasOneOrTwoLabels,
  hasAllLabels,
  getNodeTitle,
  getLabelName,
  isConnection,
  getRuleSetId,
  getLabelObject,
  getEntityTypes,
  getAllClusters,
  getServiceName,
  isVenInstalled,
  isEmptyCluster,
  getPolicyState,
  policyDecisions,
  getInternetName,
  getTypeFromNode,
  getWorkloadData,
  matchServiceName,
  isInternetIpList,
  getAppVisibility,
  truncateUsername,
  getCompleteLabels,
  getEmptyExposures,
  getAppGroupParent,
  getTimeFromFilter,
  getLabelTypesRank,
  isOutboundTraffic,
  isInterappTraffic,
  getAppPolicyState,
  isSelectedService,
  renderScopeLabels,
  constructxxxLabels,
  getMissingLabelHref,
  isConnectionUnknown,
  getTransmissionMode,
  isConnectionAllowed,
  isConnectionBlocked,
  getWorkloadServices,
  getAllLinksForRules,
  isExtraScopeTraffic,
  getUniqueRulesetName,
  getLabelNameFromNode,
  aggregateConnections,
  getLabelHrefFromNode,
  truncateAppGroupName,
  getEnforcementString,
  enforcementStateMap,
  getVirtualServiceData,
  isDiscoveredGroupLink,
  trafficHasProcessName,
  getWorkloadVisibility,
  tooltipOrMenuPosition,
  getNewPolicyDecisions,
  getFinalPolicyDecision,
  getVulnerabilityCounts,
  getPolicyDecisionCounts,
  getTransmissionModeIcon,
  getVulnerabilityOptions,
  getWorkloadServicesData,
  cleanVulnerabilityValues,
  isToVirtualServerTraffic,
  getWorkloadClusterParent,
  isToVirtualServiceTraffic,
  getVulnerabilityForRender,
  getWorkloadServicesGrouped,
  aggregateVulnerabilityValues,
  getPolicyStateGroupWorkloads,
  aggregatePolicyDecisionCounts,
  getScopeHrefsFromInterappLink,
  isConnectionPotentiallyBlocked,
  composeTruncatedStringDifference,
  isDiscoveredContainerWorkloadLink,
  getPolicyStateGroupContainerWorkloads,
  getPolicyArrowIconName,
  getPolicyDecisionClassNames,
  getTrafficPanelEnforcementModeText,
  getPolicyLabel,
  getSortedTrafficPanelServices,
  getTrafficPanelServicesLabelText,
  getTrafficPanelSortClasses,
  getTrafficServices,
};
