/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

let appGroups = [];
let count = {};

function setCount(data) {
  count = data;
}

export default createApiStore(['APP_GROUP_', 'APP_GROUPS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.APP_GROUPS_GET_COLLECTION_SUCCESS:
        appGroups = action.data;
        setCount(action.count);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return appGroups;
  },

  getCount() {
    return count;
  },
});
