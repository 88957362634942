/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';
import LabelStore from '../stores/LabelStore';
import SettingsOrgStore from '../stores/SettingsOrgStore';

const defaultTid = 'comp-label';

export default React.createClass({
  propTypes: {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.any,
    type: PropTypes.string,
    labelType: PropTypes.string,
    exclusion: PropTypes.bool,
    isGroup: PropTypes.bool,
    noBorder: PropTypes.bool,
    facet: PropTypes.string,
  },

  getDefaultProps() {
    return {
      onClick: null,
      text: '',
    };
  },

  getInitialState() {
    return {
      labelSettingList: LabelStore.getLabelSettingsList(),
      labelTypes: LabelStore.getLabelTypes(),
      labelStyle: SettingsOrgStore.getLabelStyle(),
    };
  },

  render() {
    const {labelSettingList, labelTypes, labelStyle} = this.state;
    const labelType = this.props.labelType || this.props.type;
    const labelSetting = labelSettingList.find(label => label.key === labelType);
    const type = this.props.type;

    const classes = cx({
      'Label': true,
      'Label--link': this.props.onClick,
      'Label--scopes': type === 'scopes',
      'Label--created': type === 'created',
      'Label--deleted': type === 'deleted',
      'Label--disabled': type === 'disabled',
      'Label--usergroup': type === 'usergroup',
      'Label--exclusion': this.props.exclusion,
      'Label--noleftborder': !labelTypes?.includes(labelType),
      'Label--noborder': this.props.noBorder,
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.type);
    let icon = this.props.icon ? this.props.icon : null;

    let exclusionText = '';
    let text = this.props.text;
    let titleText = text;

    if (typeof text === 'object') {
      titleText = text.props?.children;
    }

    const exclusionTextName = labelSetting?.display_info.display_name_plural || labelSetting?.display_name || '';

    if (this.props.exclusion && labelTypes?.includes(icon || labelType)) {
      exclusionText = `${intl('Labels.Excluded', {type: exclusionTextName})} `;
    }

    const style = {};
    let initial;

    if (labelType && labelTypes?.includes(labelType)) {
      // EYE-80061 - props.text is sometimes an array of JSX to handle text highlighting/matching in dropdowns
      if (Array.isArray(text)) {
        text = this.props.text.map(({props: {children}}) => children).join('');
      }

      const excludedTitleText = this.props.exclusion
        ? intl('Labels.Excluded', {type: exclusionTextName})
        : exclusionTextName;

      titleText = `${excludedTitleText} ${titleText}`;

      icon = labelSetting?.display_info?.icon || icon;

      if (labelSetting?.display_info) {
        style.backgroundColor = labelSetting.display_info.background_color;
        style.color = labelSetting.display_info.foreground_color;
      }

      initial = `${__ANTMAN__ && this.props.isGroup ? 'G' : ''}${labelSetting?.display_info?.initial}`;

      if (labelStyle?.includes('prefix') && initial) {
        text = (
          <span>
            <strong>{initial}:&nbsp;</strong>
            {text}
          </span>
        );
      } else if (labelStyle?.includes('suffix') && initial) {
        text = (
          <span>
            {text}
            <strong>-{initial}</strong>
          </span>
        );
      }
    }

    if (icon === 'loc') {
      icon = 'location';
    }

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)} onClick={this.props.onClick} title={titleText}>
        {(icon || initial) && (
          <div
            className={cx(`Label-icon Label-icon--${icon}`, this.props.isGroup ? 'Label-icon-group' : '')}
            style={style}
          >
            <Icon
              name={icon}
              initial={initial}
              labelStyle={labelStyle && labelSetting?.display_info?.icon ? labelStyle : 'initial'}
            />
          </div>
        )}
        {this.props.facet && this.props.facet}
        <span className={cx('Label-text Label-flex', this.props.isGroup ? 'LabelGroup-text' : '')} data-tid="elem-text">
          {exclusionText}
          {text}
        </span>
        {this.props.onClose && (
          <Icon
            name="close"
            size="small"
            styleClass="ObjectSelector-item-value"
            key="item-delete-multi"
            onClick={this.props.onClose}
            data-tid="comp-icon comp-icon-remove comp-icon-close"
          />
        )}
      </div>
    );
  },
});
