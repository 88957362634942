/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import ComponentUtils from '../utils/ComponentUtils';

export default React.createClass({
  propTypes: {
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    tid: PropTypes.string,
  },

  getDefaultProps() {
    return {
      disabled: false,
      onChange: _.noop,
      checked: false,
    };
  },

  handleChildrenClick(evt) {
    // Experimental
    this.props.onChange({target: {value: evt.target.previousSibling.firstChild.value}});
  },

  render() {
    const classes = cx({
      'Radio': true,
      'Radio--disabled': this.props.disabled,
      'Radio--checked': this.props.checked,
    });
    const tids = ComponentUtils.tid('comp-radio', this.props.tid);
    const hasChildren = React.Children.count(this.props.children) !== 0;
    const children = hasChildren ? (
      <div className="Radio-content" data-tid="comp-radio-content" onClick={this.handleChildrenClick}>
        {this.props.children}
      </div>
    ) : null;

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
        <label>
          <input
            tabIndex="0"
            data-tid="elem-input"
            type="radio"
            checked={this.props.checked}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            value={this.props.value}
          />
          <span className="Radio-label" data-tid="elem-text">
            {this.props.label}
          </span>
        </label>
        {children}
      </div>
    );
  },
});
