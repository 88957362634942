/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import SessionStore from './SessionStore';
import {UserUtils} from '../utils';
import {isContainerClusterHref, isVENHref} from '../utils/GeneralUtils';

let org = [];
let users = [];
let userMap = {};
// userActivityUsers are users with the user_activity representation
// This representation exposes certain user properties like presence_status which are used on the User Activity Pages
let userActivityUsers = [];
// userActivityUserOrg is the user org which holds the user's permissions.
// This is used to display permission info on the User Activity Item pages.
let userActivityUserOrg = null;
let currentUserOrg = null;
let authSecPrincipals = [];
let authSecPrincipalsByName = [];
const authSecPrincipalsDropDownValues = {};
let permissionScopeRoles = [];
let permissions = [];

let areUsersLoaded = false;
let areUserActivityUsersLoaded = false;
let isUserActivityUserOrgLoaded = false;
let isCurrentUserOrgLoaded = false;
let areAuthSecPrincipalsLoaded = false;
let arePermissionsLoaded = false;
let arePermissionScopeRolesLoaded = false;

function setOrg(data) {
  org = data;
}

function setUsers(data) {
  users = data;
  userMap = data.reduce((res, user) => {
    res[user.username] = user;

    return res;
  }, {});
}

function setUserActivityUsers(data) {
  userActivityUsers = data;
}

function setAuthSecPrincipals(data) {
  authSecPrincipals = data;
}

function setAuthSecPrincipalsByName(data) {
  authSecPrincipalsByName = data;
}

function setAuthSecPrincipalsDropDownValues(action) {
  const matchData = {num_matches: action.data.length, matches: action.data.map(res => res.name)};

  authSecPrincipalsDropDownValues[`name-${action.options.query.name}`] = matchData;
}

function setPermissions(data) {
  permissions = data;
}

function setPermissionScopeRoles(data) {
  permissionScopeRoles = data;
}

const spec = {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.ORGS_GET_INSTANCE_SUCCESS:
        setOrg(action.data);
        break;

      case Constants.USERS_GET_COLLECTION_SUCCESS:
        if (action.options && action.options.query && action.options.query.representation === 'user_activity') {
          setUserActivityUsers(action.data);
          areUserActivityUsersLoaded = true;
        } else {
          setUsers(action.data);
          areUsersLoaded = true;
        }

        break;

      case Constants.USER_ORGS_SUCCESS:
        if (action.options && action.options.query && action.options.query.representation === 'org_permissions') {
          const requestId = action.options.params && action.options.params.id;
          const currentUserId = SessionStore.getUserId();

          if (requestId === currentUserId) {
            const orgs = action.data;

            if (orgs.some(org => org.href && _.last(org.href.split('/')) === '0') && orgs.length > 1) {
              currentUserOrg = orgs.find(org => org.href && _.last(org.href.split('/')) !== '0');
            } else {
              currentUserOrg = orgs[0];
            }

            isUserActivityUserOrgLoaded = true;
          } else {
            const orgs = action.data;

            if (orgs.some(org => org.href && _.last(org.href.split('/')) === '0') && orgs.length > 1) {
              userActivityUserOrg = orgs.find(org => org.href && _.last(org.href.split('/')) !== '0');
            } else {
              userActivityUserOrg = orgs[0];
            }

            isCurrentUserOrgLoaded = true;
          }
        }

        break;

      case Constants.ORG_AUTH_SECURITY_PRINCIPALS_GET_COLLECTION_SUCCESS:
        const principalName = action.options.query && 'name' in action.options.query;

        if (principalName) {
          setAuthSecPrincipalsByName(action.data);
          setAuthSecPrincipalsDropDownValues(action);
        } else {
          setAuthSecPrincipals(action.data);
          areAuthSecPrincipalsLoaded = true;
        }

        break;

      case Constants.ORG_PERMISSIONS_SCOPE_ROLES_SUCCESS:
        setPermissionScopeRoles(action.data);
        arePermissionScopeRolesLoaded = true;
        break;

      case Constants.ORG_PERMISSIONS_GET_COLLECTION_SUCCESS:
        setPermissions(action.data);
        arePermissionsLoaded = true;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getOrg() {
    return org;
  },

  getUsers() {
    return users;
  },

  clearFilters() {
    setAuthSecPrincipalsByName([]);
  },

  getPermissionScopeRoles() {
    return permissionScopeRoles;
  },

  getPermissionFromHref(href) {
    return permissions.find(permission => permission.href === href);
  },

  getPermissionFromScopePrincipalRole(scope, principalHref, roleHref) {
    const scopeHrefs = scope.map(label => label.href);

    return permissions.find(permission => {
      const scopeMatch =
        permission.scope.length === scopeHrefs.length &&
        permission.scope.every(pscope => scopeHrefs.includes(pscope.href));
      const principalMatch = permission.auth_security_principal.href === principalHref;
      const roleMatch = permission.role.href === roleHref;

      return scopeMatch && principalMatch && roleMatch;
    });
  },

  getAuthSecPrincipals() {
    return authSecPrincipals;
  },

  getAuthSecPrincipalsFromName(name) {
    return authSecPrincipals.find(principal => principal.name === name);
  },

  getAuthSecPrincipalsByName() {
    return authSecPrincipalsByName;
  },

  getAuthSecPrincipalsDropDownValues(type) {
    if (type === 'all') {
      return authSecPrincipalsDropDownValues;
    }

    const filteredDropDownValues = {};

    Object.keys(authSecPrincipalsDropDownValues).forEach(key => {
      const filteredValues = authSecPrincipalsDropDownValues[key].matches.filter(value => {
        if (!value) {
          return false;
        }

        value = value.toLowerCase();

        if (type === 'localUsers') {
          return (
            authSecPrincipals.some(({type, name}) => type === 'user' && name && name.toLowerCase() === value) &&
            userMap[value] &&
            userMap[value].type === 'local'
          );
        }

        if (type === 'extUsers') {
          return (
            authSecPrincipals.some(({type, name}) => type === 'user' && name && name.toLowerCase() === value) &&
            !(userMap[value] && userMap[value].type === 'local')
          );
        }

        if (type === 'extGroups') {
          return authSecPrincipals.some(({type, name}) => type === 'group' && name && name.toLowerCase() === value);
        }

        return false;
      });

      filteredDropDownValues[key] = {
        matches: filteredValues,
        num_matches: filteredValues.length,
      };
    });

    return filteredDropDownValues;
  },

  getPermissions() {
    return permissions;
  },

  getUserFromHref(href) {
    if (!href) {
      return;
    }

    // Usually, it is a user href, but there are exceptions.
    // With the container work, objects can be provisioned by Kubelink.
    // For this special case, 'Container Cluster' is displayed, instead of Unknown.
    if (isContainerClusterHref(href)) {
      return {
        full_name: intl('Menu.ContainerClusters', {multiple: false}),
        username: intl('Menu.ContainerClusters', {multiple: false}),
      };
    }

    // With the VEN work, objects can be provisioned by VEN.
    // For this special case, 'VEN' is displayed, instead of Unknown.
    if (isVENHref(href)) {
      return {
        full_name: intl('Common.VEN'),
        username: intl('Common.VEN'),
      };
    }

    if (_.isEmpty(users)) {
      return;
    }

    return users.find(u => u.href === href);
  },

  getUserFromAuthSecPrincipal(authSecPrincipal) {
    return users.find(user => user.username && authSecPrincipal.name.toLowerCase() === user.username.toLowerCase());
  },

  getUserActivityUsers() {
    return userActivityUsers;
  },

  getUserActivityUserOrg() {
    return userActivityUserOrg;
  },

  getCurrentUserOrg() {
    return currentUserOrg;
  },

  getCurrentUserRoles() {
    if (currentUserOrg === null) {
      return null;
    }

    return currentUserOrg
      ? [...new Set(currentUserOrg.permissions.map(permission => _.last(permission.role.href.split('/'))))]
      : [];
  },

  getExtUsers() {
    return users.filter(user => user.type === 'external');
  },

  reverseProviderConsumer() {
    return org.reverse_provider_consumer_column;
  },

  providerConsumerOrder() {
    return org.reverse_provider_consumer_column ? 'consumerFirst' : 'providerFirst';
  },

  getLocalUsers() {
    return users.filter(user => user.type === 'local');
  },

  getPermissionsFromScope(scope) {
    return permissions.filter(permission => {
      const scopesMatch =
        permission.scope.length === scope.length && permission.scope.every(pscope => scope.includes(pscope.href));

      return scopesMatch && !UserUtils.isGlobalRoleHref(permission.role.href);
    });
  },

  getPermissionsFromRole(role) {
    return permissions.filter(permission => permission.role.href === role);
  },

  getPermissionsFromAuthSecPrincipal(authSecPrincipal) {
    return permissions.filter(permission => permission.auth_security_principal.href === authSecPrincipal.href);
  },

  getLocalAuthSecPrincipals() {
    return authSecPrincipals.filter(
      ({type, name}) => type === 'user' && userMap[name.toLowerCase()] && userMap[name.toLowerCase()].type === 'local',
    );
  },

  filterLocalAuthSecPrincipals(principals) {
    return principals.filter(
      ({type, name}) =>
        type === 'user' &&
        users.some(
          ({type: utype, username}) =>
            utype === 'local' && username && name && username.toLowerCase() === name.toLowerCase(),
        ),
    );
  },

  getCurrentAuthSecPrincipal() {
    const user = this.getUserFromHref(SessionStore.getUserHref());

    return authSecPrincipals.find(
      ({type, name}) => type === 'user' && user && user.username.toLowerCase() === name.toLowerCase(),
    );
  },

  getExtAuthSecPrincipals() {
    return authSecPrincipals.filter(
      ({type, name}) =>
        type === 'user' && !(userMap[name.toLowerCase()] && userMap[name.toLowerCase()].type === 'local'),
    );
  },

  filterExtAuthSecPrincipals(principals) {
    return principals.filter(
      ({type, name}) =>
        type === 'user' &&
        !users.some(
          ({type: utype, username}) =>
            utype === 'local' && username && name && username.toLowerCase() === name.toLowerCase(),
        ),
    );
  },

  getAuthSecPrincipalFromHref(href) {
    return authSecPrincipals.find(authSecPrincipal => authSecPrincipal.href === href);
  },

  getDefaultReadOnlyAuthSecPrincipal() {
    return authSecPrincipals.find(authSecPrincipal => authSecPrincipal.name === null);
  },

  getGroupAuthSecPrincipals() {
    return authSecPrincipals.filter(authSecPrincipal => authSecPrincipal.type === 'group');
  },

  filterGroupAuthSecPrincipals(principals) {
    return principals.filter(
      ({type, name}) =>
        type === 'group' &&
        !users.some(
          ({type: utype, username}) =>
            utype === 'local' && username && name && username.toLowerCase() === name.toLowerCase(),
        ),
    );
  },

  getAuthSecPrincipalFromUser(user) {
    return authSecPrincipals.find(
      ({type, name}) => type === 'user' && name && user.username && name.toLowerCase() === user.username.toLowerCase(),
    );
  },

  areUsersLoaded() {
    return areUsersLoaded;
  },

  areUserActivityUsersLoaded() {
    return areUserActivityUsersLoaded;
  },

  isUserActivityUserOrgLoaded() {
    return isUserActivityUserOrgLoaded;
  },

  isCurrentUserOrgLoaded() {
    return isCurrentUserOrgLoaded;
  },

  areAuthSecPrincipalsLoaded() {
    return areAuthSecPrincipalsLoaded;
  },

  arePermissionsLoaded() {
    return arePermissionsLoaded;
  },

  arePermissionScopeRolesLoaded() {
    return arePermissionScopeRolesLoaded;
  },
};

export default createApiStore(
  ['ORGS_', 'ORG_', 'USER_', 'USERS_'],
  spec,
  // Don't listen to 'ORG_HEALTH_' actions here as that is handled in the HealthStore
  // 'ORG_HEALTH_*' has nothing to do with ORGS, thus not part of this store
  'ORG_HEALTH_',
);
