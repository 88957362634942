/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import Link from '../RouteLink.jsx';
import React, {createElement, PropTypes, Component} from 'react';
import Badge from '../Badge.jsx';

export default class MenuItem extends Component {
  static propTypes = {
    // Item content
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,

    // Optional parameters, if item is for navigation
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    onRef: PropTypes.func, // Pass item instance upwards
    onClick: PropTypes.func, // To notify upper subscriber
    onFocus: PropTypes.func, // To notify upper subscriber
    onMouse: PropTypes.func, // To notify upper subscriber
    onSelect: PropTypes.func, // User's handler for select. Return false to cancel
    badge: PropTypes.oneOf(['preview', 'new']),

    // Flag to highlight active tab
    // isActive can be set the following ways:
    //  1) Controlled e.g. passed in as a prop.
    //  2) If the MenuItem is the active route.
    //  3) If the MenuItem has a child which is the active route.
    isActive: PropTypes.bool,
  };

  static contextTypes = {
    router: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: _.noop,
    onClick: _.noop,
    onFocus: _.noop,
    onMouse: _.noop,
    onSelect: _.noop,
  };

  constructor(props) {
    super(props);

    // isFocusedParent is used to keep the parent dropdown MenuItem highlighted when in focus
    this.state = {isFocusedParent: false};
  }

  componentWillMount() {
    this.saveRef = this.saveRef.bind(this);
    this.saveContentRef = this.saveContentRef.bind(this);

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.isSomeChildActive = this.isSomeChildActive.bind(this);
  }

  setParentFocus(focusedParent) {
    this.setState({isFocusedParent: focusedParent});
  }

  saveRef(element) {
    this.itemElement = element;

    if (this.props.children) {
      this.offsetTop = element ? element.offsetTop : 0;
    }

    this.props.onRef(this, Boolean(element));
  }

  saveContentRef(element) {
    this.contentElement = element;
  }

  handleKeyDown(evt) {
    // Prevent browser scrolling on space button down event
    if (evt.key === ' ' || evt.key === 'Enter') {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }

  handleKeyUp(evt) {
    // Emulate click on enter/space button up event
    if (evt.key === ' ' || evt.key === 'Enter') {
      this.click(evt);
    }
  }

  handleMouseMove(evt) {
    this.props.onMouse(evt, this);
  }

  handleFocus(evt) {
    this.props.onFocus(evt, this);
  }

  handleClick(evt) {
    // stopPropagation is needed to prevent second trigger of this handler,
    // because we have it on both item and content (to treat dropdown arrow)
    evt.stopPropagation();

    // If user specified onSelect handler, call it.
    const selectResult = this.props.onSelect(evt, this);

    // If onSelect returns false or it's element with sub dropdown - do nothing
    if (selectResult !== false && !this.props.children) {
      this.props.onClick(evt, this);
    }

    return selectResult; // Returning false to Link handler prevents click execution
  }

  focus() {
    if (document.activeElement !== this.itemElement) {
      this.itemElement.focus();
    }
  }

  click(evt) {
    const element = this.contentElement.element || this.contentElement;

    if (element.click) {
      element.click(evt);
    }
  }

  isSomeChildActive(children) {
    return React.Children.toArray(children).some(child => {
      const currentPathname = this.context.router.getCurrentPathname().substring(1);
      const pathName = child.props.link
        ? String(
            typeof child.props.link === 'object'
              ? child.props.link.to
                ? child.props.link.to.split('.')[0]
                : ''
              : child.props.link.split('.')[0],
          )
        : '';
      const isActive = pathName === '' ? false : currentPathname.toLowerCase().startsWith(pathName.toLowerCase());

      return (child.props.children && this.isSomeChildActive(child.props.children)) || (child.props.link && isActive);
    });
  }

  render() {
    const {children, link, text, badge} = this.props;

    let isActive = false;

    if (typeof this.props.isActive === 'boolean') {
      isActive = this.props.isActive;
    } else if (children) {
      // Highlights parent menu item
      isActive = this.isSomeChildActive(children);
    } else if (link) {
      // Highlights menu item
      const currentPathname = this.context.router.getCurrentPathname().substring(1);
      let pathName;

      if (typeof link === 'object') {
        pathName = link?.to?.split('.')[0] || '';
      } else {
        // links under workloads submenu that cause the bug: workloads.list, workloads.containers.list, workloads.vens.list
        pathName = /^workloads\.(vens|containers)\./.test(link) ? link.split('.')[1] : link.split('.')[0];
      }

      isActive = pathName && currentPathname.toLowerCase().startsWith(pathName.toLowerCase());
    }

    const itemProps = _.omit(this.props, [
      'children',
      'link',
      'badge',
      'text',
      'onRef',
      'onFocus',
      'onMouse',
      'onSelect',
      'initiallyFocused',
    ]);

    itemProps.role = 'menuitem';
    itemProps.ref = this.saveRef;
    itemProps.className = cx('Menu-item', {
      'Menu-itemDrill': children,
      'Menu-activeItem': isActive,
      'Menu-activeDropdown': this.state.isFocusedParent,
    });

    itemProps.onKeyUp = this.handleKeyUp;
    itemProps.onKeyDown = this.handleKeyDown;

    itemProps.onFocus = this.handleFocus;
    itemProps.onClick = this.handleClick;
    itemProps.onMouseMove = this.handleMouseMove;

    const contentProps = {
      // Make item content not tabbable (if it's a link <a> in browser has tabindex 0 by default),
      // to prevent a11y-focus-scope from iterating over this node
      tabIndex: '-1',
      ref: this.saveContentRef,
      onClick: this.handleClick,
      className: cx('Menu-itemContent', {'Menu-activeItemContent': isActive}),
    };

    if (link) {
      Object.assign(contentProps, typeof link === 'string' ? {to: link} : link);
    }

    let contentText = text;

    if (badge) {
      const classes = cx({
        'Menu-itemContent-badge--preview': badge === 'preview',
        'Menu-itemContent-badge--new': badge === 'new',
      });

      contentText = (
        <div>
          {text}
          <span className={classes}>
            <Badge type={badge}>{badge}</Badge>
          </span>
        </div>
      );
    }

    const content = createElement(link ? Link : 'div', contentProps, contentText);

    return <li {...itemProps}>{content}</li>;
  }
}
