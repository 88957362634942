/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';

export default React.createClass({
  propTypes: {
    data: PropTypes.array,
    onSelect: PropTypes.func,
    focusedResult: PropTypes.number,
    showResults: PropTypes.bool,
    formatResult: PropTypes.func,
    excludeOptions: PropTypes.arrayOf(PropTypes.string), // Free Trial options to exclude
  },

  getDefaultProps() {
    return {
      data: [],
      focusedResult: -1,
      onSelect: _.noop,
      showResults: false,
      formatResult: null,
      excludeOptions: [],
    };
  },

  componentDidUpdate() {
    this.scrollToFocusedNode();
  },

  scrollToFocusedNode() {
    // Necessary when keyboard nav focuses a node outside of the results window.
    if (this.refs.results && this.refs.focused) {
      const focusedNode = findDOMNode(this.refs.focused);
      const focusedRect = focusedNode.getBoundingClientRect();
      const resultsNode = findDOMNode(this.refs.results);
      const resultsRect = resultsNode.getBoundingClientRect();
      let newScrollTop;

      if (focusedRect.bottom > resultsRect.bottom) {
        newScrollTop = resultsNode.scrollTop + (focusedRect.bottom - resultsRect.bottom);
        resultsNode.scrollTop = newScrollTop;
      } else if (focusedRect.top < resultsRect.top) {
        newScrollTop = resultsNode.scrollTop - (resultsRect.top - focusedRect.top);
        resultsNode.scrollTop = newScrollTop;
      }
    }
  },

  selectResult(idx, evt) {
    this.props.onSelect(idx, evt);
  },

  render() {
    const classes = cx({
      'Typeahead-results': true,
      'Typeahead-results--hidden': !this.props.showResults,
    });

    return (
      <ul ref="results" className={classes} data-tid="comp-select-results">
        {this.props.data.map((d, idx) => {
          if (d === null) {
            return null;
          }

          const disabled = this.props.excludeOptions.includes(d.value || d.policyState);

          const onClick = evt => {
            this.selectResult(idx, evt);
          };
          const isFocused = idx === this.props.focusedResult;
          const resultClasses = cx({
            'Typeahead-result': true,
            'Typeahead-result--focused': isFocused,
            'Typeahead-result--disabled': disabled,
            'Typeahead-result--category': d.category,
          });
          const value = d.label ? d.label : d.value;

          return (
            <li
              ref={isFocused ? 'focused' : undefined}
              className={resultClasses}
              key={idx}
              onClick={onClick}
              data-tid={`comp-select-results-item${d.tid ? `-${d.tid}` : ''}`}
              title={typeof value === 'string' ? value : ''}
            >
              {this.props.formatResult ? this.props.formatResult(d) : value}
            </li>
          );
        })}
      </ul>
    );
  },
});
