/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';
import _ from 'lodash';

const virtualServers = {};
const expandedVirtualServers = {};
const isLoaded = {};

function setVirtualServers(data, version) {
  StoreUtils.setObjects(virtualServers, isLoaded, data, version || 'draft');
}

function setExpandedVirtualServers(data, version) {
  StoreUtils.setObjects(expandedVirtualServers, isLoaded, data, version || 'draft');
}

function setVirtualServer(data, version) {
  StoreUtils.setObjectByVersion(data, version || 'draft', expandedVirtualServers);

  if (version === 'active') {
    const pversion = StoreUtils.getPolicyVersionFromHref(data.href);

    StoreUtils.setObjectByVersion(data, pversion || 'draft', expandedVirtualServers);
  }
}

function getVersionFromHref(href) {
  const parts = href.split('/');

  return parts[parts.length - 3];
}

function getVirtualServer(href, expanded) {
  const version = getVersionFromHref(href);
  const vs = expanded ? expandedVirtualServers : virtualServers;

  if (version === 'active') {
    return _.find(vs[version], vs => vs.href.replace(/\/sec_policy\/\d+/, '/sec_policy/active') === href);
  }

  return _.find(vs[version], vs => vs.href === href);
}

function getVirtualServerByDiscovered(href, version) {
  return _.find(
    virtualServers[version],
    vs => vs.discovered_virtual_server && vs.discovered_virtual_server.href === href,
  );
}

export default createApiStore(['VIRTUAL_SERVERS_'], {
  dispatchHandler(action) {
    let version = 'draft';

    if (action.options && action.options.params) {
      version = action.options.params.pversion || 'draft';
    }

    switch (action.type) {
      case Constants.VIRTUAL_SERVERS_GET_COLLECTION_SUCCESS:
        if (action.options.query.representation) {
          setExpandedVirtualServers(action.data, version);
        } else {
          setVirtualServers(action.data, version);
        }

        isLoaded[version] = true;
        break;

      case Constants.VIRTUAL_SERVERS_GET_INSTANCE_SUCCESS:
        setVirtualServer(action.data, version);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll(version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return virtualServers[version] || [];
  },

  getSpecified(href) {
    return href ? getVirtualServer(href) : null;
  },

  getExpanded(href) {
    return href ? getVirtualServer(href, true) : null;
  },

  getByDiscovered(href, version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return href ? getVirtualServerByDiscovered(href, version) : null;
  },

  isLoaded(version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return isLoaded[version] || false;
  },
});
