/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import dispatcher from '../actions/dispatcher';
import Constants from '../constants';
import TrafficStore from './TrafficStore';
import GraphStore from './GraphStore';
import MapPageStore from './MapPageStore';
import GraphTransformUtils from '../utils/GraphTransformUtils';
import GraphDataUtils from '../utils/GraphDataUtils';

let transform = localStorage.getItem('transform')
  ? JSON.parse(localStorage.getItem('transform'))
  : {
      scale: 0.64,
      translate: [0, 0],
      isInitial: true,
    };

// default mode: select
let interactionType = 'select';
// zoom to fit focused objects
// In location view, the focused is all
// in group view, the focused is one location
// in details view, the focused is location by default
// if expanding groups or clicking a cluster linke, the focused includes source and target groups
let focused;

function setTransform(data) {
  if (!data) {
    return;
  }

  transform.scale = data.scale;
  transform.translate[0] = data.translate[0];
  transform.translate[1] = data.translate[1];
  transform.isInitial = data.isInitial;
}

function setZoomIn(prevTransform) {
  // When zoomIn, the new scale is 120% of the prev Scale
  const scaleFactor = 2;

  transform = {
    scale: prevTransform.scale * scaleFactor,
    translate: GraphTransformUtils.updateTranslate(scaleFactor, prevTransform.translate),
    isInitial: false,
  };
}

function setZoomOut(prevTransform) {
  // When zoomIn, the new scale is 50% of the prev Scale
  // check if min zoom is reached then update
  // only use clusters for appGroup / full map, use locations for everything else
  const currentMapLevel = MapPageStore.getMapLevel();
  const graphData =
    currentMapLevel === 'focusedAppGroup' || currentMapLevel === 'connectedAppGroup' || currentMapLevel === 'full'
      ? GraphStore.getClusters()
      : GraphStore.getLocations();
  const graphLevel =
    currentMapLevel === 'focusedAppGroup' || currentMapLevel === 'connectedAppGroup' || currentMapLevel === 'full'
      ? currentMapLevel
      : 'location';
  const fitScaleFactor = currentMapLevel === 'focusedAppGroup' || currentMapLevel === 'connectedAppGroup' ? 0.3 : 0.5;
  const scaleLimit = GraphTransformUtils.getGraphZoomTofitScale(graphData, graphLevel, fitScaleFactor) || 0;
  const scaleFactor = 0.5;
  const minZoomReached = prevTransform.scale * scaleFactor < scaleLimit;
  const newScale = minZoomReached ? scaleLimit : prevTransform.scale * scaleFactor;
  const newTranslate = minZoomReached
    ? GraphTransformUtils.updateTranslate(scaleLimit / prevTransform.scale, prevTransform.translate)
    : GraphTransformUtils.updateTranslate(scaleFactor, prevTransform.translate);

  // calculate zoomToFit scale is no zoomToFit scale value yet
  const newTransform = {
    scale: newScale,
    translate: newTranslate,
    isInitial: false,
  };

  // don't make it scale too small
  setTransform(newTransform);
}

// the data only exists when clicking a clusterLink in sweet-spot view
function setZoomToFit() {
  const graphData = {
    locations: GraphStore.getLocations(),
    clusters: GraphStore.getClusters(),
    nodes: GraphStore.getNodes(),
    isGraphCalculated: GraphStore.isGraphCalculated(),
    mapLevel: MapPageStore.getMapLevel(),
    mapRoute: MapPageStore.getMapRoute(),
    transform,
  };

  if (
    graphData.isGraphCalculated &&
    (graphData.locations.length || graphData.clusters.length || graphData.nodes.length)
  ) {
    const newTransform = GraphTransformUtils.getZoomToFitTransform(graphData, focused);

    setTransform(newTransform);
  }
}

function getExpandedClusterHref() {
  const expandedClusters = GraphStore.getExpandedClusters();

  return expandedClusters.length ? expandedClusters : null;
}

function setInteractionType(type) {
  interactionType = type;
}

function setFocused() {
  const clusters = GraphStore.getClusters();

  focused = getExpandedClusterHref();

  // If not 2 expanded clusters && there are more than 20 clusters on the screen, zoom to focused cluster
  if (!focused && clusters.length > 20) {
    const expanded = clusters.find(cluster => cluster.displayType === 'full');

    focused = expanded ? [expanded.href] : [];
  }
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.NETWORK_TRAFFIC_GET_SUCCESS:
      case Constants.LOCATION_SUMMARY_GET_SUCCESS:
      case Constants.APP_GROUP_SUMMARY_GET_SUCCESS:
        // The updating of location summary api
        // breaks the remember of positions of filtered locations
        dispatcher.waitFor([TrafficStore.dispatchToken, GraphStore.dispatchToken]);

        if (
          GraphDataUtils.isDataOld(action.data, action.options.query) ||
          (action.options && action.options.query && action.options.query.include_private)
        ) {
          return;
        }

        setFocused();
        setZoomToFit();

        break;

      case Constants.UPDATE_ZOOM_IN:
        setZoomIn(action.data);
        break;

      case Constants.UPDATE_ZOOM_OUT:
        setZoomOut(action.data);
        break;

      case Constants.UPDATE_ZOOM_TO_FIT:
        focused = action.data;
        setZoomToFit();
        break;

      case Constants.UPDATE_TRANSFORM:
        setTransform(action.data);
        break;

      case Constants.EXPAND_CLUSTER:
        dispatcher.waitFor([TrafficStore.dispatchToken, GraphStore.dispatchToken]);
        focused = action.data;
        break;

      case Constants.REMOVE_EXPANDED_CLUSTER:
        dispatcher.waitFor([GraphStore.dispatchToken]);
        break;

      case Constants.EXPAND_ROLE:
        dispatcher.waitFor([TrafficStore.dispatchToken, GraphStore.dispatchToken]);
        setFocused();
        setZoomToFit();
        break;

      case Constants.COLLAPSE_ROLE:
        dispatcher.waitFor([TrafficStore.dispatchToken, GraphStore.dispatchToken]);
        setFocused();
        setZoomToFit();
        break;

      case Constants.ADD_FILTERS:
      case Constants.REMOVE_FILTER:
        dispatcher.waitFor([GraphStore.dispatchToken]);
        setZoomToFit();
        break;

      case Constants.UPDATE_INTERACTION_TYPE:
        setInteractionType(action.data);
        break;

      case Constants.RESET_LAYOUT:
        dispatcher.waitFor([GraphStore.dispatchToken]);
        setZoomToFit();
        break;

      default:
        return true;
    }

    // localStorage the transform
    localStorage.setItem('transform', JSON.stringify(transform));
    this.emitChange();

    return true;
  },

  getTransform: () => transform,
  getInteractionType: () => interactionType,
});
