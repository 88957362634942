/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import update from 'react-addons-update';

// highlighted opacity is 1.0
// notHighlighted opacity is 0.1/0.2
// undefined means no hover on graph
const mouseovered = 'mouseovered';
const highlighted = 'hovered';
const notHighlighted = 'unhovered';

export function hoverCluster(cluster, clusters, clusterLinks) {
  let newClusterLinks;
  let connectedClusters;

  if (!_.isEmpty(clusterLinks)) {
    connectedClusters = {};
    connectedClusters[cluster.href] = true;

    newClusterLinks = _.map(clusterLinks, l => {
      if (l.source.href === cluster.href) {
        connectedClusters[l.target.href] = true;
      } else if (l.target.href === cluster.href) {
        connectedClusters[l.source.href] = true;
      }

      return update(l, {
        $merge: {
          hovered: l.source.href === cluster.href || l.target.href === cluster.href ? highlighted : notHighlighted,
        },
      });
    });
  }

  const isHovered = ({href}, clusters = {}) => {
    if (href === cluster.href) {
      return mouseovered;
    }

    if (clusters[href]) {
      return highlighted;
    }

    return notHighlighted;
  };

  const newClusters = _.map(clusters, c =>
    update(c, {
      $merge: {
        hovered: isHovered(c, connectedClusters),
      },
    }),
  );

  return {
    clusterLinks: newClusterLinks,
    clusters: newClusters,
  };
}

// hover clusterLink do not need make the other groupLinks and groups transparent
export function hoverClusterLink(clusterLink, clusterLinks, clusters, isMultiSelection = false) {
  const newClusterLinks = _.map(clusterLinks, l =>
    update(l, {
      $merge: {
        hovered:
          clusterLink.href === l.href || (isMultiSelection && l.hovered === highlighted) ? highlighted : notHighlighted,
      },
    }),
  );

  const newClusters = _.map(clusters, c =>
    update(c, {
      $merge: {
        hovered:
          c.href === clusterLink.source.href || c.href === clusterLink.target.href ? highlighted : notHighlighted,
      },
    }),
  );

  return {
    clusters: newClusters,
    clusterLinks: newClusterLinks,
  };
}

export function hoverLink(link, links, nodes, clusters, isMultiSelection = false) {
  // update inter app links
  const newLinks = _.map(links, l =>
    update(l, {
      $merge: {
        hovered: link.href === l.href || (isMultiSelection && l.hovered === highlighted) ? highlighted : notHighlighted,
      },
    }),
  );

  // update unconnected node
  const newNodes = _.map(nodes, n => {
    const newNodeLinks = _.map(n.links, l =>
      update(l, {
        $merge: {
          hovered:
            l.href === link.href || (isMultiSelection && l.hovered === highlighted) ? highlighted : notHighlighted,
        },
      }),
    );
    const newNodesInternets = _.map(n.internets, internet =>
      update(internet, {
        $merge: {
          hovered:
            (link.source.href === n.href || link.target.href === n.href) &&
            (internet.type === link.source.type || internet.type === link.target.type)
              ? highlighted
              : notHighlighted,
        },
      }),
    );

    return update(n, {
      $merge: {
        hovered: n.href === link.source.href || n.href === link.target.href ? highlighted : notHighlighted,
        links: newNodeLinks,
        internets: newNodesInternets,
      },
    });
  });

  // update links and nodes inside of app
  const newClusters = _.map(clusters, cluster => {
    const linksInCluster = _.map(cluster.links, l =>
      update(l, {
        $merge: {
          hovered:
            link.href === l.href || (isMultiSelection && l.hovered === highlighted) ? highlighted : notHighlighted,
        },
      }),
    );
    const nodesInCluster = _.map(cluster.nodes, n =>
      update(n, {
        $merge: {
          hovered: link.source.href === n.href || link.target.href === n.href ? highlighted : notHighlighted,
        },
      }),
    );
    const internetsInCluster = _.map(cluster.internets, n =>
      update(n, {
        $merge: {
          hovered:
            n.identifier === link.source.identifier || n.identifier === link.target.identifier
              ? highlighted
              : notHighlighted,
        },
      }),
    );

    return update(cluster, {
      $merge: {
        hovered: undefined,
        nodes: nodesInCluster,
        internets: internetsInCluster,
        links: linksInCluster,
      },
    });
  });

  return {
    clusters: newClusters,
    links: newLinks,
    nodes: newNodes,
  };
}

// hover workloads/roles
export function hoverNode(node, links, nodes, clusters) {
  const connectedNodes = {};
  const nodeHref = node.href;

  connectedNodes[nodeHref] = true;

  // update inter app links
  const newLinks = _.map(links, l => {
    if (l.source.href === nodeHref) {
      connectedNodes[l.target.href] = true;
    } else if (l.target.href === nodeHref) {
      connectedNodes[l.source.href] = true;
    }

    return update(l, {
      $merge: {
        hovered: l.source.href === nodeHref || l.target.href === nodeHref ? highlighted : notHighlighted,
      },
    });
  });

  // update unconnected nodes
  const newNodes = _.map(nodes, n => {
    const newNodeLinks = _.map(n.links, l =>
      update(l, {
        $merge: {
          hovered: n.href === nodeHref ? highlighted : notHighlighted,
        },
      }),
    );
    const newNodesInternets = _.map(n.internets, internet =>
      update(internet, {
        $merge: {
          hovered: n.href === nodeHref ? highlighted : notHighlighted,
        },
      }),
    );

    return update(n, {
      $merge: {
        hovered: connectedNodes[n.href] ? highlighted : notHighlighted,
        selfHovered: n.href === node.href,
        links: newNodeLinks,
        internets: newNodesInternets,
      },
    });
  });

  const newClusters = _.map(clusters, cluster => {
    // update links in clusters
    const newLinksInCluster = _.map(cluster.links, l => {
      if (l.source.href === nodeHref) {
        connectedNodes[l.target.href] = true;
      } else if (l.target.href === nodeHref) {
        connectedNodes[l.source.href] = true;
      }

      return update(l, {
        $merge: {
          hovered: l.source.href === nodeHref || l.target.href === nodeHref ? highlighted : notHighlighted,
        },
      });
    });
    // update nodes in clusters
    const newNodesInCluster = _.map(cluster.nodes, n =>
      update(n, {
        $merge: {
          hovered: connectedNodes[n.href] ? highlighted : notHighlighted,
          selfHovered: n.href === node.href,
        },
      }),
    );

    return update(cluster, {
      $merge: {
        hovered: undefined,
        nodes: newNodesInCluster,
        links: newLinksInCluster,
      },
    });
  });

  return {
    clusters: newClusters,
    links: newLinks,
    nodes: newNodes,
  };
}

export function hoverInternet(internet, links, nodes, clusters) {
  const connectedNodes = {};
  const internetId = internet.identifier;
  const internetClusterParent = internet.cluster && internet.cluster.href;
  const internetNodeParent = internet.node && internet.node.href;

  // update inter app links
  const newLinks = _.map(links, l =>
    update(l, {
      $merge: {
        hovered: notHighlighted,
      },
    }),
  );

  const newNodes = _.map(nodes, n => {
    const newNodesLinks = _.map(n.links, l =>
      update(l, {
        $merge: {
          hovered:
            n.href === internetNodeParent && (l.source.type === internet.type || l.target.type === internet.type)
              ? highlighted
              : notHighlighted,
        },
      }),
    );
    const newNodesInternets = _.map(n.internets, i =>
      update(i, {
        $merge: {
          hovered: internet.identifier === i.identifier && n.href === internetNodeParent ? highlighted : notHighlighted,
          selfHovered: internet.identifier === i.identifier,
        },
      }),
    );

    return update(n, {
      $merge: {
        hovered: internetNodeParent === n.href ? highlighted : notHighlighted,
        links: newNodesLinks,
        internets: newNodesInternets,
      },
    });
  });

  const newClusters = _.map(clusters, cluster => {
    // update links in clusters
    const newLinksInCluster = _.map(cluster.links, l => {
      const isInternetSource = l.source.identifier === internetId && l.source.cluster.href === internetClusterParent;

      const isInternetTarget = l.target.identifier === internetId && l.target.cluster.href === internetClusterParent;

      if (isInternetSource) {
        connectedNodes[l.target.identifier] = true;
      } else if (isInternetTarget) {
        connectedNodes[l.source.identifier] = true;
      }

      return update(l, {
        $merge: {
          hovered: isInternetSource || isInternetTarget ? highlighted : notHighlighted,
        },
      });
    });
    // update node
    const newNodesInCluster = _.map(cluster.nodes, n =>
      update(n, {
        $merge: {
          hovered: connectedNodes[n.href] ? highlighted : notHighlighted,
        },
      }),
    );
    // update internet
    const newInternets = _.map(cluster.internets, n =>
      update(n, {
        $merge: {
          hovered: cluster.href === internetClusterParent && n.identifier === internetId ? highlighted : notHighlighted,
          selfHovered: internet.identifier === n.identifier,
        },
      }),
    );

    // update cluster
    return update(cluster, {
      $merge: {
        hovered: undefined,
        nodes: newNodesInCluster,
        internets: newInternets,
        links: newLinksInCluster,
      },
    });
  });

  return {
    clusters: newClusters,
    links: newLinks,
    nodes: newNodes,
  };
}

export function unhoverClusterAndLinks(clusterLinks, clusters) {
  let newClusterLinks;
  const newClusters = cleanClusters(clusters);

  if (!_.isEmpty(clusterLinks)) {
    newClusterLinks = cleanLinks(clusterLinks);
  }

  return {
    clusterLinks: newClusterLinks,
    clusters: newClusters,
  };
}

// unhover workload/roles/interhet/ipList and link
export function unhoverNodeAndLink(links, nodes, clusters) {
  const newLinks = cleanLinks(links);
  const newNodes = cleanNodes(nodes);
  const newClusters = cleanClusters(clusters);

  return {
    clusters: newClusters,
    links: newLinks,
    nodes: newNodes,
  };
}

// helper function

export function cleanClusters(clusters) {
  const newClusters = _.map(clusters, cluster => {
    // update links in clusters
    const newLinks = _.map(cluster.links, l =>
      update(l, {
        $merge: {
          hovered: undefined,
        },
      }),
    );
    // update node
    const newNodes = _.map(cluster.nodes, n =>
      update(n, {
        $merge: {
          hovered: undefined,
          selfHovered: undefined,
        },
      }),
    );
    // update internet
    const newInternets = _.map(cluster.internets, n =>
      update(n, {
        $merge: {
          hovered: undefined,
          selfHovered: undefined,
        },
      }),
    );

    // update cluster
    return update(cluster, {
      $merge: {
        hovered: undefined,
        nodes: newNodes,
        internets: newInternets,
        links: newLinks,
      },
    });
  });

  return newClusters;
}

// not hightlight inter app links
export function cleanLinks(links) {
  return _.map(links, link =>
    update(link, {
      $merge: {
        hovered: undefined,
      },
    }),
  );
}

// not highlight unconnected nodes
export function cleanNodes(nodes) {
  const newNodes = _.map(nodes, n => {
    const newNodeLinks = _.map(n.links, l =>
      update(l, {
        $merge: {
          hovered: undefined,
        },
      }),
    );
    const newNodesInternets = _.map(n.internets, internet =>
      update(internet, {
        $merge: {
          hovered: undefined,
        },
      }),
    );

    return update(n, {
      $merge: {
        hovered: undefined,
        selfHovered: undefined,
        links: newNodeLinks,
        internets: newNodesInternets,
      },
    });
  });

  return newNodes;
}

export default {
  hoverNode,
  hoverLink,
  cleanNodes,
  cleanLinks,
  hoverCluster,
  hoverInternet,
  cleanClusters,
  hoverClusterLink,
  unhoverNodeAndLink,
  unhoverClusterAndLinks,
};
