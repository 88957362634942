/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import {Button, Icon} from '..';
import intl from '@illumio-shared/utils/intl';

export default React.createClass({
  propTypes: {
    filters: PropTypes.string,
    onAddFilter: PropTypes.func.isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {value: this.props.filter};
  },

  handleChange(evt) {
    this.setState({input: evt.target.value});
  },

  handleKeyDown(evt) {
    switch (evt.key) {
      case 'Enter':
        this.handleAddItem();
        break;
    }
  },

  handleAddItem() {
    const value = this.state.input;

    this.setState({value});
    this.props.onAddFilter((value || '').toLocaleLowerCase());
  },

  handleRemoveItem() {
    this.setState({value: null});
    this.props.onRemoveFilter();
  },

  render() {
    const {value} = this.state;
    const filter = value ? (
      <div className="PolicyGeneratorGridFilter-Value">
        <div className="PolicyGeneratorGridFilter-Value-value">
          {value}
          <Icon name="close" onClick={this.handleRemoveItem} />
        </div>
        <div className="PolicyGeneratorGridFilter-Value-space" />
      </div>
    ) : (
      <input
        placeholder={
          this.props.type === 'ipList'
            ? intl('PolicyGenerator.FilterIpPlaceholder')
            : intl('PolicyGenerator.FilterPlaceholder')
        }
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
      />
    );

    return (
      <div className="PolicyGeneratorGridFilter">
        <div className="PolicyGeneratorGridFilter-Filter">{filter}</div>
        <div>
          <Button text={intl('PolicyGenerator.Find')} onClick={this.handleAddItem} />
        </div>
      </div>
    );
  },
});
