/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import {Navigation} from 'react-router';
import {SessionStore} from '../stores';
import {HealthUtils} from '../utils';
import {Icon} from '.';

const storageCloseKey = 'listenOnly';

export function resetCertWarning() {
  localStorage.removeItem(storageCloseKey);
}

export default React.createClass({
  propTypes: {
    enabledTime: PropTypes.any,
  },

  mixins: [Navigation],

  getDefaultProps() {
    return {
      enabledTime: null,
    };
  },

  handleClose() {
    localStorage.setItem(storageCloseKey, this.props.enabledTime);
    this.setState({close: true});
  },

  handleInfo() {
    this.transitionTo('health.list');
  },

  render() {
    const {enabledTime} = this.props;

    if (!enabledTime || enabledTime === localStorage.getItem(storageCloseKey)) {
      return null;
    }

    const title = intl('Health.PceInListenOnlyMode', {time: HealthUtils.formatDate(enabledTime)});
    const duration = HealthUtils.getDuration(enabledTime);

    const counter = (
      <div className="Reminder-counter">
        <div className="Reminder-counter-time-left">{duration.time}</div>
        {duration.unit}
      </div>
    );

    return (
      <div className="Reminder Reminder--short Reminder--orange">
        {counter}
        <div className="Reminder-listen-only">
          <div className="Reminder-title">{title}</div>
          <span className="Reminder-close" onClick={this.handleClose}>
            <Icon name="close" />
          </span>
          {SessionStore.isHealthEnabled() && (
            <div className="Reminder-link-listen-only" onClick={this.handleInfo}>
              {intl('Health.ViewPCEHealth')}
            </div>
          )}
        </div>
      </div>
    );
  },
});
