/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';

const defaultTid = 'comp-form';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    onSubmit: PropTypes.func,
  },

  handleSubmit(evt) {
    evt.preventDefault();

    if (_.isFunction(this.props.onSubmit)) {
      this.props.onSubmit();
    }
  },

  render() {
    const classes = cx({
      Form: true,
    });

    const tids = this.props.tid ? [defaultTid, this.props.tid].join(' ') : defaultTid || null;

    return (
      <form className={classes} data-tid={tids} onSubmit={this.handleSubmit}>
        {this.props.children}
      </form>
    );
  },
});
