/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PropTypes} from 'react';
import ComponentUtils from '../utils/ComponentUtils';

Tally.propTypes = {
  count: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  type: PropTypes.string,
  tid: PropTypes.string,
};

export default function Tally(props) {
  const classes = cx('Tally-count', {
    'Tally-count--error': props.type === 'error',
    'Tally-count--warning': props.type === 'warning',
    'Tally-count--ok': props.type === 'ok',
  });

  const tids = ComponentUtils.tid('comp-tally', props.tid);

  return (
    <div className="Tally" data-tid={ComponentUtils.tidString(tids)}>
      <span className="Tally-label" data-tid="elem-tally-label">
        {props.label}
      </span>
      <span className={classes} data-tid="elem-tally-count">
        {props.count}
      </span>
    </div>
  );
}
