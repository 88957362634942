/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PropTypes} from 'react';
import ComponentUtils from '../../utils/ComponentUtils';

const defaultTid = 'comp-fieldset';

Fieldset.propTypes = {
  tid: PropTypes.string,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  padded: PropTypes.bool,
  // overrideCSS: override some CSS defintion
  // e.g. override ':not(:firstChild)' definition for 'Form-fieldset'
  // Note: With TesseReact and Formik CSS override can be done with 'themr'
  overrideCSS: PropTypes.bool,
};

export default function Fieldset(props) {
  const classes = cx('Form-fieldset', {
    'Form-fieldset--padded': props.padded,
    'Form-fieldset--marginTop': Boolean(props.overrideCSS),
  });

  const tids = ComponentUtils.tid(defaultTid, props.tid);

  const title = props.title ? (
    <div className="Form-fieldset-title" data-tid="comp-fieldset-title">
      <span data-tid="elem-text">{props.title}</span>
    </div>
  ) : null;

  const subtitle = props.subtitle ? (
    <div className="Form-fieldset-subtitle" data-tid="comp-fieldset-subtitle">
      <span>{props.subtitle}</span>
    </div>
  ) : null;

  return (
    <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
      {title}
      {subtitle}
      <div className="Form-fieldset-content">{props.children}</div>
    </div>
  );
}
