/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {TransitionMotion} from 'react-motion';
import {Component, PropTypes} from 'react';
import Dropdown from './MenuDropdown';
import {shallowEqual} from '../../utils/GeneralUtils';
import ComponentUtils from '../../utils/ComponentUtils';
import {dropdownVertical as dropdownMotions} from './motions';
import _ from 'lodash';

const defaultTid = 'comp-menu';

/**
 * Menu with mouse/key/tab navigation and optional open/close on hover
 */
export default class Menu extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    triggerOnHover: PropTypes.bool, // Open on trigger button hover
    triggerOnHoverOpenDebounce: PropTypes.number, // Delay between hover and opening
    triggerOnHoverCloseTimeout: PropTypes.number, // Delay between menu unhover and closing
    focusItemOnOpen: PropTypes.oneOf(['first', 'last']),
    onSelect: PropTypes.func, // User's handler for select. Return false to cancel
    disableAnimation: PropTypes.bool,

    icon: PropTypes.element,
    tid: PropTypes.string,
  };

  static defaultProps = {
    triggerOnHover: false,
    triggerOnHoverOpenDebounce: 0,
    triggerOnHoverCloseTimeout: 500,
    onSelect: _.noop,
    disableAnimation: false,
  };

  constructor(props) {
    super(props);

    this.state = {open: false, focusItemOnOpen: props.focusItemOnOpen};
    this.subDropdownConfig = [];
  }

  componentWillMount() {
    this.saveRef = this.saveRef.bind(this);
    this.renderSubDropdown = this.renderSubDropdown.bind(this);

    this.handleOpen = this.open.bind(this);
    this.handleClose = this.close.bind(this);
    this.finishOpening = this.finishOpening.bind(this);

    if (this.props.triggerOnHover) {
      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
      this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.open !== nextState.open || !shallowEqual(nextProps, this.props);
  }

  componentDidUpdate() {
    if (this.focusTriggerOnClose) {
      this.trigger.focus();
      this.focusTriggerOnClose = false;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.mouseLeaveTimeout);
  }

  saveRef(trigger) {
    this.trigger = trigger;
  }

  handleKeyDown(evt) {
    switch (evt.key) {
      case ' ':
        evt.preventDefault();
        evt.stopPropagation();
        break;
      case 'Enter':
        evt.preventDefault();
        this.open();
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        evt.preventDefault();
        this.open('last');
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        evt.preventDefault();
        this.open('first');
        break;
    }
  }

  handleKeyUp(evt) {
    if (evt.key === ' ') {
      this.toggle();
    }
  }

  handleMouseEnter(evt) {
    this.clearMouseTimeouts();

    if (!this.state.open) {
      if (evt.target === this.trigger) {
        this.trigger.addEventListener('mousemove', this.handleMouseMove);
      } else {
        this.handleMouseMove();
      }
    }
  }

  handleMouseMove() {
    // Open on hover when mousemove stops, like debounce
    this.clearMouseTimeouts();

    if (this.props.triggerOnHoverOpenDebounce) {
      this.mouseEnterTimeout = setTimeout(this.handleOpen, this.props.triggerOnHoverOpenDebounce, false, true);
    } else {
      this.handleOpen(false, true);
    }
  }

  handleMouseLeave() {
    this.clearMouseTimeouts();

    let closeTimeout = this.props.triggerOnHoverCloseTimeout;

    if (this.opening) {
      closeTimeout = 20;
      this.finishOpening();
    }

    if (closeTimeout) {
      this.mouseLeaveTimeout = setTimeout(this.handleClose, closeTimeout);
    } else {
      this.handleClose();
    }
  }

  clearMouseTimeouts() {
    clearTimeout(this.mouseEnterTimeout);
    clearTimeout(this.mouseLeaveTimeout);
    this.mouseEnterTimeout = this.mouseLeaveTimeout = null;
  }

  open(focusItemOnOpen, byTriggerHover) {
    if (this.state.open) {
      return;
    }

    if (byTriggerHover) {
      this.trackOpening();
    }

    this.trigger.removeEventListener('mousemove', this.handleMouseMove);

    // Animate opening
    this.subDropdownConfig = [
      {
        key: 'dropdown',
        data: this.props.children,
        style: dropdownMotions.open,
      },
    ];

    this.setState({open: true, focusItemOnOpen: focusItemOnOpen || this.state.focusItemOnOpen});
  }

  trackOpening() {
    this.opening = true;
    this.openingTimeout = setTimeout(this.finishOpening, 200);
  }

  finishOpening() {
    this.opening = false;
    clearTimeout(this.openingTimeout);
  }

  close(focusTriggerOnClose) {
    if (this.opening) {
      return;
    }

    // Animate closing
    this.subDropdownConfig = [];

    // Trigger button should be focused
    // if user opened menu without having focus on any other element and is closing menu by pressing Esc
    this.focusTriggerOnClose = focusTriggerOnClose;

    // Reset focused item to props value
    this.setState({open: false, focusItemOnOpen: this.props.focusItemOnOpen});
  }

  toggle() {
    this.clearMouseTimeouts();

    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  handleMouseDown(evt) {
    // Prevent putting focus on trigger button if it's not focuesd yet,
    // to save/restore focus on currently focused element on dropdown closing
    evt.preventDefault();

    if (!this.state.open) {
      // If user wants to open menu, focus trigger to remove focus from other element and blur it to remove glow
      this.trigger.focus();
      this.trigger.blur();
    } else if (evt.target === this.trigger || this.trigger.contains(evt.target)) {
      // If user clicks on trigger button while menu is opened,
      // it should be closed from this Menu component side (by click event),
      // thus we need prevent mouseDown handling on document in MenuDropdown component
      evt.nativeEvent.stopImmediatePropagation();
    }

    this.toggle();
  }

  handleClick(evt) {
    // stopPropagation is needed to prevent second trigger of this handler,
    // because we have it on both item and content (to treat dropdown arrow)
    evt.stopPropagation();

    // If user specified onSelect handler, call it.
    const selectResult = this.props.onSelect(evt, this);

    return selectResult; // Returning false to Link handler prevents click execution
  }

  subEnter() {
    return dropdownMotions.enter;
  }

  subLeave() {
    return dropdownMotions.leave;
  }

  renderSubDropdown(interpolatedStyles) {
    return (
      <span>
        {interpolatedStyles.map(config => (
          <Dropdown
            key={config.key}
            active={this.state.open}
            style={config.style}
            focusItemOnOpen={this.state.focusItemOnOpen}
            onClose={this.handleClose}
          >
            {config.data}
          </Dropdown>
        ))}
      </span>
    );
  }

  render() {
    const {
      props: {triggerOnHover, icon, label, tid, disableAnimation},
    } = this;

    const containerProps = {
      'className': 'Menu',
      'data-tid': ComponentUtils.tidString(ComponentUtils.tid(defaultTid, tid)),
    };

    const triggerProps = {
      'tabIndex': '0',
      'ref': this.saveRef,
      'className': 'Menu-trigger',
      'data-tid': `${defaultTid}-trigger${this.state.open ? 'ed' : ''}`,

      'onClick': this.handleClick,
      'onKeyUp': this.handleKeyUp,
      'onKeyDown': this.handleKeyDown,
      'onMouseDown': this.handleMouseDown,
    };

    if (this.state.open) {
      triggerProps.className = `${triggerProps.className} Menu-trigger--opened`;
    }

    if (triggerOnHover) {
      containerProps.onMouseEnter = this.handleMouseEnter;
      containerProps.onMouseLeave = this.handleMouseLeave;
    }

    return (
      <nav {...containerProps}>
        <div {...triggerProps}>
          <span className="Menu-triggerLabel" data-tid="comp-menu-title">
            {label}
          </span>
          {icon}
        </div>

        {disableAnimation ? (
          this.renderSubDropdown(this.subDropdownConfig)
        ) : (
          <TransitionMotion willEnter={this.subEnter} willLeave={this.subLeave} styles={this.subDropdownConfig}>
            {this.renderSubDropdown}
          </TransitionMotion>
        )}
      </nav>
    );
  }
}
