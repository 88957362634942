/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';

export default function PageNotFound() {
  return (
    <div className="il-static-page">
      <section>
        <div className="il-logo-border">
          <img width="154" height="40" src="static/images/logo.svg" alt="Illumio" />
        </div>
        <h1>{intl('Static.FourOhFour.CouldNotFindPage')}</h1>
        <div>
          {intl('Static.FourOhFour.LetUsKnow', {
            email: 'support@illumio.com',
            a: ([email]) => (
              <a href={`mailto:${email}`} key={email}>
                {email}
              </a>
            ),
          })}
        </div>
      </section>
    </div>
  );
}
