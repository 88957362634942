/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import CommandPanelStore from '../../stores/CommandPanelStore';
import GraphStore from '../../stores/GraphStore';
import actionCreators from '../../actions/actionCreators';
import TitleBarComponent from './TitleBar.jsx';
import AppGroupTitleBarComponent from './AppGroupTitleBar.jsx';
import MultiSelectPanelComponent from './MultiSelectPanel.jsx';
import WorkloadPanelComponent from './WorkloadPanel.jsx';
import RolePanelComponent from './RolePanel.jsx';
import VirtualServerPanelComponent from './VirtualServerPanel.jsx';
import TrafficPanelComponent from './TrafficPanel.jsx';
import InternetPanelComponent from './InternetPanel.jsx';
import TimestampBarComponent from './TimestampBar.jsx';
import ActionBarComponent from './ActionBar.jsx';
import ActionFormComponent from './ActionForm';
import GroupPanelComponent from './GroupPanel.jsx';
import VirtualServicePanelComponent from './VirtualServicePanel.jsx';
import AppGroupTrafficPanelComponent from './AppGroupTrafficPanel.jsx';
import LocationPanelComponent from './LocationPanel.jsx';

let hoverTimer;
let unhoverTimer;

export default React.createClass({
  propTypes: {
    type: PropTypes.oneOf(['workload', 'traffic', 'internet', 'group', 'location', 'virtualService']),
    subType: PropTypes.oneOf(['virtualServer']),
  },

  getInitialState() {
    return CommandPanelStore.getCommandPanel();
  },

  componentDidMount() {
    CommandPanelStore.addChangeListener(this.handleCommandPanelUpdate);
  },

  componentDidUpdate() {
    if (this.state.clearSelection) {
      _.defer(() => {
        actionCreators.unselectComponent();
      });
    }
  },

  componentWillUnmount() {
    CommandPanelStore.removeChangeListener(this.handleCommandPanelUpdate);
  },

  handleCommandPanelUpdate() {
    this.replaceState(CommandPanelStore.getCommandPanel());
  },

  handleHighlightLink(link) {
    const {type, info} = this.state;
    let timeLimit = 200;

    if (type === 'multiSelect' && info.length > 50) {
      timeLimit = 1000;
    }

    hoverTimer = setTimeout(() => {
      actionCreators.updateHoveredComponent(GraphStore.getLink(link.href, link.href));
    }, timeLimit);
  },

  handleUnhighlightLink(link) {
    const {type, info} = this.state;
    let timeLimit = 200;

    if (type === 'multiSelect' && info.length > 50) {
      timeLimit = 1000;
    }

    unhoverTimer = setTimeout(() => {
      actionCreators.updateUnhoveredComponent(GraphStore.getLink(link.href, link.href));
    }, timeLimit);
  },

  handleUpdateTimer() {
    clearTimeout(hoverTimer);
    clearTimeout(unhoverTimer);
  },

  render() {
    const {type, info, actions, form, subType} = this.state;

    if (_.isEmpty(this.state)) {
      return <div className="CommandPanel CommandPanel--hidden" />;
    }

    let titleBar = null;
    let infoPanel = null;
    let actionBar = null;
    let actionForm = null;
    let timeStampBar = null;

    if (info.searchData) {
      titleBar = <AppGroupTitleBarComponent updateMapLevel={this.props.updateMapLevel} data={this.state} />;
    } else {
      titleBar = <TitleBarComponent updateMapLevel={this.props.updateMapLevel} data={this.state} />;
    }

    switch (type) {
      case 'multiSelect':
        infoPanel = (
          <MultiSelectPanelComponent
            data={info}
            hoverTimer
            unhoverTimer
            onHover={this.handleHighlightLink}
            onUnhover={this.handleUnhighlightLink}
          />
        );
        break;
      case 'workload':
        infoPanel = <WorkloadPanelComponent data={info} />;
        break;
      case 'role':
        infoPanel = <RolePanelComponent data={info} />;
        break;
      case 'virtualService':
        if (subType && subType === 'virtualServer') {
          infoPanel = <VirtualServerPanelComponent data={info} />;
        } else {
          infoPanel = <VirtualServicePanelComponent data={info} />;
        }

        break;
      case 'traffic':
        infoPanel = <TrafficPanelComponent data={info} />;
        break;
      case 'appGroupTraffic':
        infoPanel = <AppGroupTrafficPanelComponent data={info} />;
        break;
      case 'group':
      case 'appGroup':
        infoPanel = <GroupPanelComponent type={type} data={info} />;
        break;
      case 'internet':
      case 'ipList':
      case 'fqdn':
        infoPanel = <InternetPanelComponent data={info} />;
        break;
      case 'location':
        infoPanel = <LocationPanelComponent updateMapLevel={this.props.updateMapLevel} data={info} />;
        break;
    }

    if (actions) {
      actionBar = <ActionBarComponent updateMapLevel={this.props.updateMapLevel} data={this.state} />;
    }

    actionForm = <ActionFormComponent updateMapLevel={this.props.updateMapLevel} data={this.state} />;

    if (info.timestamp) {
      timeStampBar = <TimestampBarComponent timestamp={info.timestamp} />;
    }

    const classes = cx('CommandPanel', {'CommandPanel-Form': form});
    const sectionClasses = cx('CommandPanel-Section', {'CommandPanel-Section--hidden': form});
    const formClasses = cx('CommandPanel-Section CommandPanel-Section-Form', {
      'CommandPanel-Section-Form--hidden': !form,
    });

    return (
      <div className={classes}>
        {titleBar ? <div className={sectionClasses}>{titleBar}</div> : null}
        {infoPanel ? (
          <div className="CommandPanel-Section--scroll" onScroll={this.handleUpdateTimer}>
            <div className={sectionClasses}>{infoPanel}</div>
          </div>
        ) : null}
        {timeStampBar ? <div className={sectionClasses}>{timeStampBar}</div> : null}
        {actionBar ? <div className={sectionClasses}>{actionBar}</div> : null}
        {actionForm ? <div className={formClasses}>{actionForm}</div> : null}
      </div>
    );
  },
});
