/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PropTypes} from 'react';

ProgressBar.propTypes = {
  steps: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
};

export default function ProgressBar(props) {
  return (
    <ul className="ProgressBar">
      {props.steps.map((step, idx) => (
        <li key={step} className={cx('ProgressBar-item', {'ProgressBar-item--active': props.active === idx})}>
          {step}
        </li>
      ))}
    </ul>
  );
}
