/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import Dialog from './Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  propTypes: {
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onClose: PropTypes.func,
  },

  getDefaultProps() {
    return {
      title: intl('Common.Alert'),
      onClose: _.noop,
    };
  },

  getActions() {
    return [
      {
        text: intl('Common.OK'),
        tid: 'ok',
        autoFocus: true,
        onClick: () => {
          this.handleClose();

          if (_.isFunction(this.props.onClose)) {
            this.props.onClose();
          }
        },
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    let messages = this.props.message;

    // If message is not an array, coerce it into an array
    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    return (
      <Dialog ref="dialog" className="AlertDialog" alert={true} title={this.props.title} actions={this.getActions()}>
        {messages.map(message => (
          <p key={message} className="Dialog-alert-message">
            {message}
          </p>
        ))}
      </Dialog>
    );
  },
});
