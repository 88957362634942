/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import AddRulePanel from './AddRule/AddRulePanel';
import ViewRulePanel from './AddRule/ViewRulePanel';
import FindGroupPanel from './FindGroupPanel.jsx';
import FormDetailsPanel from './FormDetailsPanel.jsx';

export default React.createClass({
  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(this.props.data, nextProps.data)) {
      return true;
    }

    return false;
  },

  render() {
    let actionForm = <div className="FormPanel-Empty" />;

    if (this.props.data.form && this.props.data.form.type) {
      switch (this.props.data.form.type) {
        case 'addRule':
          actionForm = <AddRulePanel data={this.props.data} />;
          break;

        case 'viewRule':
          actionForm = <ViewRulePanel data={this.props.data} />;
          break;

        case 'findGroup':
        case 'findConnectedGroup':
        case 'findConsumingAppGroup':
        case 'findProvidingAppGroup':
          actionForm = <FindGroupPanel updateMapLevel={this.props.updateMapLevel} data={this.props.data} />;
          break;

        case 'detailAction':
        case 'pbUbAction':
        case 'internetAction':
        case 'vulnerability':
          actionForm = <FormDetailsPanel data={this.props.data} />;
          break;
      }
    }

    return <div className="MapActionBar-FormPanel">{actionForm}</div>;
  },
});
