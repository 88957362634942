/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import GraphPositionUtils from './GraphPositionUtils';

export default {
  getSvg() {
    return d3.select('.svg-illumination').node();
  },

  // Get the scale number if zoom to fit
  zoomToFitScale(graphWidth, graphHeight, scaleFactor) {
    // If no svg, return default scale = 1
    if (!this.getSvg()) {
      return 1;
    }

    // When zoom to fit, always have a padding between the graph and the boarder of svg
    const svgDimension = this.getSvg().getBoundingClientRect();
    const svgWidth = scaleFactor * svgDimension.width;
    const svgHeight = scaleFactor * svgDimension.height;

    // In case graphWidth = 0
    graphWidth = graphWidth === 0 ? 1 : graphWidth;
    graphHeight = graphHeight === 0 ? 1 : graphHeight;

    // Zoom-to-fit based on the min rate of graph width/height with svg width/height
    return _.min([svgWidth / graphWidth, svgHeight / graphHeight]);
  },

  // get the translate x and y if zoom to fit
  zoomToFitTranslate(graphCenterX, graphCenterY, newScale, prevScale, prevTranslate) {
    // If no svg, return default translate [0, 0]
    if (!this.getSvg()) {
      return [0, 0];
    }

    const svgDimension = this.getSvg().getBoundingClientRect();
    const svgCenterX = svgDimension.width / 2;
    const svgCenterY = svgDimension.height / 2;

    // Move to svg center
    const translateX = svgCenterX - graphCenterX + prevTranslate[0];
    const translateY = svgCenterY - graphCenterY + prevTranslate[1];
    let translate = [translateX, translateY];
    // Zoom to svg center
    const scaleFactor = newScale / prevScale;

    translate = this.updateTranslate(scaleFactor, translate);

    return translate;
  },

  getGraphZoomTofitScale(data, mapLevel, scaleFactor) {
    const graphDimension = GraphPositionUtils.calculateGraphDimensions(data, mapLevel);
    const graphMinX = graphDimension.graphMinX;
    const graphMaxX = graphDimension.graphMaxX;
    const graphMinY = graphDimension.graphMinY;
    const graphMaxY = graphDimension.graphMaxY;

    if (mapLevel === 'focusedAppGroup') {
      //the default scale is 0.8
      let newScale = 0.4;

      // But if the selectedCircle size is larger than svg size, do zoomToFit scale
      const width = graphMaxX - graphMinX;
      const height = graphMaxY - graphMinY;
      const svgDimension = this.getSvg().getBoundingClientRect();
      const svgWidth = svgDimension.width;
      const svgHeight = svgDimension.height;

      if (width * newScale >= svgWidth || height * newScale >= svgHeight) {
        newScale = this.zoomToFitScale(width, height, 0.9) * 0.5;
      }

      return newScale;
    }

    // there is only one element, the scale is always 0.64
    return data.length === 1 && mapLevel === 'full'
      ? 0.64
      : this.zoomToFitScale(graphMaxX - graphMinX, graphMaxY - graphMinY, scaleFactor);
  },

  // zoom to fit the whole graph to svg
  fitToWholeGraph(data, mapLevel, scale, translate) {
    const graphDimension = GraphPositionUtils.calculateGraphDimensions(data, mapLevel);
    const graphMinX = graphDimension.graphMinX;
    const graphMaxX = graphDimension.graphMaxX;
    const graphMinY = graphDimension.graphMinY;
    const graphMaxY = graphDimension.graphMaxY;

    let graphCenterX = graphMaxX - (graphMaxX - graphMinX) / 2;
    let graphCenterY = graphMaxY - (graphMaxY - graphMinY) / 2;

    graphCenterX = graphCenterX * scale + translate[0];
    graphCenterY = graphCenterY * scale + translate[1];

    // the default value is 0.9 except 'connectedAppGroup' level
    const scaleFactor = mapLevel === 'connectedAppGroup' ? 0.6 : 0.9;
    // there is only one element, the scale is always 0.64
    const newScale =
      data.length === 1 && mapLevel === 'full'
        ? 0.64
        : this.zoomToFitScale(graphMaxX - graphMinX, graphMaxY - graphMinY, scaleFactor);
    const newTranslate = this.zoomToFitTranslate(graphCenterX, graphCenterY, newScale, scale, translate);

    return {
      scale: newScale,
      translate: newTranslate,
    };
  },

  // zoom to fit to a specific component
  fitToSingleComponent(selectedCircle, scale, translate) {
    const graphCenterX = selectedCircle.x * scale + translate[0];
    const graphCenterY = selectedCircle.y * scale + translate[1];

    let newScale;

    if (selectedCircle.r) {
      newScale = this.zoomToFitScale(selectedCircle.r * 2, selectedCircle.r * 2, 0.9) * 0.9;
    } else {
      // mapLevel === focusedAppGroup, the default scale is 0.8
      newScale = 0.8;

      // But if the selectedCircle size is larger than svg size, do zoomToFit scale
      const width = selectedCircle.width;
      const height = selectedCircle.height;
      const svgDimension = this.getSvg().getBoundingClientRect();
      const svgWidth = svgDimension.width;
      const svgHeight = svgDimension.height;

      if (width * newScale >= svgWidth || height * newScale >= svgHeight) {
        newScale = this.zoomToFitScale(width, height, 0.9) * 0.9;
      }
    }

    const newTranslate = this.zoomToFitTranslate(graphCenterX, graphCenterY, newScale, scale, translate);

    return {
      scale: newScale,
      translate: newTranslate,
    };
  },

  getZoomToFitTransform(data, focus) {
    if (!this.getSvg()) {
      return data.transform;
    }

    const scale = data.transform.scale;
    const translate = data.transform.translate;
    const mapLevel = data.mapLevel;
    const mapRoute = data.mapRoute;
    // give newTransform a default value
    let newTransform = {
      scale,
      translate,
    };

    let selectedCircle;
    let zoomToFitClusters;

    if (mapLevel === 'location') {
      // remove discovery locations when calculate zoom to fit
      const locations = data.locations.filter(({href}) => href !== 'discovered' && href !== 'no_location');

      if (locations.length) {
        newTransform = this.fitToWholeGraph(locations, mapLevel, scale, translate);
      }
    } else if (mapLevel === 'group' && mapRoute.type === 'location' && !_.isEmpty(data.locations)) {
      selectedCircle = _.find(data.locations, location => location.focused);
      newTransform = this.fitToSingleComponent(selectedCircle, scale, translate);
    } else if (mapLevel === 'workload') {
      if (focus && focus.source && focus.target) {
        zoomToFitClusters = [focus.source, focus.target];
        newTransform = this.fitToWholeGraph(zoomToFitClusters, mapLevel, scale, translate);
      } else if (focus && focus.type === 'location') {
        newTransform = this.fitToSingleComponent(focus, scale, translate);
      } else if (focus) {
        // zoomToFit to the expanded clusters
        zoomToFitClusters = _.filter(data.clusters, c => focus.includes(c.href));

        // if there are two expanded clusters, then zoom-to-fit to these two clusters
        // if another expanded cluster has been filtered, then zoom to fit to the focused location.
        if (zoomToFitClusters.length > 1) {
          newTransform = this.fitToWholeGraph(zoomToFitClusters, mapLevel, scale, translate);
        } else {
          if (zoomToFitClusters.length) {
            selectedCircle = zoomToFitClusters[0];
          } else {
            selectedCircle = _.find(data.locations, location => location.focused);
          }

          newTransform = this.fitToSingleComponent(selectedCircle, scale, translate);
        }
      } else {
        // zoomToFit to one location when in sweet-spot view
        selectedCircle = _.find(data.locations, location => location.focused);

        if (!selectedCircle) {
          return {
            scale: newTransform.scale,
            translate: newTransform.translate,
            isInitial: false,
          };
        }

        newTransform = this.fitToSingleComponent(selectedCircle, scale, translate);
      }
    } else if (mapLevel === 'full' && (data.clusters.length || data.nodes.length)) {
      const nodesInternets = [];

      _.each(data.nodes, node => {
        if (node.internets) {
          _.each(node.internets, internet => {
            nodesInternets.push({
              x: node.x + internet.x,
              y: node.y + internet.y,
              width: node.width,
              height: node.height,
            });
          });
        }
      });

      const unionData = _.union(data.clusters, data.nodes, nodesInternets);

      newTransform = this.fitToWholeGraph(unionData, mapLevel, scale, translate);
    } else if (mapLevel === 'focusedAppGroup') {
      selectedCircle = _.find(data.clusters, cluster => cluster.focused);
      newTransform = this.fitToSingleComponent(selectedCircle, scale, translate);
      // Adjust the scale down a touch for the app group map
      newTransform.scale = newTransform.scale * 0.9;
    } else if (mapLevel === 'connectedAppGroup') {
      zoomToFitClusters = data.clusters;
      newTransform = this.fitToWholeGraph(zoomToFitClusters, mapLevel, scale, translate);
      newTransform.scale = newTransform.scale * 0.9;

      // Adjust the Y translate based on the type of connected group
      if (data.mapRoute.type === 'providing') {
        newTransform.translate[1] = newTransform.translate[1] * 1.1;
      } else {
        newTransform.translate[1] = newTransform.translate[1] * 0.8;
      }
    }

    return {
      scale: newTransform.scale,
      translate: newTransform.translate,
      isInitial: false,
    };
  },

  // Any zoom is based on the svg center
  // scaleFactor is the percentage/100 of the prevScale
  // scaleFactor = 1.2 when zoom in, 0.8 when zoom out.
  updateTranslate(scaleFactor, prevTranslate) {
    // If no svg, return default translate [0, 0]
    if (!this.getSvg()) {
      return [0, 0];
    }

    const svgDimension = this.getSvg().getBoundingClientRect();
    const svgCenterX = svgDimension.width / 2;
    const svgCenterY = svgDimension.height / 2;
    const translateX = prevTranslate[0] * scaleFactor + (1 - scaleFactor) * svgCenterX;
    const translateY = prevTranslate[1] * scaleFactor + (1 - scaleFactor) * svgCenterY;

    return [translateX, translateY];
  },
};
