/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import Constants from '../constants';
import {createApiStore} from '../lib/store';

let connectivityRules = {
  draft: [],
  active: [],
};
let isLoaded = false;

function clearConnectivity() {
  connectivityRules = {
    draft: [],
    active: [],
  };
  isLoaded = false;
}

function setConnectivity(data, version) {
  connectivityRules[version] = data;
  isLoaded = true;
}

export default createApiStore(['SEC_POLICY_ALLOWS_'], {
  dispatchHandler(action) {
    let version = 'draft';

    if (action.options && action.options.params) {
      version = action.options.params.pversion || 'draft';
    }

    switch (action.type) {
      case Constants.SEC_POLICY_ALLOWS_GET_COLLECTION_SUCCESS:
        setConnectivity(action.data, version);
        break;

      case Constants.CLEAR_CONNECTIVITY:
        clearConnectivity();
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return connectivityRules;
  },

  isLoaded() {
    return isLoaded;
  },
});
