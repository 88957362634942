/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
/* eslint-disable no-unused-vars */
// no-extra-parens:      Code highlighting breaks without parenthesis
// no-use-before-define: Allow defining all the Helpers at the bottom

/***
 * Response Cache looks if the http_method is not GET for
 * invalidating existing caches. However, in the PCE API, some GET APIs are
 * disguised as POST (in order to prevent really long GET url query), so those
 * API endpoint's resource and method can be added here so that they're
 * treated as a GET.
 */
export const modificationExceptions = [['sec_policy_rule_search', 'create']];

/**
 * Object to store custom cache clear functions.
 * Putting a method here is optional. By default the cache is cleared
 * whenever anything is modified in the PCE.
 *
 */
export const cacheClearFunctions = {};

/** ------------------------------------------------------- **/
/** Helpers for cache clear functions defined at the bottom **/
/** ------------------------------------------------------- **/

/**
 * Function to tell if the method is one of 'create' or 'delete'
 *
 * @param  {string}   method - Name of the method called
 * @return {boolean}
 */
const isCreateOrDelete = method => ['create', 'delete'].includes(method);

/**
 * Function to tell if the method is one of 'update' or 'delete'
 *
 * @param  {string}   method - Name of the method called
 * @return {boolean}
 */
const isUpdateOrDelete = method => ['update', 'delete'].includes(method);

/**
 * Function to tell if a Sec Policy (Provisioning items) was changed
 *
 * @param  {string}   resource - Name of the resource called
 * @param  {string}   method   - Name of the method called
 * @return {boolean}
 */
const secPolicyChanged = (resource, method) => resource === 'sec_policies' && isCreateOrDelete(method);

/**
 * Function to tell if a Rule was changed
 *
 * @param  {string}   resource - Name of the resource called
 * @param  {string}   method   - Name of the method called
 * @return {boolean}
 */
const ruleChanged = (resource, method) =>
  (resource === 'sec_rule' && isUpdateOrDelete(method)) || (resource === 'sec_rules' && method === 'create');

/**
 * Function to tell if a Ruleset was changed
 *
 * @param  {string}   resource - Name of the resource called
 * @param  {string}   method   - Name of the method called
 * @return {boolean}
 */
const ruleSetChanged = (resource, method) =>
  (resource === 'rule_set' && isUpdateOrDelete(method)) || (resource === 'rule_sets' && isCreateOrDelete(method));
