/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import Icon from './Icon.jsx';
import ResultList from './ResultList.jsx';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-textcombobox';

export default React.createClass({
  propTypes: {
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    showOptionsOnFocus: PropTypes.bool,
    allowCustomValue: PropTypes.bool,
    allowEmptyValue: PropTypes.bool,
    clearOnFocus: PropTypes.bool,
    selectOnFocus: PropTypes.bool,
    showEmptyValueLabel: PropTypes.bool,
    textNoResults: PropTypes.string,
    tid: PropTypes.string,
  },

  getDefaultProps() {
    return {
      tabIndex: '0',
      autoFocus: false,
      disabled: false,
      onChange: _.noop,
      value: '',
      options: [],
      showOptionsOnFocus: false,
      allowCustomValue: true,
      allowEmptyValue: true,
      clearOnFocus: false,
      selectOnFocus: false,
      showEmptyValueLabel: false,
      textNoResults: 'No results found',
      placeholder: '',
      tid: '',
    };
  },

  getInitialState() {
    const value = this.resetValue(this.props.options, this.props.value);
    const focusedOption = _.findIndex(this.props.options, option => option.value === this.props.value);

    return {
      focusedOption,
      options: this.props.options,
      value,
      focused: false,
    };
  },

  componentWillReceiveProps(nextProps) {
    const value = this.resetValue(nextProps.options, nextProps.value);

    this.setState({value});
  },

  handleCaretClick() {
    if (this.props.disabled) {
      return;
    }

    this.disableFocus = true;
    this.focusInput();
    this.disableFocus = false;
    this.setState({showOptions: true, options: this.props.options});
  },

  handleClick() {
    this.focusInput();
  },

  handleInputBlur() {
    if (this.props.disabled) {
      return;
    }

    if (this.disableBlur) {
      this.disableFocus = true;
      this.focusInput();
      this.disableFocus = false;

      return;
    }

    this.checkValue();
  },

  handleInputChange(evt) {
    if (this.props.disabled) {
      return;
    }

    const value = evt.target.value;
    let options = this.props.options;

    if (value) {
      options = _.filter(options, option => option.label.toLowerCase().includes(value.toLowerCase()));
    }

    this.setState({value, showOptions: true, options});
  },

  handleInputFocus() {
    if (this.disableFocus || this.props.disabled) {
      return;
    }

    const newState = {focused: true};

    if (this.props.clearOnFocus) {
      newState.value = '';
    }

    if (this.props.selectOnFocus) {
      if (this.refs.input) {
        this.refs.input.select();
      }
    }

    if (this.props.showOptionsOnFocus) {
      newState.showOptions = true;
      newState.options = this.props.options;
      newState.focusedOption = 0;
    }

    if (!_.isEmpty(newState)) {
      this.setState(newState);
    }
  },

  handleInputKeyDown(event) {
    event.stopPropagation();

    if (this.props.disabled) {
      return;
    }

    const newState = {};
    const focusedOption = this.state.showOptions ? this.state.focusedOption : null;
    const numOptions = this.state.options.length;

    switch (event.key) {
      case 'Escape':
        this.blurInput();
        break;

      case 'ArrowDown':
        event.preventDefault();

        if (!this.state.showOptions) {
          newState.showOptions = true;
        }

        if (focusedOption === null || focusedOption === numOptions - 1) {
          newState.focusedOption = 0;
        } else {
          newState.focusedOption = focusedOption + 1;
        }

        break;

      case 'ArrowUp':
        event.preventDefault();

        if (!this.state.showOptions) {
          newState.showOptions = true;
        }

        if (focusedOption === null || focusedOption === 0) {
          newState.focusedOption = numOptions - 1;
        } else {
          newState.focusedOption = focusedOption - 1;
        }

        break;

      case 'Enter':
        event.preventDefault();

        if (focusedOption !== null && this.state.options.length) {
          newState.showOptions = false;
          newState.focusedOption = null;
          this.handleResultsSelect(focusedOption);
        } else {
          this.checkValue();
        }

        break;
    }

    this.setState(newState);
  },

  handleResultsMouseDown(evt) {
    evt.preventDefault();
    this.disableBlur = true;
  },

  handleResultsMouseUp(evt) {
    evt.preventDefault();
    this.disableBlur = false;
  },

  handleResultsSelect(idx) {
    const option = this.state.options[idx];
    let value = '';

    if (option) {
      this.sendChangeEvent(option.value);

      if (option.value !== '' || (this.props.showEmptyValueLabel && option.value === '')) {
        value = option.label;
      } else {
        value = option.value;
      }
    }

    this.setState({focusedOption: idx, showOptions: false, value, options: this.props.options});
  },

  disableBlur: false,

  disableFocus: false,

  blurInput() {
    if (this.refs.input) {
      findDOMNode(this.refs.input).blur();
    }
  },

  checkValue() {
    const option = _.find(this.props.options, option => this.state.value === option.label);

    if (option) {
      this.sendChangeEvent(option.value);
    } else if (this.props.allowCustomValue || (this.props.allowEmptyValue && this.state.value === '')) {
      this.sendChangeEvent(this.state.value);
    } else {
      const value = this.resetValue(this.props.options, this.props.value);

      this.setState({value});
    }

    this.setState({showOptions: false, options: this.props.options, focused: false});
  },

  focusInput() {
    if (this.refs.input) {
      findDOMNode(this.refs.input).focus();
    }
  },

  resetValue(options, value) {
    const option = _.find(options, option => value === option.value);

    if (option && (value !== '' || (this.props.showEmptyValueLabel && value === ''))) {
      return option.label;
    }

    return value;
  },

  sendChangeEvent(value) {
    if (value !== this.props.value) {
      this.props.onChange(value);
    }
  },

  render() {
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    const comboClasses = cx({
      'TextCombobox': true,
      'TextCombobox--disabled': this.props.disabled,
      'TextCombobox--focus': this.state.focused,
      'TextCombobox--open': this.state.showOptions,
    });

    let results = <div className="TextCombobox-noresults">{this.props.textNoResults}</div>;

    if (this.state.options.length > 0) {
      results = (
        <ResultList
          data={this.state.options}
          onSelect={this.handleResultsSelect}
          focusedResult={this.state.focusedOption}
          showResults={this.state.showOptions}
        />
      );
    }

    return (
      <div data-tid={ComponentUtils.tidString(tids)} className={comboClasses} onClick={this.handleClick}>
        <div className="TextCombobox-input">
          <input
            type="text"
            className="TextCombobox-input-field"
            tabIndex={this.props.tabIndex}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            onChange={this.handleInputChange}
            onKeyDown={this.handleInputKeyDown}
            value={this.state.value}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            ref="input"
            data-tid="comp-combobox-input"
          />
          <span
            className="TextCombobox-input-caret"
            onClick={this.handleCaretClick}
            onMouseDown={evt => evt.preventDefault()}
            onMouseUp={evt => evt.preventDefault()}
          >
            <Icon name="dropdown" />
          </span>
        </div>
        <div
          className="TextCombobox-results"
          onMouseDown={this.handleResultsMouseDown}
          onMouseUp={this.handleResultsMouseUp}
        >
          {results}
        </div>
      </div>
    );
  },
});
