/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import Constants from '../constants';
import _ from 'lodash';

const dialogs = [];
let errorDialog = false;

const openDialog = function (modal) {
  dialogs.push(modal);
};

const closeDialog = function () {
  dialogs.pop();
  errorDialog = false;
};

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.OPEN_DIALOG:
        openDialog(action.data);
        break;

      case Constants.CLOSE_DIALOG:
        closeDialog();

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getActiveDialog() {
    //render all dialogs in background hidden to prevent loss of local state
    // TODO: Kill for components in stores
    return _.map(dialogs, (dialog, idx) => (
      <div key={idx} style={{display: idx === dialogs.length - 1 ? 'block' : 'none'}}>
        {dialog}
      </div>
    ));
  },

  // Only called from other stores
  openDialog(data, error) {
    if (!error || !errorDialog) {
      openDialog(data);

      if (error) {
        errorDialog = true;
      }
    }

    this.emitChange();
  },

  closeDialog() {
    closeDialog();
    this.emitChange();
  },
});
