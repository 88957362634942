/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import intl from '@illumio-shared/utils/intl';
import {DateTimePicker} from '../DateTime';
import {Select} from '../FormComponents';

// Returns the time options for on prem environments
// `label` for display only and <Select> returns `value` on change
export const getDefaultTimeOptions = () => [
  {label: intl('Explorer.LastHours', {count: 1}), value: intl('Explorer.LastHours', {count: 1}), key: '1 hour'},
  // displayed as "Last 24 hours" instead of "Last Day" for clarification
  {label: intl('Explorer.LastHours', {count: 24}), value: intl('Explorer.LastDays', {count: 1}), key: '1 day'},
  {label: intl('Explorer.LastWeeks', {count: 1}), value: intl('Explorer.LastWeeks', {count: 1}), key: '1 week'},
  {label: intl('Explorer.LastMonths', {count: 1}), value: intl('Explorer.LastMonths', {count: 1}), key: '1 month'},
  {label: intl('Explorer.CustomRange'), value: intl('Explorer.CustomRange'), key: 'custom'},
  {label: intl('DateTimeInput.Anytime'), value: intl('DateTimeInput.Anytime'), key: 'static'},
];

// Returns the time options for SAAS environments
export const getSAASTimeOptions = limitToMaxDays => [
  {label: intl('Explorer.LastHours', {count: 1}), value: intl('Explorer.LastHours', {count: 1}), key: '1 hour'},
  {label: intl('Explorer.LastDays', {count: 1}), value: intl('Explorer.LastDays', {count: 1}), key: '1 day'},
  // if limit is 7 days or more, show the 1 week option
  ...(limitToMaxDays >= 7
    ? [{label: intl('Explorer.LastWeeks', {count: 1}), value: intl('Explorer.LastWeeks', {count: 1}), key: '1 week'}]
    : []),
  // if limit is 30 days or more, show the 1 month option
  ...(limitToMaxDays >= 30
    ? [{label: intl('Explorer.LastMonths', {count: 1}), value: intl('Explorer.LastMonths', {count: 1}), key: '1 month'}]
    : []),
  {label: intl('Explorer.CustomRange'), value: intl('Explorer.CustomRange'), key: 'custom'},
];

// Returns true if the time option is included in the time options list
// Used to disable 'go' when previously selected value is not in the new options list
export const timeOptionExistsInOptions = (timeOption = '', options = []) => {
  // If timeOption is custom, make sure "custom" is allowed in the available options
  if (timeOption?.startsWith('From: ') && options?.some(entry => entry.key === 'custom')) {
    return true;
  }

  return options?.some(entry => entry.label === timeOption);
};

export default React.createClass({
  propTypes: {
    time: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.func,
    limitCustomRangeToMaxDays: PropTypes.number,
  },

  getDefaultProps() {
    return {
      options: getDefaultTimeOptions,
      limitCustomRangeToMaxDays: null,
    };
  },

  getInitialState() {
    return {showCustom: false};
  },

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  },

  formatSelection(value) {
    if (this.props.time.toLocaleLowerCase().includes('to')) {
      return this.props.time;
    }

    return value.label;
  },

  getDateFromValue(key) {
    const now = new Date();

    switch (key) {
      case '1 hour':
        return intl.utils.subtractTime(now, 'h', 1);
      case '1 day':
        return intl.utils.subtractTime(now, 'd', 1);
      case '1 week':
        return intl.utils.subtractTime(now, 'w', 1);
      case '1 month':
        return intl.utils.subtractTime(now, 'M', 1);
      case 'static':
        return intl.utils.subtractTime(now, 'y', 5);
    }
  },

  handleClick(evt) {
    // The if check is to ensure that on clicking any
    // which does not belong to custom input close the date time custom range picker
    // we are using className.includes() here instead of classList.contains(), since for example
    // we are looking for a subset of the string 'Option-Label' instead of the entire string 'ReForm-ResultList-Option-Label' and classList
    // contains an array with the entire string and won't return the same results as className.includes()
    const className = evt.target.className;

    if (className && typeof className === 'string') {
      if (
        (className.includes('Icon-ObjectSelector-down-arrow') &&
          (!evt.path || !evt.path.some(path => path.className === 'DTPicker-Section-Input'))) ||
        className.includes('ObjectSelector-input-elem') ||
        (className.includes('ObjectSelector-item') &&
          !findDOMNode(this).contains(evt.target) &&
          !className.includes('Icon-ObjectSelector-item-value')) ||
        !(
          findDOMNode(this).contains(evt.target) ||
          (typeof className !== 'object' &&
            (className.includes('Option-Label') ||
              className.includes('ObjectSelector') ||
              className.includes('DTPicker')))
        )
      ) {
        // If the click is outside the options or the DTPicker and Custom Range is chosen, set back to one day
        if (this.props.time === intl('Explorer.CustomRange')) {
          this.props.onChange(intl('Explorer.LastDays', {count: 1}));
        }

        this.setState({showCustom: false});
      }
    }
  },

  handleApply() {
    this.setState({showCustom: false});
  },

  handleAdd(name, value, items) {
    let from = Object.keys(items.from)[0];
    let to = Object.keys(items.to)[0];

    from = from === intl('DateTimeInput.CustomTime') ? intl.date(new Date(value.from), 'l_HH_mm') : from;
    to = to === intl('DateTimeInput.CustomTime') ? intl.date(new Date(value.to), 'l_HH_mm') : to;

    const time = `${intl('DateTimeInput.From')}: ${from} ${intl('DateTimeInput.To')}: ${to}`;

    // Return the time in string format and the value object that contain the dates
    this.props.onChange(time, value);
    this.setState({showCustom: false});
  },

  handleChange(time) {
    this.props.onChange(time);
    this.setState({showCustom: time === intl('Explorer.CustomRange')});
  },

  handleRemove() {
    this.setState({showCustom: false});
  },

  handleBlur() {
    if (this.props.time === intl('Explorer.CustomRange')) {
      return;
    }

    if (this.props.time === intl('DateTimeInput.CustomTime')) {
      this.props.onChange(intl('Explorer.LastDays', {count: 1}));
    }

    this.setState({showCustom: false});
  },

  render() {
    const {limitCustomRangeToMaxDays} = this.props;
    const dateTimePickerProps = {
      name: 'explorer',
      onAdd: this.handleAdd,
      onRemove: this.handleRemove,
      fromSingleValues: {
        [intl('DateTimeInput.CustomTime')]: 'custom',
      },
      toSingleValues: {
        [intl('DateTimeInput.CustomTime')]: 'custom',
      },
      getDateFromValue: this.getDateFromValue,
      autoLoadCustom: true,
      preSelectFrom: true,
      ...(limitCustomRangeToMaxDays && {limitCustomRangeToMaxDays}),
    };

    const {options} = this.props;

    let selectOptions = [...options()];

    if (this.props.noCustom) {
      selectOptions = selectOptions.filter(
        option => option.value !== intl('Explorer.CustomRange') && option.value !== intl('DateTimeInput.Anytime'),
      );
    }

    return (
      <div className="ExplorerFilter" onBlur={this.handleBlur}>
        <div className="ExplorerFilter-Filter">
          <Select
            options={selectOptions}
            value={
              this.props.time && this.props.time.includes(intl('DateTimeInput.To'))
                ? intl('Explorer.CustomRange')
                : this.props.time
            }
            onChange={this.handleChange}
            formatSelection={this.formatSelection}
            tid="time"
          />
        </div>
        {this.state.showCustom ? <DateTimePicker {...dateTimePickerProps} /> : null}
      </div>
    );
  },
});
