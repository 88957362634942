/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import fileSaver from 'file-saver';
import {GeneralStore, DNSStore, ExplorerStore, SessionStore} from '../../stores';
import {EditLabelsDialog} from '../../modals';
import {StoreMixin, UserMixin} from '../../mixins';
import {RestApiUtils, GridDataUtils, PolicyGeneratorUtils, ServiceUtils} from '../../utils';
import actionCreators from '../../actions/actionCreators';
import ExplorerActions from '../../actions/ExplorerActions';
import {ExportUtils} from '../../utils/Explorer';
import {ToolBar, ToolGroup} from '../ToolBar';
import {Button, Pagination, Grid, SpinnerOverlay, HoverMenu, ExpandableGridDataList} from '..';
import PolicyGeneratorAddressesDialog from '../../modals/PolicyGeneratorAddressesDialog';

const MAX_RESULTS_PER_PAGE = 50;

const consumerAndProviderMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeConsumers'), type: 'consumerInclude', tid: 'includeConsumers'},
  {enTranslation: intl('Explorer.ExcludeConsumers'), type: 'consumerExclude', tid: 'excludeConsumers'},
  {enTranslation: intl('Explorer.IncludeProviders'), type: 'providerInclude', tid: 'includeProviders'},
  {enTranslation: intl('Explorer.ExcludeProviders'), type: 'providerExclude', tid: 'excludeProviders'},
];

const consumerOrProviderMenuItems = () => [
  {
    enTranslation: intl('Explorer.IncludeConsumersOrProviders'),
    type: 'consumerOrProviderInclude',
    tid: 'includeConsumersOrProviders',
  },
  {
    enTranslation: intl('Explorer.ExcludeConsumersOrProviders'),
    type: 'consumerOrProviderExclude',
    tid: 'excludeConsumersOrProviders',
  },
];

const transmissionAndFQDNMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeProviders'), type: 'providerInclude', tid: 'includeProviders'},
  {enTranslation: intl('Explorer.ExcludeProviders'), type: 'providerExclude', tid: 'excludeProviders'},
];

const portProtocolMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeServices'), type: 'portsInclude', tid: 'includeServices'},
  {enTranslation: intl('Explorer.ExcludeServices'), type: 'portsExclude', tid: 'excludeServices'},
];

const transmissionOrMenuItem = () => [
  {
    enTranslation: intl('Explorer.ExcludeConsumersOrProviders'),
    type: 'consumerOrProviderExclude',
    tid: 'excludeConsumersOrProviders',
  },
];

function getStateFromStores() {
  const orValue =
    (JSON.parse(localStorage.getItem('tx_filters')) && JSON.parse(localStorage.getItem('tx_filters')).Or) ||
    (_.cloneDeep(ExplorerStore.getFilters()) && _.cloneDeep(ExplorerStore.getFilters()).Or) ||
    false;

  return {
    dnsAddresses: DNSStore.getAllIPAddresses(),
    andOrValue: orValue ? 'or' : 'and',
  };
}

export default React.createClass({
  mixins: [UserMixin, StoreMixin([DNSStore], getStateFromStores)],

  getInitialState() {
    const type = ExplorerStore.getExplorerType();
    const selection = GeneralStore.getSelection(`domainList${type}`);
    const sorting = GeneralStore.getSorting(`domainList${type}`);

    return {
      selection: selection || [],
      sorting: sorting || [{key: 'address', direction: false}],
      currentPage: 1,
      explorerType: type,
      virtualServiceEdit: SessionStore.isVirtualServiceEditEnabled(),
    };
  },

  componentDidMount() {
    ExplorerStore.addAndOrActionListener(this.handleAndOrSelection);
  },

  componentWillReceiveProps(nextProps) {
    const type = ExplorerStore.getExplorerType();

    if (type !== this.state.explorerType) {
      const selection = GeneralStore.getSelection(`domainList${type}`);
      const sorting = GeneralStore.getSorting(`domainList${type}`);

      return {
        selection: selection || [],
        sorting: sorting || [{key: 'address', direction: false}],
        currentPage: 1,
        explorerType: type,
        virtualServiceEdit: SessionStore.isVirtualServiceEditEnabled(),
      };
    }

    if (!_.isEqual(nextProps.domainTraffic, this.props.domainTraffic)) {
      this.setState({currentPage: 1});
    }
  },

  componentWillUnmount() {
    ExplorerStore.removeAndOrActionListener(this.handleAndOrSelection);
  },

  handleAndOrSelection() {
    this.setState({andOrValue: ExplorerStore.getAndOrValue()});
  },

  getDnsValues(links) {
    // we will only look up dns names on demand for all links (instead of only visible links of current page)
    const visibleLinks = links;
    const dnsAddresses = this.state.dnsAddresses;

    const addresses = visibleLinks.reduce((addresses, link) => {
      if (!dnsAddresses[link.address]) {
        addresses.push(link.address);
      }

      return addresses;
    }, []);

    if (addresses.length) {
      RestApiUtils.dns.dnsReverseLookup({ips: _.uniq(addresses)}).then(() => {
        _.defer(() => {
          ExplorerActions.interruptDnsLookup({value: false});
        });
      });
    }
  },

  getVisibleLinks(links, page, sorting) {
    const sort = (sorting || this.state.sorting)[0];
    const offset = ((page || this.state.currentPage) - 1) * MAX_RESULTS_PER_PAGE;

    let visibleLinks = [];

    if (links) {
      visibleLinks = _.sortBy(links, link => {
        switch (sort.key) {
          case 'ports':
            return link[sort.key].join(', ');
          case 'workloads':
            return link[sort.key].length;
          default:
            return link[sort.key];
        }
      });

      if (sort.direction) {
        visibleLinks.reverse();
      }

      visibleLinks = visibleLinks.slice(offset, offset + MAX_RESULTS_PER_PAGE);
    }

    return visibleLinks;
  },

  createWorkload(node, labels) {
    return {
      name: this.state.dnsAddresses[node.address] || node.address,
      hostname: this.state.dnsAddresses[node.address] || node.address,
      ip: node.address,
      labels,
      interfaces: [
        {
          name: 'eth0',
          address: node.address,
        },
      ],
    };
  },

  createVirtualService(node, labels) {
    let matchedService;

    if (node && node.newLink && node.newLink.service) {
      const selectedService = {
        port: node.newLink.service.port,
        protocol: node.newLink.service.proto,
        osType: node.newLink.service.windows_service_name ? 'windows' : 'linux',
        processName: node.newLink.service.process_name,
        serviceName: node.newLink.service.windows_service_name,
      };

      matchedService = PolicyGeneratorUtils.buildService(selectedService);
    }

    return {
      name: `${node.domain}-${node.newLink.service.port}-${ServiceUtils.lookupProtocol(node.newLink.service.proto)}`,
      address: node.domain,
      serviceport: `${node.newLink.service.port}-${ServiceUtils.lookupProtocol(node.newLink.service.proto)}`,
      matchedService: matchedService && matchedService.length && matchedService[0],
      apply_to: 'host_only',
      description: '',
      ip_overrides: [],
      service: {href: matchedService && Array.isArray(matchedService) && matchedService[0] && matchedService[0].href},
      service_ports: [{port: node.newLink.service.port, proto: node.newLink.service.proto}],
      labels,
      service_addresses: [
        {
          fqdn: node.domain,
          description: '',
        },
      ],
    };
  },

  handleSelectToggle(selection, lastSelected = selection) {
    const newSelection = GridDataUtils.selectToggle(this.state.selection, selection);

    actionCreators.updateGeneralSelection('domainList', newSelection);
    this.setState({selection: newSelection, lastSelected});
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('domainList', sorting);
    this.setState({sorting});
  },

  handlePageChange(page) {
    this.setState({
      currentPage: page,
      selection: [],
    });
  },

  handleSaveDomains() {
    this.setState({selection: []});

    if (this.props.onSave) {
      this.props.onSave();
    }
  },

  handleSave() {
    // Find the selected traffic, and convert it into workloads
    const workloads = this.props.domainTraffic
      .filter(traffic => this.state.selection.includes(traffic.id))
      .map(traffic => this.createVirtualService(traffic, []));

    // Allow the user to select labels before saving
    actionCreators.openDialog(
      <EditLabelsDialog
        AssignForUnmanagedWorkloads={true}
        workloads={workloads}
        onComplete={this.handleSaveDomains}
        type="virtualService"
      />,
    );
  },

  handleDownload() {
    const dateFormat = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    const dateTime = intl.date(Date.now(), dateFormat);
    const fileName = `FQDNData ${dateTime}.csv`;
    const links = ExportUtils.getUnmanagedFQDNCSVData(this.state.dnsAddresses, this.props.domainTraffic);
    const blob = new Blob([links], {type: 'text/plain;charset=utf-8'});

    fileSaver.saveAs(blob, fileName);
  },

  handleOpenPortProtocolDialog(addresses) {
    actionCreators.openDialog(
      <PolicyGeneratorAddressesDialog
        addresses={addresses}
        title={intl('PolicyGenerator.Grid.PortsProtocolsProcesses')}
      />,
    );
  },

  handleExpandCollapse(href) {
    if (this.state.expanded === href) {
      this.setState({expanded: false});
    } else {
      this.setState({expanded: href});
    }
  },

  handleExpandableValues(value, row, type) {
    const {disableHover} = this.props;
    const expanded = this.state.expanded === [row.id, type].join(',');
    const data = value.map(value => (
      <HoverMenu
        menuItems={this.state.andOrValue === 'and' ? consumerAndProviderMenuItems() : consumerOrProviderMenuItems()}
        noLabel
        labelActionData={{key: 'ipaddress', value, href: value}}
        labelProps={{text: value}}
        type="ipaddress"
        disabled={disableHover}
      />
    ));

    return (
      <div>
        <span className="hover-menu-ipmargin hover-menu-expandable">
          <ExpandableGridDataList
            data={data}
            expanded={expanded}
            href={[row.id, type].join(',')}
            maxDisplay={4}
            onExpandCollapse={this.handleExpandCollapse}
            expandMessage={intl('PolicyGenerator.Grid.MorePortProtocol', {numPortsProtocols: data.length - 4})}
            collapseMessage={intl('Common.ShowLess')}
          />
        </span>
      </div>
    );
  },

  handleExpandablePortValues(value, row, type) {
    const {disableHover} = this.props;

    const expanded = this.state.expanded === [row.id, type].join(',');
    const data = Object.values(row.portObject).map(value => {
      const portProtocol = `${ServiceUtils.getPort(value) || ''} ${ServiceUtils.lookupProtocol(value.proto)}`;

      return (
        <span className="hover-menu-expandable-ports">
          <HoverMenu
            menuItems={portProtocolMenuItems()}
            noLabel
            disabled={disableHover}
            labelActionData={{key: 'portProtocol', value: portProtocol, href: portProtocol}}
            labelProps={{text: portProtocol}}
            type="portProtocol"
          />
          {value.process_name ? (
            <HoverMenu
              menuItems={portProtocolMenuItems()}
              noLabel
              disabled={disableHover}
              labelActionData={{key: 'processName', value: value.process_name, href: value.process_name}}
              labelProps={{text: value.process_name}}
              type="processName"
            />
          ) : null}
          {value.windows_service_name ? (
            <HoverMenu
              menuItems={portProtocolMenuItems()}
              noLabel
              disabled={disableHover}
              labelActionData={{
                key: 'windowsService',
                value: value.windows_service_name,
                href: value.windows_service_name,
              }}
              labelProps={{text: value.windows_service_name}}
              type="windowsService"
            />
          ) : null}
        </span>
      );
    });

    return (
      <div>
        <span className="hover-menu-expandable">
          <ExpandableGridDataList
            data={data}
            expanded={expanded}
            href={[row.id, type].join(',')}
            maxDisplay={4}
            onExpandCollapse={this.handleExpandCollapse}
            expandMessage={intl('PolicyGenerator.Grid.MorePortProtocol', {numPortsProtocols: data.length - 4})}
            collapseMessage={intl('Common.ShowLess')}
          />
        </span>
      </div>
    );
  },

  render() {
    const {domainTraffic, disableHover, withFilter, banner, showData} = this.props;
    const {dnsAddresses} = this.state;
    const columns = [
      {
        key: 'domain',
        style: 'hostname',
        label: intl('PCE.FQDN'),
        sortable: true,
        format: (value, row) => (
          <span className="hover-menu-ipmargin">
            <HoverMenu
              menuItems={transmissionAndFQDNMenuItems()}
              noLabel
              labelActionData={{
                key: 'fqdn',
                value: value || dnsAddresses[row.address],
                href: value || dnsAddresses[row.address],
              }}
              labelProps={{text: value || dnsAddresses[row.address]}}
              type="fqdn"
              disabled={disableHover}
            />
          </span>
        ),
        sortValue: value => (value || '\uFFFF').toLocaleLowerCase().split('.').reverse().join(''),
      },
      {
        key: 'ports',
        label: intl('Port.PortProcess'),
        format: (value, row) => this.handleExpandablePortValues(value, row, 'port'),
        sortable: true,
      },
      {
        key: 'address',
        label: intl('Common.IPAddress'),
        sortable: true,
        format: (value, row) => this.handleExpandableValues(value, row, 'address'),
      },
      {
        key: 'transmission',
        label: intl('Components.Transmission'),
        sortable: true,
        format: value => (
          <span className="hover-menu-ipmargin">
            <HoverMenu
              menuItems={this.state.andOrValue === 'and' ? transmissionAndFQDNMenuItems() : transmissionOrMenuItem()}
              noLabel
              labelActionData={{key: 'transmission', value, href: value.toLowerCase()}}
              labelProps={{text: value}}
              type="transmission"
              disabled={disableHover}
            />
          </span>
        ),
      },
      {
        key: 'workloads',
        style: 'unmanaged-count',
        label: intl('Common.Workloads'),
        format: value => value.length,
        sortValue: value => value.length,
        sortable: true,
      },
      {
        key: 'flows',
        style: 'unmanaged-count',
        label: intl('Common.Flows'),
        sortable: true,
      },
    ];

    return (
      <div className={`UnmanagedIps${withFilter ? '' : ' ExplorerTable-Header-small'}`} data-tid="domains">
        {this.state.spinner ? <SpinnerOverlay /> : null}
        <ToolBar>
          <ToolGroup>
            <Button
              text={intl('Explorer.CreateVirtualService')}
              onClick={_.partial(this.handleSave)}
              autoFocus={true}
              disabled={!this.state.selection.length || !this.state.virtualServiceEdit}
              tid="createVirtualService"
            />

            <Button
              text={intl('Common.Export')}
              onClick={this.handleDownload}
              autoFocus={true}
              disabled={!domainTraffic.length}
              type="secondary"
              tid="export"
            />
            <div className="ListPage-Count">
              {this.state.selection.length > 0 && (
                <span className="ListPage-Count-Selection" data-tid="elem-count-selection">
                  {intl(
                    'Common.SelectedCount',
                    {count: this.state.selection.length, className: 'selectedCountNumber'},
                    {html: true},
                  )}
                </span>
              )}
            </div>
          </ToolGroup>
        </ToolBar>
        {Boolean(domainTraffic.length) && (
          <ToolBar>
            <ToolGroup />,
            <ToolGroup>
              <Pagination
                page={this.state.currentPage}
                totalRows={domainTraffic.length}
                count={{matched: domainTraffic.length, total: domainTraffic.length}}
                pageLength={MAX_RESULTS_PER_PAGE}
                onPageChange={this.handlePageChange}
              />
            </ToolGroup>
          </ToolBar>
        )}
        {banner}
        {!banner || showData ? (
          <Grid
            columns={columns}
            idField="id"
            data={domainTraffic}
            sorting={this.state.sorting}
            selection={this.state.selection}
            sortable={true}
            selectable={!SessionStore.isSuperclusterMember() && this.state.virtualServiceEdit}
            onSort={this.handleSort}
            onRowSelectToggle={this.handleSelectToggle}
            resultsPerPage={MAX_RESULTS_PER_PAGE}
            totalRows={domainTraffic.length}
            currentPage={this.state.currentPage}
            emptyContent={null}
          />
        ) : null}
        <div className="UnmanagedIps-Position-Right">
          <ToolBar>
            {Boolean(domainTraffic.length) && (
              <ToolGroup>
                <Pagination
                  page={this.state.currentPage}
                  totalRows={domainTraffic.length}
                  count={{matched: domainTraffic.length, total: domainTraffic.length}}
                  pageLength={MAX_RESULTS_PER_PAGE}
                  onPageChange={this.handlePageChange}
                />
              </ToolGroup>
            )}
          </ToolBar>
        </div>
      </div>
    );
  },
});
