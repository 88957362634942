/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import ServiceStore from '../stores/ServiceStore';

const ProtocolMap = () => ({
  '-1': intl('Protocol.Any'),
  '1': intl('Protocol.ICMP'),
  '2': intl('Protocol.IGMP'),
  '4': intl('Protocol.IPv4'),
  '6': intl('Protocol.TCP'),
  '17': intl('Protocol.UDP'),
  '27': intl('Protocol.RDP'),
  '33': intl('Protocol.DCCP'),
  '41': intl('Protocol.IPv6'),
  '47': intl('Protocol.GRE'),
  '50': intl('Protocol.ESP'),
  '51': intl('Protocol.AH'),
  '58': intl('Protocol.ICMPv6'),
  '62': intl('Protocol.CFTP'),
  '64': intl('Protocol.SATEXPAK'),
  '65': intl('Protocol.KRYPTOLAN'),
  '66': intl('Protocol.RVD'),
  '67': intl('Protocol.IPPC'),
  '89': intl('Protocol.OSPF'),
  '94': intl('Protocol.IPIP'),
  '121': intl('Protocol.SMP'),
  '132': intl('Protocol.SCTP'),
});

const RegexPortProtocolMap = () => ({
  portProtocol: /(^\b(0|[1-9]\d*)\b\s+(T|TC|TCP|U|UD|UDP)$)/,
  port: /(^\b(0|[1-9]\d*)\b)$/,
  protocol: /^(U|UD|UDP)$|^(T|TC|TCP)$|^(I|IC|ICM|ICMP)$/,
  protocolWithoutICMP: /^(U|UD|UDP)$|^(T|TC|TCP)$/,
  portRange: /(^\b(0|[1-9]\d*)\b)-(\d+)$/,
  portRangeProtocol: /(^\b(0|[1-9]\d*)\b-\d+\s+(T|TC|TCP|U|UD|UDP)$)/,
});

// Internet Control Message Protocol version 6 is icmpv6. Retired icmp6.
export const ReverseProtocolMap = Object.keys(ProtocolMap()).reduce(
  (result, key) => ({
    ...result,
    [ProtocolMap()[key].toUpperCase()]: Number(key),
  }),
  {},
);

// All Protocols which are used in Rules
export const LimitedReverseProtocolMap = {
  [intl('Protocol.TCP')]: 6,
  [intl('Protocol.UDP')]: 17,
  [intl('Protocol.ICMP')]: 1,
  [intl('Protocol.IGMP')]: 2,
  [intl('Protocol.GRE')]: 47,
  [intl('Protocol.ICMPv6')]: 58,
  [intl('Protocol.IPIP')]: 94,
};

export const icmpCodeMap = {
  0: 'Echo Reply',
  3: 'Destination Unreachable',
  5: 'Redirect',
  8: 'Echo Request',
  9: 'Router Advertisement',
  10: 'Router Selection',
  11: 'Time Exceeded',
  12: 'Parameter Problem',
  13: 'Timestamp',
  14: 'Timestamp Reply',
  40: 'Photuris',
  42: 'Extended Echo Request',
  43: 'Extended Echo Reply',
};

export const icmpv6CodeMap = {
  1: 'Destination Unreachable',
  2: 'Packet Too Big',
  3: 'Time Exceeded',
  4: 'Parameter Problem',
  128: 'Echo Request',
  129: 'Echo Reply',
  130: 'Multicast Listener Query',
  131: 'Multicast Listener Report',
  132: 'Multicast Listener Done',
  133: 'Router Solicitation',
  134: 'Router Advertisement',
  135: 'Neighbor Solicitation',
  136: 'Neighbor Advertisement',
  137: 'Redirect Message',
  138: 'Router Renumbering',
  139: 'ICMP Node Information Query',
  140: 'ICMP Node Information Response',
  141: 'Inverse Neighbor Discovery',
  142: 'Inverse Neighbor Discovery',
  144: 'Home Agent Address Discovery',
  145: 'Home Agent Address Discovery',
  146: 'Mobile Prefix Solicitation',
  147: 'Mobile Prefix Advertisement',
  157: 'Duplicate Address Request Code Suffix',
  158: 'Duplicate Address Confirmation Code Suffix',
  160: 'Extended Echo Request',
  161: 'Extended Echo Reply',
};

export const lookupICMPCode = (code, type) => (type === 'ICMPv6' ? icmpv6CodeMap[code] : icmpCodeMap[code]);

export const getFriendlyMode = mode => {
  switch (mode) {
    case 'unmanaged':
      return intl('Common.NotEnforced');
    case 'enforced':
      return intl('Common.Enforced');
  }
};

/**
 * return protocol name by id, if not founded return number in brackets instead
 * @param protocol
 */
export const lookupProtocol = (protocol, isArray = true) =>
  ProtocolMap()[protocol] || (isArray ? `Protocol:${protocol}` : protocol);

export const allValidProtocolStrings = Object.values(ProtocolMap());

export const reverseLookupProtocol = protocol => {
  if (!protocol) {
    return -1;
  }

  if (_.isNumber(protocol)) {
    return protocol;
  }

  if (protocol === intl('Protocol.Any')) {
    return -1;
  }

  return ReverseProtocolMap[protocol.toUpperCase()] || Number(protocol);
};

export const lookupRegexPortProtocol = type => RegexPortProtocolMap()[type];

// Create a service object of all the single port/protocol services, keyed by the port/protocol
// This is used for the rule builder to quickly match services to traffic
export function getServicesObject(services) {
  return services.reduce((result, userService) => {
    if (userService.update_type !== 'delete') {
      let port;
      let proto;
      let process;
      let service;
      let os;

      // Add objects for the ICMP service
      if (
        userService.service_ports &&
        userService.service_ports.length === 2 &&
        userService.service_ports.some(port => port.proto === 1) &&
        userService.service_ports.some(port => port.proto === 58)
      ) {
        result[[0, 1, process, service, 'all'].join(',')] = userService;
        result[[0, 58, process, service, 'all'].join(',')] = userService;
      }

      if (
        userService.service_ports &&
        userService.service_ports.length === 1 &&
        !userService.service_ports[0].to_port
      ) {
        ({port, proto} = userService.service_ports[0]);
        os = 'all';
      } else if (
        userService.windows_services &&
        userService.windows_services.length === 1 &&
        !userService.windows_services[0].to_port
      ) {
        ({port, proto} = userService.windows_services[0]);
        process = userService.windows_services[0].process_name;
        service = userService.windows_services[0].service_name;
        os = 'windows';
      }

      result[[port || 0, proto, process, service, os].join(',')] = userService;
    }

    return result;
  }, {});
}

export function isIcmp(protocol) {
  return protocol === 1 || protocol === 58 || protocol === 'ICMP' || protocol === 'ICMPv6';
}

export function isPortValid(protocol) {
  return (
    protocol === -1 ||
    protocol === 6 ||
    protocol === 17 ||
    protocol === 'TCP' ||
    protocol === 'UDP' ||
    protocol === 'any'
  );
}

export function getPort(connection) {
  return isIcmp(connection.protocol || connection.proto) ? null : connection.port;
}

/** Takes a port/protocol pair and see if any user-defined Service(s) match
 * @params {Object} connection Object with port (integer) and protocol (integer)
 * @return Array of all services that match the port/protocol pair
 */
export function matchConnectionWithService(connection, services) {
  // For windows links search through both all and windows services with a priority on the windows service
  if (connection.osType === 'windows') {
    return (services || ServiceStore.getAll()).filter(
      service =>
        (service.update_type !== 'delete' &&
          service.windows_services &&
          service.windows_services.some(
            ({port, proto, to_port: toPort, process_name: process, service_name: serviceName}) =>
              connectionMatchesPortProtocol(connection, port, proto, toPort) &&
              connectionMatchesProcessService(connection, process, serviceName),
          )) ||
        (service.update_type !== 'delete' &&
          service.service_ports &&
          service.service_ports.some(({port, proto, to_port: toPort}) =>
            connectionMatchesPortProtocol(connection, port, proto, toPort),
          )),
    );
  }

  // For non-windows links only look through the all operating systems services
  return (services || ServiceStore.getAll()).filter(
    service =>
      service.update_type !== 'delete' &&
      service.service_ports &&
      service.service_ports.some(({port, proto, protocol, to_port: toPort}) =>
        connectionMatchesPortProtocol(connection, port, proto || protocol, toPort),
      ),
  );
}

/** See if the specific port/protocol matches a service (expressed as port, protocol, toPort)
 * @param {Object} connection Port (integer) and protocol (integer) to be matched with service
 * @param {Integer} port If "any", leave as null
 * @param {Integer} protocol If "any", leave as null
 * @param {Integer} toPort Relevant in a port range, null otherwise
 * @return boolean
 */
export function connectionMatchesPortProtocol(connection, port, protocol, toPort) {
  const portExists = !isNaN(port) && port !== -1;

  if (portExists && protocol && toPort) {
    // If the protocol is specific, and the port is a range
    return port <= connection.port && connection.port <= toPort && connection.protocol === protocol;
  }

  if (portExists && protocol && !toPort) {
    // If the protocol is specific, and the port is specific
    return connection.port === port && connection.protocol === protocol;
  }

  if (portExists && !protocol && toPort) {
    // If the protocol is any, and the port is a range
    return port <= connection.port && connection.port <= toPort;
  }

  if (portExists && !protocol && !toPort) {
    // If the protocol is any, and the port is specific
    return connection.port === port;
  }

  if (!portExists && protocol && !toPort) {
    // If the protocol is specific, and the port is any
    return connection.protocol === protocol;
  }

  if (!portExists && !protocol && !toPort) {
    return true;
  }
}

export function connectionMatchesProcessService(connection, process, service) {
  const connProcess = connection.processName && connection.processName.toLocaleLowerCase();
  const connService =
    (connection.serviceName && connection.serviceName.toLocaleLowerCase()) ||
    (connection.windowsService && connection.windowsService.toLocaleLowerCase());
  const serviceProcess = process && process.toLocaleLowerCase().split('\\');
  const serviceService = service && service.toLocaleLowerCase();

  if (serviceProcess && serviceService) {
    return serviceService === connService && serviceProcess[serviceProcess.length - 1] === connProcess;
  }

  if (serviceProcess) {
    return serviceProcess[serviceProcess.length - 1] === connProcess;
  }

  if (serviceService) {
    return serviceService === connService;
  }

  return true;
}

export function getServiceCount(service) {
  return (service.service_ports || service.windows_services || service.windows_egress_services).reduce(
    (result, port) => {
      if (port.to_port) {
        // Find the number of service ports inclusively
        result += port.to_port - port.port + 1;
      } else {
        result += 1;
      }

      return result;
    },
    0,
  );
}

export function getServiceSpecificity(service) {
  if (service.service_ports || !service.windows_services || !service.windows_services.length) {
    return 0;
  }

  return Object.values((service.service_ports || service.windows_services)[0]).filter(value => value).length;
}

export function getWindowsServiceSpecificity(service) {
  if (service.service_ports || !service.windows_services || !service.windows_services.length) {
    return 0;
  }

  if (service.windows_services[0].service_name) {
    return 4;
  }

  if (service.windows_services[0].process_name) {
    return 3;
  }

  if (service.windows_services[0].port) {
    return 2;
  }

  return 1;
}

export default {
  isIcmp,
  getPort,
  isPortValid,
  lookupProtocol,
  lookupRegexPortProtocol,
  lookupICMPCode,
  allValidProtocolStrings,
  reverseLookupProtocol,
  matchConnectionWithService,
  getServicesObject,
  getFriendlyMode,
  getServiceCount,
  getServiceSpecificity,
  getWindowsServiceSpecificity,
};
