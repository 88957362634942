/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {RestApiUtils, WorkloadUtils, RulesetUtils} from '../utils';
import {SessionStore} from '../stores';
import {generalUtils} from '@illumio-shared/utils/shared';

const anyIpName = intl('IPLists.Any');
const facetMap = () => ({
  [intl('Common.Labels')]: 'labels',
  [intl('Common.IPLists')]: 'ipLists',
  [intl('Labels.Groups')]: 'labelGroups',
  [intl('Common.VirtualServices')]: 'virtualServices',
  [intl('Common.VirtualServers')]: 'virtualServers',
  [intl('Common.Workloads')]: 'workloads',
  [intl('Common.UserGroups')]: 'securityPrincipals',
  [intl('Common.Services')]: 'services',
});
const values = (isDenyRule = false) => ({
  provider: {
    initialValues: [
      intl('Common.Labels'),
      intl('Common.IPLists'),
      intl('Labels.Groups'),
      ...(SessionStore.isKubernetesSupported() ? [intl('Common.VirtualServices')] : []),
      ...(__ANTMAN__ ? [] : [intl('Common.VirtualServers')]),
      intl('Common.Workloads'),
    ],
    hideItems: [
      intl('Labels.Groups'),
      ...(__ANTMAN__ ? [] : [intl('Common.VirtualServers')]),
      intl('Common.Workloads'),
    ],
    placeholder: intl('Common.SelectProviders'),
  },
  consumer: {
    initialValues: [
      intl('Common.Labels'),
      intl('Common.IPLists'),
      intl('Labels.Groups'),
      ...(SessionStore.isKubernetesSupported() ? [intl('Common.VirtualServices')] : []),
      intl('Common.Workloads'),
      ...(__ANTMAN__ && !isDenyRule ? [intl('Common.UserGroups')] : []),
    ],
    hideItems: [
      intl('Labels.Groups'),
      intl('Common.Workloads'),
      ...(__ANTMAN__ && !isDenyRule ? [intl('Common.UserGroups')] : []),
    ],
    placeholder: intl('Common.SelectConsumers'),
  },
  actor: {
    initialValues: [intl('Common.Labels'), intl('Labels.Groups'), intl('Common.Workloads')],
    hideItems: [intl('Labels.Groups'), intl('Common.Workloads')],
    placeholder: intl('Labels.SelectByName'),
  },
});

export default React.createClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.object,
    tabIndex: PropTypes.string,
    version: PropTypes.string,
    anyHref: PropTypes.string,
    type: PropTypes.oneOf(['provider', 'consumer', 'actor']),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    showServices: PropTypes.bool,
    isExtra: PropTypes.bool,
    noFilterByScope: PropTypes.bool,
  },

  getInitialState() {
    // The value in 'singleValues' needs to be any truthy value
    const singleValues = {[intl('Workloads.All')]: {actors: 'ams'}};

    if (!__ANTMAN__ && this.props.isExtra && this.props.type === 'consumer') {
      singleValues[intl('Common.ContainerHost')] = {actors: 'container_host'};
    }

    if (this.props.showServices) {
      singleValues[intl('Common.AllServices')] = true;
      singleValues[intl('Common.Stateless')] = true;
      singleValues[intl('Common.MachineAuthentication')] = true;
    }

    if (!__ANTMAN__) {
      if (['provider', 'consumer'].includes(this.props.type)) {
        singleValues[intl('Common.UsesVirtualServicesWorkloads')] = {usesVirtualServicesWorkloads: true};
        singleValues[intl('Common.UsesVirtualServices')] = {usesVirtualServices: true};
      } else if (this.props.type !== 'actor') {
        Object.assign(singleValues, {
          [`${intl('Common.Destinations')} ${intl('Common.UsesVirtualServicesWorkloads')}`]: {
            providerUsesVirtualServicesWorkloads: true,
          },
          [`${intl('Common.Destinations')} ${intl('Common.UsesVirtualServices')}`]: {providerUsesVirtualServices: true},
          [`${intl('Common.Sources')} ${intl('Common.UsesVirtualServicesWorkloads')}`]: {
            consumersUsesVirtualServicesWorkloads: true,
          },
          [`${intl('Common.Sources')} ${intl('Common.UsesVirtualServices')}`]: {consumersUsesVirtualServices: true},
        });
      }
    }

    return {
      items: {},
      dropdownValues: {},
      singleValues,
    };
  },

  componentDidMount() {
    this.setStateFromProps(this.props);
    this.loadDefaultValues();
  },

  componentWillReceiveProps(nextProps) {
    if (this.isMounted()) {
      this.setStateFromProps(nextProps);
    }
  },

  componentDidUpdate(prevProps) {
    if (
      generalUtils.sortAndStringifyArray(RulesetUtils.getSelectedLabelsHrefs(prevProps.selected)) !==
      generalUtils.sortAndStringifyArray(RulesetUtils.getSelectedLabelsHrefs(this.props.selected))
    ) {
      this.loadDefaultValues();
    }
  },

  getCustomClass({deleted, key} = {}) {
    let additionalClass = '';

    if (deleted) {
      additionalClass = 'ObjectSelector-item--workload--unpaired';
    } else if (key) {
      additionalClass = `ObjectSelector-item--label--${key}`;
    }

    return additionalClass;
  },

  getFacetValues(facet, query, maxResults = 25) {
    if (!facet) {
      return;
    }

    query ||= '';

    const resourceType = ['labels', 'labelGroups'].includes(facet);
    const selectedScope = this.props.noFilterByScope ? [] : RulesetUtils.getSelectedScope(facet, this.props.selected);

    RestApiUtils[facet]
      .autocomplete(this.props.version, {
        ...(resourceType && {resource_type: 'rule_sets'}),
        query,
        max_results: maxResults,
        ...(selectedScope.length && {selected_scope: JSON.stringify(selectedScope)}),
      })
      .then(response => {
        const dropdownValues = _.cloneDeep(this.state.dropdownValues);

        dropdownValues[`${facet}-${query}`] = response.body;

        if (this.isMounted()) {
          this.setState({dropdownValues});
        }
      });
  },

  setStateFromProps(props) {
    const newState = {};

    newState.items = props.selected ? props.selected : {};

    _.keys(facetMap()).forEach(item => {
      if (this.state.items[item] === null && !newState.items[item]) {
        newState.items[item] = null;
      }
    });

    if (props.anyHref) {
      const singleValues = _.cloneDeep(this.state.singleValues);

      singleValues[anyIpName] = {
        name: anyIpName,
        href: props.anyHref,
      };
      newState.singleValues = singleValues;
    }

    this.setState(newState);
  },

  handleChange(items) {
    this.setState({items}, () => {
      this.props.onChange(items);
    });
  },

  addItem(item, value) {
    let moveToBeginning = false;

    if (item === anyIpName) {
      item = intl('Common.IPLists');
      moveToBeginning = true;
    }

    if ([intl('Common.AllApplications'), intl('Common.AllEnvironments'), intl('Common.AllLocations')].includes(item)) {
      moveToBeginning = true;
    }

    let items = _.cloneDeep(this.state.items);

    if (!value && !items[item]) {
      items[item] = null;
    } else if (value) {
      if (Object.keys(this.state.singleValues).includes(item)) {
        // If a singleValue, then always add it to the beginning so that
        // the dropdown list doesn't hide it [not applicable to scopes filtering]
        items = {[item]: [value], ...items};
      } else {
        items[item] ||= [];
        items[item].push(value);

        if (moveToBeginning && items[item].length === 1) {
          const temp = items[item];

          delete items[item];
          items = {[item]: temp, ...items};
        }
      }
    }

    if (this.props.onChange) {
      this.handleChange(items);
    }
  },

  loadDefaultValues() {
    Object.values(facetMap()).forEach(facet => this.getFacetValues(facet));
  },

  removeItem(item) {
    const items = _.cloneDeep(this.state.items);

    delete items[item];

    if (this.props.onChange) {
      this.handleChange(items);
    }
  },

  removeMulti(item, singleItem) {
    const items = _.cloneDeep(this.state.items);

    if (singleItem) {
      items[item] = items[item].filter(item => item.href !== singleItem.href);
    } else if (items[item]) {
      items[item].pop();
    }

    if (items[item] && !items[item].length) {
      delete items[item];
    }

    if (this.props.onChange) {
      this.handleChange(items);
    }
  },

  returnValue(value, facet) {
    if (facet === 'initial' || facet === intl('Common.Labels')) {
      return value.value;
    }

    if (facet === intl('Common.Workloads')) {
      return WorkloadUtils.friendlyName(value);
    }

    if (Array.isArray(value)) {
      if (!value[0].name) {
        // TODO: Intlize this. Fixes EYE-47328.
        // This is right now only useful for Objects like
        // {providerUsesVirtualServicesWorkloads: true}, {usesVirtualServices: true}, etc.
        return _.startCase(Object.keys(value[0])[0]);
      }

      return value[0].name || value[0];
    }

    return value.name || value;
  },

  render() {
    const props = {
      facetMap: facetMap(),
      showAllSingleValues: true,
      ref: 'objectSelector',
      hasTitle: [intl('Common.Workloads')],
      defaultSelected: intl('Common.Labels'),
      customClassItems: [intl('Common.Labels'), intl('Common.Workloads')],
      items: this.state.items,
      singleValues: this.state.singleValues,
      dropdownValues: this.state.dropdownValues,
      tabIndex: this.props.tabIndex,
      hideLabel: this.props.hideLabel,
      autoFocus: this.props.autoFocus,
      addItem: this.addItem,
      removeItem: this.removeItem,
      removeMulti: this.removeMulti,
      returnValue: this.returnValue,
      getFacetValues: this.getFacetValues,
      getCustomClass: this.getCustomClass,
    };
    let tid = 'comp-entityselect filter-rules';

    if (this.props.type) {
      switch (this.props.type) {
        case 'provider':
          Object.assign(props, values().provider);
          tid = 'comp-entityselect providerselect';
          break;
        case 'consumer':
          Object.assign(props, values(this.props.isExtra).consumer);
          tid = 'comp-entityselect consumerselect';
          break;
        case 'actor':
          Object.assign(props, values().actor);
          tid = 'comp-entityselect actorselect';
          break;
        case 'scopesFilter':
          props.initialValues = [intl('Common.Labels'), intl('Labels.Groups')];
          props.placeholder = this.props.placeholder || '';
          props.singleValues = {
            [intl('Common.AllApplications')]: 'apps',
            [intl('Common.AllEnvironments')]: 'envs',
            [intl('Common.AllLocations')]: 'locs',
          };
          tid = 'comp-entityselect scopesfilter';
          break;
        default:
        //no default
      }
    } else {
      // If filter rules bar
      props.initialValues = values().provider.initialValues.concat([intl('Common.UserGroups')]);
      props.placeholder = this.props.placeholder || '';

      // Only passed if this is used in the filter bar for rules
      if (this.props.showServices) {
        props.initialValues.push(intl('Common.Services'));
      }
    }

    const anyIpExists = this.state.items[intl('Common.IPLists')]
      ? this.state.items[intl('Common.IPLists')].findIndex(ipList => ipList.name.includes(anyIpName)) > -1
      : false;

    if (anyIpExists && props.singleValues[anyIpName]) {
      delete props.singleValues[anyIpName];
    }

    props.multiItems = props.initialValues;

    if (props.items[intl('Common.ContainerHost')]) {
      // TODO: Very temporary code
      const temp = _.cloneDeep(props.items);
      const containerHost = temp[intl('Common.ContainerHost')];

      delete temp[intl('Common.ContainerHost')];

      props.items = {
        [intl('Common.ContainerHost')]: containerHost,
        ...temp,
      };
    }

    return (
      <div data-tid={tid}>
        <ObjectSelector {...props} />
      </div>
    );
  },
});
