/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

let venReleases = null;
let endpointVENCount = 0;

export default createApiStore(['VEN_', 'VENS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.VEN_SOFTWARE_RELEASES_GET_COLLECTION_SUCCESS:
        venReleases = action.data;
        break;
      case Constants.VENS_GET_COLLECTION_SUCCESS:
        if (action.options.query?.ven_type === 'endpoint') {
          endpointVENCount = action.count.total;
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getVenReleases() {
    return venReleases || [];
  },

  getEndpointVENCount() {
    return endpointVENCount;
  },
});
