/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../../actions/actionCreators';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin, UserMixin} from '../../mixins';
import {TrafficStore, ServiceStore, MapSpinnerStore, SessionStore, LabelStore} from '../../stores';
import {GraphDataUtils, RestApiUtils, PolicyGeneratorUtils} from '../../utils';
import {ObjectSelector, StepCards, ProgressBar, ConfirmationDialog, Banner, Button} from '../../components';
import cx from 'classnames';
import {AppGroupCoverage, DisabledBanner} from '../../components/PolicyGenerator';
import {findDOMNode} from 'react-dom';
import MapNotification, {MapNotificationContainer} from '../Map/MapNotification';

function getStateFromStores() {
  const state = this.getPolicyGeneratorValues();
  const {appGroup, ruleset} = state;
  const appGroupOptions = _.transform(
    TrafficStore.getTrafficAppGroupNodes(),
    (result, appgroup) => {
      const {ipListCoverage, extraGroupCoverage, intraGroupCoverage, tooManyWorkloads} = appgroup;

      if (!extraGroupCoverage || !intraGroupCoverage || !ipListCoverage) {
        result[appgroup.name] = {
          ...appgroup,
          intraGroupCoverage: {num_rules: null, num_services: null},
          extraGroupCoverage: {num_rules: null, num_services: null},
          ipListCoverage: {num_rules: null, num_services: null},
        };
      } else {
        result[appgroup.name] = Object.assign(appgroup, {
          totalCoverageCount: extraGroupCoverage.num_rules + intraGroupCoverage.num_rules + ipListCoverage.num_rules,
          totalServiceCount:
            extraGroupCoverage.num_services + intraGroupCoverage.num_services + ipListCoverage.num_services,
          tooManyWorkloads,
        });
      }
    },
    {},
  );

  const id = this.getParams() && this.getParams().id;

  if (id && (!appGroup || id !== appGroup.href) && TrafficStore.getNodeForPolicyGenerator(id)) {
    this.setAppGroup(TrafficStore.getNodeForPolicyGenerator(id));
  }

  let trafficTypes = {intra: false, extra: false, ipList: false};

  if (appGroup && appGroup.href) {
    trafficTypes = PolicyGeneratorUtils.getTrafficTypes(TrafficStore.getAllRoleTraffics(), appGroup.href);
  }

  return {
    items: appGroup && appGroupOptions[appGroup.name] ? {[appGroup.name]: appGroupOptions[appGroup.name]} : {},
    ruleset: appGroup && ruleset,
    appGroupOptions,
    trafficTypes,
    appGroupDisabled: TrafficStore.isAppGroupDisabled(),
    loadingRules: MapSpinnerStore.getRuleCoverageSpinner(),
    updatingRules: MapSpinnerStore.getRuleCoverageSpinner() && TrafficStore.isPolicyGeneratorCoverageLoaded(),
  };
}

export default Object.assign(
  React.createClass({
    mixins: [
      RouterMixin,
      PolicyGeneratorMixin,
      UserMixin,
      StoreMixin([TrafficStore, MapSpinnerStore], getStateFromStores),
    ],

    getInitialState() {
      return {
        showProgress: true,
        showMT4LWarning: false,
      };
    },

    async componentDidMount() {
      if (!ServiceStore.isLoaded()) {
        RestApiUtils.services.getCollection({max_results: 100_000});
      }

      const labels = LabelStore.getLabelSettingsList();

      if (LabelStore.getLabelSettingsList()) {
        const hideMT4LWarningMap = localStorage.getItem('hideMT4LWarning-selectAppGroup');

        if (hideMT4LWarningMap) {
          this.setState({showMT4LWarning: false});
        } else {
          this.setState({showMT4LWarning: labels.length > 4});
        }
      }

      this.getData(this.state, 'select');

      if (this.state.appGroup) {
        this.getPolicyGeneratorRuleset(this.state.appGroup, false, true);
        this.setState(getStateFromStores.call(this));
      }

      this.mapLevel = await GraphDataUtils.getMapLevelByTotalWorkloads();
    },

    async componentWillReceiveProps() {
      const id = this.getParams() && this.getParams().id;

      if (id && (!this.state.appGroup || id !== this.state.appGroup.href)) {
        const appGroup = TrafficStore.getNodeForPolicyGenerator(id);

        if (appGroup) {
          this.setAppGroup(appGroup);

          const ruleset = await this.getPolicyGeneratorRuleset(appGroup);

          this.setState({ruleset, showProgress: true, appGroup}, () => {
            //reload the data for the new app group
            this.getData(this.state, 'select');
          });
        }
      }
    },

    async onAppGroupAdd(filter, value) {
      const items = {...this.state.items, [filter]: value};
      const appGroup = Object.values(items)[0];

      this.setAppGroup(appGroup);

      const ruleset = await this.getPolicyGeneratorRuleset(appGroup, null, true);

      this.setState({items, ruleset, showProgress: true, appGroup}, () => {
        // After setting the selected app group in the state, reload the data for the new app group
        this.getData(this.state, 'select');
      });
    },

    onRemoveAppGroup() {
      this.setAppGroup();
      this.setState({items: {}, showProgress: false});

      if (this.input) {
        findDOMNode(this.input).querySelector('input[type="text"]').focus();
      }
    },

    generateCustomList({text, props, item}) {
      const appGroup = this.state.appGroupOptions[item];

      if (!item || item.footer || appGroup.tooManyWorkloads || appGroup.intraGroupCoverage === null) {
        return (
          <li key={text} {...props} data-tid="comp-select-results-item">
            {text}
          </li>
        );
      }

      const {totalCoverageCount, totalServiceCount} = appGroup;
      let percentage = totalCoverageCount / totalServiceCount;

      if (!totalCoverageCount || !totalServiceCount) {
        percentage = 0;
      }

      return (
        <li key={appGroup.name} {...props} title={appGroup.name}>
          <div className="RBSelectAppGroup-Select-AGSelect-ResultItem">
            <div className="RBSelectAppGroup-Select-AGSelect-ResultItem-Info">
              <div className="RBSelectAppGroup-Select-AGSelect-ResultItem-Info-Title">{text}</div>
              {this.state.loadingRules || !totalServiceCount ? null : (
                <div className="RBSelectAppGroup-Select-AGSelect-ResultItem-Info-CoverageBar">
                  <div
                    className="RBSelectAppGroup-Select-AGSelect-ResultItem-Info-CoverageBar-Fill"
                    style={{width: `${percentage * 100}%`}}
                  />
                </div>
              )}
            </div>
            {this.state.loadingRules || !totalServiceCount ? null : (
              <div className="RBSelectAppGroup-Select-AGSelect-ResultItem-CoveragePercentage">
                {intl('AppGroupCoverage.RuleCoverage', {val: percentage})}
              </div>
            )}
          </div>
        </li>
      );
    },

    startIpList(type) {
      const id = this.getParams() && this.getParams().id;

      this.setType(type);

      if (id) {
        this.transitionTo('appGroupIpListChoose', {id});
      } else {
        this.transitionTo('ipListChoose');
      }
    },

    startExtra(type) {
      const id = this.getParams() && this.getParams().id;

      this.setType(type);

      if (id) {
        this.transitionTo('appGroupExtraScopeChoose', {id});
      } else {
        this.transitionTo('extraScopeChoose');
      }
    },

    startIntra(type) {
      const id = this.getParams() && this.getParams().id;

      this.setType(type);

      if (id) {
        this.transitionTo('appGroupIntraScopeConfigure', {id});
      } else {
        this.transitionTo('intraScopeConfigure');
      }
    },

    handleRefresh() {
      actionCreators.openDialog(
        <ConfirmationDialog
          title={intl('AppGroupCoverage.Recalculate')}
          message={intl('AppGroupCoverage.RecalculateConfirm')}
          onConfirm={this.handleForceRefresh}
        />,
      );
    },

    handleAppGroupRules() {
      if (this.state.appGroup) {
        this.transitionTo('appGroupRules', {id: this.state.appGroup.href});
      }
    },

    handleForceRefresh() {
      this.updateAppGroupRules(this.state.appGroup.href);
    },

    handleSegmentMultiAG() {
      this.transitionTo('ringFenceChoose', {}, {appgroups: false});
    },

    async handleUsePotentialRuleset() {
      // Set the markers on this ruleset
      const newRuleset = {
        ...this.state.potentialRuleset,
        external_data_set: 'illumio_policy_generator',
        external_data_reference: this.state.appGroup.href.split('x').join(' | '),
      };

      await RestApiUtils.ruleSet.updateClean(
        newRuleset.href.split('/').pop(),
        PolicyGeneratorUtils.getStrippedRuleset(newRuleset),
      );

      this.getPolicyGeneratorRuleset(this.state.appGroup, false, true);
      this.setState({potentialRuleset: null});
      this.setState(getStateFromStores.call(this));
    },

    renderAppGroupSelection(appGroupOptions, items, noSelection) {
      return (
        <div>
          <h3 className="RBSelectAppGroup-Select-AGSelect-Title">{intl('PolicyGenerator.Select.SelectAppGroup')}</h3>
          <ObjectSelector
            addItem={this.onAppGroupAdd}
            dropdownValues={{}}
            facetMap={{}}
            getFacetValues={_.noop}
            initialValues={[]}
            items={items}
            placeholder={noSelection ? intl('PolicyGenerator.Select.SearchAppGroup') : ''}
            removeItem={this.onRemoveAppGroup}
            returnValue={item => item}
            singleValues={appGroupOptions}
            footerValues={[
              {
                footer: true,
                text: `${intl('ObjectSelector.TypeToShowMore')} ${intl('Common.AppGroups')}`,
                className: 'ObjectSelector-dd-values-item--hint',
              },
              {
                footer: true,
                text: intl('PolicyGenerator.SegmentMultipleAppGroups', {
                  count: appGroupOptions ? Object.keys(appGroupOptions).length : 0,
                }),
                className: 'ObjectSelector-dd-values-item--action',
                onClick: this.handleSegmentMultiAG,
              },
            ]}
            allowOne={true}
            customListItem={this.generateCustomList}
            ref={node => (this.input = node)}
          />
          <StepCards
            steps={[
              intl('PolicyGenerator.Select.Steps.Select'),
              intl('PolicyGenerator.Select.Steps.Configure'),
              intl('PolicyGenerator.Select.Steps.Secured'),
            ]}
          />
        </div>
      );
    },

    renderAppGroupCoverage(appGroup, noSelection, type) {
      const {appGroupOptions, trafficTypes, showProgress, updatingRules, loadingSpinner, rulesetDisabled} = this.state;

      let intraGroupCoverage = {num_rules: 0, num_services: 0};
      let extraGroupCoverage = {num_rules: 0, num_services: 0};
      let ipListCoverage = {num_rules: 0, num_services: 0};
      let tooManyWorkloads;

      if (!noSelection && !_.isEmpty(appGroupOptions)) {
        ({intraGroupCoverage, extraGroupCoverage, ipListCoverage, tooManyWorkloads} = appGroupOptions[appGroup.name]);

        if (intraGroupCoverage) {
          intraGroupCoverage.num_rules = Math.min(intraGroupCoverage.num_rules, intraGroupCoverage.num_services) || 0;
        }

        if (extraGroupCoverage) {
          extraGroupCoverage.num_rules = Math.min(extraGroupCoverage.num_rules, extraGroupCoverage.num_services) || 0;
        }

        if (ipListCoverage) {
          ipListCoverage.num_rules = Math.min(ipListCoverage.num_rules, ipListCoverage.num_services) || 0;
        }
      }

      let lastCalculated = null;

      if (appGroup && appGroup.lastCalculated) {
        lastCalculated = new Date(appGroup.lastCalculated);
      }

      return (
        <AppGroupCoverage
          lastCalculated={lastCalculated}
          name={appGroup.name}
          clearSelection={type === 'main' ? this.onRemoveAppGroup : null}
          showProgress={showProgress}
          onRefresh={this.handleRefresh}
          onAppGroupRules={this.handleAppGroupRules}
          tooManyWorkloads={tooManyWorkloads}
          ruleset={this.state.ruleset}
          stale={appGroup.stale || appGroup.coverageStale}
          rulesetDisabled={rulesetDisabled}
          potentialRuleset={this.state.potentialRuleset}
          onUsePotentialRuleset={this.handleUsePotentialRuleset}
          values={{
            intra: {
              percent:
                intraGroupCoverage &&
                intraGroupCoverage.num_services &&
                intraGroupCoverage.num_rules / intraGroupCoverage.num_services,
              connectionWithRules: intraGroupCoverage && intraGroupCoverage.num_rules,
              connectionWithoutRules:
                intraGroupCoverage && intraGroupCoverage.num_services - intraGroupCoverage.num_rules,
              start: this.startIntra,
              traffic: trafficTypes.intra,
            },
            extra: {
              percent:
                extraGroupCoverage &&
                extraGroupCoverage.num_services &&
                extraGroupCoverage.num_rules / extraGroupCoverage.num_services,
              connectionWithRules: extraGroupCoverage && extraGroupCoverage.num_rules,
              connectionWithoutRules:
                extraGroupCoverage && extraGroupCoverage.num_services - extraGroupCoverage.num_rules,
              start: this.startExtra,
              traffic: trafficTypes.extra,
            },
            ipList: {
              percent:
                ipListCoverage && ipListCoverage.num_services && ipListCoverage.num_rules / ipListCoverage.num_services,
              connectionWithRules: ipListCoverage && ipListCoverage.num_rules,
              connectionWithoutRules: ipListCoverage && ipListCoverage.num_services - ipListCoverage.num_rules,
              start: this.startIpList,
              traffic: trafficTypes.ipList,
            },
          }}
          workloads={appGroup.workloads}
          updateSpinner={
            Boolean(updatingRules) ||
            loadingSpinner === 'data' ||
            loadingSpinner === 'rules' ||
            loadingSpinner === 'traffic'
          }
        />
      );
    },

    handleCloseMT4LNotification(event) {
      event.stopPropagation();
      this.setState({showMT4LWarning: false});
      localStorage.setItem('hideMT4LWarning-selectAppGroup', 'true');
    },

    render() {
      const {items, appGroupOptions, spinner, modal, appGroupDisabled, showMT4LWarning} = this.state;
      const appGroupPage = this.getPathname().split('/')[1].includes('appgroup');

      if (appGroupDisabled) {
        const config = (
          <Button
            text={intl('AppGroups.SetAppGroupType')}
            onClick={() => this.transitionTo('appGroupType')}
            disabled={!SessionStore.isUserOwner() || SessionStore.isSuperclusterMember()}
          />
        );

        return <DisabledBanner spinner={spinner} header={intl('PolicyGenerator.AppGroupDisabled')} content={config} />;
      }

      const noSelection = _.isEmpty(items);

      let appGroup = {};

      if (!noSelection && !_.isEmpty(appGroupOptions)) {
        appGroup = _.isEmpty(items) ? this.state.appGroup : Object.values(items)[0];
      }

      const steps = [intl('PolicyGenerator.ConfigureRules'), intl('PolicyGenerator.PreviewRules')];

      let type = 'main';
      let introGraphic = null;
      let appGroupSelector = null;

      if (appGroupPage) {
        steps.unshift(intl('Common.SelectScope'));
        type = 'detail';
      } else {
        steps.unshift(intl('PolicyGenerator.SelectAppGroup'));

        introGraphic = (
          <div className="RBSelectAppGroup-Select-Intro">
            <p className="RBSelectAppGroup-Select-Intro-Text">
              {intl(
                'PolicyGenerator.Select.Intro.Body',
                {className: 'RBSelectAppGroup-Select-Intro-Text-Highlight'},
                {html: true},
              )}
            </p>
          </div>
        );

        appGroupSelector = this.renderAppGroupSelection(appGroupOptions, items, noSelection);
      }

      if (!SessionStore.isTrafficEnabled()) {
        return (
          <div className="RBSelectAppGroup">
            <Banner type="notice" header={intl('PolicyGenerator.DisabledNotice')} />
          </div>
        );
      }

      return (
        <div className="RBSelectAppGroup">
          {spinner && !modal ? (
            <Banner
              type="progresscentered"
              header={intl('PolicyGenerator.CalculationInProgress')}
              message={intl('PolicyGenerator.Spinner.CalculatingRuleCoverage')}
            />
          ) : null}
          <div className={`RBSelectAppGroup-Select${appGroupPage ? ' RBSelectAppGroup-Wide' : ''}`}>
            {introGraphic}
            <div className="RBSelectAppGroup-Select-Progress">
              <ProgressBar steps={steps} active={0} />
            </div>

            <div
              className={cx(
                'RBSelectAppGroup-Select-AGSelect',
                `RBSelectAppGroup-Select-AGSelect--${noSelection ? 'ShowSteps' : 'ShowOptions'}`,
              )}
            >
              {appGroupSelector}
              {this.renderAppGroupCoverage(appGroup, noSelection, type)}
            </div>
          </div>
          <MapNotificationContainer>
            {showMT4LWarning && (
              <MapNotification
                type="warning"
                message={intl('IlluminationMap.PolicyGeneratorWarning')}
                onClose={this.handleCloseMT4LNotification}
              />
            )}
          </MapNotificationContainer>
        </div>
      );
    },
  }),
  {
    viewName: () => intl('PolicyGenerator.PolicyGenerator'),
    isAvailable: () => SessionStore.isTrafficEnabled(),
    forRoute: 'app.policygenerator',
  },
);
