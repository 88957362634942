/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {getHrefParams} from '../lib/api';
import {createApiStore} from '../lib/store';
import StoreUtils from '../utils/StoreUtils';
import Constants from '../constants';

let ancestors = [];
const labelGroups = {};
const labelGroupsMap = {};
const isLoaded = {};
const draftAndActive = {};

function setLabelGroups(data, version) {
  isLoaded.version = true;
  StoreUtils.setObjects(labelGroups, isLoaded, data, version || 'draft');
  data.forEach(group => {
    labelGroupsMap[group.href] = {};
    labelGroupsMap[group.href][version] = group;
  });
}

function setAncestors(data) {
  ancestors = data;
}

function getAncestors() {
  return ancestors;
}

function getChildren(href) {
  const children = [];
  let childCount = 0;
  const MAX_CHILDREN = 500;

  iterateChildren(href);

  return children;

  function iterateChildren(nextHref) {
    if (labelGroupsMap[nextHref]) {
      labelGroupsMap[nextHref].labels.forEach(child => {
        children.push(child);
        childCount++;

        if (childCount < MAX_CHILDREN) {
          iterateChildren(child);
        }
      });
    }
  }
}

function setDraftAndActive(data, version) {
  const id = data.href.split('/')[data.href.split('/').length - 1];

  if (draftAndActive[id]) {
    draftAndActive[id][version] = true;
  } else {
    draftAndActive[id] = {};
    draftAndActive[id][version] = true;
  }
}

function setLabelGroup(data, version) {
  labelGroupsMap[data.href] ||= {};

  labelGroupsMap[data.href][version] = data;
}

export default createApiStore(['LABEL_GROUPS_', 'LABEL_GROUP_'], {
  dispatchHandler(action) {
    let version = 'draft';

    if (action.options && action.options.params) {
      version = action.options.params.pversion || 'draft';
    }

    switch (action.type) {
      case Constants.LABEL_GROUPS_GET_COLLECTION_SUCCESS:
        setLabelGroups(action.data, version);
        break;

      case Constants.LABEL_GROUPS_GET_INSTANCE_SUCCESS:
        setDraftAndActive(action.data, version);
        setLabelGroup(action.data, version);
        break;

      case Constants.LABEL_GROUP_MEMBER_OF_SUCCESS:
        setAncestors(action.data, version);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll(version) {
    return labelGroups[version || 'draft'] || [];
  },

  getSpecified(href) {
    const pversion = getHrefParams(href).pversion;

    if (labelGroupsMap[href]) {
      return labelGroupsMap[href][pversion];
    }

    return StoreUtils.findObjectByHref(href, labelGroups);
  },

  isLoaded(version) {
    return isLoaded[version || 'draft'] || false;
  },

  getAncestors() {
    return getAncestors();
  },

  getByType(type, version) {
    return labelGroups[version || 'draft'].filter(labelGroup => labelGroup.key === type);
  },

  getChildren(href) {
    return getChildren(href);
  },

  getDraftAndActive(href, version) {
    return draftAndActive[href] ? draftAndActive[href][version] : false;
  },
});
