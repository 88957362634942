/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          actionCreators.closeDialog();
          this.props.onCancel();
        },
      },
      {
        text: intl('SegmentationTemplates.LoadFile'),
        tid: 'loadfile',
        onClick: () => {
          actionCreators.closeDialog();
          this.props.onLoadFile();
        },
      },
      {
        text: intl('SegmentationTemplates.LogIn'),
        tid: 'login',
        autoFocus: true,
        onClick: () => {
          this.props.onConfirm();
        },
      },
    ];
  },

  render() {
    return (
      <Dialog
        ref="dialog"
        type="detail"
        className="SegmentationTemplateModal"
        actions={this.getActions()}
        title={intl('SegmentationTemplates.SegmentationTemplates')}
        message={intl('SegmentationTemplates.SupportLoginConfirmation')}
      >
        <div className="SegmentationTemplateModal-text">{intl('SegmentationTemplates.SupportLoginConfirmation')}</div>
        {this.props.fileError && (
          <div className="SegmentationTemplateUpload-error" data-tid="templates-login-file-error">
            {intl('SegmentationTemplates.FileError')}
          </div>
        )}
      </Dialog>
    );
  },
});
