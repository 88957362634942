/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../actions/actionCreators';
import Dialog from '../components/Dialog.jsx';
import _ from 'lodash';

export default React.createClass({
  getActions() {
    return [
      {
        text: intl('Common.OK'),
        tid: 'ok',
        ref: 'confirmButton',
        type: 'primary',
        content: 'both',
        onClick: this.handleClose,
      },
    ];
  },

  getDefaultProps() {
    return {
      onSave: _.noop,
      onCancel: _.noop,
      tid: 'save',
      type: 'workload',
      onClose: _.noop,
    };
  },

  handleClose() {
    this.props.onSave();
    this.props.onClose();

    if (this.props.onComplete) {
      this.props.onComplete();
    }

    actionCreators.closeDialog();
  },

  render() {
    const title =
      this.props.type === 'workload'
        ? this.props.created === 0
          ? intl('Explorer.WorkloadsCreated', {count: this.props.created})
          : intl('Explorer.CreateWorkloadsComplete')
        : this.props.created === 0
        ? intl('Explorer.VSCreated', {count: this.props.created})
        : intl('Explorer.CreateVSComplete');

    return (
      <Dialog
        ref="dialog"
        className="ExplorerAddresses"
        type="alert"
        alert={true}
        actions={this.getActions()}
        title={title}
      >
        <div className="Explorer-Created">
          {this.props.type === 'workload'
            ? intl('Explorer.WorkloadsCreated', {count: this.props.created})
            : intl('Explorer.VSCreated', {count: this.props.created})}
        </div>
        {this.props.existing && this.props.existing.length ? (
          <div>
            <div className="Explorer-Existing">
              {intl('Explorer.AddressesExisting', {count: this.props.existing.length})}
            </div>
            <div className="Explorer-Addresses">
              {this.props.existing.map(address => (
                <div>{address}</div>
              ))}
            </div>
          </div>
        ) : null}
      </Dialog>
    );
  },
});
