/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {PropTypes} from 'react';
import {Link} from 'react-router';
import {Button, Icon, Spinner, Notification} from '..';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

AppGroupCoverage.propTypes = {
  clearSelection: PropTypes.func,
  lastCalculated: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  showProgress: PropTypes.bool.isRequired, // Needed for animating progress bar
  values: PropTypes.objectOf(
    PropTypes.shape({
      percent: PropTypes.number.isRequired,
      connectionWithRules: PropTypes.number.isRequired,
      connectionWithoutRules: PropTypes.number.isRequired,
      start: PropTypes.func.isRequired,
    }),
  ).isRequired,
  workloads: PropTypes.number,
  tooManyWorkloads: PropTypes.bool,
  onRefresh: PropTypes.func.isRequired,
  updateSpinner: PropTypes.bool,
};

export default function AppGroupCoverage(props) {
  const {
    lastCalculated,
    name = '',
    clearSelection,
    onRefresh,
    onAppGroupRules,
    showProgress,
    values,
    workloads = 0,
    tooManyWorkloads,
    updateSpinner,
    rulesetDisabled,
  } = props;

  const noConnections = {
    intra: values.intra.connectionWithRules + values.intra.connectionWithoutRules === 0,
    extra: values.extra.connectionWithRules + values.extra.connectionWithoutRules === 0,
    ipList: values.ipList.connectionWithRules + values.ipList.connectionWithoutRules === 0,
  };

  // Connection counts are now zero before they are calculated
  // So if they are zero, but we have found appropriate traffic, consider them not calculated yet
  const notCalculated = {
    intra: noConnections.intra && values.intra.traffic,
    extra: noConnections.extra && values.extra.traffic,
    ipList: noConnections.ipList && values.ipList.traffic,
  };

  const headings = {
    intra: noConnections.intra ? (
      <strong>{intl('AppGroupCoverage.IntraScope')}</strong>
    ) : (
      intl('AppGroupCoverage.IntraScopeRuleCoverage', {val: values.intra.percent}, {html: true})
    ),
    extra: noConnections.extra ? (
      <strong>{intl('AppGroupCoverage.ExtraScope')}</strong>
    ) : (
      intl('AppGroupCoverage.ExtraScopeRuleCoverage', {val: values.extra.percent}, {html: true})
    ),
    ipList: noConnections.ipList ? (
      <strong>{intl('Common.IPLists')}</strong>
    ) : (
      intl('AppGroupCoverage.IpListRuleCoverage', {val: values.ipList.percent}, {html: true})
    ),
  };

  const ruleTypes = {
    intra: intl('AppGroupCoverage.StartWithIntra'),
    extra: intl('AppGroupCoverage.StartWithExtra'),
    ipList: intl('AppGroupCoverage.StartWithIpList'),
  };

  const appendRuleTypes = {
    intra: intl('AppGroupCoverage.AppendIntraRules'),
    extra: intl('AppGroupCoverage.AppendExtraRules'),
    ipList: intl('AppGroupCoverage.AppendIPListRules'),
  };

  const replaceRuleTypes = {
    intra: intl('AppGroupCoverage.ReplaceIntraRules'),
    extra: intl('AppGroupCoverage.ReplaceExtraRules'),
    ipList: intl('AppGroupCoverage.ReplaceIPListRules'),
  };

  const message = {};
  let notificationMessage;
  let calculateAction;
  let lastCalculatedMessage = intl('AppGroupCoverage.LastCalculated', {when: lastCalculated}) || '\u00A0';
  let rulesetDisabledNotification;
  let potentialRulesetNotification;

  if (props.rulesetDisabled) {
    rulesetDisabledNotification = (
      <div className="AppGroupCoverage-notification">
        <Notification
          type="error"
          message={intl('AppGroupCoverage.RulesetDisabled', {disabled: props.rulesetDisabled})}
        />
      </div>
    );
  }

  if (props.potentialRuleset) {
    const message = [
      intl('AppGroupCoverage.TheRuleset'),
      <Link
        to="rulesets.item"
        params={{id: props.potentialRuleset.href.split('/').pop(), pversion: 'draft', tab: 'intrascope'}}
      >
        {props.potentialRuleset.name}
      </Link>,
      intl('AppGroupCoverage.RulesetAvailableForPolicyGenerator'),
    ];

    potentialRulesetNotification = (
      <div className="AppGroupCoverage-notification">
        <Notification
          type="instruction"
          message={<span>{message}</span>}
          button={
            <Button
              text={intl('AppGroupCoverage.UseThisRuleset')}
              tid="use-this-ruleset"
              onClick={props.onUsePotentialRuleset}
            />
          }
        />
      </div>
    );
  }

  if ((props.ruleset && lastCalculated && new Date(props.ruleset.updated_at) > lastCalculated) || props.stale) {
    notificationMessage = intl('AppGroupCoverage.MoreRecentRulesMessage');
    calculateAction = intl('Common.Recalculate');
  }

  Object.keys(ruleTypes).forEach(type => {
    if (notCalculated[type]) {
      message[type] = intl('AppGroupCoverage.CoverageNotCalculated');
      notificationMessage = intl('AppGroupCoverage.NeverCalculatedMessage');
      calculateAction = intl('Common.Calculate');
      lastCalculatedMessage = `${intl('PolicyGenerator.LastCalculated')}: ${intl('Common.Never')}`;
    } else if (noConnections[type]) {
      message[type] = intl('AppGroupCoverage.NoConnectionsFound');
    } else if (tooManyWorkloads) {
      message[type] = intl('AppGroupCoverage.TooManyWorkloadsInAppGroup');
    }
  });

  return (
    <div className="AppGroupCoverage">
      {clearSelection ? (
        <Icon name="close" size="large" onClick={clearSelection} styleClass="AppGroupCoverage-cancel" />
      ) : null}
      <div className="AppGroupCoverage-title">
        <h3>
          <strong>{intl('Common.AppGroup')}:</strong>{' '}
          {`${name} - ${intl('Workloads.WorkloadsNumber', {count: workloads})}`}
        </h3>
        <p className="AppGroupCoverage-title-lastcalculated">
          {lastCalculatedMessage}
          <Button content="icon-only" icon="refresh" onClick={onRefresh} />
        </p>
        {potentialRulesetNotification}
        {rulesetDisabledNotification}
        {notificationMessage ? (
          <div className="AppGroupCoverage-notification">
            <Notification
              type="instruction"
              message={notificationMessage}
              button={<Button text={calculateAction} tid="calculate" onClick={onRefresh} />}
            />
          </div>
        ) : null}
        {!props.ruleset && Object.values(values).reduce((result, value) => (result += value.connectionWithRules), 0) ? (
          <div className="AppGroupCoverage-notification">
            <Notification
              type="instruction"
              message={
                <span>
                  {intl('AppGroupCoverage.NoRuleset')}
                  <span className="AppGroupCoverage-Link" onClick={onAppGroupRules}>
                    {intl('AppGroupCoverage.GoToAppGroupRules')}
                  </span>
                </span>
              }
            />
          </div>
        ) : null}
      </div>
      {Object.keys(ruleTypes).map(type => (
        <div key={type} className="AppGroupCoverage-card">
          <div className="AppGroupCoverage-card-info">
            {updateSpinner ? (
              <div className="AppGroupCoverage-card-spinner">
                {type === 'extra' && (
                  <div className="SpinnerMessage">
                    <Spinner />
                    {`${intl('PolicyGenerator.Spinner.GeneratingRuleCoverage')}\u2026`}
                  </div>
                )}
              </div>
            ) : null}
            <h3>{headings[type]}</h3>
            {message[type] ? (
              <div className="AppGroupCoverage-card-info-noConnections">
                <h2 className="AppGroupCoverage-card-info-noConnections-heading">{message[type]}</h2>
              </div>
            ) : (
              <div className="AppGroupCoverage-card-info-count">
                <div className="AppGroupCoverage-card-info-count-bar">
                  <div
                    className="AppGroupCoverage-card-info-count-bar-fill"
                    style={{width: `${values[type].percent * 100}%`}}
                  >
                    {showProgress ? (
                      <ReactCSSTransitionGroup
                        transitionName="AppGroupCoverage-card-info-count-bar-fill-value"
                        transitionAppear={true}
                        transitionAppearTimeout={5000}
                        transitionEnterTimeout={0}
                        transitionLeaveTimeout={0}
                      >
                        <div className="AppGroupCoverage-card-info-count-bar-fill-value" />
                      </ReactCSSTransitionGroup>
                    ) : null}
                  </div>
                </div>
                <div className="AppGroupCoverage-card-info-count-connections">
                  <div className="AppGroupCoverage-card-info-count-connections-with">
                    <p className="AppGroupCoverage-card-info-count-connections-with-count">
                      {values[type].connectionWithRules}
                    </p>
                    <p className="AppGroupCoverage-card-info-count-connections-with-text">
                      {intl('AppGroupCoverage.ConnectionWithRules', {count: values[type].connectionWithRules})}
                    </p>
                  </div>
                  <div className="AppGroupCoverage-card-info-count-connections-without">
                    <p className="AppGroupCoverage-card-info-count-connections-without-count">
                      {values[type].connectionWithoutRules}
                    </p>
                    <p className="AppGroupCoverage-card-info-count-connections-without-text">
                      {intl('AppGroupCoverage.ConnectionWithoutRules', {count: values[type].connectionWithoutRules})}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {props.ruleset && (values[type].connectionWithRules || notCalculated[type]) ? (
            <div className="AppGroupCoverage-card-start">
              <Button
                text={replaceRuleTypes[type]}
                disabled={rulesetDisabled}
                onClick={() => values[type].start('replace')}
                tid={type}
              />
              <Button
                text={appendRuleTypes[type]}
                disabled={rulesetDisabled}
                onClick={() => values[type].start('append')}
                tid={type}
              />
            </div>
          ) : (
            <div className="AppGroupCoverage-card-start">
              <Button
                text={replaceRuleTypes[type] || rulesetDisabled}
                onClick={() => values[type].start('replace')}
                tid={type}
              />
              <Button
                text={ruleTypes[type]}
                disabled={rulesetDisabled}
                onClick={() => values[type].start('append')}
                tid={type}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
