/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import Spinner from '../Spinner.jsx';
import {StoreMixin} from '../../mixins';
import Timestamp from '../../pages/Map/Timestamp.jsx';
import {MapSpinnerStore, GraphStore} from '../../stores';

function getStateFromStores() {
  return {
    on: MapSpinnerStore.getSpinner() || !GraphStore.isGraphCalculated(),
    calculating: MapSpinnerStore.getTrafficSpinner(),
  };
}

export default React.createClass({
  mixins: [StoreMixin([MapSpinnerStore, GraphStore], getStateFromStores)],

  render() {
    const classes = cx('Map-spinner', {
      'Map-refresh-icon': !this.props.on && !this.state.on,
      'Map-spinner-policy':
        this.props.appMapVersion === 'policy' && this.props.mapType === 'app' && this.props.isVulnerabilityEnabled,
      'Map-spinner-vulnerability':
        this.props.appMapVersion === 'vulnerability' &&
        this.props.mapType === 'app' &&
        this.props.isVulnerabilityEnabled,
    });

    if (!this.state.on) {
      return (
        <div className={classes}>
          <Timestamp iconOnly />
        </div>
      );
    }

    return (
      <div className={classes}>
        <span className="Spinner-text">
          {this.state.calculating ? intl('Map.RecalculatingData') : intl('Map.LoadingData')}
        </span>
        <Spinner size="twenty" color={this.props.policyVersion === 'draft' ? 'light' : 'dark'} />
      </div>
    );
  },
});
