/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import dispatcher from './dispatcher';
import Constants from '../constants';

export default {
  addResource(type, data) {
    dispatcher.dispatch({
      type,
      data,
    });
  },

  deleteResource(type, data) {
    dispatcher.dispatch({
      type,
      data,
    });
  },

  ruleCoverageIterateStart() {
    dispatcher.dispatch({
      type: Constants.SEC_POLICY_RULE_COVERAGE_ITERATE_INIT,
    });
  },

  ruleCoverageIterateComplete() {
    dispatcher.dispatch({
      type: Constants.SEC_POLICY_RULE_COVERAGE_ITERATE_COMPLETE,
    });
  },

  networkTrafficIterateStart(data) {
    dispatcher.dispatch({
      type: Constants.NETWORK_TRAFFIC_ITERATE_INIT,
      data,
    });
  },

  networkTrafficIterateComplete(data) {
    dispatcher.dispatch({
      type: Constants.NETWORK_TRAFFIC_ITERATE_COMPLETE,
      data,
    });
  },

  networkTrafficRebuildStart(data) {
    dispatcher.dispatch({
      type: Constants.NETWORK_TRAFFIC_REBUILD_START,
      data,
    });
  },

  networkTrafficRebuildComplete(data) {
    dispatcher.dispatch({
      type: Constants.NETWORK_TRAFFIC_REBUILD_COMPLETE,
      data,
    });
  },

  updateRuleCoverageForScope(data) {
    dispatcher.dispatch({
      type: Constants.SEC_POLICY_RULES_FOR_SCOPE,
      data,
    });
  },

  updateRuleCoverageForWorkload(data) {
    dispatcher.dispatch({
      type: Constants.SEC_POLICY_RULES_FOR_WORKLOAD,
      data,
    });
  },

  updateRuleCoverageForAll() {
    dispatcher.dispatch({
      type: Constants.SEC_POLICY_RULES_FOR_ALL,
    });
  },

  updateTrafficForNodes(data) {
    dispatcher.dispatch({
      type: Constants.NETWORK_TRAFFIC_UPDATE_FOR_NODES,
      data,
    });
  },

  updateTrafficForScopes(data) {
    dispatcher.dispatch({
      type: Constants.NETWORK_TRAFFIC_UPDATE_FOR_SCOPES,
      data,
    });
  },

  updateTrafficForAll(data) {
    dispatcher.dispatch({
      type: Constants.NETWORK_TRAFFIC_UPDATE_FOR_All,
      data,
    });
  },

  updateForAll(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_FOR_All,
      data,
    });
  },

  clearTrafficOnNext(data) {
    dispatcher.dispatch({
      type: Constants.CLEAR_TRAFFIC_ON_NEXT,
      data,
    });
  },

  setRuleCoverageType(data) {
    dispatcher.dispatch({
      type: Constants.SET_RULE_COVERAGE_TYPE,
      data,
    });
  },

  updatePolicyGeneratorRuleset() {
    dispatcher.dispatch({
      type: Constants.UPDATE_POLICY_GENERATOR_RULESET,
    });
  },

  addFilters(data, automatic) {
    dispatcher.dispatch({
      type: Constants.ADD_FILTERS,
      data,
      automatic,
    });
  },

  addApiFilter(data) {
    dispatcher.dispatch({
      type: Constants.ADD_API_FILTER,
      data,
    });
  },

  removeFilter(data) {
    dispatcher.dispatch({
      type: Constants.REMOVE_FILTER,
      data,
    });
  },

  removeApiFilter(data) {
    dispatcher.dispatch({
      type: Constants.REMOVE_API_FILTER,
      data,
    });
  },

  removeAutomaticFilters() {
    dispatcher.dispatch({
      type: Constants.REMOVE_AUTOMATIC_FILTERS,
    });
  },

  openDialog(data) {
    dispatcher.dispatch({
      type: Constants.OPEN_DIALOG,
      data,
    });
  },

  closeDialog(data) {
    dispatcher.dispatch({
      type: Constants.CLOSE_DIALOG,
      data,
    });
  },

  notShowingMessage(data) {
    dispatcher.dispatch({
      type: Constants.NOT_SHOWING_MESSAGE,
      data,
    });
  },

  startSpinner() {
    dispatcher.dispatch({
      type: Constants.START_SPINNER,
    });
  },

  stopSpinner() {
    dispatcher.dispatch({
      type: Constants.STOP_SPINNER,
    });
  },

  showMapTooltip(data) {
    dispatcher.dispatch({
      type: Constants.SHOW_MAP_TOOLTIP,
      data,
    });
  },

  hideMapTooltip(data) {
    dispatcher.dispatch({
      type: Constants.HIDE_MAP_TOOLTIP,
      data,
    });
  },

  showMapMenu(data) {
    dispatcher.dispatch({
      type: Constants.SHOW_MAP_MENU,
      data,
    });
  },

  hideMapMenu() {
    dispatcher.dispatch({
      type: Constants.HIDE_MAP_MENU,
    });
  },

  selectComponent(data) {
    dispatcher.dispatch({
      type: Constants.SELECT_COMPONENT,
      data,
    });
  },

  unselectComponent(data) {
    dispatcher.dispatch({
      type: Constants.UNSELECT_COMPONENT,
      data,
    });
  },

  updateComponentSelection(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_COMPONENT_SELECTION,
      data,
    });
  },

  updateMultipleSelection(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_MULTIPLE_SELECTION,
      data,
    });
  },

  updateInteractionType(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_INTERACTION_TYPE,
      data,
    });
  },

  updateSelectionType(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_SELECTION_TYPE,
      data,
    });
  },

  updateHoveredComponent(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_HOVERED_COMPONENT,
      data,
    });
  },

  updateUnhoveredComponent(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_UNHOVERED_COMPONENT,
      data,
    });
  },

  setConnectedSearch(data) {
    dispatcher.dispatch({
      type: Constants.SET_CONNECTED_SEARCH,
      data,
    });
  },

  hoverComponent(data) {
    dispatcher.dispatch({
      type: Constants.HOVER_COMPONENT,
      data,
    });
  },

  unhoverComponent(data) {
    dispatcher.dispatch({
      type: Constants.UNHOVER_COMPONENT,
      data,
    });
  },

  expandCluster(data) {
    dispatcher.dispatch({
      type: Constants.EXPAND_CLUSTER,
      data,
    });
  },

  removeExpandedCluster(data) {
    dispatcher.dispatch({
      type: Constants.REMOVE_EXPANDED_CLUSTER,
      data,
    });
  },

  clearExpandedClusters() {
    dispatcher.dispatch({
      type: Constants.CLEAR_EXPANDED_CLUSTERS,
    });
  },

  expandRole(data) {
    dispatcher.dispatch({
      type: Constants.EXPAND_ROLE,
      data,
    });
  },

  collapseRole(data) {
    dispatcher.dispatch({
      type: Constants.COLLAPSE_ROLE,
      data,
    });
  },

  clearLoadedRole(data) {
    dispatcher.dispatch({
      type: Constants.CLEAR_LOADED_ROLES,
      data,
    });
  },

  unmountGraph() {
    dispatcher.dispatch({
      type: Constants.UNMOUNT_GRAPH,
    });
  },

  clickActionItem(data) {
    dispatcher.dispatch({
      type: Constants.CLICK_ACTION_ITEM,
      data,
    });
  },

  closeActionItem(data) {
    dispatcher.dispatch({
      type: Constants.CLOSE_ACTION_ITEM,
      data,
    });
  },

  selectService(data) {
    dispatcher.dispatch({
      type: Constants.SELECT_SERVICE,
      data,
    });
  },

  selectPbUb(data) {
    dispatcher.dispatch({
      type: Constants.SELECT_PBUB,
      data,
    });
  },

  selectVulnerability(data) {
    dispatcher.dispatch({
      type: Constants.SELECT_VULNERABILITY,
      data,
    });
  },

  clearTrafficSelection(data) {
    dispatcher.dispatch({
      type: Constants.CLEAR_TRAFFIC_SELECTION,
      data,
    });
  },

  selectTrafficFilters(data) {
    dispatcher.dispatch({
      type: Constants.SELECT_TRAFFIC_FILTERS,
      data,
    });
  },

  setTrafficFilterHidden(data) {
    dispatcher.dispatch({
      type: Constants.SET_TRAFFIC_FILTER_HIDDEN,
      data,
    });
  },

  selectTrafficConnectionFilters(data) {
    dispatcher.dispatch({
      type: Constants.SELECT_TRAFFIC_CONNECTION_FILTERS,
      data,
    });
  },

  selectServiceFilters(data) {
    dispatcher.dispatch({
      type: Constants.SELECT_SERVICE_FILTERS,
      data,
    });
  },

  resetDefaultFilters() {
    dispatcher.dispatch({
      type: Constants.RESET_DEFAULT_FILTERS,
    });
  },

  selectTrafficTimeFilters(data) {
    dispatcher.dispatch({
      type: Constants.SELECT_TRAFFIC_TIME_FILTERS,
      data,
    });
  },

  setUserDefinedService(data) {
    dispatcher.dispatch({
      type: Constants.SET_USER_DEFINED_SERVICE,
      data,
    });
  },

  clearUserDefinedService(data) {
    dispatcher.dispatch({
      type: Constants.CLEAR_USER_DEFINED_SERVICE,
      data,
    });
  },

  updateMapRoute(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_MAP_ROUTE,
      data,
    });
  },

  updateMapType(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_MAP_TYPE,
      data,
    });
  },

  updateRingFenceRules() {
    dispatcher.dispatch({
      type: Constants.UPDATE_RING_FENCE_RULES,
    });
  },

  updateGraphCalculated(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_GRAPH_CALCULATED,
      data,
    });
  },

  updateZoomIn(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_ZOOM_IN,
      data,
    });
  },

  updateZoomOut(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_ZOOM_OUT,
      data,
    });
  },

  updateZoomToFit(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_ZOOM_TO_FIT,
      data,
    });
  },

  updateTransform(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_TRANSFORM,
      data,
    });
  },

  updatePosition(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_POSITION,
      data,
    });
  },

  resetLayout(data) {
    dispatcher.dispatch({
      type: Constants.RESET_LAYOUT,
      data,
    });
  },

  expandCollapseConnectedGroup() {
    dispatcher.dispatch({
      type: Constants.EXPAND_COLLAPSE_CONNECTED_GROUP,
    });
  },

  updateRequestedLinks(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_REQUESTED_LINKS,
      data,
    });
  },

  provisionPending(data) {
    dispatcher.dispatch({
      type: Constants.PROVISION_PENDING,
      data,
    });
  },

  provisionDone(data) {
    dispatcher.dispatch({
      type: Constants.PROVISION_DONE,
      data,
    });
  },

  generateReportPending(data) {
    dispatcher.dispatch({
      type: Constants.GENERATE_REPORT_PENDING,
      data,
    });
  },

  generateReportDone(data) {
    dispatcher.dispatch({
      type: Constants.GENERATE_REPORT_DONE,
      data,
    });
  },

  logout() {
    dispatcher.dispatch({
      type: Constants.USER_LOGOUT,
    });
  },

  changePassword() {
    dispatcher.dispatch({
      type: Constants.USER_CHANGE_PASSWORD,
    });
  },

  updateGeneralSorting(key, value) {
    dispatcher.dispatch({
      type: Constants.UPDATE_GENERAL_SORTING,
      data: {key, value},
    });
  },

  updateGeneralFilter(key, value) {
    dispatcher.dispatch({
      type: Constants.UPDATE_GENERAL_FILTER,
      data: {key, value},
    });
  },

  updateGeneralFacetFilter(key, value) {
    dispatcher.dispatch({
      type: Constants.UPDATE_GENERAL_FACET_FILTER,
      data: {key, value},
    });
  },

  resetFacetValues(data, query) {
    dispatcher.dispatch({
      type: Constants.RESET_FACET_VALUES,
      data,
      query,
    });
  },

  updateGeneralSelection(key, value) {
    dispatcher.dispatch({
      type: Constants.UPDATE_GENERAL_SELECTION,
      data: {key, value},
    });
  },

  forceListPageRefresh(key, value) {
    dispatcher.dispatch({
      type: Constants.GENERAL_FACET_UPDATE_STATUS,
      data: {key, value},
    });
  },

  clearGeneralSelection(key) {
    dispatcher.dispatch({
      type: Constants.CLEAR_GENERAL_SELECTION,
      data: key,
    });
  },

  updatePanelShown(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_PANEL_SHOWN,
      data,
    });
  },

  clearConnectivity() {
    dispatcher.dispatch({
      type: Constants.CLEAR_CONNECTIVITY,
    });
  },

  updateTrafficParameters(data) {
    dispatcher.dispatch({
      type: Constants.UPDATE_TRAFFIC_PARAMETERS,
      data,
    });
  },

  setTotalWorkloads(data) {
    dispatcher.dispatch({
      type: Constants.SET_TOTAL_WORKLOADS,
      data,
    });
  },

  clearHistory(data) {
    dispatcher.dispatch({
      type: Constants.CLEAR_HISTORY,
      data,
    });
  },

  setMapPolicyState(data) {
    dispatcher.dispatch({
      type: Constants.SET_MAP_POLICY_VERSION,
      data,
    });
  },

  setAppMapVersion(data) {
    dispatcher.dispatch({
      type: Constants.SET_APP_MAP_VERSION,
      data,
    });
  },

  ruleCoverageIsTruncated(data) {
    dispatcher.dispatch({
      type: Constants.SEC_POLICY_RULE_COVERAGE_IS_TRUNCATED,
      data,
    });
  },

  segmentationTemplateParseData(data) {
    dispatcher.dispatch({
      type: Constants.SEGMENTATION_TEMPLATE_PARSE_DATA,
      data,
    });
  },

  setSegmentationTemplateStatus(data) {
    dispatcher.dispatch({
      type: Constants.SEGMENTATION_TEMPLATE_SET_STATUS,
      data,
    });
  },

  segmentationTemplatesCompareInstalledTemplates() {
    dispatcher.dispatch({
      type: Constants.SEGMENTATION_TEMPLATES_COMPARE_INSTALLED_TEMPLATES,
    });
  },

  segmentationTemplatesClearInstalledTemplates() {
    dispatcher.dispatch({
      type: Constants.SEGMENTATION_TEMPLATES_CLEAR_INSTALLED_TEMPLATES,
    });
  },

  setMapNotification(data) {
    dispatcher.dispatch({
      type: Constants.SET_MAP_NOTIFICATION,
      data,
    });
  },

  setFilterOnHoverSelection(data) {
    dispatcher.dispatch({
      type: Constants.ADD_FILTER_ON_HOVER_SELECTION,
      data,
    });
  },

  setAndOrFlag(data) {
    dispatcher.dispatch({
      type: Constants.AND_OR_FILTER_TYPE,
      data,
    });
  },

  setExplorerType(data) {
    dispatcher.dispatch({
      type: Constants.SET_EXPLORER_TYPE,
      data,
    });
  },

  clearNewExplorerQuery() {
    dispatcher.dispatch({
      type: Constants.CLEAR_NEW_EXPLORER_QUERY,
    });
  },

  setRuleSearchFilters(data) {
    dispatcher.dispatch({
      type: Constants.SEC_POLICY_RULE_SEARCH_SET_FILTERS,
      data,
    });
  },

  openInstantSearch(data) {
    dispatcher.dispatch({
      type: Constants.OPEN_INSTANT_SEARCH,
      data,
    });
  },

  closeInstantSearch() {
    dispatcher.dispatch({
      type: Constants.CLOSE_INSTANT_SEARCH,
    });
  },

  setRoutesMap(data) {
    dispatcher.dispatch({
      type: Constants.SET_ROUTES_MAP,
      data,
    });
  },

  closeWhatsNew(data, broadcasted = false) {
    dispatcher.dispatch({
      type: Constants.CLOSE_WHATS_NEW,
      data,
      broadcasted,
    });
  },

  setEssentialServiceRules(data) {
    dispatcher.dispatch({
      type: Constants.ESSENTIAL_SERVICE_RULES,
      data,
    });
  },

  exceededWarningConfirm(data) {
    dispatcher.dispatch({
      type: Constants.EXCEEDED_WARNING_CONFIRM,
      data,
    });
  },
};
