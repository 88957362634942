/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Link} from 'react-router';
import Button from '../components/Button.jsx';
import VersionStore from '../stores/VersionStore';
import SessionStore from '../stores/SessionStore';
import NotificationGroup from '../components/NotificationGroup.jsx';
import {webStorageUtils} from '@illumio-shared/utils';

export default {
  getPolicyObjectAlert(params) {
    const {
      policyObject,
      routeTo,
      shaded,
      tab,
      readOnly,
      hasntChanged,
      isGlobalObject,
      isRuleset,
      hasRulesetPriv,
      draftAndActive,
    } = params;
    let type;
    let title;
    let message;
    let button;
    let policyVersion = this.getParams().pversion;
    const globalObjectPriv = isGlobalObject && this.doesUserHaveGlobalObjectPermissions();
    const rulesetPriv = isRuleset && hasRulesetPriv && !SessionStore.isSuperclusterMember();
    const remainingPriv =
      !isGlobalObject &&
      !isRuleset &&
      (this.isUserAdmin() || this.isUserOwner()) &&
      !SessionStore.isSuperclusterMember();
    const draftRouteParams = {
      id: this.getParams().id,
      pversion: 'draft',
    };
    const activeRouteParams = {
      id: this.getParams().id,
      pversion: 'active',
    };

    if (tab) {
      draftRouteParams.tab = activeRouteParams.tab = tab;
    }

    if (readOnly) {
      type = 'instruction';
      title = intl('Policy.ReadOnlyVersion');
      message = [];

      if (policyObject && policyObject.update_type !== 'create') {
        if (policyVersion === 'active') {
          message.push(
            <Link to={routeTo} params={draftRouteParams} data-tid="elem-link" className="Link" key="edit">
              {intl('Policy.ViewDraftVersion')}
            </Link>,
          );
        } else {
          message.push(
            <Link to={routeTo} params={activeRouteParams} data-tid="elem-link" className="Link" key="view">
              {intl('Policy.ViewActiveVersion')}
            </Link>,
          );
        }
      }
    } else if (policyVersion === 'draft') {
      type = 'warning';
      title = intl('Policy.ViewingDraftVersion');

      if (hasntChanged) {
        message = [intl('Provision.UpToDate')];
      } else {
        message = [intl('Policy.YouViewingDraftVersion')];
      }

      if (policyObject && policyObject.update_type !== 'create') {
        message.push(
          <Link to={routeTo} params={activeRouteParams} data-tid="elem-link" className="Link" key="view">
            {intl('Policy.ViewActiveVersion')}
          </Link>,
        );
      }

      if (
        policyObject &&
        policyObject.update_type &&
        policyObject.href &&
        (globalObjectPriv || rulesetPriv || remainingPriv)
      ) {
        const onClick = () => {
          let objectType;
          const changeSubset = {};

          if (policyObject.href.includes('firewall_settings')) {
            objectType = 'firewall_settings';
          } else {
            objectType = policyObject.href.split('/').slice(-2)[0];
          }

          const storedGeneralSelection = (webStorageUtils.getSessionItem('GeneralSelection') || []).filter(
            ({key}) => key !== 'provision',
          );

          changeSubset[objectType] = [{href: policyObject.href}];
          storedGeneralSelection.push({key: 'provision', value: {operation: 'commit', change_subset: changeSubset}});
          webStorageUtils.setSessionItem('GeneralSelection', storedGeneralSelection);
          this.transitionTo('provision');
        };

        button = <Button onClick={onClick} text={intl('Common.Provision')} tid="provision" />;
      }
    } else {
      let activePolicyVersion;

      if (policyVersion !== 'active') {
        activePolicyVersion = VersionStore.getLatestVersion();

        if (!activePolicyVersion || !policyObject || !policyObject.href) {
          return null;
        }

        const activeHrefParts = policyObject.href.split('/');

        if (activeHrefParts[activeHrefParts.length - 1] === 'firewall_settings') {
          policyVersion = activeHrefParts[activeHrefParts.length - 2];
        } else {
          policyVersion = activeHrefParts[activeHrefParts.length - 3];
        }

        policyVersion = parseInt(policyVersion, 10);
      }

      if (policyVersion === 'active' || (activePolicyVersion && policyVersion === activePolicyVersion)) {
        type = 'instruction';
        title = intl('Policy.ActiveVersion');
        message = [];

        if (policyObject && policyObject.update_type !== 'create') {
          message.push(
            <Link to={routeTo} params={draftRouteParams} data-tid="elem-link" className="Link" key="edit">
              {intl('Policy.ViewDraftVersion')}
            </Link>,
          );
        }
      } else {
        type = 'warning';
        title = intl('Policy.OldVersion');
        message = [intl('Policy.OldVersionDesc', {version: policyVersion})];

        if (policyObject && !policyObject.deleted_at && policyObject.update_type !== 'create' && draftAndActive) {
          if (draftAndActive.currentDraft) {
            message.push(
              <Link to={routeTo} params={draftRouteParams} data-tid="elem-link" className="Link" key="edit">
                {intl('Policy.EditDraftVersion')}
              </Link>,
            );
          }

          if (draftAndActive.currentActive) {
            message.push(
              <Link to={routeTo} params={activeRouteParams} data-tid="elem-link" className="Link" key="view">
                {intl('Policy.ViewActiveVersion')}
              </Link>,
            );
          }
        }
      }
    }

    const notification = {
      type,
      title,
      message: <span>{message}</span>,
      button,
    };

    return <NotificationGroup displayStyle={shaded ? 'shaded' : undefined} notifications={[notification]} />;
  },
};
