/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import React, {PropTypes} from 'react';
import ServiceSelect from '../ServiceSelect.jsx';

export default React.createClass({
  propTypes: {
    disabled: PropTypes.bool,
    selected: PropTypes.object,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    disableSpinner: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      onChange: _.noop,
    };
  },

  handleChange(items) {
    this.props.onChange(items);
  },

  handleNewService(oldServices, newService, rule, setItems) {
    const newRule = {...rule};

    newRule.ingress_services = [...(oldServices || []), newService];
    newRule.resolve_labels_as = {
      providers: ['workloads'],
      consumers: ['workloads'],
    };

    ['providers', 'consumers'].forEach(type => {
      if (!rule[type]) {
        return;
      }

      rule[type].some(entity => {
        if (entity[intl('Common.UsesVirtualServicesWorkloads')]) {
          rule.resolve_labels_as[type] = ['workloads', 'virtual_services'];

          return true;
        }

        if (entity[intl('Common.UsesVirtualServices')]) {
          rule.resolve_labels_as[type] = ['virtual_services'];

          return true;
        }

        return false;
      });
    });

    const items = {[intl('Services.PolicyServices')]: [...(oldServices || []), newService]};

    if (rule.stateless) {
      items[intl('Common.Stateless')] = {stateless: true, name: 'Stateless'};
    }

    this.handleChange(items);
    setItems(newRule);
  },

  render() {
    return (
      <ServiceSelect
        {...this.props}
        allowNull
        allowCreate
        allowRuleAttrs
        placeholder={intl('Rulesets.Rules.SelectService')}
        onChange={this.handleChange}
        onNewService={this.handleNewService}
      />
    );
  },
});
