/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import React from 'react';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';
import Checkbox from '../components/Checkbox.jsx';

export default React.createClass({
  getInitialState() {
    return {disabled: true};
  },

  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          this.handleClose();
        },
      },
      {
        text: intl('Common.Continue'),
        tid: 'continue',
        autoFocus: true,
        disabled: this.state.disabled,
        onClick: () => {
          this.props.onInstall();
          this.handleClose();
        },
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  handleAgreeToggle() {
    this.setState({disabled: !this.state.disabled});
  },

  render() {
    const text = [
      intl('SegmentationTemplates.EULAText1'),
      intl('SegmentationTemplates.EULAText2'),
      intl('SegmentationTemplates.EULAText3'),
    ].join(' ');

    return (
      <Dialog
        className="SegmentationTemplateModal"
        ref="dialog"
        type="detail"
        title={intl('SegmentationTemplates.EULAAcceptance')}
        actions={this.getActions()}
      >
        <div className="SegmentationTemplateModal-text" data-tid="templates-eula-text">
          {text}
        </div>
        <div className="SegmentationTemplateModal-check">
          <Checkbox
            checked={!this.state.disabled}
            onChange={this.handleAgreeToggle}
            data-tid="templates-eula-checkbox"
          />
          {intl('SegmentationTemplates.EULAAcceptanceCheck')}
          <a
            href="https://www.illumio.com/eula"
            target="_new"
            className="SegmentationTemplateModal-link"
            data-tid="templates-eula-link"
          >
            {intl('SegmentationTemplates.EULALink')}
          </a>
        </div>
      </Dialog>
    );
  },
});
