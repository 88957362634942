/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import {GridDataUtils, ProviderConsumerUtils} from '../../utils';
import {ToolBar} from '../ToolBar';
import {Grid, Banner} from '..';
import intl from '@illumio-shared/utils/intl';
import {PolicyGeneratorConfigGridFilter, TrafficFilter} from '.';

ConfigureIntraConnectionsGrid.propTypes = {
  trafficData: PropTypes.array,
  onRowToggle: PropTypes.func,
  exclusions: PropTypes.array,
  config: PropTypes.any,
  sorting: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onAddFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onIncludeAll: PropTypes.func.isRequired,
  onExcludeAll: PropTypes.func.isRequired,
  maxRows: PropTypes.number.isRequired,
  transmissionFilters: PropTypes.object,
};

export default function ConfigureIntraConnectionsGrid(props) {
  const disableToggle = props.trafficData.length < 2 && props.completeData;
  let included = true;
  let excluded = true;

  if (_.isEmpty(props.trafficData)) {
    included = false;
    excluded = false;
  }

  const formattedTrafficData = props.trafficData.slice(0, props.maxRows).map(traffic => {
    const provider = traffic.target;
    const consumer = traffic.source;
    const key = traffic.key;
    const rulesetInclusion = {
      included: props.config === 'ringfencing' || (props.exclusions && !props.exclusions.includes(key)),
      connections: traffic.connections,
      sessions: traffic.sessions,
      vulnerabilities: traffic.vulnerabilities,
      roles: {provider, consumer},
      key,
    };

    included &&= rulesetInclusion.included;
    excluded &&= !rulesetInclusion.included;

    return {
      rulesetInclusion,
      sessions: rulesetInclusion,
      connections: rulesetInclusion,
      services: traffic.services,
      serviceCount: traffic.totalServices,
      sourceAddresses: traffic.sourceAddresses,
      targetAddresses: traffic.targetAddresses,
      missingServices: traffic.missingConnections,
      fqdnType: traffic.fqdnType,
      provider,
      consumer,
      key,
      type: traffic.type,
    };
  });

  const sortingItems = {
    rulesetInclusion: {
      key: 'rulesetInclusion',
      label: intl('PolicyGenerator.Grid.RulesetInclusion'),
      sort: 'included',
    },
    connections: {
      key: 'connections',
      label: intl('Common.Connections'),
      sort: 'connections',
    },
    sessions: {
      key: 'sessions',
      label: intl('Common.Flows'),
      sort: 'sessions',
    },
  };

  const {key, label, sort} = sortingItems[props.sorting[0].key] || sortingItems.rulesetInclusion;

  const columns = [
    {
      key,
      style: 'inc-exc',
      label,
      format: inclusionData =>
        GridDataUtils.formatRulesetInclusion(
          inclusionData,
          props.title,
          props.onRowToggle,
          props.config,
          'intra',
          disableToggle,
          props.providerConsumerOrder,
        ),
      sortable: true,
      sortingItems: Object.values(sortingItems),
      sortValue: inclusionData => inclusionData[sort],
    },
    ...ProviderConsumerUtils.setProviderConsumerColumnOrderArrow(
      {
        key: 'provider',
        style: 'provider',
        label: intl('Common.Destination'),
        format: (value, row) =>
          GridDataUtils.formatAppGroupEntities(value, false, row.type, row.targetAddresses, 'provider', row.fqdnType),
      },
      {
        key: 'services',
        style: 'providing-services',
        label: intl('Port.ProtocolProcess'),
        sortable: true,
        format: (services, row) => GridDataUtils.formatAppGroupTrafficService(services, row, false, row.type),
        sortValue: GridDataUtils.sortValueAppGroupTrafficService,
      },
      {
        key: 'consumer_to_provider_arrow',
        style: 'consumerToProviderArrow-pg',
        format: (services, row, arrowDirection) =>
          GridDataUtils.formatAppGroupTrafficService(services, row, false, row.type, arrowDirection),
      },
      {
        key: 'consumer',
        style: 'policygenerator-role',
        label: intl('Common.Source'),
        format: (value, row) =>
          GridDataUtils.formatAppGroupEntities(value, false, row.type, row.sourceAddresses, 'consumer'),
      },
      props.providerConsumerOrder,
    ),
  ];

  const wrapperClasses = cx({
    'PolicyGeneratorGrid-wrapper': true,
    'PolicyGeneratorGrid-wrapper-top': props.progressBar,
  });

  const toolbarClasses = cx({
    'PolicyGeneratorGrid-toolbar': true,
    'PolicyGeneratorGrid-toolbar-none': props.showProgressBar === 'none',
    'PolicyGeneratorGrid-toolbar-top': props.showProgressBar === 'top',
    'PolicyGeneratorGrid-toolbar-inline': props.showProgressBar === 'inline',
  });

  const switchClass = cx({
    'PolicyGeneratorGrid-inc-exc-switch': true,
    'PolicyGeneratorGrid-inc-exc-switch--single': disableToggle,
  });

  return (
    <div className={wrapperClasses}>
      <div className={toolbarClasses}>
        <ToolBar>
          <div className="PolicyGeneratorGrid-title-bar">
            <div className="PolicyGeneratorGrid-title-section">
              <div className="PolicyGeneratorGrid-title">{intl('PolicyGenerator.Grid.Title')}</div>
              <div className="PolicyGeneratorGrid-subtitle">
                <TrafficFilter onSetFilter={props.onTransmissionFilterChange} filters={props.transmissionFilters} />
              </div>
            </div>
          </div>
        </ToolBar>
        <div className="PolicyGeneratorGrid-grid-controls">
          <div className="PolicyGeneratorGrid-inc-exc">
            {props.allMissingRoles ? (
              <div className="PolicyGeneratorGrid-inc-exc-switch">
                <div
                  className="PolicyGeneratorGrid-inc-exc-switch--single PolicyGeneratorGrid-exc-active"
                  data-tid="rule-exclude"
                >
                  {intl('PolicyGenerator.Excluded')}
                </div>
              </div>
            ) : (
              <div className={switchClass}>
                <div
                  className={included ? 'PolicyGeneratorGrid-inc-active' : 'PolicyGeneratorGrid-inc'}
                  onClick={included ? _.noop : props.onIncludeAll}
                  data-tid="rule-include"
                >
                  {props.completeData
                    ? intl('PolicyGenerator.IncludeAll')
                    : intl('PolicyGenerator.IncludeSome', {count: formattedTrafficData.length})}
                </div>
                <div
                  className={excluded ? 'PolicyGeneratorGrid-exc-active' : 'PolicyGeneratorGrid-exc'}
                  onClick={excluded ? _.noop : props.onExcludeAll}
                  data-tid="rule-exclude"
                >
                  {props.completeData
                    ? intl('PolicyGenerator.ExcludeAll')
                    : intl('PolicyGenerator.ExcludeSome', {count: formattedTrafficData.length})}
                </div>
              </div>
            )}
          </div>
          <div className="PolicyGeneratorGrid-grid-Filter">
            <PolicyGeneratorConfigGridFilter
              type={props.type}
              filter={props.filters}
              onAddFilter={props.onAddFilter}
              onRemoveFilter={props.onRemoveFilter}
              /* eslint-enable react/jsx-handler-names */
            />
          </div>
        </div>
        <div>{props.progressBar}</div>
        {props.showProgressBar === 'top' ? (
          <div className="PolicyGeneratorGrid-grid">
            <Grid
              idField="key"
              data={formattedTrafficData}
              columns={columns}
              rowClass={GridDataUtils.formatStatusRowClass}
              onRowSelectToggle={props.onRowSelect}
              sortable={true}
              sorting={props.sorting}
              onSort={props.onSort}
              emptyContent={
                props.rulesetId ? <Banner content={intl('PolicyGenerator.Grid.CoveredByOtherRuleset')} /> : null
              }
            />
          </div>
        ) : null}
      </div>
      {props.showProgressBar === 'top' ? <div className="PolicyGeneratorGrid-empty-header" /> : null}
      <div className="PolicyGeneratorGrid-grid">
        <Grid
          idField="key"
          data={formattedTrafficData}
          columns={columns}
          rowClass={GridDataUtils.formatStatusRowClass}
          onRowSelectToggle={props.onRowSelect}
          sortable={true}
          sorting={props.sorting}
          onSort={props.onSort}
          emptyContent={
            props.rulesetId ? <Banner content={intl('PolicyGenerator.Grid.CoveredByOtherRuleset')} /> : null
          }
        />
      </div>
      {props.trafficData.length > props.maxRows && (
        <div className="PolicyGeneratorGrid-more">
          {intl('PolicyGenerator.Grid.MoreRules', {count: props.trafficData.length - formattedTrafficData.length})}
        </div>
      )}
    </div>
  );
}
