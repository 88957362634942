/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Navigation} from 'react-router';
import StoreMixin from '../mixins/StoreMixin';
import UserMixin from '../mixins/UserMixin';
import ProvisionStore from '../stores/ProvisionStore';

function getStateFromStores2() {
  return {
    status: ProvisionStore.getProvisionStatus(),
    progress: ProvisionStore.getProvisionProgress(),
  };
}

export default React.createClass({
  mixins: [UserMixin, Navigation, StoreMixin(ProvisionStore, getStateFromStores2)],

  render() {
    const {
      state,
      state: {progress},
    } = this;
    const isActive = state.status === 'pending' && progress.red;

    return (
      <div className="ProvisionCount-ProgressItem">
        {intl('Provision.ProvisioningStatus')}
        {isActive && (
          <div className="ProvisionProgress-Status-Bar ProvisionProgress-Status-Bar--Menu" data-tid="statusbar" />
        )}
      </div>
    );
  },
});
