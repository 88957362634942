/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
// eslint-disable-next-line unicorn/prefer-node-protocol -- Import user land 'events' instead of node:events
import EventEmitter from 'events';
import Constants from '../constants';
import dispatcher from '../actions/dispatcher';

const CHANGE_EVENT = 'change';
const renderedComponents = [];

// eslint-disable-next-line unicorn/prefer-event-target -- Use EventEmitter from user land 'events' for compatibility, which resembles Node.js events
class Store extends EventEmitter {
  constructor(spec = {}) {
    super();

    Object.assign(this, spec);

    if (_.isFunction(this.dispatchHandler)) {
      this.dispatchToken = dispatcher.register(this.dispatchHandler.bind(this));
    }
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  register(callback) {
    this.dispatchToken = dispatcher.register(callback);
  }

  mixin() {
    const addChangeListener = this.addChangeListener.bind(this);
    const removeChangeListener = this.removeChangeListener.bind(this);

    return {
      componentDidMount() {
        if (this.name && _.isFunction(this.getStateFromStores)) {
          this.storeChangeListener ||= () => {
            if (this.isMounted()) {
              this.setState(this.getStateFromStores());
            }
          };

          addChangeListener(this.storeChangeListener);
        }
      },
      componentWillUnmount() {
        removeChangeListener(this.storeChangeListener);
      },
      getInitialState() {
        if (!this.state && this.name && !renderedComponents.includes(this.name)) {
          renderedComponents.push(this.name);

          if (_.isFunction(this.getStateFromStores)) {
            return this.getStateFromStores() || {};
          }
        }
      },
    };
  }
}

export function createStore(spec) {
  return new Store(spec);
}

export function createApiStore(apiPrefix, spec, ignoreAction) {
  if (!Array.isArray(apiPrefix)) {
    apiPrefix = [apiPrefix];
  }

  const originDispatchHandler = spec.dispatchHandler;

  spec.dispatchHandler = function (action) {
    const actionType = action.type;

    if (_.some(apiPrefix, prefix => actionType.indexOf(prefix) === 0)) {
      const actionPostfix = actionType.split('_').slice(-1).pop();
      const actionPrefix = actionType.split('_').slice(0, -1).join('_');

      if (ignoreAction && actionType.includes(ignoreAction)) {
        // Don't dispatch any action if the store asks to explicitly ignore an action type
        // Only needed for ORG Store at the moment
        // ORG Store wants to listen to all the "ORG_*" but not "ORG_HEALTH_*"
        return;
      }

      switch (actionPostfix) {
        case 'INIT':
          updateStatus(actionPrefix, Constants.STATUS_BUSY);
          break;
        case 'SUCCESS':
          updateStatus(actionPrefix, Constants.STATUS_SUCCESS);
          break;
        case 'FAIL':
          updateStatus(actionPrefix, Constants.STATUS_FAIL);
          break;
        case 'COMPLETE':
          updateStatus(actionPrefix, Constants.STATUS_IDLE);
          break;
      }
    }

    if (_.isFunction(originDispatchHandler)) {
      originDispatchHandler.call(this, action);
    }
  };

  const store = new Store(spec);

  const status = {};

  function updateStatus(prefix, type) {
    status[prefix] ||= [];

    status[prefix].push({
      timestamp: Date.now(),
      type,
    });

    store.emitChange();
  }

  store.getStatus = () => {
    const statuses = Object.values(status).map(prefix => prefix[prefix.length - 1].type);

    if (!statuses.length) {
      return Constants.STATUS_IDLE;
    }

    if (statuses.includes(Constants.STATUS_BUSY)) {
      return Constants.STATUS_BUSY;
    }

    if (statuses.includes(Constants.STATUS_FAIL)) {
      return Constants.STATUS_FAIL;
    }

    if (statuses.includes(Constants.STATUS_SUCCESS)) {
      return Constants.STATUS_SUCCESS;
    }

    if (statuses.includes(Constants.STATUS_IDLE)) {
      return Constants.STATUS_IDLE;
    }
  };

  return store;
}

export default {createStore, createApiStore};
