/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createApiStore} from '../lib/store';
import StoreUtils from '../utils/StoreUtils';
import Constants from '../constants';

let LoadBalancers = [];
let isLoaded = false;

function setLoadBalancers(data) {
  LoadBalancers = data;
}

function getLoadBalancer(href) {
  return _.find(LoadBalancers, slb => slb.href === href);
}

export default createApiStore(['SLBS_', 'SLB_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SLBS_GET_COLLECTION_SUCCESS:
        setLoadBalancers(action.data);
        isLoaded = true;
        break;

      case Constants.SLBS_GET_INSTANCE_SUCCESS:
        StoreUtils.setObjectByHref(action.data);
        isLoaded = true;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return LoadBalancers || [];
  },

  getSpecified(href) {
    return href ? getLoadBalancer(href) : null;
  },

  isLoaded() {
    return isLoaded;
  },
});
