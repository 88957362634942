/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import {Button} from '.';
import SegmentationTemplateUtils from '../utils/SegmentationTemplateUtils';
import actionCreators from '../actions/actionCreators';
import SegmentationTemplateEula from '../modals/SegmentationTemplateEula.jsx';
import SegmentationTemplateMixin from '../pages/SegmentationTemplates/SegmentationTemplateMixin.jsx';

export default React.createClass({
  propTypes: {
    onViewDetails: PropTypes.func.isRequired,
    template: PropTypes.object.isRequired,
  },

  mixins: [SegmentationTemplateMixin],

  handleViewDetails() {
    this.props.onViewDetails({id: this.props.template.key});
  },

  handleEula() {
    actionCreators.openDialog(<SegmentationTemplateEula onInstall={this.handleInstall} />);
  },

  render() {
    const {props} = this;
    const logo = this.getLogo(props.template.icon, 'Template-logo');

    return (
      <div className="Template-card" data-tid="templates-catalogue-card">
        {logo}
        <div className="Template-summary">
          <div className="Template-title">
            <div data-tid="templates-catalogue-name" className="Template-title-text" onClick={this.handleViewDetails}>
              {this.props.template.name}
            </div>
            {this.props.showButton && (
              <Button
                data-tid="templates-catalogue-button-install"
                text={SegmentationTemplateUtils.getInstallText(props.template.status)}
                onClick={this.handleEula}
                disabled={
                  props.template.status === 'installed' ||
                  props.template.status === 'invalidVersion' ||
                  props.disableInstall
                }
              />
            )}
          </div>
          {this.getVersion(props.template)}
          <div className="Template-content" data-tid="templates-catalogue-content">
            <div className="Template-key">{intl('SegmentationTemplates.Content')}</div>
            <div className="Template-values">{SegmentationTemplateUtils.getContent(props.template)}</div>
          </div>
        </div>
      </div>
    );
  },
});
