/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createApiStore} from '../lib/store';
import Constants from '../constants/Constants';
import {SegmentationTemplateUtils} from '../utils';

let segmentationTemplates = {};
let segmentationTemplateRulesets = {};
let segmentationTemplateLabels = {};
let segmentationTemplateServices = {};
let segmentationTemplateIpLists = {};
let segmentationTemplateLabelGroups = {};
let installedTemplates = [];
let newInstalledTemplates = [];
let fileError;
let installStatusCalculated;

const colors = ['#2980B9', '#27AE60', '#FFAE00', '#E64C70', '#9467BD'];

function validateLabels(labels) {
  const validLabels = [];

  labels.forEach(label => {
    if (!label.key || !label.value || !['app', 'env', 'loc', 'role'].includes(label.key)) {
      fileError = true;

      return;
    }

    const validLabel = {key: label.key, value: label.value};

    if (validLabels.some(label => label.value === validLabel.value && label.key === validLabel.key)) {
      return;
    }

    validLabels.push(validLabel);
  });

  return validLabels;
}

function validateLabelGroups(labelGroups) {
  const validLabelGroups = [];

  labelGroups.forEach(labelGroup => {
    if (!labelGroup.key || !labelGroup.name || !['app', 'env', 'loc', 'role'].includes(labelGroup.key)) {
      fileError = true;

      return;
    }

    const validLabels = labelGroup.labels && labelGroup.labels.length ? validateLabels(labelGroup.labels) : [];
    const description = labelGroup.description ? labelGroup.description : '';
    const validLabelGroup = {key: labelGroup.key, name: labelGroup.name, labels: validLabels, description};

    validLabelGroups.push(validLabelGroup);
  });

  return validLabelGroups;
}

function validateRulesets(rulesets) {
  const validRulesets = [];

  rulesets.forEach(ruleset => {
    if (!ruleset.name || !ruleset.scopes || !ruleset.key) {
      fileError = true;

      return;
    }

    const validRuleset = {key: ruleset.key, name: ruleset.name, scopes: ruleset.scopes, enabled: ruleset.enabled};

    validRuleset.description = ruleset.description ? ruleset.description : '';

    if (validRuleset.description.length >= 255) {
      validRuleset.description = validRuleset.description.slice(0, 254);
    }

    if (ruleset.rules) {
      validRuleset.rules = validateRules(ruleset.rules);
    }

    validRulesets.push(validRuleset);
  });

  return validRulesets;
}

function validateRules(rules) {
  const validRules = [];

  rules.forEach(rule => {
    if (
      !rule.consumers ||
      !rule.providers ||
      (!rule.service && !rule.ingress_services && !rule.ingress_services.length)
    ) {
      fileError = true;

      return;
    }

    const validRule = {
      providers: rule.providers,
      consumers: rule.consumers,
      sec_connect: rule.sec_connect,
      unscoped_consumers: rule.unscoped_consumers,
    };

    if (rule.ingress_services) {
      validRule.ingress_services = rule.ingress_services;
    } else {
      validRule.ingress_services = [rule.service];
    }

    validRule.consuming_security_principals = rule.consuming_security_principals
      ? rule.consuming_security_principals
      : [];
    validRule.resolve_labels_as = rule.resolve_labels_as
      ? rule.resolve_labels_as
      : {providers: ['workloads'], consumers: ['workloads']};

    validRules.push(validRule);
  });

  return validRules;
}

function validateIpLists(ipLists) {
  const validIpLists = [];

  ipLists.forEach(ipList => {
    if (!ipList.ip_ranges || !ipList.name) {
      fileError = true;

      return;
    }

    validIpLists.push({ip_ranges: ipList.ip_ranges, name: ipList.name});
  });

  return validIpLists;
}

function validateServices(services) {
  const validServices = [];

  services.forEach(service => {
    if (!service.name || (!service.windows_services && !service.service_ports) || !service.key || !service.key.length) {
      if (!service.name || (service.name !== 'All Services' && service.name !== 'ICMP')) {
        fileError = true;

        return;
      }
    }

    const validService = {name: service.name, key: service.key};

    if (service.service_ports) {
      validService.service_ports = service.service_ports;
    } else if (service.windows_services) {
      validService.windows_services = service.windows_services;
    }

    if (!validService.windows_services && !validService.service_ports) {
      fileError = true;

      return;
    }

    if (service.description) {
      validService.description = service.description;

      if (validService.description.length >= 255) {
        validService.description = validService.description.slice(0, 254);
      }
    }

    validServices.push(validService);
  });

  return validServices;
}

function getTemplateIcon(template) {
  if (SegmentationTemplateUtils.getPngIcons().includes(template.icon)) {
    return template.icon;
  }

  const color = colors.shift();

  colors.push(color);

  if (!template.icon) {
    return template.name[0].toUpperCase() + color;
  }

  if (template.icon.length > 2) {
    return template.icon[0].toUpperCase() + color;
  }

  return template.icon.toUpperCase() + color;
}

function validateSegmentationTemplateData(template) {
  template.key = `${template.name.split(' ').join('-').toLowerCase()}_${template.version}`;
  template.dependencies = template.dependencies ? template.dependencies : [];
  template.change_note = template.change_note ? template.change_note : '';
  template.icon = getTemplateIcon(template);
  template.updated_at = template.updated_at ? template.updated_at : 'Unknown';
  template.version = template.version ? template.version : 1;

  if (template.pce_compatibility) {
    //todo Determine some min/max default values for pce version
    template.pce_compatibility.minVersion = template.pce_compatibility.minVersion
      ? template.pce_compatibility.minVersion
      : '0';
    template.pce_compatibility.maxVersion = template.pce_compatibility.maxVersion
      ? template.pce_compatibility.maxVersion
      : '0';
  } else {
    template.pce_compatibility = {min_version: '', max_version: ''};
  }

  template.rule_sets = template.rule_sets ? validateRulesets(template.rule_sets) : [];
  template.services = template.services ? validateServices(template.services) : [];
  template.labels = template.labels ? validateLabels(template.labels) : [];
  template.label_groups = template.label_groups ? validateLabelGroups(template.label_groups) : [];
  template.ip_lists = template.ip_lists ? validateIpLists(template.ip_lists) : [];
  template.status = getTemplateStatus(template);
}

function getTemplateStatus(template, newStatus) {
  if (newStatus) {
    return newStatus;
  }

  let status = 'notInstalled';
  const templateData = `${template.name} - Version ${template.version}`;

  if (installedTemplates.includes(templateData)) {
    status = 'installed';

    const increasedVersion = template.version + 1;

    if (installedTemplates.includes(`${template.name} - Version ${increasedVersion}`)) {
      status = 'invalidVersion';
    }
  } else if (template.version > 1) {
    const possibleInstalledVersions = [];

    for (let version = 1; version < template.version; version++) {
      possibleInstalledVersions.push(`${template.name} - Version ${version}`);
    }

    possibleInstalledVersions.forEach(version => {
      if (installedTemplates.includes(version)) {
        status = 'updateAvailable';
      }
    });
  }

  return status;
}

function compareInstallationStatus() {
  if (
    installedTemplates.length !== newInstalledTemplates.length ||
    _.difference(installedTemplates, newInstalledTemplates).length
  ) {
    installedTemplates = [...newInstalledTemplates];
    updateAllTemplateStatus();
  }

  newInstalledTemplates = [];
  installStatusCalculated = true;
}

function setStatus(data) {
  segmentationTemplates[data.key].status = getTemplateStatus(segmentationTemplates[data.key], data.status);
}

function updateAllTemplateStatus() {
  for (const template in segmentationTemplates) {
    segmentationTemplates[template].status = getTemplateStatus(segmentationTemplates[template]);
  }
}

function setSegmentationTemplates(data) {
  segmentationTemplates = {};
  data.forEach(template => {
    validateSegmentationTemplateData(template);
    segmentationTemplates[template.key] = template;
  });
}

function setEntitiesWithKeys(entitiesFromStore, entityObject) {
  entitiesFromStore.forEach(entity => {
    if (entity.external_data_set === 'illumio_segmentation_templates') {
      let entityKey = entity.external_data_reference.split(' -- ')[0];

      if (SegmentationTemplateUtils.isEntityEdited(entity.external_data_reference)) {
        entity.isEdited = true;
        entityKey = SegmentationTemplateUtils.getEntityKey(entityKey);
      }

      entityObject[entityKey] = entity;

      const entityTemplates = entity.external_data_reference.split(' -- ')[1].split(' & ');

      if (!entity.deleted_at) {
        entityTemplates.forEach(templateName => {
          if (!newInstalledTemplates.includes(templateName)) {
            newInstalledTemplates.push(templateName);

            if (templateName.split(' - Version ')[1] > 1) {
              for (let i = 1; i < templateName.split(' - Version ')[1]; i++) {
                newInstalledTemplates.push(`${templateName.split(' - Version ')[0]} - Version ${i}`);
              }
            }
          }
        });
      }
    }
  });
}

export default createApiStore(['SEGMENTATION_TEMPLATE_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SEGMENTATION_TEMPLATE_PARSE_DATA:
        fileError = false;
        setSegmentationTemplates(action.data[0].illumio_security_templates);
        break;

      case Constants.LABELS_GET_COLLECTION_SUCCESS:
        if (
          action.options &&
          action.options.query &&
          action.options.query.external_data_set === 'illumio_segmentation_templates'
        ) {
          segmentationTemplateLabels = {};
          setEntitiesWithKeys(action.data, segmentationTemplateLabels);
        }

        break;

      case Constants.LABEL_GROUPS_GET_COLLECTION_SUCCESS:
        if (
          action.options &&
          action.options.query &&
          action.options.query.external_data_set === 'illumio_segmentation_templates'
        ) {
          segmentationTemplateLabelGroups = {};
          setEntitiesWithKeys(action.data, segmentationTemplateLabelGroups);
        }

        break;

      case Constants.SERVICES_GET_COLLECTION_SUCCESS:
        if (
          action.options &&
          action.options.query &&
          action.options.query.external_data_set === 'illumio_segmentation_templates'
        ) {
          segmentationTemplateServices = {};
          setEntitiesWithKeys(action.data, segmentationTemplateServices);
        }

        break;

      case Constants.RULE_SETS_GET_COLLECTION_SUCCESS:
        if (
          action.options &&
          action.options.query &&
          action.options.query.external_data_set === 'illumio_segmentation_templates'
        ) {
          segmentationTemplateRulesets = {};
          setEntitiesWithKeys(action.data, segmentationTemplateRulesets);
        }

        break;

      case Constants.IP_LISTS_GET_COLLECTION_SUCCESS:
        if (
          action.options &&
          action.options.query &&
          action.options.query.external_data_set === 'illumio_segmentation_templates'
        ) {
          segmentationTemplateIpLists = {};
          setEntitiesWithKeys(action.data, segmentationTemplateIpLists);
        }

        break;

      case Constants.SEGMENTATION_TEMPLATE_SET_STATUS:
        setStatus(action.data);
        break;

      case Constants.SEGMENTATION_TEMPLATES_COMPARE_INSTALLED_TEMPLATES:
        compareInstallationStatus();
        break;

      case Constants.SEGMENTATION_TEMPLATES_CLEAR_INSTALLED_TEMPLATES:
        newInstalledTemplates = [];
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return fileError ? 'error' : segmentationTemplates;
  },

  getTemplate(id) {
    return segmentationTemplates[id];
  },

  getSegmentationTemplateServices() {
    return segmentationTemplateServices;
  },

  getSegmentationTemplateLabels() {
    return segmentationTemplateLabels;
  },

  getSegmentationTemplateLabelGroups() {
    return segmentationTemplateLabelGroups;
  },

  getSegmentationTemplateIpLists() {
    return segmentationTemplateIpLists;
  },

  getSegmentationTemplateRulesets() {
    return segmentationTemplateRulesets;
  },

  getInstallStatus() {
    return installStatusCalculated;
  },
});
