/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';

export default React.createClass({
  propTypes: {
    data: PropTypes.array,
    expanded: PropTypes.bool,
    maxDisplay: PropTypes.number,
    expandMessage: PropTypes.string,
    tid: PropTypes.string,
    onExpandCollapse: PropTypes.func,
    collapseMessage: PropTypes.string,
    href: PropTypes.string,
  },

  getDefaultProps() {
    return {
      data: [],
      expanded: false,
      maxDisplay: 10,
    };
  },

  handleExpandCollapse(evt) {
    evt.preventDefault();

    const {data, maxDisplay} = this.props;

    // Do not expand/collapse the count is max display + 1 or less
    if (data.length > maxDisplay + 1) {
      this.props.onExpandCollapse(this.props.href);
    }
  },

  render() {
    const {data, expanded, maxDisplay, expandMessage, collapseMessage} = this.props;
    let displayData = data.map((data, index) => (
      <tr key={index} className="ExpandableGridList-Row">
        <td className="ExpandableGridList-Data" onClick={this.handleExpandCollapse}>
          {data}
        </td>
      </tr>
    ));

    // Show all if we are only hiding one
    if (!expanded && data.length > maxDisplay + 1) {
      //truncate the data to the max display length
      displayData = displayData.slice(0, maxDisplay);

      if (expandMessage) {
        displayData.push(
          <tr key={maxDisplay + 1} className="ExpandableGridList-Row">
            <td className="Grid-link Note" onClick={this.handleExpandCollapse}>
              {expandMessage}
            </td>
          </tr>,
        );
      }
    }

    if (expanded && this.props.collapseMessage) {
      displayData.push(
        <tr key="message" className="ExpandableGridList-Row">
          <td className="Grid-link Note" onClick={this.handleExpandCollapse}>
            {collapseMessage}
          </td>
        </tr>,
      );
    }

    return (
      <table className="ExpandableGridList">
        <tbody>{displayData}</tbody>
      </table>
    );
  },
});
