/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';

const hasOwn = Object.prototype.hasOwnProperty;

/**
 * Function to deeply/recursively check if a key is present in an object
 *
 * @param key
 * @returns {boolean}
 */
export const keyExists = (obj, key) => {
  for (const sKey in obj) {
    // No need to do hasOwnProperty since we are checking the key against a specific string
    if (sKey === key || (typeof obj[sKey] === 'object' && obj[sKey] !== null && keyExists(obj[sKey], key))) {
      return true;
    }
  }
};

/**
 * Function to recursively search for a given key in an Object and return the array of values
 *
 * @param obj
 * @param key
 * @param arr
 * @returns {*|Array}
 */
export const deepPluck = (obj, key, arr = []) => {
  for (const sKey in obj) {
    if (sKey === key) {
      arr.push(obj[key]);
    }

    if (typeof obj[sKey] === 'object' && obj[sKey] !== null) {
      deepPluck(obj[sKey], key, arr);
    }
  }

  return arr;
};

/**
 * Stringify items in a consistent way to return a key
 *
 * @param {*}       items - Can be anything
 * @return {string} Plain string of the passed argument
 */
export const generateKey = items => {
  if (items instanceof Object) {
    if (Array.isArray(items)) {
      const objectStringMap = new Map();
      const sortedItems = [...items].sort((a, b) => {
        let itemA;
        let itemB;

        if (a instanceof Object) {
          if (objectStringMap.has(a)) {
            itemA = objectStringMap.get(a);
          } else {
            itemA = generateKey(a);
            objectStringMap.set(a, itemA);
          }
        } else {
          itemA = String(a);
        }

        if (b instanceof Object) {
          if (objectStringMap.has(b)) {
            itemB = objectStringMap.get(b);
          } else {
            itemB = generateKey(b);
            objectStringMap.set(b, itemB);
          }
        } else {
          itemB = String(b);
        }

        if (itemA > itemB) {
          return 1;
        }

        return itemA < itemB ? -1 : 0;
      });

      return `[${sortedItems
        .map(item => {
          if (item instanceof Object) {
            if (objectStringMap.has(item)) {
              return objectStringMap.get(item);
            }

            return generateKey(item);
          }

          return `"${String(item)}"`;
        })
        .join(',')}]`;
    }

    return Object.keys(items)
      .sort()
      .map(key => {
        if (items[key] instanceof Object) {
          return `{${key}:${generateKey(items[key])}}`;
        }

        return `{${key}:"${String(items[key])}"}`;
      })
      .join(',');
  }

  return `"${String(items)}"`;
};

/**
 * Get minimum and maximum values for each of specified object properties in set of objects
 * First parameter - array(or object) of objects
 * Rest parameters - name/path/function for resolving value
 *
 * Returns object with property for each field, value of which contains object with 'min' and 'max' values for this field.
 * If only one field specified 'min' and 'max' values can be get directly frome result
 * Each field value can be also accessed with its position in incoming fields param (function field can be accessed only through position)
 *
 * @motivation
 * If we want to take minimum and maximum value of some property of array objects we usually use _.minBy/_.maxBy
 * But in this case we do 2 cycles fo array with all calculation logic inside.
 * Moreover, if we want to take min/max values of several properties we have to multiply this 2 cycles by number of properties
 * This function calculate min/max for any number of properties in one cycle!
 * Furthermore, this method returns calculated values, unlike _.minBy/maxBy that return object
 * Also it can handle object of objects, so you don't need to convert object to array
 *
 * @example
 * const items = [{x:1}, {x: 20}, {x: 10}, {x: 5}]
 * const {min, max} = minmaxFor(items, 'x');
 * console.log(min, max);
 * ==> 1 20
 *
 * @example
 * const items = [{x:1, y: -1}, {x: 20}, {x: 10, y: 9}, {x: 5, y: 5}]
 * const {x: {min: minX, max: maxX}, y: {min: minY, max: maxY}} = minmaxFor(items, 'x', 'y');
 * console.log(`x:${minX} ${maxX}, y:${minY} ${maxY}`);
 * ==> x: 1 20,  y: -1 9
 *
 * @example
 * const items = [{x: -11, z: {y: 1}}, {x: 2}, {x: 10, z: {y: -9}}, {x: 5, z: {y: 9}}]
 * const {0: {min: minX = 0, max:  maxX = 0}, 1: {min: minY = 0, max: maxY = 0}} = minmaxFor(items, item => item.x*2, 'z.y');
 * console.log(`x:${minX} ${maxX}, y:${minY} ${maxY}`);
 * ==> x:-22 20, y:-9 9
 *
 * @example
 * const items = [{x: {y: {z: 1}}}, {x: {y: {z: -9}}}, {x: {y: {z: 9}}}, {x: {y: 5}}, {x: {y: {z: 4}}}]
 * const {'x.y.z': {min}} = minmaxFor(items, 'x.y.z');
 * console.log('minimum z:', min);
 * ==> minimum z: -9
 *
 * @param {(Array|Object)} items Set objects
 * @param fields Fields(name/path/function) for getting value from each object
 * @returns {*}
 */
export function minmaxFor(items, ...fields) {
  if (!fields.length) {
    return {};
  }

  const result = {};
  const fieldsWithInfo = fields.map((field, index) => {
    const isFunction = typeof field === 'function';
    const name = isFunction ? index : field;

    result[name] = result[index] = {};

    return {field, name, isFunction};
  });

  _.each(items, (item = {}) => {
    for (const {field, name, isFunction} of fieldsWithInfo) {
      let value;

      if (isFunction) {
        // If field is function, call it with current item
        value = field(item);
      } else {
        // Try to take as property directly from item
        value = item[name];

        // If such property doesn't exists, try to take as path with lodash get
        if (value === undefined) {
          value = _.get(item, name);
        }
      }

      if (typeof value !== 'number' || isNaN(value)) {
        continue;
      }

      const minmax = result[name];

      if (value < minmax.min) {
        minmax.min = value;
      } else if (value > minmax.max) {
        minmax.max = value;
      } else if (minmax.min === undefined) {
        // If min doesn't exist, means that it's first iteration - set value as min and max
        minmax.min = minmax.max = value;
      }
    }
  });

  // If specified only one field, we can assign min/max directly to result object for easier access
  if (fieldsWithInfo.length === 1) {
    Object.assign(result, result[fieldsWithInfo[0].name]);
  }

  return result;
}

const defaultSeparator = '.';
const defaultFilter = false;
const defaultPrefix = '';
const defaultDepth = 0;

/**
 * Creates new object (with specified depth) from transferred object by flattening its keys
 *
 * @param {Object} obj Object to flatten
 * @param {Object} [options] Options
 * @param {string} [options.prefix=''] Prefix for all keys
 * @param {string} [options.separator='.'] Character to use as a separator
 * @param {number} [options.depth=0] Maximum depth to recurse to. Zero or null is unlimited
 * @param {Function} [options.filter=false] Function to filter values. Has three parameters: value, key, newKey
 * @param resultObj
 *
 * @example
 *     flattenObject({
 *       a: {
 *         b: {
 *           c: 'test1',
 *           d: 'test2',
 *           e: { f: 1 }
 *         }
 *       },
 *       g: 1,
 *       h: null
 *     }, { depth: 3 });
 *     // returns
 *     {
 *       'a.b.c': 'test1',
 *       'a.b.d': 'test2',
 *       'a.b.e': { f: 1 },
 *       'g': 1,
 *       'h': null
 *     }
 *
 * @returns {*}
 */
export function flattenObject(obj, options = {}, resultObj = Object.create(null)) {
  const {separator = defaultSeparator, filter = defaultFilter, prefix = defaultPrefix, depth = defaultDepth} = options;

  options.depthCurrent = Math.trunc(options.depthCurrent) + 1;

  const isLastLevel = depth && options.depthCurrent >= depth;

  _.forOwn(obj, (value, key) => {
    const newKey = prefix + key;

    if (!isLastLevel && _.isPlainObject(value) && (!filter || filter(value, key, newKey))) {
      options.prefix = newKey + separator;

      flattenObject(value, options, resultObj);
    } else {
      resultObj[newKey] = value;
    }
  });

  return resultObj;
}

/**
 * Returns new object with sorted object keys by specified comparator
 * ECMA doesn't guarantee object keys order, but browser implementations usually keep insertion order
 *
 * @param {Object} obj            - Object to sort keys
 * @param {Function} [comparator] - Function that defines the sort order
 *                                  Optional, by default sort as strings in Unicode code point order
 *                                  Receive three parameters, instead of usually two - (keyA, keyB, object),
 *                                  so you can take value of keys if needed
 * @param {Boolean} [deep=true]   - Sort all subobjects recursively, true by default
 * @returns {Object}
 *
 * @example
 * // Sort object's keys, but keys with string values first, with object values next
 * sortObjectKeys(languageBundle, (a, b, obj) => {
 *   const aIsObject = _.isPlainObject(obj[a]);
 *   const bIsObject = _.isPlainObject(obj[b]);
 *
 *   if (!aIsObject && !bIsObject || aIsObject && bIsObject) {
 *     return a > b ? 1 : -1;
 *   }
 *
 *   return aIsObject ? 1 : -1;
 * })
 *
 */
export const sortObjectKeys = (obj, comparator, deep = true) =>
  Object.keys(obj)
    .sort(comparator ? (a, b) => comparator(a, b, obj) : undefined)
    .reduce((result, key) => {
      const value = obj[key];

      result[key] = deep && _.isPlainObject(value) ? sortObjectKeys(value, comparator, deep) : value;

      return result;
    }, {});

// Very fast objects equality check
export const shallowEqual = (objA, objB) => {
  if (objA === objB) {
    return true;
  }

  const keysA = Object.keys(objA);

  return (
    // Check that length is equal
    keysA.length === Object.keys(objB).length &&
    // And every key of A is contained in B and has the same value
    keysA.every(key => hasOwn.call(objB, key) && objA[key] === objB[key])
  );
};

/**
 * Function to check whether a given "string" or "number" is a valid number
 * (Optional) and is bounded within the parameters [including]
 *
 * @param {number|string} num
 * @param {number} [min=num]
 * @param {number} [max=num]
 * @returns {boolean}
 */
export const isValidNumber = (num, min = num, max = num) => {
  if (typeof num === 'number') {
    num = String(num);
  }

  const str = num.trim();
  const n = Math.trunc(Number(str));

  return String(n) === str && n >= min && n <= max;
};

export const isNumeric = value => {
  return /^-?\d+$/.test(value);
};

/**
 * Given a value, check if it's typeof belongs in an array of primitive types
 *
 * @param value
 * @param {Array} [types] Array of types
 */
export const isTypeof = (value, types) => {
  if (!types) {
    return false;
  }

  return types.includes(typeof value);
};

/**
 * Given a value, check if it's typeof belongs to "number" or "string"
 *
 * @param value
 */
export const isNumberOrString = value => isTypeof(value, ['number', 'string']);

/**
 * Given version to parse e.g. version = '20.2.0.UI1-2719' | '20.2.0+UI1-2719' | '20.2.0-UI1-2719'
 * @param version
 */
export const getVersion = version => {
  // version = "20.2.0.UI1-2719"
  // (\d+\.\d+\.\d+) - match 3 digits folowed by a dot(.) e.g. 20.2.0
  const regex = /^(\d+\.\d+\.\d+).*/;

  if (regex.test(version)) {
    return regex.exec(version)[1];
  }
};

export const parseVersion = rawVersion => {
  const version = getVersion(rawVersion);

  if (version) {
    return _.mapValues(version.match(/^(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+).*/).groups, Number);
  }
};

/** Determine version mismatch between PCE and UI Version.
 *  to determine whether PCE or UI is ahead or behind.
 *  @param pceVersion pce version
 *  @param parameterized boolean to determine if parameterized build
 */
export const getVersionMismatch = ({pceVersion, parameterized = false} = {}) => {
  if (!__CHECK_VERSIONS_MATCH__) {
    return 0;
  }

  const uiVersion = getVersion(process.env.UI_VERSION);

  /* When PCE version(backend) and the UI version(package.json) do not match.
   * PCE is a development parameterized build when isParameterizedBuild === true
   * localCompare() when versions match.
   * NOTE: Negative and positive integer results vary between browsers (as well as between browser versions)
   * because the W3C specification only mandates negative and positive values
   * e.g. pceVersion 18.2.0, uiVersion 18.3.0 : any negative e.g. -1, -2, etc...
   * e.g. pceVersion 18.3.0, uiVersion 18.2 : 1 any positive e.g. 1, 2, etc...
   * e.g. pceVersion 18.2.0, uiVersion 18.2 : 0 - match was made
   */
  return parameterized === true ? 0 : pceVersion.localeCompare(uiVersion);
};

/**
 * Return item's true value or undefined. This converts empty string, null to undefined.
 * @param {*} item
 * @returns {undefined|*}
 */
export const getTrueValue = item => {
  if (typeof item === 'string') {
    item = item.trim();
  }

  if (item || item === 0) {
    return item;
  }
};

/**
 * Equal if the items' true value equals
 * @param {*} item1
 * @param {*} item2
 * @returns {boolean}
 */
export const looseEqual = (item1, item2) => getTrueValue(item1) === getTrueValue(item2);

/**
 * Verify whether the item is empty as initialized
 * @param {*} item The item to be checked
 * @param {Object} options The options: includes is considered before excludes if both are given
 * @param {Array} [options.includes=[]] Only check the composed keys in includes for the given path, if item is an object/array
 * @param {Array} [options.excludes=[]] Only check the composed keys not in excludes, if item is an object/array
 *     Example in WorkloadCreate: GeneralUtils.isDeepEmpty(this.state, {includes: ['interfaces.text'], excludes: ['status', 'newService.address']})
 *     Example in VirtualServiceCreate: isDeepEmpty(this.state.virtualService, {includes: ['ipRanges.text'], excludes: ['apply_to']})
 * @param {string} currentPath The current path for includes and excludes
 * @returns {boolean}
 */
export const isDeepEmpty = (item, options = {}, currentPath = '') => {
  const {includes = [], excludes = []} = options;

  if (Array.isArray(item)) {
    if (!item.length) {
      return true;
    }

    // sometimes, the array item is initialized, but no value assigned. Need to check everyone of them.
    for (const value of item) {
      if (!isDeepEmpty(value, options, currentPath)) {
        return false;
      }
    }

    return true;
  }

  if (_.isObject(item)) {
    const pathLength = currentPath === '' ? 0 : currentPath.length + 1; // include '.' if not empty
    let currentKeys = [];
    let useInclude = false;

    // if includes is specified
    if (includes.length > 0) {
      // check whether items in includes are applicable to the current path
      for (const composedKey of includes) {
        if (composedKey.startsWith(currentPath)) {
          const singleKey = composedKey.substring(pathLength);

          if (singleKey.length > 0 && !singleKey.includes('.')) {
            currentKeys.push(singleKey);
          }
        }
      }
    }

    if (currentKeys.length > 0) {
      useInclude = true;
    } else {
      // if no items includes are applicable, loop through all keys
      currentKeys = Object.keys(item);
    }

    // check applicable includes or object keys
    for (const singleKey of currentKeys) {
      const composedKey = pathLength === 0 ? singleKey : `${currentPath}.${singleKey}`;

      // excludes is considered only if not useInclude
      if (useInclude || !excludes.includes(composedKey)) {
        if (!isDeepEmpty(item[singleKey], options, composedKey)) {
          return false;
        }
      }
    }

    return true;
  }

  return getTrueValue(item) === undefined;
};

export const isContainerClusterHref = href => typeof href === 'string' && href.includes('/container_clusters/');
export const isVENHref = href => typeof href === 'string' && href.includes('/agents/');

/**
 * Gets the id at the end of the href string.
 * 'some/string/123' -> '123'
 * 'asdf/asd/S-1234' -> 'S-1234'
 *
 * @param {string} href
 * @returns {string}
 */
export const getId = href => href.slice(href.lastIndexOf('/') + 1);

// Util to check if OS is Mac - useful for determining keyboard keys
export const isMac = () => browser.os.name === 'macOS';

export const cmdOrCtrlPressed = evt => (isMac() && evt.metaKey) || (!isMac() && evt.ctrlKey);

// Convert a slug based string to camel-case e.g. "foo_bar" -> "fooBar"
export const slugToCamelCase = (str, slug) =>
  str
    .replace(slug, ' ')
    .replaceAll(/\s[a-z]/g, str => str.toUpperCase())
    .replace(' ', '');

// Slugify a camel-case string e.g. "fooBar -> "foo_bar"
export const camelCaseToSlug = (str, delimeter = '_') => {
  str = str.replaceAll(/([a-z\u00E0-\u00FF])([A-Z\u00C0\u00DF])/g, '$1 $2');
  str = str.toLowerCase();

  return str.replace(' ', delimeter);
};

export default {
  keyExists,
  deepPluck,
  generateKey,
  minmaxFor,
  shallowEqual,
  flattenObject,
  sortObjectKeys,
  isValidNumber,
  isTypeof,
  isNumberOrString,
  isMac,
  getVersion,
  getVersionMismatch,
  getTrueValue,
  looseEqual,
  isDeepEmpty,
  getId,
  slugToCamelCase,
  camelCaseToSlug,
  isNumeric,
};
