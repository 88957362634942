/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import {ComponentUtils} from '../../utils';
import {Icon} from '..';

const defaultTid = 'comp-field';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
    hintBelow: PropTypes.bool,
    errorString: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    onLabelClick: PropTypes.func,
    hideLabel: PropTypes.bool,
    customStyle: PropTypes.string,
    onHelpClick: PropTypes.func,
    showHelpModel: PropTypes.object,
  },

  getDefaultProps() {
    return {
      onLabelClick: _.noop,
      onHelpClick: _.noop,
    };
  },

  render() {
    const classes = cx({
      'ReForm-Field': true,
      'ReForm-Field--error': this.props.error,
      'ReForm-Field--disabled': this.props.disabled,
      'ReForm-Field--hideLabel': this.props.hideLabel,
      [`ReForm-Field--${this.props.customStyle}`]: this.props.customStyle,
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
        {this.props.help ? (
          <div
            className={cx('ReForm-Field-Help', {
              'ReForm-Field-Help--Hide': !this.props.showHelpModel[this.props.label],
            })}
            data-tid="comp-field-help"
          >
            {this.props.help}
            <div className="ReForm-Field-Help-Close">
              <Icon name="close" size="xlarge" onClick={this.props.onHelpClick} />
            </div>
          </div>
        ) : null}
        {this.props.hint && !this.props.hintBelow ? (
          <div className="ReForm-Field-Hint" data-tid="comp-field-hint">
            {this.props.hint}
          </div>
        ) : null}
        <div className="ReForm-Field-Inner">
          <div className="ReForm-Field-Label" data-tid="comp-field-label" onClick={this.props.onLabelClick}>
            {this.props.required ? (
              <span className="ReForm-Field-Required" data-tid="comp-field-required">
                *
              </span>
            ) : null}
            <span data-tid="elem-text">
              {this.props.label}
              {this.props.error ? (
                <span className="ReForm-Field-ErrorIcon" data-tid="comp-field-erroricon">
                  <Icon name="error" />
                </span>
              ) : null}
            </span>
          </div>
          <div className="ReForm-Field-Controls" data-tid="comp-field-controls">
            {this.props.children}
            {this.props.help ? (
              <div className="ReForm-Field-Controls-HelpIcon">
                <Icon name="help" size="large" onClick={this.props.onHelpClick} />
              </div>
            ) : null}
          </div>
        </div>
        {this.props.hint && this.props.hintBelow ? (
          <div className="ReForm-Field-HintBottom" data-tid="comp-field-hint">
            {this.props.hint}
          </div>
        ) : null}
        <div className="ReForm-Field-Error" data-tid="comp-field-error">
          {this.props.error && this.props.errorString ? this.props.errorString : null}
        </div>
      </div>
    );
  },
});
