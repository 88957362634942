/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';
import {Grid, Pagination, Badge, RadioGroup} from '../components';
import {ToolBar, ToolGroup} from '../components/ToolBar';
import ExplorerAddressesDialog from 'scripts/modals/ExplorerAddressesDialog';
import {GeneralStore} from '../stores';
import {ServiceUtils, RestApiUtils} from '../utils';

const MAX_RESULTS_PER_PAGE = 50;

export default React.createClass({
  propTypes: {
    onClose: PropTypes.func,
  },

  getDefaultProps() {
    return {
      onClose: _.noop,
    };
  },

  getInitialState() {
    const sorting = GeneralStore.getSorting('virtualServiceExplorerTable');

    return {
      newServices: this.getNewServices(this.props.virtualServices),
      creationType: 'port',
      sorting: sorting || [{key: 'name', direction: true}],
      currentPage: 1,
    };
  },

  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          this.handleComplete();
        },
      },
      {
        text: intl('Common.Back'),
        tid: 'back',
        type: 'nofill',
        onClick: () => {
          this.handleClose();
        },
      },
      {
        text: intl('Common.Save'),
        type: 'primary',
        tid: 'save',
        onClick: () => {
          this.handleOnSave();
        },
      },
    ];
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.virtualServices, this.props.virtualServices)) {
      this.setState({currentPage: 1});
    }
  },

  getNewServices() {
    return Object.values(
      this.props.virtualServices.reduce((result, virtualService, index) => {
        if (!virtualService.matchedService) {
          const key = Object.values(virtualService.service_ports).join(',');

          if (result[key]) {
            result[key].indexes.push(index);
          } else {
            const {port, proto} = virtualService.service_ports[0];

            result[key] = {
              indexes: [index],
              service: {
                name: intl('PolicyGenerator.ServiceName', {port, protocol: ServiceUtils.lookupProtocol(proto)}),
                description: '',
                service_ports: [{port, proto}],
              },
            };
          }
        }

        return result;
      }, {}),
    );
  },

  handleServiceTypeSelect(evt) {
    this.setState({creationType: evt.target.value});
  },

  handleCompleteRemoveDomain() {
    this.props.onComplete();
  },

  async createServices() {
    let newVirtualServcices = [...this.props.virtualServices];

    for (const serviceChunk of _.chunk(this.state.newServices, 5)) {
      // Create new services for all the ports without existing services
      try {
        newVirtualServcices = await Promise.all(
          serviceChunk.map(service => this.createService(service, newVirtualServcices)),
        );
        newVirtualServcices = newVirtualServcices[0];
      } catch {
        // Backout all the created services
        if (this.createdServices) {
          RestApiUtils.services.delete(this.createdServices.map(href => ({href})));
        }

        this.servicesFailed = true;

        return 'error';
      }
    }

    RestApiUtils.services.getCollection({}, 'draft', true);

    return newVirtualServcices;
  },

  async createService(service, virtualServices) {
    const response = await RestApiUtils.services.create(service.service, 'draft', false);
    const href = response.body.href;

    if (this.servicesFailed) {
      // Remove any service which was in-flight when another service failure occurred
      RestApiUtils.services.delete([{href}]);

      return;
    }

    this.createdServices ||= [];

    this.createdServices.push(href);
    service.indexes.forEach(index => (virtualServices[index].service.href = href));

    return virtualServices;
  },

  async handleOnSave() {
    const {newServices, creationType} = this.state;

    let virtualServices = this.props.virtualServices;

    if (newServices && newServices.length && creationType === 'service') {
      virtualServices = await this.createServices();
    }

    if (virtualServices === 'error') {
      return;
    }

    // Apply the newLabels to the virtual services and Save
    const finalVirtualServices = virtualServices.map(virtualService => {
      virtualService.labels = Array.isArray(virtualService.labels)
        ? virtualService.labels.map(label => ({href: label.href}))
        : [{href: (virtualService.labels && virtualService.labels.href) || ''}];

      return virtualService.service && virtualService.service.href
        ? _.omit(virtualService, ['service_ports', 'serviceport', 'address', 'matchedService'])
        : _.omit(virtualService, ['service', 'serviceport', 'address', 'matchedService']);
    });

    try {
      for (const virtualServiceChunk of _.chunk(finalVirtualServices, 5)) {
        // Create new virtual services
        await Promise.all(
          virtualServiceChunk.map(virtualService => RestApiUtils.virtualServices.create(virtualService)),
        );
      }

      actionCreators.openDialog(
        <ExplorerAddressesDialog
          type="VirtualService"
          created={finalVirtualServices.length}
          onComplete={this.handleCompleteRemoveDomain}
          onClose={this.handleComplete}
        />,
      );
    } catch (error) {
      console.log(error);
    }
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('virtualServiceExplorerTable', sorting);
    this.setState({sorting});
  },

  handlePageChange(page) {
    this.setState({
      currentPage: page,
    });
  },

  handleComplete() {
    this.props.onClose();
    actionCreators.closeDialog();
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    const serviceRadioGroupData = [
      {key: 'port', value: intl('Common.UseVirtualService')},
      {key: 'service', value: intl('Explorer.CreateNewService')},
    ];

    const columns = [
      {
        key: 'name',
        label: intl('Common.Name'),
        sortable: true,
        format: value => <div className="Explorer-ip Explorer-unmanaged">{value}</div>,
      },
      {
        key: 'address',
        label: intl('Events.Address'),
        sortable: true,
        format: value => <div className="Explorer-ip Explorer-unmanaged">{value}</div>,
      },
      {
        key: 'matchedService',
        label: intl('Common.ServicePort'),
        sortable: true,
        format: (value, row) => {
          const {port, proto} = row.service_ports[0];
          let name = `${port} ${ServiceUtils.lookupProtocol(proto)}`;

          if (value) {
            name = value.name;
          } else if (this.state.creationType === 'service') {
            name = (
              <span>
                <Badge type="new" />
                {intl('PolicyGenerator.ServiceName', {port, protocol: ServiceUtils.lookupProtocol(proto)})}
              </span>
            );
          }

          return <div className="Explorer-ip Explorer-unmanaged">{name}</div>;
        },
      },
    ];

    return (
      <Dialog
        ref="virtualServiceDialog"
        tid={this.props.tid}
        title={intl('Labels.CreateVSPreview')}
        actions={this.getActions()}
        className="VSCreate"
      >
        {this.state.newServices.length ? (
          <div className="Explorer-VirtualService">
            <div className="Explorer-Title-VirtualService">{intl('Explorer.ServiceDoesNotExist')}</div>
            <RadioGroup
              data={serviceRadioGroupData}
              value={this.state.creationType}
              format="vertical"
              onChange={this.handleServiceTypeSelect}
            />
          </div>
        ) : null}
        <ToolBar>
          {Boolean(this.props.virtualServices.length) && (
            <ToolGroup className="VSToolGroup">
              <Pagination
                page={this.state.currentPage}
                totalRows={this.props.virtualServices.length}
                count={{matched: this.props.virtualServices.length, total: this.props.virtualServices.length}}
                pageLength={MAX_RESULTS_PER_PAGE}
                onPageChange={this.handlePageChange}
              />
            </ToolGroup>
          )}
        </ToolBar>
        <div className="Explorer-VS">
          <Grid
            columns={columns}
            sorting={this.state.sorting}
            data={this.props.virtualServices}
            selection={[]}
            onSort={this.handleSort}
            sortable={true}
            selectable={false}
            resultsPerPage={MAX_RESULTS_PER_PAGE}
            currentPage={this.state.currentPage}
            emptyContent={null}
          />
        </div>
      </Dialog>
    );
  },
});
