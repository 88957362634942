/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import ComponentUtils from '../utils/ComponentUtils';

export default React.createClass({
  propTypes: {
    data: PropTypes.array,
    // Key: string
    // Value: string
    // Detail: react element (use only with the vertical format)
    // DetailFormat: string (right,bottom)
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    format: PropTypes.oneOf(['horizontal', 'vertical']),
  },

  getDefaultProps() {
    return {
      value: '',
      disabled: false,
      onChange: _.noop,
      format: 'vertical',
    };
  },

  getItems() {
    if (!this.props.data) {
      return null;
    }

    const selectedItem = this.props.value;
    const format = this.props.format;

    return this.props.data.map((item, idx) => {
      if (!item) {
        return null;
      }

      const isSelected = item.key === selectedItem;
      const detailFormat = item.detailFormat;
      let itemDetail = '';

      if (item.detail) {
        itemDetail = <span>{item.detail}</span>;
      }

      const itemClasses = cx({
        'RadioGroupItem': true,
        'RadioGroupItem--vertical': format === 'vertical',
        'RadioGroupItem--horizontal': format === 'horizontal',
      });

      const selectClasses = cx({
        'RadioGroupItem-Label': true,
        'RadioGroupItem--selected': isSelected,
      });

      const detailPositionClasses = cx({
        'RadioGroupDetail--right': detailFormat === 'right',
        'RadioGroupDetail--bottom': detailFormat === 'bottom',
      });

      const tids = ComponentUtils.tid('comp-radio', item.tid);

      return (
        <div key={idx} className={itemClasses} data-tid={ComponentUtils.tidString(tids)}>
          <label className={selectClasses} data-tid="comp-radio-label">
            <input
              tabIndex="0"
              autoFocus={item.focus === true}
              data-tid={`elem-input ${isSelected ? 'elem-input-selected' : ''} elem-input-${_.snakeCase(
                item.key,
              ).replaceAll(/_/gi, '-')}`}
              type="radio"
              checked={isSelected}
              onChange={this.props.onChange}
              disabled={this.props.disabled}
              value={item.key}
            />
            <span className="RadioGroupItemLabel" data-tid="elem-text">
              {item.value}
            </span>
          </label>
          <div className={detailPositionClasses} data-tid="comp-radio-sublabel">
            <span data-tid="elem-text">{itemDetail}</span>
          </div>
        </div>
      );
    });
  },

  render() {
    const classes = cx('RadioGroup', {RadioGroupDisabled: this.props.disabled});

    if (this.props.children) {
      return (
        <div className={classes} data-tid="comp-radiogroup">
          {this.props.children}
        </div>
      );
    }

    return (
      <div className={classes} data-tid="comp-radiogroup">
        {this.getItems()}
      </div>
    );
  },
});
