/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PropTypes} from 'react';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-alert';

Alert.propTypes = {
  tid: PropTypes.string,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  icon: PropTypes.bool,
  wrap: PropTypes.bool,
  detached: PropTypes.bool,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  icon: true,
};

export default function Alert(props) {
  const classes = cx('Alert', {
    'Alert--success': props.type === 'success',
    'Alert--warning': props.type === 'warning',
    'Alert--old': props.type === 'old',
    'Alert--notification': props.type === 'notification',
    'Alert--wrap': props.wrap,
    'Alert--detached': props.detached,
  });

  const tids = ComponentUtils.tid(defaultTid, [props.tid, props.type]);
  let iconName;

  switch (props.type) {
    case 'success':
      iconName = 'check';
      break;

    case 'warning':
      iconName = props.detached ? 'warning' : 'info';
      break;

    case 'old':
      iconName = 'timezone';
      break;
  }

  const icon = props.icon ? (
    <span className="Alert-icon">
      <Icon name={iconName} />
    </span>
  ) : null;

  const title = props.title ? (
    <span className="Alert-title" data-tid="comp-alert-title">
      {props.title}
    </span>
  ) : null;

  const close = props.onClose ? (
    <span className="Alert-close" onClick={props.onClose}>
      <Icon name="close" />
    </span>
  ) : null;

  return (
    <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
      {icon}
      {title}
      <span className="Alert-message" data-tid="comp-alert-message">
        {props.children}
      </span>
      {close}
    </div>
  );
}
