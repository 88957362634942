/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createStore} from '../lib/store';
import DvsStore from './DvsStore';
import LabelStore from './LabelStore';
import ServiceStore from './ServiceStore';
import Constants from '../constants';
import LoadBalancerStore from './LoadBalancerStore';
import VirtualServerStore from './VirtualServerStore';
import dispatcher from '../actions/dispatcher';

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.LABELS_GET_COLLECTION_SUCCESS:
        dispatcher.waitFor([LabelStore.dispatchToken]);
        break;

      case Constants.VIRTUAL_SERVERS_GET_COLLECTION_SUCCESS:
        dispatcher.waitFor([VirtualServerStore.dispatchToken]);
        break;

      case Constants.DISCOVERED_VIRTUAL_SERVERS_GET_COLLECTION_SUCCESS:
      case Constants.DISCOVERED_VIRTUAL_SERVERS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([DvsStore.dispatchToken]);
        break;

      case Constants.SLBS_GET_COLLECTION_SUCCESS:
      case Constants.SLBS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([LoadBalancerStore.dispatchToken]);
        break;

      case Constants.SERVICES_GET_COLLECTION_SUCCESS:
        dispatcher.waitFor([ServiceStore.dispatchToken]);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll(version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return getAll(version);
  },

  getAllChainable(version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return getAll(version);
  },

  getSpecified(href) {
    return get(href);
  },

  getByDiscovered(href, version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return getByDiscovered(href, version);
  },

  isLoaded(version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return (
      VirtualServerStore.isLoaded(version) &&
      LabelStore.isLoaded() &&
      ServiceStore.isLoaded() &&
      DvsStore.isLoaded() &&
      LoadBalancerStore.isLoaded()
    );
  },
});

function expandResources(virtualServer) {
  const vs = _.cloneDeep(virtualServer);

  vs.expandedLabels = {};
  vs.labels.forEach(l => {
    const expandedLabel = LabelStore.getSpecified(l.href);

    if (expandedLabel) {
      vs.expandedLabels[expandedLabel.key] = expandedLabel.value;
    }
  });

  if (vs.service && vs.service.href) {
    vs.expandedService = ServiceStore.getSpecified(vs.service.href);
  }

  if (vs.discovered_virtual_server) {
    vs.dvs = DvsStore.get(vs.discovered_virtual_server.href);

    if (vs.dvs && vs.dvs.slb) {
      vs.slb = LoadBalancerStore.getSpecified(vs.dvs.slb.href);
    }
  }

  return vs;
}

// Places the expanded labels in the expandedLabels property of each virtual server.
function getAll(version) {
  return VirtualServerStore.getAll(version).map(vs => expandResources(vs));
}

function get(href) {
  const vs = VirtualServerStore.getExpanded(href);

  if (!vs) {
    return null;
  }

  return expandResources(vs);
}

function getByDiscovered(href, version) {
  const vs = VirtualServerStore.getByDiscovered(href, version);

  if (!vs) {
    return null;
  }

  return expandResources(vs);
}
