/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import Constants from '../constants';
import DialogStore from './DialogStore';
import RoutesStore from './RoutesStore';
import {slugToCamelCase} from '../utils/GeneralUtils';

let isOpen = false;
let history = [];

// Format collection items back to collection path
const formatItem = item => {
  // Note we should only expect on instance of 'item' per route as it is the convention we follow
  if (!item.startsWith('app') && item.endsWith('item')) {
    return `app.${item.replace('item', 'list')}`;
  }

  return item;
};

const getValidatedHistory = (routesMap, item) => {
  const formattedItem = formatItem(item);

  return (
    routesMap.get(formattedItem)?.isAvailable?.() ?? routesMap.get(formattedItem)?.handler?.isAvailable?.() ?? true
  );
};

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.OPEN_INSTANT_SEARCH:
        DialogStore.openDialog(action.data);
        isOpen = true;
        break;

      case Constants.CLOSE_INSTANT_SEARCH:
        DialogStore.closeDialog();
        isOpen = false;
        break;

      case Constants.KVPAIRS_GET_INSTANCE_SUCCESS:
      case Constants.KVPAIR_UPDATE_SUCCESS:
        if (action.options.params.key === 'instant_search_history' && action.data) {
          const routesMap = RoutesStore.getRoutesMap();
          const updatedHistory = [];

          if (action.data.length) {
            for (const item of action.data) {
              item.collectionName &&= slugToCamelCase(item.collectionName, '_');

              if (getValidatedHistory(routesMap, item.name)) {
                updatedHistory.push(item);
              }
            }

            history = updatedHistory;
          } else {
            history = [];
          }
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  isOpen: () => isOpen,

  getHistory: () => history,
});
