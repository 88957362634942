/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import _ from 'lodash';
import ParallelCoordinatesUtils from '../../../utils/Explorer/ParallelCoordinatesUtils';

export default React.createClass({
  propTypes: {
    width: PropTypes.number,
    height: PropTypes.number,
    vizHighlighted: PropTypes.object,
    data: PropTypes.object,
  },

  componentDidMount() {
    this.canvas = this.refs.canvas;
    this.ctx = this.canvas.getContext('2d');
    this.ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    this.ctx.strokeStyle = 'rgba(0,100,160,0.24)';
    _.forOwn(this.props.data.pcLinks, d => {
      this.path(d, this.ctx, this.props.data);
    });
  },

  componentDidUpdate(previousProps) {
    const numLinks = Object.keys(this.props.data.pcLinks).length;

    // re-scale the canvas if the container resized.
    if (this.props.width !== previousProps.width || this.props.height !== previousProps.height) {
      this.ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    }

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    _.forOwn(this.props.data.pcLinks, d => {
      this.path(d, this.ctx, this.props.data, this.props.vizHighlighted, numLinks);
    });
  },

  path(d, ctx, pc, highlighted, numLinks) {
    const dimensions = pc.dimensions;
    const x = pc.xCoord;
    const y = pc.yCoord;
    const isLinkHighlighted = ParallelCoordinatesUtils.calculateHighlightedVizLink(highlighted, d.data); //link is highlighted in the graphs

    ctx.beginPath();

    if (highlighted && highlighted.source) {
      if (isLinkHighlighted) {
        //highlight link
        ctx.strokeStyle = 'rgba(255, 165, 0, 1.0)';
        ctx.lineWidth = 1;
      } else {
        //fade link
        ctx.strokeStyle = 'rgba(0, 100, 160, 0.0)';
        ctx.lineWidth = 0;
      }
    } else if (numLinks < 100) {
      ctx.strokeStyle = 'rgba(66, 165, 245, 0.8)';
      ctx.lineWidth = 1;
    } else {
      ctx.strokeStyle = 'rgba(66, 165, 245, 0.5)';
      ctx.lineWidth = 0.7;
    }

    dimensions.forEach((p, i) => {
      if (i === 0) {
        ctx.moveTo(x[p], y[p](d.data[p]) + 20);
      } else {
        ctx.lineTo(x[p], y[p](d.data[p]) + 20);
      }
    });
    ctx.stroke();
    ctx.closePath();
  },

  render() {
    const {width, height} = this.props;

    // scale the canvas width & height to support retina displays
    const canvasWidth = width * window.devicePixelRatio;
    const canvasHeight = height * window.devicePixelRatio;

    // set canvas to the correct size using the inline styles
    const canvasStyle = {width: `${width}px`, height: `${height}px`};

    return (
      <div className="ParallelCoordinatesLinksCanvas">
        <canvas ref="canvas" className="canvasLinks" width={canvasWidth} height={canvasHeight} style={canvasStyle} />
      </div>
    );
  },
});
