/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import {ComponentUtils} from '../../utils';

const defaultTid = 'comp-checkbox';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    autoFocus: PropTypes.bool,
    tabIndex: PropTypes.string,
    label: PropTypes.string,
    sublabel: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
  },

  getDefaultProps() {
    return {
      onFocus: _.noop,
      onBlur: _.noop,
      onChange: _.noop,
      onKeyDown: _.noop,
      onKeyUp: _.noop,
    };
  },

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focusInput();
    }
  },

  handleChange(evt) {
    this.focusInput();
    this.props.onChange(evt.target.value, evt);
  },

  handleKeyDown(evt) {
    this.props.onKeyDown(evt);

    if (evt.key === 'Enter' || evt.key === ' ') {
      evt.preventDefault();
      this.handleChange(evt);
    }
  },

  focusInput() {
    if (this.refs.input) {
      this.refs.input.focus();
    }
  },

  render() {
    const classes = cx({
      'ReForm-Checkbox': true,
      'ReForm-Checkbox--checked': this.props.checked,
      'ReForm-Checkbox--error': this.props.error,
      'ReForm-Checkbox--disabled': this.props.disabled,
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);
    const singleTid = tids.length ? tids[tids.length - 1] : null;

    return (
      <label className={classes} data-tid={ComponentUtils.tidString(tids)}>
        <input
          className="ReForm-Checkbox-Input"
          data-tid={`elem-input elem-input--${singleTid}`}
          tabIndex={this.props.tabIndex}
          type="checkbox"
          ref="input"
          autoFocus={this.props.autoFocus}
          value={this.props.value}
          checked={this.props.checked}
          disabled={this.props.disabled}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.props.onKeyUp}
        />
        <span className="ReForm-Checkbox-Input-Cover" data-tid="comp-checkbox-input" />
        {this.props.sublabel ? (
          <span className="ReForm-Checkbox-Label ReForm-Checkbox-Label--complex" data-tid="comp-checkbox-label">
            <div className="ReForm-Checkbox-Label-Primary" data-tid="elem-text">
              {this.props.label}
            </div>
            <div className="ReForm-Checkbox-Label-Secondary" data-tid="elem-text">
              {this.props.sublabel}
            </div>
          </span>
        ) : (
          <span className="ReForm-Checkbox-Label" data-tid="comp-checkbox-label">
            <span data-tid="elem-text">{this.props.label}</span>
          </span>
        )}
      </label>
    );
  },
});
