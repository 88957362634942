/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import StoreUtils from '../utils/StoreUtils';
import Constants from '../constants';

const ruleViews = [];

function setRuleView(data, query) {
  StoreUtils.setObjectByQuery(data, query.workload || query.virtual_server, ruleViews);
}

export default createApiStore(['SEC_POLICY_RULE_VIEW_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SEC_POLICY_RULE_VIEW_GET_SUCCESS:
        setRuleView({...action.data, ...action.count}, action.options.query);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },
  getSpecified(query) {
    return StoreUtils.findObjectByQuery(query, ruleViews);
  },
});
