/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';

let workloads = [];
const workloadMap = {};
let count = {};
let isLoaded = false;

function expandWithLabelKeys(workload) {
  for (const label of workload.labels) {
    if (label.key) {
      workload[label.key] = label;
    }
  }

  return workload;
}

function setWorkloads(data) {
  // put label keys on workload object
  workloads = data.map(workload => expandWithLabelKeys(workload));
  workloads.forEach(workload => (workloadMap[workload.href] = workload));
}

function setCount(data) {
  count = data;
}

export default createApiStore(['WORKLOADS_GET_COLLECTION_', 'WORKLOADS_GET_INSTANCE_'], {
  dispatchHandler(action) {
    const {options: {isList, isDetail} = {}} = action;

    switch (action.type) {
      case Constants.WORKLOADS_GET_COLLECTION_SUCCESS:
        if (isList) {
          setWorkloads(action.data);
          setCount(action.count);
          isLoaded = true;
        }

        break;

      case Constants.WORKLOADS_GET_INSTANCE_SUCCESS:
        if (isDetail) {
          const workload = action.data;

          // Save the vulnerability data from the list
          if (workloadMap[workload.href] && workloadMap[workload.href].vulnerability_summary) {
            workload.vulnerability_summary = workloadMap[workload.href].vulnerability_summary;
          }

          const workloadWithLabelKeys = expandWithLabelKeys(workload);

          workloadMap[workloadWithLabelKeys.href] = workloadWithLabelKeys;
          StoreUtils.setObject(workloadWithLabelKeys, workloads);
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return workloads;
  },

  getCount() {
    return count;
  },

  getSpecified(href) {
    return workloadMap[href];
  },

  isLoaded() {
    return isLoaded;
  },
});
