/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import ResultList from './ResultList.jsx';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-select';

export default React.createClass({
  propTypes: {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    formatSelection: PropTypes.func,
    selected: PropTypes.any,
    icon: PropTypes.string,
    tid: PropTypes.string,
    formatResult: PropTypes.func,
    excludeOptions: PropTypes.arrayOf(PropTypes.string), // e.g. ['enforced', 'low', 'high']
  },

  getDefaultProps() {
    return {
      disabled: false,
      label: '',
      onSelect: _.noop,
      selected: '',
      formatSelection: null,
      excludeOptions: [],
    };
  },

  getInitialState() {
    const selected = this.props.selected;
    let label = null;

    if (selected || selected === 0) {
      const option = _.find(this.props.options, option => option.value === selected);

      label = option ? option.label : null;
    }

    return {
      value: label || '',
      focusedOption: null,
      showOptions: false,
    };
  },

  componentWillReceiveProps(nextProps) {
    const selected = nextProps.selected;
    let label = null;

    if (selected || selected === 0) {
      const option = _.find(nextProps.options, option => option.value === selected);

      label = option ? option.label : null;
    }

    this.setState({value: label || ''});
  },

  handleChange(event) {
    event.stopPropagation();
    this.props.onSelect(event.target);
    this.setState({
      focusedOption: null,
      showOptions: false,
    });
  },

  handleInputBlur() {
    // This ridiculousness is caused by the fact that, if the user clicks on an item in the
    // dropdown, the input element's 'blur' event will happen before the 'click' event ever
    // gets a chance to. Erego, we introduce an artificial delay here - just enough time
    // for the browser to properly fire off its 'click' event.
    setTimeout(() => {
      if (this.isMounted()) {
        this.setState({focusedOption: null, showOptions: false});
      }
    }, 250);
  },

  handleInputKeyDown(event) {
    event.stopPropagation();

    const {focusedOption} = this.state;
    const numOptions = this.props.options.length;
    const newState = {};
    const {options, excludeOptions} = this.props;

    switch (event.key) {
      case 'ArrowDown':
        if (this.state.showOptions) {
          newState.showResults = true;

          // focusedOption === null during the very first down arrow key
          if (focusedOption === null) {
            // Note: Need to dynamically find the position for non-excluded options
            // for the very first time for arrow down key.
            for (let i = 0; i < numOptions; i++) {
              if (!excludeOptions.includes(options[i].value)) {
                // find the first index to set the starting focusdOption position
                newState.focusedOption = i;
                // break out of the loop for the first index match
                break;
              }
            }
          } else if (focusedOption === numOptions - 1) {
            newState.focusedOption = 0;
          } else {
            newState.focusedOption = focusedOption + 1;
          }

          // When excludeOptions exist need to go through logic to disable excludedOptions from arrow down toggling
          if (this.props.excludeOptions.length) {
            let fallbackIndex = -1; // keep consistent with indexOf when not found indicator
            let filteredIndex = -1; // keep consistent with indexOf when not found indicator

            if (focusedOption === null) {
              // newState.focusedOption is the start of the first position
              filteredIndex = newState.focusedOption;
            } else {
              // loop to find the first matching index position in options
              for (let i = focusedOption + 1; i < numOptions; i++) {
                if (!excludeOptions.includes(options[i].value)) {
                  filteredIndex = i;
                  // break out of the loop for the first index match
                  break;
                }
              }

              // only call fallback when filteredIndex not found
              if (filteredIndex === -1) {
                // loop from the beginning up the focusedOption position
                for (let x = 0; x < focusedOption; x++) {
                  if (!excludeOptions.includes(options[x].value)) {
                    fallbackIndex = x;
                    // break out of the loop for the first index match
                    break;
                  }
                }
              }
            }

            if (filteredIndex !== -1) {
              newState.focusedOption = filteredIndex;
            } else if (filteredIndex === -1 && fallbackIndex !== -1) {
              // fallbackIndex is used when filteredIndex === -1
              newState.focusedOption = fallbackIndex;
            } else {
              // delete newState.focusOption to disable keyboard arrow down from toggling when
              // 1) all items disabled
              // 2) is only one item - the down arrow will just be focus on that paricular item
              delete newState.focusedOption;
            }
          }
        }

        break;

      case 'ArrowUp':
        if (this.state.showOptions) {
          newState.showResults = true;

          if (focusedOption === null) {
            for (let i = numOptions - 1; i >= 0; i--) {
              if (!excludeOptions.includes(options[i].value)) {
                newState.focusedOption = i;
                // break out of the loop for the first index match
                break;
              }
            }
          } else if (focusedOption === 0) {
            newState.focusedOption = numOptions - 1;
          } else {
            newState.focusedOption = focusedOption - 1;
          }

          // When excludeOptions exist need to go through logic to disable excludedOptions from arrow up toggling
          if (this.props.excludeOptions.length) {
            let fallbackIndex = -1; // keep consistent with indexOf when not found indicator
            let filteredIndex = -1; // keep consistent with indexOf when not found indicator

            if (focusedOption === null) {
              // newState.focusedOption is the start of the first position
              filteredIndex = newState.focusedOption;
            } else {
              // don't need to loop during the very first arrow up
              // loop from the previous 'focusedOption - 1'
              for (let i = focusedOption - 1; i >= 0; i--) {
                // Find the first item that is not excluded in excludedOptions
                if (!excludeOptions.includes(options[i].value)) {
                  // When one item found break out of the loop. Don't need to loop anymore
                  filteredIndex = i;
                  break;
                }
              }

              // only call fallback when filteredIndex not found
              if (filteredIndex === -1) {
                // loop from the end up to the focusedOption position
                for (let x = numOptions - 1; x > focusedOption; x--) {
                  // Find the first item that is not excluded in excludedOptions
                  if (!excludeOptions.includes(options[x].value)) {
                    // When one item found break out of the loop. Don't need to loop anymore
                    fallbackIndex = x;
                    break;
                  }
                }
              }
            }

            if (filteredIndex !== -1) {
              newState.focusedOption = filteredIndex;
            } else if (filteredIndex === -1 && fallbackIndex !== -1) {
              // fallbackIndex is used when filteredIndex === -1
              newState.focusedOption = fallbackIndex;
            } else {
              // delete newState.focusOption to disable keyboard arrow down toggling when
              // 1) all items disabled
              // 2) is only one item - the up arrow will just be focus on that paricular item
              delete newState.focusedOption;
            }
          }
        }

        break;

      case 'Enter':
        if (this.state.showOptions) {
          this.handleSelectOption(focusedOption);
        } else {
          this.handleSelectFocus();
        }

        return;
    }

    this.setState(newState);
  },

  handleSelectFocus() {
    if (this.props.disabled) {
      return;
    }

    const show = !this.state.showOptions;

    this.setState({
      showOptions: show,
    });
  },

  handleSelectOption(idx) {
    const activeOption = this.props.options[idx];

    this.props.onSelect(activeOption);
    findDOMNode(this.refs.input).focus();
    this.setState({
      value: activeOption.label,
      focusedOptions: idx,
      showOptions: false,
    });
  },

  render() {
    let {value, focusedOption} = this.state;
    const options = this.props.options;
    const classes = cx({
      'Select': true,
      'Select--disabled': this.props.disabled,
    });

    let icon = null;

    if (this.props.icon) {
      icon = <Icon className="Select-icon" name={this.props.icon} />;
    }

    if (this.props.formatSelection) {
      value = this.props.formatSelection(value);
    }

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
        <span className="Select-label">{this.props.label}</span>
        <div
          ref="input"
          className="Select-value"
          onBlur={this.handleInputBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleInputKeyDown}
          onClick={this.handleSelectFocus}
          disabled={true}
          tabIndex="0"
          data-tid="comp-select-trigger"
        >
          {icon}
          <span data-tid="comp-select-value" className="Select-value-inner">
            {value || `- ${intl('Common.NoneSelected')} -`}
          </span>
          <span className="Select-chevron">
            <Icon name="dropdown" />
          </span>
        </div>
        <ResultList
          data={options}
          onSelect={this.handleSelectOption}
          focusedResult={focusedOption}
          showResults={this.state.showOptions}
          formatResult={this.props.formatResult}
          excludeOptions={this.props.excludeOptions}
        />
      </div>
    );
  },
});
