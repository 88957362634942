/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {Icon} from '..';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export default React.createClass({
  propTypes: {
    href: PropTypes.string.isRequired,
    onNoteIconClick: PropTypes.func.isRequired,
    onNoteCloseClick: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    showNote: PropTypes.bool.isRequired,
    onSetNoteBox: PropTypes.func.isRequired,
    isCustomIp: PropTypes.bool,
    onNodeEditClick: PropTypes.func,
    onNoteSaveClick: PropTypes.func,
    onNoteValueChange: PropTypes.func,
    isEdit: PropTypes.bool,
    value: PropTypes.string,
    activeValue: PropTypes.string,
  },

  handleNoteIconClick(evt) {
    const rect = evt.target.getBoundingClientRect();
    const showNoteTop = rect.bottom + 96 > window.innerHeight - window.pageYOffset;

    this.props.onNoteIconClick(this.props.href, showNoteTop);
  },

  handleNoteCloseClick() {
    this.props.onNoteCloseClick();
  },

  handleNoteSaveClick() {
    this.props.onNoteSaveClick(this.props.href, this.props.isCustomIp);
  },

  handleNoteValueChange(evt) {
    this.props.onNoteValueChange(this.props.href, this.props.isCustomIp, evt.target.value);
  },

  handleTextareaClick() {
    if (this.props.isReadOnly) {
      return;
    }

    this.props.onNodeEditClick(this.props.href);
  },

  render() {
    // isReadOnly not same as !isEdit
    // isReadOnly means that either the user is readonly or the version is not draft
    const {isReadOnly, isEdit, value, activeValue, showNote, showNoteTop} = this.props;
    const classNames = cx('RulesetRules-Note-Box', {
      'RulesetRules-Note-Box--ReadOnly': isReadOnly,
      'RulesetRules-Note-Box--Normal': !isEdit,
      'RulesetRules-Note-Box--Edit': isEdit,
      'RulesetRules-Note-Box--Top': showNoteTop,
    });
    const differentActive = activeValue && activeValue !== value;
    const textareaClassnames = cx('RulesetRules-Note-Box-Textarea', {
      'RulesetRules-Note-Box-Textarea--Draft': differentActive,
      'RulesetRules-Note-Box-Textarea--ActiveEmpty': activeValue && !value,
    });
    const showNoteIcon = value || activeValue || showNote;

    return (
      <div className="RulesetRules-Note" data-tid="comp-note">
        {showNoteIcon && (
          <Icon name="note" size="xlarge" styleClass="RulesetRules-NoteIcon" onClick={this.handleNoteIconClick} />
        )}
        {showNote && (
          <ReactCSSTransitionGroup
            transitionName="RulesetRules-Note-Box"
            transitionAppear={true}
            transitionAppearTimeout={5000}
            transitionEnterTimeout={0}
            transitionLeaveTimeout={0}
          >
            <div className={classNames} ref={this.props.onSetNoteBox} data-tid="comp-note-box">
              <Icon name="close" size="large" styleClass="RulesetRules-NoteClose" onClick={this.handleNoteCloseClick} />
              <div className="RulesetRules-Note-Box-TextareaContainer" onClick={this.handleTextareaClick}>
                <textarea
                  value={value}
                  onChange={this.handleNoteValueChange}
                  className={textareaClassnames}
                  name="RuleNote"
                  placeholder={intl('Rulesets.Rules.NotePlaceholder')}
                  disabled={!isEdit}
                  ref={node => (this.textarea = node)}
                  data-tid="comp-description comp-description-draft"
                />
                {!isEdit && differentActive && (
                  <textarea
                    value={activeValue}
                    className="RulesetRules-Note-Box-Textarea RulesetRules-Note-Box-Textarea--Active"
                    disabled={true}
                    readOnly={true}
                    data-tid="comp-description comp-description-active"
                  />
                )}
              </div>
              {!isReadOnly &&
                (isEdit ? (
                  <Icon
                    name="save"
                    size="medium"
                    styleClass="RulesetRules-NoteSave"
                    onClick={this.handleNoteSaveClick}
                  />
                ) : (
                  <Icon
                    name="edit"
                    size="medium"
                    styleClass="RulesetRules-NoteEdit"
                    onClick={this.handleTextareaClick}
                  />
                ))}
            </div>
          </ReactCSSTransitionGroup>
        )}
      </div>
    );
  },
});
