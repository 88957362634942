/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {PropTypes} from 'react';
import ComponentUtils from '../../utils/ComponentUtils';

const defaultTid = 'comp-toolgroup';

Alert.propTypes = {
  tid: PropTypes.string,
  hasObjectSelector: PropTypes.bool,
};

export default function Alert(props) {
  const tids = ComponentUtils.tid(defaultTid, props.tid);
  const classes = cx({
    'ToolGroup': true,
    'ToolGroup-ObjectSelector': props.hasObjectSelector,
  });

  return (
    <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
      {React.Children.map(props.children, (item, idx) => {
        if (!item) {
          return null;
        }

        return (
          <div className="ToolGroup-item" key={idx} data-tid="elem-toolgroup-item">
            {item}
          </div>
        );
      })}
    </div>
  );
}
