/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import Vulnerability from '../Vulnerability';
import Icon from '../Icon.jsx';
import Tooltip from '../Tooltip.jsx';
import RestApiUtils from '../../utils/RestApiUtils';
import RenderUtils from '../../utils/RenderUtils';
import {Link} from 'react-router';
import ServiceUtils from '../../utils/ServiceUtils';
import actionCreators from '../../actions/actionCreators';
import {TrafficStore, MapPageStore, GraphStore, TrafficFilterStore} from '../../stores';
import SetPolicyState from './SetPolicyStatePanel';
import {getEnforcementIntlByState} from '@illumio-shared/utils/intl/dynamic';
import EditPolicyStateConfirmModal from '../../modals/EditPolicyStateConfirmModal';

const enforcementOrder = {
  unmanaged: 0,
  idle: 1,
  visibility: 2,
  selective: 3,
  enforced: 4,
  unknown: 5,
};

export default React.createClass({
  getInitialState() {
    return {
      editPolicyState: false,
    };
  },

  componentDidMount() {
    this.getWorkloads();
    this.getVirtualServices();
    this.getContainerWorkloads();
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.data.labels, nextProps.data.labels)) {
      this.getWorkloads(nextProps.data);
      this.getVirtualServices(nextProps.data);
      this.getContainerWorkloads(nextProps.data);
      this.handleClosePolicyState();
      this.setState({
        editPolicyState: false,
      });
    }
  },

  getWorkloads(data) {
    const {workloads, workloadsNum, labels} = data || this.props.data;

    if (MapPageStore.getAppMapVersion() !== 'vulnerability' && (!workloads || workloads.length < workloadsNum)) {
      // go and get the workloads for the role if workloads is empty
      // or the number of workloads we found is less than the number we expect

      const roleWorkloadsIsReadable = this.props.data.caps.workloads.includes('read');

      if (roleWorkloadsIsReadable) {
        _.defer(() =>
          RestApiUtils.workloads.getCollection({labels: JSON.stringify([RenderUtils.getCompleteLabels(labels)])}, true),
        );
      }
    }
  },

  getContainerWorkloads(data) {
    const {containerWorkloads, containerWorkloadsNum, labels} = data || this.props.data;

    if (
      MapPageStore.getAppMapVersion() !== 'vulnerability' &&
      (!containerWorkloads || containerWorkloads.length < containerWorkloadsNum)
    ) {
      // go and get the workloads for the role if workloads is empty
      // or the number of workloads we found is less than the number we expect

      // If the labels are not complete, get 500 containers to sift through, until we can ask for the no label case
      _.defer(() =>
        RestApiUtils.containerWorkloads.getCollection(
          {
            labels: JSON.stringify([_.compact(_.map(labels, 'href'))]),
            max_results: Object.keys(labels).length === 4 ? 50 : 500,
          },
          true,
        ),
      );
    }
  },

  getVirtualServices(data) {
    const {virtualServices, virtualServicesNum, labels} = data || this.props.data;

    if (
      MapPageStore.getAppMapVersion() !== 'vulnerability' &&
      (!virtualServices || virtualServices.length < virtualServicesNum)
    ) {
      // go and get the virtualServices for the role if workloads is empty
      // or the number of virtualServices we found is less than the number we expect

      // If the labels are not complete, get 500 virtual services to sift through, until we can ask for the no label case
      _.defer(() =>
        RestApiUtils.virtualServices.getCollection(
          'draft',
          {
            labels: JSON.stringify([_.compact(_.map(labels, 'href'))]),
            max_results: Object.keys(labels).length === 4 ? 50 : 500,
            representation: 'virtual_service_labels_services_and_workloads',
          },
          true,
        ),
      );
    }
  },

  handleEditPolicyState() {
    if (localStorage.getItem('edit_workload_policy_state_only')) {
      this.handleConfirmEditPolicyState();
    } else {
      actionCreators.openDialog(<EditPolicyStateConfirmModal onConfirm={this.handleConfirmEditPolicyState} />);
    }
  },

  handleConfirmEditPolicyState(data) {
    if (data) {
      localStorage.setItem('edit_workload_policy_state_only', 'true');
    }

    this.setState({editPolicyState: !this.state.editPolicyState});
  },

  handleClosePolicyState() {
    this.setState({editPolicyState: false});
  },

  selectVulnerability(vulnerability) {
    _.defer(() => {
      actionCreators.selectVulnerability({
        type: 'vulnerability',
        formData: vulnerability,
      });
    });
  },

  render() {
    let group;
    const groupLabels = [];
    const groupLabelsHrefs = [];
    const {data} = this.props;
    const groupsType = MapPageStore.getMapType() === 'app' ? TrafficStore.getAppGroupsType() : ['app', 'env', 'loc'];
    const roleWorkloadsIsWritable = data.caps.workloads.includes('write');
    const roleWorkloadsIsReadable = data.caps.workloads.includes('read');
    let isCurrentFocused;

    if (data.labels) {
      groupsType.forEach(key => {
        if (data.labels.hasOwnProperty(key) && !_.isEmpty(data.labels[key])) {
          groupLabels.push(data.labels[key].value);

          if (data.labels[key] && data.labels[key].href) {
            groupLabelsHrefs.push(data.labels[key].href.split('/').pop());
          }
        }
      });

      const groupName = groupLabels.length ? groupLabels.join(' | ') : intl('Map.DiscoveredGroup');
      const groupParams = {id: groupLabels.length ? groupLabelsHrefs.sort((a, b) => a - b).join('x') : 'discovered'};
      let tooltipContent;

      if (groupName.length > 40) {
        tooltipContent = groupName;
      }

      const currentRoute = MapPageStore.getMapRoute();
      let groupType = intl('Common.Group');

      isCurrentFocused =
        currentRoute.type === 'focused' ||
        (currentRoute.prevtype === 'focused' && currentRoute.previd === groupParams.id);

      let groupLink = (
        <div className="MapInfoPanel-Row-Group" data-tid="map-info-panel-row-value">
          <Link to="groupWorkloads" className="MapInfoPanel-Link" params={groupParams}>
            {RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])}
          </Link>
        </div>
      );

      if (MapPageStore.getMapType() === 'app') {
        groupType = intl('Common.AppGroup');
        groupLink = (
          <div className="MapInfoPanel-Row-Group" data-tid="map-info-panel-row-value">
            {roleWorkloadsIsReadable && !isCurrentFocused ? (
              <Link to="appMapLevel" className="MapInfoPanel-Link" params={{type: 'focused', ...groupParams}}>
                {RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])}
              </Link>
            ) : (
              RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])
            )}
          </div>
        );
      }

      group = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label">
            <span data-tid="map-info-panel-row-label">{groupType}</span>
          </th>
          <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
            {tooltipContent ? (
              <Tooltip content={tooltipContent} position="role" width={372} location="bottomright">
                {groupLink}
              </Tooltip>
            ) : (
              groupLink
            )}
          </td>
        </tr>
      );
    }

    let location;

    if (MapPageStore.getMapType() === 'app' && groupsType.length === 2) {
      const locationName = data.labels.loc && data.labels.loc.value;

      location = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label">
            <span data-tid="map-info-panel-row-label">{intl('Common.Location')}</span>
          </th>
          <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
            <div className="MapInfoPanel-Row-Group" data-tid="map-info-panel-row-value">
              {data.labels.loc && data.labels.loc.href
                ? RenderUtils.truncateAppGroupName(locationName, 40)
                : intl('Common.None')}
            </div>
          </td>
        </tr>
      );
    }

    // All the different possible policy values are:
    // bulding, testing, enforced, none
    // And flow visibility are: flow_summary, flow_drops, flow_off

    const roleHasMultiType =
      data.workloads && data.workloads.length && data.containerWorkloads && data.containerWorkloads.length;
    const currentCluster =
      MapPageStore.getMapType() === 'app'
        ? GraphStore.getClusters().find(cluster =>
            isCurrentFocused ? cluster.connectionType === 'focused' : cluster.connectionType !== 'focused',
          )
        : GraphStore.getCluster(data.href.split('-')[0]);
    const currentRole = currentCluster?.nodes?.find(node => node.type === 'role' && node.href === data.href);
    let policyState = null;
    let policyStateHeader = null;
    let enforcementModes = null;
    let enforcementKeys = [];
    const filteredPolicyState = TrafficFilterStore.getHiddenPolicyStates();

    if (currentRole?.data?.mode) {
      const enforcementObj = currentRole.data.mode;

      enforcementKeys = Object.keys(enforcementObj)
        .filter(key => enforcementObj[key] && !filteredPolicyState.includes(key))
        .sort((a, b) => enforcementOrder[a] - enforcementOrder[b]);
      enforcementModes = enforcementKeys.map((key, index) => (
        <tr key={`Row-${index}`} className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
          <th colSpan="2" className="MapSubInfoPanel-Row-Label" key={key} data-tid="map-info-panel-row-value">
            <div className="MapInfoPanel-Container">{getEnforcementIntlByState(key)}</div>
          </th>
          <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            <Link
              className="CommandPanel-Link"
              to={MapPageStore.getMapType() === 'app' ? 'appGroupWorkloads' : 'groupWorkloads'}
              params={{id: data.href.split('-')[0], filter: key}}
              data-tid="map-info-panel-workloads"
            >
              {enforcementObj[key]}
            </Link>
          </td>
        </tr>
      ));
    }

    let containerEnforcementModes = null;
    let containerEnforcementKeys = [];

    if (currentRole?.data?.containerMode) {
      const containerEnforcementObj = currentRole.data.containerMode;

      containerEnforcementKeys = Object.keys(containerEnforcementObj)
        .filter(key => containerEnforcementObj[key] && !filteredPolicyState.includes(key))
        .sort((a, b) => enforcementOrder[a] - enforcementOrder[b]);
      containerEnforcementModes = containerEnforcementKeys.map((key, index) => (
        <tr key={`Row-${index}`} className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
          <th colSpan="2" className="MapSubInfoPanel-Row-Label" key={key} data-tid="map-info-panel-row-value">
            <div className="MapInfoPanel-Container">{getEnforcementIntlByState(key)}</div>
          </th>
          <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            <Link
              className="CommandPanel-Link"
              to={MapPageStore.getMapType() === 'app' ? 'appGroupContainerWorkloads' : 'groupContainerWorkloads'}
              params={{id: data.href.split('-')[0], filter: key}}
              data-tid="map-info-panel-workloads"
            >
              {containerEnforcementObj[key]}
            </Link>
          </td>
        </tr>
      ));
    }

    if (data.policyState) {
      const policyStateData = {
        type: 'role',
        href: data.href,
        currentState: data.policyState,
        labels: Object.values(data.labels).map(label => label.href),
        labelsObject: data.labels,
      };

      const editPolicyState = (
        <td
          className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow"
          colSpan="2"
          data-tid="map-info-panel-row-value"
        >
          <SetPolicyState onClose={this.handleClosePolicyState} data={policyStateData} />
        </td>
      );

      const disablePolicyEdit = !roleWorkloadsIsWritable;
      const individualEnforcementKeys = enforcementKeys?.length
        ? enforcementKeys
        : containerEnforcementKeys?.length
        ? containerEnforcementKeys
        : null;

      let displayPolicyState = (
        <td
          className={
            data.policyState &&
            (data.workloadsNum || data.virtualServersNum) &&
            data.entityNum < 500 &&
            !disablePolicyEdit &&
            enforcementKeys?.length
              ? 'MapSubInfoPanel-Row--Enforcement'
              : 'MapInfoPanel-Row-Value'
          }
          colSpan="2"
          data-tid="map-info-panel-row-value"
        >
          {individualEnforcementKeys?.length === 1 ? getEnforcementIntlByState(individualEnforcementKeys[0]) : null}
          {data.policyState &&
          (data.workloadsNum || data.virtualServersNum) &&
          data.entityNum < 500 &&
          !disablePolicyEdit &&
          enforcementKeys?.length ? (
            <span className="Icon-Edit">
              <Icon onClick={this.handleEditPolicyState} name="edit" tid="edit-policy-state" />
            </span>
          ) : null}
        </td>
      );

      if (data.policyState === 'unmanaged') {
        displayPolicyState = (
          <td className="MapInfoPanel-Row-Value" colSpan="2" data-tid="map-info-panel-row-value">
            <Icon name="unmanaged" styleClass="Unmanaged" position="before" />
            {intl('Workloads.Status.NotInstalled')}
          </td>
        );
      }

      if (roleWorkloadsIsReadable && roleHasMultiType) {
        policyStateHeader = (
          <tr
            className="MapSubInfoPanel-Row MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
            data-tid="map-sub-info-panel-row"
          >
            <th className="MapSubInfoPanel-Row-Align-top" data-tid="map-info-panel-row-label" colSpan="1">
              <div
                className={this.state.editPolicyState ? 'MapSubInfoPanel-Row-Label-Top' : 'MapSubInfoPanel-Row-Label'}
              >
                {intl('Common.Enforcement')}
              </div>
            </th>
            {this.state.editPolicyState ? editPolicyState : displayPolicyState}
          </tr>
        );
      } else if (roleWorkloadsIsReadable && !roleHasMultiType) {
        policyStateHeader = (
          <tr className="MapSubInfoPanel-Row" data-tid="map-info-panel-row">
            <th className="MapInfoPanel-Row-Label" colSpan="1" data-tid="map-info-panel-row-header">
              <div className={this.state.editPolicyState ? 'MapInfoPanel-edit' : 'MapInfoPanel-role'}>
                {data.policyState === 'unmanaged' ? intl('Common.Connectivity') : intl('Common.Enforcement')}
              </div>
            </th>
            {this.state.editPolicyState ? editPolicyState : displayPolicyState}
          </tr>
        );
      }

      policyState = roleWorkloadsIsReadable ? (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <td colSpan="2">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel">
                <tbody>
                  {policyStateHeader}
                  {enforcementKeys?.length > 1 ? enforcementModes : null}
                  {containerEnforcementKeys?.length > 1 ? containerEnforcementModes : null}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      ) : null;
    }

    let workloads;
    let workloadHeader;

    if (data.workloads && data.workloads.length) {
      data.workloads.forEach(workload => {
        if (RenderUtils.truncateAppGroupName(workload.name, 55, [55]).includes('...')) {
          workload.tooltipName = workload.name.slice(0);
        }

        workload.name = RenderUtils.truncateAppGroupName(workload.name, 55, [55]);
      });

      workloads = roleHasMultiType && (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2" className="MapInfoPanel-Row-Value">
            <table className="MapSubInfoPanel" data-tid="map-sub-info-panel">
              <tbody>
                <tr className="MapInfoPanel-Header">
                  <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
                    {data.collapsedGroup ? intl('Workloads.Total') : intl('Common.Workloads')}
                  </th>
                </tr>
                {data.workloadsNum ? (
                  <tr
                    className="MapSubInfoPanel-Row MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                    data-tid="map-sub-info-panel-row"
                  >
                    <td className="MapSubInfoPanel-Row-Label">
                      <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-sub-info-panel-row-label">
                        {intl('Map.Count')}
                      </div>
                    </td>
                    <td className="MapInfoPanel-Row-Value" colSpan="2" data-tid="map-sub-info-panel-row-value">
                      <Link
                        className="CommandPanel-Link"
                        to={MapPageStore.getMapType() === 'app' ? 'appGroupWorkloads' : 'groupWorkloads'}
                        params={{id: data.href.split('-')[0]}}
                        data-tid="map-info-panel-workloads"
                      >
                        {data.workloads ? data.workloads.length : 0}
                      </Link>
                    </td>
                  </tr>
                ) : null}
                {policyStateHeader}
                {enforcementModes}
              </tbody>
            </table>
          </td>
        </tr>
      );

      if (!roleHasMultiType) {
        workloads = data.workloads.map((workload, index) => (
          <tr key={`Row-${index}`} className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
            <th colSpan="1" className="MapSubInfoPanel-Row-Label" key={workload.id} data-tid="map-info-panel-row-value">
              <Tooltip
                content={workload.tooltipName}
                position="workloads"
                width={372}
                location="fixed"
                data-tid="map-info-panel-row-value"
              >
                <div className="MapInfoPanel-Container">
                  {roleWorkloadsIsReadable ? (
                    <Link className="MapInfoPanel-Link" to="workloads.item" params={{id: workload.id}}>
                      {workload.name}
                    </Link>
                  ) : (
                    workload.name
                  )}
                </div>
              </Tooltip>
            </th>
            {enforcementKeys.length > 1 ? (
              <td
                className="MapInfoPanel-Row-Value MapInfoPanel-Row-Attribute--Enforcement"
                data-tid="map-info-panel-row-value"
              >
                {getEnforcementIntlByState(workload.policyState)}
              </td>
            ) : null}
          </tr>
        ));
      }

      let workloadHeaderText;

      if (data.totalWorkloadCount?.matched > workloads.length) {
        const counts = {count: data.workloads ? workloads.length : 0, total: data.totalWorkloadCount?.matched};

        workloadHeaderText = data.collapsedGroup
          ? intl('Map.Workloads.RoleTotalWorkloadsOfTotal', counts)
          : intl('Map.Workloads.RoleWorkloadsOfTotal', counts);
      } else {
        const counts = {count: data.workloads ? workloads.length : 0};

        workloadHeaderText = data.collapsedGroup
          ? intl('Map.Workloads.RoleTotalWorkloads', counts)
          : intl('Map.Workloads.RoleWorkloads', counts);
      }

      workloadHeader = (
        <tr className="MapInfoPanel-Header">
          <th colSpan="2" className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
            {workloadHeaderText}
          </th>
        </tr>
      );

      workloads = roleHasMultiType ? (
        workloads
      ) : (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel">
                <tbody>
                  {workloadHeader}
                  {workloads}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }

    let containerWorkloads;
    let containerWorkloadHeader;

    if (data.containerWorkloads && data.containerWorkloads.length) {
      data.containerWorkloads.forEach(workload => {
        if (RenderUtils.truncateAppGroupName(workload.name, 55, [55]).includes('...')) {
          workload.tooltipName = workload.name.slice(0);
        }

        workload.name = RenderUtils.truncateAppGroupName(workload.name, 55, [55]);
      });

      containerWorkloads = roleHasMultiType && (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2" className="MapInfoPanel-Row-Value">
            <table className="MapSubInfoPanel" data-tid="map-sub-info-panel">
              <tbody>
                <tr className="MapInfoPanel-Header">
                  <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
                    {data.collapsedGroup ? intl('ContainerWorkloads.Total') : intl('Common.ContainerWorkloads')}
                  </th>
                </tr>
                {data.containerWorkloadsNum ? (
                  <tr
                    className="MapSubInfoPanel-Row MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                    data-tid="map-sub-info-panel-row"
                  >
                    <td className="MapSubInfoPanel-Row-Label">
                      <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-sub-info-panel-row-label">
                        {intl('Map.Count')}
                      </div>
                    </td>
                    <td className="MapInfoPanel-Row-Value" colSpan="2" data-tid="map-sub-info-panel-row-value">
                      <Link
                        className="CommandPanel-Link"
                        to={
                          MapPageStore.getMapType() === 'app' ? 'appGroupContainerWorkloads' : 'groupContainerWorkloads'
                        }
                        params={{id: data.href.split('-')[0]}}
                        data-tid="map-info-panel-workloads"
                      >
                        {data.containerWorkloads ? data.containerWorkloads.length : 0}
                      </Link>
                    </td>
                  </tr>
                ) : null}
                {data.containerWorkloadsNum ? (
                  <tr
                    className="MapSubInfoPanel-Row MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                    data-tid="map-sub-info-panel-row"
                  >
                    <td className="MapSubInfoPanel-Row-Label">
                      <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-sub-info-panel-row-label-enforcement">
                        {intl('Common.Enforcement')}
                      </div>
                    </td>
                    <td
                      className="MapInfoPanel-Row-Value"
                      colSpan="2"
                      data-tid="map-sub-info-panel-row-value-enforcement"
                    >
                      {containerEnforcementKeys.length === 1
                        ? getEnforcementIntlByState(containerEnforcementKeys[0])
                        : null}
                    </td>
                  </tr>
                ) : null}
                {containerEnforcementKeys.length > 1 ? containerEnforcementModes : null}
              </tbody>
            </table>
          </td>
        </tr>
      );

      if (!roleHasMultiType) {
        containerWorkloads = data.containerWorkloads.map((workload, index) => (
          <tr key={`Row-${index}`} className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
            <th colSpan="2" className="MapSubInfoPanel-Row-Label" key={workload.id} data-tid="map-info-panel-row-value">
              <Tooltip
                content={workload.tooltipName}
                position="workloads"
                width={372}
                location="fixed"
                data-tid="map-info-panel-row-value"
              >
                <div className="MapInfoPanel-Container">
                  {roleWorkloadsIsReadable ? (
                    <Link className="MapInfoPanel-Link" to="containerWorkload" params={{id: workload.id}}>
                      {workload.name}
                    </Link>
                  ) : (
                    workload.name
                  )}
                </div>
              </Tooltip>
            </th>
            {containerEnforcementKeys.length > 1 ? (
              <td
                className="MapInfoPanel-Row-Value MapInfoPanel-Row-Attribute--Enforcement"
                data-tid="map-info-panel-row-value"
              >
                {getEnforcementIntlByState(workload.policyState)}
              </td>
            ) : null}
          </tr>
        ));
      }

      containerWorkloadHeader = (
        <tr className="MapInfoPanel-Header">
          <th colSpan="2" className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
            {intl('Map.Workloads.RoleContainerWorkloads', {
              count: data.containerWorkloads ? data.containerWorkloads.length : 0,
            })}
          </th>
        </tr>
      );
      containerWorkloads = roleHasMultiType ? (
        containerWorkloads
      ) : (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2" className="MapInfoPanel-Row-Value">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel">
                <tbody>
                  {containerWorkloadHeader}
                  {containerWorkloads}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }

    let virtualServices;
    let virtualServiceHeader;

    if (data.virtualServices && data.virtualServices.length) {
      data.virtualServices.forEach(vs => {
        if (RenderUtils.truncateAppGroupName(vs.name, 55, [55]).includes('...')) {
          vs.tooltipName = vs.name.slice(0);
        }

        vs.name = RenderUtils.truncateAppGroupName(vs.name, 55, [55]);
      });

      virtualServices = data.virtualServices.map((workload, index) => (
        <tr key={`Row-${index}`} className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
          <th colSpan="2" className="MapSubInfoPanel-Row-Label" key={workload.id} data-tid="map-info-panel-row-value">
            <Tooltip
              content={workload.tooltipName}
              position="workloads"
              width={372}
              location="fixed"
              data-tid="map-info-panel-row-value"
            >
              <div className="MapInfoPanel-Container">
                {roleWorkloadsIsReadable ? (
                  <Link className="MapInfoPanel-Link" to="virtualService" params={{id: workload.id, pversion: 'draft'}}>
                    {workload.name}
                  </Link>
                ) : (
                  workload.name
                )}
              </div>
            </Tooltip>
          </th>
        </tr>
      ));
      virtualServiceHeader = (
        <tr className="MapInfoPanel-Header">
          <th colSpan="2" className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
            {intl('Map.Workloads.RoleVirtualServices', {count: data.virtualServices ? data.virtualServices.length : 0})}
          </th>
        </tr>
      );
      virtualServices = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel">
                <tbody>
                  {virtualServiceHeader}
                  {virtualServices}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }

    let vulnerabilities;

    if (data.vulnerabilities) {
      vulnerabilities = data.vulnerabilities.map(vulnerability => (
        <tr
          className="MapSubInfoPanel-Row"
          onClick={_.partial(this.selectVulnerability, {...vulnerability})}
          data-tid="vulnerability-sub-info-panel-row"
        >
          <td
            className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Width-Vulnerability"
            data-tid="map-info-panel-row-value-vulnerability-name"
          >
            <span className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
              <Tooltip content={vulnerability.details.name} position="location-group" width={372} location="fixed">
                {vulnerability.details.name}
              </Tooltip>
            </span>
          </td>
          <td className="MapSubTrafficPanel-Row-Port-Protocol">
            {vulnerability.port || vulnerability.protocol
              ? `${vulnerability.port ? vulnerability.port : ''} ${
                  vulnerability.protocol ? ServiceUtils.lookupProtocol(vulnerability.protocol) : ''
                }`
              : intl('Common.NA')}
          </td>
          <td className="MapSubInfoPanel-Row-Value-Vulnerability">
            <Vulnerability vulnerability={{...vulnerability}} opacity />
            <span className="MapSubInfoPanel-RowValue-VulnerabilityIconMargin">
              <Icon name="next" size="xxlarge" position="after" tid="nav-to-vulnerability" />
            </span>
          </td>
        </tr>
      ));
      vulnerabilities = roleWorkloadsIsReadable ? (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel MapSubInfoPanel-Row-Auto-Layout">
                <tbody>
                  <tr className="MapSubInfoPanel-Header" data-tid="map-info-panel-row-header">
                    <th colSpan="2" className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                      {`${data.vulnerabilities.length} ${intl('Common.Vulnerabilities')}`}
                    </th>
                  </tr>
                  {vulnerabilities}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      ) : null;
    }

    return (
      <table className="MapInfoPanel">
        <tbody>
          {group}
          {location}
          {vulnerabilities}
          {workloads}
          {containerWorkloads}
          {roleHasMultiType ? null : policyState}
          {virtualServices}
        </tbody>
      </table>
    );
  },
});
