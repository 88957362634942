/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import Constants from '../constants';

let routesMap = new Map();

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SET_ROUTES_MAP:
        routesMap = action.data;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getRoutesMap: () => routesMap,
});
