/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import Constants from '../constants';
import {createApiStore} from '../lib/store';

// Variable to hold the health data for all the PCEs
// Local health is always going to be the first time
let clusters = [];
// Health data keyed by the PCE FQDN
const clusterData = {};
// If current Supercluster is in a rolling upgrade
let inRollingUpgrade = false;
// Rolling upgrade finished
let activeWhileUpgradeFinished = false;

let trafficDBMigrationInProgress = false;

function setData(data) {
  const wasInUpgrade = inRollingUpgrade;

  clusters = data;

  data.forEach(cluster => {
    clusterData[cluster.fqdn] = cluster;

    if (cluster.upgrade_pending) {
      inRollingUpgrade = true;
    }

    if (cluster.groups) {
      const applicationGroup = _.find(cluster.groups, {group: 'Application'});
      const trafficDBSection =
        applicationGroup && _.find(applicationGroup.components, {section: 'Traffic Database Migration'});

      trafficDBMigrationInProgress =
        trafficDBSection && trafficDBSection.contents && trafficDBSection.contents.length > 0;
    }
  });

  if (wasInUpgrade && !inRollingUpgrade) {
    activeWhileUpgradeFinished = true;
  }
}

export default createApiStore(['HEALTH_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.HEALTH_GET_SUCCESS:
        setData(action.data);
        break;
      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getClusterData(fqdn) {
    if (fqdn === 'local') {
      return this.getLocalData();
    }

    return clusterData[fqdn];
  },

  getRemainingRollingUpgradeCount() {
    return clusters.filter(cluster => cluster.upgrade_pending).length;
  },

  getRollingUpgradeCount() {
    return clusters.length;
  },

  getInRollingUpgrade() {
    return inRollingUpgrade;
  },

  getActiveWhileUpgradeFinished() {
    return activeWhileUpgradeFinished;
  },

  getLocalListenOnlyEnableTime() {
    return clusters[0] ? clusters[0].listen_only_mode_enabled_at : null;
  },

  getClusters() {
    return clusters;
  },

  getLocalData() {
    // The first item is the local pce
    return clusters[0];
  },

  isTrafficDBMigrationInProgress() {
    return trafficDBMigrationInProgress;
  },
});
