/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {PortUtils, ServiceUtils, PolicyGeneratorUtils} from '../../../utils';
import actionCreators from '../../../actions/actionCreators';
import ServiceCreateDialog from '../../../modals/ServiceCreateDialog';
import {ObjectSelector, Checkbox, ConfirmationDialog, Badge, Icon, Button} from '../..';
import {SessionStore} from '../../../stores';

export default class SelectService extends Component {
  constructor(props) {
    super(props);

    this.addService = this.addService.bind(this);
    this.removeService = this.removeService.bind(this);
    this.createService = this.createService.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleVerifyService = this.handleVerifyService.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleUseAlways = this.handleUseAlways.bind(this);
    this.customListItem = this.customListItem.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOverCaret = this.handleMouseOverCaret.bind(this);
  }

  componentDidUpdate() {
    if (!this.props.policyGenerator && this.input) {
      this.input.refs.itemInput.click();
    }
  }

  handleCancel() {
    this.props.onClose(this.props.row.service, false);
    this.props.onEdit(false);
  }

  handleContinue() {
    this.props.onClose(this.state.services, this.state.useAlways);
    this.props.onEdit(false);
  }

  handleMouseOver(item) {
    if (this.props.row.services[0].name !== item.value) {
      this.setState({removeHighlight: true});
    }
  }

  handleMouseOverCaret() {
    if (this.state.removeHighlight) {
      this.setState({removeHighlight: false});
    }
  }

  handleEdit() {
    const {newService} = this.props.row;

    if (!newService[this.props.index]) {
      this.props.onEdit('service');

      return;
    }

    const service = newService[this.props.index];
    const key = PolicyGeneratorUtils.getServicePortKey(service);

    // Make these calculations only on edit.
    // Prevent grid instance from getting off, and no need to calculate when not needed
    this.props.onEdit('service');
    this.setState({
      items: {},
      useAlways: true,
      service,
      moreServices: this.props.row.port && ServiceUtils.matchConnectionWithService(this.props.row.port[key][0]),
    });
  }

  addService(filter, value) {
    if (this.props.policyGenerator) {
      this.setState({items: {[filter]: value}, services: [value]});
    } else {
      this.props.onClose([value]);
      this.props.onEdit(false);
    }
  }

  removeService() {
    this.setState({items: {}, service: this.props.row.service});
  }

  createService(service) {
    let autoDescription = '';
    let description = '';
    const ports = this.props.row.port;

    if (ports) {
      const key = PolicyGeneratorUtils.getServicePortKey(service);
      const {processName, serviceName} = ports[key] ? ports[key][0] : {};

      if (this.props.policyGenerator) {
        autoDescription = service.service_ports
          ? intl('PolicyGenerator.AutoDescription')
          : intl('PolicyGenerator.ServiceAutoDescription', {
              processName: processName || 'unknown',
              serviceName: serviceName || 'unknown',
            });
        autoDescription += '\n';
      }

      description = service.service_ports
        ? autoDescription
        : ports &&
          ports[key] &&
          ports[key].reduce(
            (result, port) => {
              const portProtocol = `${port.port} ${ServiceUtils.lookupProtocol(port.protocol)}`;

              result += `\r${portProtocol} `;

              if (port.processName && port.processName.toUpperCase() === 'SYSTEM') {
                result = `${result} "SYSTEM"\r${portProtocol} "${port.serviceName}"`;
              } else {
                if (port.processName !== 'unknown') {
                  result += intl('PolicyGenerator.WindowsServiceHelp', {process: port.processName});
                }

                if (port.serviceName !== 'unknown') {
                  result += ` "${port.serviceName}"`;
                }
              }

              return result;
            },
            `${autoDescription} ${intl('PolicyGenerator.WindowsServiceInstructions')} \n`,
          );
    }

    const newService = {...service, description};

    newService.windows_services &&= [];

    this.autoDescription = autoDescription;
    this.description = description;

    actionCreators.openDialog(
      <ServiceCreateDialog returnService={true} providedService={newService} onCreate={this.handleVerifyService} />,
    );
  }

  handleVerifyService(service, oldService) {
    // TODO Joy: Verify not a duplicate name
    // If the user changed the description, use that, otherwise strip off the extraneous help text
    const newService = {
      ...service,
      description:
        this.description.replaceAll(/\s/g, '') === service.description.replaceAll(/\s/g, '')
          ? this.autoDescription
          : service.description,
    };

    // We aren't sure how they changed the service, so try to match based on the old service
    // but for windows we send an empty array for the old service, so do our best with the new service
    const port =
      this.props.row.port &&
      (this.props.row.port[PolicyGeneratorUtils.getServicePortKey(oldService)] ||
        this.props.row.port[PolicyGeneratorUtils.getServicePortKey(service)]);

    // Verify all the connections are covered by a service
    // If not prompt to continue, or go back to create service modal
    if (port && port.some(connection => !ServiceUtils.matchConnectionWithService(connection, [newService]).length)) {
      actionCreators.openDialog(
        <ConfirmationDialog
          title={intl('PolicyGenerator.AllTrafficNotAllowed')}
          message={intl('PolicyGenerator.AllTrafficNotAllowedDescription')}
          cancelLabel={intl('PolicyGenerator.EditService')}
          onConfirm={_.partial(this.handleCreate, newService)}
          onCancel={_.partial(this.createService, newService)}
        />,
      );
    } else {
      this.handleCreate(newService);
    }
  }

  handleCreate(service) {
    const items = {[service.name]: [service]};

    if (this.props.policyGenerator) {
      this.setState({items, services: [service]});
    } else {
      this.props.onClose([service]);
      this.props.onEdit(false);
    }
  }

  customListItem({text, props, item}) {
    let className = `${props.className || ''} OSServiceSelectResultItem`;

    if (item.footer) {
      className += ' OSServiceSelectFooter';
    }

    if (this.props.row.services[0].name === text && !this.state.removeHighlight) {
      className += ' OSRulesetSelectSelected';
    }

    let secondary;

    if (item) {
      const service = this.state.moreServices.find(service => service.name === item);

      if (service) {
        secondary = this.formatPort(service);
      } else if (!item.footer && text !== intl('Common.AllServices') && item.name && !item.hasOwnProperty('port')) {
        secondary = <Badge type="new" />;
      }
    }

    if (text === intl('Services.Create')) {
      className += ' OSServiceSelectResultItem--CreateService';
    }

    return (
      <li key={text} {...props} className={className} data-tid="comp-select-results-item">
        <span className="OSServiceSelectResultItem-Name">{text}</span>
        <span className="OSServiceSelectResultItem-PortProtocol">{secondary}</span>
      </li>
    );
  }

  formatPort(service) {
    const connections = service.service_ports || service.windows_services;

    if (connections) {
      return connections.map(value => PortUtils.stringifyPortObjectReadonly(value)).join(', ');
    }
  }

  handleUseAlways() {
    this.setState({useAlways: !this.state.useAlways});
  }

  render() {
    const {name, edit, editable, policyGenerator} = this.props;
    const {port, newService, portService} = this.props.row;
    const services = this.props.row.services || this.props.row.ingress_services;

    if (!services) {
      return null;
    }

    if (!edit) {
      const icon = policyGenerator ? 'edit' : 'caret-down';
      const size = policyGenerator ? 'small' : 'xlarge';
      const itemEditable = port && editable;

      return (
        <div
          className={`SelectService${itemEditable ? ' MapFormPanel-Selected-Item--Editable' : ''}`}
          data-tid="map-info-panel-row-value"
          onClick={itemEditable ? this.handleEdit : _.noop}
        >
          {!services[this.props.index].href && !services[this.props.index].hasOwnProperty('port') && (
            <Badge type="new" />
          )}
          <div className="SelectService-Name">{name}</div>
          {itemEditable ? (
            <span className="Icon-Edit">
              <Icon name={icon} size={size} position="after" tid="edit-service" />
            </span>
          ) : null}
        </div>
      );
    }

    const {items} = this.state;
    let dropdownValues = {
      [services[this.props.index].name ||
      [services[this.props.index].port, ServiceUtils.lookupProtocol(services[this.props.index].proto)].join(' ')]:
        services[this.props.index],
    };

    if (portService) {
      dropdownValues[portService[this.props.index].name] = portService[this.props.index];
    }

    dropdownValues = this.state.moreServices.reduce((result, serviceOpt) => {
      result[serviceOpt.name] = serviceOpt;

      return result;
    }, dropdownValues);

    dropdownValues = Object.assign(dropdownValues, {
      [intl('Common.AllServices')]: PolicyGeneratorUtils.getAllServices(),
    });

    // Don't give the all services option in the policy generator - they should select R2R for this
    if (policyGenerator) {
      delete dropdownValues[intl('Common.AllServices')];
    }

    const props = {
      singleValues: dropdownValues,
      addItem: this.addService,
      removeItem: this.removeService,
      dropdownValues,
      customListItem: this.customListItem,
      getFacetValues: _.noop,
      initialValues: [],
      items,
      placeholder: _.isEmpty(items) ? intl('Rulesets.Rules.SelectService') : '',
      allowOne: true,
      facetMap: {[services[0].name]: 'service'},
      isInitial: true,
      customClassItems: ['initial'],
      getCustomClass: this.getCustomClass,
      customCaretIcon: policyGenerator ? null : (
        <span onMouseOver={this.handleMouseOverCaret}>
          <Icon name="caret-up" size="xlarge" styleClass="ObjectSelector-down-arrow" />
        </span>
      ),
      onClose: policyGenerator ? _.noop : this.handleCancel,
      onMouseOver: this.handleMouseOver,
      ref: node => (this.input = node),
      footerValues: SessionStore.isGlobalEditEnabled()
        ? [
            {
              footer: true,
              text: intl('Services.Create'),
              className: 'ObjectSelector-dd-values-item--action',
              onClick: _.partial(this.createService, newService[this.props.index]),
            },
          ]
        : null,
      returnValue: item => item.value || item.name || item.label || item.toString(),
      // Added service is not in the singleItems so mark this as truncated
      singleValuesTruncated: true,
    };

    return (
      <div className="SelectService-Selector" data-tid="map-info-panel-row-value">
        <div className="SelectService-ObjectSelector">
          <ObjectSelector {...props} />
        </div>
        {policyGenerator ? (
          <div className="SelectService-Selector-UseAlways">
            <Checkbox
              checked={this.state.useAlways}
              onChange={this.handleUseAlways}
              label={intl('PolicyGenerator.EditServiceUseAlways')}
            />
          </div>
        ) : null}
        {policyGenerator ? (
          <div className="SelectService-Actions">
            <Button
              text={intl('Common.Cancel')}
              tid="cancel"
              type={policyGenerator ? 'secondary' : 'primary'}
              onClick={this.handleCancel}
            />
            <Button
              text={intl('Common.Continue')}
              tid="continue"
              autoFocus={true}
              disabled={_.isEmpty(items)}
              onClick={this.handleContinue}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
