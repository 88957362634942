/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import {Icon} from '..';
import {Navigation} from 'react-router';
import {SessionStore, RulesetStore, VenReleaseStore} from '../../stores';
import {StoreMixin} from '../../mixins';
import {RestApiUtils} from 'scripts/utils';
import {getId} from '../../utils/GeneralUtils';
import actionCreators from '../../actions/actionCreators';

const getStateFromStores = () => {
  return {
    product: SessionStore.getProduct(),
    version: SessionStore.getUIVersion(),
    parsedVersion: SessionStore.getParsedUIVersionNumber(),
    isEnabled: SessionStore.isWhatsNewEnabled(),
    user: SessionStore.getUserId(),
    docUrl: SessionStore.getWhatsNewDocUrl(),
    endpointVENCount: VenReleaseStore.getEndpointVENCount(),
    upgradeRuleset: RulesetStore.getPCEUpgradeRuleset(),
  };
};

export default React.createClass({
  mixins: [Navigation, StoreMixin([SessionStore, RulesetStore, VenReleaseStore], getStateFromStores)],

  handleWhatsNewClose() {
    actionCreators.closeWhatsNew(this.state.parsedVersion);
    RestApiUtils.kvPair.update(this.state.user, 'whats_new', this.state.parsedVersion);
  },

  render() {
    const {isEnabled, product, version, docUrl, endpointVENCount, upgradeRuleset} = this.state;

    return isEnabled ? (
      <div className="WhatsNewPopup" data-tid="whatsnew">
        <Icon name="close" styleClass="close" size="large" onClick={this.handleWhatsNewClose} />
        <div>
          <div className="WhatsNewPopup-title">{intl('WhatsNew.NewAnnouncement')}</div>
          <div className="WhatsNewPopup-content">
            {intl('WhatsNew.IsReleased', {product: _.capitalize(product), version})}
          </div>
        </div>
        <div>
          <a href={docUrl} className="WhatsNewPopup-link" target="_blank">
            {intl('WhatsNew.SeeWhatsNew')}
          </a>
        </div>
        {endpointVENCount > 0 && !_.isEmpty(upgradeRuleset) && (
          <div className="WhatsNewPopup-secondary">
            {intl('WhatsNew.RulesForNonDomainAdapters', {
              a: ([text]) => (
                <span>
                  <br />
                  <a
                    href={this.makeHref('rulesets.item', {
                      id: getId(upgradeRuleset.href),
                      pversion: 'draft',
                      tab: 'intrascope',
                    })}
                    className="WhatsNewPopup-link"
                    target="_blank"
                  >
                    {text}
                  </a>
                </span>
              ),
            })}
          </div>
        )}
      </div>
    ) : null;
  },
});
