/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import update from 'react-addons-update';
import {Link, Navigation} from 'react-router';
import {ToolBar, ToolGroup} from '../components/ToolBar';
import {
  AttributeList,
  Button,
  Checkbox,
  Dialog,
  Icon,
  Label,
  LabelGroup,
  RadioGroup,
  Select,
  RouteLink,
  ScopeSelector,
  AlertDialog,
  ConfirmationDialog,
  Notification,
  NotificationGroup,
  Tooltip,
  Banner,
  HoverMenu,
  Badge,
} from '../components';
import {Form, Fieldset, Field} from '../components/Form';
import {ASPVersion, ConfirmPolicyState, MapDataSync, Success, PolicyGeneratorDisabledRulesetsDialog} from '../modals';
import actionCreators from '../actions/actionCreators';

const labelData = {key: 'env', value: 'testWithIcon'};

const labelData2 = {value: 'testWithoutIcon'};

const attributeListData = [
  {key: intl('Common.Name'), value: intl('Components.AttributeListTest')},
  {key: intl('Common.Status'), value: `${intl('Common.Success')}!`},
];

const selectData = [
  {value: 'loc', label: intl('Common.Location')},
  {value: 'app', label: intl('Common.Application')},
  {value: 'env', label: intl('Common.Environment')},
  {value: 'role', label: intl('Common.Role')},
];

const menuItems = [
  {enTranslation: intl('Explorer.IncludeConsumers'), type: 'consumerInclude', tid: 'includeConsumers'},
  {enTranslation: intl('Explorer.ExcludeConsumers'), type: 'consumerExclude', tid: 'excludeConsumers'},
  {enTranslation: intl('Explorer.IncludeProviders'), type: 'providerInclude', tid: 'includeProviders'},
  {enTranslation: intl('Explorer.ExcludeProviders'), type: 'providerExclude', tid: 'excludeProviders'},
];

const selection = (
  <div style={{width: 200}}>
    <Select options={selectData} selected="loc" />
  </div>
);
const radioGroupData = [
  {key: 'name', value: intl('Common.Name')},
  {key: 'label', value: intl('Common.Label'), detail: selection, detailFormat: 'right'},
];

const openDialog = () => {
  actionCreators.openDialog(<Dialog>test dialog</Dialog>);
};

const openModal = () => {
  actionCreators.openDialog(<Dialog>test modal</Dialog>);
};

const defaultNotification = {
  type: 'instruction',
  title: 'Sample Instructions Notification',
  message:
    'This is some sample instructions that is going to be rather long and wrap to a few lines. This will wrap to multiple lines and force the message to a new line rather than being on the same line as the title.',
  button: <Button text="More Info" onClick={() => {}} />,
  closeable: true,
};

export default React.createClass({
  mixin: [Navigation],

  getInitialState() {
    return {
      checked: false,
      radioSelect: '',
      notifications: [NotificationGroup],
      notificationsShrink: false,
      notificationsSidebar: false,
    };
  },

  handleCheck() {
    const checked = this.state.checked;

    this.setState({checked: !checked});
  },

  handleNotificationChange(newNotifications) {
    this.setState({notifications: newNotifications});
  },

  handleNotificationShrink() {
    const notificationsShrink = this.state.notificationsShrink;

    this.setState({notificationsShrink: !notificationsShrink});
  },

  handleNotificationSidebar() {
    const notificationsSidebar = this.state.notificationsSidebar;

    this.setState({notificationsSidebar: !notificationsSidebar});
  },

  handleRadio(evt) {
    this.setState({radioSelect: evt.target.value});
  },

  handleSelect(input) {
    this.setState({selected: input.value});
  },

  render() {
    return (
      <div className="ComponentsPage">
        <Link to="components.list" target="_blank" className="ComponentsPage-jumpToNew">
          Jump to new redux components
        </Link>
        <section>
          <h2>Version</h2>
          <Button text="Version Dialog" onClick={openVersionDialog} />
        </section>
        <section>
          <h2>Scope Selector</h2>
          <ScopeSelector />
        </section>
        <section>
          <h2>Attribute List</h2>
          <AttributeList data={attributeListData} />
        </section>
        <section>
          <h2>Buttons</h2>
          <ToolBar>
            <ToolGroup>
              <Button text="Enabled Button" />
              <Button disabled={true} text="Disabled Button" />
              <Tooltip
                content={
                  <div>
                    <h2>wioejfiowejoiww</h2>
                    <h1>eifjiejf</h1>
                    <ToolBar>
                      <ToolGroup>
                        <Label text={labelData2.value} />
                        <Label icon={labelData.key} text={labelData.value} />
                      </ToolGroup>
                    </ToolBar>
                  </div>
                }
                location="topleft"
              >
                <Button text="topleft" />
              </Tooltip>
              <Tooltip content="hi ihiihihihihi hihih ihi hihihihi hihihihih ihihihihihihihihihi" location="top">
                <Button text="top" />
              </Tooltip>
              <Tooltip content="hi ihiihihihihi hihih ihi hihihihi hihihihih ihihihihihihihihihi" location="topright">
                <Button text="topright" />
              </Tooltip>
              <Tooltip content="hi ihiihihihihi hihih ihi hihihihi hihihihih ihihihihihihihihihi" location="bottomleft">
                <Button text="bottomleft" />
              </Tooltip>
              <Tooltip content="hi ihiihihihihi hihih ihi hihihihi hihihihih ihihihihihihihihihi" location="bottom">
                <Button text="bottom" />
              </Tooltip>
              <Tooltip
                content="hi ihiihihihihi hihih ihi hihihihi hihihihih ihihihihihihihihihi"
                location="bottomright"
              >
                <Button text="bottomright" />
              </Tooltip>
            </ToolGroup>
          </ToolBar>
          <h4>Primary</h4>
          <ToolBar>
            <ToolGroup>
              <Button text="primary large" type="primary" size="large" />
              <Button text="primary medium" type="primary" size="medium" />
              <Button text="primary small" type="primary" size="small" />
            </ToolGroup>
          </ToolBar>
          <ToolBar>
            <ToolGroup>
              <Button text="primary large disabled" disabled type="primary" size="large" />
              <Button text="primary medium disabled" disabled type="primary" size="medium" />
              <Button text="primary small disabled" disabled type="primary" size="small" />
            </ToolGroup>
          </ToolBar>
          <h4>Secondary</h4>
          <ToolBar>
            <ToolGroup>
              <Button text="secondary large" type="secondary" size="large" />
              <Button text="secondary medium" type="secondary" size="medium" />
              <Button text="secondary small" type="secondary" size="small" />
            </ToolGroup>
          </ToolBar>
          <ToolBar>
            <ToolGroup>
              <Button text="secondary large disabled" disabled type="secondary" size="large" />
              <Button text="secondary medium disabled" disabled type="secondary" size="medium" />
              <Button text="secondary small disabled" disabled type="secondary" size="small" />
            </ToolGroup>
          </ToolBar>
          <h4>Reversed</h4>
          <ToolBar>
            <ToolGroup>
              <Button text="reversed large" type="reversed" size="large" />
              <Button text="reversed medium" type="reversed" size="medium" />
              <Button text="reversed small" type="reversed" size="small" />
            </ToolGroup>
          </ToolBar>
          <ToolBar>
            <ToolGroup>
              <Button text="reversed large disabled" disabled type="reversed" size="large" />
              <Button text="reversed medium disabled" disabled type="reversed" size="medium" />
              <Button text="reversed small disabled" disabled type="reversed" size="small" />
            </ToolGroup>
          </ToolBar>
          <h4>strong</h4>
          <ToolBar>
            <ToolGroup>
              <Button text="strong large" type="strong" size="large" />
              <Button text="strong medium" type="strong" size="medium" />
              <Button text="strong small" type="strong" size="small" />
            </ToolGroup>
          </ToolBar>
          <ToolBar>
            <ToolGroup>
              <Button text="strong large disabled" disabled type="strong" size="large" />
              <Button text="strong medium disabled" disabled type="strong" size="medium" />
              <Button text="strong small disabled" disabled type="strong" size="small" />
            </ToolGroup>
          </ToolBar>
          <h4>nofill</h4>
          <ToolBar>
            <ToolGroup>
              <Button text="nofill large" type="nofill" size="large" />
              <Button text="nofill medium" type="nofill" size="medium" />
              <Button text="nofill small" type="nofill" size="small" />
            </ToolGroup>
          </ToolBar>
          <ToolBar>
            <ToolGroup>
              <Button text="nofill large disabled" disabled type="nofill" size="large" />
              <Button text="nofill medium disabled" disabled type="nofill" size="medium" />
              <Button text="nofill small disabled" disabled type="nofill" size="small" />
            </ToolGroup>
          </ToolBar>
          <h4>linky</h4>
          <ToolBar>
            <ToolGroup>
              <Button text="linky large" type="linky" size="large" />
              <Button text="linky medium" type="linky" size="medium" />
              <Button text="linky small" type="linky" size="small" />
            </ToolGroup>
          </ToolBar>
          <ToolBar>
            <ToolGroup>
              <Button text="linky large disabled" disabled type="linky" size="large" />
              <Button text="linky medium disabled" disabled type="linky" size="medium" />
              <Button text="linky small disabled" disabled type="linky" size="small" />
            </ToolGroup>
          </ToolBar>
          <h4>huge</h4>
          <ToolBar>
            <ToolGroup>
              <Button text="primary huge" type="primary" size="huge" />
              <Button text="secondary huge" type="secondary" size="huge" />
              <Button text="reversed huge" type="reversed" size="huge" />
              <Button text="strong huge" type="strong" size="huge" />
              <Button text="nofill huge" type="nofill" size="huge" />
              <Button text="linky huge" type="linky" size="huge" />
            </ToolGroup>
          </ToolBar>
        </section>
        <section>
          <h2>Checkboxes</h2>
          <Checkbox label={intl('Common.EnabledLow')} checked={this.state.checked} onChange={this.handleCheck} />
          <Checkbox label={intl('Common.DisabledLow')} disabled={true} onChange={this.handleCheck} />
        </section>
        <section>
          <h2>Labels</h2>
          <ToolBar>
            <ToolGroup>
              <Label text={labelData2.value} />
              <Label icon="app" text="Application Label" />
              <Label icon="env" text="Environment Label" />
              <Label icon="loc" text="Location Label" />
              <Label icon="role" text="Role Label" />

              <Label icon="app" text="Application Label" type="created" />
              <Label icon="env" text="Environment Label" type="deleted" />

              <Label icon="all-workloads" text="All Workloads" />
              <Label icon="ip-list" text="IP List" />
              <Label icon="virtual-service" text="Virtual Service" />
              <Label icon="user-group" text="User Group" />
              <Label icon="virtual-server" text="Virtual Server" />
              <Label icon="workload" text="Workload" />

              <LabelGroup type="app" text="Application Label Group" />
              <LabelGroup type="env" text="Environment Label Group" />
              <LabelGroup type="loc" text="Location Label Group" />
              <LabelGroup type="role" text="Role Label Group" />
            </ToolGroup>
          </ToolBar>
        </section>
        <section>
          <h2>Hover Menu</h2>
          <ToolBar>
            <ToolGroup>
              <HoverMenu
                menuItems={menuItems}
                labelProps={{iconName: 'app', text: 'Application Label'}}
                labelType="app"
              />
              <HoverMenu menuItems={menuItems} labelProps={{iconName: 'role', text: 'Role Label'}} labelType="role" />
            </ToolGroup>
          </ToolBar>
        </section>
        <section>
          <h2>Button Icons</h2>
          <ToolBar>
            <ToolGroup>
              <Button text="Linky button" icon="edit" type="linky" />
              <Button icon="edit" type="nofill" content="icon-only" />
              <Button text={intl('Common.Back')} disabled />
              <Button text={intl('Common.Next')} />
              <Button text={intl('Common.Remove')} />
              <Button type="secondary" disabled text={intl('Common.Delete')} />
              <Button type="secondary" text={intl('Common.Save')} />
              <Button type="strong" text={intl('Common.Edit')} />
              <Button type="primary" text={intl('Common.Add')} />
              <Button type="strong" disabled text={intl('Common.Close')} />
              <Button text={intl('Common.Illuminate')} />
              <Button text={intl('Common.Enforce')} />
              <Button text={intl('PairingProfiles.Pair')} />
              <Button text={intl('Common.AssignRole')} />
              <Button text={intl('Common.EntityState')} />
              <Button text={intl('Role.Set')} />
              <Button text={intl('Workloads.SetEnforcement')} />
              <Button text={intl('Common.Filter')} />
              <Button text={intl('Common.ConfigurationWizard')} />
              <Button text={intl('Common.Provision')} />
              <Button text={intl('Provision.All')} />
              <Button text={intl('Provision.Now')} />
              <Button text={intl('Provision.Revert.All')} />
              <Button text={intl('Provision.Revert.Now')} />
              <Button text={intl('Common.Cancel')} />
              <Button text={intl('Common.Confirm')} />
              <Button text={intl('Common.OK')} />
              <Button text={intl('Common.Finish')} />
              <Button text={intl('Common.Change')} />
              <Button text={intl('Users.Invite.Reinvite')} />
              <Button text={intl('Common.Invite')} />
              <Button text={intl('Common.Export')} />
              <Button text={intl('Common.GenerateKey')} />
              <Button text={intl('PairingProfiles.Start')} />
              <Button text={intl('PairingProfiles.Resume')} />
              <Button text={intl('PairingProfiles.Stop')} />
              <Button text={intl('Common.Download')} />
            </ToolGroup>
          </ToolBar>
        </section>
        <section>
          <h2>Button Actions</h2>
          <ToolBar justify="right">
            <ToolGroup>
              <Button text={intl('Components.Dialog')} onClick={openDialog} />
              <Button text={intl('Components.Modal')} onClick={openModal} />
              <Button text="Confirm Policy State Dialog" onClick={openConfirmPolicyStateDialog} />
              <Button text="User Added Dialog" onClick={openUserAddedDialog} />
              <Button text="Map Data Sync Dialog" onClick={openMapDataSyncDialog} />
              <Button text="Different Org Invite Dialog" onClick={openDifferentOrgInviteDialog} />
              <Button text="User Already In Org Dialog" onClick={openUserAlreadyInOrgDialog} />
              <Button text="Role Change Success Dialog" onClick={openRoleChangeSuccessDialog} />
              <Button text="Reinvite Successful Dialog" onClick={openReinviteSuccessfulDialog} />
              <Button text="User Removed Successful Dialog" onClick={openUserRemovedSuccessfulDialog} />
              <Button text="Unsaved Changes Dialog" onClick={openUnsavedChangesDialog} />
              <Button text="Name Validation Error Dialog" onClick={openNameValidationErrorDialog} />
              <Button text="Complete Invite While Logged In Dialog" onClick={openCompleteInviteWhileLoggedInDialog} />
              <Button text="Full Name Missing Dialog" onClick={openFullNameMissingDialog} />
              <Button text="Time Zone Missing Dialog" onClick={openTimeZoneMissingDialog} />
              <Button text="Terms Must Be Accepted Dialog" onClick={openTermsMustBeAcceptedDialog} />
              <Button text="Confirm Leave Page Dialog" onClick={openConfirmLeavePageDialog} />
              <Button text="Test Email Sent Dialog" onClick={openTestEmailSentDialog} />
              <Button text="Test Email Failure Dialog" onClick={openTestEmailFailureDialog} />
              <Button text="Confirm Delete Services Dialog" onClick={openConfirmDeleteServicesDialog} />
              <Button text="Confirm Delete Service Dialog" onClick={openConfirmDeleteServiceDialog} />
              <Button text="Confirm Discard Pending Changes Dialog" onClick={openConfirmDiscardPendingChangesDialog} />
              <Button text="Confirm Delete Scopes Dialog" onClick={openConfirmDeleteScopesDialog} />
              <Button text="Confirm Delete Rulesets Dialog" onClick={openConfirmDeleteRulesetsDialog} />
              <Button text="Confirm Delete Profile Dialog" onClick={openConfirmDeleteProfileDialog} />
              <Button text="Delete Failed Item In Use Dialog" onClick={openDeleteFailedItemInUseDialog} />
              <Button text="Delete Failed Labels In Use Dialog" onClick={openDeleteFailedLabelsInUseDialog} />
              <Button text="Confirm Delete Labels Dialog" onClick={openConfirmDeleteLabelsDialog} />
              <Button text="Confirm Delete Label Dialog" onClick={openConfirmDeleteLabelDialog} />
              <Button text="Confirm Delete Dialog" onClick={openConfirmDeleteDialog} />
              <Button text="Confirm Delete IP Lists Dialog" onClick={openConfirmDeleteIpListsDialog} />
              <Button text="Confirm Delete IP List Dialog" onClick={openConfirmDeleteIpListDialog} />
              <Button text="Map Request Limit Dialog" onClick={openMapRequestLimitDialog} />
              <Button text="Session Timeout Dialog" onClick={openSessionTimeoutDialog} />
              <Button text="Network Settings Apply Success Dialog" onClick={openNetworkSettingsApplySuccessDialog} />
              <Button text="Settings Save No Values Failure Dialog" onClick={openSettingsSaveNoValuesFailureDialog} />
              <Button
                text="Add Name And Port Service Failure Dialog"
                onClick={openAddNameAndPortServiceFailureDialog}
              />
              <Button text="Add Name And Service Failure Dialog" onClick={openAddNameAndServiceFailureDialog} />
              <Button text="Ruleset In Use Delete Confirm Dialog" onClick={openRulesetInUseDeleteConfirmDialog} />
              <Button text="Confirm Delete Rules Dialog" onClick={openConfirmDeleteRulesDialog} />
              <Button text="Confirm Delete Pairing Profiles Dialog" onClick={openConfirmDeletePairingProfilesDialog} />
              {/*<Button text='Ignore Selected Services Dialog' onClick={openIgnoreSelectedServicesDialog} />*/}
              <Button text="Invalid Label Dialog" onClick={openInvalidLabelDialog} />
              <Button text="Invalid Name And IP Range Dialog" onClick={openInvalidNameAndIpRangeDialog} />
              <Button text="Missing Ruleset Name Dialog" onClick={openMissingRulesetNameDialog} />
              <Button text="Disabled Ruleset Dialog" onClick={openDisabledRulesetsDialog} />
              <Button text="Ruleset Already Exists Dialog" onClick={openRulesetAlreadyExistsDialog} />
              <Button text="No Rulesets Selected Dialog" onClick={openNoRulesetsSelectedDialog} />
              <Button text="Unpair Unmanaged Workloads Dialog" onClick={openUnpairUnmanagedWorkloadsDialog} />
              {/*<Button text='Clear Traffic Dialog' onClick={openClearTrafficDialog} />*/}
            </ToolGroup>
          </ToolBar>
        </section>
        <section>
          <h2>Toolbar Right Justify</h2>
          <ToolBar justify="right">
            <ToolGroup>
              {intl('Components.ThreeSixtyWorkloads')}
              <Select options={selectData} selected="loc" onSelect={this.handleSelect} />
            </ToolGroup>
          </ToolBar>
        </section>
        <section>
          <div style={{width: 200}}>
            <h2>Select</h2>
            <Select options={selectData} selected="xxx" />
          </div>
        </section>
        <section>
          <h2>Radio Group</h2>
          <RadioGroup
            data={radioGroupData}
            value={this.state.radioSelect}
            format="vertical"
            onChange={this.handleRadio}
          />
        </section>
        <section>
          <h2>RouteLinks</h2>
          <RouteLink to="componentsold">components</RouteLink>
        </section>
        <section>
          <h2>Form Elements</h2>
          <Form>
            <Fieldset title={intl('Components.FieldsetTitle')}>
              <Field type="text" label={intl('Components.TextLabel')} />
              <Field type="textarea" label={intl('Components.TextareaLabel')} />
            </Fieldset>
          </Form>
        </section>
        <section className="ComponentsPage-iconStyles">
          <h2>Global Icon Styles</h2>
          <ToolBar>
            <ToolGroup>
              <div>
                Error: <Icon name="error" styleClass="Error" />
              </div>
              <div>
                Warning: <Icon name="warning" styleClass="Warning" />
              </div>
              <div>
                Success: <Icon name="success" styleClass="Success" />
              </div>
              <div>
                Remove: <Icon name="close" styleClass="Remove" />
              </div>
              <div>
                {intl('Common.Close')}: <Icon name="close" styleClass="Close" />
              </div>
              <div>
                Policy: <Icon name="enforced" styleClass="Policy" />
              </div>
              <div>
                Illuminated: <Icon name="illuminated" styleClass="Illuminated" />
              </div>
              <div>
                {intl('Common.Enforced')}: <Icon name="enforced" styleClass="Enforced" />
              </div>
              <div>
                Unmanaged: <Icon name="unmanaged" styleClass="Unmanaged" />
              </div>
              <div>
                Sorting: <Icon name="sort-up" styleClass="Sorting" />
                <Icon name="sort-down" styleClass="Sorting" />
              </div>
              <div>
                Communicator: <Icon name="error" styleClass="Communicator" />
              </div>
              <div>
                Expandable: <Icon name="caret-down" styleClass="Expandable" />
              </div>
              <div>
                Syncing: <Icon name="syncing" styleClass="Syncing" />
              </div>
              <div>
                {intl('Common.Online')}: <Icon name="online" styleClass="Online" />
              </div>
              <div>
                Offline: <Icon name="online" styleClass="Offline" />
              </div>
            </ToolGroup>
          </ToolBar>
        </section>
        <section>
          <h2>Notification</h2>
          <ToolBar>
            <ToolGroup>
              <Button text="Shrink Notification" onClick={this.handleNotificationShrink} />
              <Button text="Sidebar Notification" onClick={this.handleNotificationSidebar} />
            </ToolGroup>
          </ToolBar>
          <Notification
            type="instruction"
            title="Instruction"
            message={
              <span>
                Sample instructions<RouteLink to="componentsold">Link Text</RouteLink>
              </span>
            }
            button={<Button text="Button" />}
            closeable={true}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="warning"
            title={intl('Common.Warning')}
            message={
              <span>
                Sample warning<RouteLink to="componentsold">Link Text</RouteLink>
              </span>
            }
            button={<Button text="Button" />}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="error"
            title={intl('Common.Error')}
            message={
              <span>
                Sample error<RouteLink to="componentsold">Link Text</RouteLink>
              </span>
            }
            button={<Button text="Button" />}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="success"
            title={intl('Common.Success')}
            message={
              <span>
                Sample success<RouteLink to="componentsold">Link Text</RouteLink>
              </span>
            }
            button={<Button text="Button" />}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="instruction"
            title="Instructions"
            message="Sample wrapping with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line Sample wrapping with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line Sample wrapping with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line with links and wrap to multi-line"
            button={<Button text="Button" />}
            closeable={true}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="instruction"
            title="Instructions"
            message={
              <span>
                This is some sample instructions that is going to be rather long and wrap to a few lines. This will wrap
                to multiple lines and force the message to a new line rather than being on the same line as the title.
                <RouteLink to="componentsold">Link Text</RouteLink>
              </span>
            }
            button={<Button text="Button" />}
            closeable={true}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="instruction"
            message="Sample without a title"
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="instruction"
            icon="online"
            message="Sample with a custom icon"
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="instruction"
            icon="online"
            message="Sample with a custom icon and button"
            button={<Button text="Button" />}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="error"
            title="Sample notification with just a title and no message"
            button={<Button text="Button" />}
            closeable={true}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="error"
            title="Sample notification with just a title and no message"
            closeable={true}
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
          <Notification
            type="error"
            title="Sample notification with just a title and no message"
            shrink={this.state.notificationsShrink}
            sidebar={!this.state.notificationsSidebar}
          />
        </section>
        <section className="ComponentsPage-notificationStyles">
          <h2>Notification Group</h2>
          <div>
            <span>Inline elements</span>
            <NotificationGroup notifications={this.state.notifications} onChange={this.handleNotificationChange} />
            <span>Inline elements</span>
          </div>
          <ToolBar>
            <ToolGroup>
              <Button
                text="Reset to Load"
                disabled={!this.state.notificationType}
                onClick={() => {
                  this.setState({notifications: [defaultNotification]});
                }}
              />
              <Button
                text="Add Error"
                disabled={!this.state.notificationType}
                onClick={() => {
                  const newNotifications = update(this.state.notifications, {
                    $push: [
                      {
                        type: 'error',
                        title: 'Sample Error Message',
                        message: 'This is a sample error message',
                        closeable: true,
                      },
                    ],
                  });

                  this.setState({notifications: newNotifications});
                }}
              />
              <Button
                text="Add Warning"
                disabled={!this.state.notificationType}
                onClick={() => {
                  const newNotifications = update(this.state.notifications, {
                    $push: [
                      {
                        type: 'warning',
                        title: 'Sample Warning Message',
                        message: 'This is a sample warning message',
                        closeable: true,
                      },
                    ],
                  });

                  this.setState({notifications: newNotifications});
                }}
              />
              <Button
                text="Add Success"
                disabled={!this.state.notificationType}
                onClick={() => {
                  const newNotifications = update(this.state.notifications, {
                    $push: [
                      {
                        type: 'success',
                        title: 'Sample Success Message',
                        message: 'This is a sample success message',
                        closeable: true,
                      },
                    ],
                  });

                  this.setState({notifications: newNotifications});
                }}
              />
              <Button
                text="Clear All"
                disabled={!this.state.notificationType}
                onClick={() => {
                  this.setState({notifications: []});
                }}
              />
            </ToolGroup>
          </ToolBar>
        </section>
        <section title="Badge">
          <h2>Badge</h2>
          <ToolBar justify="left">
            <ToolGroup tid="tags">
              <Badge type="created" />
              <Badge type="updated" />
              <Badge type="deleted" />
              <Badge type="ok" />
              <Badge type="warning" />
              <Badge type="error" />
              <Badge type="disabled" />
              <Badge type="preview" />
              <Badge type="new" />
            </ToolGroup>
          </ToolBar>
        </section>
        <section className="ComponentsPage-iconStyles">
          <h2>Global Icon Styles</h2>
          <ToolBar>
            <ToolGroup>
              <div>
                {intl('Common.Error')}: <Icon name="error" styleClass="Error" />
              </div>
              <div>
                Warning: <Icon name="warning" styleClass="Warning" />
              </div>
              <div>
                Success: <Icon name="success" styleClass="Success" />
              </div>
              <div>
                Remove: <Icon name="close" styleClass="Remove" />
              </div>
              <div>
                {intl('Common.Close')}: <Icon name="close" styleClass="Close" />
              </div>
              <div>
                Policy: <Icon name="enforced" styleClass="Policy" />
              </div>
              <div>
                Illuminated: <Icon name="illuminated" styleClass="Illuminated" />
              </div>
              <div>
                {intl('Common.Enforced')}: <Icon name="enforced" styleClass="Enforced" />
              </div>
              <div>
                Unmanaged: <Icon name="unmanaged" styleClass="Unmanaged" />
              </div>
              <div>
                Sorting: <Icon name="sort-up" styleClass="Sorting" />
                <Icon name="sort-down" styleClass="Sorting" />
              </div>
              <div>
                Communicator: <Icon name="error" styleClass="Communicator" />
              </div>
              <div>
                Expandable: <Icon name="caret-down" styleClass="Expandable" />
              </div>
              <div>
                Syncing: <Icon name="syncing" styleClass="Syncing" />
              </div>
              <div>
                {intl('Common.Online')}: <Icon name="online" styleClass="Online" />
              </div>
              <div>
                Offline: <Icon name="online" styleClass="Offline" />
              </div>
              <div>
                Enabled: <Icon name="enabled" styleClass="Enabled" />
              </div>
              <div>
                Disabled: <Icon name="disabled" styleClass="Disabled" />
              </div>
              <div>
                Paired: <Icon name="pair" styleClass="Paired" />
              </div>
              <div>
                unPaired: <Icon name="unpair" styleClass="unPaired" />
              </div>
              <div>
                Deny: <Icon name="cancel" styleClass="Deny" />
              </div>
              <div>
                Suspended: <Icon name="suspended" styleClass="Suspended" />
              </div>
              <div>
                Stopped: <Icon name="stopped" styleClass="Stopped" />
              </div>
            </ToolGroup>
          </ToolBar>
        </section>
        <section>
          <h2>Icons</h2>
          <div style={{lineHeight: '20px'}}>
            <div>
              add <Icon name="add" position="after" />
            </div>
            <div>
              zoom-in <Icon name="zoom-in" position="after" />
            </div>
            <div>
              remove <Icon name="remove" position="after" />
            </div>
            <div>
              zoom-out <Icon name="zoom-out" position="after" />
            </div>
            <div>
              close <Icon name="close" position="after" />
            </div>
            <div>
              back <Icon name="back" position="after" />
            </div>
            <div>
              caret-left <Icon name="caret-left" position="after" />
            </div>
            <div>
              next <Icon name="next" position="after" />
            </div>
            <div>
              caret-right <Icon name="caret-right" position="after" />
            </div>
            <div>
              caret-up <Icon name="caret-up" position="after" />
            </div>
            <div>
              boundary-left <Icon name="boundary-left" position="after" />
            </div>
            <div>
              across-boundary-left <Icon name="across-boundary-left" position="after" />
            </div>
            <div>
              boundary-right <Icon name="boundary" position="after" />
            </div>
            <div>
              across-boundary-right <Icon name="across-boundary-right" position="after" />
            </div>
            <div>
              dropdown <Icon name="dropdown" position="after" />
            </div>
            <div>
              caret-down <Icon name="caret-down" position="after" />
            </div>
            <div>
              comment <Icon name="comment" position="after" />
            </div>
            <div>
              copy <Icon name="copy" position="after" />
            </div>
            <div>
              clear <Icon name="clear" position="after" />
            </div>
            <div>
              capslock <Icon name="capslock" position="after" />
            </div>
            <div>
              decrease <Icon name="decrease" position="after" />
            </div>
            <div>
              delete <Icon name="delete" position="after" />
            </div>
            <div>
              duplicate <Icon name="duplicate" position="after" />
            </div>
            <div>
              export <Icon name="export" position="after" />
            </div>
            <div>
              download <Icon name="download" position="after" />
            </div>
            <div>
              import <Icon name="import" position="after" />
            </div>
            <div>
              upload <Icon name="import" position="after" />
            </div>
            <div>
              edit <Icon name="edit" position="after" />
            </div>
            <div>
              email <Icon name="email" position="after" />
            </div>
            <div>
              help <Icon name="help" position="after" />
            </div>
            <div>
              env <Icon name="env" position="after" />
            </div>
            <div>
              key <Icon name="key" position="after" />
            </div>
            <div>
              version <Icon name="version" position="after" />
            </div>
            <div>
              illuminate <Icon name="illuminate" position="after" />
            </div>
            <div>
              illuminated <Icon name="illuminated" position="after" />
            </div>
            <div>
              internet <Icon name="internet" position="after" />
            </div>
            <div>
              info <Icon name="info" position="after" />
            </div>
            <div>
              iplist <Icon name="iplist" position="after" />
            </div>
            <div>
              allow-list <Icon name="allow-list" position="after" />
            </div>
            <div>
              deny-list <Icon name="deny-list" position="after" />
            </div>
            <div>
              loc <Icon name="loc" position="after" />
            </div>
            <div>
              map <Icon name="map" position="after" />
            </div>
            <div>
              menu <Icon name="menu" position="after" />
            </div>
            <div>
              name <Icon name="name" position="after" />
            </div>
            <div>
              navigate-up <Icon name="navigateup" position="after" />
            </div>
            <div>
              org <Icon name="org" position="after" />
            </div>
            <div>
              user <Icon name="user" position="after" />
            </div>
            <div>
              pause <Icon name="pause" position="after" />
            </div>
            <div>
              play <Icon name="play" position="after" />
            </div>
            <div>
              provision <Icon name="provision" position="after" />
            </div>
            <div>
              refresh <Icon name="refresh" position="after" />
            </div>
            <div>
              revert <Icon name="revert" position="after" />
            </div>
            <div>
              role <Icon name="role" position="after" />
            </div>
            <div>
              save <Icon name="save" position="after" />
            </div>
            <div>
              search <Icon name="search" position="after" />
            </div>
            <div>
              service <Icon name="service" position="after" />
            </div>
            <div>
              disabled <Icon name="disabled" position="after" />
            </div>
            <div>
              enabled <Icon name="enabled" position="after" />
            </div>
            <div>
              settings <Icon name="settings" position="after" />
            </div>
            <div>
              increase <Icon name="increase" position="after" />
            </div>
            <div>
              in-use <Icon name="in-use" position="after" />
            </div>
            <div>
              timezone <Icon name="timezone" position="after" />
            </div>
            <div>
              warning <Icon name="warning" position="after" />
            </div>
            <div>
              critical <Icon name="critical" position="after" />
            </div>
            <div>
              arrow-left <Icon name="arrow-left" position="after" />
            </div>
            <div>
              arrow-right <Icon name="arrow-right" position="after" />
            </div>
            <div>
              arrow-up <Icon name="arrow-up" position="after" />
            </div>
            <div>
              arrow-down <Icon name="arrow-down" position="after" />
            </div>
            <div>
              arrow-left-long <Icon name="arrow-left-long" position="after" />
            </div>
            <div>
              arrow-right-long <Icon name="arrow-right-long" position="after" />
            </div>
            <div>
              filter <Icon name="filter" position="after" />
            </div>
            <div>
              add-user <Icon name="add-user" position="after" />
            </div>
            <div>
              app <Icon name="app" position="after" />
            </div>
            <div>
              server <Icon name="server" position="after" />
            </div>
            <div>
              video <Icon name="video" position="after" />
            </div>
            <div>
              zoom-to-fit <Icon name="zoom-to-fit" position="after" />
            </div>
            <div>
              cancel <Icon name="cancel" position="after" />
            </div>
            <div>
              success <Icon name="success" position="after" />
            </div>
            <div>
              check <Icon name="check" position="after" />
            </div>
            <div>
              confirm <Icon name="confirm" position="after" />
            </div>
            <div>
              secure-connect <Icon name="secure-connect" position="after" />
            </div>
            <div>
              virtualService <Icon name="virtual-service" position="after" />
            </div>
            <div>
              location <Icon name="location" position="after" />
            </div>
            <div>
              sort-up <Icon name="sort-up" position="after" />
            </div>
            <div>
              sort-down <Icon name="sort-down" position="after" />
            </div>
            <div>
              enforcement <Icon name="enforcement" position="after" />
            </div>
            <div>
              enforce <Icon name="enforce" position="after" />
            </div>
            <div>
              enforced <Icon name="enforced" position="after" />
            </div>
            <div>
              unmanaged <Icon name="unmanaged" position="after" />
            </div>
            <div>
              error <Icon name="error" position="after" />
            </div>
            <div>
              service-running <Icon name="service-running" position="after" />
            </div>
            <div>
              service-not-running <Icon name="service-not-running" position="after" />
            </div>
            <div>
              online <Icon name="online" position="after" />
            </div>
            <div>
              offline <Icon name="offline" position="after" />
            </div>
            <div>
              syncing <Icon name="syncing" position="after" />
            </div>
            <div>
              list <Icon name="list" position="after" />
            </div>
            <div>
              container-workload <Icon name="container-workload" position="after" />
            </div>
            <div>
              workload <Icon name="workload" position="after" />
            </div>
            <div>
              third-party <Icon name="third-party" position="after" />
            </div>
            <div>
              pending <Icon name="pending" position="after" />
            </div>
            <div>
              expand <Icon name="expand" position="after" />
            </div>
            <div>
              MIA <Icon name="mia" position="after" />
            </div>
            <div>
              deny <Icon name="deny" position="after" />
            </div>
            <div>
              scope <Icon name="scope" position="after" />
            </div>
            <div>
              overflow <Icon name="overflow" position="after" />
            </div>
            <div>
              machine-auth <Icon name="machine-auth" position="after" />
            </div>
            <div>
              pin <Icon name="pin" position="after" />
            </div>
            <div>
              time-count <Icon name="time-count" position="after" />
            </div>
            <div>
              unlock <Icon name="unlock" position="after" />
            </div>
            <div>
              suspended <Icon name="suspended" position="after" />
            </div>
            <div>
              stopped <Icon name="stopped" position="after" />
            </div>
            <div>
              dashboard <Icon name="dashboard" position="after" />
            </div>
            <div>
              database <Icon name="database" position="after" />
            </div>
            <div>
              link <Icon name="link" position="after" />
            </div>
            <div>
              unlink <Icon name="unlink" position="after" />
            </div>
            <div>
              admin <Icon name="admin" position="after" />
            </div>
          </div>
        </section>
        <section className="ComponentsPage-iconStyles">
          <h2>Banner</h2>
          <ToolBar>
            <ToolGroup>
              <Banner
                type="ok"
                header="Lorem ipsum tos si amet conseteurt oi sutoes"
                message="Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet"
              />
              <Banner
                type="notice"
                header="Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet"
              />
              <Banner
                type="progress"
                header="Calculation in Progress"
                message="Lorem ipsum tos si amet conseteurt oi sutoes"
              />
              <Banner
                type="error"
                header="Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet conseteurt oi sutoes"
                message="Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet"
              />
              <Banner
                type="info"
                header="Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet conseteurt oi sutoes Lorem ipsum tos si amet"
              />
            </ToolGroup>
          </ToolBar>
        </section>
      </div>
    );
  },
});

function openVersionDialog() {
  actionCreators.openDialog(<ASPVersion />);
}

function openConfirmPolicyStateDialog() {
  const workloads = [{agent: {status: 'warn'}}, 2, 3];
  const item = {
    policyState: intl('Policy.State'),
    visibilityLevel: 'Visibility Level',
  };

  actionCreators.openDialog(
    <ConfirmPolicyState workloads={workloads} policyState={item.policyState} visibilityLevel={item.visibilityLevel} />,
  );
}

function openMapDataSyncDialog() {
  const timestamp = intl('Map.JustNow');

  actionCreators.openDialog(<MapDataSync timestamp={timestamp} />);
}

function openUserAddedDialog() {
  const email = 'foo@bar.com';
  const message = (
    <div className="Users-success-message">
      {intl('Users.UserAdded', {username: email, className: 'Users-success-item'}, {html: true})}
    </div>
  );

  actionCreators.openDialog(<Success message={message} chainAction={intl('Components.InviteAnotherUser')} />);
}

function openDifferentOrgInviteDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('Users.Invite.UserInDifferentOrg')} />);
}

function openUserAlreadyInOrgDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('Users.Invite.UserAlreadyInOrg')} />);
}

function openRoleChangeSuccessDialog() {
  const row = {
    username: intl('Common.Username'),
  };
  const message = (
    <div className="Users-success-message">
      {intl(
        'Users.RoleChangedSuccess',
        {username: row.username, rolename: 'Saved User Role', className: 'Users-success-item'},
        {html: true},
      )}
    </div>
  );

  actionCreators.openDialog(<Success message={message} />);
}

function openReinviteSuccessfulDialog() {
  const row = {
    username: 'user.name@blah.com',
  };
  const message = (
    <div className="Users-success-message">
      {intl('Users.Invite.Reinvited', {username: row.username, className: 'Users-success-item'}, {html: true})}
    </div>
  );

  actionCreators.openDialog(<Success message={message} />);
}

function openUserRemovedSuccessfulDialog() {
  const row = {
    username: 'user.name@blah.com',
  };
  const message = (
    <div className="Users-success-message">
      {intl('Users.UserRemovedFromOrg', {username: row.username, className: 'Users-success-item'}, {html: true})}
    </div>
  );

  actionCreators.openDialog(<Success message={message} />);
}

function openUnsavedChangesDialog() {
  actionCreators.openDialog(<ConfirmationDialog message={intl('Common.UnsavedChanges')} />);
}

function openNameValidationErrorDialog() {
  actionCreators.openDialog(
    <AlertDialog title={intl('Common.ValidationError')} message={intl('Common.ProvideName')} />,
  );
}

function openCompleteInviteWhileLoggedInDialog() {
  actionCreators.openDialog(
    <AlertDialog
      title={intl('Components.PleaseLogOut')}
      message={intl('Components.PleaseLogOutBeforeCompleteInvite')}
    />,
  );
}

function openFullNameMissingDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('Components.PleaseEnterFullName')} />);
}

function openTimeZoneMissingDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('Components.PleaseEnterTimeZone')} />);
}

function openTermsMustBeAcceptedDialog() {
  actionCreators.openDialog(<AlertDialog message="AcceptTerms" />);
}

function openConfirmLeavePageDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog title={intl('Common.UnsavedChanges')} message={intl('Components.ConfirmLeavePage')} />,
  );
}

function openTestEmailSentDialog() {
  const email = 'foo@bar.com';
  const message = (
    <div className="Users-success-message">
      {intl('SystemSettings.TestEmailSentSuccess', {email, className: 'Users-success-item'}, {html: true})}
    </div>
  );

  actionCreators.openDialog(<Success message={message} />);
}

function openTestEmailFailureDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('Components.TestEmailUnableToSend')} />);
}

function openConfirmDeleteServicesDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog title={intl('Services.DeleteSeveral')} message={intl('Services.DeleteSeveralConfirm')} />,
  );
}

function openConfirmDeleteServiceDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog title={intl('Services.Delete', {count: 1})} message={intl('Services.DeleteConfirm')} />,
  );
}

function openConfirmDiscardPendingChangesDialog() {
  actionCreators.openDialog(<ConfirmationDialog message={intl('Common.UnsavedPendingWarning')} />);
}

function openConfirmDeleteScopesDialog() {
  const messages = [];
  const hasScopeInApplication = true;

  if (hasScopeInApplication) {
    messages.push({
      type: 'warning',
      text: "One or more of the selected Scopes matches a Group that's part of this Ruleset.",
    });
  }

  messages.push('Are you sure you want to delete the selected Scopes?');
  actionCreators.openDialog(<ConfirmationDialog title={intl('Rulesets.Rules.DeleteScopes')} message={messages} />);
}

function openConfirmDeleteRulesetsDialog() {
  const messages = [];
  const selectedRulesetsInApplications = [];

  if (selectedRulesetsInApplications.length) {
    messages.push({
      type: 'warning',
      text: intl('Rulesets.PartOfApplication'),
    });
  }

  messages.push(intl('Rulesets.DeleteConfirm'));
  actionCreators.openDialog(<ConfirmationDialog title={intl('Rulesets.DeleteSeveral')} message={messages} />);
}

function openConfirmDeleteProfileDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog
      title={intl('PairingProfiles.Delete', {count: 1})}
      message={intl('PairingProfiles.DeleteConfirm', {count: 1})}
    />,
  );
}

function openDeleteFailedItemInUseDialog() {
  actionCreators.openDialog(
    <AlertDialog title={intl('Labels.InUse')} message={intl('Common.CannotRemoveItemInUse')} />,
  );
}

function openDeleteFailedLabelsInUseDialog() {
  const labelString = 'items';
  const usedLabelString = 'more items';
  const messages = [
    <div>
      <div>{intl('Common.CannotRemoveItemsInUse')}</div>
      <div>{`${intl('Common.AllowItemsRemove')} ${labelString}`}</div>
      <div>{`${intl('Common.ItemsCannotRemove')} ${usedLabelString}`}</div>
      <div>{intl('Common.OkToRemoveRemovable')}</div>
    </div>,
  ];

  actionCreators.openDialog(<ConfirmationDialog title={intl('Labels.InUse')} message={messages} />);
}

function openConfirmDeleteLabelsDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog
      title={intl('Labels.DeleteSeveral')}
      message={intl('Labels.List.DeleteConfirm', {count: 10})}
    />,
  );
}

function openConfirmDeleteLabelDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog title={intl('Labels.Delete')} message={intl('Labels.DeleteConfirm')} />,
  );
}

function openConfirmDeleteDialog() {
  actionCreators.openDialog(<ConfirmationDialog message={intl('Components.ConfirmDelete')} />);
}

function openConfirmDeleteIpListsDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog title={intl('IPLists.Delete', {count: 2})} message={intl('Components.ConfirmDeleteIPLists')} />,
  );
}

function openConfirmDeleteIpListDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog title={intl('IPLists.Delete', {count: 1})} message={intl('IPLists.DeleteConfirm')} />,
  );
}

function openMapRequestLimitDialog() {
  const error = {
    status: 429,
  };
  let title;
  let message;

  if (error.status === 429) {
    // If user has clicked update within the last 10 minutes
    title = intl('Map.RequestLimit');
    message = intl('Map.RequestLimitMessage', {minutes: 10});
  } else if (error.status === 403) {
    // If there are too many workloads in the app container
    title = intl('Map.Workloads.Limit');
    message = intl('Map.Workloads.LimitMessage');
  }

  actionCreators.openDialog(<AlertDialog title={title} message={message} />);
}

function openSessionTimeoutDialog() {
  actionCreators.openDialog(<AlertDialog title="Session Timeout" message={intl('Users.SessionTimeOut')} />);
}

function openNetworkSettingsApplySuccessDialog() {
  const message = (
    <div className="SystemSettings-network-message">
      {intl('SystemSettings.NetworkEdit.NetworkSettingsApplied')}
      <br />
      {intl('SystemSettings.NetworkEdit.LogBackIn')}
    </div>
  );

  actionCreators.openDialog(<Success message={message} confirm={intl('Common.LogIn')} />);
}

function openSettingsSaveNoValuesFailureDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('Settings.Edit.SelectValuesBeforeSaving')} />);
}

function openAddNameAndPortServiceFailureDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('Services.Edit.Message')} />);
}

function openAddNameAndServiceFailureDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('Services.AddValidNameAndService')} />);
}

function openRulesetInUseDeleteConfirmDialog() {
  const messages = [];
  const applicationsInRuleset = true;

  if (applicationsInRuleset) {
    messages.push({
      type: 'warning',
      text: 'This Ruleset is part of a Group.',
    });
  }

  messages.push('Are you sure you want to delete this Ruleset?');
  actionCreators.openDialog(<ConfirmationDialog title={intl('Rulesets.Delete', {count: 1})} message={messages} />);
}

function openConfirmDeleteRulesDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog
      title={intl('Rulesets.Rules.DeleteRules', {count: 2})}
      message={intl('Rulesets.Rules.ConfirmRuleDelete', {count: 2})}
    />,
  );
}

function openConfirmDeletePairingProfilesDialog() {
  actionCreators.openDialog(
    <ConfirmationDialog
      title={intl('PairingProfiles.Delete', {count: 1})}
      message={intl('PairingProfiles.DeleteConfirm', {count: 1})}
    />,
  );
}

function openInvalidLabelDialog() {
  actionCreators.openDialog(
    <AlertDialog title={intl('Labels.InvalidLabel')} message={intl('Labels.InvalidLabelCantSave')} />,
  );
}

function openInvalidNameAndIpRangeDialog() {
  actionCreators.openDialog(<AlertDialog message={intl('IPLists.InvalidMessage')} />);
}

function openMissingRulesetNameDialog() {
  actionCreators.openDialog(
    <AlertDialog title={intl('Common.ValidationError')} message={intl('Components.ProvideRulesetName')} />,
  );
}
function openDisabledRulesetsDialog() {
  actionCreators.openDialog(<PolicyGeneratorDisabledRulesetsDialog disabled={[]} deleted={[]} />);
}

function openRulesetAlreadyExistsDialog() {
  actionCreators.openDialog(
    <AlertDialog title={intl('Common.ValidationError')} message={intl('Components.RulesetNameExists')} />,
  );
}

function openNoRulesetsSelectedDialog() {
  actionCreators.openDialog(
    <AlertDialog title={intl('Common.ValidationError')} message={intl('Components.PleaseSelectRuleset')} />,
  );
}

function openUnpairUnmanagedWorkloadsDialog() {
  actionCreators.openDialog(
    <AlertDialog title={intl('Workloads.UnmanagedCantUnpair')} message={intl('Workloads.UnmanagedCantUnpairDesc')} />,
  );
}
