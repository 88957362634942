/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import GraphStore from '../../stores/GraphStore';
import RenderUtils from '../../utils/RenderUtils';
import actionCreators from '../../actions/actionCreators';
import Tooltip from '../Tooltip.jsx';
import {Icon} from '..';

const networkEntity = () => ({
  containerWorkload: intl('Common.ContainerWorkload'),
  workload: intl('Common.Workload'),
  multiSelect: 'Multiple Selection',
  virtualServer: intl('Common.VirtualServer'),
  virtualService: intl('Common.VirtualService'),
  role: intl('Common.Role'),
  location: intl('Common.Location'),
  group: intl('Common.Group'),
  internet: intl('Common.Internet'),
  ipList: intl('Common.IPLists'),
  fqdn: intl('Common.Domains'),
});

const iconShapes = {
  containerWorkload: 'hexagon',
  virtualService: 'diamond',
  virtualServer: 'diamond',
  workload: 'square',
  role: 'square',
  group: 'oval',
  internet: 'internet',
  ipList: 'iplist',
  fqdn: 'domain',
};

export default React.createClass({
  handleClose(evt, info) {
    evt.stopPropagation();

    const selections = GraphStore.getSelections();
    const allLinks = [];

    selections.forEach(s => {
      if (s.identifier !== info.href) {
        allLinks.push(s);
      }
    });
    actionCreators.updateComponentSelection(allLinks);
  },

  handleHighlightLink(info) {
    clearTimeout(this.props.hoverTimer);
    this.props.onHover(info);
  },

  handleUnhighlightLink(info) {
    clearTimeout(this.props.unhoverTimer);
    this.props.onUnhover(info);
  },

  createTraffic(data) {
    // we need tranffic link, so we only need identifier (in this case it's regarded as href from calculateTrafficPanel in InfoPanelUtil.js)
    const link = GraphStore.getLink(data.href, data.href);

    if (!link.identifier || link.type === 'notLoaded' || _.isEmpty(link.connections)) {
      return;
    }

    // If this is an internet link, then send over all the hrefs.
    // If there's no internetLinks attribute, then this must
    // be a regularly link between workloads, so it only has one href
    const source = link.source;
    const target = link.target;
    const clusterHref =
      source.cluster && target.cluster && source.cluster.href === target.cluster.href ? source.cluster.href : null;

    return {
      type: 'traffic',
      href: link.href,
      identifier: link.identifier,
      clusterHref,
    };
  },

  handleSelectLink(info) {
    const allSelections = [];

    this.props.data.forEach(l => {
      allSelections.push(this.createTraffic(l.info));
    });

    actionCreators.updateMultipleSelection(allSelections);

    const traffics = [this.createTraffic(info)];

    actionCreators.updateComponentSelection(traffics);
  },

  transformData(data) {
    const {info, type} = data;
    let labels;
    let targetName;
    let sourceName;

    if (type === 'group') {
      const {groups} = info;

      if (groups instanceof Object && groups[info.href]) {
        const {name} = groups[info.href];

        if (name) {
          labels = name;
        }
      }
    } else if (type === 'traffic') {
      targetName = info.targetName;
      sourceName = info.sourceName;

      labels = {
        sourceName,
        targetName,
      };
    }

    return labels;
  },

  renderTraffics(data) {
    const {info} = data;
    let {type} = data;

    const labels = this.transformData(data);
    let tooltipInfoData;
    let tooltipTrafficSourceName;
    let tooltipTrafficTargetName;
    let tooltipTrafficName;

    if (info.subType === 'container') {
      type = 'containerWorkload';
    }

    if (
      info &&
      info.name &&
      type !== 'internet' &&
      type !== 'ipList' &&
      type !== 'group' &&
      RenderUtils.truncateAppGroupName(info.name, 40, [40]).includes('...')
    ) {
      tooltipInfoData = info.name;
    } else if (type === 'traffic' && labels) {
      if (labels.sourceName && RenderUtils.truncateAppGroupName(labels.sourceName, 40, [20, 10, 10]).includes('...')) {
        tooltipTrafficSourceName = labels.sourceName;
      }

      if (labels.targetName && RenderUtils.truncateAppGroupName(labels.targetName, 40, [20, 10, 10]).includes('...')) {
        tooltipTrafficTargetName = labels.targetName;
      }
    } else if (
      type === 'group' &&
      !_.isEmpty(info.labels) &&
      labels &&
      RenderUtils.truncateAppGroupName(labels, 40, [20, 10, 10]).includes('...')
    ) {
      tooltipTrafficName = labels;
    }

    return (
      <div
        className="CommandPanelTitleBar CommandPanelMultiSelect"
        onMouseEnter={() => this.handleHighlightLink(info)}
        onMouseLeave={() => this.handleUnhighlightLink(info)}
        onClick={() => this.handleSelectLink(info)}
      >
        <div className="CommandPanelContentBar">
          <div className="CommandPanelTitle" data-tid={`title-bar-${type}`}>
            <div>
              {type === 'traffic'
                ? intl('Common.SourceToTarget', {
                    source: info.sources.linkType === 'ipList' ? 'IP LISTS' : info.sources.linkType,
                    target: info.targets.linkType === 'ipList' ? 'IP LISTS' : info.targets.linkType,
                  })
                : networkEntity()[type]}
            </div>
          </div>

          {type === 'group' ? (
            _.isEmpty(info.labels) ? (
              <div className="CommandPanelNoTooltipSubTitle CommandPanelTitle-Link">{intl('Map.DiscoveredGroup')}</div>
            ) : (
              <Tooltip content={tooltipTrafficName} width={372} position="group" location="bottomright">
                <div className="CommandPanelSubTitle">
                  <div
                    className={info.href ? 'CommandPanelTitle-Link' : 'CommandPanelTitle-Name'}
                    data-tid="nav-to-group-details"
                  >
                    {RenderUtils.truncateAppGroupName(labels, 40, [20, 10, 10])}
                  </div>
                </div>
              </Tooltip>
            )
          ) : info.name && type !== 'internet' && type !== 'ipList' ? (
            <Tooltip content={tooltipInfoData} width={372} position="group" location="bottomright">
              <div className="CommandPanelSubTitle">
                <div
                  className={
                    (type === 'workload' || type === 'containerWorkload' || type === 'virtualServer') && info.href
                      ? 'CommandPanelTitle-Link'
                      : 'CommandPanelTitle-Name'
                  }
                  onClick={this.handleExternalLink}
                  data-tid={
                    (type === 'workload' || type === 'containerWorkload' || type === 'virtualServer') && info.href
                      ? 'nav-to-workload-details'
                      : type
                  }
                >
                  {RenderUtils.truncateAppGroupName(info.name, 50, [50])}
                </div>
              </div>
            </Tooltip>
          ) : null}

          {type === 'traffic' ? (
            <div className="CommandPanelTraffic">
              <div className="CommandPanelArrowPane">
                {info.sources.linkType !== 'ipList' && info.sources.linkType !== 'internet' ? (
                  <div className={iconShapes[info.sources.linkType]} />
                ) : null}
                {info.sources.linkType === 'ipList' || info.sources.linkType === 'internet' ? (
                  <div className="Icon-position">
                    <Icon
                      name={iconShapes[info.sources.linkType]}
                      styleClass={info.sources.linkType.toLowerCase()}
                      position="inbetween"
                    />
                  </div>
                ) : null}
                {info.sourceName && info.targetName ? <Icon name="arrow-right" styleClass="arrowDown" /> : null}
                {info.targets.linkType !== 'ipList' ||
                info.targets.linkType !== 'internet' ||
                info.targets.linkType !== 'fqdn' ? (
                  <div className={iconShapes[info.targets.linkType]} />
                ) : null}
                {info.targets.linkType === 'ipList' ||
                info.targets.linkType === 'internet' ||
                info.targets.linkType === 'fqdn' ? (
                  <div>
                    <Icon
                      name={iconShapes[info.targets.linkType]}
                      styleClass={info.targets.linkType.toLowerCase()}
                      position="inbetween"
                    />
                  </div>
                ) : null}
              </div>
              <div className="CommandPanelContent">
                <Tooltip
                  content={tooltipTrafficSourceName}
                  position="multitraffic-top"
                  width={372}
                  location="bottomright"
                >
                  <div className="CommandPanelTopTraffic" data-tid="traffic-source">
                    {RenderUtils.truncateAppGroupName(labels.sourceName, 40, [20, 10, 10])}
                  </div>
                </Tooltip>
                <Tooltip
                  content={tooltipTrafficTargetName}
                  position="multitraffic-bottom"
                  width={372}
                  location="bottomright"
                >
                  <div className="CommandPanelBottomTraffic" data-tid="traffic-target">
                    {RenderUtils.truncateAppGroupName(labels.targetName, 40, [20, 10, 10])}
                  </div>
                </Tooltip>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className="CommandPanelTitleBarClose"
          onClick={evt => {
            this.handleClose(evt, info);
          }}
        >
          <Icon name="close" />
        </div>
      </div>
    );
  },

  render() {
    const allTraffics = this.props.data.map((traffic, index) => (
      <tr className="CommandPanelMultiSelect-Row" key={`MultiSelect-${index}`}>
        <td className="CommandPanelMultiSelect-NoPadding">{this.renderTraffics(traffic)}</td>
      </tr>
    ));

    return (
      <div>
        <table className="MapInfoPanel">
          <tbody>{allTraffics}</tbody>
        </table>
      </div>
    );
  },
});
