/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import Constants from '../constants';
import {createApiStore} from '../lib/store';

let networks = [];
let count = 0;

export default createApiStore(['NETWORKS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.NETWORKS_GET_COLLECTION_SUCCESS:
        networks = action.data;
        count = action.count;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getCount() {
    return count;
  },

  getAll() {
    return networks;
  },
});
