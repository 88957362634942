/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {Link} from 'react-router';
import {PairingProfileUtils} from '.';
import GridUtils from './GridDataUtils';
import Icon from '../components/Icon.jsx';
import Badge from '../components/Badge.jsx';
import SessionStore from '../stores/SessionStore';
import {getWorkloadStatusIntl} from '@illumio-shared/utils/intl/dynamic';

// Pre-defined policy states array used in select component
export const policyStates = [
  {label: intl('Common.Build'), value: 'building'},
  {label: intl('Common.Test'), value: 'testing'},
  {label: intl('Common.Enforced'), value: 'enforced'},
];

export const locations = [
  {key: 'us-east-1.amazonaws.com', name: 'Amazon EC2 (US East)', loc: 'N. Virginia, USA'},
  {key: 'us-west-1.amazonaws.com', name: 'Amazon EC2 (US West)', loc: 'N. California, USA'},
  {key: 'us-west-2.amazonaws.com', name: 'Amazon EC2 (US West)', loc: 'Oregon, USA'},
  {key: 'sa-east-1.amazonaws.com', name: 'Amazon EC2 (S. America)', loc: 'Sao Paulo, BRA'},
  {key: 'eu-west-1.amazonaws.com', name: 'Amazon EC2 (EU)', loc: 'Ireland, IRL'},
  {key: 'ap-southeast-1.amazonaws.com', name: 'Amazon EC2 (APAC)', loc: 'Singapore, SGP'},
  {key: 'ap-southeast-2.amazonaws.com', name: 'Amazon EC2 (APAC)', loc: 'Sydney, AUS'},
  {key: 'ap-northeast-1.amazonaws.com', name: 'Amazon EC2 (APAC)', loc: 'Tokyo, JPN'},

  {key: 'dfw1.rackspace.com', name: 'Rackspace (DFW)', loc: 'Grapevine, TX, USA'},
  {key: 'ord1.rackspace.com', name: 'Rackspace (ORD)', loc: 'Chicago, IL, USA'},
  {key: 'iad1.rackspace.com', name: 'Rackspace (IAD)', loc: 'Ashburn, VA, USA'},
  {key: 'syd1.rackspace.com', name: 'Rackspace (SYD)', loc: 'Sydney, AUS'},
  {key: 'syd2.rackspace.com', name: 'Rackspace (SYD)', loc: 'Sydney, AUS'},
  {key: 'lon1.rackspace.com', name: 'Rackspace (LON)', loc: 'Heathrow, London, UK'},
  {key: 'lon3.rackspace.com', name: 'Rackspace (LON)', loc: 'Slough, London, UK'},
  {key: 'hkg1.rackspace.com', name: 'Rackspace (HKG)', loc: 'Hong Kong, CHN'},

  {key: 'sfo.gogrid.com', name: 'GoGrid (US West-1)', loc: 'San Francisco, CA, USA'},
  {key: 'iad.gogrid.com', name: 'GoGrid (US East-1)', loc: 'Auburn, VA, USA'},
  {key: 'ams.gogrid.com', name: 'GoGrid (EU East-1)', loc: 'Amsterdam, NH, NLD'},

  {key: 'europe-west1-a.google.com', name: 'Google (EU West1-a)', loc: 'EU West'},
  {key: 'europe-west1-b.google.com', name: 'Google (EU West1-b)', loc: 'EU West'},
  {key: 'us-central1-a.google.com', name: 'Google (US Central1-a)', loc: 'US Central'},
  {key: 'us-central1-b.google.com', name: 'Google (US Central1-b)', loc: 'US Central'},
  {key: 'us-central2-a.google.com', name: 'Google (US Central2-a)', loc: 'US Central'},

  {key: 'europewest.cloudapp.net', name: 'Windows Azure (EU West)', loc: 'EU West'},
  {key: 'europenorth.cloudapp.net', name: 'Windows Azure (EU North)', loc: 'EU North'},
  {key: 'brazilsouth.cloudapp.net', name: 'Windows Azure (Brazil South)', loc: 'BRA South'},
  {key: 'usnorth.cloudapp.net', name: 'Windows Azure (US North)', loc: 'US North'},
  {key: 'useast.cloudapp.net', name: 'Windows Azure (US East)', loc: 'US East'},
  {key: 'useast2.cloudapp.net', name: 'Windows Azure (US East 2)', loc: 'US East 2'},
  {key: 'useast2euap.cloudapp.net', name: 'Windows Azure (US East 2 EUAP)', loc: 'US East 2 EUAP'},
  {key: 'uscentral.cloudapp.net', name: 'Windows Azure (US Central)', loc: 'US Central'},
  {key: 'uscentraleuap.cloudapp.net', name: 'Windows Azure (US Central EUAP)', loc: 'US Central EUAP'},
  {key: 'uswestcentral.cloudapp.net', name: 'Windows Azure (US West Central)', loc: 'US West Central'},
  {key: 'uswest.cloudapp.net', name: 'Windows Azure (US West)', loc: 'US West'},
  {key: 'uswest2.cloudapp.net', name: 'Windows Azure (US West 2)', loc: 'US West 2'},
  {key: 'ussouth.cloudapp.net', name: 'Windows Azure (US South)', loc: 'US South'},
  {key: 'asiasoutheast.cloudapp.net', name: 'Windows Azure (SE Asia)', loc: 'SE Asia'},
  {key: 'asiaeast.cloudapp.net', name: 'Windows Azure (East Asia)', loc: 'East Asia'},
  {key: 'japaneast.cloudapp.net', name: 'Windows Azure (JPN East)', loc: 'JPN East'},
  {key: 'japanwest.cloudapp.net', name: 'Windows Azure (JPN West)', loc: 'JPN West'},
  {key: 'koreaeast.cloudapp.net', name: 'Windows Azure (KOR East)', loc: 'KOR East'},
  {key: 'koreacentral.cloudapp.net', name: 'Windows Azure (KOR Central)', loc: 'KOR Central'},
  {key: 'australiaeast.cloudapp.net', name: 'Windows Azure (AUS East)', loc: 'AUS East'},
  {key: 'australiasoutheast.cloudapp.net', name: 'Windows Azure (AUS SE)', loc: 'AUS SE'},
  {key: 'indiacentral.cloudapp.net', name: 'Windows Azure (IN Central)', loc: 'IND Central'},
  {key: 'indiasouth.cloudapp.net', name: 'Windows Azure (IN South)', loc: 'IND South'},
  {key: 'indiawest.cloudapp.net', name: 'Windows Azure (IN West)', loc: 'IND West'},
  {key: 'canadaeast.cloudapp.net', name: 'Windows Azure (CAN East)', loc: 'CAN East'},
  {key: 'canadacentral.cloudapp.net', name: 'Windows Azure (CAN Central)', loc: 'CAN Central'},
  {key: 'uksouth.cloudapp.net', name: 'Windows Azure (UK South)', loc: 'UK South'},
  {key: 'ukwest.cloudapp.net', name: 'Windows Azure (UK West)', loc: 'UK West'},
];

// Only show compatibility report tab for idle workloads with 17.1-on VENs
export const showWorkloadReport = workload => {
  const {
    agent: {config, status},
  } = workload;

  // For certain Workloads (like Hawks), the "agent_version" might be null
  return config.mode === 'idle' && status && status.agent_version && parseFloat(status.agent_version, 10) >= 17.1;
};

export const isDeleted = workload => {
  if (workload.agent && workload.agent.status) {
    const status = Array.isArray(workload.agent.status.status)
      ? workload.agent.status.status
      : [workload.agent.status.status];

    return status.includes('uninstalling') || status.includes('deactivated_confirmed');
  }

  return false;
};

/**
 * Function to return whether a workload is
 * Unmanaged, Suspended, Offline or Online.
 *
 * @param row
 * @return {string}
 */
export const getWorkloadStatus = workload => {
  if (!workload.agent || !workload.agent.status) {
    return 'unmanaged';
  }

  if (!workload.online) {
    return 'offline';
  }

  return 'online';
};

const workloadHealthErrorMessage = error => {
  let message;

  switch (error) {
    case 'security_policy_refresh_failure':
      message = intl('Policy.ChangeFailed');
      break;
    case 'tampering_error':
      message = intl('Policy.TamperingRevertFailed');
      break;
    case 'secure_connect_error':
      message = intl('Policy.SecureConnectionFailed');
      break;
    case 'required_processes_not_running':
      message = intl('Policy.RequiredProcessesNotRunning');
      break;
    case 'suspended':
      message = intl('Policy.SuspendedMessage');
      break;
    default:
      message = intl('Error.Unknown');
  }

  return <div data-tid="workload-error-message"> {message} </div>;
};

export default {
  policyStates,
  locations,
  isDeleted,
  getWorkloadStatus,
  showWorkloadReport,

  // Get friendly name of workload based on several properties
  friendlyName(workload) {
    if (workload.name) {
      return workload.name;
    }

    if (workload.hostname) {
      return workload.hostname;
    }

    if (workload.agent && workload.agent.instance_id) {
      return workload.agent.instance_id;
    }

    return '';
  },

  // Returns an array of policy states for the policy state dropdown
  // FIXME: Remove `includeIdle` param and always show idle
  getPolicyStates(includeIdle) {
    if (includeIdle) {
      return [{label: intl('Common.Idle'), value: 'idle'}].concat(policyStates);
    }

    return policyStates;
  },

  // Pre-defined policy states array used in select component
  enforcedVisibilityLevels: [
    {label: intl('Map.FlowVisibility.HighDetail'), value: 'flow_summary'},
    {label: intl('Map.FlowVisibility.LowDetail'), value: 'flow_drops'},
    {label: intl('Map.FlowVisibility.NoDetail'), value: 'flow_off'},
  ],

  // Returns string key for the policyState
  policyState(workload) {
    if (!workload.agent || !workload.agent.status) {
      return 'unmanaged';
    }

    return workload.enforcement_mode;
  },

  // Gives data that can be saved to server based on policy state and visibility level values
  policyStateData(policyState, visibilityLevel) {
    const result = {};

    switch (policyState) {
      case 'idle':
        result.mode = 'idle';
        result.log_traffic = false;
        result.visibility_level = visibilityLevel || 'flow_summary';
        break;

      case 'building':
        result.mode = 'illuminated';
        result.log_traffic = false;
        result.visibility_level = visibilityLevel || 'flow_summary';
        break;

      case 'testing':
        result.mode = 'illuminated';
        result.log_traffic = true;
        result.visibility_level = visibilityLevel || 'flow_summary';
        break;

      case 'enforced':
        // In CS, default enforced policy state to high level.
        result.mode = 'enforced';
        result.log_traffic = visibilityLevel !== 'flow_off';
        result.visibility_level = visibilityLevel || 'flow_summary';

        break;
    }

    return result;
  },

  // Get content and icons based on policy state and visibility level
  policyStateContent(policyState, visibilityLevel) {
    const result = {};

    switch (policyState) {
      case 'idle':
        result.title = intl('Common.Idle');
        result.content = intl('Policy.IdleDesc');
        break;

      case 'build':
      case 'visibility_only':
        result.title = intl('Common.VisibilityOnly');
        result.content = intl('Policy.BuildDesc');
        // TODO: do we need to pass back custom or any visibility level message?
        break;

      case 'test':
      case 'selective':
        result.title = intl('Workloads.Selective');
        result.content = intl('Policy.TestDesc');
        // TODO: do we need to pass back custom or any visibility level message?
        break;

      case 'enforced':
      case 'full':
        result.title = intl('Workloads.Full');
        result.iconName = 'enforced';
        result.iconStyle = 'Enforced';

        // Don't show detail level messaging in CS
        switch (visibilityLevel) {
          case 'flow_summary':
            result.visibilityLevel = intl('Map.FlowVisibility.HighDetail');
            result.content = intl('Map.FlowVisibility.HighDetailDesc');

            break;

          case 'flow_drops':
            result.visibilityLevel = intl('Map.FlowVisibility.LowDetail');
            result.content = intl('Map.FlowVisibility.LowDetailDesc');

            break;

          case 'flow_off':
            result.visibilityLevel = intl('Map.FlowVisibility.NoDetail');
            result.content = intl('Map.FlowVisibility.NoDetailDesc');

            break;
        }

        break;

      default:
        result.title = intl('Common.NA');
        result.iconName = 'unmanaged';
        result.iconStyle = 'Unmanaged';
        result.content = intl('Policy.UnmanagedDesc');
        break;
    }

    return result;
  },

  filterWorkloadsByPolicyState(workloads, policyState) {
    let filteredWorkloads = workloads;

    switch (policyState) {
      case 'idle':
        filteredWorkloads = _.filter(workloads, item => item.enforcement_mode === 'idle');
        break;

      case 'visibility':
        filteredWorkloads = _.filter(
          workloads,
          item => item.enforcement_mode === 'visibility_only' && item?.agent?.status,
        );
        break;

      case 'selective':
        filteredWorkloads = _.filter(workloads, item => item.enforcement_mode === 'selective' && item?.agent?.status);
        break;

      case 'enforced':
        filteredWorkloads = _.filter(workloads, item => item.enforcement_mode === 'full' && item?.agent?.status);
        break;

      case 'unmanaged':
        filteredWorkloads = _.filter(workloads, item => !item.agent || !item.agent.status);
        break;

      case 'unknown':
        filteredWorkloads = _.filter(workloads, item => !item.enforcement_mode);
        break;
    }

    return filteredWorkloads;
  },

  filterContainerWorkloadsByPolicyState(workloads, policyState) {
    let filteredWorkloads = workloads;

    switch (policyState) {
      case 'visibility':
        filteredWorkloads = _.filter(workloads, item => item.enforcement_mode === 'visibility_only');
        break;

      case 'selective':
        filteredWorkloads = _.filter(workloads, item => item.enforcement_mode === 'selective');
        break;

      case 'enforced':
        filteredWorkloads = _.filter(workloads, item => item.enforcement_mode === 'full');
        break;

      case 'idle':
        filteredWorkloads = _.filter(workloads, item => item.enforcement_mode === 'idle');
        break;
    }

    return filteredWorkloads;
  },

  locationText(dataCenter, dataCenterZone, managed) {
    if (!dataCenter && !dataCenterZone) {
      if (managed) {
        return intl('Policy.UnknownDataCenterAndLocation');
      }

      return '';
    }

    const location = _.find(locations, location => dataCenter === location.key);

    if (location) {
      return `${location.name}, ${location.loc}`;
    }

    //Unmanaged workload
    if (dataCenter && !dataCenterZone && !managed) {
      return dataCenter;
    }

    if (!dataCenter) {
      return `${intl('Policy.UnnamedDataCenter')}, ${dataCenterZone}`;
    }

    if (!dataCenterZone) {
      return `${dataCenter}, ${intl('Policy.UnknownLocation')}`;
    }

    return `${dataCenter}, ${dataCenterZone}`;
  },

  getGroupWorkloads(labelHrefs, workloads) {
    let filteredWorkloads = [];

    if (workloads.length) {
      filteredWorkloads = _.filter(workloads, w => {
        if (!w.labels) {
          return;
        }

        // For discovered group case.
        if (!labelHrefs.length) {
          // For Only Role Labelled Workload
          if (w.labels[0]?.key === 'role' && w.labels.length === 1) {
            return true;
          }

          if (w.labels.length) {
            return false;
          }

          return true;
        }

        const wLabelHrefs = _.map(w.labels, 'href');
        const intersection = _.intersection(wLabelHrefs, labelHrefs);

        if (intersection.length !== labelHrefs.length) {
          return;
        }

        return true;
      });
    }

    return filteredWorkloads;
  },

  getWorkloadStatusIconText(value, row) {
    const status = getWorkloadStatus(row);

    return (
      <div>
        <Icon
          name={status === 'suspended' ? 'suspended-2' : status}
          styleClass={_.startCase(status)}
          position="before"
        />
        {status === 'unmanaged' ? intl('Workloads.Status.UnmanagedNotInstalled') : getWorkloadStatusIntl(status)}
      </div>
    );
  },

  getWorkloadStatusIcon(value, row) {
    const workloadStatus = getWorkloadStatus(row);
    const string = getWorkloadStatusIntl(workloadStatus);

    return (
      <div title={string}>
        <Icon name={workloadStatus} styleClass={_.startCase(workloadStatus)} />
      </div>
    );
  },

  /**
   * Function to return the uptime of a Workload if its owned by the PCE from
   * where the API request is made. Or show Unavailable if owned by another PCE.
   *
   * @param agent           Object of Workload agent
   * @param localData       Object of the local PCE health data.
   * @return {string}       Return the string of the Workload uptime
   */
  workloadUptime(agent, localData) {
    const workloadPce = agent.active_pce_fqdn;
    const fqdn = _.get(localData, 'fqdn');
    const pceType = _.get(localData, 'type');

    // If Health is disabled, it means that the PCE is on SaaS which is not Supercluster
    if (!SessionStore.isHealthEnabled() || fqdn === workloadPce || pceType === 'standalone') {
      return PairingProfileUtils.formatTimeLeft(agent.status.uptime_seconds, true, false);
    }

    return intl('Workloads.UnavailableViewableOn', {workloadPce});
  },

  /**
   * Function to return the last heartbeat of a Workload if its owned by the PCE from
   * where the API request is made. Or show Unavailable if owned by another PCE.
   *
   * @param agent           Object of Workload agent
   * @param localData       Object of the local PCE health data.
   * @return {string}       Return the string of the Workload uptime
   */
  lastHeartbeatOn(agent, localData) {
    const workloadPce = agent.active_pce_fqdn;
    const fqdn = _.get(localData, 'fqdn');
    const pceType = _.get(localData, 'type');

    // If Health is disabled, it means that the PCE is on SaaS which is not Supercluster
    if (!SessionStore.isHealthEnabled() || fqdn === workloadPce || pceType === 'standalone') {
      return GridUtils.formatDate(agent.status.last_heartbeat_on);
    }

    return intl('Workloads.UnavailableViewableOn', {workloadPce});
  },

  /**
   * Return the Health badge for a Workload agent
   * Show 'error' or 'warning' badge if that exists in the severity in agent_health
   * Show 'suspended' if workload is 'suspended'
   * SHow 'error' and 'suspended' if workload is suspended and also in error
   * SHow 'warning' and 'suspended' if workload is suspended and also in warning
   * Show 'unknown' if a Supercluster member PCE and the Workload is not owned by member PCE
   * Otherwise show the badge correspondent to the Workload Sync State ['applied', 'staged', 'syncing']
   *
   * @param agent           Object of Workload agent
   * @param options         Object of boolean values for custom return value
   * @param localData       Object of the local PCE health data.
   * @return {string|XML}   Return either a string or a React Element
   */
  workloadHealthTag(agent, options = {}, localData, online) {
    if (!agent || !agent.status || !agent.status.agent_health) {
      return null;
    }

    // Possible 'status' values are
    //    ['active', 'suspended', 'uninstalling', 'deactivated_confirmed', 'deactivated_unconfirmed']
    // Possible 'security_policy_sync_state' values are ['syncing', 'staged', 'applied']
    const {status: {status, security_policy_sync_state: securityPolicSyncState, agent_health} = {}} = agent;
    // labelOnly            = only show the text label of the Health state
    // badgeOnlyOnNotOk       = only show the Badge if the health is not ok (i.e. suspended or error)
    // withIcon             = show the corresponding health icon
    // hideAppliedIcon      = hide the icon if the health is "Up to date" (i.e. applied)
    // showTitleOnUnknown   = Show the title tooltip if 'Unknown' Policy Sync
    // isSuperclusterMember = If the PCE is a member PCE in a Supercluster
    // isSummary            = If the function is called from the Summary page
    const {
      labelOnly,
      badgeOnlyOnNotOk,
      withIcon,
      hideAppliedIcon,
      showTitleOnUnknown,
      isSuperclusterMember,
      isSummaryPage,
    } = options;
    let type;
    let label;
    let title;
    let iconName;

    // agent_health is an array of objects with required keys 'type' and 'severity'
    // we discard 'type' in the UI
    // severity can be either 'error' or 'warning'
    const severities = agent_health.map(({severity}) => severity);

    if (severities.length) {
      if (severities.includes('error')) {
        type = 'error';
        iconName = 'error';

        if (status === 'suspended') {
          label = intl('Workloads.Status.ErrorAndSuspended');
        } else {
          label = intl('Common.Error');
        }
      } else {
        type = 'warning';
        iconName = 'warning';

        if (status === 'suspended') {
          label = intl('Workloads.Status.WarningAndSuspended');
        } else {
          label = intl('Common.Warning');
        }
      }
    } else if (status === 'suspended') {
      type = 'error';
      label = intl('Workloads.Status.Suspended');
      iconName = 'suspended';
    } else if (online) {
      let workloadPce;
      let fqdn;
      let pceType;

      if (isSuperclusterMember) {
        // If Supercluster member PCE, set the workloadPce and the fqdn
        workloadPce = agent.active_pce_fqdn;

        if (localData) {
          fqdn = _.get(localData, 'fqdn');
          pceType = _.get(localData, 'type');
        }
      }

      if (fqdn === workloadPce || pceType === 'standalone' || pceType === 'leader') {
        // All managed workloads will have security_policy_sync_state
        type = securityPolicSyncState === 'applied' ? 'check' : securityPolicSyncState;

        if (securityPolicSyncState === 'syncing' && status === 'active') {
          // If the Workload is 'active' but also in 'syncing' state
          // This should not happen normally or for a long duration
          label = intl('Workloads.ActiveSyncing');
          iconName = securityPolicSyncState;
        } else {
          label = getWorkloadStatusIntl(securityPolicSyncState);
          iconName = securityPolicSyncState;
        }
      } else {
        // No value must be displayed for policy sync here.
        // Reaches when a member is viewing a workload attached to a leader.
        type = 'unknown';
        label = null;
      }
    } else {
      // Convert to this current format
      if (securityPolicSyncState === 'staged') {
        type = 'offline';
        label = getWorkloadStatusIntl(securityPolicSyncState);
      }

      return null;
    }

    // Used in the 'Policy Sync' column in the Workloads List page
    if (labelOnly && withIcon) {
      // If security_policy_sync_state is 'applied'
      if (hideAppliedIcon && iconName === 'applied') {
        return label;
      }

      return (
        <span className={isSummaryPage ? 'WorkloadHealthTag-Summary' : 'WorkloadHealthTag'}>
          {iconName ? (
            <Icon
              size="large"
              name={iconName === 'suspended' ? 'suspended-2' : iconName}
              styleClass={`WorkloadHealthTag-${iconName}`}
            />
          ) : null}
          <span className="WorkloadHealthTag-Text" title={showTitleOnUnknown ? title : ''}>
            {label}
          </span>
        </span>
      );
    }

    // Used in the list page and Workload Summary page
    if (labelOnly) {
      return label;
    }

    const badge = <Badge type={type}>{label}</Badge>;

    // The Name column in the List page only shows the badge if it is either 'warning' or 'error'
    if (badgeOnlyOnNotOk) {
      return type === 'ok' ? null : badge;
    }

    return badge;
  },
  /* eslint-enable camelcase */

  workloadHealthError(agent) {
    const messages = [];

    if (!agent || !agent.status) {
      return messages;
    }

    const healthErrors = agent.status.agent_health;

    if (healthErrors.length) {
      healthErrors.forEach(error => {
        if (error.audit_event) {
          const id = GridUtils.getIdFromHref(error.audit_event);

          messages.push(
            <div data-tid="workload-error-message-link">
              <Link to="events.detail" className="Grid-link" params={{id}}>
                {workloadHealthErrorMessage(error.type)}
              </Link>
            </div>,
          );
        } else {
          messages.push(workloadHealthErrorMessage(error.type));
        }
      });
    }

    if (agent.status.status === 'suspended') {
      messages.push(workloadHealthErrorMessage('suspended'));
    }

    return messages;
  },

  networkInterfaceToString(value) {
    if (!_.isPlainObject(value)) {
      return value;
    }

    return _.compact([
      `${value.name}: `,
      value.address,
      value.default_gateway_address ? ` ${value.default_gateway_address}` : null,
    ]).join('');
  },

  getInterfaceInstructions() {
    return (
      <div className="Workload-interface-instructions" data-tid="page-interface-instructions">
        <div className="Workload-interface-instructions-item">{intl('Common.Formats')}</div>
        <div className="Workload-interface-instructions-example">
          &lt;interface&gt;: &lt;ip address&gt;
          <br />
          &lt;interface&gt;: &lt;ip address&gt;/&lt;cidr&gt;
          <br />
          &lt;interface&gt;: &lt;ip address&gt; &lt;gateway&gt;
          <br />
          <br />
        </div>
        <div className="Workload-interface-instructions-item">{intl('Common.Examples')}</div>
        <div className="Workload-interface-instructions-example">
          eth0: 10.23.2.7
          <br />
          eth1: 10.0.10.1/24
          <br />
          wlan0: 24.1.4.6/24 24.1.4.1
        </div>
      </div>
    );
  },

  getContainerWorkloadStatusIntl(type) {
    return {
      none: intl('Common.NA'),
      online: intl('Common.Online'),
      offline: intl('Workloads.Status.Offline'),
      out_of_sync: intl('Workloads.Status.Syncing'),
      suspended: intl('Workloads.Status.Suspended'),
      syncing: intl('Workloads.Status.Syncing'),
      unmanaged: intl('Workloads.Status.NotInstalled'),
      uninstalling: intl('Workloads.Status.Uninstalling'),
      applied: intl('Common.Active'),
      staged: intl('Common.Staged'),
    }[type];
  },

  getMachineAuthenticationInstructions() {
    return (
      <div className="Workload-pki-instructions" data-tid="page-pki-instructions">
        <div className="Workload-pki-instructions-item">{intl('Workloads.MachineAuthenticationExample')}</div>
        <div className="Workload-pki-instructions-item">
          {intl('Workloads.VenToPCEAuthentication.PKICertificateIdExampleWithDN')}
          <div className="Workload-pki-instructions-example">
            C=US, ST=California, L=San Francisco, O=Example, CN=workload1.example.net
          </div>
        </div>
      </div>
    );
  },

  getPkiInstructions() {
    return (
      <div className="Workload-pki-instructions" data-tid="page-pki-instructions">
        <div className="Workload-pki-instructions-item">
          {intl('Workloads.VenToPCEAuthentication.PKICertificateIdExamples')}
        </div>
        <div className="Workload-pki-instructions-item">
          {intl('Workloads.VenToPCEAuthentication.PKICertificateIdExampleWithDN')}
          <div className="Workload-pki-instructions-example">
            C=US, ST=California, L=San Francisco, O=Example, CN=workload1.example.net
          </div>
          <div className="Workload-pki-instructions-example">
            /C=US/ST=California/L=San Francisco/O=Example/CN=workload1.example.net/
          </div>
        </div>
        <div className="Workload-pki-instructions-item">
          {intl('Workloads.VenToPCEAuthentication.PKICertificateIdExampleWithSAN')}
          <div className="Workload-pki-instructions-example">DNS:workload1.example.net</div>
        </div>
      </div>
    );
  },

  getSelectedLabels(labels) {
    const result = [];

    if (labels) {
      for (const [key, value] of Object.entries(labels)) {
        if (key && value) {
          const {href} = value;

          if (href && !href.includes('exists')) {
            result.push(href.includes('label_groups') ? {label_group: {href}} : {label: {href}});
          }
        }
      }
    }

    return result;
  },

  /**
   * Returns a string representation of a list, truncated to visibleCount items
   * @param {string[]} list
   * @param {number} visibleCount count of items to display (0 to display all)
   * @returns {string}
   */
  toStringTruncated(list, visibleCount = 0) {
    return list
      .filter((_, index) => visibleCount === 0 || index < visibleCount)
      .join(', ')
      .concat(
        visibleCount > 0 && list.length > visibleCount
          ? `, ${intl('Common.CountMore', {count: list.length - visibleCount})}`
          : '',
      );
  },
};
