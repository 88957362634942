/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';

let venOS = [];
let isLoaded = false;

function setVENOS(data) {
  venOS = data;
}

export default createApiStore(['WORKLOAD_OS_FAMILIES'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.WORKLOAD_OS_FAMILIES_GET_COLLECTION_SUCCESS:
        setVENOS(action.data);
        isLoaded = true;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },
  getAll() {
    return venOS;
  },
  getSpecified(href) {
    return StoreUtils.findObjectByHref(href, venOS);
  },
  isLoaded() {
    return isLoaded;
  },
});
