/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import actionCreators from '../actions/actionCreators';
import {AlertDialog} from '../components';
import {FormComponentsForm} from '../components/Forms';

export default React.createClass({
  handleFormSubmit() {
    actionCreators.openDialog(<AlertDialog message="Form Submitted" />);
  },

  handleFormCancel() {
    actionCreators.openDialog(<AlertDialog message="Form Canceled" />);
  },

  render() {
    return <FormComponentsForm model={{}} onSubmit={this.handleFormSubmit} onCancel={this.handleFormCancel} />;
  },
});
