/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import _ from 'lodash';
import {Icon, Tooltip} from '..';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

CoverageProgressBar.propTypes = {
  connections: PropTypes.number,
  connectionRules: PropTypes.number,
  ruleCoverage: PropTypes.number,
  excluded: PropTypes.number,
  percent: PropTypes.number,
  iplist: PropTypes.bool,
  totals: PropTypes.object,
  closed: PropTypes.object,
  direction: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};

export default function CoverageProgressBar(props) {
  const {ruleCoverage, excluded, percent, connections, connectionRules, type, totals, closed} = props;
  const existingWidth = connectionRules / connections;
  const newWidth = 1 - existingWidth;

  const counterClasses = cx({
    'AppGroupCoverage-card-info-count-cText': props.direction === 'vertical',
    'AppGroupCoverage-info-count-bar-counts': props.direction === 'horizontal',
  });
  const counterRowClasses = props.direction === 'vertical' ? 'AppGroupCoverage-card-info-count-cText-row' : '';

  const counters = (
    <div className={counterClasses}>
      <div className={counterRowClasses}>
        <div className="AppGroupCoverage-card-info-count-cText-row-cell AppGroupCoverage-card-info-count-cText-row-cell--existing">
          {connectionRules}
        </div>
        <div className="AppGroupCoverage-card-info-count-cText-row-cell">
          {intl('PolicyGenerator.ExistingRules', {count: connectionRules})}
        </div>
      </div>
      <div className="AppGroupCoverage-card-info-count-cText-row">
        <div className="AppGroupCoverage-card-info-count-cText-row-cell AppGroupCoverage-card-info-count-cText-row-cell--included">
          {ruleCoverage}
        </div>
        <div className="AppGroupCoverage-card-info-count-cText-row-cell">
          {intl('PolicyGenerator.IncludedConnections', {count: ruleCoverage})}
        </div>
      </div>
      <div className="AppGroupCoverage-card-info-count-cText-row">
        <div className="AppGroupCoverage-card-info-count-cText-row-cell AppGroupCoverage-card-info-count-cText-row-cell--excluded">
          {excluded}
        </div>
        <div className="AppGroupCoverage-card-info-count-cText-row-cell">
          {intl('PolicyGenerator.ExcludedConnections', {count: excluded})}
        </div>
      </div>
    </div>
  );

  const classes = cx({
    'AppGroupCoverage-info-count-bar': props.direction === 'horizontal',
  });
  const subClasses = cx({
    'AppGroupCoverage-info-count-bar-bar': props.direction === 'horizontal',
  });

  let vulnerabilityTitle;

  switch (type) {
    case 'intra':
      vulnerabilityTitle = intl('Vulnerability.IntraScopeMitigation');
      break;
    case 'extra':
      vulnerabilityTitle = intl('Vulnerability.ExtraScopeMitigation');
      break;
    case 'ipList':
      vulnerabilityTitle = intl('Vulnerability.InternetMitigation');
  }

  return (
    <div className={classes}>
      {props.direction === 'horizontal' && counters}
      {props.direction === 'vertical' && (
        <div className={subClasses}>
          <div
            className="AppGroupCoverage-card-info-count-bar AppGroupCoverage-card-info-count-bar--existing"
            style={{width: `${existingWidth * 100}%`}}
          >
            <div className="AppGroupCoverage-card-info-count-bar-fill">
              <ReactCSSTransitionGroup
                transitionName="AppGroupCoverage-card-info-count-bar-fill-value"
                transitionAppear={true}
                transitionAppearTimeout={5000}
                transitionEnterTimeout={0}
                transitionLeaveTimeout={0}
              >
                <div className="AppGroupCoverage-card-info-count-bar-fill-value" />
              </ReactCSSTransitionGroup>
            </div>
          </div>
          <div className="AppGroupCoverage-card-info-count-bar" style={{width: `${newWidth * 100}%`}}>
            <div className="AppGroupCoverage-card-info-count-bar-fill" style={{width: `${percent * 100}%`}}>
              <ReactCSSTransitionGroup
                transitionName="AppGroupCoverage-card-info-count-bar-fill-value"
                transitionAppear={true}
                transitionAppearTimeout={5000}
                transitionEnterTimeout={0}
                transitionLeaveTimeout={0}
              >
                <div className="AppGroupCoverage-card-info-count-bar-fill-value" />
              </ReactCSSTransitionGroup>
            </div>
          </div>
        </div>
      )}
      {props.direction === 'vertical' && counters}
      {totals && closed ? (
        <div className="AppGroupCoverage-card-info-vulnerability">
          {vulnerabilityTitle}
          <div className="AppGroupCoverage-card-info-vulnerabilities">
            <span className="AppGroupCoverage-card-info-vulnerabilities-type">
              {type === 'ipList' ? <Icon name="internet" size="large" /> : intl('Vulnerability.Reduced')}
            </span>
            <div className="AppGroupCoverage-card-info-vulnerability-row">
              {_.reduce(
                totals,
                (result, total, key) => {
                  result.push(total - closed[key]);

                  return result;
                },
                [],
              ).map((total, index) => (
                <Tooltip
                  content={intl('Vulnerability.BoxInfo', {state: 'open', type, severity: index, count: total})}
                  location="topleft"
                >
                  <div
                    className={`AppGroupCoverage-card-info-vulnerability-count AppGroupCoverage-card-info-vulnerability-count-${
                      total ? index + 1 : 0
                    }`}
                  >
                    {total}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
          <div className="AppGroupCoverage-card-info-vulnerabilities">
            <span className="AppGroupCoverage-card-info-vulnerabilities-type">{intl('Vulnerability.Eliminated')}</span>
            <div className="AppGroupCoverage-card-info-vulnerability-row">
              {Object.values(closed).map((closed, index) => (
                <Tooltip
                  content={intl('Vulnerability.BoxInfo', {state: 'closed', type, severity: index, count: closed})}
                  location="topleft"
                >
                  <div
                    className={`AppGroupCoverage-card-info-vulnerability-count AppGroupCoverage-card-info-vulnerability-count-${
                      closed ? index + 1 : 0
                    }`}
                  >
                    {closed}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
