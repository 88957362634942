/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import update from 'react-addons-update';
import ComponentUtils from '../utils/ComponentUtils';
import Notification from './Notification.jsx';

const defaultTid = 'comp-notificationgroup';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    displayStyle: PropTypes.string,
    notifications: PropTypes.array,
    onChange: PropTypes.func,
  },

  getDefaultProps() {
    return {
      onChange: _.noop,
    };
  },

  handleClose(idx) {
    const newNotifications = update(this.props.notifications, {$splice: [[idx, 1]]});

    this.props.onChange(newNotifications);
  },

  render() {
    if (!this.props.notifications.length) {
      return null;
    }

    const classes = cx('NotificationGroup', {
      'NotificationGroup--shaded': this.props.displayStyle === 'shaded',
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    const notifications = _.map(this.props.notifications, (notification, idx) => {
      let notificationOutput;

      if (notification.onClose) {
        notificationOutput = <Notification {...notification} />;
      } else {
        notificationOutput = (
          <Notification
            {...notification}
            onClose={() => {
              this.handleClose(idx);
            }}
          />
        );
      }

      return (
        <div className="NotificationGroup-notification" key={idx}>
          {notificationOutput}
        </div>
      );
    });

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
        {notifications}
      </div>
    );
  },
});
