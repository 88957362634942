/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';

let anyIpList = null;
const ipLists = {};
let count = {};
const isLoaded = {};

function setIpLists(data, version) {
  StoreUtils.setObjects(ipLists, isLoaded, data, version || 'draft');
}

function setIpList(data, version) {
  StoreUtils.setObjectByVersion(data, version || 'draft', ipLists);

  if (version === 'active') {
    const pversion = StoreUtils.getPolicyVersionFromHref(data.href);

    StoreUtils.setObjectByVersion(data, pversion || 'draft', ipLists);
  }
}

function setCount(data) {
  count = data;
}

export default createApiStore(['IP_LISTS_', 'IP_LIST_'], {
  dispatchHandler(action) {
    let version = 'draft';

    if (action.options && action.options.params) {
      version = action.options.params.pversion || 'draft';
    }

    switch (action.type) {
      case Constants.IP_LISTS_GET_COLLECTION_SUCCESS:
        setIpLists(action.data, version);
        setCount(action.count);
        break;

      case Constants.IP_LISTS_GET_INSTANCE_SUCCESS:
        if (action.options.params.ip_list_id === 'any') {
          anyIpList = action.data;
        } else {
          setIpList(action.data, version);
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll(version) {
    return ipLists[version || 'draft'] || [];
  },

  getCount() {
    return count;
  },

  getSpecified(href) {
    return StoreUtils.findObjectByHref(href, ipLists);
  },

  isLoaded(version) {
    return isLoaded[version || 'draft'] || false;
  },

  getAnyIpList() {
    return anyIpList;
  },
});
