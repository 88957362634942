/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import RestApiUtils from './RestApiUtils';
import ProvisionStore from '../stores/ProvisionStore';
import actionCreators from '../actions/actionCreators';

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));
let pendingOverride = false;
let pendingOverrideCount = 0;

const ProvisionUtils = {};

const isProvisionInProgress = async function () {
  const provisionResponse = await RestApiUtils.provision.inProgress();
  const isPending =
    _.get(provisionResponse, 'body.red_agents.length') > 0 && !_.get(provisionResponse, 'body.red_agents_timed_out');

  if (ProvisionUtils.getPendingOverride()) {
    // If a Pending Override is set for Supercluster setups
    if (isPending) {
      // If the red_agents has become non empty, start relying on that and
      // revert the override
      ProvisionUtils.setPendingOverride(false);
    }

    return true;
  }

  return isPending;
};

export default Object.assign(ProvisionUtils, {
  getPendingOverride() {
    return pendingOverride;
  },

  setPendingOverride(value) {
    pendingOverride = value;
  },

  getPendingOverrideCount() {
    return pendingOverrideCount;
  },

  setPendingOverrideCount(value) {
    pendingOverrideCount = value;
  },

  async provisionCheckPending() {
    // Set pending provisioning flag in ProvisionStore, if only it's not yet pending and first request returns true
    if (ProvisionStore.getProvisionStatus() !== 'pending' && (await isProvisionInProgress())) {
      actionCreators.provisionPending();

      // And check that it's still pending every 2sec until api returns false
      do {
        await timeout(2000);
      } while (await isProvisionInProgress());

      // Reset pending provisioning flag in ProvisionStore
      actionCreators.provisionDone();
    }
  },

  provisionCountsTotal(counts) {
    return _.values(counts).reduce((prev, cur) => prev + cur, 0);
  },

  getProvisionMenuCounts(item = {}) {
    return Object.entries(item).reduce((result, [objType, items]) => {
      if (Array.isArray(items)) {
        result[objType] = items.filter(item => item.caps).length;
      } else if (objType === 'firewall_settings') {
        if (items.caps && items.caps.includes('provision')) {
          result[objType] = 1;
        }
      }

      return result;
    }, {});
  },
});
