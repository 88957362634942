/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import {Navigation, State} from 'react-router';
import Icon from './Icon.jsx';
import actionCreators from '../actions/actionCreators';
import {Menu, MenuDelimiter, MenuItem} from './Menu';
import {StoreMixin, UserMixin} from '../mixins';
import MapPageStore from '../stores/MapPageStore';
import SessionStore from '../stores/SessionStore';
import TrafficStore from '../stores/TrafficStore';
import UserStore from '../stores/UserStore';
import {ASPVersion} from '../modals';
import {isAPIAvailable} from '../lib/api';

function getStateFromStores() {
  return {
    healthEnabled: SessionStore.isHealthEnabled(),
    explorerEnabled: SessionStore.isExplorerEnabled(),
    trafficEnabled: SessionStore.isTrafficEnabled(),
    venLibraryEnabled: SessionStore.isVenLibraryEnabled(),
    vulnerabilitiesEnabled: SessionStore.areVulnerabilitiesEnabled(),
    networkEnforcementNodeEnabled: SessionStore.isNetworkEnforcementNodeEnabled(),
    illuminationMapIsEnabled: SessionStore.isIlluminationMapEnabled(),
    illuminationApiIsEnabled: SessionStore.isIlluminationApiEnabled(),
    infrastructureIsEnabled: SessionStore.isInfrastructureEnabled(),
    eventsIsEnabled: SessionStore.isEventsEnabled(),
    segmentationTemplatesIsEnabled: SessionStore.isSegmentationTemplatesEnabled(),
    settingsIsEnabled: SessionStore.isSettingsEnabled(),
    pairingProfilesIsEnabled: SessionStore.isPairingProfilesEnabled(),
    userIsWithReducedScope: SessionStore.isUserWithReducedScope(),
    loadBalancerEnabled: SessionStore.isLoadBalancerEnabled(),
    ransomwareReadinessEnabled: SessionStore.isRansomwareReadinessEnabled(),
    reportingEnabled: SessionStore.isReportingEnabled(),
    coreServicesEnabled: SessionStore.isCoreServicesEnabled(),
    coreServicesSettingsEnabled: SessionStore.isCoreServicesSettingsEnabled(),
    labelSettingsPageEnabled: SessionStore.isLabelSettingsPageEnabled(),
    isAppGroupMapEnabled: TrafficStore.isAppGroupMapEnabled(),
  };
}

const tid = 'comp-navbar-';

export default React.createClass({
  propTypes: {
    type: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  },

  mixins: [UserMixin, State, Navigation, StoreMixin(SessionStore, getStateFromStores)],

  getRBACMenuItems() {
    return (
      this.isUserOwner() && (
        <MenuItem text={intl('RBAC.AccessManagement')} data-tid={`${tid}sub-rbac`}>
          {!__ANTMAN__ && (
            <MenuItem
              link="rbac.roles.global.list"
              text={intl('RBAC.GlobalRoles')}
              data-tid={`${tid}pages-rbac-global`}
            />
          )}
          {!__ANTMAN__ && (
            <MenuItem link="rbac.roles.scope.list" text={intl('Common.Scopes')} data-tid={`${tid}pages-rbac-scope`} />
          )}
          {!__ANTMAN__ && <MenuDelimiter />}
          <MenuItem
            link="rbac.users.external.groups.list"
            text={intl('Users.ExternalGroups')}
            data-tid={`${tid}pages-groups`}
          />
          <MenuItem
            link="rbac.users.external.list"
            text={intl('Users.ExternalUsers')}
            data-tid={`${tid}pages-users-external`}
          />
          <MenuItem link="rbac.users.local.list" text={intl('Users.LocalUsers')} data-tid={`${tid}pages-users`} />

          <MenuItem
            link="rbac.serviceAccounts.list"
            text={intl('Common.ServiceAccounts')}
            data-tid={`${tid}pages-serviceaccount`}
          />

          <MenuDelimiter />
          <MenuItem
            link="rbac.users.activity.list"
            text={intl('RBAC.UsersActivity.UserActivity')}
            data-tid={`${tid}useractivity`}
          />
          <MenuDelimiter />
          {this.isUserOwner() && (
            <MenuItem
              link="authenticationSettings.main"
              text={intl('Common.Authentication')}
              data-tid={`${tid}pages-authentication-settings`}
            />
          )}
          {!__ANTMAN__ && (
            <MenuItem
              link="accessRestrictions.list"
              text={intl('AccessRestriction.AccessRestrictionTitle')}
              data-tid={`${tid}pages-access-restriction`}
            />
          )}
        </MenuItem>
      )
    );
  },

  handleMapSelect() {
    const mapRoute = MapPageStore.getMapRoute('loc');

    if (_.isEmpty(mapRoute)) {
      this.transitionTo('map');
    } else if (!_.isEmpty(mapRoute.prevtype)) {
      this.transitionTo('prevMapLevel', mapRoute);
    } else if (!_.isEmpty(mapRoute.type)) {
      this.transitionTo('mapLevel', mapRoute);
    }
  },

  handleAppMapSelect() {
    const mapRoute = MapPageStore.getMapRoute('app');
    const appMapType = sessionStorage.getItem('recent_app_map_type');
    const recentIlluminationPlusId = sessionStorage.getItem('recent_app_map_id');

    if (!appMapType || appMapType === 'illuminationPlus') {
      if (recentIlluminationPlusId) {
        this.transitionTo('appGroupIllumination', {id: JSON.parse(recentIlluminationPlusId)});
      } else {
        this.transitionTo('appMap');
      }

      return;
    }

    sessionStorage.setItem('app_group_list', 'recents');

    if (_.isEmpty(mapRoute)) {
      this.transitionTo('appMap');
    } else if (!_.isEmpty(mapRoute.prevtype)) {
      this.transitionTo('prevAppMapLevel', mapRoute);
    } else if (!_.isEmpty(mapRoute.type)) {
      this.transitionTo('appMapLevel', mapRoute);
    }
  },

  handleVulnerabilityMapSelect() {
    localStorage.setItem('vulnerability_map', 'true');
    this.handleAppMapSelect('vulnerability');
  },

  handleOpenVersionDialog() {
    actionCreators.openDialog(<ASPVersion />);
  },

  // Pass content as function to prevent creating react elements at the beginning, create only when dropdown is open
  // New tags should exist for 3 months after their release
  // Preview tags should exist as long as the feature is in preview mode
  renderContent() {
    const route = this.getRoutes()[1];
    const {
      illuminationApiIsEnabled,
      illuminationMapIsEnabled,
      labelSettingsPageEnabled,
      trafficEnabled,
      explorerEnabled,
      ransomwareReadinessEnabled,
      reportingEnabled,
      isAppGroupMapEnabled,
    } = this.state;

    const enforcementBoundaryEnabled = SessionStore.canUserViewEnforcementBoundaries();
    const illuminationClassicSettings = UserStore.getDefaultIllumination();
    const isVensStatisticsAvailable = isAPIAvailable('vens.statistics');
    const isReportsTimeSeriesStatisticsAvailable =
      ransomwareReadinessEnabled && isAPIAvailable('reports.time_series_statistics');

    const menu = [
      (__ANTMAN__ || __TARGET__ === 'core') &&
        (isVensStatisticsAvailable || isReportsTimeSeriesStatisticsAvailable) && (
          <MenuItem text={intl('Common.Dashboard')} data-tid={`${tid}sub-dashboard`}>
            {isVensStatisticsAvailable && (
              <MenuItem
                link="dashboard"
                isActive={route.name === 'dashboard'}
                text={intl('Common.VENS')}
                badge={__TARGET__ === 'core' ? 'new' : null}
                data-tid={`${tid}pages-venstats`}
              />
            )}
            {ransomwareReadinessEnabled && isReportsTimeSeriesStatisticsAvailable && (
              <MenuItem
                link="ransomwareDashboard"
                isActive={route.name === 'ransomwareDashboard'}
                text={intl('RansomwareDashboard.RansomwareProtection')}
                badge={__TARGET__ === 'core' ? 'new' : null}
                data-tid={`${tid}pages-ransomware-dashboard`}
              />
            )}
          </MenuItem>
        ),

      explorerEnabled && (
        <MenuItem
          link="illumination"
          badge="new"
          text={intl('Common.IlluminationPlus')}
          data-tid={`${tid}pages-illuminationplus`}
        />
      ),
      illuminationMapIsEnabled &&
        (illuminationClassicSettings
          ? illuminationClassicSettings === 'enabled'
          : SessionStore.isIlluminationClassicEnabled()) && (
          <MenuItem
            isActive={route.name === 'map'}
            text={intl('IlluminationMap.IlluminationClassic')}
            onSelect={this.handleMapSelect}
            data-tid={`${tid}pages-map`}
          />
        ),
      (illuminationApiIsEnabled &&
        isAppGroupMapEnabled &&
        (trafficEnabled ? (
          <MenuItem
            isActive={route.name === 'appMap' || undefined}
            text={intl('Common.AppGroups')}
            data-tid={`${tid}sub-app-map`}
          >
            <MenuItem
              isActive={route.name === 'appMap'}
              text={intl('Common.AppGroupMap')}
              onSelect={this.handleAppMapSelect}
              data-tid={`${tid}pages-app-group-map`}
            />
            <MenuItem link="appGroups" text={intl('Common.AppGroupsList')} data-tid={`${tid}pages-app-groups`} />
          </MenuItem>
        ) : (
          <MenuItem link="appGroups" text={intl('Common.AppGroups')} data-tid={`${tid}pages-app-groups`} />
        ))) ||
        null,
      explorerEnabled && (
        <MenuItem link="explorer" text={intl('Common.Explorer')} data-tid={`${tid}pages-traffic-analyzer`} />
      ),
      reportingEnabled && isAPIAvailable('reports.create') && (
        <MenuItem link="reporting.downloads.list" text={intl('Common.Reports')} data-tid={`${tid}pages-reporting`} />
      ),
      illuminationApiIsEnabled && trafficEnabled && (
        <MenuItem
          text={intl('PolicyGenerator.PolicyGenerator')}
          link="policygenerator"
          data-tid={`${tid}pages-policygenerator`}
        />
      ),
      enforcementBoundaryEnabled && (
        <MenuItem
          link="boundaries.list"
          data-tid={`${tid}pages-boundaries-enforcement-rules`}
          text={intl('Rulesets.DenyRules')}
        />
      ),
      !this.state.userIsWithReducedScope && (
        <MenuItem text={intl('Common.Policies')} data-tid={`${tid}sub-rulesets`}>
          <MenuItem link="rulesets.list" data-tid={`${tid}pages-rulesets`} text={intl('Common.Policies')} />
          <MenuItem
            data-tid={`${tid}pages-rulesearch`}
            text={intl('Common.RuleSearch')}
            link={{to: 'rulesets.ruleSearch'}}
            onSelect={this.handleRuleSearchClick}
          />
        </MenuItem>
      ),

      <MenuItem text={intl('Common.WorkloadManagement')} data-tid={`${tid}sub-workloadmanagement`}>
        <MenuItem link="workloads.list" text={intl('Common.Workloads')} data-tid={`${tid}pages-workloads`} />
        {SessionStore.isKubernetesSupported() && (
          <MenuItem
            link="workloads.containers.list"
            text={intl('Common.ContainerWorkloads')}
            data-tid={`${tid}pages-container-workloads`}
          />
        )}
        <MenuItem link="workloads.vens.list" text={intl('Common.VENS')} data-tid={`${tid}pages-vens`} />
        {this.state.pairingProfilesIsEnabled && (
          <MenuItem
            link="pairingProfiles.list"
            text={intl('PairingProfiles.Profiles')}
            data-tid={`${tid}pages-pairingprofiles`}
          />
        )}
        {this.state.venLibraryEnabled && (
          <MenuItem link="venLibrary" text={intl('VEN.Library')} data-tid={`${tid}pages-vens-library`} />
        )}
      </MenuItem>,

      <MenuDelimiter />,

      <MenuItem text={intl('Policy.Objects')} data-tid={`${tid}sub-policy`}>
        <MenuItem link="services.list" text={intl('Common.Services')} data-tid={`${tid}pages-services`} />
        <MenuItem link="iplists.list" text={intl('Common.IPLists')} data-tid={`${tid}pages-iplists`} />
        <MenuItem link="labels.list" text={intl('Common.Labels')} data-tid={`${tid}pages-labels`} />
        <MenuItem link="userGroups.list" text={intl('Common.UserGroups')} data-tid={`${tid}pages-usergroups`} />
        <MenuItem link="labelGroups.list" text={intl('Labels.Groups')} data-tid={`${tid}pages-labelgroups`} />
        {SessionStore.isKubernetesSupported() && (
          <MenuItem
            link="virtualServices.list"
            text={intl('Common.VirtualServices')}
            data-tid={`${tid}pages-virtualservices`}
          />
        )}

        <MenuItem
          link="virtualServers.list"
          text={intl('Common.VirtualServers')}
          data-tid={`${tid}pages-virtualserver`}
        />

        {this.state.segmentationTemplatesIsEnabled && (
          <MenuItem
            text={intl('SegmentationTemplates.SegmentationTemplates')}
            link="segmentationTemplates.list"
            data-tid="pages-segmentationtemplates"
          />
        )}
      </MenuItem>,

      this.state.infrastructureIsEnabled && (
        <MenuItem text={intl('Menu.Infrastructure')} data-tid={`${tid}sub-network`}>
          {this.state.coreServicesEnabled && isAPIAvailable('settings_core_services.update') && (
            <MenuItem
              link="coreservices.list"
              text={intl('CoreServices.CoreServices')}
              data-tid={`${tid}pages-coreservices`}
            />
          )}
          {this.state.loadBalancerEnabled && (
            <MenuItem
              link="loadBalancers.list"
              text={intl('Menu.LoadBalancers')}
              data-tid={`${tid}pages-loadbalancers`}
            />
          )}
          {SessionStore.isKubernetesSupported() && (
            <MenuItem
              link="containerClusters.list"
              text={intl('Menu.ContainerClusters', {multiple: true})}
              data-tid={`${tid}pages-containerClusters`}
            />
          )}

          <MenuItem
            link="secureGateways.list"
            text={intl('SecureGateway.SecureConnect')}
            data-tid={`${tid}pages-secureGateways`}
          />

          <MenuItem
            link="networks.list"
            text={intl('Menu.Networks', {multiple: true})}
            data-tid={`${tid}pages-networks`}
          />
          {this.state.networkEnforcementNodeEnabled && (
            <MenuItem link="switches.list" text={intl('Menu.Switches')} data-tid={`${tid}pages-switches`} />
          )}
          <MenuItem
            link={{href: 'https://lumos1.illum.io', target: '_blank'}}
            text={intl('Common.CloudSecure')}
            data-tid={`${tid}cloudsecure`}
          />
        </MenuItem>
      ),

      <MenuDelimiter />,

      !this.state.userIsWithReducedScope && (
        <MenuItem text={intl('Common.Provision')} data-tid={`${tid}sub-provision`}>
          <MenuItem link="pending" text={intl('Provision.DraftChanges')} data-tid={`${tid}pages-provisioning`} />
          <MenuItem
            link="versions.list"
            text={intl('Provision.Versions', {multiple: true})}
            data-tid={`${tid}pages-versions`}
          />
        </MenuItem>
      ),

      <MenuDelimiter />,

      this.getRBACMenuItems(),

      this.state.settingsIsEnabled ? (
        <MenuItem
          isActive={route.path.startsWith('/sso') || undefined}
          text={intl('Common.Settings')}
          data-tid={`${tid}sub-settings`}
        >
          <MenuItem
            link="corporatepublicips.list"
            text={intl('GlobalNetwork.GlobalIPs')}
            data-tid={`${tid}pages-global-ips`}
          />

          <MenuItem link="venoperations" text={intl('VEN.VENOperation')} data-tid={`${tid}pages-venoperations`} />

          {this.state.healthEnabled && SessionStore.isEventsEnabled() ? (
            <MenuItem
              link={{to: 'events.config.detail', params: {mode: 'view'}}}
              text={intl('Common.EventSettings')}
              data-tid={`${tid}pages-eventconfig`}
            />
          ) : null}
          {this.isUserOwner() && (
            <MenuItem
              link="flowCollectionFilters.list"
              text={intl('Common.FlowCollectionFilters')}
              data-tid={`${tid}pages-flow-collection`}
            />
          )}
          {labelSettingsPageEnabled && (
            <MenuItem
              link="labelsettings.view"
              text={intl('Common.LabelSettings')}
              data-tid={`${tid}pages-labelsettings`}
            />
          )}

          <MenuItem
            link={{to: 'securitysettings.section', params: {section: 'general'}}}
            text={intl('Common.Security')}
            data-tid={`${tid}pages-securitysettings`}
          />

          {this.state.coreServicesSettingsEnabled && isAPIAvailable('settings_core_services.update') && (
            <MenuItem
              link="coreservicesettings"
              text={intl('CoreServices.CoreServices')}
              data-tid={`${tid}pages-core-services-settings`}
            />
          )}
          {isAPIAvailable('essential_service_rules.get') && (
            <MenuItem
              link="essentialservicerules"
              text={intl('Settings.EssentialServiceRules')}
              data-tid={`${tid}pages-essentialservicerules`}
            />
          )}

          <MenuItem link="venoperations" text={intl('VEN.VENOperation')} data-tid={`${tid}pages-venoperations`} />

          {isAPIAvailable('trusted_proxy_ips.get') && (
            <MenuItem
              link={{to: 'trustedproxy.list'}}
              text={intl('TrustedProxy.TrustedProxyIp')}
              data-tid={`${tid}pages-trustedproxylist`}
            />
          )}
          {this.isUserOwner() ? (
            <MenuItem link="policySettings.detail" text={intl('Policy.Settings')} data-tid={`${tid}policy-settings`} />
          ) : null}
          {this.isUserOwner() && (
            <MenuItem
              link="apiKeySettings.view"
              text={intl('APIKeySettings.APIKeySettings')}
              data-tid={`${tid}apikey-settings`}
            />
          )}

          <MenuItem
            link="offlineTimers.server"
            text={intl('OfflineTimers.OfflineTimers')}
            data-tid={`${tid}offline-timers`}
          />
        </MenuItem>
      ) : null,

      <MenuDelimiter />,

      <MenuItem text={intl('Common.Troubleshooting')} data-tid={`${tid}sub-troubleshooting`}>
        {this.state.eventsIsEnabled && (
          <MenuItem link="events" text={intl('Common.Events')} data-tid={`${tid}pages-orgevents`} />
        )}
        <MenuItem link="reports.list" text={intl('Exports.PageName')} data-tid={`${tid}pages-reports`} />
        {(!this.isUserReadOnly(false) || this.isWorkloadManager()) && (
          <MenuItem
            link="supportBundles.ven.list"
            text={intl('SupportReports.PageName')}
            data-tid={`${tid}pages-vensupportbundle`}
          />
        )}
        {this.state.healthEnabled && (this.isUserOwner() || this.isUserAdmin()) && (
          <MenuItem
            link="supportBundles.pce.list"
            text={intl('PCESupportBundles.PageName')}
            data-tid={`${tid}pages-pcesupportbundle`}
          />
        )}
        {!this.state.userIsWithReducedScope && (
          <MenuItem link="connectivity" text={intl('Policy.Check')} data-tid={`${tid}pages-connectivity`} />
        )}
        <MenuItem
          onSelect={this.handleOpenVersionDialog}
          text={intl('Common.ProductVersion')}
          data-tid={`${tid}about`}
        />
      </MenuItem>,

      <MenuItem
        link={{href: SessionStore.getSupportLink(), target: '_blank'}}
        text={intl('Common.Support')}
        data-tid={`${tid}pages-support`}
      />,
    ];

    const antmanMenu = [
      <MenuItem link="landing" text={intl('Common.Home')} data-tid={`${tid}pages-landing`} />,
      !this.state.userIsWithReducedScope && (
        <MenuItem
          link="dashboard"
          isActive={route.name === 'dashboard'}
          text={intl('Common.Dashboard')}
          badge={__TARGET__ === 'core' ? 'preview' : null}
          data-tid={`${tid}pages-dashboard`}
        />
      ),

      <MenuItem link="landing" text={intl('Common.Groups')} data-tid={`${tid}pages-dashboard`} />,
      <MenuItem
        isActive={route.name === 'map'}
        text={intl('IlluminationMap.IlluminationClassic')}
        onSelect={this.handleMapSelect}
        data-tid={`${tid}pages-map`}
      />,
      <MenuItem
        link="illumination"
        badge="new"
        text={intl('Common.IlluminationPlus')}
        data-tid={`${tid}pages-illuminationplus`}
      />,

      explorerEnabled && (
        <MenuItem link="explorer" text={intl('Common.Explorer')} data-tid={`${tid}pages-traffic-analyzer`} />
      ),
      !this.state.userIsWithReducedScope && (
        <MenuItem text={intl('Common.Policies')} data-tid={`${tid}sub-rulesets`}>
          <MenuItem link="rulesets.list" data-tid={`${tid}pages-rulesets`} text={intl('Common.Policies')} />
          {illuminationApiIsEnabled && trafficEnabled && (
            <MenuItem
              text={intl('PolicyGenerator.PolicyGenerator')}
              link="policygenerator"
              data-tid={`${tid}pages-policygenerator`}
            />
          )}
        </MenuItem>
      ),

      <MenuItem text={intl('Common.WorkloadManagement')} data-tid={`${tid}sub-workloadmanagement`}>
        <MenuItem link="workloads.list" text={intl('Common.Workloads')} data-tid={`${tid}pages-workloads`} />
        <MenuItem link="workloads.vens.list" text={intl('Common.VENS')} data-tid={`${tid}pages-vens`} />
      </MenuItem>,

      <MenuItem text={intl('Policy.Objects')} data-tid={`${tid}sub-policy`}>
        <MenuItem link="services.list" text={intl('Common.Services')} data-tid={`${tid}pages-services`} />
        <MenuItem link="labels.list" text={intl('Common.Labels')} data-tid={`${tid}pages-labels`} />
        <MenuItem link="iplists.list" text={intl('Common.IPLists')} data-tid={`${tid}pages-iplists`} />
        <MenuItem link="labelGroups.list" text={intl('Labels.Groups')} data-tid={`${tid}pages-labelgroups`} />
      </MenuItem>,

      this.state.settingsIsEnabled ? (
        <MenuItem
          isActive={route.path.startsWith('/sso') || undefined}
          text={intl('Common.Settings')}
          data-tid={`${tid}sub-settings`}
        >
          {this.getRBACMenuItems()}
          <MenuItem
            link="corporatepublicips.list"
            text={intl('GlobalNetwork.GlobalIPs')}
            data-tid={`${tid}pages-global-ips`}
          />

          <MenuItem
            link="venoperations"
            text={__ATR__ ? intl('VEN.VENOperation') : intl('Settings.IKECertificates')}
            data-tid={__ATR__ ? `${tid}pages-venoperations` : `${tid}pages-ikecertificates`}
          />

          {this.state.healthEnabled && SessionStore.isEventsEnabled() ? (
            <MenuItem
              link={{to: 'events.config.detail', params: {mode: 'view'}}}
              text={intl('Common.EventSettings')}
              data-tid={`${tid}pages-eventconfig`}
            />
          ) : null}
          {this.isUserOwner() && (
            <MenuItem
              link="flowCollectionFilters.list"
              text={intl('Common.FlowCollectionFilters')}
              data-tid={`${tid}pages-flow-collection`}
            />
          )}
          {labelSettingsPageEnabled && (
            <MenuItem
              link="labelsettings.view"
              text={intl('Common.LabelSettings')}
              data-tid={`${tid}pages-labelsettings`}
            />
          )}

          <MenuItem
            link={{to: 'securitysettings.section', params: {section: 'general'}}}
            text={intl('Common.Security')}
            data-tid={`${tid}pages-securitysettings`}
          />

          {this.state.coreServicesSettingsEnabled && isAPIAvailable('settings_core_services.update') && (
            <MenuItem
              link="coreservicesettings"
              text={intl('CoreServices.CoreServices')}
              data-tid={`${tid}pages-core-services-settings`}
            />
          )}
          {isAPIAvailable('essential_service_rules.get') && (
            <MenuItem
              link="essentialservicerules"
              text={intl('Settings.EssentialServiceRules')}
              data-tid={`${tid}pages-essentialservicerules`}
            />
          )}

          <MenuItem
            link="venoperations"
            text={__ATR__ ? intl('VEN.VENOperation') : intl('Settings.IKECertificates')}
            data-tid={__ATR__ ? `${tid}pages-venoperations` : `${tid}pages-ikecertificates`}
          />

          {isAPIAvailable('trusted_proxy_ips.get') && (
            <MenuItem
              link={{to: 'trustedproxy.list'}}
              text={intl('TrustedProxy.TrustedProxyIp')}
              data-tid={`${tid}pages-trustedproxylist`}
            />
          )}
          {this.isUserOwner() ? (
            <MenuItem link="policySettings.detail" text={intl('Policy.Settings')} data-tid={`${tid}policy-settings`} />
          ) : null}
          {this.isUserOwner() && (
            <MenuItem
              link="apiKeySettings.view"
              text={intl('APIKeySettings.APIKeySettings')}
              data-tid={`${tid}apikey-settings`}
            />
          )}

          <MenuItem
            link="offlineTimers.detail"
            text={intl('OfflineTimers.OfflineTimers')}
            data-tid={`${tid}offline-timers`}
          />
        </MenuItem>
      ) : null,

      <MenuDelimiter />,

      <MenuItem text={intl('Common.AdditionalTools')} data-tid={`${tid}sub-additional-tools`}>
        {this.state.eventsIsEnabled && (
          <MenuItem link="events" text={intl('Common.Events')} data-tid={`${tid}pages-orgevents`} />
        )}
        {(!this.isUserReadOnly(false) || this.isWorkloadManager()) && (
          <MenuItem
            link="supportBundles.ven.list"
            text={intl('SupportReports.PageName')}
            data-tid={`${tid}pages-vensupportbundle`}
          />
        )}
        <MenuItem link="userGroups.list" text={intl('Common.UserGroups')} data-tid={`${tid}pages-usergroups`} />
        {this.state.venLibraryEnabled && (
          <MenuItem link="venLibrary" text={intl('VEN.Library')} data-tid={`${tid}pages-vens-library`} />
        )}
        {this.state.pairingProfilesIsEnabled && (
          <MenuItem
            link="pairingProfiles.list"
            text={intl('PairingProfiles.Profiles')}
            data-tid={`${tid}pages-pairingprofiles`}
          />
        )}
        <MenuItem
          data-tid={`${tid}pages-rulesearch`}
          text={intl('Common.RuleSearch')}
          link={{to: 'rulesets.ruleSearch'}}
          onSelect={this.handleRuleSearchClick}
        />
        <MenuItem
          link="reporting.downloads.list"
          text={intl('Common.Reports')}
          badge={null}
          data-tid={`${tid}pages-reporting`}
        />
        <MenuItem
          isActive={route.name === 'appMap' || undefined}
          text={intl('Common.AppGroups')}
          data-tid={`${tid}sub-app-map`}
        >
          <MenuItem
            isActive={route.name === 'appMap'}
            text={intl('Common.AppGroupMap')}
            onSelect={this.handleAppMapSelect}
            data-tid={`${tid}pages-app-group-map`}
          />
          <MenuItem link="appGroups" text={intl('Common.AppGroupsList')} data-tid={`${tid}pages-app-groups`} />
        </MenuItem>
      </MenuItem>,
    ];

    if (__DEV__) {
      menu.push(
        <MenuDelimiter />,
        <MenuItem link="componentsold" text="Components" />,
        <MenuItem link="formcomponents" text="Components Forms" />,
      );
    }

    if (__ANTMAN__) {
      return antmanMenu;
    }

    return menu;
  },

  render() {
    const {type} = this.props;
    const icon = type === 'primary' ? <Icon name="menu" size="xlarge" /> : <Icon name="dropdown" size="xxlarge" />;

    return (
      <Menu icon={icon} tid="pages">
        {this.renderContent}
      </Menu>
    );
  },
});
