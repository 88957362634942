/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

const bsServiceBindings = new Map();
let isLoaded = false;

function setBsServiceBindings(virtualServiceHref, data) {
  bsServiceBindings.set(virtualServiceHref, data);
}

function deleteServiceBinding(id) {
  for (const bsServiceBinding of bsServiceBindings.values()) {
    const index = bsServiceBinding.findIndex(sb => sb.href.split('/').pop() === id);

    if (index > -1) {
      bsServiceBinding.splice(index, 1);

      return;
    }
  }
}

export default createApiStore(['SERVICE_BINDING_', 'SERVICE_BINDINGS_'], {
  dispatchHandler(action) {
    const virtualServiceHref = action.options && action.options.query && action.options.query.virtual_service;
    const workloadHref = action.options && action.options.query && action.options.query.workload;

    switch (action.type) {
      case Constants.SERVICE_BINDINGS_GET_COLLECTION_SUCCESS:
        if (virtualServiceHref) {
          setBsServiceBindings(virtualServiceHref, action.data);
          isLoaded = true;
        }

        if (workloadHref) {
          setBsServiceBindings(workloadHref, action.data);
          isLoaded = true;
        }

        break;

      case Constants.SERVICE_BINDING_DELETE_SUCCESS:
        deleteServiceBinding(action.options.params.service_binding_id);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll(virtualServiceHref) {
    return bsServiceBindings.get(virtualServiceHref);
  },

  isLoaded() {
    return isLoaded;
  },
});
