/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {Button} from '../components';
import {Fieldset, Form, Field} from '../components/Form';
import {ToolBar, ToolGroup} from '../components/ToolBar';
import {RouterMixin} from '../mixins';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  mixins: [RouterMixin],

  getInitialState() {
    // this is used to decide whether any change from here
    // need to clean and update traffic from traffic store.
    this.needUpdateTraffic = false;

    let locationView = localStorage.getItem('location_view');

    if (locationView === null) {
      locationView = 'default';
    } else {
      locationView = parseInt(locationView, 10);
    }

    let level = localStorage.getItem('level');

    switch (level) {
      case 'location':
        locationView = 0;
        break;
      case 'custom':
        break;
      default:
        level = 'default';

        if (locationView !== 'default') {
          level = 'custom';
        }
    }

    return {
      ports: localStorage.getItem('max_ports') || 'default',
      maxWorkloadThreshold: parseInt(localStorage.getItem('total_workload_threshold'), 10) || 'default',
      roleCollapse: parseInt(localStorage.getItem('role_collapse'), 10) || 'default',
      roleExpand: parseInt(localStorage.getItem('role_expand'), 10) || 'default',
      groupExpand: parseInt(localStorage.getItem('group_expand'), 10) || 'default',
      maximumExpandedRoles: parseInt(localStorage.getItem('maximum_expanded_roles'), 10) || 'default',
      overlappingIplists: parseInt(localStorage.getItem('overlapping_iplists'), 10) || 'default',
      addressesPerIpLink: parseInt(localStorage.getItem('addresses_per_ip_link'), 10) || 'default',
      green: parseInt(localStorage.getItem('green'), 10) || 'default',
      locationView,
      level,
      maxClustersForLinks: parseInt(localStorage.getItem('max_clusters_for_links'), 10) || 'default',
      maxClustersPerLocation: parseInt(localStorage.getItem('max_clusters_per_location'), 10) || 'default',
      maxLocations: parseInt(localStorage.getItem('max_locations'), 10) || 'default',
      addToTime: parseInt(localStorage.getItem('add_to_time'), 10) || 'default',
      rebuildAlways: parseInt(localStorage.getItem('rebuild_always'), 10) || 'default',
      rebuildOnDemand: parseInt(localStorage.getItem('rebuild_on_demand'), 10) || 'default',
      loadRuleCoverage: parseInt(localStorage.getItem('load_rule_coverage'), 10) || 'default',
      rebuildWorkloads: parseInt(localStorage.getItem('rebuild_expanded_workloads'), 10) || 'default',
      dnsIplistMapping: localStorage.getItem('dns_iplist_mapping') || 'default',
      showConnectionState: localStorage.getItem('show_connection_state') || 'default',
      connectedAppGroupRules: parseInt(localStorage.getItem('connected_app_group_rules'), 10) || 'default',
      truncationLimit: parseInt(localStorage.getItem('truncation_limit'), 10) || 'default',
      servicesPerRule: parseInt(localStorage.getItem('services_per_rule'), 10) || 'default',
      maxLinksForRules: parseInt(localStorage.getItem('max_links_for_rules'), 10) || 'default',
      showExplorerServices: localStorage.getItem('disable_explorer_services') || 'default',
    };
  },

  handleClearPositions() {
    localStorage.removeItem('positions');
    localStorage.removeItem('transform');
    location.reload();
  },

  handleSave() {
    if (this.state.ports === 'default') {
      localStorage.removeItem('max_ports');
    } else {
      localStorage.setItem('max_ports', this.state.ports);
    }

    if (this.state.maxWorkloadThreshold === 'default') {
      localStorage.removeItem('total_workload_threshold');
    } else {
      localStorage.setItem('total_workload_threshold', this.state.maxWorkloadThreshold);
    }

    if (this.state.level === 'default') {
      localStorage.removeItem('level');
    } else {
      localStorage.setItem('level', this.state.level);
    }

    if (this.state.locationView === 'default') {
      localStorage.removeItem('location_view');
    } else {
      localStorage.setItem('location_view', this.state.locationView);
    }

    if (this.state.overlappingIplists === 'default') {
      localStorage.removeItem('overlapping_iplists');
    } else {
      localStorage.setItem('overlapping_iplists', this.state.overlappingIplists);
    }

    if (this.state.addressesPerIpLink === 'default') {
      localStorage.removeItem('addresses_per_ip_link');
    } else {
      localStorage.setItem('addresses_per_ip_link', this.state.addressesPerIpLink);
    }

    if (this.state.roleCollapse === 'default') {
      localStorage.removeItem('role_collapse');
    } else {
      localStorage.setItem('role_collapse', this.state.roleCollapse);
    }

    if (this.state.roleExpand === 'default') {
      localStorage.removeItem('role_expand');
    } else {
      localStorage.setItem('role_expand', this.state.roleExpand);
    }

    if (this.state.groupExpand === 'default') {
      localStorage.removeItem('group_expand');
    } else {
      localStorage.setItem('group_expand', this.state.groupExpand);
    }

    if (this.state.maximumExpandedRoles === 'default') {
      localStorage.removeItem('maximum_expanded_roles');
    } else {
      localStorage.setItem('maximum_expanded_roles', this.state.maximumExpandedRoles);
    }

    if (this.state.green === 'default') {
      localStorage.removeItem('green');
    } else {
      localStorage.setItem('green', this.state.green);
    }

    if (this.state.maxClustersForLinks === 'default') {
      localStorage.removeItem('max_clusters_for_links');
    } else {
      localStorage.setItem('max_clusters_for_links', this.state.maxClustersForLinks);
    }

    if (this.state.maxClustersPerLocation === 'default') {
      localStorage.removeItem('max_clusters_per_location');
    } else {
      localStorage.setItem('max_clusters_per_location', this.state.maxClustersPerLocation);
    }

    if (this.state.maxLocations === 'default') {
      localStorage.removeItem('max_locations');
    } else {
      localStorage.setItem('max_locations', this.state.maxLocations);
    }

    if (this.state.addToTime === 'default') {
      localStorage.removeItem('add_to_time');
    } else {
      localStorage.setItem('add_to_time', this.state.addToTime);
    }

    if (this.state.rebuildAlways === 'default') {
      localStorage.removeItem('rebuild_always');
    } else {
      localStorage.setItem('rebuild_always', this.state.rebuildAlways);
    }

    if (this.state.rebuildOnDemand === 'default') {
      localStorage.removeItem('rebuild_on_demand');
    } else {
      localStorage.setItem('rebuild_on_demand', this.state.rebuildOnDemand);
    }

    if (this.state.loadRuleCoverage === 'default') {
      localStorage.removeItem('load_rule_coverage');
    } else {
      localStorage.setItem('load_rule_coverage', this.state.loadRuleCoverage);
    }

    if (this.state.rebuildWorkloads === 'default') {
      localStorage.removeItem('rebuild_expanded_workloads');
    } else {
      localStorage.setItem('rebuild_expanded_workloads', this.state.rebuildWorkloads);
    }

    if (this.state.dnsIplistMapping === 'default') {
      localStorage.removeItem('dns_iplist_mapping');
    } else {
      localStorage.setItem('dns_iplist_mapping', this.state.dnsIplistMapping);
    }

    if (this.state.showConnectionState === 'default') {
      localStorage.removeItem('show_connection_state');
    } else {
      localStorage.setItem('show_connection_state', this.state.showConnectionState);
    }

    if (this.state.showExplorerServices === 'default') {
      localStorage.removeItem('disable_explorer_services');
    } else {
      localStorage.setItem('disable_explorer_services', this.state.showExplorerServices);
    }

    if (this.state.connectedAppGroupRules === 'default') {
      localStorage.removeItem('connected_app_group_rules');
    } else {
      localStorage.setItem('connected_app_group_rules', this.state.connectedAppGroupRules);
    }

    if (this.state.truncationLimit === 'default') {
      localStorage.removeItem('truncation_limit');
    } else {
      localStorage.setItem('truncation_limit', this.state.truncationLimit);
    }

    if (this.state.servicesPerRule === 'default') {
      localStorage.removeItem('services_per_rule');
    } else {
      localStorage.setItem('services_per_rule', this.state.servicesPerRule);
    }

    if (this.state.maxLinksForRules === 'default') {
      localStorage.removeItem('max_links_for_rules');
    } else {
      localStorage.setItem('max_links_for_rules', this.state.maxLinksForRules);
    }

    if (this.needUpdateTraffic) {
      actionCreators.updateForAll();
    }

    actionCreators.updateTrafficParameters(this.state);
    this.transitionTo('map');
  },

  handleSelectAddressesPerIpLink(input) {
    this.setState({addressesPerIpLink: input.value});
  },

  handleSelectGreen(input) {
    this.setState({green: input.value});
  },

  handleSelectLevel(input) {
    switch (input.value) {
      case 'location':
        this.setState({
          level: input.value,
          locationView: 0,
        });
        break;
      case 'default':
        this.setState({
          level: input.value,
          locationView: 'default',
        });
        break;
      default:
        this.setState({
          level: input.value,
        });
    }

    this.needUpdateTraffic = true;
  },

  handleSelectLocationView(input) {
    this.needUpdateTraffic = true;
    this.setState({locationView: input.value});
  },

  handleSelectMaxClustersForLinks(input) {
    this.setState({maxClustersForLinks: input.value});
  },

  handleSelectMaxClustersPerLocation(input) {
    this.setState({maxClustersPerLocation: input.value});
  },

  handleSelectMaxLocations(input) {
    this.setState({maxLocations: input.value});
  },

  handleSelectMaxWorkloadThreshold(input) {
    this.setState({maxWorkloadThreshold: input.value});
  },

  handleSelectMaximumExpandedRoles(input) {
    this.setState({maximumExpandedRoles: input.value});
  },

  handleSelectOverlappingIpLists(input) {
    this.setState({overlappingIplists: input.value});
  },

  handleSelectPorts(input) {
    this.setState({ports: input.value});
  },

  handleSelectRoleCollapse(input) {
    this.setState({roleCollapse: input.value});
  },

  handleSelectRoleExpand(input) {
    this.setState({roleExpand: input.value});
  },

  handleSelectGroupExpand(input) {
    this.setState({groupExpand: input.value});
  },

  handleSelectAddToTime(input) {
    this.setState({addToTime: input.value});
  },

  handleSelectRebuildAlways(input) {
    this.setState({rebuildAlways: input.value});
  },

  handleSelectRebuildOnDemand(input) {
    this.setState({rebuildOnDemand: input.value});
  },

  handleLoadRuleCoverage(input) {
    this.setState({loadRuleCoverage: input.value});
  },

  handleSelectRebuildWorkloads(input) {
    this.setState({rebuildWorkloads: input.value});
  },

  handleSelectDnsIpListMapping(input) {
    this.setState({dnsIplistMapping: input.value});
    this.needUpdateTraffic = true;
  },

  handleSelectShowConnectionState(input) {
    this.setState({showConnectionState: input.value});
  },

  handleSelectShowExplorerServices(input) {
    this.setState({showExplorerServices: input.value});
  },

  handleSelectConnectedAppGroupRules(input) {
    this.setState({connectedAppGroupRules: input.value});
  },

  handleSelectTruncationLimit(input) {
    this.setState({truncationLimit: input.value});
  },

  handleSelectServicesPerRule(input) {
    this.setState({servicesPerRule: input.value});
  },

  handleSelectMaxLinksForRules(input) {
    this.setState({maxLinksForRules: input.value});
  },

  render() {
    const selectPortData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfPorts', {count: 5000})},
      {value: '5', label: intl.num(5)},
      {value: '64', label: intl.num(64)},
      {value: '128', label: intl.num(128)},
      {value: '256', label: intl.num(256)},
      {value: '512', label: intl.num(512)},
      {value: '1024', label: intl('TrafficParameters.KiloNumber', {count: 1})},
      {value: '2048', label: intl('TrafficParameters.KiloNumber', {count: 2})},
      {value: '4096', label: intl('TrafficParameters.KiloNumber', {count: 4})},
      {value: '8192', label: intl('TrafficParameters.KiloNumber', {count: 8})},
      {value: '16384', label: intl('TrafficParameters.KiloNumber', {count: 16})},
      {value: '32768', label: intl('TrafficParameters.KiloNumber', {count: 32})},
      {value: '-1', label: intl('TrafficParameters.NoLimit')},
    ];

    const maxWorkloadThreshold = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfWorkloads', {count: 100_000})},
      {value: 10, label: intl.num(10)},
      {value: 100, label: intl.num(100)},
      {value: 1000, label: intl.num(1000)},
      {value: 10_000, label: intl.num(10_000)},
      {value: 15_000, label: intl.num(15_000)},
      {value: 20_000, label: intl.num(20_000)},
      {value: 25_000, label: intl.num(25_000)},
      {value: 30_000, label: intl.num(30_000)},
      {value: 40_000, label: intl.num(40_000)},
      {value: 50_000, label: intl.num(50_000)},
      {value: 100_000, label: intl.num(100_000)},
      {value: 250_000, label: intl.num(250_000)},
      {value: 1_000_000, label: intl.num(1_000_000)},
    ];

    const levelData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfWorkloads', {count: 50})},
      {value: 'location', label: intl('TrafficParameters.LocationLevel')},
      {value: 'custom', label: intl('Common.Custom')},
    ];

    const dnsIplistMappingData = [
      {value: 'default', label: intl('TrafficParameters.DefaultDnsIplistMapping')},
      {value: 'domains', label: intl('TrafficParameters.MapAllFqdnsToDomains')},
    ];

    const showConnectionStateData = [
      {value: 'default', label: intl('TrafficParameters.DefaultShowConnectionState')},
      {value: 'show', label: intl('TrafficParameters.ShowConnectionState')},
    ];

    const showExplorerServicesData = [
      {value: 'default', label: intl('TrafficParameters.DefaultShowExplorerServices')},
      {value: 'disable', label: intl('TrafficParameters.DoNotShowExplorerServices')},
    ];

    const locationViewData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfWorkloads', {count: 50})},
      {value: 5, label: intl.num(5)},
      {value: 10, label: intl.num(10)},
      {value: 30, label: intl.num(30)},
      {value: 50, label: intl.num(50)},
      {value: 75, label: intl.num(75)},
      {value: 100, label: intl.num(100)},
      {value: 200, label: intl.num(200)},
    ];

    const overlappingIplistsData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfIPLists', {count: 50})},
      {value: 1, label: intl.num(1)},
      {value: 2, label: intl.num(2)},
      {value: 5, label: intl.num(5)},
      {value: 6, label: intl.num(6)},
      {value: 7, label: intl.num(7)},
      {value: 8, label: intl.num(8)},
      {value: 9, label: intl.num(9)},
      {value: 10, label: intl.num(10)},
      {value: 20, label: intl.num(20)},
      {value: 30, label: intl.num(30)},
      {value: 100, label: intl.num(100)},
      {value: 1000, label: intl.num(1000)},
    ];

    const addressesPerIpLinkData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfAddresses', {count: 500})},
      {value: 1, label: intl.num(1)},
      {value: 10, label: intl.num(10)},
      {value: 25, label: intl.num(25)},
      {value: 50, label: intl.num(50)},
      {value: 100, label: intl.num(100)},
      {value: 250, label: intl.num(250)},
      {value: 500, label: intl.num(500)},
      {value: 750, label: intl.num(750)},
      {value: 1000, label: intl.num(1000)},
      {value: 2000, label: intl.num(2000)},
      {value: 5000, label: intl.num(5000)},
      {value: 10_000, label: intl.num(10_000)},
    ];

    const roleCollapseData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfWorkloads', {count: 1})},
      {value: 1, label: intl.num(1)},
      {value: 2, label: intl.num(2)},
      {value: 3, label: intl.num(3)},
      {value: 4, label: intl.num(4)},
      {value: 5, label: intl.num(5)},
      {value: 10, label: intl.num(10)},
      {value: 20, label: intl.num(20)},
      {value: 50, label: intl.num(50)},
      {value: 100, label: intl.num(100)},
      {value: 200, label: intl.num(200)},
      {value: 500, label: intl.num(500)},
    ];

    const roleExpandData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfWorkloads', {count: 50})},
      {value: 75, label: intl.num(75)},
      {value: 100, label: intl.num(100)},
      {value: 125, label: intl.num(125)},
      {value: 150, label: intl.num(150)},
      {value: 175, label: intl.num(175)},
      {value: 200, label: intl.num(200)},
    ];

    const groupExpandData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfWorkloads', {count: 50})},
      {value: 75, label: intl.num(75)},
      {value: 100, label: intl.num(100)},
      {value: 125, label: intl.num(125)},
      {value: 150, label: intl.num(150)},
      {value: 175, label: intl.num(175)},
      {value: 200, label: intl.num(200)},
    ];

    const maxExpandedRolesData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfRoles', {count: 3})},
      {value: 2, label: intl.num(3)},
      {value: 4, label: intl.num(4)},
      {value: 5, label: intl.num(5)},
      {value: 10, label: intl.num(10)},
      {value: 20, label: intl.num(20)},
      {value: 50, label: intl.num(50)},
      {value: 100, label: intl.num(100)},
      {value: 200, label: intl.num(200)},
      {value: 500, label: intl.num(500)},
    ];

    const greenData = [
      {value: 'default', label: intl('TrafficParameters.DefaultPercentOfRuleCover', {proportion: 1})},
      {value: 1, label: intl('TrafficParameters.AnyRuleCoverage')},
      {value: 10, label: intl.num(0.1, {style: 'percent'})},
      {value: 20, label: intl.num(0.25, {style: 'percent'})},
      {value: 30, label: intl.num(0.33, {style: 'percent'})},
      {value: 50, label: intl.num(0.5, {style: 'percent'})},
      {value: 75, label: intl.num(0.75, {style: 'percent'})},
      {value: 80, label: intl.num(0.8, {style: 'percent'})},
      {value: 90, label: intl.num(0.9, {style: 'percent'})},
      {value: 100, label: intl.num(1, {style: 'percent'})},
    ];

    const maxClustersForLinksData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfClusters', {count: 75})},
      {value: 1, label: intl('TrafficParameters.NumClusters', {count: 1})},
      {value: 5, label: intl('TrafficParameters.NumClusters', {count: 5})},
      {value: 10, label: intl('TrafficParameters.NumClusters', {count: 10})},
      {value: 20, label: intl('TrafficParameters.NumClusters', {count: 20})},
      {value: 40, label: intl('TrafficParameters.NumClusters', {count: 40})},
      {value: 50, label: intl('TrafficParameters.NumClusters', {count: 50})},
      {value: 75, label: intl('TrafficParameters.NumClusters', {count: 75})},
      {value: 100, label: intl('TrafficParameters.NumClusters', {count: 100})},
    ];

    const maxClustersPerLocationData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfClusters', {count: 2000})},
      {value: 200, label: intl('TrafficParameters.NumClusters', {count: 200})},
      {value: 500, label: intl('TrafficParameters.NumClusters', {count: 500})},
      {value: 1000, label: intl('TrafficParameters.NumClusters', {count: 1000})},
      {value: 1500, label: intl('TrafficParameters.NumClusters', {count: 1500})},
      {value: 2000, label: intl('TrafficParameters.NumClusters', {count: 2000})},
      {value: 3000, label: intl('TrafficParameters.NumClusters', {count: 3000})},
      {value: 4000, label: intl('TrafficParameters.NumClusters', {count: 4000})},
      {value: 5000, label: intl('TrafficParameters.NumClusters', {count: 5000})},
      {value: 7500, label: intl('TrafficParameters.NumClusters', {count: 7500})},
      {value: 10_000, label: intl('TrafficParameters.NumClusters', {count: 10_000})},
    ];

    const maxLocationsData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfLocations', {count: 5})},
      {value: 10, label: intl('TrafficParameters.NumLocations', {count: 10})},
      {value: 20, label: intl('TrafficParameters.NumLocations', {count: 20})},
      {value: 30, label: intl('TrafficParameters.NumLocations', {count: 30})},
      {value: 50, label: intl('TrafficParameters.NumLocations', {count: 50})},
      {value: 100, label: intl('TrafficParameters.NumLocations', {count: 100})},
      {value: 200, label: intl('TrafficParameters.NumLocations', {count: 200})},
      {value: 500, label: intl('TrafficParameters.NumLocations', {count: 500})},
    ];

    const addToTimeData = [
      {value: 'default', label: intl('TrafficParameters.DefaultTime')},
      {value: 1, label: intl('TrafficParameters.Days', {count: 1})},
      {value: 5, label: intl('TrafficParameters.Days', {count: 5})},
      {value: 7, label: intl('TrafficParameters.Weeks', {count: 1})},
      {value: 14, label: intl('TrafficParameters.Weeks', {count: 2})},
      {value: 30, label: intl('TrafficParameters.Months', {count: 1})},
      {value: 180, label: intl('TrafficParameters.Months', {count: 6})},
      {value: 356, label: intl('TrafficParameters.Years', {count: 1})},
      {value: 1780, label: intl('TrafficParameters.Years', {count: 5})},
    ];

    const rebuildAlwaysData = [
      {value: 'default', label: intl('TrafficParameters.DoNotAlwaysRebuild')},
      {value: 100, label: intl('TrafficParameters.RebuildAlways')},
    ];

    const rebuildOnDemandData = [
      {value: 'default', label: intl('TrafficParameters.DefaultRebuildStale')},
      {value: 1, label: intl('TrafficParameters.DoNotRebuildStale')},
      {value: 1_000_000, label: intl('TrafficParameters.RebuildStale')},
    ];

    const loadRuleCoverageData = [
      {value: 'default', label: intl('TrafficParameters.DefaultMaxAppGroupRuleCoverage')},
      {value: 1, label: intl('TrafficParameters.AppGroups', {count: 1})},
      {value: 10, label: intl('TrafficParameters.AppGroups', {count: 10})},
      {value: 50, label: intl('TrafficParameters.AppGroups', {count: 50})},
      {value: 100, label: intl('TrafficParameters.AppGroups', {count: 100})},
      {value: 500, label: intl('TrafficParameters.AppGroups', {count: 500})},
      {value: 1000, label: intl('TrafficParameters.AppGroups', {count: 1000})},
      {value: 5000, label: intl('TrafficParameters.AppGroups', {count: 5000})},
      {value: 10_000, label: intl('TrafficParameters.AppGroups', {count: 10_000})},
      {value: 20_000, label: intl('TrafficParameters.AppGroups', {count: 20_000})},
    ];

    const rebuildWorkloadData = [
      {value: 'default', label: intl('TrafficParameters.DefaultNumberOfWorkloads', {count: 500})},
      {value: 10, label: intl.num(10)},
      {value: 100, label: intl.num(100)},
      {value: 1000, label: intl.num(1000)},
      {value: 5000, label: intl.num(5000)},
      {value: 10_000, label: intl.num(10_000)},
      {value: 15_000, label: intl.num(15_000)},
      {value: 20_000, label: intl.num(20_000)},
      {value: 25_000, label: intl.num(25_000)},
      {value: 30_000, label: intl.num(30_000)},
      {value: 40_000, label: intl.num(40_000)},
      {value: 50_000, label: intl.num(50_000)},
      {value: 100_000, label: intl.num(100_000)},
      {value: 250_000, label: intl.num(250_000)},
      {value: 1_000_000, label: intl.num(1_000_000)},
    ];

    const connectedAppGroupRulesData = [
      {value: 'default', label: intl('TrafficParameters.DefaultConnectedLinks', {count: 5000})},
      {value: 1, label: intl.num(1)},
      {value: 100, label: intl.num(100)},
      {value: 1000, label: intl.num(1000)},
      {value: 10_000, label: intl.num(10_000)},
      {value: 20_000, label: intl.num(20_000)},
      {value: 30_000, label: intl.num(30_000)},
      {value: 40_000, label: intl.num(40_000)},
      {value: 50_000, label: intl.num(50_000)},
      {value: 100_000, label: intl.num(100_000)},
      {value: 1_000_000, label: intl.num(1_000_000)},
    ];

    const truncationLimitData = [
      {value: 'default', label: intl('TrafficParameters.DefaultTruncationLimit', {count: 5000})},
      {value: 1, label: intl.num(1)},
      {value: 100, label: intl.num(100)},
      {value: 1000, label: intl.num(1000)},
      {value: 10_000, label: intl.num(10_000)},
      {value: 20_000, label: intl.num(20_000)},
      {value: 30_000, label: intl.num(30_000)},
      {value: 40_000, label: intl.num(40_000)},
      {value: 50_000, label: intl.num(50_000)},
      {value: 100_000, label: intl.num(100_000)},
      {value: 1_000_000, label: intl.num(1_000_000)},
    ];

    const servicesPerRuleData = [
      {value: 'default', label: intl('TrafficParameters.DefaultServicesPerRule', {count: 200})},
      {value: 1, label: intl.num(1)},
      {value: 5, label: intl.num(5)},
      {value: 10, label: intl.num(10)},
      {value: 20, label: intl.num(20)},
      {value: 40, label: intl.num(40)},
      {value: 50, label: intl.num(50)},
      {value: 100, label: intl.num(100)},
      {value: 200, label: intl.num(200)},
      {value: 300, label: intl.num(300)},
      {value: 400, label: intl.num(400)},
      {value: 500, label: intl.num(500)},
      {value: 1000, label: intl.num(1000)},
      {value: 5000, label: intl.num(5000)},
    ];

    const linksForRulesData = [
      {value: 'default', label: intl('TrafficParameters.DefaultLinksForRules', {count: 10_000})},
      {value: 1, label: intl.num(1)},
      {value: 100, label: intl.num(100)},
      {value: 1000, label: intl.num(1000)},
      {value: 10_000, label: intl.num(10_000)},
      {value: 20_000, label: intl.num(20_000)},
      {value: 30_000, label: intl.num(30_000)},
      {value: 40_000, label: intl.num(40_000)},
      {value: 50_000, label: intl.num(50_000)},
      {value: 100_000, label: intl.num(100_000)},
    ];

    return (
      <div className="TrafficParams">
        <ToolBar>
          <ToolGroup>
            <Button autoFocus={true} text={intl('Common.Save')} onClick={this.handleSave} />
            <Button
              autoFocus={true}
              text={intl('TrafficParameters.ResetPositions')}
              onClick={this.handleClearPositions}
            />
          </ToolGroup>
        </ToolBar>
        <Form>
          <Fieldset title={intl('TrafficParameters.ConfigTraffic')}>
            <Field
              type="select"
              label={intl('TrafficParameters.DnsIplistMapping')}
              options={dnsIplistMappingData}
              selected={this.state.dnsIplistMapping}
              onSelect={this.handleSelectDnsIpListMapping}
              tid="dns-iplist-mapping"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.ShowExplorerConnectionState')}
              options={showConnectionStateData}
              selected={this.state.showConnectionState}
              onSelect={this.handleSelectShowConnectionState}
              tid="show-connection-state"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.ExplorerServices')}
              options={showExplorerServicesData}
              selected={this.state.showExplorerServices}
              onSelect={this.handleSelectShowExplorerServices}
              tid="show-explorer-services"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxPortsPerLink')}
              options={selectPortData}
              selected={this.state.ports}
              onSelect={this.handleSelectPorts}
              tid="max-ports-per-link"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxOverlappingIP')}
              options={overlappingIplistsData}
              selected={this.state.overlappingIplists}
              onSelect={this.handleSelectOverlappingIpLists}
              tid="max-overlapping-iplists"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxAddressesPerLink')}
              options={addressesPerIpLinkData}
              selected={this.state.addressesPerIpLink}
              onSelect={this.handleSelectAddressesPerIpLink}
              tid="max-addresses-per-ip-link"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxExplorerLinksForDeepRules')}
              options={linksForRulesData}
              selected={this.state.maxLinksForRules}
              onSelect={this.handleSelectMaxLinksForRules}
              tid="max-links-for-rules"
            />
          </Fieldset>
          <Fieldset title="Configurable Illumination Workload Thresholds">
            <Field
              type="select"
              label={intl('TrafficParameters.MaxWorkloads')}
              options={maxWorkloadThreshold}
              selected={this.state.maxWorkloadThreshold}
              onSelect={this.handleSelectMaxWorkloadThreshold}
              tid="max-ports-per-link"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.ViewLevel')}
              options={levelData}
              selected={this.state.level}
              onSelect={this.handleSelectLevel}
              tid="view-level"
            />
            {this.state.level === 'custom' ? (
              <div>
                <Field
                  type="select"
                  label={intl('TrafficParameters.LocationThreshold')}
                  options={locationViewData}
                  selected={this.state.locationView}
                  onSelect={this.handleSelectLocationView}
                  tid="location-view-threshold"
                />
              </div>
            ) : null}
            <Field
              type="select"
              label={intl('TrafficParameters.FreshData')}
              options={rebuildAlwaysData}
              selected={this.state.rebuildAlways}
              onSelect={this.handleSelectRebuildAlways}
              tid="rebuild-always"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.StaleData')}
              options={rebuildOnDemandData}
              selected={this.state.rebuildOnDemand}
              onSelect={this.handleSelectRebuildOnDemand}
              tid="rebuild-on-demand"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxAppGroupsForRuleCoverage')}
              options={loadRuleCoverageData}
              selected={this.state.loadRuleCoverage}
              onSelect={this.handleLoadRuleCoverage}
              tid="load-rule-coverage"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxLinksForConnectedAppGroupRuleCoverage')}
              options={connectedAppGroupRulesData}
              selected={this.state.connectedAppGroupRules}
              onSelect={this.handleSelectConnectedAppGroupRules}
              tid="rebuild-workloads"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxNodesBeforeTruncation')}
              options={truncationLimitData}
              selected={this.state.truncationLimit}
              onSelect={this.handleSelectTruncationLimit}
              tid="truncation-limit"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.AlwaysRebuildWorkloads')}
              options={rebuildWorkloadData}
              selected={this.state.rebuildWorkloads}
              onSelect={this.handleSelectRebuildWorkloads}
              tid="rebuild-workloads"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.CollapsedThreshold')}
              options={roleCollapseData}
              selected={this.state.roleCollapse}
              onSelect={this.handleSelectRoleCollapse}
              tid="collapsed-role-threshold"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxWorkloadsPerRole')}
              options={roleExpandData}
              selected={this.state.roleExpand}
              onSelect={this.handleSelectRoleExpand}
              tid="max-workloads-expanded-per-role"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxWorkloadsPerGroup')}
              options={groupExpandData}
              selected={this.state.groupExpand}
              onSelect={this.handleSelectGroupExpand}
              tid="max-workloads-expanded-per-group"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaximumRolesExpandable')}
              options={maxExpandedRolesData}
              selected={this.state.maximumExpandedRoles}
              onSelect={this.handleSelectMaximumExpandedRoles}
              tid="max-roles-expandable"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxClustersForLinks')}
              options={maxClustersForLinksData}
              selected={this.state.maxClustersForLinks}
              onSelect={this.handleSelectMaxClustersForLinks}
              tid="max-clusters-for-links"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.LightGreenThreshold')}
              options={greenData}
              selected={this.state.green}
              onSelect={this.handleSelectGreen}
              tid="light-green-line-threshold"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxClustersPerLocation')}
              options={maxClustersPerLocationData}
              selected={this.state.maxClustersPerLocation}
              onSelect={this.handleSelectMaxClustersPerLocation}
              tid="max-clusters-per-location"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.MaxLocations')}
              options={maxLocationsData}
              selected={this.state.maxLocations}
              onSelect={this.handleSelectMaxLocations}
              tid="max-locations"
            />
            <Field
              type="select"
              label={intl('TrafficParameters.AddTimeToTimestamp')}
              options={addToTimeData}
              selected={this.state.addToTime}
              onSelect={this.handleSelectAddToTime}
              tid="add-time-to-timestamp"
            />
          </Fieldset>
          <Fieldset title="Configurable Policy Generator Thresholds">
            <Field
              type="select"
              label={intl('TrafficParameters.MaxServicesPerRule')}
              options={servicesPerRuleData}
              selected={this.state.servicesPerRule}
              onSelect={this.handleSelectServicesPerRule}
              tid="services-per-rule"
            />
          </Fieldset>
        </Form>
      </div>
    );
  },
});
