/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {RestApiUtils} from '../utils';
import React, {PropTypes} from 'react';
import StoreMixin from '../mixins/StoreMixin';
import ObjectSelector from './ObjectSelector.jsx';
import {FilterStore, LabelsAutocompleteStore} from '../stores';
import actionCreators from '../actions/actionCreators';
import {getSessionUri, getInstanceUri} from '../lib/api';

const facetMap = () => ({
  [intl('Common.Role')]: 'labels-role',
  [intl('Common.Application')]: 'labels-app',
  [intl('Common.Environment')]: 'labels-env',
  [intl('Common.Location')]: 'labels-loc',
});

const unlabeledMap = () => ({
  [intl('Common.Role')]: {label: intl('Common.NoRoleLabel'), value: intl('Common.NoRoleLabel')},
  [intl('Common.Application')]: {label: intl('Common.NoApplicationLabel'), value: intl('Common.NoApplicationLabel')},
  [intl('Common.Environment')]: {label: intl('Common.NoEnvironmentLabel'), value: intl('Common.NoEnvironmentLabel')},
  [intl('Common.Location')]: {label: intl('Common.NoLocationLabel'), value: intl('Common.NoLocationLabel')},
});

const allLabelsMap = () => ({
  [intl('Common.Role')]: {label: intl('Common.AllRoleLabels'), value: intl('Common.All')},
  [intl('Common.Application')]: {label: intl('Common.AllApplicationLabels'), value: intl('Common.All')},
  [intl('Common.Environment')]: {label: intl('Common.AllEnvironmentLabels'), value: intl('Common.All')},
  [intl('Common.Location')]: {label: intl('Common.AllLocationLabels'), value: intl('Common.All')},
});

const reverseFacetMap = () => ({
  role: intl('Common.Role'),
  app: intl('Common.Application'),
  env: intl('Common.Environment'),
  loc: intl('Common.Location'),
});

function getStateFromStores() {
  const filters = getFilters(this.props);

  return {
    filters,
    dropdownValues: LabelsAutocompleteStore.getDropdownValues(),
  };
}

function getFilters(props) {
  let filters = FilterStore.getScopeFilters();

  if (props.allowUnlabeled || props.allowAll) {
    filters = props.noRole ? FilterStore.getAllScopeFiltersNoRole() : FilterStore.getAllScopeFilters();
  }

  if (props.noRole) {
    filters = filters.filter(filter => filter.key !== 'role');
  }

  if (props.appEnvOnly) {
    filters = filters.filter(filter => filter.key !== 'role' && filter.key !== 'loc');
  }

  return filters;
}

export default React.createClass({
  propTypes: {
    allowUnlabeled: PropTypes.bool,
    allowAll: PropTypes.bool,
    noRole: PropTypes.bool,
    appEnvOnly: PropTypes.bool,
  },

  mixins: [StoreMixin([FilterStore, LabelsAutocompleteStore], getStateFromStores)],

  getInitialState() {
    return {
      items: {},
      open: Boolean(getFilters(this.props).length),
      dropdown: false,
    };
  },

  componentDidUpdate() {
    if (this.input && !this.state.open && this.input.refs.itemInput) {
      this.input.refs.itemInput.blur();
    }
  },

  componentDidMount() {
    this.setState({items: this.getItems(this.state.filters)});
  },

  componentWillReceiveProps(nextProps) {
    const filters = getFilters(nextProps);

    this.setState({
      filters,
      items:
        !_.isEmpty(this.state.items) && _.isEmpty(this.getItems(filters)) ? this.state.items : this.getItems(filters),
    });
  },

  getCustomClass(value) {
    let additionalClass = '';

    if (value) {
      additionalClass = `ObjectSelector-item--label--${value.key}`;
    }

    return additionalClass;
  },

  getFacetValues(key, query = '', maxResults = 25) {
    if (!key) {
      return;
    }

    if (this.state.dropdownValues[`${key}-${query}`]) {
      return;
    }

    RestApiUtils.labels.autocomplete(undefined, {
      key: key.split('-').pop(),
      query,
      max_results: maxResults,
      // This is only used for Illumination and App Group List today, so we can hardcode this
      resource_type: 'workloads',
    });
  },

  getItems(filters) {
    const items = {};

    if (filters && filters.length) {
      filters.forEach(filter => {
        if (filter.partial && this.props.allowUnlabeled) {
          items[unlabeledMap()[reverseFacetMap()[filter.key]].label] = {
            ...filter,
            value: unlabeledMap()[reverseFacetMap()[filter.key]].value,
          };
        } else if (filter.partial && this.props.allowAll) {
          items[allLabelsMap()[reverseFacetMap()[filter.key]].label] = {
            ...filter,
            value: allLabelsMap()[reverseFacetMap()[filter.key]].value,
          };
        } else {
          items[reverseFacetMap()[filter.key]] = filter;
        }
      });
    }

    return items;
  },

  addItem(filter, value) {
    const items = _.cloneDeep(this.state.items);

    items[filter] = value;

    if (value) {
      // when user add filters, need remove command panel
      actionCreators.unselectComponent();
      actionCreators.addFilters(value);
    }

    this.setState({items});
  },

  async removeItem(filter) {
    const items = _.cloneDeep(this.state.items);
    const removeItem = items[filter];

    delete items[filter];
    // Wait for set state to complete before updating the store
    // We need the state to be up-to-date in WillRecieveProps
    await new Promise(resolve => {
      this.setState({items}, resolve);
    });

    if (removeItem) {
      actionCreators.removeFilter(removeItem);
    }
  },

  handleFocus() {
    if (this.input && !this.state.open) {
      this.input.refs.itemInput.click();
    }

    this.setState({open: true, dropdown: true});
  },

  handleBlur(dropdown) {
    if (!dropdown && _.isEmpty(this.state.items)) {
      this.setState({open: false, dropdown: false});
    } else {
      this.setState({dropdown});
    }
  },

  render() {
    const {addItem, getFacetValues, removeItem, getCustomClass} = this;
    const {open, dropdown, items, dropdownValues} = this.state;
    const initialValues = Object.keys(facetMap());
    const props = {
      customClassItems: initialValues,
      getCustomClass,
      dropdownValues,
      items,
      facetMap: facetMap(),
      initialValues,
      addItem,
      getFacetValues,
      placeholder: intl('Labels.SelectToFilterViews'),
      removeItem,
      showFacetItems: [],
      returnValue: item => item.value,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onClose: this.handleBlur,
      ref: node => (this.input = node),
      tid: 'scope-selector',
    };

    if (this.props.noRole && !this.props.appEnvOnly) {
      props.initialValues = props.initialValues.slice(1);
    }

    if (this.props.appEnvOnly) {
      props.initialValues = props.initialValues.slice(1, 3);
    }

    if (this.props.allowUnlabeled || this.props.allowAll) {
      const hrefPrefix = getSessionUri(getInstanceUri('labels'), {label_id: ''}).slice(0, -1);
      const singleValues = {};

      // For all the label types
      [...props.initialValues].forEach(facet => {
        let map;
        const key = facetMap()[facet].split('labels-').pop();

        // Pick a map
        if (this.props.allowUnlabeled) {
          map = unlabeledMap()[facet];
        } else if (this.props.allowAll) {
          map = allLabelsMap()[facet];
        }

        // If this label type is not already in the scope, add the non-existing label
        if (!this.state.items[map.label] && !this.state.items[facet]) {
          singleValues[map.label] = {
            key,
            value: map.value,
            partial: true,
            href: `${hrefPrefix}?key=${key}&exists=false`,
          };
        }

        // If this non-label type is in the scope, remove the label type from the initialValues
        if (this.state.items[unlabeledMap()[facet].label] || this.state.items[allLabelsMap()[facet].label]) {
          props.initialValues.splice(props.initialValues.indexOf(facet), 1);
        }
      });

      props.customClassItems = props.customClassItems.concat(Object.keys(singleValues));
      props.singleValues = singleValues;
    }

    return (
      <div className={`ScopeSelector${open ? '' : ' ScopeSelector--Close'}${dropdown ? '' : ' ScopeSelector--Fade'}`}>
        <ObjectSelector {...props} />
      </div>
    );
  },
});
