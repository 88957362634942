/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import Constants from '../constants';
import {RestApiUtils, GridDataUtils} from '../utils';
import actionCreators from '../actions/actionCreators';
import {Dialog, Radio, RadioGroup, SpinnerOverlay} from '../components';

export default React.createClass({
  propTypes: {
    workloads: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string,
  },

  getInitialState() {
    return {radioValue: 'saved'};
  },

  handleCancel() {
    actionCreators.closeDialog();
  },

  async handleConfirm() {
    const workloads = this.props.workloads;
    const managedWorkloads = workloads.filter(workload => workload.agent.status).map(({href}) => ({href}));
    const unmanagedWorkloads = workloads
      .filter(workload => !workload.agent.status)
      .map(workload => GridDataUtils.getIdFromHref(workload.href));

    actionCreators.closeDialog();

    try {
      await Promise.all([
        managedWorkloads.length
          ? RestApiUtils.workloads.unpair({
              ip_table_restore: this.state.radioValue,
              workloads: managedWorkloads,
            })
          : null,
        unmanagedWorkloads.length ? RestApiUtils.workloads.delete(unmanagedWorkloads) : null,
      ]);
    } catch (err) {
      console.log(err);
    }

    this.props.onRemove();
  },

  handleRadioSelect(evt) {
    this.setState({
      radioValue: evt.target.value,
    });
  },

  render() {
    const kerberosPKI = [];
    const pairingKey = [];
    const unmanaged = [];
    const idle = [];

    this.props.workloads.forEach(workload => {
      if (workload.agent.status) {
        const creator = _.get(workload, 'created_by.href');
        const mode = _.get(workload, 'agent.config.mode');

        if (mode === 'idle') {
          idle.push(workload);
        } else if (creator.includes('agents')) {
          pairingKey.push(workload);
        } else if (creator.includes('users')) {
          kerberosPKI.push(workload);
        }
      } else {
        unmanaged.push(workload);
      }
    });

    const content = (
      <div className="Content-section">
        <div className="Content-section-selected-count">
          {intl('Workloads.RemoveDialog.Content.SelectedCount', {selected: this.props.workloads.length})}
        </div>
        <div className="Content-section-table">
          <div className="Content-section-rowHeader">
            <div className="Content-section-number">#</div>
            <div className="Content-section-type">{intl('Workloads.RemoveDialog.Content.Type.Header')}</div>
            <div className="Content-section-action">{intl('Workloads.Unpair.Actions.Header')}</div>
          </div>
          {unmanaged.length > 0 && (
            <div className="Content-section-row">
              <div className="Content-section-number">{unmanaged.length}</div>
              <div className="Content-section-type">{intl('Common.Unmanaged')}</div>
              <div className="Content-section-action">{intl('Workloads.RemoveDialog.Content.Actions.Unmanaged')}</div>
            </div>
          )}
          {kerberosPKI.length > 0 && (
            <div className="Content-section-row">
              <div className="Content-section-number">{kerberosPKI.length}</div>
              <div className="Content-section-type">{intl('Workloads.Unpair.Type.KerberosPKI')}</div>
              <div className="Content-section-action">{intl('Workloads.RemoveDialog.Content.Actions.KerberosPKI')}</div>
            </div>
          )}
          {pairingKey.length > 0 && (
            <div className="Content-section-row">
              <div className="Content-section-number">{pairingKey.length}</div>
              <div className="Content-section-type">{intl('Workloads.Unpair.Type.PairingKey')}</div>
              <div className="Content-section-action">{intl('Workloads.RemoveDialog.Content.Actions.PairingKey')}</div>
            </div>
          )}
          {idle.length > 0 && (
            <div className="Content-section-row">
              <div className="Content-section-number">{idle.length}</div>
              <div className="Content-section-type">{intl('Common.Idle')}</div>
              <div className="Content-section-action">{intl('Workloads.RemoveDialog.Content.Actions.Idle')}</div>
            </div>
          )}
        </div>
      </div>
    );

    const options = (
      <div>
        <div className="Option-section-title">
          <div className="Option-section-title-count">
            {intl('Workloads.RemoveDialog.Content.SelectedUninstall', {
              selected: kerberosPKI.length + pairingKey.length,
            })}
          </div>
          <div>{intl('Workloads.RemoveDialog.Content.SelectFirewallState')}</div>
        </div>

        <RadioGroup value={this.state.radioValue}>
          <Radio
            tid="saved"
            value="saved"
            onChange={this.handleRadioSelect}
            checked={this.state.radioValue === 'saved'}
            label={intl('Workloads.RemoveDialog.Content.Options.First.Title')}
          >
            <div className="Option-description-row">
              <div className="Option-description-os">
                {intl('Workloads.Unpair.Options.OperatingSystems.LinuxAIXSolaris')}
              </div>
              <div className="Option-description-message">{intl('Workloads.Unpair.Options.First.LinuxDesc')}</div>
            </div>

            <div className="Option-description-row">
              <div className="Option-description-os">{intl('Workloads.Unpair.Options.OperatingSystems.Windows')}</div>
              <div className="Option-description-message">{intl('Workloads.Unpair.Options.First.WindowsDesc')}</div>
            </div>
          </Radio>

          <Radio
            tid="disable"
            value="disable"
            onChange={this.handleRadioSelect}
            checked={this.state.radioValue === 'disable'}
            label={intl('Workloads.Unpair.Options.Second.Title')}
          >
            <div className="Option-description-row">
              <div className="Option-description-os">{intl('Workloads.Unpair.Options.OperatingSystems.All')}</div>
              <div className="Option-description-message">{intl('Workloads.Unpair.Options.Second.Desc')}</div>
            </div>
          </Radio>

          <Radio
            tid="default"
            value="default"
            onChange={this.handleRadioSelect}
            checked={this.state.radioValue === 'default'}
            label={intl('Workloads.Unpair.Options.Third.Title')}
          >
            <div className="Option-description-row">
              <div className="Option-description-os">
                {intl('Workloads.Unpair.Options.OperatingSystems.LinuxAIXSolaris')}
              </div>
              <div className="Option-description-message">{intl('Workloads.Unpair.Options.Third.LinuxDesc')}</div>
            </div>
            <div className="Option-description-row">
              <div className="Option-description-os">{intl('Workloads.Unpair.Options.OperatingSystems.Windows')}</div>
              <div className="Option-description-message">{intl('Workloads.Unpair.Options.Third.WindowsDesc')}</div>
            </div>
          </Radio>
        </RadioGroup>
      </div>
    );

    const showOptions = kerberosPKI.length > 0 || pairingKey.length > 0;

    const actions = [
      {
        text: intl('Common.Cancel'),
        onClick: this.handleCancel,
        type: 'nofill',
        tid: 'cancel',
      },
      {
        text: intl('Workloads.RemoveDialog.RemoveNow'),
        onClick: this.handleConfirm,
        tid: 'confirm',
      },
    ];

    const dialogTitle = this.props.dialogTitle || intl('Workloads.RemoveDialog.Title');

    return (
      <div className="RemoveWorkloadsDialog">
        <Dialog title={dialogTitle} actions={actions} tid="unpair">
          {this.state.status === Constants.STATUS_BUSY ? <SpinnerOverlay /> : null}
          {content}
          {showOptions && options}
        </Dialog>
      </div>
    );
  },
});
