/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import {ComponentUtils} from '../../utils';
import {Checkbox} from '.';

const defaultTid = 'comp-checkboxgroup';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    autoFocus: PropTypes.bool,
    tabIndex: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.array,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    display: PropTypes.oneOf(['horizontal', 'vertical']),
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
  },

  getDefaultProps() {
    return {
      value: [],
      display: 'vertical',
      onFocus: _.noop,
      onBlur: _.noop,
      onChange: _.noop,
    };
  },

  handleChange(value, evt) {
    const newValue = this.props.value.filter(item => item !== value);

    if (newValue.length === this.props.value.length) {
      newValue.push(value);
    }

    this.props.onChange(newValue, evt);
  },

  render() {
    const classes = cx({
      'ReForm-CheckboxGroup': true,
      'ReForm-CheckboxGroup--error': this.props.error,
      'ReForm-CheckboxGroup--disabled': this.props.disabled,
      'ReForm-CheckboxGroup--vertical': this.props.display === 'vertical',
      'ReForm-CheckboxGroup--horizontal': this.props.display === 'horizontal',
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)}>
        {this.props.options.map((option, index) => (
          <span className="ReForm-CheckboxGroup-Option" key={option.value}>
            <Checkbox
              tid={this.props.tid}
              ref={`option${index}`}
              autoFocus={this.props.autoFocus && index === 0}
              tabIndex={this.props.tabIndex}
              label={option.label}
              sublabel={option.sublabel}
              value={option.value}
              checked={this.props.value.includes(option.value)}
              disabled={this.props.disabled || option.disabled}
              error={this.props.error || option.error}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.props.onKeyDown}
              onKeyUp={this.props.onKeyUp}
            />
          </span>
        ))}
      </div>
    );
  },
});
