/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

const dnsReverseLookup = {};
let isLoaded = false;

function setNameFromIPAddress(data) {
  for (const {ip, names} of data) {
    dnsReverseLookup[ip] = (Array.isArray(names) && names[0]) || '';
  }
}

export default createApiStore(['DNS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.DNS_REVERSE_LOOKUP_CREATE_SUCCESS:
        setNameFromIPAddress(action.data);
        isLoaded = true;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAllIPAddresses() {
    return dnsReverseLookup;
  },

  getSpecifiedDNSName(address) {
    return dnsReverseLookup[address] || '';
  },

  isLoaded() {
    return isLoaded;
  },
});
