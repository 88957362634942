/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';

let workloads = [];
let report;
const workloadMap = {};
const count = {};
let isLoaded = false;

function setReport(data) {
  report = data;
}

function setWorkloads(data) {
  // put label keys on workload object
  workloads = data;
  workloads.forEach(workload => (workloadMap[workload.href] = workload));
}

function setCount(data) {
  Object.assign(count, data);
}

export default createApiStore(['WORKLOADS_GET_COLLECTION_', 'WORKLOADS_GET_INSTANCE_', 'VENS_COMPATIBILITY_REPORT_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.WORKLOADS_GET_COLLECTION_SUCCESS:
        // Don't save the workloads, if we only called the collection to get the counts
        if (!action.options.query || !action.options.query.max_results || action.options.query.max_results > 1) {
          setWorkloads(action.data);
          isLoaded = true;
        }

        setCount(action.count);
        break;

      case Constants.WORKLOADS_GET_INSTANCE_SUCCESS:
        workloadMap[action.data.href] = action.data;
        StoreUtils.setObject(action.data, workloads);
        break;

      case Constants.VEN_COMPATIBILITY_REPORT_SUCCESS:
        setReport(action.data);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return workloads;
  },

  getCount() {
    return count;
  },

  getSpecified(href) {
    return workloadMap[href];
  },

  getWorkloadFromAgent(agent) {
    return workloads.find(workload => workload.agent.href === agent);
  },

  getReport() {
    return report;
  },

  isLoaded() {
    return isLoaded;
  },
});
