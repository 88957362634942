/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import React, {PropTypes} from 'react';
import Dialog from './Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  propTypes: {
    title: PropTypes.any,
    tid: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]).isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    className: PropTypes.string,
  },

  getDefaultProps() {
    return {
      title: intl('Common.AreYouSure'),
      cancelLabel: intl('Common.Cancel'),
      confirmLabel: intl('Common.OK'),
    };
  },

  getActions() {
    return [
      {
        text: this.props.cancelLabel,
        tid: 'cancel',
        type: 'nofill',
        content: 'both',
        onClick: () => {
          this.handleClose();

          if (_.isFunction(this.props.onCancel)) {
            this.props.onCancel();
          }
        },
      },
      {
        text: this.props.confirmLabel,
        tid: 'ok',
        autoFocus: true,
        content: 'both',
        onClick: () => {
          this.handleClose();

          if (_.isFunction(this.props.onConfirm)) {
            this.props.onConfirm();
          }
        },
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    const messages = (Array.isArray(this.props.message) ? this.props.message : [this.props.message]).map(
      (message, index) => {
        if (React.isValidElement(message)) {
          return message;
        }

        let type;
        let text;

        if (_.isString(message)) {
          text = message;
        } else if (_.isPlainObject(message)) {
          type = message.type;
          text = message.text;
        }

        const classes = cx('Dialog-confirmation-message', {
          'Dialog-confirmation-message--success': type === 'success',
          'Dialog-confirmation-message--info': type === 'info',
          'Dialog-confirmation-message--warning': type === 'warning',
          'Dialog-confirmation-message--danger': type === 'danger',
        });

        return (
          <p className={classes} key={index}>
            {text}
          </p>
        );
      },
    );

    return (
      <Dialog
        ref="dialog"
        tid={this.props.tid}
        confirmation={true}
        title={this.props.title}
        actions={this.getActions()}
        className={this.props.className}
      >
        {messages}
      </Dialog>
    );
  },
});
