/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
export default {
  hoverFilter(selection) {
    // Selection has to be def element
    const filter = selection
      .append('filter')
      .attr('id', 'hoverFilter')
      .attr('x', '-50%')
      .attr('y', '-50%')
      .attr('width', '200%')
      .attr('height', '200%');

    filter.append('feOffset').attr('result', 'offOutHover').attr('in', 'SourceGraphic').attr('dx', 0).attr('dy', 0);

    filter
      .append('feMorphology')
      .attr('result', 'dilateOutHover')
      .attr('in', 'offOutHover')
      .attr('operator', 'dilate')
      .attr('radius', '3.0');

    const feComponentTransfer = filter
      .append('feComponentTransfer')
      .attr('result', 'transferOutHover')
      .attr('in', 'dilateOutHover');

    feComponentTransfer.append('feFuncR').attr('type', 'discrete').attr('tableValues', '0.21 0.21 0.21 0.21');
    feComponentTransfer.append('feFuncG').attr('type', 'discrete').attr('tableValues', '0.8 0.8 0.8 0.8');
    feComponentTransfer.append('feFuncB').attr('type', 'discrete').attr('tableValues', '0.97 0.97 0.97 0.97');
    feComponentTransfer.append('feFuncA').attr('type', 'discrete').attr('tableValues', '0.0 0.6');

    filter.append('feBlend').attr('in', 'SourceGraphic').attr('in2', 'transferOutHover').attr('mode', 'normal');
  },

  selectFilter(selection) {
    const filter = selection
      .append('filter')
      .attr('id', 'selectFilter')
      .attr('x', '-50%')
      .attr('y', '-50%')
      .attr('width', '200%')
      .attr('height', '200%');

    filter.append('feOffset').attr('result', 'offOutSelect').attr('in', 'SourceGraphic').attr('dx', 0).attr('dy', 0);

    filter
      .append('feMorphology')
      .attr('result', 'dilateOutSelect')
      .attr('in', 'offOutSelect')
      .attr('operator', 'dilate')
      .attr('radius', '3.0');

    const feComponentTransfer = filter
      .append('feComponentTransfer')
      .attr('result', 'transferOutSelect')
      .attr('in', 'dilateOutSelect');

    feComponentTransfer.append('feFuncR').attr('type', 'discrete').attr('tableValues', '0.21 0.21 0.21 0.21');
    feComponentTransfer.append('feFuncG').attr('type', 'discrete').attr('tableValues', '0.8 0.8 0.8 0.8');
    feComponentTransfer.append('feFuncB').attr('type', 'discrete').attr('tableValues', '0.97 0.97 0.97 0.97');
    feComponentTransfer.append('feFuncA').attr('type', 'table').attr('tableValues', '0.0 0.6');

    filter.append('feBlend').attr('in', 'SourceGraphic').attr('in2', 'transferOutSelect').attr('mode', 'normal');
  },

  linkGradient1(selection) {
    const lineGradient = selection
      .append('linearGradient')
      .attr('id', 'lineGradient1')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%');

    lineGradient.append('stop').attr('offset', '0%').attr('stop-color', '#e0e0e0');

    lineGradient.append('stop').attr('offset', '100%').attr('stop-color', '#808080');
  },

  linkGradient2(selection) {
    const lineGradient = selection
      .append('linearGradient')
      .attr('id', 'lineGradient2')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%');

    lineGradient.append('stop').attr('offset', '0%').attr('stop-color', '#808080');

    lineGradient.append('stop').attr('offset', '100%').attr('stop-color', '#e0e0e0');
  },

  linkGradient3(selection) {
    const lineGradient = selection
      .append('linearGradient')
      .attr('id', 'lineGradient3')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');

    lineGradient.append('stop').attr('offset', '0%').attr('stop-color', '#e0e0e0');

    lineGradient.append('stop').attr('offset', '100%').attr('stop-color', '#808080');
  },

  linkGradient4(selection) {
    const lineGradient = selection
      .append('linearGradient')
      .attr('id', 'lineGradient4')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%');

    lineGradient.append('stop').attr('offset', '0%').attr('stop-color', '#e0e0e0');

    lineGradient.append('stop').attr('offset', '100%').attr('stop-color', '#808080');
  },
};
