/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../actions/actionCreators';
import Dialog from '../components/Dialog.jsx';

export default React.createClass({
  getActions() {
    return [
      {
        text: intl('Common.OK'),
        tid: 'ok',
        ref: 'confirmButton',
        onClick: this.handleClose,
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    return (
      <Dialog
        ref="dialog"
        className="RuleBuilderAddresses"
        type="alert"
        alert={true}
        actions={this.getActions()}
        title={this.props.title ? this.props.title : intl('PolicyGenerator.Addresses')}
      >
        <div className="PolicyGeneratorGrid-port-protocol-list">
          {this.props.addresses.map(address => (
            <div className="PolicyGeneratorGrid-port-protocol-item">{address}</div>
          ))}
        </div>
      </Dialog>
    );
  },
});
