/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';
import _ from 'lodash';

let trafficEvents = [];

function setTrafficEvents(data) {
  trafficEvents = data;
}

function getTrafficEvent(href) {
  return _.find(trafficEvents, event => event.href === href);
}

export default createApiStore(['TRAFFIC_EVENTS_', 'TRAFFIC_EVENTS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.TRAFFIC_EVENTS_GET_COLLECTION_SUCCESS:
        setTrafficEvents(action.data);
        break;

      case Constants.TRAFFIC_EVENTS_GET_INSTANCE_SUCCESS:
        StoreUtils.setObject(action.data, trafficEvents);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getAll() {
    return trafficEvents;
  },

  getSpecified(href) {
    return href ? getTrafficEvent(href) : null;
  },
});
