/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

import {createStore} from '../lib/store';
import Constants from '../constants';
import dispatcher from '../actions/dispatcher';
import TrafficStore from './TrafficStore';

let menu = {
  data: null,
  type: null,
  location: null,
  collapse: null,
};

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SHOW_MAP_MENU:
        menu = action.data;
        break;

      case Constants.HIDE_MAP_MENU:
        menu = {
          data: null,
          type: null,
          location: null,
          collapse: null,
        };
        break;

      case Constants.SEC_POLICY_RULE_COVERAGE_CREATE_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getMenu() {
    return menu;
  },
});
