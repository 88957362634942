/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import {MultilineInput} from '.';

export default React.createClass({
  propTypes: {
    values: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  },

  parseRules(ruleString) {
    const value = {text: ruleString};

    if (ruleString.length === 0) {
      return value;
    }

    const parts = ruleString.split(' ');

    if (parts.length < 5) {
      value.error = 'Invalid format';
    } else {
      const tableFlag = parts.shift();

      if (tableFlag !== '-t') {
        value.error = 'Invalid format';
      }

      value.table_name = parts.shift();

      const chainFlag = parts.shift();

      if (chainFlag !== '-A') {
        value.error = 'Invalid format';
      }

      value.chain_name = parts.shift();

      const bannedParameters = [
        '-t',
        '--table',
        '-A',
        '--append',
        '-C',
        '--check',
        '-D',
        '--delete',
        '-I',
        '--insert',
        '-R',
        '--replace',
        '-L',
        '--list',
        '-F',
        '--flush',
        '-Z',
        '--zero',
        '-N',
        '--new-chain',
        '-X',
        '--delete-chain',
        '-P',
        '--policy',
        '-E',
        '--rename-chain',
        '-S',
        '--list-rules',
        '-h',
        '-g',
        '--goto',
        '-v',
        '--verbose',
        '-n',
        '--numeric',
        '-x',
        '--exact',
        '-c',
        '--set-counters',
        '-w',
        '--wait',
        '--line-numbers',
        '--modprobe',
        '-m set',
        '--match-set',
        '--add-set',
        '--del-set',
      ];

      if (_.some(parts, part => bannedParameters.includes(part))) {
        value.error = 'Invalid parameter';
      }

      const bannedChainNames = [
        'usr_nat_prerouting',
        'usr_nat_output',
        'usr_nat_postrouting',
        'mark_nat_t_in',
        'usr_mangle_input',
        'usr_mangle_output',
        'usr_mangle_forward',
        'usr_mangle_prerouting',
        'usr_mangle_postrouting',
        'pre_out',
        'other_allow_out',
        'tcp_allow_out',
        'test_out',
        'tcp_test_out',
        'other_test_out',
        'udp_allow_out',
        'drop_out',
        'tcp_drop_out',
        'udp_drop_out',
        'icmp_drop_out',
        'other_drop_out',
        'icmp_allow_out',
        'pre_in',
        'pkt_check_in',
        'tcp_check_in',
        'tcp_allow_in',
        'test_in',
        'tcp_test_in',
        'other_test_in',
        'udp_allow_in',
        'icmp_allow_in',
        'other_allow_in',
        'drop_in',
        'tcp_drop_in',
        'udp_drop_in',
        'icmp_drop_in',
        'other_drop_in',
        'ike_in',
        'nat_t_in',
        'ike_out',
        'nat_t_out',
        'usr_filter_input',
        'usr_filter_forward',
        'usr_filter_output',
      ];

      const bannedTokens = ['#', '|', '"', '`', '\\', ';', '$', '&', '{', '}', '[', ']'];

      value.parameters = parts.join(' ');

      if (value.parameters === '') {
        value.error = 'Invalid format';
      } else {
        if (_.some(bannedTokens, token => value.parameters.includes(token))) {
          value.error = 'Invalid character';
        }

        if (_.some(bannedChainNames, name => value.parameters.includes(name))) {
          value.error = 'Invalid chain name';
        }
      }

      const tableNames = [
        {name: 'mangle', chainNames: ['PREROUTING', 'INPUT', 'OUTPUT', 'FORWARD', 'POSTROUTING']},
        {name: 'nat', chainNames: ['PREROUTING', 'OUTPUT', 'POSTROUTING']},
        {name: 'filter', chainNames: ['INPUT', 'OUTPUT', 'FORWARD']},
      ];

      const tableNameObject = _.find(tableNames, tableObject => tableObject.name === value.table_name);

      if (!tableNameObject) {
        value.error = 'Invalid table name';
      } else if (!tableNameObject.chainNames.includes(value.chain_name)) {
        value.error = 'Invalid chain name';
      }
    }

    return value;
  },

  stringifyRules(value) {
    const result = [];

    if (value.table_name) {
      result.push(`-t ${value.table_name}`);
    }

    if (value.chain_name) {
      result.push(`-A ${value.chain_name}`);
    }

    if (value.parameters) {
      result.push(value.parameters);
    }

    return result.join(' ');
  },

  validateRules(values) {
    return values.map((value, index, array) => {
      if (value && value.text !== '') {
        if (
          !value.error &&
          _.some(
            array,
            (currentValue, currentIndex) =>
              index !== currentIndex &&
              !currentValue.removed &&
              !value.removed &&
              value.table_name === currentValue.table_name &&
              value.chain_name === currentValue.chain_name &&
              value.parameters === currentValue.parameters,
          )
        ) {
          value.duplicate = 'Overlapping Rules';
        } else {
          value.duplicate = null;
        }
      }

      return value;
    });
  },

  render() {
    return (
      <MultilineInput
        values={this.props.values.filter(value => !value.deleted)}
        onChange={this.props.onChange}
        parse={this.parseRules}
        stringify={this.stringifyRules}
        validate={this.validateRules}
        readonly={false}
        showDiff={true}
        name="rules"
        placeholder={intl('Rulesets.Rules.IpTables.Form.StatementsPlaceholder')}
      />
    );
  },
});
