/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';
import _ from 'lodash';

let userGroups = [];
const userGroupsMap = {};
let count = {};

let isLoaded = false;

function setUserGroups(data) {
  userGroups = data;
  data.forEach(i => (userGroupsMap[i.href] = i));
}

function setCount(data) {
  count = data;
}

export default createApiStore(['SECURITY_PRINCIPALS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SECURITY_PRINCIPALS_GET_COLLECTION_SUCCESS:
        setUserGroups(action.data);
        setCount(action.count);
        isLoaded = true;
        break;

      case Constants.SECURITY_PRINCIPALS_GET_INSTANCE_SUCCESS:
        userGroupsMap[action.data.href] = action.data;
        StoreUtils.setObject(action.data, userGroups);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getSpecified(href) {
    return userGroupsMap[href];
  },

  getAll() {
    return userGroups;
  },

  getNotDeleted() {
    return _.filter(userGroups, o => !o.deleted);
  },

  getCount() {
    return count;
  },

  isLoaded() {
    return isLoaded;
  },
});
