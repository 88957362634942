/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {WPFacetStore, FilterStore} from '../stores';
import StoreMixin from '../mixins/StoreMixin';
import {RestApiUtils} from '../utils';
import actionCreators from '../actions/actionCreators';
import {SpinnerOverlay} from '.';
import {DateTimePicker} from './DateTime';

function getStateFromStores() {
  return {
    items: FilterStore.getSelectorFilters('workloadList'),
    dropdownValues: WPFacetStore.getDropdownValues(),
  };
}

export default React.createClass({
  propTypes: {
    facetMap: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    hasStatic: PropTypes.bool.isRequired,
    lastProvisionedTime: PropTypes.instanceOf(Date).isRequired,
  },

  mixins: [StoreMixin([WPFacetStore, FilterStore], getStateFromStores)],

  // No getInitialState on purpose so that `this.state` can be used to verify RestApiUtils.firewallSettings is called or not

  async componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  },

  getDateFromValue(value) {
    // This function will only be called for 'last' value
    // All the other values ('anytime', 'now', 'custom') are default values
    // which are handled in DateTimePicker
    if (value === 'listenOnly') {
      return this.props.listenOnlyEnableTime;
    }

    return this.props.lastProvisionedTime;
  },

  getFacetValues(facet, query = '') {
    if (!facet || /security_policy_(applied|received)_at/.test(facet) || facet === 'last_heartbeat_on') {
      return;
    }

    if (!query.includes('*')) {
      RestApiUtils.workloads.facets({facet, query});
    }
  },

  handleAdd(filter, value) {
    actionCreators.addApiFilter({page: 'workloadList', filter, value});
  },

  handleClick(evt) {
    if (findDOMNode(this).contains(evt.target)) {
      return;
    }

    actionCreators.resetFacetValues('workloadList');
  },

  handleRemove(filter) {
    actionCreators.removeApiFilter({page: 'workloadList', filter});
  },

  returnValue(value, facet) {
    if (
      facet === intl('Workloads.PolicyLastApplied') ||
      facet === intl('Workloads.PolicyLastReceived') ||
      facet === intl('Workloads.LastHeartbeatReceived')
    ) {
      // Very temporary Language
      const from = value.from ? intl.date(new Date(value.from), 'l_HH_mm') : intl('DateTimeInput.Anytime');
      const to = value.to ? intl.date(new Date(value.to), 'l_HH_mm') : intl('DateTimeInput.Anytime');

      return `${intl('DateTimeInput.From')} ${from} ${intl('DateTimeInput.To')} ${to}`;
    }

    return value;
  },

  render() {
    const statics = {
      [intl('Policy.State')]: [intl('Common.Build'), intl('Common.Test'), intl('Common.Enforced'), intl('Common.Idle')],
      [intl('Workloads.PolicySync')]: [
        intl('Common.Error'),
        intl('Common.Warning'),
        intl('Common.Active'),
        intl('Workloads.Status.Suspended'),
      ],
      [intl('Workloads.VENConnectivity')]: [
        intl('Workloads.Status.Offline'),
        intl('Common.Online'),
        intl('Common.Unmanaged'),
      ],
    };
    let initialValues = Object.keys(this.props.facetMap);

    const commonValues = {
      [intl('DateTimeInput.LastPolicyProvisioned', {
        when: this.props.lastProvisionedTime,
      })]: 'last',
      [intl('DateTimeInput.CustomTime')]: 'custom',
    };

    if (this.props.listenOnlyEnableTime) {
      commonValues[
        intl('DateTimeInput.ListenOnlyEnableTime', {
          when: this.props.listenOnlyEnableTime,
        })
      ] = 'listenOnly';
      // Move the custom time back to the end of the list
      delete commonValues[intl('DateTimeInput.CustomTime')];
      commonValues[intl('DateTimeInput.CustomTime')] = 'custom';
    }

    const dateTimePickerProps = {
      onAdd: this.handleAdd,
      onRemove: this.handleRemove,
      fromSingleValues: {
        [intl('DateTimeInput.Anytime')]: 'anytime',
        ...commonValues,
      },
      toSingleValues: {
        [intl('DateTimeInput.Now')]: 'now',
        ...commonValues,
      },
      getDateFromValue: this.getDateFromValue,
    };

    const customValuesComp = {
      [intl('Workloads.PolicyLastApplied')]: (
        <DateTimePicker {...dateTimePickerProps} name={intl('Workloads.PolicyLastApplied')} />
      ),
      [intl('Workloads.LastHeartbeatReceived')]: (
        <DateTimePicker {...dateTimePickerProps} name={intl('Workloads.LastHeartbeatReceived')} />
      ),
    };

    if (this.props.hasStatic) {
      // Show relevant filters if the PCE has 'static' workloads.
      statics[intl('Policy.UpdateMode')] = [intl('Workloads.StaticWorkloads'), intl('Workloads.AdaptiveWorkloads')];
      statics[intl('Workloads.PolicySync')].push(intl('Common.Staged'));
      customValuesComp[intl('Workloads.PolicyLastReceived')] = (
        <DateTimePicker {...dateTimePickerProps} name={intl('Workloads.PolicyLastReceived')} />
      );
    } else {
      // Remove 'Policy Update Mode' and 'Policy Last Received' from filters
      initialValues = initialValues.filter(
        value => ![intl('Workloads.PolicyLastReceived'), intl('Policy.UpdateMode')].includes(value),
      );
    }

    const props = {
      addItem: this.handleAdd,
      dropdownValues: this.state.dropdownValues,
      facetMap: this.props.facetMap,
      getFacetValues: this.getFacetValues,
      hasTitle: [intl('Common.Description')],
      initialValues,
      items: this.state.items,
      onChange: this.props.onChange,
      partialItems: [
        intl('Common.Hostname'),
        intl('Common.Name'),
        intl('Common.IPAddress'),
        intl('Common.Description'),
        intl('Common.OSFamily'),
      ],
      placeholder: intl('Common.FilterView'),
      removeItem: this.handleRemove,
      returnValue: this.returnValue,
      statics,
      staticsKeys: Object.keys(statics),
      customValuesComp,
      customValuesKeys: Object.keys(customValuesComp),
      hasWildcard: [intl('Common.Hostname'), intl('Common.Name')],
      customClassItems: [
        intl('Workloads.PolicyLastApplied'),
        intl('Workloads.PolicyLastReceived'),
        intl('Workloads.LastHeartbeatReceived'),
      ],
      getCustomClass: () => 'ObjectSelector-item--DateTime',
    };

    return (
      <div>
        {this.state ? null : <SpinnerOverlay />}
        <ObjectSelector {...props} />
      </div>
    );
  },
});
