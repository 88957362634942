/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {spring} from 'react-motion';

export const dropdownVertical = {
  positions: {
    close: {y: 25, opacity: 0},
    open: {y: 0, opacity: 1},
  },
};

dropdownVertical.enter = dropdownVertical.positions.close;
dropdownVertical.open = {
  y: spring(dropdownVertical.positions.open.y, {stiffness: 1000, damping: 40, precision: 0.25}),
  opacity: spring(dropdownVertical.positions.open.opacity, {stiffness: 800, damping: 40, precision: 0.1}),
};
dropdownVertical.leave = {
  y: spring(dropdownVertical.positions.close.y, {stiffness: 800, damping: 40, precision: 0.5}),
  opacity: spring(dropdownVertical.positions.close.opacity, {stiffness: 700, damping: 40, precision: 0.1}),
};

export const dropdownHorizontal = {
  positions: {
    close: {x: 10, opacity: 0},
    open: {x: 0, opacity: 1},
  },
};

dropdownHorizontal.enter = y => ({y, ...dropdownHorizontal.positions.close});
dropdownHorizontal.open = y => ({
  y: spring(y, {stiffness: 700, damping: 40, precision: 0.5}),
  x: spring(dropdownHorizontal.positions.open.x, {stiffness: 800, damping: 40, precision: 0.5}),
  opacity: spring(dropdownHorizontal.positions.open.opacity, {stiffness: 800, damping: 40, precision: 0.1}),
});
dropdownHorizontal.leave = y => ({
  y,
  x: spring(dropdownHorizontal.positions.close.x, {stiffness: 800, damping: 40, precision: 0.5}),
  opacity: spring(dropdownHorizontal.positions.close.opacity, {stiffness: 800, damping: 40, precision: 0.1}),
});

// Items container and items list
export const items = {
  positions: {
    close: {y: -30, opacity: 0},
    open: {y: 0, opacity: 1},
  },
  config: {
    y: {stiffness: 700, damping: 40, precision: 0.5},
    size: {stiffness: 700, damping: 40, precision: 0.5},
    opacity: {stiffness: 700, damping: 40, precision: 0.1},
  },
};

items.containerSizes = ({width, height}) => ({
  width: spring(width, items.config.size),
  height: spring(height, items.config.size),
});
items.listEnter = dir => ({
  y: items.positions.close.y * dir,
  opacity: items.positions.close.opacity,
});
items.listActive = {
  y: spring(items.positions.open.y, items.config.y),
  opacity: spring(items.positions.open.opacity, items.config.opacity),
};
items.listLeave = dir => ({
  y: spring(items.positions.close.y * dir, items.config.y),
  opacity: spring(items.positions.close.opacity, items.config.opacity),
});
