/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import RestApiUtils from '../../utils/RestApiUtils';
import actionCreators from '../../actions/actionCreators';
import {GeneralStore, MapPageStore, ServiceStore} from '../../stores';
import {RenderUtils, ServiceUtils} from '../../utils';
import {Icon, Vulnerability, Spinner} from '..';
import {getStateFromStore as getTrafficPanelStateFromStore} from './TrafficPanel';
import StoreMixin from '../../mixins/StoreMixin';

const MAX_SERVICES = 256;

function getStateFromStore() {
  const {vulnerabilityView, policyVersion, servicesLoaded, sort} = getTrafficPanelStateFromStore();

  return {
    vulnerabilityView,
    policyVersion,
    servicesLoaded,
    sort,
  };
}

export default React.createClass({
  mixins: [StoreMixin([GeneralStore, MapPageStore, ServiceStore], getStateFromStore)],

  componentDidMount() {
    _.defer(() => {
      if (!this.state.servicesLoaded) {
        RestApiUtils.services.getCollection({max_results: 100_000});
      }
    });
  },

  handleSort(column) {
    const {key, direction} = this.state.sort;
    const sort = {key: column, direction: key === column ? !direction : true};

    actionCreators.updateGeneralSorting('trafficPanel', sort);
  },

  handleSelectService(formData) {
    actionCreators.clickActionItem({
      type: 'detailAction',
      formData,
    });
  },

  renderServices() {
    if (this.spinner) {
      return <Spinner size="twenty" />;
    }

    const {vulnerabilityView} = this.state;
    const services = RenderUtils.getSortedTrafficPanelServices(
      this.props.data.services,
      this.state.sort.key,
      this.state.sort.direction,
      this.state.vulnerabilityView,
    );

    return (
      services &&
      _.map(services.slice(0, MAX_SERVICES), (service, index) => {
        const serviceName = service.matchedServices.length ? (
          service.name
        ) : (
          <span className="MapSubInfoPanel-serviceName">{service.name}</span>
        );
        const serviceClasses = cx({
          'MapSubInfoPanel-Row': true,
          'MapSubInfoPanel-Row--Selected': service.isSelected,
          'MapSubInfoPanel-Row--Selectable': this.props.data.services.length > 1 && !service.isSelected,
          'MapSubInfoPanel-Row--NoHover': this.props.data.services.length <= 1,
        });
        const trafficPolicyDecisionClasses = RenderUtils.getPolicyDecisionClassNames(
          service.trafficPd,
          this.props.data.colorBlind,
        );
        const trafficBarClasses = cx({
          [trafficPolicyDecisionClasses]: true,
          TrafficBar: !vulnerabilityView,
        });
        const trafficArrowClasses = cx({
          [trafficPolicyDecisionClasses]: true,
          'PolicyDecision-Arrow': true,
        });
        const trafficBarStyle = {width: service.trafficWeight};
        const clickService = _.partial(this.handleSelectService, service);
        let vulnerability;

        if (vulnerabilityView) {
          const values = service.vulnerabilities && service.vulnerabilities.aggregatedValues;

          vulnerability = service.vulnerabilities ? (
            <td className="MapSubInfoPanel-Row-TrafficVulnerability">
              {values && <Vulnerability vulnerability={{...values}} opacity />}
            </td>
          ) : (
            <td className="MapSubInfoPanel-Row-TrafficVulnerability" />
          );
        }

        const {trafficPd} = service;
        const servicePortProtocol = ServiceUtils.getPort(service)
          ? `${service.port} ${service.friendlyProtocol}`
          : String(service.friendlyProtocol);
        const isUnicastTransmission = !service.connectionClass || service.connectionClass === 'U';
        const trafficTransmissionTypeClasses = [
          'MapSubInfoPanel-TransmissionType',
          isUnicastTransmission ? '' : 'MapSubInfoPanel-TransmissionType-NonUnicast',
        ].join(' ');

        return (
          <tr className={serviceClasses} key={index} onClick={clickService} data-tid="map-info-panel-row">
            <td
              className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Width"
              data-tid="map-info-panel-row-value-service"
            >
              <Icon
                customClass={trafficArrowClasses}
                size="large"
                name={RenderUtils.getPolicyArrowIconName(trafficPd)}
              />
              <span className="MapSubInfoPanel-Row-Overflow Services-Item-Name">{serviceName}</span>
            </td>
            <td
              className="MapSubTrafficPanel-Row-Value servicesListItem-port"
              data-tid="map-info-panel-row-value-port-protocol"
            >
              <div>{servicePortProtocol}</div>
            </td>
            <td className="MapSubInfoPanel-Row-Transmission" data-tid="map-info-panel-row-value-service">
              <span className={trafficTransmissionTypeClasses}>
                {isUnicastTransmission ? '' : service.connectionClass}
              </span>
            </td>
            {vulnerabilityView ? (
              vulnerability
            ) : (
              <td className="MapSubInfoPanel-Row-TrafficBar" data-tid="map-info-panel-row-value-traffic">
                <div className={trafficBarClasses} style={trafficBarStyle} />
              </td>
            )}
            <td className="MapSubInfoPanel-Row-TrafficAction">{service.trafficAction}</td>
            <td onClick={clickService} data-tid="map-info-panel-row-view-service">
              <Icon styleClass="Chevron-Padding" size="xxlarge" name="next" />
            </td>
          </tr>
        );
      })
    );
  },

  render() {
    const {vulnerabilityView} = this.state;
    const {key, direction} = this.state.sort;
    const {services, serviceNum} = this.props.data;
    const serviceLabel = RenderUtils.getTrafficPanelServicesLabelText(services, serviceNum, MAX_SERVICES);
    const isSortable = this.props.data.services.length > 1;
    const [nameSortUpIconClass, nameSortDownIconClass] = RenderUtils.getTrafficPanelSortClasses('name', key, direction);
    const [portSortUpIconClass, portSortDownIconClass] = RenderUtils.getTrafficPanelSortClasses('port', key, direction);
    const [trafficSortUpIconClass, trafficSortDownIconClass] = RenderUtils.getTrafficPanelSortClasses(
      'trafficWeight',
      key,
      direction,
    );
    let servicesRow;

    let servicesTitle = (
      <tr className="MapSubInfoPanel-Row MapSubInfoPanel-Header" data-tid="map-sub-info-panel-row">
        <td className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Width" data-tid="map-info-panel-row-label">
          <span
            className="MapSubInfoPanel-Row-Header"
            onClick={() => this.handleSort('name')}
            data-tid="map-info-panel-row-header-label"
          >
            <span className="MapSubInfoPanel-Row-Overflow">{serviceLabel}</span>
            {isSortable ? (
              <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                <Icon styleClass={nameSortUpIconClass} name="sort-up" size="medium" />
                <Icon styleClass={nameSortDownIconClass} name="sort-down" size="medium" />
              </span>
            ) : null}
          </span>
        </td>
        <td className="MapSubInfoPanel-Row-Header-Value servicesList-port" data-tid="map-info-panel-row-value-service">
          <span
            className="MapSubInfoPanel-Row-Header"
            onClick={() => this.handleSort('port')}
            data-tid="map-info-panel-row-header-port"
          >
            <span>{intl('Port.Port')}</span>
            {isSortable ? (
              <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                <Icon styleClass={portSortUpIconClass} name="sort-up" size="medium" />
                <Icon styleClass={portSortDownIconClass} name="sort-down" size="medium" />
              </span>
            ) : null}
          </span>
        </td>
        <td className="MapSubInfoPanel-Row-Header-TrafficBar" data-tid="map-info-panel-row-value-service">
          <span
            className="MapSubInfoPanel-Row-Header"
            onClick={() => this.handleSort('trafficWeight')}
            data-tid="map-info-panel-row-header-value"
          >
            {vulnerabilityView ? <span>{intl('Common.Vulnerability')}</span> : <span>{intl('Common.Traffic')}</span>}
            {isSortable ? (
              <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                <Icon styleClass={trafficSortUpIconClass} name="sort-up" size="medium" />
                <Icon styleClass={trafficSortDownIconClass} name="sort-down" size="medium" />
              </span>
            ) : null}
          </span>
        </td>
      </tr>
    );

    if (services.length) {
      servicesRow = (
        <tr className="MapInfoPanel-Row MapInfoPanel-Row--SetBorder">
          <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanelWidth MapSubInfoPanel">
                <tbody>{this.renderServices()}</tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    } else {
      servicesTitle = null;

      servicesRow = (
        <tr className="MapInfoPanel-Row">
          <td className="MapInfoPanel-Row-Message">{intl('Services.NoServices')}</td>
        </tr>
      );
    }

    return (
      <table className="MapInfoPanel AppGroupTrafficPanel-Services">
        <tbody>
          {servicesTitle}
          {servicesRow}
        </tbody>
      </table>
    );
  },
});
