/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import Icon from '../components/Icon.jsx';

export default {
  // At a component level this is used for lables / inputs in ConnectivityCheck
  setProviderConsumerColumnOrder(provider, service, consumer, providerConsumerOrder, network) {
    const providerOrder = [provider];

    if (service) {
      providerOrder.push(service);
    }

    if (providerConsumerOrder === 'consumerFirst') {
      return [consumer, ...providerOrder, ...(network ? [network] : [])];
    }

    return [...providerOrder, consumer, ...(network ? [network] : [])];
  },

  // Use case for grids without a native arrow but need one e.g. Policy Generator.
  // For plain objects, such as in ConfigureExtraConnectionsGrid/ConfigureIntraConnectionsGrid
  // we flip the native arrow (created by the .format() function on the object) depending on the
  // providerConsumerOrder preference.
  setProviderConsumerColumnOrderArrow(provider, service, arrow, consumer, providerConsumerOrder) {
    if (providerConsumerOrder === 'consumerFirst') {
      if (arrow.format) {
        arrow.format = _.partial(arrow.format, _, _, 'right');
        arrow.label = <Icon name="arrow-right-long" size="xlarge" />;
      } else {
        arrow.format = () => <Icon name="arrow-right" size="large" />;
        arrow.label = <Icon name="arrow-right" size="large" />;
      }

      return [consumer, arrow, provider, service];
    }

    if (arrow.format) {
      arrow.format = _.partial(arrow.format, _, _, 'left');
      arrow.label = <Icon name="arrow-left-long" size="xlarge" />;
    } else {
      arrow.format = () => <Icon name="arrow-left" size="large" />;
      arrow.label = <Icon name="arrow-left" size="large" />;
    }

    return [provider, service, arrow, consumer];
  },

  // Sets provider consumer order for Blocked Traffic and Explorer views which
  // include labels and in the case of Explorer an extra providing service column
  // Otherwise both of those views fit the bill.
  setProviderConsumerWithLabelsColumnOrder(
    provider,
    providerLabels,
    providingService,
    arrow,
    consumer,
    consumerLabels,
    consumingService,
    providerConsumerOrder,
  ) {
    const providerOrder = [provider, providerLabels];
    const consumerOrder = [consumer, consumerLabels];

    // Explorer case with extra column
    if (providingService) {
      // For explorer, place providing service next to provider: [provider, providerLabels, providingService]
      providerOrder.push(providingService);
    }

    if (consumingService) {
      // For explorer, place consuming service next to consume: [consumer, consumerLabels, consumingService]
      consumerOrder.push(consumingService);
    }

    if (providerConsumerOrder === 'consumerFirst') {
      if (arrow.format) {
        arrow.format = _.partial(arrow.format, _, _, 'right');
        arrow.label = <Icon name="arrow-right-long" size="xlarge" />;
      } else {
        arrow.format = () => <Icon name="arrow-right" size="large" />;
        arrow.label = <Icon name="arrow-right" size="large" />;
      }

      return [...consumerOrder, arrow, ...providerOrder];
    }

    if (arrow.format) {
      arrow.format = _.partial(arrow.format, _, _, 'left');
      arrow.label = <Icon name="arrow-left-long" size="xlarge" />;
    } else {
      arrow.format = () => <Icon name="arrow-left" size="large" />;
      arrow.label = <Icon name="arrow-left" size="large" />;
    }

    return [...providerOrder, arrow, ...consumerOrder];
  },
};
