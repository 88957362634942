/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../actions/actionCreators';
import Dialog from './Dialog.jsx';
import Notification from './Notification.jsx';

export default React.createClass({
  propTypes: {
    object: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  },

  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: this.handleClose,
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    const {object, id} = this.props;

    return (
      <Dialog ref="dialog" actions={this.getActions()}>
        <Notification
          type="error"
          title={intl('Common.Alert')}
          message={intl('ObjectLimit.HardLimitReached', {object})}
        />
        <div className="ObjectLimit-title">{intl('ObjectLimit.IllumioHardLimitReached', {object})}</div>
        <div className="ObjectLimit-message">{intl('ObjectLimit.ContactIllumioSupport')}</div>
        <div className="ObjectLimit-request">{intl('ObjectLimit.RequestId', {id})}</div>
      </Dialog>
    );
  },
});
