/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
// Import polyfills first, to make sure all of its on-demand awaits are complete before anything else in the entry point
import '../../app/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import Router from 'react-router';
import Visibility from 'visibilityjs';
import {UNAUTHORIZED} from 'http-status-codes';
import {SessionStore} from './stores';
import Routes, {RoutesMap} from './Routes.jsx';
import {login} from './stores/SessionStore';
import Session from './lib/session';
import * as progressBar from 'progressBar';
import RestApiUtils from './utils/RestApiUtils';
import ProvisionUtils from './utils/ProvisionUtils';
import GeneralUtils from './utils/GeneralUtils';
import {webStorageUtils} from '@illumio-shared/utils';
import {setRoleNames, setHostIsSupercluserMember} from './lib/api';
import intl, {setIntlValueMapper} from '@illumio-shared/utils/intl';
import {getIntlValueMapper} from '@illumio-shared/utils/intl/utils';
import actionCreators from './actions/actionCreators';
import tesseReactRouteNamesMap from './components/InstantSearch/InstantSearchContainerProperties';
import {GraphDataUtils} from './utils';

const intlValuesMappingForLightning = {
  [intl('Common.Destination')]: intl('Common.Destination'),
  [intl('Common.Destination')]: intl('Common.Destination'),
  [intl('Common.Destination')]: intl('Common.Destination'),
  [intl('Common.Destinations')]: intl('Common.Destinations'),
  [intl('Common.Source')]: intl('Common.Source'),
  [intl('Common.Sourced')]: intl('Common.Sourced'),
  [intl('Common.Source')]: intl('Common.Source'),
  [intl('Common.Sources')]: intl('Common.Sources'),
  [intl('Help.Desc.Destination')]: intl('Help.Desc.Destination'),
  [intl('Help.Desc.Source')]: intl('Help.Desc.Source'),
  [intl('Rulesets.DenyRule')]: intl('Rulesets.DenyRule'),
  [intl('Rulesets.DenyRules')]: intl('Rulesets.DenyRules'),
  [intl('Rulesets.DenyRules')]: intl('Rulesets.DenyRules'),
  [intl('Rulesets.DenyRulesAuto')]: intl('Rulesets.DenyRulesAuto'),
  [intl('Rulesets.Rules.DestinationServices')]: intl('Rulesets.Rules.DestinationServices'),
  [intl('Explorer.ByDenyRules')]: intl('Explorer.ByDenyRules'),
  [intl('Explorer.AcrossDenyRules')]: intl('Explorer.AcrossDenyRules'),
  [intl('Common.BlockedByDenyRules')]: intl('Common.BlockedByDenyRules'),
  [intl('Common.PotentiallyBlockByDenyRules')]: intl('Common.PotentiallyBlockByDenyRules'),
};

(async () => {
  let user;

  try {
    if (Visibility.state() === 'prerender') {
      // If document is prerendering (like in Safari) before user actually pressed enter on the url,
      // wait for any other visibility state to actually start fetching data.
      // It's ok to fetch and render on hidden page, for instance if user opened new tab in background (like cmd+enter)
      await new Promise(resolve => Visibility.afterPrerendering(resolve));
    }

    // Try to get user from tessereact jump first. #USERJUMP
    user = webStorageUtils.getSessionItem('JUMPS_USER', {remove: true});

    if (user) {
      login(user);
    } else {
      try {
        // Try to login user on PCE with or without authToken
        // If without, browser will send pce_session cookie, that we can't read from javascript (it is HttpOnly)
        user = (await RestApiUtils.users.loginToPCE(window.authToken)).body;
      } catch (error) {
        // If user session does not exist, save url and redirect to login server
        // 401
        if ([UNAUTHORIZED].includes(error.response.status)) {
          Session.clear();
          Session.saveUriForLoginRedirect();

          localStorage.removeItem('user_id');

          window.location = error.response.headers.get('x-redirect') || _loginHref_;

          return; // Because redirect is not instant
        }

        throw error;
      }
    }

    setIntlValueMapper(getIntlValueMapper(intlValuesMappingForLightning));
    actionCreators.setRoutesMap(new Map([...RoutesMap.entries(), ...Object.entries(tesseReactRouteNamesMap())]));

    const regex = /^(\d+\.\d+\.\d+).*/;

    if (regex.test(user.product_version.release_info)) {
      user.product_version.version = user.product_version.release_info.match(regex)[1];
    }

    setRoleNames(Session.getRoleNames());
    setHostIsSupercluserMember(SessionStore.isSuperclusterMember());
  } catch {
    // In case of any other error (not 401) redirect to the root, so tessereact can handle it and show a message
    window.location = '#/';
    location.reload(false);

    return; // Because redirection is not instant
  }

  // check version mismatch
  if (
    GeneralUtils.getVersionMismatch({
      pceVersion: user.product_version.version,
      parameterized: user.product_version.parameterized,
    }) !== 0
  ) {
    window.location = '#/versionmismatch';
    // need to reload(false) to reload from cache since a hash is declared for window.location = '#/versionmismatch'
    location.reload(false);

    // Because redirection is not instant
    return;
  }

  await RestApiUtils.orgSettings.get();

  if (!__ANTMAN__) {
    await RestApiUtils.labels.getLabelSettingsCollection();
  }

  // Wait
  const [settingsResponse] = await Promise.all([
    RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'settings'),
    RestApiUtils.optionalFeatures.getCollection(),
    RestApiUtils.orgs.getInstance(Session.getOrgId()),
    RestApiUtils.health.get(),
    RestApiUtils.secPolicies.getCollection(),
    RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'whats_new'),
    RestApiUtils.vens.getEndpointVENCount(),
    RestApiUtils.ruleSets.getPCEUpgradeRuleset(),
  ]);

  if (!settingsResponse?.body) {
    // if there are no settings in kvpairs yet, make a call to create them once
    RestApiUtils.kvPair.update(SessionStore.getUserId(), 'settings', {});
  }

  RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'instant_search_history').then(historyResponse => {
    if (!historyResponse?.body) {
      RestApiUtils.kvPair.update(SessionStore.getUserId(), 'instant_search_history', []);
    }
  });

  if (SessionStore.isCoreServicesSettingsEnabled()) {
    RestApiUtils.coreServices.getSettings();
  }

  if (SessionStore.isUIAnalyticsEnabled() && !__DEV__) {
    const loginUrl = new URL(user.login_url);

    try {
      import(/* webpackChunkName: 'analytics' */ 'utils/analytics').then(({default: loadDAPAnalytics}) => {
        loadDAPAnalytics(user);
      });
    } catch (error) {
      console.error(`Error attempting to initialize DAP analytics: ${error}`);
    }

    try {
      import(/* webpackChunkName: 'logrocket' */ 'logrocket').then(({default: LogRocket}) => {
        // Setup analytics
        const appID = __ANTMAN__ ? 'rgjofh/masked-commercial-staging' : `rgjofh/${__TARGET__.toLowerCase()}-production`;

        LogRocket.init(appID, {
          shouldCaptureIP: false,
          ...(!__ANTMAN__ && {
            dom: {
              isEnabled: true,
              inputSanitizer: true,
              textSanitizer: true,
            },
          }),
          network: {
            isEnabled: false,
          },
        });

        // Since Org IDs + User IDs are not guaranteed to be unique between clusters
        // lets tie the hostname to the org ID and user ID.
        LogRocket.identify(user.id, {
          orgId: user.org_id,
          hostname: loginUrl.hostname,
        });
      });
    } catch (error) {
      console.error(`Error attempting to initialize analytics: ${error}`);
    }
  }

  // Start but don't wait
  RestApiUtils.nfcs.getCollection();
  ProvisionUtils.provisionCheckPending();
  RestApiUtils.secPolicies.dependencies();
  RestApiUtils.venReleases.getCollection();

  if (SessionStore.isUserScoped() && !GraphDataUtils.isAppGroupSummaryLoaded(true)) {
    GraphDataUtils.getTraffic('appGroup', {route: 'groups'});
  }

  progressBar.end();

  const render = Element => ReactDOM.render(Element, document.querySelector('#root')); // eslint-disable-line react/no-render-return-value

  if (__DEV__) {
    const RedBox = require('redbox-react').default;
    let renderError;

    const run = Routes => {
      Router.run(Routes, Handler => {
        requestAnimationFrame(() => {
          try {
            // _rootComponentInstance_ is needes for invoking forceUpdate after hot intl replacement occured (when you change language file)
            window._rootComponentInstance_ = render(React.createFactory(Handler)({}));

            if (renderError) {
              // If there is no render error anymore, reload the page (can't correctly restore state now after error)
              location.reload();
            }
          } catch (error) {
            renderError = error;
            render(<RedBox error={error} />);
          }
        });
      });
    };

    run(Routes);

    if (module.hot) {
      module.hot.accept('./Routes.jsx', () => {
        requestAnimationFrame(() => {
          const Routes = require('./Routes.jsx').default;

          run(Routes);
        });
      });
    }
  } else {
    Router.run(Routes, Handler => render(React.createFactory(Handler)({})));
  }
})();
